import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { LanguageDictionary } from 'src/app/model/languagedictionary.model';
import { TrainingPlan, TrainingSession } from 'src/app/model/training-plan.model';
import { LanguageService } from 'src/app/services/language.service';
import { TrainingService } from 'src/app/services/training.service';
import { TrainingPlanEditorComponent } from '../training-plan-editor/training-plan-editor.component';

@Component({
  selector: 'app-exercise-group-templates-dialog',
  templateUrl: './exercise-group-templates-dialog.component.html',
  styleUrls: ['./exercise-group-templates-dialog.component.css']
})
export class ExerciseGroupTemplatesDialogComponent {

  public showSessionTemplates: boolean = false;
  public filteredTrainingPlanTemplates: TrainingPlan[];
  public selectedSessionTemplateTab: string = "OWN"

  public selectedGroupHeadings: LanguageDictionary<string>[] = []

  constructor(public dialogRef: MatDialogRef<ExerciseGroupTemplatesDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { }, public trainingService: TrainingService, public languageService: LanguageService, private spinner: NgxSpinnerService){
    this.updateFilteredSessionTemplates();
  }

  public getGroupHeadingsBySession(session: TrainingSession): string[]{
    let headings = session.exercises.filter(x => x.groupHeading != null).map(x => x.groupHeading);
    return headings
  }

  public sessionTemplateSearchInput: string

  onSelectTemplateTab(tabName: string){
    this.selectedSessionTemplateTab = tabName
  }

  async selectSessionTemplate(selectedGroupHeading: LanguageDictionary<string>, template: TrainingPlan){
    this.spinner.show("sessionTemplateSpinner")
    if(!this.selectedGroupHeadings.includes(selectedGroupHeading)){
      await this.trainingService.getTrainingPlanTemplatePlannedExercises(template);
      this.selectedGroupHeadings.push(selectedGroupHeading);
    }
    else{
      this.selectedGroupHeadings = this.selectedGroupHeadings.filter(x => x != selectedGroupHeading)
    }
    this.spinner.hide("sessionTemplateSpinner")
  }

  onSessionTemplateSearchInputChanged(text: string) {
    this.sessionTemplateSearchInput = text
    this.updateFilteredSessionTemplates()
  }

  async updateFilteredSessionTemplates() {
    this.spinner.show("sessionTemplateSpinner");
    if (!this.sessionTemplateSearchInput || this.sessionTemplateSearchInput.length === 0) {
      this.filteredTrainingPlanTemplates = (await this.trainingService.getTrainingPlanTemplates())?.filter(template => template.sessions?.filter(x => !x.isRestDay)?.length > 0);
    } else {
    this.filteredTrainingPlanTemplates = 
      (await this.trainingService.getTrainingPlanTemplates())
        .filter(template => template.sessions?.filter(x => !x.isRestDay)?.length > 0 && (template.getName()?.toLowerCase().includes(this.sessionTemplateSearchInput.toLowerCase()) || template.sessions?.find(x => x.name?.toLowerCase()?.includes(this.sessionTemplateSearchInput.toLowerCase())) != null));
    }
    this.spinner.hide("sessionTemplateSpinner");
  }

  async onDeleteSessionTemplateSearchInput() {
    this.sessionTemplateSearchInput = null;
    (<HTMLInputElement> document.getElementById('templatesearch-input')).value = ''
    this.filteredTrainingPlanTemplates = await this.trainingService.getTrainingPlanTemplates()
  }

  async onTakeSelection(selectedGroupHeadings: LanguageDictionary<string>[]){
    let newSessions = [];
    let trainingPlanTemplates = await this.trainingService.getTrainingPlanTemplates();
    for (let groupHeading of selectedGroupHeadings) {
      // let session = trainingPlanTemplates.map(x => x.sessions).find(x => x.find(y => y.exercises.find(z => z.groupHeadingTranslation == groupHeading)));
      let session = trainingPlanTemplates.map(x => x.sessions).reduce((acc, val) => acc.concat(val), []).find(x => x.exercises.find(y => y.groupHeadingTranslation == groupHeading));
      if(!session) continue;
      let exercises = TrainingPlanEditorComponent.getExercisesByGroupName(session.exercises, groupHeading);
      let newSession = new TrainingSession(groupHeading.GetValue(this.languageService.selectedLanguageCode), null, false, exercises, false, session.weekId, session.plannedDate, session.baseSessionId, session.hide, session.indicatorColor, session.estimatedDurationInMinutes, session.nameTranslation);
      newSession.exercises.forEach(exercise => {
        exercise.id = null;
        exercise.sessionId = null;
      });
      newSessions.push(newSession);
    }
    

    if(newSessions.length > 0){
      this.dialogRef.close({shouldTake: true, selectedSessions: newSessions})
    }
  }

  onCloseDialog(){
    this.dialogRef.close({shouldTake: false})
  }
}
