<div class="dialog-topbar">
    <div class="icon-button" *ngIf="!previewOnly" (click)="onSaveExercise()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
        <span class="icon-button-text">Speichern</span>
    </div>
    <div class="display-inlineblock marginleft-25">
        <app-language-selector></app-language-selector>
    </div>
    <div class="label display-inlineblock" *ngIf="previewOnly">Um die Übung zu bearbeiten, öffne sie über die Datenbank.</div>
    <div class="icon-button float-right" (click)="cancelEditExercise()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        <span class="icon-button-text">Schließen</span>
    </div>
    <button *ngIf="!previewOnly" class="icon-button float-right marginright-25" (click)="onDeleteExercise()">
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"  fill="currentColor" class="bi bi-trash padding-4" viewBox="0 0 16 16">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
        <span class="icon-button-text">Löschen</span>
    </button>
</div>
<div class="dialog-content-wrapper">
    <div class="row justify-content-center">
        <div class="col-lg-2 col-md-5">
            <div class="row">
                <div class="col-lg col-6 mb-2">
                    <div class="spacer-10"></div>
                    <label for="thumbnail-input">Thumbnail</label>
                    <div class="spacer-10"></div>
                    <div class="thumbnail-image link image-dropzone" (click)="openThumbnailDialog()" (dragover)="onDragOver($event)" (drop)="onDropThumbnail($event)" [ngStyle]="{'background-image':'url('+ thumbnailImageSrc +')'}">
                        <div *ngIf="!thumbnailImageSrc" for="thumbnail-input" class="dropzone-hint">Thumbnail (.png oder .jpg) ablegen oder auswählen</div>
                        <input [disabled]="previewOnly" id="thumbnail-input" type="file" style="display: none;" accept=".jpg, .png" (change)="uploadThumbnail($event)">
                    </div>
                </div>
                <div class="col-lg col-6 mb-2">
                    <div class="spacer-10"></div>
                    <label for="preview-input">Animation</label>
                    <div class="spacer-10"></div>
                    <div class="thumbnail-image link image-dropzone" (click)="openExercisePreviewDialog()" (dragover)="onDragOver($event)" (drop)="onDropPreview($event)" [style.background-image]="'url(' + exercisePreviewSrc + ')'">
                        <span *ngIf="!exercisePreviewSrc" for="preview-input" class="dropzone-hint">Animation (.gif) ablegen oder auswählen</span>
                        <input [disabled]="previewOnly" id="preview-input" type="file" style="display: none;" accept=".gif" (change)="uploadExercisePreview($event)">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-10 col-sm-12">
            <div class="row">
                <div class="col-12 col-sm-6">
                    <label for="input-exercise-name">Übungsname</label>
                    <input [disabled]="previewOnly" id="input-exercise-name" required class="input plannedmeal-name form-control full-width-input" [ngModel]="selectedTrainingExercise.name.GetValue(selectedLanguageCode)" (input)="setLanguageDictionaryValue(selectedTrainingExercise, 'name', $event.target.value)" placeholder="Name" type="text">
                </div>
                <div class="col-12 col-sm-6 mobile-margintop-10">
                    <label for="input-exercise-name">Untertitel</label>
                    <input [disabled]="previewOnly" id="input-exercise-name" class="input plannedmeal-name form-control full-width-input" [ngModel]="selectedTrainingExercise.subName.GetValue(selectedLanguageCode)" (input)="setLanguageDictionaryValue(selectedTrainingExercise, 'subName', $event.target.value)" placeholder="Name" type="text">
                </div>
            </div>
            <div class="row" *ngIf="trainingService.AdministratorEditModeActivated && !previewOnly">
                <div class="col">
                    <div class="spacer-10"></div>
                    <label for="invertedWeight-toggle">Basisübung</label>
                    <div class="toggle-wrapper">
                        <div id="invertedWeight-toggle" class="toggle {{selectedTrainingExercise.isBasicExercise ? 'on' : ''}} {{previewOnly ? 'toggle-disabled' : ''}}" (click)="onIsBasicExerciseChanged(!selectedTrainingExercise.isBasicExercise)">
                            <div class="handle"></div>
                        </div>
                    </div>
                    <div class="icon-button marginleft-25">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-sm-12">
                    <div class="spacer-10"></div>
                    <label for="exerciseType-select">Übungstyp</label>
                    <app-search-input-select [disabled]="previewOnly" id="exerciseType-select" [(SelectedItems)]="selectedTrainingExercise.exerciseType" [SelectableItems]="trainingService.selectableExerciseTypes" [LanguageCode]="selectedLanguageCode"></app-search-input-select>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="spacer-10"></div>
                    <label for="movementType-select">Bewegungstyp</label>
                    <app-search-input-select [disabled]="previewOnly" id="movementType-select" [(SelectedItems)]="selectedTrainingExercise.movement" [SelectableItems]="trainingService.selectableMovementTypes" [LanguageCode]="selectedLanguageCode"></app-search-input-select>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="spacer-10"></div>
                    <label for="invertedWeight-toggle">Unterstützendes Gewicht</label>
                    <div class="toggle-wrapper">
                        <div id="invertedWeight-toggle" class="toggle {{selectedTrainingExercise.isInvertedWeight ? 'on' : ''}} {{previewOnly ? 'toggle-disabled' : ''}}" (click)="onIsInvertedWeightChanged(!selectedTrainingExercise.isInvertedWeight)">
                            <div class="handle"></div>
                        </div>
                    </div>
                    <div class="icon-button marginleft-25" tooltip="Schalte diesen Regler an, wenn eine Übung leichter wird, wenn mehr Gewicht hinzugefügt wird.">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                        </svg>
                    </div>
                </div>
                <div class="col">
                    <div class="spacer-10"></div>
                    <label for="pause-duration-input">Pausendauer</label>
                    <div class="light small ng-pristine ng-valid ng-touched">
                        <!-- <input id="pause-duration-input" min="0" max="600" class="number-input" type="number" [(ngModel)]="selectedTrainingExercise.pauseDuration" [disabled]="overwriteExerciseDisabled"> Sekunden -->
                        <input min="0" max="600" class="input form-control white-input number-input display-inlineblock" [(ngModel)]="selectedTrainingExercise.pauseDuration" type="number" [disabled]="overwriteExerciseDisabled || previewOnly" [placeholder]="selectedTrainingExercise.getDefaultPauseDuration()">
                        <div class="display-inlineblock paddingleft-10"> s</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-sm-12">
                    <div class="spacer-10"></div>
                    <label for="equipment-select">Equipment</label>
                    <app-search-input-select [disabled]="previewOnly" id="equipment-select" [(SelectedItems)]="selectedTrainingExercise.equipment" [SelectableItems]="trainingService.selectableEquipments" [LanguageCode]="selectedLanguageCode"></app-search-input-select>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="spacer-10"></div>
                    <label for="muscleGroup-select">Muskelgruppen</label>
                    <app-search-input-select [disabled]="previewOnly" id="muscleGroup-select" [(SelectedItems)]="selectedTrainingExercise.muscleGroups" [SelectableItems]="trainingService.selectableMuscleGroups" [LanguageCode]="selectedLanguageCode"></app-search-input-select>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-sm-12">
                    <div class="spacer-10"></div>
                    <label for="mainMuscle-select">Hauptmuskulatur</label>
                    <app-search-input-select [disabled]="previewOnly" id="mainMuscle-select" [(SelectedItems)]="selectedTrainingExercise.mainMuscles" [SelectableItems]="trainingService.selectableMuscles" [LanguageCode]="selectedLanguageCode"></app-search-input-select>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="spacer-10"></div>
                    <label for="subMuscle-select">Nebenmuskulatur</label>
                    <app-search-input-select [disabled]="previewOnly" id="subMuscle-select" [(SelectedItems)]="selectedTrainingExercise.subMuscles" [SelectableItems]="trainingService.selectableMuscles" [LanguageCode]="selectedLanguageCode"></app-search-input-select>
                </div>
            </div>
            <div class="spacer-10"></div>
            <div class="row">
                <div class="col-lg-6 col-sm-12">
                    <label for="category-select">Eigene Kategorie</label>
                    <app-search-input-select [disabled]="overwriteExerciseDisabled || previewOnly" id="category-select" [(SelectedItems)]="selectedTrainingExercise.customCategories" [SelectableItems]="trainingService.selectableCustomCategories" [LanguageCode]="selectedLanguageCode" [FreeInput]="true" [translatable]="false"></app-search-input-select>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="spacer-10"></div>
                    <label for="invertedWeight-toggle">Übung in Übungsdatenbank ausblenden</label>
                    <div class="toggle-wrapper">
                        <div id="invertedWeight-toggle" class="toggle {{selectedTrainingExercise.hiddenExercise ? 'on' : ''}} {{previewOnly ? 'toggle-disabled' : ''}}" (click)="onHiddenExerciseChanged(!selectedTrainingExercise.hiddenExercise)">
                            <div class="handle"></div>
                        </div>
                    </div>
                    <div class="icon-button marginleft-25" tooltip="Schalte diesen Regler an, wenn du diese Übung nicht in der Übungsdatenbank deiner Coachees zur Verfügung stellen möchtest. Sie wird einem Coachee nur innerhalb eines Plans angezeigt.">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                        </svg>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <label for="default-setparameter-select">Standard Satzparameter</label>
                    <app-search-input-select [disabled]="previewOnly" id="default-setparameter-select" [(SelectedItems)]="selectedTrainingExercise.defaultSetParameters" [SelectableItems]="getSelectableSetParameters()" [LanguageCode]="selectedLanguageCode" [FreeInput]="false" [translatable]="true"></app-search-input-select>
                </div>
            </div>
            <div class="spacer-10"></div>
            <div class="row">
                <div class="col">
                    <label for="instruction-editor">Instruktionen</label>
                    <div class="icon-button col-12" *ngIf="!editExerciseDisabled && !previewOnly && trainingService.CanEnterAdministratorEditMode && !selectedTrainingExercise?.instructions?.GetValue(this.selectedLanguageCode)" (click)="languageService.translateOrGenerateInstructionsForExercise(selectedTrainingExercise)">
                        Automatisch ausfüllen
                    </div>
                    <app-md-editor id="instruction-editor" [readOnlyMode]="previewOnly" class="min-height" [placeHolder]="placeholder" (valueChanged)="onEditorValueChanged($event)" [inputValue]="selectedTrainingExercise?.instructions?.GetValue(this.selectedLanguageCode)"></app-md-editor>
                </div>
                
            </div>
            <!-- <div class="spacer-10"></div>
            <div class="row">
                <div class="col">
                    <label for="notes-editor">Eigene Notizen</label>
                    <app-md-editor id="notes-editor" [readOnlyMode]="overwriteExerciseDisabled" class="min-height" [placeHolder]="placeholder" (valueChanged)="onNotesEditorValueChanged($event)" [inputValue]="selectedTrainingExercise?.customNotes"></app-md-editor>
                </div>
            </div> -->
            <div class="spacer-20"></div>
            <div class="row">
                <div class="col-12 col-sm-6">
                    <div class="spacer-10"></div>
                    <label for="upload-video">Video hinterlegen</label>
                    <div class="video-input link" (click)="openVideoDialog()" *ngIf="!videoSrc">
                        <label class="hint label">Hochladen (max. 50MB)</label>
                        <input [disabled]="previewOnly" id="video-upload" class="link" type="file" style="display: none;" accept=".mp4, .x-m4v, .mov" (change)="uploadVideo($event)">
                    </div>
                    <div class="spacer-10"></div>
                    <video [src]="videoSrc" *ngIf="videoSrc" height="200" class="video-preview" id="videoPlayer" controls></video>
                    <button *ngIf="videoSrc && !previewOnly" class="small light" (click)="onDeleteVideo()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"  fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                        </svg>
                        Video entfernen
                    </button>
                </div>
                <div class="col-12 col-sm-6">
                    <div class="spacer-10"></div>
                    <label for="custom-video-url-input">Externes Video</label>
                    <input [disabled]="overwriteExerciseDisabled || previewOnly" id="custom-video-url-input" class="text-input full-width-input" pattern="^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.be|youtube\.de|youtube-nocookie\.com|vimeo.com|player.vimeo.com)\/.+$" type="url" [(ngModel)]="selectedTrainingExercise.customVideoUrl" placeholder="YouTube/Vimeo-Link einfügen...">
                    <div class="spacer-10"></div>
                    <div class="label">
                        Füge einen Embed-Link eines YouTube- oder Vimeo-Videos ein. Diesen erhältst du, indem du auf Teilen und Video einbetten klickst und dann nur den Link kopierst. Das Format sollte so aussehen: https://player.vimeo.com/video/... bzw. https://www.youtube.com/embed/...
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>