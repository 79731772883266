import { 
    Component, 
    NgModule, 
    VERSION, 
    ElementRef,
    ViewChild,
    Input
} from '@angular/core'
declare var Calendly: any;

@Component({
  selector: 'app-calendly-widget',
  template: `
        <div id='calendly-wrapper' class="calendly-inline-widget" style="min-width:320px;height:700px;" data-auto-load="false"></div>
  `
})
export class CalendlyWidgetComponent {
    _calendlyUrl: string = null
    initialized: boolean = false

    @Input() set calendlyUrl(value: string){
        if (value != this._calendlyUrl) {
            this._calendlyUrl = value
            this.initCalendly()
        }
    }

    ngOnInit() {
        this.initCalendly()
    }

    initCalendly() {
        if (this.initialized) return
        Calendly.initInlineWidget({
            url: this._calendlyUrl,
            parentElement: document.getElementById('calendly-wrapper'),
        });
        this.initialized = true
    }
}