import { ClientComponent } from '../client/client-v1/client-v1.component';
import { NutritionalGoal } from './nutritionalgoal.model';
import { CalendarDay, DayItem } from '../nutrition-plan/nutrition-plan.component';
import { PlannedMealV2 } from './plannedmealv2.model';
import { NutritionalGoalV2 } from './nutritionalgoalv2.model';
import { CycleConfig } from './nutritionconfig.model';
import { NutritionPlanConfig } from './nutritionplanconfig.model';

export class PlanningTemplate {
    public weekDays = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];

    id: string
    name: string
    description: string
    coachUid: string
    licenceHolderUid: string
    
    cycleConfigs: CycleConfig[] = []
    nutritionPlanConfigs: NutritionPlanConfig[] = []

    constructor();
    constructor(init: PlanningTemplate);
    constructor(init?: PlanningTemplate) {
        this.id = init && init.id || null
        this.name = init && init.name || null
        this.description = init && init.description || null
        this.coachUid = init && init.coachUid || null
        this.licenceHolderUid = init && init.licenceHolderUid || null
    }

    getName() {
        return this.name || ''
    }

    getDescription() {
        return this.description || ''
    }

}