import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-create-template-dialog',
  templateUrl: './create-template-dialog.component.html',
  styleUrls: ['./create-template-dialog.component.css']
})
export class CreateTemplateDialogComponent implements OnInit {

  public name: string
  public description: string

  constructor(public dialogRef: MatDialogRef<CreateTemplateDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {name: string, description: string}) {
    this.name = data.name
    this.description = data.description
  }

  ngOnInit(): void {
  }

  onCancelDialog() {
    this.dialogRef.close(null);
  }
  onConfirmDialog() {
    this.dialogRef.close({name: this.name, description: this.description});
  }

}
