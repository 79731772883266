<div id="training-plan-editor" class="layout-box {{readOnlyMode ? 'disabled': ''}}"
    (window:beforeunload)="doBeforeUnload()">
    <div class="component-wrapper" *ngIf="trainingPlan">
        <div class="top-wrapper" (click)="closeSessionsColumn()">
            <div class="top-bar">
                <div *ngIf="!readOnlyMode" class="icon-button" (click)="saveTrainingPlan()">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                    </svg>
                    <ng-container *ngIf="!utilityService.onMobile()">Speichern</ng-container>
                </div>
                <div *ngIf="trainingPlan.isTemplate || user?.hasOtherLanguageSettings()" class="display-inlineblock marginleft-25">
                    <app-language-selector></app-language-selector>
                </div>
                <div class="icon-button float-right ignore-disabled" (click)="cancelEditTrainingPlan()">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                    <ng-container *ngIf="!utilityService.onMobile()">Schließen</ng-container>
                </div>
                <div *ngIf="!readOnlyMode" class="icon-button float-right delete-recipe marginleft-25 marginright-25"
                    (click)="deleteTrainingPlan()">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="padding-4 bi bi-trash" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                        <path fill-rule="evenodd"
                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                    </svg>
                    <ng-container *ngIf="!utilityService.onMobile()">Löschen</ng-container>
                </div>
                <div class="icon-button float-right marginleft-25" *ngIf="!trainingPlan.isTemplate && !readOnlyMode"
                    (click)="savePlanAsTemplate(trainingPlan)"
                    tooltip="Als Vorlage speichern">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor"
                        class="padding-4 bi bi-clipboard" viewBox="0 0 16 16">
                        <path
                            d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                        <path
                            d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                    </svg>
                    <ng-container *ngIf="!utilityService.onMobile()">Als Vorlage speichern</ng-container>
                </div>
                <div class="icon-button float-right ignore-disabled" *ngIf="trainingExercisesLoaded()"
                    (click)="onExportTrainingPlan();$event.stopPropagation()" tooltip="Als PDF exportieren">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor"
                        class="padding-4 bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                        <path
                            d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                        <path
                            d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                    </svg>
                    <ng-container *ngIf="!utilityService.onMobile()">Exportieren</ng-container>
                </div>
            </div>
            <div class="spacer-10"></div>
            <div class="row header-row">
                <div class="col-auto">
                    <h2 class="">Trainingsplan<ng-container *ngIf="trainingPlan.isTemplate">-Vorlage</ng-container></h2>
                </div>
                <div class="col">
                    <h3 class="link ignore-disabled"
                        (click)="trainingPlanSettingsExpanded = !trainingPlanSettingsExpanded">
                        <ng-container *ngIf="trainingPlanSettingsExpanded; else settingsCollapsed">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor"
                                class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path
                                    d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                        </ng-container>
                        <ng-template #settingsCollapsed>
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor"
                                class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                <path
                                    d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                            </svg>
                        </ng-template>
                        Einstellungen
                    </h3>
                </div>
                <div class="col-auto float-right top-plan-variables-button">
                    <div class="icon-button small" (click)="openTrainingVariablesDialog()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor"
                            class="bi bi-bullseye" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="M8 13A5 5 0 1 1 8 3a5 5 0 0 1 0 10m0 1A6 6 0 1 0 8 2a6 6 0 0 0 0 12" />
                            <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8" />
                            <path d="M9.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                        </svg>
                        Planvariablen bearbeiten
                    </div>
                </div>
            </div>
            <app-training-plan-settings [trainingPlan]="trainingPlan"
                [(trainingPlanSettingsExpanded)]="trainingPlanSettingsExpanded" [(removeThumbnail)]="removeThumbnail"
                [(thumbnailImageSrc)]="thumbnailImageSrc" [(newThumbnail)]="newThumbnail" [(hasChanges)]="hasChanges"
                [(readOnlyMode)]="readOnlyMode" [selectedLanguageCode]="languageService.selectedLanguageCode"
                [assignedTrainingTemplateFolders]="assignedTrainingTemplateFolders"
                [trainingTemplateAdministratorEditModeActivated]="trainingService.TrainingTemplateAdministratorEditModeActivated"
                (updateSessionPlannedDates)="trainingPlanEditorHelper?.updateSessionPlannedDates($event.trainingPlan, $event.previousDate, $event.newDate)"
                (setWeekDays)="setWeekDays()" (setCalendarItems)="setCalendarItems()" [user]="user"></app-training-plan-settings>
            <div class="row col-12 hidden toggle-sessions-button">
                <div class="row">
                    <div class="col icon-button burger d-inline-block ignore-disabled"
                        (click)="toggleSessionsColumn(); $event.stopPropagation();">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-list" fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                        </svg>
                        <span> Einheiten</span>
                    </div>
                    <div class="col-auto float-right" *ngIf="!readOnlyMode">
                        <div class="icon-button small" (click)="openTrainingVariablesDialog()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor"
                                class="bi bi-bullseye" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                <path d="M8 13A5 5 0 1 1 8 3a5 5 0 0 1 0 10m0 1A6 6 0 1 0 8 2a6 6 0 0 0 0 12" />
                                <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8" />
                                <path d="M9.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                            </svg>
                            Planvariablen bearbeiten
                        </div>
                    </div>
                </div>
            </div>
            <div class="spacer-20"></div>
            <div class="shadow-edge"></div>
        </div>
        <div class="container-fluid session-container padding-0" (click)="trainingPlanSettingsExpanded = false">
            <app-sessions-column class="col-12 col-lg-auto sessions-column {{sessionsColumnClosed ? 'closed' : ''}}" [(sessionsColumnClosed)]="sessionsColumnClosed" [(draggingWeek)]="draggingWeek"
                [(draggingSession)]="draggingSession" [(focusedBeforeDropzoneWeek)]="focusedBeforeDropzoneWeek"
                [(focusedAfterDropzoneWeek)]="focusedAfterDropzoneWeek"
                [(focusedWeekDayForSessionDrop)]="focusedWeekDayForSessionDrop"
                [(focusedWeekNumberForSessionDrop)]="focusedWeekNumberForSessionDrop"
                [(weekDaysByWeekId)]="weekDaysByWeekId" [(selectedSession)]="selectedSession"
                [(focusedAfterDropzoneSession)]="focusedAfterDropzoneSession" [(hasChanges)]="hasChanges"
                [trainingPlan]="trainingPlan" [readOnlyMode]="readOnlyMode" (addWeek)="addWeek()"
                (removeWeek)="removeWeek($event)" (duplicateWeek)="duplicateWeek($event)"
                (dropWeek)="onDropWeek($event.week, $event.after)"
                (dropOnWeekDayItem)="onDropOnWeekDayItem($event.weekDay, $event.weekId)"
                (addSessionToDay)="addSessionToDay($event.date, $event.weekId)"
                (addSessionFromTemplate)="addSessionFromTemplate($event.plannedDay, $event.week)"
                (addSession)="addSession($event.isRest, $event.addToNewWeek, $event.newSession)"
                (duplicateSession)="duplicateSession($event)" (removeSession)="removeSession($event)"
                (dropAfterSession)="onDropAfterSession($event)" (setCalendarItems)="setCalendarItems()"
                (connectToBaseSession)="connectToBaseSession($event.session, $event.baseSession)"
                (saveSessionAsTemplate)="saveSessionAsTemplate($event)"
                (saveWeekAsTemplate)="saveWeekAsTemplate($event)"
                (addWeekFromTemplate)="addWeekFromTemplate()"
                (openTrackedTrainingSession)="onOpenTrackedTrainingSession($event)">
            </app-sessions-column>
            <app-training-session-editor class="col-12 col-lg" [headerAreaEnabled]="true" [readOnlyMode]="readOnlyMode" [trainingPlan]="trainingPlan" [user]="user"
                [userCardioZoneGroups]="getUserCardioZoneGroups()" [globalCardioZones]="getGlobalCardioZones()"
                [selectedCoach]="selectedCoach" [selectedSession]="selectedSession"
                [trackedTrainingSessions]="trackedTrainingSessions" [(hasChanges)]="hasChanges"
                [availableTrainingVariables]="availableTrainingVariables"
                [(copyExercise)]="copyExercise" [(copyExercisesList)]="copyExercisesList" [(expandedExercise)]="expandedExercise"
                (closeSessionsColumn)="closeSessionsColumn()"
                (openTrainingVariablesDialog)="openTrainingVariablesDialog()"
                (showVariableNotAvailableDialog)="showVariableNotAvailableDialog()">
            </app-training-session-editor>
            <div class="calendar-view ignore-disabled" cdkDrag (cdkDragStarted)="disableCalendarCollapsible()"
                (cdkDragEnded)="enableCalendarCollapsible()" (cdkDragReleased)="calendarDropped($event)"
                (cdkDragDropped)="calendarDropped($event)">
                <app-monthly-calendar [showRelativeMonths]="trainingPlan.isTemplate"
                    [isCollapsible]="isCalendarCollapsible" [calendarItems]="calendarItems"
                    (selectedDayItemChanged)="onSessionSelectionChanged($event?.item)"
                    (selectedDayChanged)="onDaySelectionChanged($event)" [selectedDay]="selectedSession?.plannedDate"
                    (droppedOnCalendarDay)="onDroppedOnCalendarDay($event)" [startDate]="trainingPlan.startDate"
                    [endDate]="endDate" (dragOverCalendarDay)="dragOverCalendarDay($event[0], $event[1])"
                    [draggingEnabled]="draggingSession" (currentMonthChanged)="onSelectedCalendarMonthChanged($event)"
                    [isBusy]="loadingTrackedTrainingSessions"></app-monthly-calendar>
            </div>
        </div>
    </div>

</div>
<ngx-spinner name="trainingPlanSpinner" bdColor="rgba(0,0,0,0.33)" size="default" color="var(--accentColor)"
    type="ball-clip-rotate" [fullScreen]="true">
    <div *ngIf="spinnerText" class="spinner-overlaytext" [innerHTML]="spinnerText"></div>
</ngx-spinner>