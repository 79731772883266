import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { MergedTrainingExercise } from 'src/app/model/training-exercise';
import { TrainingService } from 'src/app/services/training.service';
import { SingleExerciseComponent } from '../single-exercise/single-exercise.component';

@Component({
  selector: 'app-exercises-table-dialog',
  templateUrl: './exercises-table-dialog.component.html',
  styleUrls: ['./exercises-table-dialog.component.css']
})
export class ExercisesTableDialogComponent implements OnInit {

  spinnerText: string = "";
  constructor(public dialogRef: MatDialogRef<ExercisesTableDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {}, private trainingService: TrainingService, private spinner: NgxSpinnerService, public dialog: MatDialog) 
  { 
  }

  ngOnInit(): void {
  }

  getTrainingExercises() {
    return this.trainingService.MergedTrainingExercises
  }

  onCancelSelection(){
    this.onCloseDialog(false);
  }

  // onTakeSelection(selectedExercise: MergedTrainingExercise){
  //   this.onCloseDialog(true, selectedExercise)
  // }

  public selectedTrainingExercises: MergedTrainingExercise[] = [];
  onMultiSelectionChanged(selectedExercises: MergedTrainingExercise[]){
    this.selectedTrainingExercises = selectedExercises;
  }

  onTakeSelection(selectedExercises: MergedTrainingExercise[]){
    this.onCloseDialog(true, selectedExercises)
  }

  onCloseDialog(shouldTake: boolean, selectedExercises?: MergedTrainingExercise[]){
    this.dialogRef.close({shouldTake: shouldTake, exercises: selectedExercises})
  }

  onCreateExercise(){
    var selectedTrainingExercise = new MergedTrainingExercise();
    selectedTrainingExercise.creatorUid = this.trainingService.UserUid
    const dialogRef = this.dialog.open(SingleExerciseComponent, { data: { selectedExercise: selectedTrainingExercise, editExerciseDisabled: this.trainingService.UserUid != selectedTrainingExercise.creatorUid, overwriteExerciseDisabled: this.trainingService.AdministratorEditModeActivated}, width: '1000px'})
    dialogRef.afterClosed().subscribe(async result => {
      if(result){
        if(result.shouldSave){
          this.spinner.show()
          var exercise = result.exercise as MergedTrainingExercise
          if(exercise) 
          {
            if(result.shouldDelete) 
            {
              exercise.deleted = true
              selectedTrainingExercise = null
            }
            else {
              this.saveMergedTrainingExercise(exercise, result.newThumbnail, result.newVideo, result.newExercisePreview)
            }
          }
          this.spinner.hide()
        }
      }
    })
  }

  saveMergedTrainingExercise(exercise:MergedTrainingExercise, newThumbnail:any, newVideo:any, newExercisePreview:any){
    this.trainingService.insertTrainingExercise(exercise, newThumbnail, newVideo, newExercisePreview)

  }
}
