export class NutritionalGoal {
    date: Date;
    calories: number;
    carbohydrates: number;
    protein: number;
    fat: number;
    adjustWithActivityCalories: boolean;
    applicableWeekdays: string[];
    name: string
    isOverwrite: boolean
    goalId: string
    applied: boolean

    constructor();
    constructor(init: NutritionalGoal);
    constructor(init?: NutritionalGoal) {
        this.date = init && init.date || new Date()
        this.goalId = init && init.goalId || null
        this.carbohydrates = init && init.carbohydrates || 0;
        this.protein = init && init.protein || 0;
        this.fat = init && init.fat || 0;
        this.calories = init && init.calories || 0;
        this.adjustWithActivityCalories = init && init.adjustWithActivityCalories || false
        this.applicableWeekdays = init && init.applicableWeekdays || ["all"]
        this.name = init && init.name || null
        this.isOverwrite = init && init.isOverwrite || false
        this.applied = init && init.applied != null ? init.applied : null
    }

    isValid(): boolean {
        return this.carbohydrates != undefined && this.protein != undefined && this.fat != undefined && this.calories != undefined && this.carbohydrates > 0 && this.protein > 0 && this.fat > 0 && this.calories > 0
    }
    hasValues(): boolean {
        return this.carbohydrates != undefined && this.protein != undefined && this.fat != undefined && this.calories != undefined
    }

    addWeekday(day: string) {
        if (!this.applicableWeekdays.includes(day)) this.applicableWeekdays.push(day)
    }
    removeWeekday(day: string) {
        this.applicableWeekdays.forEach( (item, i) => {
            if (item === day) {
              this.applicableWeekdays.splice(i, 1)
            }
        });
    }

    clone(): NutritionalGoal {
        var nutritionalGoal = new NutritionalGoal()
        nutritionalGoal.date = this.date
        nutritionalGoal.calories = this.calories
        nutritionalGoal.carbohydrates = this.carbohydrates
        nutritionalGoal.protein = this.protein
        nutritionalGoal.fat = this.fat
        nutritionalGoal.adjustWithActivityCalories = this.adjustWithActivityCalories
        nutritionalGoal.applicableWeekdays = this.applicableWeekdays
        nutritionalGoal.name = this.name
        nutritionalGoal.isOverwrite = this.isOverwrite
        nutritionalGoal.goalId = this.goalId
        nutritionalGoal.applied = this.applied
        return nutritionalGoal
    }

    public static WEEKDAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
}