import { ShortWeekDayStringsToNumberMapping, NumberToShortWeekDayStringsMapping, ShortWeekDayStrings } from './../../model/task.model';
import { browser } from 'protractor';
import { Component, Injector, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { filter, combineLatest } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { Label2RepetitionMapping, Repetition, Repetition2LabelMapping } from 'src/app/model/automatic-push-notification.model';
import { Label2PriorityMapping, Priority, Priority2LabelMapping, SubTask, Task } from 'src/app/model/task.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { TaskManagementService } from 'src/app/services/task-management.service';
import * as e from 'cors';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { RadioButtonsConfirmationDialogComponent } from 'src/app/dialogs/radio-buttons-confirmation-dialog/radio-buttons-confirmation-dialog.component';
import { TaskManagementBase } from '../task-management-base';
import { Coach } from 'src/app/model/coach.model';
import { LanguageService } from 'src/app/services/language.service';
import { group } from 'console';

@Component({
  selector: 'app-task-management',
  templateUrl: './task-management.component.html',
  styleUrls: ['./task-management.component.css'],
  animations: [
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(10px)' }),
        animate('500ms', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        animate('500ms', style({ opacity: 0, transform: 'translateY(10px)' })),
      ]),
    ]),
    trigger('fadeSlideGrowKeyframe', [
      transition(':enter', [
          style({ opacity: 0, transform: 'scale(0.5) translateY(50px)' }),
          animate(
              '500ms',
              keyframes([
                  style({ opacity: 1, offset: 0.3 }),
                  style({ transform: 'translateY(0)', offset: 0.6 }),
                  style({ transform: 'scale(1)', offset: 1 }),
              ])
          ),
      ]),
      transition(':leave', [
          animate(
              '500ms',
              keyframes([
                  style({ transform: 'scale(1)', offset: 0.3 }),
                  style({ transform: 'translateY(50px)', offset: 0.6 }),
                  style({ opacity: 0, transform: 'scale(0.5)', offset: 1 }),
              ])
          ),
      ]),
    ]),
      trigger('enterExitRight', [
          transition(':enter', [
              style({ opacity: 0, transform: 'translateX(200px)' }),
              animate(
                  '300ms ease-in',
                  style({ opacity: 1, transform: 'translateX(0)' })
              ),
          ]),
          transition(':leave', [
              animate(
                  '300ms ease-in',
                  style({ opacity: 0, transform: 'translateX(200px)' })
            ),
          ]),
    ]),
    trigger('leaveToTop', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(200px)' }),
        animate('300ms', style({ opacity: 1, transform: 'translateY(0)', })),
      ]),
      transition(':leave', [
        animate('300ms', style({ opacity: 0, transform: 'translateY(-200px)' })),
      ]),
    ]),
    trigger('leaveToBottom', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(200px)' }),
        animate('200ms', style({ opacity: 1, transform: 'translateY(0)', })),
      ]),
      transition(':leave', [
        animate('200ms', style({ opacity: 0, transform: 'translateY(200px)' })),
      ]),
    ]),
    trigger('closeToBottom', [
      state('false', style({ opacity: 1 })),
      state('true', style({ opacity: 0 })),
      transition('false => true', animate('300ms', style({ opacity: 0, transform: 'translateY(200px)' }))),
      transition('true => false', animate('2000ms ease-out'))
    ]),
  ]
})
export class TaskManagementComponent extends TaskManagementBase implements OnInit  {
  public coaches: Coach[] = [];

  public showDoneTasks: boolean = false;
  public selectedUserName: string = null;

  public repetitions = Object.values(Repetition).filter(value => typeof value === 'string');
  public repetitionLabels = Object.values(Repetition2LabelMapping).filter(value => typeof value === 'string');
  public repetition2LabelMapping = Repetition2LabelMapping;
  public label2RepetitionMapping = Label2RepetitionMapping;
  public Repetition = Repetition;

  public priorities = Object.values(Priority).filter(value => typeof value === 'string');
  public priorityLabels = Object.values(Priority2LabelMapping).filter(value => typeof value === 'string');
  public priority2LabelMapping = Priority2LabelMapping;
  public label2PriorityMapping = Label2PriorityMapping;

  public shortWeekDayStringsToNumberMapping = ShortWeekDayStringsToNumberMapping;
  public numberToShortWeekDayStringsMapping = NumberToShortWeekDayStringsMapping;
  public shortWeekDayStrings = ShortWeekDayStrings;

  constructor(taskManagementService: TaskManagementService, dialog: MatDialog, spinner: NgxSpinnerService, toastr: ToastrService, userService: FirestoreService, private router: Router, public languageService: LanguageService) {
    super(taskManagementService, toastr, dialog, userService, spinner);
  }

  ngOnInit() {
    // this.setNewSelectedTask();
    this.showAllTasks = true;
    this.loadTasks();
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event) => {
      let navRoute = event as NavigationEnd;
      let params = navRoute.url.split('/');
      if(params.length > 2 && params[1] == 'client'){
        let uid = params[2];
        let user = this.getSelectableClients().find(client => client.uid == uid);
        if(user != null){
          this.selectedUserName = user.getName();
          this.selectedUserUid = uid;
          if(this.selectedUserUid){
            this.setConnectedUser();
          }
        }
      }
      else {
        this.showAllTasks = true;
        this.selectedUserUid = null;
        this.selectedUserName = null;
      }
    });

    this.userService.getAllCoachesByLicenceHolderUid(this.userService.getLoggedInUser().licenceHolderUid).subscribe(coaches => {
      try {
        this.coaches = [];
        for(let coach of coaches){
          if(this.loggedInCoach?.canAccessCoachClients(coach)){
            this.coaches.push(coach);
          }
        }
      }
      catch(ex){
        console.error(ex);
      }
    })
  }


  onTargetSelectionChangedAssignedCoach(task: Task, uid: string){
    task.assignedCoachUid = uid;
  }

  getPrintableAssignedCoachName(task: Task): string {
    if(!task.assignedCoachUid){
      this.loggedInCoach?.uid;
    };
    return this.coaches?.find(coach => coach.uid == task.assignedCoachUid)?.name;
  }

  onSelectedCoachChanged(coach: Coach){
    this.selectedCoach = coach;
    this.setVisibleTasks();
    this.setFilterableTags();
    this.setFilterableClients();
    this.setFilterableClientGroups();
  }

  onTargetSelectionChangedConnectedUser(task: Task, uid: string){
    task.connectedClientUid = uid;
    task.connectedClientGroup = null;
  }

  onTargetSelectionChangedConnectedClientGroup(task: Task, clientGroup: string){
    task.connectedClientGroup = clientGroup;
    task.connectedClientUid = null;
  }

  onPrioritySelectionChanged(task: Task, priority: string){
    task.priority = this.label2PriorityMapping[priority];
  }

  setConnectedUser() {
    if(this.taskManagementService.selectedTask){
      this.taskManagementService.selectedTask.connectedClientUid = this.selectedUserUid;
    }
  }

  onSelectShowAll(value: boolean){
    this.showAllTasks = value;
    this.setVisibleTasks();
  }

  onShowDoneTasks(value: boolean){
    this.showDoneTasks = value;
  }


  onShowTaskManagement(){
    this.sortTasks();
    this.taskManagementService.showTaskManagementSideBar();
  }

  onHideTaskManagement(){
    this.taskManagementService.hideTaskManagementSideBar();
  }


  onCancelEdit(){
    this.taskManagementService.selectedTask = null;
    this.taskEditCanceled = true;
  }

  onCreateTask(){
    this.setNewSelectedTask();
  }

  setDescription(task: Task, description: string){
    task.description = description;
  }

  onRepetitionMultiplierChanged(task: Task, multiplier: string){
    task.repetitionMultiplier = parseInt(multiplier, 10);
  }

  isRepetitionMultiplierValid(task: Task){
    return task?.repetition !==  Repetition.everyXDays || (task?.repetitionMultiplier >= 1 && task?.repetitionMultiplier <= 3000);
  }

  onRepetitionSelectionChanged(task: Task, rep: string){
    task.repetition = this.label2RepetitionMapping[rep].toString();
  }

  getDueDate(task: Task): string {
    if(!task.dueDate){
      return '';
    }
    return this.toDateString(task.dueDate);
  }

  getDueDateTimeForMask(task: Task): string {
    if(!task.dueDate){
      return '';
    }
    return this.toMaskDateTimeString(task.dueDate);
  }

  getDueTime(task: Task): string {
    if(!task.dueDate){
      return '';
    }
    return this.toTimeString(task.dueDate);
  }
  getDueDateTime(task: Task): string {
    if(!task.dueDate){
      return '';
    }
    return this.toDateTimeString(task.dueDate);
  }

  onDueDateChanged(task: Task, value: string){
    if(value == null || value == '') task.dueDate = null;
    if(!task.dueDate){
      task.dueDate = new Date();
    }
    task.dueDate = this.parseDateString(value + 'T' + this.getDueTime(task));
  }

  onDueTimeChanged(task: Task, value: string){
    if(value == null || value == '') task.dueDate = null;
    if(!task.dueDate){
      task.dueDate = new Date();
    }
    task.dueDate = this.parseDateString(this.getDueDate(task) + 'T' + value);
    // task.dueDate = this.parseDateString(value);
  }

  onDueMaskInputChanged(task: Task, value: string){
    if(value == null || value == '') task.dueDate = null;
    if(value.length < 16) return;
    task.dueDate = this.parseMaskDateTimeString(value);
  }

  onDueDateTimeChanged(task: Task, value: string){
    if(value == null || value == '') task.dueDate = null;
    if(!task.dueDate){
      task.dueDate = new Date();
    }
    task.dueDate = this.parseDateString(value);
  }

  onDueDatepickerChanged(task: Task, date: Date){
    if(task.dueDate != null){
      date.setHours(task.dueDate.getHours());
      date.setMinutes(task.dueDate.getMinutes());
    }
    task.dueDate = date;
    task.dueDate.setHours(8);
  }

  private toMaskDateTimeString(date: Date): string {
    return (("0" + date.getDate().toString()).slice(-2) + '.'
        + ("0" + (date.getMonth() + 1)).slice(-2) + '.' 
        + date.getFullYear().toString())
        + ' ' + date.toTimeString().slice(0,5);
  }

  private toDateTimeString(date: Date): string {
    return (date.getFullYear().toString() + '-' 
       + ("0" + (date.getMonth() + 1)).slice(-2) + '-' 
       + ("0" + (date.getDate())).slice(-2))
       + 'T' + date.toTimeString().slice(0,5);
  }

  private toDateString(date: Date): string {
    return (date.getFullYear().toString() + '-' 
       + ("0" + (date.getMonth() + 1)).slice(-2) + '-' 
       + ("0" + (date.getDate())).slice(-2));
  }

  private toTimeString(date: Date): string {
    return date.toTimeString().slice(0,5);
  }

  private parseDateString(date:string): Date {
    if(date == null || date == '') return null;
    console.log(date)
    date = date?.replace('T','-');
    var parts = date?.split('-');
    var timeParts = parts[3]?.split(':');
    return new Date(+parts[0], +parts[1]-1, +parts[2], +timeParts[0], +timeParts[1]);
 }

 private parseMaskDateTimeString(date:string): Date {
  if(date == null || date == '') return null;
  try{
    var parts = date?.split('.');
    var spaceSplit = parts[2]?.split(' ');
    var timeParts = spaceSplit[1]?.split(':');
    return new Date(+spaceSplit[0], +parts[1]-1, +parts[0], +timeParts[0], +timeParts[1]);
  }
  catch(ex){
    console.error(ex);
  }
 }

 private isMaskInputValid(date: string): boolean {
  if(date.length < 16) return false;
  return true;
 }
}
