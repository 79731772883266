import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmailTemplate } from 'src/app/model/payment-settings.model';

@Component({
  selector: 'app-email-template-dialog',
  templateUrl: './email-template-dialog.component.html',
  styleUrls: ['./email-template-dialog.component.css']
})
export class EmailTemplateDialogComponent {

  template: EmailTemplate
  type: string

  constructor(public dialogRef: MatDialogRef<EmailTemplateDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { template: EmailTemplate, type: string }) {
    this.template = data.template
    this.template.body = this.template.body.replaceAll('<br>', '\n')
    this.type = data.type
  }

  ngOnInit(): void {
  }

  onBodyChanged(text: string) {
    this.template.body = text
  }

  onCancelDialog() {
    this.dialogRef.close({template: null});
  }
  onConfirmDialog() {
    this.template.body = this.template.body.replaceAll('\n', '<br>').replaceAll('  ', '<br>')
    this.dialogRef.close({template: this.template});
  }
}
