import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CardioZone } from 'src/app/model/cardio-zone-group.model';

@Component({
  selector: 'app-global-cardio-zone-editor',
  templateUrl: './global-cardio-zone-editor.component.html',
  styleUrls: ['./global-cardio-zone-editor.component.css']
})
export class GlobalCardioZoneEditorComponent {
  @Input() zones: CardioZone[] = [];

  public namePattern: string = CardioZone.namePattern;

  addCardioZone(){
    let zone = new CardioZone();
    zone.name = "Zone " + this.zones.length;
    this.zones.push(zone);
  }

  setName(value: string, index: number){
    this.zones[index].name = value;
  }

  removeCardioZone(zone: CardioZone){
    this.zones.splice(this.zones.indexOf(zone), 1);
  }

  getCardioZonesHintText(){
    return " In diesem Fenster kannst du die Anzahl und Namen der unterschiedlichen Belastungszonen festlegen, die dann für jeden Coachee übernommen werden. Die Definition der Zonenwerte bpm und Pace kannst du dann in den Trainingsvariablen des Coachees direkt vornehmen.";
  }
}
