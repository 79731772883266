<div class="input margin-0 {{SearchInputFocused ? 'focused' : ''}}  {{disabled ? 'disabled' : ''}}" id="wrapper-search-input-select" (click)="onFocusRestrictionSearchInput();searchInput.focus();$event.stopPropagation()">
    <ng-container *ngFor="let item of selectedItems">
        <div class="chip white tag">
            <div *ngIf="translatable;else notTranslatableContent">
                {{item.GetValue(LanguageCode)}}
            </div>
            <ng-template #notTranslatableContent>{{item.originObject.toString()}}</ng-template>
            <div class="delete-tag" (click)="onRemoveItemFromSelectedItems(item);$event.stopPropagation()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </div>
        </div>
    </ng-container>
    <input #searchInput id="search-input" autocomplete="off" [disabled]="disabled" (keyup.enter)="onInputEnter()" (focusout)="onInputEnter()" class="input tag plain restrictionsearch-input" (input)="onSearchInputChanged($event.target.value);$event.stopPropagation()" [(ngModel)]="searchInputValue" placeholder="Suchen..." type="text">
</div>
<div id="search-results" class="layout-box sub-box margin-0" *ngIf="FilteredSelectableItems?.length > 0 && SearchInputFocused">
    <div *ngFor="let item of FilteredSelectableItems" class="search-result-item">
        <div class="row">
            <div (click)="onSearchResultSelected(item);searchInput.value = '';searchInput.focus()" class="link p-1">
                <div *ngIf="translatable;else notTranslatableSearchResult">
                    {{item.GetValue(LanguageCode)}}
                </div>
                <ng-template #notTranslatableSearchResult>{{item.originObject.toString()}}</ng-template>
            </div>
        </div>
    </div>
</div>