<div class="dialog-topbar">
    <div class="icon-button float-right" (click)="onCloseDialog()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        Schließen
    </div>
</div>
<div class="dialog-content-wrapper">
    <!--div class="tab-selection textalign-center">
        <button class="button tab-button" (click)="onSelectTemplateTab('OWN')">
            Eigene Vorlagen
            <div *ngIf="selectedTemplateTab == 'OWN'" class="underline"></div>
        </button>
        <button class="button tab-button" (click)="onSelectTemplateTab('NUTRILIZE')">
            nutrilize Vorlagen
            <div *ngIf="selectedTemplateTab == 'NUTRILIZE'" class="underline"></div>
        </button>
    </div>
    <div class="spacer-20"></div-->
    <div class="row">
        <div class="col-12">
            <div class="spacer-10"></div>
            <input id="templatesearch-input" class="input wide" (input)="onTemplateSearchInputChanged($event.target.value)" placeholder="Vorlagen durchsuchen" type="text">
            <div class="icon-button deletesearch-icon delete-recipesearch" (click)="onDeleteTemplateSearchInput()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </div>
            <div class="spacer-25"></div>
        </div>
    </div>
    <div *ngFor="let template of filteredTrainingPlanTemplates" class="col-12">
        <ng-container *ngIf="(selectedTemplateTab === 'OWN' && template.licenceHolderUid !== 'nutrilize') || (selectedTemplateTab === 'NUTRILIZE' && template.licenceHolderUid === 'nutrilize')">
            <div class="row layout-box sub-box link" (click)="selectTemplate(template)">
                <div class="checkbox-wrapper">
                    <input class="input checkbox" type="checkbox" [checked]="selectedTemplates.includes(template)">
                </div>
                <div class="col">
                    <div class="row">
                        <div class="col">
                            {{template.nameTranslation.GetValue(languageService.selectedLanguageCode)}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto">
                            <div class="label">{{template.subNameTranslation.GetValue(languageService.selectedLanguageCode)}}</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto">
                            <div *ngIf="getPrintableTemplateFolderNames(template)" class="label template-folder-names">
                                <div class="spacer-10"></div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-folder" viewBox="0 0 16 16">
                                    <path d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z"/>
                                </svg>
                                {{getPrintableTemplateFolderNames(template)}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="spacer-50"></div>
</div>
<div class="dialog-bottombar" mat-dialog-actions>
    <div class="label col-12">
        <span *ngFor="let template of selectedTemplates; let isLast=last">
            {{template.name}}{{isLast ? '' : ', '}}
        </span>
    </div>
    <button *ngIf="selectedTemplates?.length > 0" class="button-filled small select-exercises" (click)="onTakeSelection(selectedTemplates)">Auswahl übernehmen</button>
</div>