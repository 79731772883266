import { UtilityService } from './services/utility.service';
import { Component, EventEmitter, Injectable, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { FirestoreNutritionPlanService } from "./services/firestore-nutritionplan.service";
import { Microphone } from '@mozartec/capacitor-microphone';
import { Capacitor } from '@capacitor/core';
import { WavHeader, Mp3Encoder } from "lamejs"
import { KeepAwake } from '@capacitor-community/keep-awake';
import { BehaviorSubject } from 'rxjs';


export class AudioRecorder{
    private startTime: Date = null
    private mediaRecorder: MediaRecorder = null
    private capturingStream: MediaStream = null

    private maxSeconds: number = 0
  
    // @Output() reachedMaxSeconds = new EventEmitter<number>()
    reachedMaxSeconds: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  
    constructor(maxSeconds: number, public toastr: ToastrService = null, public utilityService: UtilityService){
      this.maxSeconds = maxSeconds
    }
  
    private isRecording: boolean = false
    public IsRecording():boolean{
      return this.isRecording && (Capacitor.getPlatform() != 'web' || this.mediaRecorder?.state === 'recording');
      // return ((Capacitor.getPlatform() != 'web' || this.mediaRecorder?.state === 'recording') && this.isRecording === true)
      // return this.isRecording;
    }
  
    public GetTime():number {
      if(this.startTime){
        var seconds = ((new Date).getTime() - this.startTime.getTime()) / 1000;
        return seconds;
      }
      else return 0
    }
  
    public elapsedSeconds: number = 0
    public printableSeconds: string = "00:00"
  
    private tick() {
      this.elapsedSeconds += 1
      this.printableSeconds = new Date(this.elapsedSeconds * 1000).toISOString().substring(14, 19)
      if(this.maxSeconds > 0 && this.elapsedSeconds >= this.maxSeconds) {
        // this.reachedMaxSeconds.emit(this.elapsedSeconds)
        this.reachedMaxSeconds.next(this.elapsedSeconds)
      }
    }
  
    private resetTime() {
      this.elapsedSeconds = 0
      this.printableSeconds = "00:00"
    }
  
    private interval: NodeJS.Timeout;

    public async Start(){
      if(this.IsRecording()) return
      this.isRecording = true
      this.resetTime()

      if(Capacitor.getPlatform() != 'web'){
        try{
          const checkPermissionsResult = await Microphone.checkPermissions();
          if(checkPermissionsResult.microphone !== 'granted') {
            var requestResult = await Microphone.requestPermissions();
            if(requestResult.microphone === 'granted') {
              this.isRecording = false;
              return;
            }
          }
          await Microphone.startRecording();
        }
        catch(error) {
          this.isRecording = false
          console.error(error);
          if (error.name === 'PermissionDeniedError' || error.name === 'NotAllowedError') {
            this.toastr.error("Fehler: Berechtigung zum Zugriff auf das Mikrofon wurde nicht erteilt.", "Aufnahme nicht möglich",  {
                positionClass: 'toast-bottom-center'
            });
          }
          else {
            this.toastr.error("Es ist ein unbekannter Fehler aufgetreten.", "Aufnahme nicht möglich",  {
            positionClass: 'toast-bottom-center'
            });
          }
          return;
        }
      }
      else {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          try{
            let stream = await navigator.mediaDevices.getUserMedia({
              audio:  {
                sampleRate: 44100,
                autoGainControl: true,
            },
              video: false
            });
            this.elapsedSeconds = 0
            this.capturingStream = stream
            const options = {
              audioBitsPerSecond: 64000,
            };  
            this.mediaRecorder = new MediaRecorder(stream, options)
            this.mediaRecorder.start()
          }
          catch(error) {
            this.isRecording = false
            console.error(error.name);
            if (error.name === 'PermissionDeniedError' || error.name === 'NotAllowedError') {
                this.toastr.error("Fehler: Berechtigung zum Zugriff auf das Mikrofon wurde nicht erteilt.", "Aufnahme nicht möglich",  {
                    positionClass: 'toast-bottom-center'
                });
            }
            else {
              this.toastr.error("Es ist ein unbekannter Fehler aufgetreten.", "Aufnahme nicht möglich",  {
              positionClass: 'toast-bottom-center'
              });
            }
            return;
          }
        }
        else {
          this.toastr.error("Audio-Aufnahmen werden in deinem Browser nicht unterstützt.", "Aufnahme nicht möglich",  {
            positionClass: 'toast-bottom-center'
            });
            return;
        }
      }

      this.startTime = new Date()
      if(this.interval != null) clearTimeout(this.interval)
      this.interval = setInterval(() => {
              this.tick()
            }, 1000);
      this.isRecording = true
    }

    private ResetRecorder(){
      if(this.interval != null) clearTimeout(this.interval)
      this.isRecording = false
      this.capturingStream = null
      this.mediaRecorder = null
    }
  
    public async Cancel(){
      try {
        if(Capacitor.getPlatform() != 'web'){
          if(this.IsRecording()) {
            this.ResetRecorder();
            await Microphone.stopRecording();
          }
        }
        else {
          if(this.mediaRecorder && this.mediaRecorder?.state !== 'inactive') this.mediaRecorder.stop()
          this.ResetRecorder();
          this.stopStream()
          this.resetTime()
        }
      }
      catch(error) {
        console.error(error);
      }
    }
  
    private blobs: Blob[] = []
    public async Stop():Promise<File>{
      if(this.interval != null) clearTimeout(this.interval)
      if(Capacitor.getPlatform() != 'web'){
        try {
          if(!this.IsRecording()) return null;
          let recording = await Microphone.stopRecording();
          let base64 = recording.base64String;
          let blob = this.base64ToBlob(base64, 'audio/wav');
          let wavBlob = await getWaveBlob(blob, false, {latencyHint: "interactive", sampleRate: 44100});
          let wavBlobBuffer = await wavBlob.arrayBuffer();
          var wav = WavHeader.readHeader(new DataView(wavBlobBuffer));
          var samples = new Int16Array(wavBlobBuffer, wav.dataOffset, wav.dataLen / 2);
          let sampleRate = wav.channels === 2 ? wav.sampleRate * 2 : wav.sampleRate
          let file = this.convertToMP3(samples, sampleRate);
          this.ResetRecorder();
          return file;
        }
        catch(error) {
          this.toastr.error("", "Fehler bei Stoppen der Aufnahme",  {
            positionClass: 'toast-bottom-center'
          });
          return null;
        }
      }
      else {
        let filePromise = new Promise<File>(resolve => {
          this.mediaRecorder.ondataavailable = async (e) => {
            this.blobs.push(e.data)
            if(this.mediaRecorder?.state === 'inactive'){
              let wavBlob = await getWaveBlob(e.data, false, {latencyHint: "interactive", sampleRate: 44100})
              let wavBlobBuffer = await wavBlob.arrayBuffer();
              var wav = WavHeader.readHeader(new DataView(wavBlobBuffer));
              var samples = new Int16Array(wavBlobBuffer, wav.dataOffset, wav.dataLen / 2);
              let sampleRate = wav.channels === 2 ? wav.sampleRate * 2 : wav.sampleRate
              let file = this.convertToMP3(samples, sampleRate);
              this.ResetRecorder()
              resolve(file)
            }
        }})
        if(this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
          this.mediaRecorder.stop();
        }
        this.stopStream()
        return filePromise
      }

    }

    private stopStream() {
      this.capturingStream?.getTracks()?.forEach(x => x.stop())
    }

    private base64ToBlob(base64: string, type: string) {
      var binStr = atob(base64);
      var len = binStr.length;
      var arr = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i);
      }
      return new Blob([arr], { type: type });
    }

    public getWavFile(blob: Blob):Promise<File>{
      return new Promise<File>(resolve => {
        getWaveBlob(blob, false, {latencyHint: "interactive", sampleRate: 44100}).then(async (wavBlob) => {
          let wavBlobBuffer = await wavBlob.arrayBuffer();
          var wav = WavHeader.readHeader(new DataView(wavBlobBuffer));
          var samples = new Int16Array(wavBlobBuffer, wav.dataOffset, wav.dataLen / 2);
          let sampleRate = wav.channels === 2 ? wav.sampleRate * 2 : wav.sampleRate
          let file = this.convertToMP3(samples, sampleRate);
          resolve(file)
        })
      })
    }

    public convertToMP3(samples:Int16Array, sampleRate:number = 48000):File{
      var kbps = 128;
      var channels = 1;
      var mp3encoder = new Mp3Encoder(channels, sampleRate, kbps);
      var sampleBlockSize = 1152; // use multiple of 576
      var mp3Data = [];
      for (var i = 0; i < samples.length; i += sampleBlockSize) {
        var sampleChunk = samples.subarray(i, i + sampleBlockSize);
        var mp3buf = mp3encoder.encodeBuffer(sampleChunk);
        if (mp3buf.length > 0) {
            mp3Data.push(mp3buf);
        }
      }
      var mp3buf = mp3encoder.flush(); //finish writing mp3
      if (mp3buf.length > 0) {
          mp3Data.push(new Int8Array(mp3buf));
      }
      var blob = new Blob(mp3Data, {type: 'audio/mp3'});
      return new File([blob], "audio-" + FirestoreNutritionPlanService.generateUniqueString() + ".mp3")
    }
}

function writeStringToArray(aString, targetArray, offset) {
  for (let i = 0; i < aString.length; ++i)
      targetArray[offset + i] = aString.charCodeAt(i);
}

function writeInt16ToArray(aNumber, targetArray, offset) {
  aNumber = Math.floor(aNumber);
  targetArray[offset + 0] = aNumber & 255;          // byte 1
  targetArray[offset + 1] = (aNumber >> 8) & 255;   // byte 2
}

function writeInt32ToArray(aNumber, targetArray, offset) {
  aNumber = Math.floor(aNumber);
  targetArray[offset + 0] = aNumber & 255;          // byte 1
  targetArray[offset + 1] = (aNumber >> 8) & 255;   // byte 2
  targetArray[offset + 2] = (aNumber >> 16) & 255;  // byte 3
  targetArray[offset + 3] = (aNumber >> 24) & 255;  // byte 4
}

function floatBits(f) {
  const buf = new ArrayBuffer(4);
  (new Float32Array(buf))[0] = f;
  const bits = (new Uint32Array(buf))[0];
  return bits | 0;
}

function writeAudioBufferToArray(
  audioBuffer,
  targetArray,
  offset,
  bitDepth
) {
  let index = 0, channel = 0;
  const length = audioBuffer.length;
  const channels = audioBuffer.numberOfChannels;
  let channelData, sample;

  for (index = 0; index < length; ++index) {
      for (channel = 0; channel < channels; ++channel) {
          channelData = audioBuffer.getChannelData(channel);
          if (bitDepth === 16) {
              sample = channelData[index] * 32768.0;
              if (sample < -32768)
                  sample = -32768;
              else if (sample > 32767)
                  sample = 32767;
              writeInt16ToArray(sample, targetArray, offset);
              offset += 2;
          } else if (bitDepth === 32) {
              sample = floatBits(channelData[index]);
              writeInt32ToArray(sample, targetArray, offset);
              offset += 4;
          } else {
              console.log('Invalid bit depth for PCM encoding.');
              return;
          }

      }
  }
}
async function getAudioBuffer(blobData, contextOptions = undefined) {
  let blob = blobData;
  if (!(blob instanceof Blob)) blob = new Blob([blobData]);
  const url = URL.createObjectURL(blob);
  const response = await fetch(url);
  const arrayBuffer = await response.arrayBuffer();
  const audioContext = new AudioContext(contextOptions);
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer)
  return audioBuffer;
}

/**
* 
* @param {Blob | Blob[]} blobData - Blob or Blob[]
* @param {boolean} as32BitFloat - Convert to 16-bit or 32-bit, default 16-bit
* @param {AudioContextOptions} contextOptions
* @returns 
*/
async function getWaveBlob(blobData, as32BitFloat, contextOptions = undefined) {
  const audioBuffer = await getAudioBuffer(blobData, contextOptions);

  // Encoding setup.
  const frameLength = audioBuffer.length;
  const numberOfChannels = audioBuffer.numberOfChannels;
  const sampleRate = audioBuffer.sampleRate;
  const bitsPerSample = as32BitFloat ? 32 : 16;
  const bytesPerSample = bitsPerSample / 8;
  const byteRate = sampleRate * numberOfChannels * bitsPerSample / 8;
  const blockAlign = numberOfChannels * bitsPerSample / 8;
  const wavDataByteLength = frameLength * numberOfChannels * bytesPerSample;
  const headerByteLength = 44;
  const totalLength = headerByteLength + wavDataByteLength;
  const waveFileData = new Uint8Array(totalLength);
  const subChunk1Size = 16;
  const subChunk2Size = wavDataByteLength;
  const chunkSize = 4 + (8 + subChunk1Size) + (8 + subChunk2Size);

  writeStringToArray('RIFF', waveFileData, 0);
  writeInt32ToArray(chunkSize, waveFileData, 4);
  writeStringToArray('WAVE', waveFileData, 8);
  writeStringToArray('fmt ', waveFileData, 12);
  writeInt32ToArray(subChunk1Size, waveFileData, 16);
  writeInt16ToArray(as32BitFloat ? 3 : 1, waveFileData, 20);
  writeInt16ToArray(numberOfChannels, waveFileData, 22);
  writeInt32ToArray(sampleRate, waveFileData, 24);
  writeInt32ToArray(byteRate, waveFileData, 28);
  writeInt16ToArray(blockAlign, waveFileData, 32);
  writeInt32ToArray(bitsPerSample, waveFileData, 34);
  writeStringToArray('data', waveFileData, 36);
  writeInt32ToArray(subChunk2Size, waveFileData, 40);
  writeAudioBufferToArray(audioBuffer, waveFileData, 44, bitsPerSample);

  return new Blob([waveFileData], {
      type: 'audio/wave'
  });
}
