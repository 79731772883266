import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Moment } from 'moment';

@Component({
  selector: 'app-timerange-selection-dialog',
  templateUrl: './timerange-selection-dialog.component.html',
  styleUrls: ['./timerange-selection-dialog.component.css']
})
export class TimerangeSelectionDialogComponent {
  public message: string
  public startDate: Date
  public endDate: Date
  public showEndDate: boolean = true

  startDateFilter = (d: Moment | null): boolean => {
    return true
  }

  constructor(public dialogRef: MatDialogRef<TimerangeSelectionDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { message: string, startDate: Date, endDate: Date, showEndDate: boolean, startDateFilter: (d: Moment | null) => boolean }) {
    this.message = data.message
    this.startDate = data.startDate
    this.endDate = data.endDate
    this.showEndDate = data.showEndDate != null ? data.showEndDate : true
    if (data.startDateFilter) this.startDateFilter = data.startDateFilter
  }

  ngOnInit(): void {
  }

  onStartDateChanged(date: Date) {
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    this.startDate = date
  }
  onEndDateChanged(date: Date) {
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    this.endDate = date
  }
  onCancelDialog() {
    this.dialogRef.close(null);
  }
  onConfirmDialog() {
    if (this.showEndDate && this.startDate > this.endDate) {
      var tmp = this.startDate
      this.startDate = this.endDate
      this.endDate = tmp
    }
    this.dialogRef.close({ startDate: this.startDate, endDate: this.endDate });
  }
}
