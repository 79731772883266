import { NutritionalValueHolder } from './basenutritionfact.model';
import { Meal } from './meal.model';
import { PlannedMeal } from './plannedmeal.model';

export class Day implements NutritionalValueHolder{
    id: string;
    number: number;
    date: Date;
    plannedMeals: PlannedMeal[]

    constructor();
    constructor(init: Day);
    constructor(init?: Day) {
        this.id = init && init.id || null
        this.number = init && init.number || 0
        this.date = init && init.date || null
        this.plannedMeals = init && init.plannedMeals || []
    }
    
    getNutritionalValue(nutritionalValue: string): number {
        var value = 0
        this.plannedMeals.forEach(item => {
            if (item) value += item.getNutritionalValue(nutritionalValue) || 0
        })
        return value
    }
    getCarbohydrates() {
        var value = 0
        this.plannedMeals.forEach(item => {
            if (item) value += item.getCarbohydrates()
        })
        return value
    }
    getProtein() {
        var value = 0
        this.plannedMeals.forEach(item => {
            if (item) value += item.getProtein()
        })
        return value
    }
    getFat() {
        var value = 0
        this.plannedMeals.forEach(item => {
            if (item) value += item.getFat()
        })
        return value
    }
    getCalories() {
        var value = 0
        this.plannedMeals.forEach(item => {
            if (item) value += item.getCalories()
        })
        return value
    }

    setDateAndNumber(startDate: Date, number: number) {
        this.number = number
        this.setDateByStartDate(startDate)
    }
    setDateByStartDate(startDate: Date) {
        this.date = startDate.clone()
        this.date.setDate(this.date.getDate() + this.number)
    }
    initMeals(mealsPerDay: number) {
        this.plannedMeals = []
        for (var i = 0; i < mealsPerDay; i++) {
            this.plannedMeals.push(null)
        }
    }

    isEmpty(): boolean {
        var empty = true
        this.plannedMeals.forEach(meal => {
            if (meal != null) empty = false
        })
        return empty
    }

    clone() {
        var day = new Day()
        day.id = this.id
        day.number = this.number
        day.date = this.date
        day.plannedMeals = []
        this.plannedMeals.forEach(meal => {
            if (meal) {
                day.plannedMeals.push(meal.clone())
            } else {
                day.plannedMeals.push(null)
            }
        })
        return day
    }
}