import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TrainingHistoryDialogComponent } from '../training-history-dialog/training-history-dialog.component';
import { User } from 'src/app/model/user.model';
import { Activity } from 'src/app/model/activity.model';
import { TrainingService } from 'src/app/services/training.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-activity-history-dialog',
  templateUrl: './activity-history-dialog.component.html',
  styleUrls: ['./activity-history-dialog.component.css']
})
export class ActivityHistoryDialogComponent {

  public user: User
  public userUid: string
  public activity: Activity


  constructor(public dialogRef: MatDialogRef<TrainingHistoryDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {user: User, activity: Activity }, public dialog: MatDialog, public trainingService: TrainingService, public utilityService: UtilityService) 
  {
    this.userUid = data.user?.uid
    this.user = data.user
    this.activity = data.activity
  }

  ngOnInit(): void {
  }

  onCloseDialog(){
    this.dialogRef.close()
  }
}
