<div *ngIf="notificationService.showComposer" class="composer-wrapper">
    <div class="bold display-inlineblock">
        Notification versenden
    </div>
    <div class="icon-button float-right" (click)="onClose()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
    </div>
    <div class="spacer-10"></div>
    <input [(ngModel)]="notificationTitle" class="notification-title input small" type="text" placeholder="Titel"/>
    <textarea [ngModel]="notificationContent" (input)="onNotificationContentChanged($event.target.value)" class="input notification-content" type="text" rows="3" placeholder=""></textarea>    
    <div class="send-wrapper">
        <div class="send-button link" (click)="onSendNotification()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
                <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
            </svg>
        </div>
    </div>
</div>
<div *ngIf="notificationService.showBadge" class="composer-badge link" (click)="onOpenComposer()">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send" viewBox="0 0 16 16">
        <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
    </svg>
</div>