import { environment } from 'src/environments/environment';
import { BaseNutritionFact, NutritionalValue, NutritionalValueHolder } from './basenutritionfact.model';
import { Food } from './food.model';

export class Meal implements NutritionalValueHolder {

    id: string
    name: string
    deleted: boolean
    foods: Food[]
    imageLink: string
    date: Date
    comment: string
    commentAcknowledged: boolean
    show: boolean
    mealType: string
    imagePath: string

    calories: number
    carbohydrates: number
    protein: number
    fat: number
    sugar: number
    fibre: number
    saturatedFat: number
    salt: number

    coacheeNote: string
    instructions: string

    plannedMealId: string


    constructor();
    constructor(init: Meal);
    constructor(init?: Meal) {
        this.id = init && init.id != null ? init.id : null
        this.name = init && init.name || null
        this.deleted = init && init.deleted || false
        this.foods = init && init.foods?.map(x => x) || []
        this.imageLink = init && init.imageLink || null
        this.date = init && init.date || null
        this.comment = init && init.comment || null
        this.commentAcknowledged = init && init.commentAcknowledged || false
        this.show = init && init.show || false
        this.imagePath = init && init.imagePath || null
        this.mealType = init && init.mealType || null
        this.coacheeNote = init && init.coacheeNote || null
        this.instructions = init && init.instructions || null

        this.plannedMealId = init && init.plannedMealId || null
    }

    getNutritionalValue(nutritionalValue: string): number {
        var value = 0
        this.foods.forEach(f => {
            value += f.getNutritionalValue(nutritionalValue) || 0
        })
        return value
    }
    getCarbohydrates() {
        if (this.carbohydrates) return this.carbohydrates
        var value = 0
        this.foods.forEach(f => {
            value += f.carbohydrates || 0
        })
        return value
    }
    getProtein() {
        if (this.protein) return this.protein
        var value = 0
        this.foods.forEach(f => {
            value += f.protein || 0
        })
        return value
    }
    getFat() {
        if (this.fat) return this.fat
        var value = 0
        this.foods.forEach(f => {
            value += f.fat || 0
        })
        return value
    }
    getCalories() {
        if (this.calories) return this.calories
        var value = 0
        this.foods.forEach(f => {
            value += f.calories || 0
        })
        return value
    }
    getSugar() {
        if (this.sugar) return this.sugar
        var value = 0
        this.foods.forEach(f => {
            value += f.sugar || 0
        })
        return value
    }
    getSaturatedFat() {
        if (this.saturatedFat) return this.saturatedFat
        var value = 0
        this.foods.forEach(f => {
            value += f.saturatedFat || 0
        })
        return value
    }
    getFibre() {
        if (this.fibre) return this.fibre
        var value = 0
        this.foods.forEach(f => {
            value += f.fibre || 0
        })
        return value
    }
    getSalt() {
        if (this.salt) return this.salt
        var value = 0
        this.foods.forEach(f => {
            value += f.salt || 0
        })
        return value
    }

    getPrintableDate() {
        if (this.date && !(this.date.getHours() == 0 && this.date.getMinutes() == 0)) return "Um " + this.date.getHours() + ":" + (this.date.getMinutes() < 10 ? '0' : '') + this.date.getMinutes() + " Uhr"
        return ""
    }
    getPrintableMealType() {
        var type
        switch (this.mealType) {
          case "breakfast":
            type = "Frühstück"
            break;
          case "lunch":
            type = "Mittagessen"
            break;
          case "dinner":
            type = "Abendessen"
            break;
          case "snack":
            type = "Snack"
            break;
          case null:
          case undefined:
          case "":
              type = "-"
              break;
          default:
              type = this.mealType
              break;
        }
        return type
    }

    convertableToRecipe(): boolean {
        var convertable = true;
        this.foods.forEach(food => {
            if (food.nutritionFactId != null && !food.nutritionFactId.startsWith('off') && !food.nutritionFactId.startsWith('crd') && !food.nutritionFactId.startsWith('ntf') && !food.nutritionFactId.startsWith('brc')) {
                convertable = false;
            }
        })
        return convertable
    }

    isDummyImage(): boolean {
        return this.imageLink == environment.MEAL_DUMMY_IMAGE_UNDEFINED_URL || this.imageLink == environment.MEAL_DUMMY_IMAGE_BREAKFAST_URL || this.imageLink == environment.MEAL_DUMMY_IMAGE_LUNCH_URL || this.imageLink == environment.MEAL_DUMMY_IMAGE_DINNER_URL || this.imageLink == environment.MEAL_DUMMY_IMAGE_SNACK_URL;
    }


    setNutritionalValue(nutritionalValue: string, value: number) {
        switch (NutritionalValue[nutritionalValue].toString()) {
            case NutritionalValue.calories.toString(): this.calories = value; break;
            case NutritionalValue.carbohydrates.toString(): this.carbohydrates = value; break;
            case NutritionalValue.protein.toString(): this.protein = value; break;
            case NutritionalValue.fat.toString(): this.fat = value; break;
            case NutritionalValue.sugar.toString(): this.sugar = value; break;
            case NutritionalValue.fibre.toString(): this.fibre = value; break;
            case NutritionalValue.saturatedFat.toString(): this.saturatedFat = value; break;
            case NutritionalValue.salt.toString(): this.salt = value; break;
            
            default: break
        }
    }

    recalculateNutritionalValues() {
        BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
            var value = 0
            this.foods.forEach(ingredient => {
                value += ingredient.getNutritionalValue(nutritionalValue) || 0
            })
            this.setNutritionalValue(nutritionalValue, value)
        })
    }

    clone(): Meal{
        let clonedMeal = new Meal(this);
        clonedMeal.foods = this.foods.map(x => x.clone());
        return clonedMeal;
    }
}
