import { DataSample } from "../graph/cardio-evaluation/cardio-evaluation.component";
import { TrackedTrainingSession } from "./training-monitoring.model";

export class Activity {
    id: string;
    date: Date;
    activityFactId: string;
    caloriesBurned: number;
    duration: number;
    name: string;
    heartRate: string
    speed: string
    note: string
    trackedSessionId: string
    trainingSession: TrackedTrainingSession

    // Tmp
    endDate: Date

    constructor();
    constructor(init: Activity);
    constructor(init?: Activity) {
        this.id = init && init.id != null ? init.id : null
        this.date = init && init.date ? new Date((init as any).date.seconds * 1000) : null
        this.activityFactId = init && init.activityFactId || null
        this.caloriesBurned = init && init.caloriesBurned != null ? init.caloriesBurned : null
        this.duration = init && init.duration != null ? init.duration : 0
        this.name = init && init.name || null
        this.heartRate = init && init.heartRate || null
        this.speed = init && init.speed || null
        this.note = init && init.note || null
        this.endDate = new Date(this.date.getTime() + this.duration * 1000 * 60)
        this.trackedSessionId = init && init.trackedSessionId || null
    }
    
    getPrintableDate() {
        if (this.date && !(this.date.getHours() == 0 && this.date.getMinutes() == 0)) return "Um " + this.date.getHours() + ":" + (this.date.getMinutes() < 10 ? '0' : '') + this.date.getMinutes() + " Uhr"
        return ""
    }

    getDataSamples() {
        var samples = []
        var heartRateValues = this.heartRate?.split(";") ?? []
        var speedValues = this.speed?.split(";") ?? []
        if (heartRateValues?.length > 0 || speedValues?.length > 0) {
            var d = new Date(this.date.getTime())
            for (var i = 0; i < Math.max(heartRateValues.length, speedValues.length); i++) {
                var heartRate = heartRateValues[i]
                var rawSpeed = speedValues[i]
                var speed = rawSpeed != null ? parseFloat(rawSpeed) : null
                var pace = speed == 0 || speed == null ? null : 1000 / (speed * 60)
                var heartRateNumber = null
                if(heartRate != null && !isNaN(parseInt(heartRate))){
                    heartRateNumber = parseInt(heartRate)
                }
                samples.push(new DataSample({
                    date: d,
                    heartRate: heartRateNumber,
                    speed: speed,
                    pace: pace
                }))
                d = new Date(d.getTime() + 15000)
            }
        }
        return samples.filter(x => x.heartRate != null || x.speed != null)
    }

    clone(){
        let clonedActivity = new Activity(this);
        clonedActivity.date = this.date.clone();
        return clonedActivity;
    }
}
