export class LicenceMonitoringItem {
    id: string
    date: Date
    activeLicencesPerCoach: Map<string, number>
    trialLicencesPerCoach: Map<string, number>

    constructor();
    constructor(init: LicenceMonitoringItem);
    constructor(init?: LicenceMonitoringItem) {
        this.id = init && init.id || null
        this.date = init && init.date || null
        this.activeLicencesPerCoach = init && init.activeLicencesPerCoach || new Map()
        this.trialLicencesPerCoach = init && init.trialLicencesPerCoach || new Map()
    }
}