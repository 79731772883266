import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Activity } from '../model/activity.model';
import { ActivityFact } from '../model/activity-fact.model';
import { LanguageService } from '../services/language.service';
import { LanguageDictionary } from '../model/languagedictionary.model';
import { User } from '../model/user.model';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from '../services/utility.service';
import {MatDividerModule} from '@angular/material/divider';

@Component({
  selector: 'app-activity-editor',
  templateUrl: './activity-editor.component.html',
  styleUrls: ['./activity-editor.component.css']
})
export class ActivityEditorComponent {

  public activity: Activity;
  public availableAcitivityFacts: ActivityFact[];
  private user: User;

  public selectedActivityFact: ActivityFact;

  public static dailyActivityFactId: string = "acf71";

  public isDailyActivity: boolean = false;

  public readonly customActivityFactId: string = "custom";
  public readonly customActivityFact: ActivityFact = new ActivityFact({
    id: "custom",
    nameTranslation: new LanguageDictionary<string>("Eigene Aktivität", "Custom activity"),
    met: 0,
    valid: true
  } as ActivityFact);

  constructor(public dialogRef: MatDialogRef<ActivityEditorComponent>, @Inject(MAT_DIALOG_DATA) private data: {activity: Activity, availableAcitivityFacts: ActivityFact[], user: User}, public languageService: LanguageService, public dialog: MatDialog, public toastr: ToastrService, public utilityService: UtilityService) {
    this.activity = data.activity || new Activity()
    if(this.activity.activityFactId == ActivityEditorComponent.dailyActivityFactId){
      this.isDailyActivity = true;
    }
    this.availableAcitivityFacts = data.availableAcitivityFacts?.sort((a, b) => a.nameTranslation.GetValue(languageService.selectedLanguageCode).localeCompare(b.nameTranslation.GetValue(languageService.selectedLanguageCode))) || []
    let activity = this.availableAcitivityFacts?.find(f => f.id == this.activity.activityFactId)
    if(activity != null){
      this.selectedActivityFact = activity
    }
    else if(this.activity.activityFactId == this.customActivityFactId){
      this.selectedActivityFact = this.customActivityFact;
    }
    this.user = data.user;
  }

  getTimeOfDate(){
    if(this.activity.date == null) return ""
    return (this.activity.date.getHours() < 10 ? '0' : '') + this.activity.date.getHours() + ":" + (this.activity.date.getMinutes() < 10 ? '0' : '') + this.activity.date.getMinutes()
  }

  setTimeOfDate(value: string){
    let split = value.split(":")
    if(split.length != 2) return
    this.activity.date.setHours(parseInt(split[0]))
    this.activity.date.setMinutes(parseInt(split[1]))
  }

  getCalculatedCalories(): number {
    if (this.activity.duration != null && this.selectedActivityFact) {
      return (this.selectedActivityFact.getBurnedCaloriesPerMinute(this.user.bodyWeight) * this.activity.duration).roundToInt();
    }
    return 0;
  }

  setDurationNumber(value: string) {
    let intValue = parseInt(value) || 0
    if(intValue < 0){
      intValue = 0;
    }
    this.activity.duration = intValue
  }

  setBurdnedCalories(value: string) {
    let intValue = parseInt(value) || null
    if(intValue < 0){
      intValue = 0;
    }
    this.activity.caloriesBurned = intValue
  }

  getSelectedActivityFact(): string {
    if(this.selectedActivityFact == null) return "";
    return this.selectedActivityFact?.nameTranslation?.GetValue(this.languageService.selectedLanguageCode) || "";
  }

  public filteredActivitySearchResults: ActivityFact[] = [];
  public activityFactSearchFocused: boolean = false;

  public activitySearchInput: string = "";

  onActivityFactSearchFocused() {
    this.activityFactSearchFocused = true;
  }
  onActivityFactSearchFocusedOut() {
    // this.activityFactSearchFocused = false;
  }

  setSelectedActivityFact(activityFact: ActivityFact) {
    this.activity.name = activityFact.nameTranslation.GetValue(this.languageService.selectedLanguageCode);
    this.activity.activityFactId = activityFact.id;
    this.selectedActivityFact = activityFact;
    if(activityFact.id == this.customActivityFact.id && this.activitySearchInput?.length > 0) {
      this.activity.name = this.activitySearchInput;
    }
    this.activityFactSearchFocused = false;
  }

  onActivitySearchInput(value: string) {
    this.activitySearchInput = value;
    this.filteredActivitySearchResults = this.availableAcitivityFacts.filter(f => f.nameTranslation.GetValue(this.languageService.selectedLanguageCode).toLowerCase().includes(value.toLowerCase()));
    let customActivityFact = this.customActivityFact;
    this.filteredActivitySearchResults.push(customActivityFact);
  }


  onSaveActivity(){
    if(!this.selectedActivityFact) {
      this.toastr.error("Bitte wähle eine Aktivität aus.", "Fehler");
      return;
    }
    if(this.activity.caloriesBurned == null){
      this.activity.caloriesBurned = this.getCalculatedCalories();
    }
    this.activity.activityFactId = this.selectedActivityFact.id;
    this.dialogRef.close({save: true});
  }

  onCloseActivity(){
    this.dialogRef.close();
  }

  onDeleteActivity(){
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du diese Aktivität wirklich löschen?', title: 'Mahlzeit löschen' },
    });
    confirmationDialogRef.afterClosed().subscribe(async confirmationResult => {
      if(confirmationResult){
        this.dialogRef.close({delete: true});
      }
    });
  }
}
