<div class="container container-left-align main-container">
    <!-- <input id="clientsearch-input" class="input wide client" (input)="onSearchInputChanged($event.target.value)" placeholder="Suchen" type="text"> -->
    <div class="row">
        <div class="col-12">
            <div class="spacer-10"></div>
            <input id="search-input" class="input wide" [(ngModel)]="SearchText" placeholder="Übungen durchsuchen"
                type="text">
            <div class="icon-button deletesearch-icon delete-recipesearch"
                (click)="onDeleteSearchInput(); $event.stopPropagation()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </div>
            <!-- <div class="remove-all-filters-button only-desktop">
            </div> -->
            <div class="remove-all-filters-button wrapper-sort-selection only-desktop link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Sortieren nach <ng-container *ngIf="sortAlphabetically">"alphabetisch"</ng-container><ng-container *ngIf="sortTimestamp">"neu hinzugefügt"</ng-container><ng-container *ngIf="sortOwnFirst">"eigene zuerst"</ng-container>
                <button class="filter-button ml-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor"
                        class="bi bi-sort-down" viewBox="0 0 16 16">
                        <path
                            d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5M7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1z" />
                    </svg>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownBodyDataGraphTimeRange">
                    <button class="dropdown-item {{sortAlphabetically ? 'selected' : ''}}" type="button"
                        (click)="sortByAlphabeticalOrder()">Alphabetisch</button>
                    <button class="dropdown-item {{sortTimestamp ? 'selected' : ''}}" type="button"
                        (click)="sortByTimestamp()">Neu hinzugefügt</button>
                    <button class="dropdown-item {{sortOwnFirst ? 'selected' : ''}}" type="button"
                        (click)="sortByOwnFirst()">Eigene</button>
                </div>
            </div>
            <div *ngIf="isAnyFilterActive()" class="remove-all-filters-button only-desktop">
                Filter zurücksetzen
                <button class="filter-button" (click)="onRemoveAllFilters(); $event.stopPropagation()">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </button>
            </div>
            <div class="spacer-25"></div>
        </div>
    </div>
    <div class="mobile-filter">
        <div class="row">
            <div class="col-12 col-md-6 mobile-filter-container">
                <button class="filter-button" data-toggle="collapse" href="#collapseFilterContainer" role="button"
                    aria-expanded="false" aria-controls="collapseFilterContainer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-filter" viewBox="0 0 16 16">
                        <path
                            d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                    </svg>
                </button>
                <span>Filter</span>
            </div>
        </div>
        <div class="collapse" id="collapseFilterContainer">
            <div class="card card-body">
                <div class="row">
                    <div class="col-12 col-md-6 mobile-filter-container mobile-reset-filter">
                        <div class="wrapper-sort-selection">
                            <button class="filter-button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor"
                                    class="bi bi-sort-down" viewBox="0 0 16 16">
                                    <path
                                        d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5M7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1z" />
                                </svg>
                            </button>
                            <span>Sortieren nach</span>
                            <div class="dropdown-menu" aria-labelledby="dropdownBodyDataGraphTimeRange">
                                <button class="dropdown-item {{sortAlphabetically ? 'selected' : ''}}" type="button"
                                    (click)="sortByAlphabeticalOrder()">Alphabetisch</button>
                                <button class="dropdown-item {{sortTimestamp ? 'selected' : ''}}" type="button"
                                    (click)="sortByTimestamp()">Neu hinzugefügt</button>
                                <button class="dropdown-item {{sortOwnFirst ? 'selected' : ''}}" type="button"
                                    (click)="sortByOwnFirst()">Eigene</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="isAnyFilterActive()" class="row">
                    <div class="col-12 col-md-6 mobile-filter-container mobile-reset-filter">
                        <button class="filter-button" (click)="onRemoveAllFilters(); $event.stopPropagation()">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </button>
                        <span>Filter zurücksetzen</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="col-12 col-md-6 mobile-filter-container">
                                <app-filter-selection-dropdown [availableFilterObjects]="availableMuscleGroups"
                                    (filterChanged)="setFilteredTrainingExercises()"
                                    [selectedLanguage]="selectedLanguage"></app-filter-selection-dropdown>
                                <span>Muskel&shy;gruppen</span>
                            </div>
                            <div class="col-12 col-md-6 mobile-filter-container">
                                <app-filter-selection-dropdown [availableFilterObjects]="availableMainMuscles"
                                    (filterChanged)="setFilteredTrainingExercises()"
                                    [selectedLanguage]="selectedLanguage"></app-filter-selection-dropdown>
                                <span>Haupt&shy;muskulatur</span>
                            </div>
                            <div class="col-12 col-md-6 mobile-filter-container">
                                <app-filter-selection-dropdown [availableFilterObjects]="availableExerciseTypes"
                                    (filterChanged)="setFilteredTrainingExercises()"
                                    [selectedLanguage]="selectedLanguage"></app-filter-selection-dropdown>
                                <span>Übungs&shy;typ</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6 mobile-filter-container">
                                <app-filter-selection-dropdown [availableFilterObjects]="availableMovementTypes"
                                    (filterChanged)="setFilteredTrainingExercises()"
                                    [selectedLanguage]="selectedLanguage"></app-filter-selection-dropdown>
                                <span>Bewegungs&shy;typ</span>
                            </div>
                            <div class="col-12 col-md-6 mobile-filter-container">
                                <app-filter-selection-dropdown [availableFilterObjects]="availableEquipments"
                                    (filterChanged)="setFilteredTrainingExercises()"
                                    [selectedLanguage]="selectedLanguage"></app-filter-selection-dropdown>
                                <span>Equipment</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6 mobile-filter-container">
                                <app-filter-selection-dropdown [availableFilterObjects]="availableCustomCategories"
                                    (filterChanged)="setFilteredTrainingExercises()"
                                    [isTranslatable]="false"></app-filter-selection-dropdown>
                                <span>Eigene Kategorie</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="spacer-10"></div>
    <div class="row header-row">
        <div class="thumbnail-image">
        </div>
        <div class="col">
            <b>Name</b>
        </div>
        <div *ngIf="trainingService?.AdministratorEditModeActivated" class="col">
            Parameter
        </div>
        <div class="col">
            <span>Muskel&shy;gruppen</span>
            <span class="filter-button-span"><app-filter-selection-dropdown
                    [availableFilterObjects]="availableMuscleGroups" (filterChanged)="setFilteredTrainingExercises()"
                    [selectedLanguage]="selectedLanguage"></app-filter-selection-dropdown></span>
        </div>
        <div class="col">
            <span>Haupt&shy;muskulatur</span>
            <span class="filter-button-span"><app-filter-selection-dropdown
                    [availableFilterObjects]="availableMainMuscles" (filterChanged)="setFilteredTrainingExercises()"
                    [selectedLanguage]="selectedLanguage"></app-filter-selection-dropdown></span>
        </div>
        <div class="col">
            <span>Übungs&shy;typ</span>
            <span class="filter-button-span"><app-filter-selection-dropdown
                    [availableFilterObjects]="availableExerciseTypes" (filterChanged)="setFilteredTrainingExercises()"
                    [selectedLanguage]="selectedLanguage"></app-filter-selection-dropdown></span>
        </div>
        <!--div class="col">
            <span>Bewegungs&shy;typ</span>
            <span class="filter-button-span"><app-filter-selection-dropdown
                    [availableFilterObjects]="availableMovementTypes" (filterChanged)="setFilteredTrainingExercises()"
                    [selectedLanguage]="selectedLanguage"></app-filter-selection-dropdown></span>
        </div-->
        <div class="col">
            <span>Equipment</span>
            <span class="filter-button-span"><app-filter-selection-dropdown
                    [availableFilterObjects]="availableEquipments" (filterChanged)="setFilteredTrainingExercises()"
                    [selectedLanguage]="selectedLanguage"></app-filter-selection-dropdown></span>
        </div>
        <div class="col">
            <span>Eigene Kategorie</span>
            <span class="filter-button-span"><app-filter-selection-dropdown
                    [availableFilterObjects]="availableCustomCategories"
                    (filterChanged)="setFilteredTrainingExercises()"
                    [isTranslatable]="false"></app-filter-selection-dropdown></span>
        </div>
        <div class="rotate-easter-egg duplicate-header">
            <i class="bi bi-files" style="font-size: 20px; opacity: 0.6;"></i>
        </div>
    </div>
    <div *ngIf="allTrainingExercises?.length === 0">
        <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
    </div>
    <div class="row layout-box sub-box link" *ngFor="let exercise of trainingExercises" (click)="onTrainingExerciseSelected(exercise)">
        <div class="checkbox-wrapper" *ngIf="multiselectEnabled">
            <input class="input checkbox" type="checkbox" [checked]="selectedTrainingExercises.includes(exercise)" (click)="onTrainingExerciseSelected(exercise);$event.stopPropagation()">
        </div>
        <div class="thumbnail-image">
            <img draggable="false" *ngIf="exercise.thumbnailDownloadURL" loading="lazy" (error)="utilityService.onImageErrorHide($event)"
                src="{{exercise.thumbnailDownloadURL}}">
            <div *ngIf="!exercise.thumbnailDownloadURL">
                <img draggable="false" loading="lazy" (error)="utilityService.onImageErrorHide($event)"
                    src="{{ (trainingService.getExerciseThumbnailUrl(exercise) | async) }}">
            </div>
        </div>
        <div class="col">
            <div class="row">
                <div class="col">
                    <b>{{exercise.name?.GetValue('de')}}</b>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    {{exercise.subName?.GetValue('de')}}
                </div>
            </div>
        </div>
        <div *ngIf="trainingService?.AdministratorEditModeActivated" class="col">
            {{exercise.getPrintableDefaultSetParameters()}}
        </div>
        <div class="col only-desktop">
            {{getStringForLanguageDictionaryList(exercise.muscleGroups, 3)}}
        </div>
        <div class="col only-desktop">
            {{getStringForLanguageDictionaryList(exercise.mainMuscles, 3)}}
        </div>
        <div class="col only-desktop">
            {{getStringForLanguageDictionaryList(exercise.exerciseType, 3)}}
        </div>
        <!--div class="col only-desktop">
            {{getStringForLanguageDictionaryList(exercise.movement, 3)}}
        </di-->
        <div class="col only-desktop">
            {{getStringForLanguageDictionaryList(exercise.equipment, 3)}}
        </div>
        <div class="col only-desktop">
            {{getStringForCustomCategoryList(exercise.customCategories, 3)}}
        </div>
        <div>
            <i class="bi bi-files link" (click)="onDuplicateTrainingExercise(exercise);$event.stopPropagation()"
                tooltip="Übung duplizieren" style="font-size: 20px;"></i>
        </div>
    </div>
    <div class="margin-10 label">{{trainingExercises?.length}} Übungen gefunden.</div>
</div>