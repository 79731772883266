import { Injectable } from '@angular/core';
import { FirestoreService } from './firestore.service';
import { CommonFirebase } from '../app.module';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor(private userService: FirestoreService, private commonFirebase: CommonFirebase) { }

  get firestore(): AngularFirestore {
    return this.commonFirebase.firestore
  }

  log(message: string, position: string){
    console.log(message);
    let firestoreMap = this.getFirestoreMap(message, 'log', position);
    this.saveToFirestore(firestoreMap);
  }

  error(error: Error, position: string){
    console.error(error);
    let errorMessage = error.stack ?? error.message;
    let firestoreMap = this.getFirestoreMap(errorMessage, 'error', position);
    this.saveToFirestore(firestoreMap);
  }

  private getFirestoreMap(message: string, errorType: string, position: string): unknown {
    return {
      date: new Date(),
      error: message,
      uid: this.userService?.getLoggedInUser()?.uid,
      type: errorType,
      platform: Capacitor.getPlatform() + '-portal',
      position: position
    }
  }

  async saveToFirestore(firestoreMap: unknown){
    try {
      const result = await this.firestore
        .collection('ExceptionLogging')
        .add(firestoreMap);
    }
    catch (error) {
      console.error("Error saving log to Firestore: ", error);
    }
  }
}
