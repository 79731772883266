import { Component, Input, NgZone } from '@angular/core'; 
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';  
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { Payment } from 'src/app/model/payment.model';
import { ProductPurchase } from 'src/app/model/product-purchase.model';
import { Product } from 'src/app/model/product.model';
import { User } from 'src/app/model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { PaymentService } from 'src/app/services/payment.service';
import { UtilityService } from 'src/app/services/utility.service';
import { PurchaseDialogComponent } from '../purchase-dialog/purchase-dialog.component';
import { LicenceDialogComponent } from 'src/app/dialogs/licence-dialog/licence-dialog.component';
import { Coach } from 'src/app/model/coach.model';
import { Questionaire } from 'src/app/model/questionaires.model';
import { QuestionairesService } from 'src/app/services/questionaires.service';
import { PaymentDetailsDialogComponent } from '../paymentdetails-dialog/paymentdetails-dialog.component';
import { FilterObject } from 'src/app/filter-selection-dropdown/filter-selection-dropdown.component';
import { ILanguageDictionary, LanguageDictionary } from 'src/app/model/languagedictionary.model';
import * as JSZip from 'jszip';
import * as FileSaver from 'file-saver';
import { TimerangeSelectionDialogComponent } from 'src/app/dialogs/timerange-selection-dialog/timerange-selection-dialog.component';
import { InvoiceEditorComponent } from '../invoice-editor/invoice-editor.component';
import { PayoutReportDialogComponent } from '../payout-report-dialog/payout-report-dialog.component';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent {

  public availablePaymentStatuses: FilterObject[] = [
    new FilterObject(new LanguageDictionary('Entwurf', 'Draft', 'draft'), false),
    new FilterObject(new LanguageDictionary('Bezahlt', 'Paid', 'paid'), false),
    new FilterObject(new LanguageDictionary('In Verarbeitung', 'Processing', 'processing'), false),
    new FilterObject(new LanguageDictionary('Ausstehend', 'Pending', 'unpaid'), false),
    new FilterObject(new LanguageDictionary('Fehlgeschlagen', 'Failed', 'failed'), false),
    new FilterObject(new LanguageDictionary('Erstattet', 'Refunded', 'refunded'), false),
    new FilterObject(new LanguageDictionary('Storniert', 'Voided', 'voided'), false),
  ];
  public availablePurchaseStatuses: FilterObject[] = [
    new FilterObject(new LanguageDictionary('Aktiv', 'Active', 'active'), false),
    new FilterObject(new LanguageDictionary('Gekauft', 'Purchased', 'purchased'), false),
    new FilterObject(new LanguageDictionary('Ausstehend', 'Pending', 'uncompleted'), false),
    new FilterObject(new LanguageDictionary('Abgelaufen', 'Expired', 'expired'), false),
  ];
  public availableClients: FilterObject[] = []
  public availableProducts: FilterObject[] = []
  public availablePayoutDates: FilterObject[] = []
  public availableReversePayoutDates: FilterObject[] = []

  public coachesOfLicenceHolder: Coach[] = []
  public availableQuestionaires: Questionaire[] = []

  user: User
  private get allPayments() {
    return this.paymentService.payments
  }
  public get payments() {
    return this.allPayments.filter(x => this.fallsInFilter(this.availablePaymentStatuses, x.status, false) && this.fallsInFilter(this.availableClients, x.customerUid, false) && this.fallsInFilter(this.availablePayoutDates, x.payoutDate?.getTime()?.toString(), false) && this.fallsInFilter(this.availableReversePayoutDates, x.reversePayoutDate?.getTime()?.toString(), false))
  }
  
  get products(): Product[] {
    return this.paymentService.activeProducts
  }

  private get allProductPurchases() {
    return this.paymentService.productPurchases
  }
  public get productPurchases() {
    return this.allProductPurchases.filter(x => this.fallsInFilter(this.availablePurchaseStatuses, x.status, false) &&  this.fallsInFilter(this.availableClients, x.customerUid, false) &&  this.fallsInFilter(this.availableProducts, x.productId, false)).sort((a, b) => {
      if (this.purchasesSortAttribute == 'nextPaymentDate') {
        if (this.purchasesSortDirection == 'asc') {
          if (a.nextPaymentDate == null) return 1
          if (b.nextPaymentDate == null) return -1
          return a.nextPaymentDate.getTime() - b.nextPaymentDate.getTime()
        } else {
          if (a.nextPaymentDate == null) return -1
          if (b.nextPaymentDate == null) return 1
          return b.nextPaymentDate.getTime() - a.nextPaymentDate.getTime()
        }
      } else if (this.purchasesSortAttribute == 'startDate') {
        if (this.purchasesSortDirection == 'asc') {
          if (a.startDate == null) return 1
          if (b.startDate == null) return -1
          return a.startDate.getTime() - b.startDate.getTime()
        } else {
          if (a.startDate == null) return -1
          if (b.startDate == null) return 1
          return b.startDate.getTime() - a.startDate.getTime()
        }
      } else if (this.purchasesSortAttribute == 'endDate') {
        if (this.purchasesSortDirection == 'asc') {
          if (a.endDate == null) return 1
          if (b.endDate == null) return -1
          return a.endDate.getTime() - b.endDate.getTime()
        } else {
          if (a.endDate == null) return -1
          if (b.endDate == null) return 1
          return b.endDate.getTime() - a.endDate.getTime()
        }
      } else if (this.purchasesSortAttribute == 'customer') {
        if (this.purchasesSortDirection == 'asc') {
          return a.customer?.getName().localeCompare(b.customer?.getName())
        } else {
          return b.customer?.getName().localeCompare(a.customer?.getName())
        }
      } else if (this.purchasesSortAttribute == 'product') {
        if (this.purchasesSortDirection == 'asc') {
          return a.getProductName().localeCompare(b.getProductName())
        } else {
          return b.getProductName().localeCompare(a.getProductName())
        }
      } else {
        if (this.purchasesSortDirection == 'asc') {
          return a.creationDate.getTime() - b.creationDate.getTime()
        } else {
          return b.creationDate.getTime() - a.creationDate.getTime()
        }
      } 
    })
  }

  public static TAB_PAYMENTS = 'payments'
  public get tabPayments() {
    return OrdersComponent.TAB_PAYMENTS
  }
  public static TAB_SUBSCRIPTIONS = 'subscriptions'
  public get tabSubscriptions() {
    return OrdersComponent.TAB_SUBSCRIPTIONS
  }
  public currentTab = OrdersComponent.TAB_PAYMENTS

  public purchasesSortAttribute = 'creationDate'
  public purchasesSortDirection = 'desc'

  public spinnerText = null

  constructor(private router: Router, public userService: FirestoreService, public paymentService: PaymentService, private authService: AuthService, public utilityService: UtilityService, private toastr: ToastrService, private ngZone: NgZone, public dialog: MatDialog, private spinner: NgxSpinnerService, public questionaireService: QuestionairesService, private httpClient: HttpClient) {
    this.user = userService.getLoggedInUser()

  }

  onOpenClientsFilter() {
    if (this.availableClients.length == 0) {
      this.availableClients = []
      this.allPayments.forEach(payment => {
        if (payment.customer) {
          if (this.availableClients.filter(x => x.originObject.originObject == payment.customerUid).length == 0) {
            this.availableClients.push(new FilterObject(new LanguageDictionary(payment.customer.getName(), null, payment.customerUid), false))
          }
        }
      })
      this.allProductPurchases.forEach(purchase => {
        if (purchase.customer) {
          if (this.availableClients.filter(x => x.originObject.originObject == purchase.customerUid).length == 0) {
            this.availableClients.push(new FilterObject(new LanguageDictionary(purchase.customer.getName(), null, purchase.customerUid), false))
          }
        }
      })
      this.availableClients.sort((a, b) => a.originObject.GetValue('de').localeCompare(b.originObject.GetValue('de')))
    }
  }

  onOpenProductsFilter() {
    if (this.availableProducts.length == 0) {
      this.availableProducts = []
      this.allProductPurchases.forEach(purchase => {
        if (purchase.product) {
          if (this.availableProducts.filter(x => x.originObject.originObject == purchase.productId).length == 0) {
            this.availableProducts.push(new FilterObject(new LanguageDictionary(purchase.product.name, null, purchase.productId), false))
          }
        }
      })
      this.availableProducts.sort((a, b) => a.originObject.GetValue('de').localeCompare(b.originObject.GetValue('de')))
    }
  }

  onOpenPayoutDatesFilter() {
    this.availablePayoutDates = []
    this.allPayments.forEach(payment => {
      if (payment.payoutDate) {
        if (this.availablePayoutDates.filter(x => x.originObject.originObject == payment.payoutDate.getTime()).length == 0) {
          this.availablePayoutDates.push(new FilterObject(new LanguageDictionary(payment.payoutDate.asFormatedString(), null, payment.payoutDate.getTime()), false))
        }
      }
    })
    this.availablePayoutDates.sort((a, b) => a.originObject.originObject.localeCompare(b.originObject.originObject))
  }
  onOpenReversePayoutDatesFilter() {
    this.availableReversePayoutDates = []
    this.allPayments.forEach(payment => {
      if (payment.reversePayoutDate) {
        if (this.availableReversePayoutDates.filter(x => x.originObject.originObject == payment.reversePayoutDate.getTime()).length == 0) {
          this.availableReversePayoutDates.push(new FilterObject(new LanguageDictionary(payment.reversePayoutDate.asFormatedString(), null, payment.reversePayoutDate.getTime()), false))
        }
      }
    })
    this.availableReversePayoutDates.sort((a, b) => a.originObject.originObject.localeCompare(b.originObject.originObject))
  }

  onOpenInvoice(payment: Payment) {
    this.spinner.show()
    this.userService.getDownloadUrl('payments/' + payment.id + '/' + (payment.invoiceFileName ?? ('Rechnung_' + payment.id + '.pdf'))).then(url => {
      this.spinner.hide()
      window.open(url, "_blank");
    })
  }
  onOpenCancellationInvoice(payment: Payment) {
    this.spinner.show()
    this.userService.getDownloadUrl('payments/' + payment.id + '/' + (payment.cancellationInvoiceFileName ?? ('Rechnung_' + payment.id + '.pdf'))).then(url => {
      this.spinner.hide()
      window.open(url, "_blank");
    })
  }

  async onExportInvoices() {
    var today = new Date()
    var startDate = new Date(today.getFullYear(), today.getMonth(), 1)
    const dialogRef = this.dialog.open(TimerangeSelectionDialogComponent, {
      data: { startDate: startDate, endDate: new Date() },
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result && result.startDate) {
        this.spinner.show()
        var startDate = result.startDate
        startDate.setHours(0)
        startDate.setMinutes(0)
        startDate.setSeconds(0)
        startDate.setMilliseconds(0)
        var endDate = result.endDate
        if (!endDate) endDate = new Date()
        endDate.setHours(23)
        endDate.setMinutes(59)
        endDate.setSeconds(59)
        endDate.setMilliseconds(999)
        var invoicesToDownload = this.payments.filter(x => x.invoiceNumber != null && x.date >= startDate && x.date <= endDate)
        const zip = new JSZip();  
        const name = 'Rechnungen' + '.zip';  
        this.spinnerText = 'Rechnung 0 / ' + invoicesToDownload.length
        for (let invoice of invoicesToDownload) {
          this.spinnerText = 'Rechnung ' + (invoicesToDownload.indexOf(invoice) + 1) + ' / ' + invoicesToDownload.length
          var url = await this.userService.getDownloadUrl('payments/' + invoice.id + '/' + (invoice.invoiceFileName ?? ('Rechnung_' + invoice.id + '.pdf')))
          const fileData: any = await this.getFile(url)
          const b: any = new Blob([fileData], { type: '' + fileData.type + '' })
          zip.file(invoice.invoiceFileName ?? ('Rechnung_' + invoice.id + '.pdf'), b)
          if (invoice.cancellationInvoiceFileName) {
            var url = await this.userService.getDownloadUrl('payments/' + invoice.id + '/' + invoice.cancellationInvoiceFileName)
            const fileData: any = await this.getFile(url)
            const b: any = new Blob([fileData], { type: '' + fileData.type + '' })
            zip.file(invoice.cancellationInvoiceFileName, b)
          }
        }
        zip.generateAsync({ type: 'blob' }).then((content) => {  
          this.spinner.hide()
          this.spinnerText = null
          if (content) { 
            FileSaver.saveAs(content, name)
          }  
        }); 
      }
    });
  }

  onExportPaymentTable() {
    var data: any[] = []
    this.payments.forEach(p => {
        data.push([p.id, p.date?.asFormatedString(), p.customer?.getName(), p.getPrintablePrice(), p.getPrintableCurrency(), p.getPrintableStatus(), p.invoiceNumber])
    })

    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], {type: 'text/csv' })
    FileSaver.saveAs(blob, 'Zahlungen.csv')
  }

  async getFile(url: string) {  
    const httpOptions = {  
      responseType: 'blob' as 'json'  
    };  
    const res = await this.httpClient.get(url, httpOptions).toPromise().catch((err: HttpErrorResponse) => {  
      const error = err.error;  
      return error;  
    });  
    return res;  
  }

  hasFilter(filterObjects:FilterObject[]):boolean{
    return filterObjects?.filter(x => x.isFiltered)?.length > 0
  }
  
  fallsInFilter(filter:FilterObject[], item: string, isTranslatable: boolean = true):boolean{
    return (!this.hasFilter(filter) || (filter.filter(i => i.isFiltered && this.getCompareText(i.originObject, isTranslatable) === item)).length > 0)
  }

  onPurchaseSortChange(sortAttribute: string) {
    if (this.purchasesSortAttribute == sortAttribute) {
      if (this.purchasesSortDirection == 'desc') {
        this.purchasesSortDirection = 'asc'
      } else {
        this.purchasesSortDirection = 'desc'
        this.purchasesSortAttribute = 'creationDate'
      }
    } else {
      this.purchasesSortDirection = 'desc'
      this.purchasesSortAttribute = sortAttribute
    }
  }

  getCompareText(filterObject: ILanguageDictionary<any>, isTranslatable: boolean = true):string{
    if(isTranslatable) return filterObject.comparableValue
    else return filterObject.originObject.toString()
  }

  ngOnInit(): void {
    var subscriptionCoaches = this.userService.getAllCoachesByLicenceHolderUid(this.userService.getLoggedInUser().licenceHolderUid).subscribe(coaches => {
      this.coachesOfLicenceHolder = coaches
      subscriptionCoaches.unsubscribe()
    })
    if (!this.authService.isLoggedIn) {
      this.router.navigate['login'];
    }
    this.questionaireService.getQuestionaires().then(questionaires => {
      this.availableQuestionaires = questionaires
    })
  }

  async onCreatePurchase() {
    const dialogRef = this.dialog.open(LicenceDialogComponent, { data: { user: this.user, licence: null, coachesOfLicenceHolder: this.coachesOfLicenceHolder, availableQuestionaires: this.availableQuestionaires, createNew: true }, width: '1000px'})
  }

  async onCreatePayment() {
    const dialogRef = this.dialog.open(InvoiceEditorComponent, { data: { }, width: '1000px'})
  }

  onSelectProductPurchase(purchase: ProductPurchase) {
    const dialogRef = this.dialog.open(PurchaseDialogComponent, { data: { productPurchase: purchase}, width: '1000px'})
    dialogRef.afterClosed().subscribe(async result => {});
  }

  onSelectPayment(payment: Payment) {
    const dialogRef = this.dialog.open(PaymentDetailsDialogComponent, { data: { payment: payment}, width: '1000px'})
    dialogRef.afterClosed().subscribe(async result => {});
  }

  async onEditSubscription(purchase: ProductPurchase) {
    var licence = null
    console.log(purchase.licenceId)
    if (purchase.licenceId) {
      licence = await this.userService.getLicenceById(purchase.licenceId, true)
      console.log(licence)
    }
    const dialogRef = this.dialog.open(LicenceDialogComponent, { data: { user: this.user, licence: licence, coachesOfLicenceHolder: this.coachesOfLicenceHolder, availableQuestionaires: this.availableQuestionaires, createNew: false }, width: '1000px'})
  }

  onCancelSubscription(purchase: ProductPurchase) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du das Abonnement wirklich kündigen?<br>Nach Ablauf der Laufzeit werden keine weiteren Rechnungen gestellt und die Lizenz wird deaktiviert.', title: 'Abonnement kündigen' },
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        this.spinner.show()
        await this.userService.cancelProductPurchase(purchase)
        this.spinner.hide()
      }
    });
  }

  onDeleteSubscription(purchase: ProductPurchase) {
    if (purchase.status == 'active' || purchase.status == 'purchased') {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { message: 'Möchtest du das Abonnement wirklich löschen?<br>Es werden keine weiteren Rechnungen gestellt und die Lizenz wird sofort deaktiviert.', title: 'Abonnement löschen' },
      });
      dialogRef.afterClosed().subscribe(async result => {
        if (result) {
          this.spinner.show()
          if (purchase.licenceId) {
            var licence = await this.userService.getLicenceById(purchase.licenceId, false)
            if (licence) {
              licence.productPurchaseId = null
              licence.active = false
              licence.deactivationDate = new Date()
              await this.userService.updateLicence(licence)
            }
          }
          await this.userService.cancelProductPurchase(purchase, true)
          this.spinner.hide()
        }
      });
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { message: 'Möchtest du das Angebot wirklich löschen?', title: 'Angebot löschen' },
      });
      dialogRef.afterClosed().subscribe(async result => {
        if (result) {
          this.spinner.show()
          if (purchase.licenceId) {
            var licence = await this.userService.getLicenceById(purchase.licenceId, false)
            if (licence) {
              if (licence.active) {
                licence.productPurchaseId = null
                await this.userService.updateLicence(licence)
              } else {
                await this.userService.deleteLicence(licence)
              }
            }
          }
          await this.userService.deleteProductPurchase(purchase)
          this.spinner.hide()
        }
      });
    }
  }

  onActivateLicence(purchase: ProductPurchase) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du die Lizenz wirklich sofort aktivieren?<br>Die Abrechnung startet dennoch am geplanten Startdatum.', title: 'Lizenz aktivieren' },
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        this.spinner.show()
        this.userService.activateLicenceForProductPurchase(purchase.licenceId, purchase.id).then((res) => {
          this.spinner.hide()
          if (res?.success == true) {
            this.toastr.success('Lizenz wurde aktiviert und Einladung per Email versendet.')
          } else {
            this.toastr.error('Lizenz konnte nicht aktiviert werden.')
          }
        })
        this.spinner.hide()
      }
    });

  }

  onCreatePayoutReport() {
    const dialogRef = this.dialog.open(PayoutReportDialogComponent, {width: '800px'}).afterClosed().subscribe(async result => {});
  }

  getProductById(productId: string) {
    return this.paymentService.getProductById(productId)
  }

  getTotalAmount() {
    var total = 0
    this.payments.forEach(payment => {
      if (payment.status != 'voided') total += payment.amount
    })
    return (total / 100).toFixed(2)
  }

  onChangeTab(tab: string) {
    this.currentTab = tab
  }
}
