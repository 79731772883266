export class PortalSettingsLicenceHolder {
    autoShareNewRecipesEnabled: boolean
    hideCreateSubscriptionHint: boolean
    hideRecipeMigrationHint: boolean

    constructor();
    constructor(init: PortalSettingsLicenceHolder);
    constructor(init?: PortalSettingsLicenceHolder) {
        this.autoShareNewRecipesEnabled = init && init.autoShareNewRecipesEnabled || false
        this.hideCreateSubscriptionHint = init && init.hideCreateSubscriptionHint || false
        this.hideRecipeMigrationHint = init && init.hideRecipeMigrationHint || false
    }
}