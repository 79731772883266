<div class="dialog-topbar">
    <button class="button-filled small create-Exercise" (click)="onTakeChanges()">Übernehmen</button>
    <div class="icon-button float-right" (click)="onCancelChanges()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        <ng-container *ngIf="!utilityService.onSmallDisplay()">Schließen</ng-container>
    </div>
</div>
<div class="dialog-content-wrapper">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-sm-12">
                <label for="input-exercise-name">Inhalt</label>
                <app-md-editor id="editor-note" class="multi-line"
                    (valueChanged)="onEditorValueChanged($event)"
                    [inputValue]="questionaireItem.content || ''"
                    [placeHolder]="''">
                </app-md-editor>
            </div>
            <div class="spacer-10"></div>
        </div>
        <div class="row">
            <div class="col-lg-6 mb-2">
                <label for="thumbnail-input">Bild</label>
                <div class="question-image link" (click)="onUploadImage()" (dragover)="onDragOver($event)" (drop)="onDropImage($event)">
                    <div *ngIf="!questionaireItem?.imageURL" for="question-input" class="dropzone-hint">Bild (.png oder .jpg) ablegen oder auswählen</div>
                    <div *ngIf="questionaireItem?.imageURL" class="question-image" [ngStyle]="{'background-image':'url('+ questionaireItem?.imageURL +')'}"></div>
                    <input id="input-recipeimage" type="file" style="display: none;" accept=".jpg, .png" (change)="onNewImageUploaded($event)">
                </div>
            </div>
        </div>  
    </div>
</div>