import { ToastrService } from "ngx-toastr";
import { TaskManagementService } from "../services/task-management.service";
import { Priority, SubTask, Task } from "../model/task.model";
import { Repetition } from "../model/automatic-push-notification.model";
import { RadioButtonsConfirmationDialogComponent } from "../dialogs/radio-buttons-confirmation-dialog/radio-buttons-confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { FirestoreService } from "../services/firestore.service";
import { TaskListComponent } from "./task-list/task-list.component";
import { Coach } from "../model/coach.model";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { NgxSpinnerService } from "ngx-spinner";
import { FilterObject } from "../filter-selection-dropdown/filter-selection-dropdown.component";
import { ILanguageDictionary, LanguageDictionary } from "../model/languagedictionary.model";

export class TaskManagementBase {
    public tasks: Task[] = [];
    // public tasksByDate: Map<string, Task[]> = new Map<string, Task[]>();

    public tasksByDate: TaskByDate[] = [];
    public selectedUserUid: string = null;
    public tomorrow: Date;
    public today: Date;
    public selectedCoach: Coach = null;

    public loadingTasks: boolean = false;

    public selectedSubTask: SubTask = null;
    public loggedInCoach: Coach = null;

    public TaskTimeFrame = TaskTimeFrame;

    public selectedTaskTimeFrame: TaskTimeFrame = TaskTimeFrame.all;

    public TaskTimeFrame2LabelMapping = TaskTimeFrame2LabelMapping;

    public animationIsDisabled: boolean = false;
    public showAllTasks: boolean = true;
    

    constructor(public taskManagementService: TaskManagementService, public toastr: ToastrService, public dialog: MatDialog, public userService: FirestoreService, private spinner: NgxSpinnerService) {
        let now = new Date();
        this.tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
        this.today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    }
        
    loadTasks(){
        this.loggedInCoach = this.userService.getLoggedInUser().coach;
        this.selectedCoach = this.loggedInCoach;
        this.loadingTasks = true;
        try{
            this.setFilteredTasks(this.taskManagementService.getTasks());
            this.taskManagementService.getObservableTasks(this.userService.getLoggedInUser()?.licenceHolderUid).subscribe(tasks => {
                this.loadingTasks = false;
                this.setFilteredTasks(tasks);
                this.setFilterableTags();
                this.setFilterableClients();
                this.setFilterableClientGroups();
            });
        }
        catch(ex){
            this.loadingTasks = false;
            console.error(ex);
            this.toastr.error("Fehler beim Laden der Aufgaben", "",  {
                positionClass: 'toast-bottom-center'
            });
        }
    }

    setFilteredTasks(tasks: Task[]){
        let availableTasks = tasks.filter(task => this.isTaskVisible(task)).map(x => x.clone());
        for(let task of availableTasks){
            let availableTaskIndex = this.tasks.indexOf(this.tasks.find(t => t.id == task.id));
            if(availableTaskIndex >= 0){
                if(this.tasks[availableTaskIndex].timestamp < task.timestamp){
                    this.refreshTaskByDate(this.tasks[availableTaskIndex], task);
                    for(let property of Object.getOwnPropertyNames(task)){
                        this.tasks[availableTaskIndex][property] = task[property];
                    }
                }
            }
            else {
                this.tasks.push(task);
                this.setTaskByDate(task);
            }
        }

        for(let i = this.tasks.length - 1; i >= 0; i--){
            let task = this.tasks[i];
            let taskVailable = availableTasks.find(t => t.id == task.id);
            if(taskVailable == null){
                this.tasks.splice(i, 1);
                this.removeTaskByDate(task);
            }
        }

        this.sortTasks();
        this.sortTasksByDate();

        this.setFutureTasks();
        this.setPastTasks();
        this.setTodayTasks();
    }

    removeTaskByDate(task: Task){
        let date = task.dueDate != null ? new Date(task.dueDate.getFullYear(), task.dueDate.getMonth(), task.dueDate.getDate()) : null;
        if(this.taskByDateIncludes(date)){
            let taskByDate = this.getTaskByDate(date);
            taskByDate.tasks.splice(taskByDate.tasks.indexOf(task), 1);
            if(taskByDate.tasks.length == 0){
                this.tasksByDate.splice(this.tasksByDate.indexOf(taskByDate), 1);
            }
        }
    }

    refreshTaskByDate(oldTask: Task, task: Task){
        let oldDate = oldTask.dueDate != null ? new Date(oldTask.dueDate.getFullYear(), oldTask.dueDate.getMonth(), oldTask.dueDate.getDate()) : null;
        let newDate = task.dueDate != null ? new Date(task.dueDate.getFullYear(), task.dueDate.getMonth(), task.dueDate.getDate()) : null;
        if(oldTask.done != task.done || oldTask.deleted != task.deleted || oldTask.dueDate?.getTime() != task.dueDate?.getTime() || oldTask.assignedCoachUid != task.assignedCoachUid){
            this.removeTaskByDate(oldTask);
            this.setTaskByDate(task);
        }
        else if(oldDate != newDate){
            if(this.taskByDateIncludes(oldDate)){
                let oldTaskByDate = this.getTaskByDate(oldDate);
                oldTaskByDate.tasks.splice(oldTaskByDate.tasks.indexOf(oldTask), 1);
                if(oldTaskByDate.tasks.length == 0){
                    this.tasksByDate.splice(this.tasksByDate.indexOf(oldTaskByDate), 1);
                }
            }
            this.setTaskByDate(task);
        }
    }

    setTaskByDate(task: Task){
        if(task.deleted || task.done) return;
        let date = task.dueDate != null ? new Date(task.dueDate.getFullYear(), task.dueDate.getMonth(), task.dueDate.getDate()) : null;
        if(!this.taskByDateIncludes(date)){
            this.tasksByDate.push(new TaskByDate(date, []));
        }
        this.getTaskByDate(date).tasks.push(task);
    }

    taskByDateIncludes(date: Date){
        return this.getTaskByDate(date) != null;
    }

    getTaskByDate(date: Date){
        return this.tasksByDate.find(t => t.isSameDay(date));
    }

    sortTasksByDate(){
        this.tasksByDate.sort((a, b) => {
            if(a.date == null && b.date == null){
                return 0;
            }
            if(a.date == null){
                return -1;
            }
            if(b.date == null){
                return 1;
            }
            return this.selectedTaskTimeFrame == TaskTimeFrame.future ? a.date.getTime() - b.date.getTime() : b.date.getTime() - a.date.getTime();
        });
        
        this.tasksByDate.forEach((tasks) => {
            this.sortTasksByPriority(tasks.tasks);
        });
    }

    sortTasksByPriority(tasks: Task[]){
        tasks.sort((a, b) => {
            if(a.priority == null && b.priority == null){
                return 0;
            }
            if(a.priority == b.priority){
                return 0;
            }
            if(a.priority == null){
                return 1;
            }
            if(b.priority == null){
                return -1;
            }
            if(a.priority == Priority.low && b.priority == Priority.medium){
                return 1;
            }
            if(a.priority == Priority.medium && b.priority == Priority.high){
                return 1;
            }
            if(a.priority == Priority.low && b.priority == Priority.high){
                return 1;
            }
            if(a.priority == Priority.high && b.priority == Priority.medium){
                return -1;
            }
            if(a.priority == Priority.high && b.priority == Priority.low){
                return -1;
            }
            return 0;
        });
    }

    sortTasks() {
        this.tasks?.sort((a, b) => {
            if(a.dueDate == null && b.dueDate == null){
                return 0;
            }
            if(a.dueDate == null){
                return -1;
            }
            if(b.dueDate == null){
                return 1;
            }
            return a.dueDate.getTime() - b.dueDate.getTime();
        });
    }

    getNumberOfTasksForTimeFrame(taskTimeFrame: TaskTimeFrame) {
        return this.taskManagementService.getTasks().filter(task => this.isTaskInTimeFrame(task, taskTimeFrame) && task.assignedCoachUid == this.selectedCoach.uid && !task.done && !task.deleted)?.length ?? 0
    }

    setSelectedTaskTimeFrame(taskTimeFrame: TaskTimeFrame){
        this.selectedTaskTimeFrame = taskTimeFrame;
        this.setVisibleTasks();
    }
    
    async onCompleteTask(task: Task){
        try{
            await this.taskManagementService.completeTask(task);
            this.setFilteredTasks(this.taskManagementService.getTasks());
        }
        catch(ex){
            console.error(ex);
            this.toastr.error("Fehler beim Abschließen der Aufgabe", "",  {
                positionClass: 'toast-bottom-center'
            });
        }
    }

    async onUnCompleteTask(task: Task){
        try{
            await this.taskManagementService.unCompleteTask(task);
            this.setFilteredTasks(this.taskManagementService.getTasks());
        }
        catch(ex){
            console.error(ex);
            this.toastr.error("Fehler beim Aufheben des Abschlusses der Aufgabe", "",  {
                positionClass: 'toast-bottom-center'
            });
        }
    }

    async onDuplicateTask(task: Task){
        let duplicatedTask = task.clone();
        duplicatedTask.id = null;
        duplicatedTask.title = task.title + ' (Kopie)';
        duplicatedTask.refTaskId = null;
        for(let subTask of duplicatedTask.subTasks){
            subTask.done = false;
        }
        await this.onSaveTask(duplicatedTask, duplicatedTask);
    }

    private searchInput: string = '';

    onSearchInputChanged(searchInput: string){
        this.searchInput = searchInput;
        this.setVisibleTasks();
    }

    onDeleteSearchInput(){
        this.searchInput = '';
        (<HTMLInputElement> document.getElementById('tasksearch-input')).value = ''
        this.setVisibleTasks();
    }

    removeAllFiltersOfFilterObjects(filterObjects:FilterObject[]){
        filterObjects.forEach(filterObject => {
          filterObject.isFiltered = false
        });
      }
    onRemoveAllFilters(){
        this.removeAllFiltersOfFilterObjects(this.filterableTags);
        this.removeAllFiltersOfFilterObjects(this.filterableClients);
        this.setVisibleTasks();
    }

    hasFilter(filterObjects:FilterObject[]):boolean{
      return filterObjects?.filter(x => x.isFiltered)?.length > 0
    }
    
    fallsInFilter(filter:FilterObject[], list:ILanguageDictionary<any>[], isTranslatable: boolean = true):boolean{
      return (!this.hasFilter(filter) || list.filter(x => filter.filter(i => i.isFiltered && this.getCompareText(i.originObject, isTranslatable) === this.getCompareText(x, isTranslatable)).length > 0).length > 0)
    }

    getCompareText(filterObject: ILanguageDictionary<any>, isTranslatable: boolean = true):string{
      if(isTranslatable) return filterObject.comparableValue
      else return filterObject?.originObject?.toString()
    }

    isTaskInTimeFrame(task: Task, taskTimeFrame: TaskTimeFrame): boolean {
        if(taskTimeFrame == TaskTimeFrame.all) return true;
        if(taskTimeFrame == TaskTimeFrame.today){
            return task.dueDate == null || task.dueDate.isSameDate(this.today);
        }
        if(taskTimeFrame == TaskTimeFrame.future){
            return task.dueDate != null && task.dueDate.getTime() >= this.tomorrow.getTime() && !task.dueDate.isSameDate(this.today);
        }
        if(taskTimeFrame == TaskTimeFrame.past){
            return task.dueDate != null && task.dueDate.getTime() < this.today.getTime() && !task.dueDate.isSameDate(this.today);
        }
        return false;
    }

    isTaskVisible(task: Task){
        if(!this.showAllTasks && task.connectedClientUid != this.selectedUserUid && !this.isUserPartOfGroup(task.connectedClientGroup)) return false;
        if(task.assignedCoachUid != this.selectedCoach.uid) return false;
        if(!this.isTaskInTimeFrame(task, this.selectedTaskTimeFrame)) return false;
        if(!this.fallsInFilter(this.filterableClientGroups, [new LanguageDictionary<string>(task.connectedClientGroup, task.connectedClientGroup, task.connectedClientGroup)], false)) return false;
        if(!this.fallsInFilter(this.filterableClients, [new LanguageDictionary<string>(this.getPrintableConnectedClientName(task), this.getPrintableConnectedClientName(task), this.getPrintableConnectedClientName(task))], false)) return false;
        if(!this.fallsInFilter(this.filterableTags, task.tags.map(tag => tag), false)) return false;
        if(this.searchInput == null || this.searchInput == '') return true;
        if(task.title.toLowerCase().includes(this.searchInput.toLowerCase())) return true;
        if(task.description != null && task.description.toLowerCase().includes(this.searchInput.toLowerCase())) return true;
        if(task.subTasks != null && task.subTasks.filter(subTask => !subTask.deleted && subTask.title.toLowerCase().includes(this.searchInput.toLowerCase())).length > 0) return true;
        if(task.connectedClientUid != null && this.getPrintableConnectedClientName(task) && this.getPrintableConnectedClientName(task).toLowerCase().includes(this.searchInput.toLowerCase())) return true;
        if(task.tags != null && task.tags.filter(tag => tag.originObject.toLowerCase().includes(this.searchInput.toLowerCase())).length > 0) return true;
        return false;
    }

    isUserPartOfGroup(groupName: string): boolean{
      if(!groupName || !this.selectedUserUid) return false;
      let user = this.getSelectableClients().find(client => client.uid == this.selectedUserUid);
      return user?.metadataUser?.assignedClientGroups?.includes(groupName);
    }

    setVisibleTasks(){
        this.animationIsDisabled = true;
        this.setFilteredTasks(this.taskManagementService.getTasks());
        setTimeout(async () => {
            this.animationIsDisabled = false;
          }, 1500);
    }

    public futureTasks: Task[] = [];

    setFutureTasks(){
        this.futureTasks = this.tasks.filter(task => task.dueDate != null && task.dueDate.getTime() >= this.tomorrow.getTime() && !task.dueDate.isSameDate(this.today));
    }

    public pastTasks: Task[] = [];

    setPastTasks(){
        this.pastTasks = this.tasks.filter(task => task.dueDate != null && task.dueDate.getTime() < this.today.getTime() && !task.dueDate.isSameDate(this.today));
    }

    public todayTasks: Task[] = [];

    setTodayTasks(){
        this.todayTasks = this.tasks.filter(task => task.dueDate == null || task.dueDate.isSameDate(this.today));
    }

    filterableClients: FilterObject[] = [];

    setFilterableClients(){
        let clients = this.getSelectableClients();
        this.filterableClients = [];
        let printableTaskClients = this.tasks.map(task => this.getPrintableConnectedClientName(task)).filter((value, index, self) => self.indexOf(value) === index);
        if(clients == null) return;
        for(let taskClient of printableTaskClients){
            if(taskClient != null && taskClient != '-'){
                this.filterableClients.push(new FilterObject(new LanguageDictionary<string>(taskClient, taskClient, taskClient), false));
            }
        }
    }

    filterableTags: FilterObject[] = [];

    setFilterableTags(){
        this.filterableTags = this.taskManagementService.selectableTags.map(x => new FilterObject(x, false));
    }
    
    getSelectableClients() {
        return this.userService.getAccessibleClients();
    }

    filterableClientGroups: FilterObject[] = [];

    setFilterableClientGroups(){
        this.filterableClientGroups = this.tasks.map(task => task.connectedClientGroup).filter((value, index, self) => self.indexOf(value) === index && value != null).map(x => new FilterObject(new LanguageDictionary<string>(x, x, x), false));
    }

    getSelectableClientGroups() {
        return this.userService.getClientGroups();
    }
    
    getPrintableConnectedClientOrGroupName(task: Task): string {
        if(!task.connectedClientUid && !task.connectedClientGroup) return '-';
        if(task.connectedClientGroup) return task.connectedClientGroup;
        return this.getPrintableConnectedClientName(task);
    }

    getPrintableConnectedClientName(task: Task): string {
        if(!task.connectedClientUid) return '-';
        return this.userService.getAccessibleClients()?.find(client => client.uid == task.connectedClientUid)?.getName();
    }


    onEditTask(task: Task){
      this.taskManagementService.selectedTask = task.clone();
      this.taskManagementService.showTaskManagementSideBar();
    }

    public taskSaved: boolean = false;
    public taskEditCanceled: boolean = false;

    async onSaveTask(task: Task, selectedTask: Task = null){
        try {
        if(!task.title){
            this.toastr.error("Bitte gib einen Titel ein", "",  {
            positionClass: 'toast-bottom-center'
            });
            return;
        }
        if(task.id){
            if(this.hasRepetitionChanged(task)){
                await this.taskManagementService.updateTaskRepetition(task);
            }
            else if(task.dueDate != null && task.repetition != Repetition.once) {
                let radioButtonOptions = ['Diese Aufgabe', 'Diese und alle folgende Aufgaben'];
                let dialogRef = this.dialog.open(RadioButtonsConfirmationDialogComponent, {
                data: {
                    title: 'Wiederkehrende Aufgabe speichern?',
                    message: '',
                    radioButtons: radioButtonOptions
                    }});
                dialogRef.afterClosed().subscribe(async result => {
                if(result){
                    if(result == radioButtonOptions[1]){
                        await this.taskManagementService.updateAllRecurringTasks(task, true);
                    }
                    else {
                        await this.taskManagementService.updateSingleTask(task);
                    }
                    if(selectedTask){
                        this.setNewSelectedTask(selectedTask);
                    }
                    else {
                        this.taskSaved = true;
                    }
                }
                });
            }
            else {
                await this.taskManagementService.updateSingleTask(task);
                if(selectedTask){
                    this.setNewSelectedTask(selectedTask);
                }
                else {
                    this.taskSaved = true;
                }
            }
            // this.setNewSelectedTask(selectedTask);
            this.taskManagementService.selectedTask = null;
        }
        else {
            await this.taskManagementService.addTask(task);
            if(selectedTask){
            this.setNewSelectedTask(selectedTask);
            }
            else {
            this.taskSaved = true;
            }
            this.taskManagementService.selectedTask = null;
        }


        }
        catch(ex){
        console.error(ex);
        this.toastr.error("Fehler beim Speichern der Aufgabe", "",  {
            positionClass: 'toast-bottom-center'
        });
        }
    }

    
  onDeleteTask(task: Task){
    if(task.id){
      if(task.dueDate != null && task.repetition != Repetition.once) {
        
        let dialogRef = this.dialog.open(RadioButtonsConfirmationDialogComponent, {
          data: {
            title: 'Wiederkehrende Aufgabe löschen?',
            message: '',
            radioButtons: ['Diese Aufgabe', 'Diese und alle folgende Aufgaben', 'Alle Aufgaben']
            }});
        dialogRef.afterClosed().subscribe(async result => {
          if(result){
            try{
              this.spinner.show();
              if(result == 'Alle Aufgaben'){
                await this.taskManagementService.deleteAllRecurringTasks(task, false);
              }
              else if(result == 'Diese und alle folgende Aufgaben'){
                await this.taskManagementService.deleteAllRecurringTasks(task, true);
              }
              else if(result == 'Diese Aufgabe'){
                await this.taskManagementService.deleteOnlyOneRecurringTask(task);
              }
            }
            catch(ex) {
              console.error(ex);
              this.toastr.error("Fehler beim Löschen der Aufgabe", "",  {
                positionClass: 'toast-bottom-center'
              });
            }
            finally{
              this.spinner.hide();
            }
          }
        });
      }
      else {
        let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          data: {
            title: 'Aufgabe löschen',
            message: 'Soll die Aufgabe wirklich gelöscht werden?'
            }});
        dialogRef.afterClosed().subscribe(async result => {
          if(result){
            try{
              await this.taskManagementService.deleteSingleTask(task);
            }
            catch(ex) {
              console.error(ex);
              this.toastr.error("Fehler beim Löschen der Aufgabe", "",  {
                positionClass: 'toast-bottom-center'
              });
            }
          }
        });
      }
      this.taskManagementService.selectedTask = null;
    }


  }

    
    setNewSelectedTask(selectedTask: Task = null){
        if(selectedTask){
            this.taskManagementService.selectedTask = selectedTask;
        }
        else {
            this.taskManagementService.selectedTask = new Task();
        }
        
        if(this.taskManagementService.selectedTask.licenceHolderUid == null || this.taskManagementService.selectedTask.licenceHolderUid == ''){
            this.taskManagementService.selectedTask.licenceHolderUid = this.userService.getLoggedInUser().licenceHolderUid;
        }
        if(this.taskManagementService.selectedTask.assignedCoachUid == null || this.taskManagementService.selectedTask.assignedCoachUid == ''){
            this.taskManagementService.selectedTask.assignedCoachUid = this.selectedCoach.uid;
        }
        if(this.taskManagementService.selectedTask.connectedClientUid == null || this.taskManagementService.selectedTask.connectedClientUid == ''){
            this.taskManagementService.selectedTask.connectedClientUid = this.selectedUserUid;
        }
    }

    
    hasRepetitionChanged(task: Task) {
        let originalTask = this.tasks.find(t => t.id == task.id);
        if(originalTask == null) return false;
        return originalTask.repetition != task.repetition || originalTask.repetitionMultiplier != task.repetitionMultiplier || originalTask.dueDate?.getTime() != task.dueDate?.getTime();
    }
    
    onAddSubTask(task: Task){
        let newSubTask = new SubTask();
        newSubTask.focus = true;
        task.subTasks.push(newSubTask);
        this.selectedSubTask = newSubTask;
    }

    async onSaveAndAddSubTasks(task: Task, subTask: SubTask){
        let filteredSubTasks = task.subTasks.filter(subTask => !subTask.deleted);
        let subTaskIndex = filteredSubTasks.indexOf(subTask);
        await this.onSaveSubTasks(task);
        if(subTaskIndex == filteredSubTasks.length - 1){
            this.onAddSubTask(task);
        }
    }

    async onSaveSubTasks(task: Task){
        // await this.taskManagementService.updateSingleTask(task);

        try {
            if(task.dueDate != null && task.repetition != Repetition.once){
                await this.taskManagementService.updateAllRecurringTasks(task, false);
            }
            else {
                await this.taskManagementService.updateSingleTask(task);
            }
            this.toastr.success("Unteraufgabe gespeichert", "",  {
                positionClass: 'toast-bottom-center',
                timeOut: 1000
            });
        }
        catch(ex){
            console.error(ex);
            this.toastr.error("Fehler beim Speichern der Aufgabe", "",  {
                positionClass: 'toast-bottom-center'
            });
        }
    }

    
    async onRemoveSubTask(task: Task, subTask: SubTask){
        if(subTask.id){
            subTask.deleted = true;
            await this.onSaveSubTasks(task);
        }
        else {
            task.subTasks.splice(task.subTasks.indexOf(subTask), 1);
        }
    }

    
    async onCompleteSubTask(task: Task, subTask: SubTask){
        subTask.done = true;
        try{
        await this.taskManagementService.updateSingleTask(task);
        }
        catch(ex){
        console.error(ex);
        this.toastr.error("Fehler beim Speichern der Aufgabe", "",  {
            positionClass: 'toast-bottom-center'
        });
        }
    }
    async onUnCompleteSubTask(task: Task, subTask: SubTask){
        subTask.done = false;
        try{
        await this.taskManagementService.updateSingleTask(task);
        }
        catch(ex){
        console.error(ex);
        this.toastr.error("Fehler beim Speichern der Aufgabe", "",  {
            positionClass: 'toast-bottom-center'
        });
        }
    }
}


enum TaskTimeFrame {
    all,
    today,
    past,
    future
}

const TaskTimeFrame2LabelMapping: Record<TaskTimeFrame, string> = {
    [TaskTimeFrame.all]: "Alle",
    [TaskTimeFrame.past]: "Überfällig",
    [TaskTimeFrame.future]: "Zukünftig",
    [TaskTimeFrame.today]: "Heute",
};

class TaskByDate{
    public date: Date;
    public tasks: Task[];

    constructor(date: Date, tasks: Task[]){
        this.date = date || null;
        this.tasks = tasks || [];
    }

    isSameDay(date: Date){
        if(this.date == null && date == null) return true;
        if(this.date == null || date == null) {
            return false;
        }
        return this.date.getTime() == date.getTime();
    }

    getPrintableDate(){
        if(this.date == null) return 'Kein Datum';
        if(this.date.getTime() == new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime()) return 'Heute';
        if(this.date.getTime() == new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1).getTime()) return 'Morgen';
        if(this.date.getTime() == new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1).getTime()) return 'Gestern';
        return this.date.toLocaleDateString();
    }
}