<div class="dialog-topbar">
    <h3>{{title}}</h3>
</div>
<div class="dialog-content-wrapper">
    <p [innerHTML]="message"></p>
    <br>
    <div class="actions row">
        <!-- <button class="button-filled small light float-right" (click)="onConfirmDialog()">{{positiveButton}}</button>
        <button class="button-filled small light float-right marginright-25" (click)="onCancelDialog()">{{negativeButton}}</button> -->
        <div class="col">
            <button class="button-filled small light" (click)="onCancelDialog()">{{negativeButton}}</button>
        </div>
        <div class="col">
            <button class="button-filled small light float-right" (click)="onConfirmDialog()">{{positiveButton}}</button>
        </div>
    </div>
</div>