<div *ngIf="!isLoading && isProductPageEnabled()" class="container">
    <div class="row">
        <div class="col-12 col-lg-4"></div>
        <div class="col-12 col-lg-4">
            <div class="branding-wrapper">
                <h3>Willkommen!</h3>
                <img draggable="false" *ngIf="brandingImageUrl" class="branding-image" src="{{brandingImageUrl}}" alt="">
            </div>
        </div>
        <div class="col-12 col-lg-4">
            <div *ngIf="user && userSelected">
                <div class="user-selection">
                    <div class="profile-container">
                        <div class="profile-picture">
                            <button class="profile-button" title="Profilbild hochladen">
                                <img draggable="false" src="{{user.profilePictureUrl}}">
                            </button>
                        </div>
                        <h3 class="name">{{user?.getName()}}</h3>
                    </div>
                    Nicht du?<br>
                    <div class="spacer-10"></div>
                    <button class="button-filled small white" id="logout-button" (click)="onLogout()">Abmelden</button>
                </div>
                <div class="spacer-25"></div>
            </div>
        </div>

        <div class="col-12">
            
            <div class="row">
                <div class="col">
                    <ng-container *ngIf="isProductAvailable()">
                        <app-checkout-view [setProduct]="product" [setPaymentSettings]="paymentSettings"></app-checkout-view>
                    </ng-container>
                    <ng-container *ngIf="!isProductAvailable()">
                        <div class="product-wrapper layout-box padding-0">
                            <div class="product-content-wrapper">
                                <div class="spacer-25"></div>
                                <div class="bold">Dieses Produkt ist leider nicht mehr verfügbar.</div>
                                <div class="spacer-25"></div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                
            </div>
        </div>
        
        <div class="checkout-footer col-12">
            <div class="label">Anbieter:</div>
            <ng-container *ngIf="paymentSettings?.alternativeImprintAddress">
                <div class="whitespace-prewrap">{{paymentSettings?.alternativeImprintAddress}}</div>
                <div class="spacer-25"></div>
            </ng-container>
            <ng-container *ngIf="!paymentSettings?.alternativeImprintAddress">
                <div class="whitespace-prewrap">{{paymentSettings?.companyName}}</div>
                <div class="spacer-10"></div>
                <div class="whitespace-prewrap">{{paymentSettings?.address}}</div>
                <div class="spacer-10"></div>
                <div class="whitespace-prewrap">{{paymentSettings?.phone}}</div>
                <div class="whitespace-prewrap">{{paymentSettings?.email}}</div>
                <div class="spacer-10"></div>
                <div *ngIf="paymentSettings?.vatIdNo" class="whitespace-prewrap">USt-IdNr: {{paymentSettings?.vatIdNo}}</div>
                <div class="spacer-25"></div>
            </ng-container>
        </div>
    </div>
    
</div>
<div *ngIf="!isLoading && !isProductPageEnabled()" class="textalign-center">
    <div class="row">
        <div class="col-12">
            <div class="spacer-50"></div>
            <h3>Kein Produkt gefunden.</h3>
        </div>
    </div>
</div>
<div *ngIf="isLoading" class="textalign-center">
    <div class="row">
        <div class="col-12">
            <div class="spacer-50"></div>
            <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
        </div>
    </div>
</div>

<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>