<div class="dialog-topbar">
    <div class="icon-button" (click)="onSaveFile()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
        <span class="icon-button-text">Speichern</span>
    </div>
    <div class="display-inlineblock marginleft-25">
        <app-language-selector></app-language-selector>
    </div>
    <div class="icon-button float-right" (click)="onCancelEdit()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        <span class="icon-button-text">Schließen</span>
    </div>
    <button class="icon-button float-right marginright-25" (click)="onDeleteFile()" *ngIf="file.id">
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"  fill="currentColor" class="bi bi-trash padding-4" viewBox="0 0 16 16">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
        <span class="icon-button-text">Löschen</span>
    </button>
</div>
<div class="dialog-content-wrapper">
    <div class="row">
        <div *ngIf="!file.isImage()" class="col-lg-2 col-md-5">
            <div class="row">
                <div class="col-lg col-6 mb-2">
                    <label for="thumbnail-input">Thumbnail</label>
                    <div class="thumbnail-image link image-dropzone" (click)="openThumbnailDialog()" (dragover)="onDragOver($event)" (drop)="onDropThumbnail($event)" [ngStyle]="{'background-image':'url('+ thumbnailImageSrc +')'}">
                        <div *ngIf="!thumbnailImageSrc" for="thumbnail-input" class="dropzone-hint">
                            Thumbnail (.png oder .jpg) ablegen oder auswählen
                        </div>
                        <input id="thumbnail-input" type="file" style="display: none;" accept=".jpg, .png, .heic, .heif" (change)="uploadThumbnail($event)">
                    </div>
                </div>
            </div>
            <div class="row">
                <button *ngIf="thumbnailImageSrc" class="label" (click)="onRemoveThumbnail()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"  fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                    </svg>
                    Thumbnail entfernen
                </button>
            </div>
            <div class="spacer-10"></div>
        </div>
        <div class="col-lg-10 col-sm-12">
            <div class="row">
                <div class="col-lg-6 col-sm-12">
                    <label for="input-exercise-name">
                        <ng-container *ngIf="sharedFileType == SharedFileType.Folder">Ordnername</ng-container>
                        <!-- <ng-container *ngIf="sharedFileType == SharedFileType.File">Dateiname</ng-container> -->
                        <ng-container *ngIf="sharedFileType == SharedFileType.WebLink || sharedFileType == SharedFileType.File">Vorschautext</ng-container>
                    </label>
                    <input id="input-exercise-name" required class="input plannedmeal-name form-control wide-input" [ngModel]="file.name.GetValue(languageService.selectedLanguageCode)" (input)="setLanguageDictionaryValue(file, 'name', $event.target.value)" placeholder="Name" type="text">
                </div>
            </div>
            <div class="row">
                <div class="spacer-10"></div>
                <div class="col-lg-6 col-sm-12">
                    <div  class="display-inlineblock">
                        <div class="spacer-10"></div>
                        <input class="input checkbox" type="checkbox" [(ngModel)]="file.showOnDashboard"> Auf Dashboard anzeigen
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12" *ngIf="environment.isWhitelabel">
                    <div class="display-inlineblock">
                        <div class="spacer-10"></div>
                        <input class="input checkbox" type="checkbox" [(ngModel)]="file.public"> Freemium-Nutzern anzeigen
                    </div>
                    <div class="display-inlineblock marginleft-10" *ngIf="file.public && !(sharedFileType == SharedFileType.Folder || sharedFileType == null && file.isFolder())">
                        <div class="spacer-10"></div>
                        <input class="input checkbox" type="checkbox" [(ngModel)]="file.freeContent"> Frei verfügbar
                    </div>
                </div>
                <div class="spacer-10"></div>
            </div>
            <div class="row" *ngIf="sharedFileType == SharedFileType.WebLink">
                <div class="col-lg-6 col-sm-12">
                    <div class="spacer-10"></div>
                    <label for="linkinput">Link</label>
                    <input id="linkinput" class="input wide-input" [(ngModel)]="file.webLink" type="url" placeholder="https://example.com" [pattern]="urlLinkPattern" required>
                    <div class="spacer-10"></div>
                </div>
            </div>
            <div class="row" *ngIf="sharedFileType == SharedFileType.VideoLink">
                <div class="col-lg-6 col-sm-12">
                    <div class="spacer-10"></div>
                    <label for="linkinput">Link</label>
                    <input id="linkinput" class="input wide-input" [(ngModel)]="file.webLink" type="url" [pattern]="SharedFile.urlVideoLinkPattern" required placeholder="YouTube/Vimeo-Link einfügen...">
                    <div class="label">
                        Füge einen Embed-Link eines YouTube- oder Vimeo-Videos ein. Diesen erhältst du, indem du auf Teilen und Video einbetten klickst und dann nur den Link kopierst. Das Format sollte so aussehen: https://player.vimeo.com/video/... bzw. https://www.youtube.com/embed/...
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!file.personal && (sharedFileType == SharedFileType.Folder || sharedFileType == null && file.isFolder())">
                <div class="col-lg-6 col-sm-12">
                    <div class="spacer-25"></div>
                    <label for="dropdownRepetion">Verfügbar für</label>
                    <div class="wrapper-repetition-selection">
                        <button class="button-filled light small dropdown-toggle margin-inbetween" [class.button-invalid]='getPrintableAssignments(file)?.length === 0' type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getPrintableAssignments(file)}}</button>
                        <div class="dropdown-menu dropdown-recipient" aria-labelledby="dropdownNotificationTarget">
                            <div class="dropdown-item link" (click)="onTargetSelectionChangedGroup('Alle');$event.stopPropagation()">
                                <input [disabled]="!canAccessGroup('Alle')" class="input checkbox" type="checkbox" [checked]="file?.assignedGroupNames.includes('Alle')"><div class="display-inlineblock">Alle</div>
                            </div>
                            <div class="dropdown-divider"></div>
                            <div class="dropdown-item link" *ngFor="let group of getSelectableClientGroups()" (click)="onTargetSelectionChangedGroup(group);$event.stopPropagation()">
                                <input [disabled]="group != 'Alle' && areAllUsersTargeted() || !canAccessGroup(group)" class="input checkbox" type="checkbox" [checked]="file?.assignedGroupNames.includes(group)"><div class="display-inlineblock">{{group}}</div>
                            </div>
                            <div class="dropdown-divider"></div>
                            <div class="dropdown-item link" *ngFor="let user of getSelectableClients()" (click)="onTargetSelectionChangedUser(user);$event.stopPropagation()">
                                <input [disabled]="areAllUsersTargeted()" class="input checkbox" type="checkbox" [checked]="file?.assignedUids.includes(user.uid)"><div class="display-inlineblock">{{user.getName()}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="file.isVideo() || file.isVideoLink() || sharedFileType == SharedFileType.VideoLink || sharedFileType == SharedFileType.Folder || sharedFileType == null && file.isFolder()">
                <div class="col-12">
                    <div class="spacer-10"></div>
                    <label for="input-exercise-name">Beschreibung</label>
                    <app-md-editor id="editor-note" class="min-height" [placeHolder]="descriptionPlaceholder"
                        (valueChanged)="setLanguageDictionaryValue(file, 'description', $event)"
                        [inputValue]="file.description.GetValue(languageService.selectedLanguageCode)">
                    </app-md-editor>
                </div>
            </div>
            <div class="spacer-10"></div>
        </div>
    </div>
</div>