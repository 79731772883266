export class AutomaticPushNotification {
    public id: string;
    public active: boolean = true;
    public nextExecutionDate: Date = new Date(Date.now());
    public repetition: string = Repetition.once;
    public repetitionMultiplier: number = 1;
    public title: string = "";
    public description: string = "";
    public groupNames: string[];
    public userUids: string[];
    public coachUids: string[];

    public coachUid: string = '';
    public licenceHolderUid: string = '';


    constructor();
    constructor(init: AutomaticPushNotification);
    constructor(init?: AutomaticPushNotification){
      this.id = init && init.id || null
      this.active = init && init.active || false
      this.nextExecutionDate = init && init.nextExecutionDate || new Date()
      this.repetition = init && init.repetition || Repetition.once.toString()
      this.repetitionMultiplier = init && init.repetitionMultiplier || 1
      this.title = init && init.title || null
      this.description = init && init.description || null
      this.groupNames = init && init.groupNames || []
      this.userUids = init && init.userUids || []
      this.coachUids = init && init.coachUids || []
      this.coachUid = init && init.coachUid || null
      this.licenceHolderUid = init && init.licenceHolderUid || ''
    }
}

export class DropdownItem {
  uid: string;
  name: string;
  checked: boolean;
  
  constructor(uid: string, name: string, checked: boolean) {
    this.uid = uid
    this.name = name
    this.checked = checked
  }
}

export enum Repetition {
    once = "once",
    daily = "daily",
    weekly = "weekly",
    monthly = "monthly",
    yearly = "yearly",
    everyXDays = "everyXDays"
  }
export const Repetition2LabelMapping: Record<Repetition, string> = {
    [Repetition.once]: "Einmalig",
    [Repetition.daily]: "Täglich",
    [Repetition.weekly]: "Wöchentlich",
    [Repetition.monthly]: "Monatlich",
    [Repetition.yearly]: "Jährlich",
    [Repetition.everyXDays]: "Alle x Tage"
};
  
export const Label2RepetitionMapping: Record<string, Repetition> = {
    ["Einmalig"]: Repetition.once,
    ["Täglich"]: Repetition.daily,
    ["Wöchentlich"]: Repetition.weekly,
    ["Monatlich"]: Repetition.monthly,
    ["Jährlich"]: Repetition.yearly,
    ["Alle x Tage"]: Repetition.everyXDays
}