import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from 'src/app/model/user.model';
import { RpeTableDialogComponent } from '../rpe-table-dialog/rpe-table-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TrainingVariable } from 'src/app/model/training-variables.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { TrainingService } from 'src/app/services/training.service';

@Component({
  selector: 'app-one-rm-editor',
  templateUrl: './one-rm-editor.component.html',
  styleUrls: ['./one-rm-editor.component.css']
})
export class OneRmEditorComponent {

  constructor(public dialog: MatDialog, public userService: FirestoreService, public trainingService: TrainingService) {}

  public user: User
  public selectedCoach: User
  public trainingVariables: TrainingVariable[]
  public oneRmTrainingVariables: TrainingVariable[]
  public allAvailableForeignVariables: TrainingVariable[] = []
  public hasChanges = false

  @Input() set User(value: User) {
    this.user = value;
    this.trainingVariables = this.user.trainingVariables ?? []
    this.oneRmTrainingVariables = this.trainingVariables.filter(x => x.id.startsWith('ONERM_'))
    this.allAvailableForeignVariables = this.userService.getLoggedInUser()?.trainingSettingsLicenceHolder?.trainingVariables?.filter(x => !this.trainingVariables?.find(y => y?.id == x?.id));
  }

  @Input() set SelectedCoach(value: User) {
    this.selectedCoach = value;
  }

  onOpenRpeDialog(variable: TrainingVariable) {
    var dialog = this.dialog.open(RpeTableDialogComponent, { data: { user: this.user, fixedOneRM: variable.weight } });
    dialog.afterClosed().subscribe(result => {
      if (result && result.weight) {
        this.trainingService.rpeTableCopyResult = result
      }
    });
  }

  setWeight(trainingVariable: TrainingVariable, value: string) {
    if (value == null || value.length == 0) {
      trainingVariable.weight = null;
    }
    let weight = parseFloat(value);
    if (weight) {
      trainingVariable.weight = weight;
    }
    this.hasChanges = true
  }
  setName(trainingVariable: TrainingVariable, value: string) {
    trainingVariable.name = value;
    var goalVariable = this.getGoalVariableForOneRmVariable(trainingVariable)
    if (goalVariable) goalVariable.name = value
  }

  isVariableSelectable(variable: TrainingVariable) {
    return this.trainingVariables.find(x => x.name == variable.name) == null;
  }
  onCreateNewVariable() {
    let newVariable = new TrainingVariable();
    var id = newVariable.id
    newVariable.id = 'ONERM_' + id
    newVariable.weight = null
    this.trainingVariables.push(newVariable)
    this.oneRmTrainingVariables.push(newVariable)

    newVariable = new TrainingVariable();
    newVariable.id = 'GOAL1RM_' + id
    newVariable.weight = null
    this.trainingVariables.push(newVariable)
  }

  getGoalVariableForOneRmVariable(oneRmVariable: TrainingVariable) {
    return this.trainingVariables.find(x => x.id == 'GOAL1RM_' + oneRmVariable.id.split('_')[1]);
  }

  onAddAvailableVariable(variable: TrainingVariable) {
    let newVariable = variable.clone()
    this.trainingVariables.push(newVariable)
    this.oneRmTrainingVariables.push(newVariable)

    newVariable = newVariable.clone()
    newVariable.id = variable.id.replace('ONERM_', 'GOAL1RM_')
    newVariable.weight = null
    this.trainingVariables.push(newVariable)
  }

  async onSave() {
    await this.userService.updateTrainingVariables(this.user, this.trainingVariables);
    this.hasChanges = false
  }
}
