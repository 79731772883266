import { TrainingService } from './../services/training.service';
import { LanguageDictionary } from '../model/languagedictionary.model';
import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-search-input-select',
  templateUrl: './search-input-select.component.html',
  styleUrls: ['./search-input-select.component.css']
})
export class SearchInputSelectComponent implements OnInit {

  constructor(private renderer: Renderer2, public trainingService:TrainingService) { }

  public selectedItems:LanguageDictionary<any>[] = []
  @Input() set SelectedItems(value: LanguageDictionary<any>[]){
    this.selectedItems = value || []
  }
  @Input() SelectableItems:LanguageDictionary<any>[] = []
  @Input() FreeInput: boolean = false
  @Input() LanguageCode: string = "de"
  @Input() disabled: boolean = false;
  @Input() translatable: boolean = true;
  @Input() singleMode: boolean = false;

  @Output() SelectedItemsChange = new EventEmitter<LanguageDictionary<any>[]>();


  public FilteredSelectableItems:LanguageDictionary<any>[] = []

  public SearchInputFocused:boolean = false

  public searchInputValue:string = ""

  ngOnInit(): void {
    this.updateSelectableItems()
    this.renderer.listen('window', 'click',(e) => {
      this.SearchInputFocused = false
    })
  }
  

  onFocusRestrictionSearchInput(){
    if(this.disabled) return
    this.SearchInputFocused = true;
    this.updateSelectableItems()
  }

  onRemoveItemFromSelectedItems(item:LanguageDictionary<any>){
    if(this.disabled) return
    const index = this.selectedItems.indexOf(item);
    if(index !== -1){
      this.selectedItems.splice(index, 1);
    }
    this.updateSelectableItems()
    this.SelectedItemsChange.emit(this.selectedItems)
  }

  onInputEnter(){
    if(this.disabled) return
    var availableItem;
    
    if(this.translatable) availableItem = this.selectedItems?.filter(x => x.AsMap()[this.LanguageCode].toLowerCase() == this.searchInputValue.toLowerCase())[0]
    else availableItem = this.selectedItems?.filter(x => x.originObject.toString().toLowerCase() == this.searchInputValue.toLowerCase())[0]
    if(availableItem){
      this.onSearchResultSelected(availableItem)
    }
    else if(this.FreeInput === true && this.searchInputValue){
      var newItem = new LanguageDictionary()
      if(this.translatable) newItem.ParseFromMap({[this.LanguageCode]: this.searchInputValue || ""})
      else newItem.originObject = this.searchInputValue
      this.selectedItems.push(newItem)
      this.searchInputValue = ""
      this.updateSelectableItems()
    }
    this.SelectedItemsChange.emit(this.selectedItems)
  }

  onSearchResultSelected(item:LanguageDictionary<any>){
    if(this.disabled) return
    if(this.singleMode){
      this.selectedItems = [];
    }
    if(!this.selectedItems?.includes(item)) this.selectedItems.push(item)
    this.searchInputValue = ""
    this.updateSelectableItems()
    this.SelectedItemsChange.emit(this.selectedItems)
  }

  onSearchInputChanged(searchText:string){
    if(this.disabled) return
    this.updateSelectableItems()
    this.SearchInputFocused = true;
  }
  
  updateSelectableItems(){
    if(this.disabled) return
    if(this.translatable) this.FilteredSelectableItems = this.SelectableItems?.filter(x => !this.selectedItems?.map(x => x.comparableValue).includes(x.comparableValue))?.filter(x => x.GetValue(this.LanguageCode)?.toLowerCase().includes(this.searchInputValue?.toLowerCase())) || []
    else this.FilteredSelectableItems = this.SelectableItems?.filter(x => !this.selectedItems?.map(x => x.originObject.toString()).includes(x.originObject.toString()))?.filter(x => x.originObject.toString()?.toLowerCase().includes(this.searchInputValue?.toLowerCase())) || []
  }
}
