import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FirebaseProject, IndividualFirebase } from 'src/app/app.module';
import { FileSharingComponent } from 'src/app/file-sharing/file-sharing.component';
import { User } from 'src/app/model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-file-sharing-dialog',
  templateUrl: './file-sharing-dialog.component.html',
  styleUrls: ['./file-sharing-dialog.component.css']
})
export class FileSharingDialogComponent implements OnInit {

  public user: User
  public referencedSharedFileId: string

  constructor(public dialogRef: MatDialogRef<FileSharingComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) private data: {user: User, referencedSharedFileId: string}) {
    this.user = data.user
    this.referencedSharedFileId = data.referencedSharedFileId
  }

  ngOnInit(): void {
  }

  onConfirmDialog() {
    this.dialogRef.close()
  }

}
