import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { User } from 'src/app/model/user.model';
import { QuestionaireOverviewComponent } from '../questionaire-overview/questionaire-overview.component';

@Component({
  selector: 'app-user-questionaires-overview',
  templateUrl: './user-questionaires-overview.component.html',
  styleUrls: ['./user-questionaires-overview.component.css']
})
export class UserQuestionairesOverviewComponent implements OnInit {

  @Input() user: User;

  @ViewChild(QuestionaireOverviewComponent, {static : true}) questionaireOverviewComponent : QuestionaireOverviewComponent;
  
  constructor() { }

  ngOnInit(): void {
  }

  onAssignQuestionaire(){
    this.questionaireOverviewComponent.onAssignQuestionaires()    
  }

  async onCreateNewQuestionaire(){
    this.questionaireOverviewComponent.onCreateQuestionaire()
  }
}
