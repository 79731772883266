import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NutritionalGoalV2 } from '../model/nutritionalgoalv2.model';
import { CycleConfig, Situation, SituationTranslation, SituationType } from '../model/nutritionconfig.model';
import { User } from '../model/user.model';
import { NutritionStatisticsItem } from '../model/nutritionstatistics.model';
import { NutritionalValue } from '../model/basenutritionfact.model';
import { BaseNutritionFact } from '../model/basenutritionfact.model';
import { Moment } from 'moment';

@Component({
  selector: 'app-nutrition-config-editor',
  templateUrl: './nutrition-config-editor.component.html',
  styleUrls: ['./nutrition-config-editor.component.css']
})
export class NutritionConfigEditorComponent implements OnInit {

  @Input() expertMode: boolean
  @Input() user: User
  @Input() config: CycleConfig

  @Output() configChanged = new EventEmitter<CycleConfig[]>();
  
  public selectedSituation: Situation

  public extendedGoals: any[] = []

  public extendedGoalsSelected = false;

  public BaseNutritionFact = BaseNutritionFact;

  constructor() {}

  public situationTranslation = SituationTranslation
  public situationTypes = Object.values(SituationType).filter(value => typeof value === 'string' && value != SituationType.default && value != SituationType.none)
  onSituationTypeSelected(type: SituationType) {
    this.selectedSituation.type = type
    if (type == SituationType.custom) {
      this.selectedSituation.customName = this.situationTranslation[type]
    }
  }

  ngOnInit(): void {
    this.selectedSituation = this.config.situations[0]
    if (this.selectedSituation.nutritionalGoals.length == 0) {
      var nutritionalGoal = new NutritionalGoalV2()
      nutritionalGoal.applicableWeekdays = ['all']
      this.selectedSituation.nutritionalGoals.push(nutritionalGoal)
    }

    
    Object.values(NutritionalValue).filter((key) => typeof key === "string").forEach(key => {
      if(this.isAllowedCommonTargetValue(key.toString())){
        this.extendedGoals.push({
          name: key,
          group: BaseNutritionFact.getNutritionalValueGroup(key.toString()),
          value: null
        });
      }
    });
  }

  private isAllowedCommonTargetValue(targetValue: string):boolean {
    switch (NutritionalValue[targetValue].toString()) {
      case NutritionalValue.calories.toString():
        return false;
      case NutritionalValue.fat.toString():
        return false;
      case NutritionalValue.carbohydrates.toString():
        return false;
      case NutritionalValue.protein.toString():
        return false;
      default:
        return true;
    }
  }
  
  onSelectSituation(situation: Situation) {
    this.extendedGoalsSelected = false;
    if (this.selectedSituation) {
      this.selectedSituation.nutritionalGoals.forEach(goal => {
        goal.situationId = this.selectedSituation.id
        goal.situation = this.selectedSituation
      })
    }
    this.selectedSituation = situation
  }

  onSelectExtendedGoals(){
    this.extendedGoalsSelected = true;
    this.selectedSituation = null;
  }
  
  onAddSituation() {
    var situation = new Situation()
    situation.type = SituationType.training
    if (this.config?.situations.filter(s => s.type == SituationType.training).length > 0) situation.type = SituationType.restday
    if (this.config?.situations.filter(s => s.type == SituationType.restday).length > 0) situation.type = SituationType.custom

    var nutritionalGoal = new NutritionalGoalV2()
    nutritionalGoal.applicableWeekdays = ['all']
    situation.nutritionalGoals.push(nutritionalGoal)
    this.selectedSituation = situation
    
    if (this.config) this.config.situations.push(situation)
  }

  onDeleteSituation(situation: Situation) {
    if (situation.id != SituationType.default) {
      this.config.situations.splice(this.config.situations.indexOf(situation), 1)
      if (this.selectedSituation == situation) this.selectedSituation = this.config.situations[0]
    }
  }

  public today = new Date()
  startDateFilterNutritionConfig = (d: Moment | null): boolean => {
    if (this.config.id && this.config.startDate < this.today) return false
    if (d?.toDate() < this.today && !d?.toDate().isSameDate(this.today)) return false

    if (this.user.configs.length > 0) {
      var hasActiveConfig = false
      for (var c of this.user.configs) {
        if (c.isActiveAtDate(d?.toDate())) hasActiveConfig = true
      }
      if (!hasActiveConfig) return true
      var maxStartDate = this.user.configs[0].startDate
      if (d?.toDate() < maxStartDate || d?.toDate().isSameDate(maxStartDate)) return false
    }
    return true
  }
  endDateFilterNutritionConfig = (d: Moment | null): boolean => {
    if (d?.toDate() < this.today && !d?.toDate().isSameDate(this.today)) return false
    if (this.config.startDate && d?.toDate() < this.config.startDate) return false
    if (this.config.id) {
      if (d?.toDate() < this.config.startDate || d?.toDate().isSameDate(this.config.startDate)) return false
    }
    return true
  }

  onStartDateChanged(date: Date) {
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    this.config.startDate = date
  }
  onEndDateChanged(date: Date) {
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    this.config.endDate = date
  }
  onRemoveEndDate() {
    this.config.endDate = null
  }
  canRemoveEndDate() {
    var isLastConfig = true
    this.user.configs.forEach(c => {
      if (this.config.startDate <= c.startDate && c.id != this.config.id) isLastConfig = false
    })
    return isLastConfig
  }

  // public notAvailableCommonTargetValues: Set<NutritionalValue> = new Set([NutritionalValue.calories,
  //   NutritionalValue.fat,
  //   NutritionalValue.carbohydrates,
  //   NutritionalValue.protein]);



  setCommonTargetValue(key: string, value: string, focusout: boolean = false){
    if(value == null || value.length == 0) {
      delete this.config.commonTargetValues[key];
    }
    else{
      value = value.replaceAll(',', '.');
      let numberValue = Number.parseFloat(value);
      if(!Number.isNaN(numberValue)) {
        let unit = BaseNutritionFact.getDefaultUnit(key);
        this.config.commonTargetValues[key] = BaseNutritionFact.convertBackByUnit(numberValue, unit);
      }
      else if(focusout) {
        delete this.config.commonTargetValues[key];
      }
    }
  }

  getCommonTargetValue(key: string) {
    let unit = BaseNutritionFact.getDefaultUnit(key);
    return BaseNutritionFact.getConvertedValueByUnit(this.config.commonTargetValues[key], unit);
  }

  getRDA(key: string) {
    let unit = BaseNutritionFact.getDefaultUnit(key);
    let rda = BaseNutritionFact.getRawRecommendedDailyAllowance(key, unit);
    if(rda) return "RDA: " + rda + " " + unit

    return null;
  }
}
