<div class="container pt-3">
    <div class="row justify-content-sm-center">
      <div class="col-sm-10 col-md-6">
        <div class="card border-info">
          <div class="card-header">Register</div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4 text-center">
                <img src="https://placeimg.com/128/128/nature">
              </div>
              <div class="col-md-8">
                <form>
                  <input [(ngModel)]="mail" type="text" class="form-control mb-2" required autofocus/>
                  <input [(ngModel)]=password type="password" class="form-control mb-2" required/>
                  <button class="btn btn-lg btn-primary btn-block mb-1" type="submit" (click)="register()">Register</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
