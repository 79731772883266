<div class="dialog-topbar">
    <h3>Vorlage einfügen</h3>
</div>
<div class="dialog-content-wrapper">
    <p>
        Lege das Startdatum für den neuen Plan fest.
    </p>
    <br>
    <div>
        Start:
        <span class="marginleft-10">{{startDate?.asFormatedString()}}</span>
        <input [matDatepicker]="startDatePicker" [value]="startDate" [matDatepickerFilter]="startDateFilterNutritionPlanConfig" class="visibility-hidden width-0 padding-0" (dateInput)="onStartDateChanged($event.value?.toDate())">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker ></mat-datepicker><br>
    </div>
    <br>
    <div class="actions">
        <button class="button-filled small light float-right" (click)="onConfirmDialog()">Bestätigen</button>
        <button class="button-filled small light float-right marginright-25" (click)="onCancelDialog()">Abbrechen</button>
    </div>
</div>