<div class="dialog-topbar">
    <div class="icon-button float-right" (click)="onCloseDialog()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        Schließen
    </div>
</div>
<div class="dialog-content-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="spacer-10"></div>
            <input id="templatesearch-input" class="input wide" (input)="onSessionTemplateSearchInputChanged($event.target.value)" placeholder="Vorlagen durchsuchen" type="text">
            <div class="icon-button deletesearch-icon delete-recipesearch" (click)="onDeleteSessionTemplateSearchInput()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </div>
            <div class="spacer-25"></div>
        </div>
    </div>
    <div *ngIf="trainingService.trainingPlanTemplates?.length > 0" class="row">
        <div *ngFor="let template of filteredTrainingPlanTemplates" class="col-12">
            <ng-container *ngIf="(selectedSessionTemplateTab === 'OWN' && template.licenceHolderUid !== 'nutrilize') || (selectedSessionTemplateTab === 'NUTRILIZE' && template.licenceHolderUid === 'nutrilize')">
                <div class="label">{{template.nameTranslation.GetValue(languageService.selectedLanguageCode)}}
                    <ng-container *ngIf="template.subNameTranslation.GetValue(languageService.selectedLanguageCode)"> - {{template.nameTranslation.GetValue(languageService.selectedLanguageCode)}}</ng-container>
                </div>
                <ng-container *ngFor="let session of template.sessions">
                    <ng-container *ngFor="let groupHeading of getGroupHeadingsBySession(session)">
                        <div class="row layout-box sub-box link" *ngIf="!session.isRestDay && !session.deleted" (click)="selectSessionTemplate(session, template)">
                            <div class="checkbox-wrapper">
                                <input class="input checkbox" type="checkbox" [checked]="selectedGroupHeadings.includes(groupHeading)">
                            </div>
                            <div class="col only-desktop">
                                <div class="exercise-group-heading">{{groupHeading.GetValue(languageService.selectedLanguageCode)}}</div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div class="spacer-50"></div>
</div>
<div class="dialog-bottombar" mat-dialog-actions>
    <div class="label col-12">
        <span *ngFor="let groupHeading of selectedGroupHeadings; let isLast=last">
            {{groupHeading.GetValue(languageService.selectedLanguageCode)}}{{isLast ? '' : ', '}}
        </span>
    </div>
    <button *ngIf="selectedGroupHeadings?.length > 0" class="button-filled small create-Exercise" (click)="onTakeSelection(selectedGroupHeadings)">Auswahl übernehmen</button>
</div>
<ngx-spinner name="sessionTemplateSpinner" bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>