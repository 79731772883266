<div id="purchase-dialog" class="layout-box">
    <div class="container-fluid component-wrapper" >
        <div class="top-bar">
            <div class="icon-button marginleft-25" (click)="onCloseDialog()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
                <span class="icon-button-text">Schließen</span>
            </div>
        </div>
        <div class="spacer-50"></div>
        
        <div class="row">
            <div class="col-12">
                <h3>Zahlungsdetails</h3>
                <div class="spacer-10"></div>
                <div class="bold">
                    Käufer
                </div>
                {{payment.customer?.getName() ?? 'Unbekannter Kunde'}}<br>
            </div>
            <div class="spacer-25"></div>
            <div class="col-12">
                <div class="bold">Rechnung</div>
                {{payment.getPrintablePriceWithCurrency()}}<br>
                <div class="link" (click)="onOpenInvoice()">Rechnung herunterladen</div>
                <div class="icon-button" (click)="onRecreateInvoice()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
                        <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
                    </svg>
                    <span class="icon-button-text">Neu generieren</span>
                </div>
            </div>
            <div class="spacer-25"></div>
            <div class="col-12" *ngIf="payment.status == 'voided' || payment.status == 'refunded'">
                <div class="bold">Stornorechnung</div>
                <div class="link" (click)="onOpenCancellationInvoice()">Rechnung herunterladen</div>
                <div class="icon-button" (click)="onRecreateCancellationInvoice()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
                        <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
                    </svg>
                    <span class="icon-button-text">Neu generieren</span>
                </div>
                <div class="spacer-25"></div>
            </div>
            <div class="col-12">
                <div class="bold">Zahlungsmethode</div>
                {{payment.getPrintablePaymentMethod()}}
                <div class="spacer-25"></div>

                <div class="bold">Status</div>
                <div class="spacer-10"></div>
                <div *ngIf="payment.getPrintableStatus()" class="status-indicator-wrapper">
                    <div class="status-indicator {{payment.status}}"></div>
                    {{payment.getPrintableStatus()}}
                </div>
                <div *ngIf="payment.status == 'initiated' || payment.status == 'failed'">
                    <div class="spacer-10"></div>
                    <div class="icon-button" (click)="onMarkPaymentAsPaid()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0"/>
                          </svg>
                        <span class="icon-button-text">Als bezahlt markieren</span>
                    </div>
                </div>
                <div *ngIf="payment.status == 'paid'">
                    <div class="spacer-10"></div>
                    <div class="icon-button" (click)="onRefundPayment()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
                            <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
                        </svg>
                        <span class="icon-button-text">Betrag erstatten</span>
                    </div>
                </div>
                <div *ngIf="payment.status == 'failed' || payment.status == 'draft' || payment.status == 'initiated'">
                    <div class="spacer-10"></div>
                    <div class="icon-button" (click)="onVoidPayment()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
                            <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
                        </svg>
                        <span class="icon-button-text">Rechnung stornieren</span>
                    </div>
                </div>
                <div *ngIf="payment.status == 'failed'">
                    <div class="spacer-10"></div>
                    <div class="icon-button" (click)="onReChargePayment()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
                            <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
                        </svg>
                        <span class="icon-button-text">Zahlung erneut einziehen</span>
                    </div>
                </div>
            </div>
            <div class="spacer-25"></div>
            <div class="col-12">
                <div class="bold">
                    Verlauf
                </div>
                <div class="spacer-10"></div>
                <div class="row">
                    <div class="col-12" *ngFor="let log of payment.logs">
                        <div class="timeline-container">
                            <div class="timeline"></div>
                            <div class="point"></div>
                        </div>
                        <div class="log-content">
                            <div class="label date">{{log.date?.asDateTimeFormatedString()}}</div>
                            {{log.getPrintableEvent()}}
                            <div *ngIf="log.getPrintableErrorDescription()" class="label">{{log.getPrintableErrorDescription()}}</div>

                            <ng-container *ngIf="log.getPrintableErrorDescription()?.includes('The currency provided (eur) is invalid. Payments with customer_balance support the following currencies')">
                                <div class="layout-box sub-box">
                                    <div class="bold">Hinweis</div>
                                    <div class="label">
                                        Melde dich in deinem Stripe Account an und besuche die Seite Zahlungsmethoden (<a href="https://dashboard.stripe.com/settings/payment_methods" target="_blank">https://dashboard.stripe.com/settings/payment_methods</a>). Aktiviere dort unten auf der Seite die Zahlungsmethode Banküberweisungen. Wenn sie bei Stripe als aktiv gekennzeichnet ist, kannst du über den Button oben die Zahlung erneut einziehen.
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "#4AE6D7" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>