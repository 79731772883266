import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OpenAIService } from 'src/app/services/openai.service';
import { DomSanitizer } from '@angular/platform-browser';
import { User } from 'src/app/model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { Questionaire } from 'src/app/model/questionaires.model';
import { QuestionairesService } from 'src/app/services/questionaires.service';
import { ToastrService } from 'ngx-toastr';
import { Metric } from 'src/app/model/metric.model';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-profile-settings-dialog',
  templateUrl: './profile-settings-dialog.component.html',
  styleUrls: ['./profile-settings-dialog.component.css']
})
export class ProfileSettingsDialogComponent {

  public user: User
  public userEmail: string
  public allQuestionaires: Questionaire[]

  constructor(public dialogRef: MatDialogRef<ProfileSettingsDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { user: User }, public userService: FirestoreService, public questionaireService: QuestionairesService, private toastr: ToastrService, public languageService: LanguageService) {
    this.user = data.user
    if (!this.userEmail) this.userService.getEmailForUser(this.user.uid).then(email => {
      this.userEmail = email
    })
  }

  async ngOnInit(): Promise<void> {
    this.allQuestionaires = await this.questionaireService.getQuestionaires()
    await this.userService.loadAllAssignedQuestionairesForUser(this.user)
  }

  getDailyQuestionaires() {
    return this.allQuestionaires?.filter(x => x.type == 'daily')
  }
  getWeeklyQuestionaires() {
    return this.allQuestionaires?.filter(x => x.type == 'weekly')
  }
  getTrainingFeedbackQuestionaires() {
    return this.allQuestionaires?.filter(x => x.type == 'training_feedback')
  }

  getSelectedDailyQuestionaire() {
    if (this.user.assignedMetrics?.length > 0) {
      var questionaireId = this.user.assignedMetrics[0].questionaireId
      if (questionaireId) {
        var questionaire = this.allQuestionaires.find(x => x.id == questionaireId)
        return questionaire
      }
    }
    return null
  }
  getSelectedWeeklyQuestionaire() {
    if (this.user.weeklyQuestionaireId) {
      var questionaire = this.allQuestionaires.find(x => x.id == this.user.weeklyQuestionaireId)
      return questionaire
    }
    return null
  }
  getSelectedTrainingFeedbackQuestionaire() {
    if (this.user.trainingFeedbackQuestionaireId) {
      var questionaire = this.allQuestionaires.find(x => x.id == this.user.trainingFeedbackQuestionaireId)
      return questionaire
    }
    return null
  }

  async onSelectDailyCheck(questionaire: Questionaire) {
    this.user.assignedMetrics = questionaire.questions.map(x => {
      var metric = x.metric?.clone() ?? new Metric()
      metric.groupHeading = x.groupHeading
      metric.content = x.content
      metric.imagePath = x.imagePath
      metric.required = x.required
      return metric
    })
    this.user.assignedMetrics[0].questionaireId = questionaire.id
    var date = new Date()
    this.user.assignedMetrics.forEach(metric => {
      metric.applied = false
      metric.assignmentDate = date
    });
    await this.userService.insertAssignedMetrics(this.user, this.user.assignedMetrics)
    this.user.dailyQuestionaireId = questionaire.id
    await this.userService.saveAssignedQuestionaireSettings(this.user)
    this.toastr.success("Daily Check gespeichert.", "",  {
      positionClass: 'toast-bottom-center'
    });
  }

  async onSelectWeeklyCheck(questionaire: Questionaire) {
    this.user.weeklyQuestionaireId = questionaire.id
    if (!this.user.nextWeeklyQuestionaireDate) {
      var date = new Date()
      var day = date.getDay()
      var diff = date.getDate() - day + (day == 0 ? -6 : 1)
      var nextMonday = new Date(date.setDate(diff))
      nextMonday.setHours(0, 0, 0, 0)
      this.user.nextWeeklyQuestionaireDate = nextMonday
    }
    await this.userService.saveAssignedQuestionaireSettings(this.user)
    this.toastr.success("Weekly Check gespeichert.", "",  {
      positionClass: 'toast-bottom-center'
    });
  }

  async onSelectTrainingFeedbackQuestionaire(questionaire: Questionaire) {
    this.user.trainingFeedbackQuestionaireId = questionaire?.id ?? null
    await this.userService.saveAssignedQuestionaireSettings(this.user)
    if (questionaire && !questionaire.assignedUids.includes(this.user.uid)) {
      questionaire.assignedUids.push(this.user.uid)
      await this.questionaireService.updateQuestionaire(questionaire)
    }
    for (var q of this.getTrainingFeedbackQuestionaires()) {
      if (q.id != questionaire?.id && q.assignedUids.includes(this.user.uid)) {
        q.assignedUids = q.assignedUids.filter(x => x != this.user.uid)
        await this.questionaireService.updateQuestionaire(q)
      }
    }
    this.toastr.success("Trainingsfeedback gespeichert.", "",  {
      positionClass: 'toast-bottom-center'
    });
  }

  async onWeeklyCheckDayChanged(weekday: string) {
    var dayIndex = 0
    switch (weekday) {
      case 'monday': dayIndex = 1; break
      case 'tuesday': dayIndex = 2; break
      case 'wednesday': dayIndex = 3; break
      case 'thursday': dayIndex = 4; break
      case 'friday': dayIndex = 5; break
      case 'saturday': dayIndex = 6; break
      case 'sunday': dayIndex = 0; break
    }
    var date = new Date()
    date.setHours(0, 0, 0, 0)
    if (dayIndex < date.getDay()) {
      var diff = date.getDate() - date.getDay() + 7 + dayIndex
      var nextDay = new Date(date.setDate(diff))
      this.user.nextWeeklyQuestionaireDate = nextDay
    } else {
      var diff = date.getDate() - date.getDay() + dayIndex
      var nextDay = new Date(date.setDate(diff))
      this.user.nextWeeklyQuestionaireDate = nextDay
    }
    await this.userService.saveAssignedQuestionaireSettings(this.user)
  }
  getSelectedWeeklyCheckInDay() {
    var date = this.user?.nextWeeklyQuestionaireDate
    return date?.getPrintableWeekday()
  }

  onCompetitionDateChanged(date: Date) {
    this.user.nextCompetitionDate = date
    this.userService.updateLicenceSettings(this.user)
  }

  isNutritionEnabled() {
    if (this.user.nutritionEnabled != null) return this.user.nutritionEnabled
    return this.userService.getLoggedInUser().isNutritionEnabled()
  }
  onEnableNutritionChanged(value: boolean) {
    this.user.nutritionEnabled = value
    this.userService.updateLicenceSettings(this.user)
  }

  getDefaultExertionParameter() {
    if (this.user.defaultExertionParameter == 'rpe') return 'RPE'
    if (this.user.defaultExertionParameter == 'rir') return 'RIR'
    return null
  }
  onSelectDefaultExertionParameter(value: string) {
    this.user.defaultExertionParameter = value
    this.userService.updateLicenceSettings(this.user)
  }

  onCancelDialog() {
    this.dialogRef.close(null);
  }
}
