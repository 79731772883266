import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA,MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-selection-dialog',
  templateUrl: './selection-dialog.component.html',
  styleUrls: ['./selection-dialog.component.css']
})
export class SelectionDialogComponent implements OnInit {

  public selectableItems: any[] = [];
  public relevantProperties: string[][] = [[]];
  public layers: number = 1;

  constructor(public dialogRef: MatDialogRef<SelectionDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {selectableItems: any[], relevantProperties: string[]}, private dialog: MatDialog) { 
    this.selectableItems = data.selectableItems;
    this.relevantProperties = (data.relevantProperties.map(x => x.split('.')));

    // if(this.relevantProperties.length > 1) {
    //   this.layers = this.relevantProperties[0].length;
    // }

  }

  getProperty(item: any, property: string[]){
    var result = item;
    property.forEach(element => {
      result = result[element];
    });

    if(result instanceof Date){
      return (result as Date).asDateTimeFormatedString();
    }
    return result;
  }

  ngOnInit(): void {

  }

  onItemSelected(item: any){
    this.dialogRef.close({selectedItem: item});
  }

  onCancelSelection(){
    this.dialogRef.close();
  }
}
