import { Injectable } from '@angular/core';
import { User } from '../model/user.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SpikeService {

  constructor(private http: HttpClient) { }

  getOptions(user: User): any {
    return {
      hostname: 'api.spikeapi.com',
      path: '/v2/metrics/heart/?user_id=' + user.spikeUserId + '&start_date=2024-01-16&end_date=2024-01-18',
      method: 'GET',
      headers: {
        'x-spike-auth': '3b3eb51d-fed6-479e-bc0e-ffe7ca31364e'
      }
    }
  }

  async getHeartFrequency(user: User, startDate: Date, endDate: Date) {
    if (!user.spikeUserId) return
    var options = {
      method: 'GET',
      headers: {
        'x-spike-auth': '3b3eb51d-fed6-479e-bc0e-ffe7ca31364e'
      }
    }
    const res = await this.http.get('https://api.spikeapi.com/v2/metrics/heart/?user_id=' + user.spikeUserId + '&start_date=' + startDate.toISOString().split('T')[0] + '&end_date=' + endDate.toISOString().split('T')[0], options).toPromise().catch((err: HttpErrorResponse) => {  
      const error = err.error;  
      console.log(error);
      return error;  
    });
    return res
  }

  async getActivities(user: User, startDate: Date, endDate: Date) {
    if (!user.spikeUserId) return
    var options = {
      method: 'GET',
      headers: {
        'x-spike-auth': '3b3eb51d-fed6-479e-bc0e-ffe7ca31364e'
      }
    }
    const res = await this.http.get('https://api.spikeapi.com/v2/metrics/activities_stream/?user_id=' + user.spikeUserId + '&start_date=' + startDate.toISOString().split('T')[0] + '&end_date=' + endDate.toISOString().split('T')[0], options).toPromise().catch((err: HttpErrorResponse) => {  
      const error = err.error;  
      return error;  
    });
    return res
  }
}
