import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { BehaviorSubject } from 'rxjs';
import { User } from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  updateTrigger: BehaviorSubject<boolean>
  showComposer: boolean = false
  showBadge: boolean = false

  notificationTitle: string
  notificationContent: string
  type: string

  constructor(private firebaseFunctions: AngularFireFunctions) {
    this.updateTrigger = new BehaviorSubject(undefined)
  }

  composeNotification(title: string, content: string, type: string, composerView: boolean) {
    if (composerView != null) {
      this.showComposer = composerView
      this.showBadge = !composerView
    }
    this.notificationTitle = title
    this.notificationContent = content
    this.type = type
    this.showComposer = true
    this.updateTrigger.next(true)
  }

  composeNutritionPlanNotification() {
    this.composeNotification('Ernährungsplan aktualisiert', 'Dein Coach hat deinen Ernährungsplan aktualisiert.', 'NEW_ASSIGNMENTS', true)
  }
  composeNutritionalGoalNotification() {
    this.composeNotification('Nährstoffziele aktualisiert', 'Dein Coach hat deine Nährstoffziele aktualisiert.', 'NEW_ASSIGNMENTS', true)
  }
  composeTrainingPlanCreationNotification() {
    this.composeNotification('Trainingsplan erstellt', 'Dein Coach hat dir einen Trainingsplan erstellt.', 'NEW_ASSIGNMENTS', true)
  }
  composeTrainingPlanUpdateNotification() {
    this.composeNotification('Trainingsplan aktualisiert', 'Dein Coach hat deinen Trainingsplan aktualisiert.', 'NEW_ASSIGNMENTS', true)
  }
  composeFileExchangeUpdateNotification() {
    this.composeNotification('Dateiablage aktualisiert', 'Dein Coach hat die Dateiablage aktualisiert.', 'NEW_ASSIGNMENTS', true)
  }

  async sendNotification(user: User, type: string, title: string, text: string) {
    if (user && user.fcmToken && user.fcmToken?.length > 0) {
      const callable = user.isAndroidUser() ? this.firebaseFunctions.httpsCallable('sendNotificationHttps') : this.firebaseFunctions.httpsCallable('sendNotificationiOSHttps');
      await callable({ type: type, title: title, body: text, fcmToken: user.fcmToken }).pipe().toPromise()
    }
  }
}
