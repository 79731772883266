import { SetParameter } from 'src/app/model/training-plan.model';
import { FirestoreNutritionPlanService } from "../services/firestore-nutritionplan.service";


class MinMaxPercentages {
    min: number;
    max: number;

    constructor(min: number, max: number) {
        this.min = min;
        this.max = max;
    }
}

export class CardioZoneGroup {
    public id: string = null;
    public name: string = "";
    public exerciseIds: string[] = [];
    public deleted: boolean = false;
    public zones: CardioZone[] = [];

    public heartRateAvailable: boolean = true;
    public paceAvailable: boolean = true;
    public pace500Available: boolean = true;

    public get isDefaultZoneGroup() {
        return this.id == "default"
    }

    constructor(init: CardioZoneGroup = null, age: number = 30) {
        this.id = init && init.id || FirestoreNutritionPlanService.generateUniqueString()
        this.name = init && init.name || ""
        this.exerciseIds = init && init.exerciseIds || []
        this.deleted = init && init.deleted || false
        this.zones = init && init.zones?.map(x => new CardioZone(x)) || CardioZoneGroup.getDefaultCardioZones(age)
        this.heartRateAvailable = (init != null && init?.heartRateAvailable != undefined) ? init.heartRateAvailable : true
        this.paceAvailable = (init != null && init?.paceAvailable != undefined) ? init.paceAvailable : true
        this.pace500Available = (init != null && init?.pace500Available != undefined) ? init.pace500Available : true
    }

    asMap() {
        let map = {}
        map['id'] = this.id
        map['name'] = this.name
        map['exerciseIds'] = this.exerciseIds
        map['deleted'] = this.deleted
        map['zones'] = this.zones.map(x => x.asMap())
        map['heartRateAvailable'] = this.heartRateAvailable
        map['paceAvailable'] = this.paceAvailable
        map['pace500Available'] = this.pace500Available
        return map
    }

    clone(age: number = 30) {
        return new CardioZoneGroup(this)
    }

    static getEmptyDefaultZoneGroup(age: number = 30): CardioZoneGroup {
        let defaultZoneGroup = new CardioZoneGroup(null, age);
        defaultZoneGroup.id = "default";
        defaultZoneGroup.name = null;

        return defaultZoneGroup;
    }

    static getDefaultCardioZones(age: number = 30) {
        let cardioZones = [];
        cardioZones.push(CardioZone.getMaxCardioZone(age));

        for (let i = 0; i < CardioZoneGroup.pacePercentages.length; i++) {
            let zone = new CardioZone();
            zone.name = "Zone " + (i + 1);
            cardioZones.push(zone);
        }

        CardioZoneGroup.recalculateCardioZones(cardioZones);

        return cardioZones;
    }

    static pacePercentages: MinMaxPercentages[] = [
        new MinMaxPercentages(1.29, 1.37),
        new MinMaxPercentages(1.19, 1.27),
        new MinMaxPercentages(1.05, 1.08),
        new MinMaxPercentages(0.99, 1.04),
        new MinMaxPercentages(0.82, 0.99),
    ]

    static heartRatePercentages: MinMaxPercentages[] = [
        new MinMaxPercentages(0.70, 0.75),
        new MinMaxPercentages(0.75, 0.80),
        new MinMaxPercentages(0.80, 0.85),
        new MinMaxPercentages(0.85, 0.88),
        new MinMaxPercentages(0.90, 0.95),
    ]

    static presetColors = ["#C5FEB9", "#E9FFA9", "#FFF9C1", "#FDD6C0", "#FFC7C7"]

    resetMaxCardioZone(age: number = 30) {
        let maxCardioZone = this.zones.find(x => x.isMaxCardioZone);
        if (maxCardioZone) {
            this.zones = this.zones.filter(x => !x.isMaxCardioZone);
            this.zones.push(CardioZone.getMaxCardioZone(age));
        }
    }

    static recalculateCardioZones(zones: CardioZone[], setParameter: SetParameter = null) {
        let maxZone = zones.find(x => x.isMaxCardioZone);
        let zonesWithoutMax = zones.filter(x => !x.isMaxCardioZone);
        for (let i = 0; i < zonesWithoutMax.length; i++) {
            let zone = zonesWithoutMax[i];

            if (setParameter == null || setParameter == SetParameter.pace) {
                if (i < CardioZoneGroup.pacePercentages.length) {
                    let percentage = CardioZoneGroup.pacePercentages[i];
                    zone.minPace = (percentage.min * maxZone.minPace)?.roundToInt();
                    zone.maxPace = (percentage.max * maxZone.minPace)?.roundToInt();
                }
            }

            if (setParameter == null || setParameter == SetParameter.pace500) {
                if (i < CardioZoneGroup.pacePercentages.length) {
                    let percentage = CardioZoneGroup.pacePercentages[i];
                    zone.minPace500 = (percentage.min * maxZone.minPace500)?.roundToInt();
                    zone.maxPace500 = (percentage.max * maxZone.minPace500)?.roundToInt();
                }
            }

            if (setParameter == null || setParameter == SetParameter.heartRate) {
                if (i < CardioZoneGroup.heartRatePercentages.length) {
                    let percentage = CardioZoneGroup.heartRatePercentages[i];
                    zone.minHeartRate = (percentage.min * maxZone.minHeartRate)?.roundToInt();
                    zone.maxHeartRate = (percentage.max * maxZone.minHeartRate)?.roundToInt();
                }
            }
        }
    }

    recalculateCardioZones(setParameter: SetParameter = null) {
        CardioZoneGroup.recalculateCardioZones(this.zones, setParameter);
    }

    getColorByCardioZone(cardioZone: CardioZone) {
        let index = this.zones.filter(x => !x.isMaxCardioZone).indexOf(cardioZone);
        return CardioZoneGroup.presetColors[index % CardioZoneGroup.presetColors.length];
    }

    getCardioZonePacePlaceholder(cardioZone: CardioZone) {
        if (cardioZone.isMaxCardioZone) {
            return "";
        }
        if (cardioZone.minPace !== undefined || cardioZone.maxPace !== undefined) {
            return "";
        }
        let maxPaceZone = this.zones.find(x => x.isMaxCardioZone);
        if (maxPaceZone == cardioZone) {
            return "240";
        }
        else if (maxPaceZone?.minPace) {
            let index = this.zones.filter(x => !x.isMaxCardioZone).indexOf(cardioZone);
            if (index < 0) return "";
            if ((index) < CardioZoneGroup.pacePercentages.length) {
                let percentage = CardioZoneGroup.pacePercentages[index];
                let min = (percentage.min * maxPaceZone.minPace)?.roundToInt();
                let max = (percentage.max * maxPaceZone.minPace)?.roundToInt();

                if (min && max) {
                    let minDate = new Date(0, 0, 0, 0, 0, min, 0)?.toTimeString()?.slice(3, 8);
                    let maxDate = new Date(0, 0, 0, 0, 0, max, 0)?.toTimeString()?.slice(3, 8);
                    return minDate + "-" + maxDate;
                }
            }
        }

        return "00:00-00:00";
    }

    getCardioZoneHeartRatePlaceholder(cardioZone: CardioZone) {
        if (cardioZone.isMaxCardioZone) {
            return "";
        }
        if (cardioZone.minHeartRate !== undefined || cardioZone.maxHeartRate !== undefined) {
            return "";
        }
        let maxHeartRateZone = this.zones.find(x => x.isMaxCardioZone);
        if (maxHeartRateZone == cardioZone) {
            return "240";
        }
        else if (maxHeartRateZone?.minHeartRate) {
            let index = this.zones.filter(x => !x.isMaxCardioZone).indexOf(cardioZone);
            if (index < 0) return "";
            if ((index) < CardioZoneGroup.heartRatePercentages.length) {
                let percentage = CardioZoneGroup.heartRatePercentages[index];
                let min = (percentage.min * maxHeartRateZone.minHeartRate)?.roundToInt();
                let max = (percentage.max * maxHeartRateZone.minHeartRate)?.roundToInt();

                if (min && max) {
                    return min + "-" + max;
                }
            }
        }

        return "00-00";
    }


    isExerciseAssignementInvalid() {
        return !this.isDefaultZoneGroup && this.exerciseIds?.length == 0;
    }

    isZoneGroupInvalid() {
        return this.isGroupNameInvalid() || this.isExerciseAssignementInvalid() || this.isAnyZoneValueInvalid();
    }

    isGroupNameInvalid() {
        if(this.isDefaultZoneGroup) return false;
        return this.name == "" || this.name == null;
    }

    isAnyZoneValueInvalid() {
        return this.zones.find(x => this.isPaceValueInvalid(x) || this.isHeartRateValueInvalid(x) || this.isPace500ValueInvalid(x));
    }

    isPace500ValueInvalid(zone: CardioZone) {
        if (!this.pace500Available) {
            return false;
        }
        if (zone.isMaxCardioZone && zone.minPace500 != undefined) {
            return false;
        }
        if (zone.minPace500 == undefined || zone.maxPace500 == undefined) {
            return true;
        }
        return false
    }

    isPaceValueInvalid(zone: CardioZone) {
        if (!this.paceAvailable) {
            return false;
        }
        if (zone.isMaxCardioZone && zone.minPace != undefined) {
            return false;
        }
        if (zone.minPace == undefined || zone.maxPace == undefined) {
            return true;
        }
        return false
    }
    isHeartRateValueInvalid(zone: CardioZone) {
        if (!this.heartRateAvailable) {
            return false;
        }
        if (zone.isMaxCardioZone && zone.minHeartRate != undefined) {
            return false;
        }
        if (zone.minHeartRate == undefined || zone.maxHeartRate == undefined) {
            return true;
        }
        return false
    }
}

export class CardioZone {
    public id: string = null;
    public name: string = "";
    public minPace: number = undefined;
    public maxPace: number = undefined;
    public maxHeartRate: number = undefined;
    public minHeartRate: number = undefined;
    public minPace500: number = undefined;
    public maxPace500: number = undefined;


    public get isMaxCardioZone() {
        return this.id == "max"
    }

    constructor();
    constructor(init: CardioZone);
    constructor(init?: CardioZone) {
        this.id = init && init.id || FirestoreNutritionPlanService.generateUniqueString()
        this.name = init && init.name || ""
        this.minPace = init && init.minPace || undefined
        this.maxPace = init && init.maxPace || undefined
        this.minHeartRate = init && init.minHeartRate || undefined
        this.maxHeartRate = init && init.maxHeartRate || undefined
        this.minPace500 = init && init.minPace500 || undefined
        this.maxPace500 = init && init.maxPace500 || undefined
        // this.deleted = init && init.deleted || false
    }

    public static namePattern: string = "^[a-zA-Z0-9_. ]+$";

    isZoneNameInvalid(availableZones: CardioZone[]) {
        if (availableZones.filter(x => x.name == this.name).length > 1) {
            return true;
        }
        if (!this?.name?.match(CardioZone.namePattern)) {
            return true;
        }
        if (this.name == "" || this.name == null) {
            return true;
        }
        return false;
    }

    asMap() {
        let map = {}
        map['id'] = this.id
        if (this.name) map['name'] = this.name
        if (this.minPace !== undefined) map['minPace'] = this.minPace
        if (this.maxPace !== undefined) map['maxPace'] = this.maxPace
        if (this.minHeartRate !== undefined) map['minHeartRate'] = this.minHeartRate
        if (this.maxHeartRate !== undefined) map['maxHeartRate'] = this.maxHeartRate
        if (this.minPace500 !== undefined) map['minPace500'] = this.minPace500
        if (this.maxPace500 !== undefined) map['maxPace500'] = this.maxPace500
        // if(this.deleted) map['deleted'] = this.deleted
        return map
    }

    asGlobalMap() {
        let map = {}
        map['id'] = this.id
        if (this.name) map['name'] = this.name
        return map
    }

    clone() {
        return new CardioZone(this)
    }

    static getMaxCardioZone(age: number = 30): CardioZone {
        let maxCardioZone = new CardioZone();
        maxCardioZone.id = "max";
        maxCardioZone.name = "Max.";
        maxCardioZone.minPace = 180;
        maxCardioZone.maxPace = undefined;
        maxCardioZone.minHeartRate = 220 - age;
        maxCardioZone.maxHeartRate = undefined;
        maxCardioZone.minPace500 = 360;
        maxCardioZone.maxPace500 = undefined;

        return maxCardioZone;
    }
}