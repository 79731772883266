<div class="editor-wrapper">
    <ng-container *ngIf="hasChanges">
        <button class="button-filled small button-save" (click)="onSave()">Speichern</button>
    </ng-container>
    <div class="row">
        <div class="col-4 description-col">
            <h3 class="display-inlineblock">Werte des Athleten</h3>
        </div>
        <div class="col-4 onerm-col">
            <h3 class="display-inlineblock">1RM aktuell</h3>
        </div>
        <div class="col-4 goal-col">
            <h3 class="display-inlineblock">Zyklusziel 1RM</h3>
        </div>
    </div>
    <div class="onerm-table">
        <div class="row">
            <div class="col-12" *ngFor="let variable of oneRmTrainingVariables">
                <div class="row onerm-row">
                    <div class="col-4 description-col">
                        <input *ngIf="variable.isNew" id="input-variable-name" required class="input small form-control full-width" [value]="variable.name" (input)="setName(variable, $event.target.value)" placeholder="Name" type="text">
                        <div *ngIf="!variable.isNew">{{variable.name}}</div>
                    </div>
                    <div class="col-4 onerm-col">
                        <input class="input small input-weight form-control full-width" [value]="variable.weight" (input)="setWeight(variable, $event.target.value)" placeholder="kg">
                        <div *ngIf="variable.weight" class="icon-button display-inlineblock marginleft-10 button-exerciserecording" (click)="onOpenRpeDialog(variable)">
                            <i class="bi bi-table fontsize-18"></i>
                        </div>
                    </div>
                    <div class="col-4 goal-col">
                        <input class="input small input-weight form-control full-width" [value]="getGoalVariableForOneRmVariable(variable)?.weight" (input)="setWeight(getGoalVariableForOneRmVariable(variable), $event.target.value)" placeholder="kg">
                        <div *ngIf="getGoalVariableForOneRmVariable(variable)?.weight" class="icon-button display-inlineblock marginleft-10 button-exerciserecording" (click)="onOpenRpeDialog(getGoalVariableForOneRmVariable(variable))">
                            <i class="bi bi-table fontsize-18"></i>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="icon-button marginleft-10" [matMenuTriggerFor]="menu">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>
                Übung hinzufügen
            </div>
            <mat-menu #menu="matMenu">
                <div class="available-variables-area">
                    <button mat-menu-item (click)="onCreateNewVariable()">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>
                        Neue Variable anlegen
                    </button>
                    <mat-divider></mat-divider>
                    <ng-container *ngFor="let variable of allAvailableForeignVariables">
                        <button *ngIf="isVariableSelectable(variable) && variable.id.startsWith('ONERM_')" mat-menu-item (click)="onAddAvailableVariable(variable)">
                            {{variable.name}}
                        </button>
                    </ng-container>
                </div>
            </mat-menu>
        </div>
    </div>
</div>