import { Component, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProductPurchase } from 'src/app/model/product-purchase.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { PaymentService } from 'src/app/services/payment.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-renewal-dialog',
  templateUrl: './renewal-dialog.component.html',
  styleUrls: ['./renewal-dialog.component.css']
})
export class RenewalDialogComponent {


  public static TAB_SHORTEN = 'shorten'
  public get tabShorten() {
    return RenewalDialogComponent.TAB_SHORTEN
  }
  public static TAB_EXTEND = 'extend'
  public get tabExtend() {
    return RenewalDialogComponent.TAB_EXTEND
  }
  public currentTab = RenewalDialogComponent.TAB_EXTEND

  onChangeTab(tab: string) {
    this.currentTab = tab
  }

  productPurchase: ProductPurchase
  durationMultiplier: number = 1

  constructor(public dialogRef: MatDialogRef<RenewalDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { productPurchase: ProductPurchase }, public userService: FirestoreService, private paymentService: PaymentService, public utilityService: UtilityService, private dialog: MatDialog, private toastr: ToastrService, private ngZone: NgZone, private spinner: NgxSpinnerService) { 
    this.productPurchase = data.productPurchase
  }

  ngOnInit(): void {

  }

  getProjectedEndDate() {
    return this.productPurchase.getProjectedEndDate(this.currentTab == this.tabShorten ? -this.durationMultiplier : this.durationMultiplier)
  }

  isInputValid() {
    return this.durationMultiplier > 0 && this.getProjectedEndDate() >= new Date()
  }

  async onConfirm() {
    if (!this.isInputValid()) return
    this.spinner.show()
    await this.userService.updateProductPurchaseRuntime(this.productPurchase.id, this.getProjectedEndDate(), (this.productPurchase.durationMultiplier ?? 1) + this.durationMultiplier).then((res) => {
      if (res?.success == true) {
        this.productPurchase.durationMultiplier = (this.productPurchase.durationMultiplier ?? 1) + this.durationMultiplier
        this.productPurchase.endDate = this.getProjectedEndDate()
        this.toastr.success('Änderung erfolgreich übernommen')
      }
      this.spinner.hide()
      this.closeDialog()
    })
  }

  onCloseDialog() {
    this.closeDialog()
  }

  closeDialog() {
    this.dialogRef.close(null);
  }
}
