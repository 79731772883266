import { Component, Inject } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';
import { ToastrService } from 'ngx-toastr';
import { IndividualFirebase } from 'src/app/app.module';
import { Question } from 'src/app/model/questionaires.model';
import { UtilityService } from 'src/app/services/utility.service';
import { SingleExerciseComponent } from 'src/app/training/single-exercise/single-exercise.component';

@Component({
  selector: 'app-questionaire-infoslide-dialog',
  templateUrl: './questionaire-infoslide-dialog.component.html',
  styleUrls: ['./questionaire-infoslide-dialog.component.css']
})
export class QuestionaireInfoslideDialogComponent {
  public questionaireItem: Question;

  private hasChanges: boolean = false;
  
  private fireStorage: AngularFireStorage

  constructor(public dialogRef: MatDialogRef<QuestionaireInfoslideDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {question: Question}, private mainFirebase: IndividualFirebase, public utilityService: UtilityService, private toastr: ToastrService, private imageCompressor: NgxImageCompressService){
    this.questionaireItem = data.question;
    this.fireStorage = mainFirebase.storage;
    if(this.questionaireItem.imagePath && !this.questionaireItem.imageURL){
      this.fireStorage.ref(this.questionaireItem.imagePath).getDownloadURL().toPromise().then((link) => {
        this.questionaireItem.imageURL = link;
      }).catch(ex => console.log(ex));
    }
  }

  onTakeChanges(){
    this.dialogRef.close({shouldTake: true, question: this.questionaireItem});
  }
  onCancelChanges(){
    this.dialogRef.close();
  }

  onEditorValueChanged(text: string) {
    this.questionaireItem.content = text;
  }

  openImageDialog(){
    (document.querySelector('#thumbnail-input') as HTMLElement).click()
  }

  onUploadImage() {
    document.getElementById('input-recipeimage').click()
  }
  // uploadImage(e){
  //   if (e.target.files && e.target.files[0]) {
  //     if (!this.checkUploadFile(e.target.files[0], 50000000, ["jpg", "jpeg", "png"])) return;
  //     this.convertThumbnail(e.target.files[0])
  //   }
  // }

  onDragOver(event) {
    event.preventDefault();
  }
  
  onDropImage(event) {
    event.preventDefault();
    if (event.dataTransfer.files?.length > 0) {
      var file = event.dataTransfer.files[0]
      if (!SingleExerciseComponent.checkUploadFile(file, 1000000, ["jpg", "jpeg", "png", "gif"], this.toastr)) return
      this.uploadFile(file);
    }
  }

  uploadFile(file: File){
    try{
      var image = new Image();
      image.src = URL.createObjectURL(file);
  
      this.questionaireItem.imageURL = image.src
  
      image.onload = () => {
        var maxDimension = Math.max(image.width, image.height)
        var scaleRatio = Math.min(100, 800 / maxDimension * 100)
        
        this.imageCompressor.compressFile(image.src, DOC_ORIENTATION.Up, scaleRatio, 60).then((compressedFile) => {
          this.questionaireItem.updatedImage = this.dataURLtoFile(compressedFile, file.name);
        })
      }
      this.hasChanges = true
    }
    catch(ex){
      this.toastr.show("Fehler beim Upload.")
      console.log(ex);
    }
  }

  onNewImageUploaded(e) {
    var file: File;
    file = e.target.files[0];
    if(file){
      this.uploadFile(file);
    }
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }
}
