import { LanguageDictionary } from './languagedictionary.model'

export class Metric {

    static circumferenceMetricIds: string[] = [
        'bellyCircumference',
        'chestCircumference',
        'hipCircumference',
        'seatCircumference',
        'waistCircumference',
        'leftArmCircumference',
        'rightArmCircumference',
        'leftCalfCircumference',
        'rightCalfCircumference',
        'leftThighCircumference',
        'rightThighCircumference',
    ]

    static circumferenceMetricIdToNameMapping: Map<string, string> = new Map<string, string>([
        ["bellyCircumference", "Bauchumfang"],
        ['chestCircumference', 'Brustumfang'],
        ['hipCircumference', 'Hüftumfang'],
        ['seatCircumference', 'Gesäßumfang'],
        ['waistCircumference', 'Taillenumfang'],
        ['leftArmCircumference', 'Armumfang links'],
        ['rightArmCircumference', 'Armumfang rechts'], 
        ['leftCalfCircumference', 'Wadenumfang links'],
        ['rightCalfCircumference', 'Wadenumfang rechts'],
        ['leftThighCircumference', 'Beinumfang links'],
        ['rightThighCircumference', 'Beinumfang rechts'],
    ]);

    static bodyDataMetricIds: string[] = [
        'bodyWeight',
        'bodyFat',
        ...this.circumferenceMetricIds
    ] 

    id: string
    metricId: string

    // For assignment to a user
    assignmentDate: Date
    groupHeading: string;
    content: string;
    imagePath: string;
    required: boolean;
    questionaireId: string;

    get nameDe(): string {
        return this.nameTranslation.de
    }
    set nameDe(value: string) {
        this.nameTranslation.de = value
    }

    get nameEn(): string {
        return this.nameTranslation.en
    }
    set nameEn(value: string) {
        this.nameTranslation.en = value
    }

    getName(languageCode: string = 'de'): string {
        var name = this.nameDe
        if (languageCode == 'en') {
            name = this.nameEn
        }
        return name
    }

    nameTranslation: LanguageDictionary<string> = new LanguageDictionary();
    descriptionDe: string
    questionText: string
    questionTextTranslation: LanguageDictionary<string> = new LanguageDictionary();
    metricType: string
    dataType: string

    get unit(): string {
        return this.unitTranslation.de
    }
    set unit(value: string) {
        this.unitTranslation.de = value
    }

    unitTranslation: LanguageDictionary<string> = new LanguageDictionary();

    get valueExplanation(): string {
        return this.valueExplanationTranslation.de
    }
    set valueExplanation(value: string) {
        this.valueExplanationTranslation.de = value
    }
    valueExplanationTranslation: LanguageDictionary<string> = new LanguageDictionary();
    selectableValues: string
    selectableValuesTranslation: LanguageDictionary<string> = new LanguageDictionary();
    targetValue: any
    minValue: any
    maxValue: any
    displayOrder: number
    public: boolean
    deleted: boolean
    applied: boolean

    hasTargetValueError = false;

    constructor();
    constructor(init: Metric);
    constructor(init?: Metric) {
        this.id = init && init.id || null
        this.metricId = init && init.metricId || null
        this.assignmentDate = init && init.assignmentDate || null
        this.nameDe = init && init.nameDe || null
        this.nameEn = init && init.nameEn || null
        this.nameTranslation = init && init.nameTranslation != null ? new LanguageDictionary(init.nameTranslation.de, init.nameTranslation.en, init.nameTranslation.originObject) : new LanguageDictionary(this.nameDe, this.nameEn)
        this.descriptionDe = init && init.descriptionDe || null
        this.questionText = init && init.questionText || null
        this.questionTextTranslation = init && init.questionTextTranslation != null ? new LanguageDictionary(init.questionTextTranslation.de, init.questionTextTranslation.en, init.questionTextTranslation.originObject) : new LanguageDictionary(this.questionText)
        this.metricType = init && init.metricType || null
        this.dataType = init && init.dataType || null
        this.unit = init && init.unit || null
        this.valueExplanation = init && init.valueExplanation || null
        this.valueExplanationTranslation = init && init.valueExplanationTranslation != null ? new LanguageDictionary(init.valueExplanationTranslation.de, init.valueExplanationTranslation.en, init.valueExplanationTranslation.originObject) : new LanguageDictionary(this.valueExplanation)
        this.selectableValues = init && init.selectableValues || null
        this.selectableValuesTranslation = init && init.selectableValuesTranslation != null ? new LanguageDictionary(init.selectableValuesTranslation.de, init.selectableValuesTranslation.en, init.selectableValuesTranslation.originObject) : new LanguageDictionary()
        this.targetValue = init && init.targetValue != null ? init.targetValue : null
        this.minValue = init && init.minValue != null ? init.minValue : null
        this.maxValue = init && init.maxValue != null ? init.maxValue : null
        this.displayOrder = init && init.displayOrder || 0
        this.public = init && init.public || false
        this.deleted = init && init.deleted || false
        this.applied = init && init.applied || false
        this.unitTranslation = init && init.unitTranslation != null ? new LanguageDictionary(init.unitTranslation.de, init.unitTranslation.en, init.unitTranslation.originObject) : new LanguageDictionary(this.unit)
        this.groupHeading = init && init.groupHeading || null;
        this.content = init && init.content || null;
        this.imagePath = init && init.imagePath || null;
        this.required = init && init.required || false;
        this.questionaireId = init && init.questionaireId || null;
    }

    getSelectableValues(languageCode: string = 'de'): string[] {
        var values = this.selectableValues
        if (this.selectableValuesTranslation != null && this.selectableValuesTranslation[languageCode] != null) {
            values = this.selectableValuesTranslation[languageCode]
        }
        if (!values || values.length == 0) return []
        return values.split(';')
    }

    clone(): Metric {
        var metric = new Metric()
        metric.id = this.id
        metric.metricId = this.metricId
        metric.assignmentDate = this.assignmentDate
        metric.nameDe = this.nameDe
        metric.nameEn = this.nameEn
        metric.nameTranslation = new LanguageDictionary(this.nameTranslation.de, this.nameTranslation.en, this.nameTranslation.originObject)
        metric.descriptionDe = this.descriptionDe
        metric.questionText = this.questionText
        metric.questionTextTranslation = new LanguageDictionary(this.questionTextTranslation.de, this.questionTextTranslation.en, this.questionTextTranslation.originObject)
        metric.metricType = this.metricType
        metric.dataType = this.dataType
        metric.unit = this.unit
        metric.valueExplanation = this.valueExplanation
        metric.valueExplanationTranslation = new LanguageDictionary(this.valueExplanationTranslation.de, this.valueExplanationTranslation.en, this.valueExplanationTranslation.originObject)
        metric.selectableValues = this.selectableValues
        metric.selectableValuesTranslation = new LanguageDictionary(this.selectableValuesTranslation.de, this.selectableValuesTranslation.en, this.selectableValuesTranslation.originObject)
        metric.targetValue = this.targetValue
        metric.minValue = this.minValue
        metric.maxValue = this.maxValue
        metric.displayOrder = this.displayOrder
        metric.public = this.public
        metric.deleted = this.deleted
        metric.applied = this.applied
        metric.unitTranslation = new LanguageDictionary(this.unitTranslation.de, this.unitTranslation.en, this.unitTranslation.originObject)
        metric.groupHeading = this.groupHeading
        metric.content = this.content
        metric.imagePath = this.imagePath
        metric.required = this.required
        metric.questionaireId = this.questionaireId
        return metric
    }

    public static metricTypeToLanguageDictionary(metricType: string): LanguageDictionary<string> {
        if (metricType == 'TEXT') return new LanguageDictionary('Text', 'Text', 'TEXT')
        if (metricType == 'NUMBER') return new LanguageDictionary('Zahl', 'Number', 'NUMBER')
        if (metricType == 'YES_NO') return new LanguageDictionary('Ja/Nein', 'Yes/No', 'YES_NO')
        if (metricType == 'DURATION') return new LanguageDictionary('Dauer', 'Duration', 'DURATION')
        if (metricType == 'SELECTION') return new LanguageDictionary('Auswahl', 'Selection', 'SELECTION')
        if (metricType == 'IMAGE') return new LanguageDictionary('Bild', 'Image', 'IMAGE')
        if (metricType == 'VIDEO') return new LanguageDictionary('Video', 'Video', 'VIDEO')
        if (metricType == 'MULTISELECT') return new LanguageDictionary('Mehrfachauswahl', 'Multiselect', 'MULTISELECT')
        if (metricType == 'TODO') return new LanguageDictionary('ToDo', 'ToDo', 'TODO')
        return new LanguageDictionary(metricType, metricType, metricType)
    }
    getPrintableType() {
        if (this.isMetricTypeNumber() && this.isDataTypeInteger()) {
            return 'Ganzzahl'
        } else if (this.isMetricTypeNumber() && this.isDataTypeDouble()) {
            return 'Kommazahl'
        } else if (this.isMetricTypeText()) {
            return 'Text'
        } else if (this.isMetricTypeDuration()) {
            return 'Dauer'
        } else if (this.isMetricTypeYesNo()) {
            return 'Ja/Nein'
        } else if (this.isMetricTypeSelection()) {
            return 'Auswahl'
        } else if (this.isMetricTypeImage()) {
            return 'Bild'
        }
        else if (this.isMetricTypeVideo()) {
            return 'Video'
        }
        else if (this.isMetricTypeMultiselect()) {
            return 'Mehrfachauswahl'
        }
        else if (this.isMetricTypeToDo()) {
            return 'ToDo'
        }
        return ''
    }

    getPrintableTargetValue() {
        if (this.isMetricTypeDuration() && this.targetValue != null) return Math.floor(this.targetValue / 60) + ':' + ((this.targetValue % 60) < 10 ? '0' : '') + (this.targetValue % 60)
        return this.targetValue
    }
    isMetricTypeText(): boolean {
        return this.metricType == 'TEXT'
    }
    isMetricTypeNumber(): boolean {
        return this.metricType == 'NUMBER'
    }
    isMetricTypeYesNo(): boolean {
        return this.metricType == 'YES_NO'
    }
    isMetricTypeDuration(): boolean {
        return this.metricType == 'DURATION'
    }
    isMetricTypeSelection(): boolean {
        return this.metricType == 'SELECTION'
    }
    isMetricTypeImage(): boolean {
        return this.metricType == 'IMAGE'
    }
    isMetricTypeVideo(): boolean {
        return this.metricType == 'VIDEO'
    }
    isMetricTypeMultiselect(): boolean {
        return this.metricType == 'MULTISELECT'
    }
    isMetricTypeToDo(): boolean {
        return this.metricType == 'TODO'
    }
    isDataTypeInteger(): boolean {
        return this.dataType == 'INTEGER'
    }
    isDataTypeDouble(): boolean {
        return this.dataType == 'DOUBLE'
    }

    static metricIdSleepDuration(): string {
        return "ENTLILvd6P9AjnRVrYsi"
    }
    static metricIdNote(): string {
        return "wD83UBPLpOi0XWVJuIss"
    }
    static metricIdMenstruation(): string {
        return "4F7oGBpqTUxmCDiTwRW9"
    }
}


export class MetricGroup {
    id: string;
    metricIds: string[];
    name: LanguageDictionary<any>;
    licenceHolderUid: string;

    metrics: Metric[];

    public get public() : boolean {
        return this.licenceHolderUid == 'nutrilize'; 
    }
    

    constructor();
    constructor(init: MetricGroup);
    constructor(init?: MetricGroup) {
        this.id = init && init.id || null;
        this.name = this.name = init && new LanguageDictionary(init.name.de, init.name.en, init.name.originObject) || new LanguageDictionary()
        this.metricIds = init && init.metricIds || [];
        this.licenceHolderUid = init && init.licenceHolderUid || null;
        this.metrics = init && init.metrics?.map(metric => new Metric(metric)) || [];
    }

    getMetricNamesString(language: string){
        let result: string = "";
        for (let index = 0; index < this.metrics.length; index++) {
            const metric = this.metrics[index];
            if(result.length > 0) result += ", ";
            if(language == "en"){
                result += metric.nameEn ?? '';
            }
            else {
                result += metric.nameDe ?? '';
            }
            if(index > 4) {
                if(index < this.metrics.length) {
                    result += ", ...";
                }
                break;
            }
        }
        return result;
    }

    clone(): MetricGroup{
        return new MetricGroup(this);
    }
}