<div class="dialog-topbar">
    <div class="icon-button float-right" (click)="onCloseDialog()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        Schließen
    </div>
</div>
<div class="dialog-content-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="row margin-0">
                    <div class="thumbnail-image">
                        <img draggable="false" src="{{getExerciseById(plannedExercise.exerciseId)?.thumbnailDownloadURL}}" *ngIf="getExerciseById(plannedExercise.exerciseId)?.thumbnailDownloadURL">
                    </div>
                    <div class="col">
                        <div class="row">
                            <b>{{getExerciseById(plannedExercise.exerciseId)?.name?.GetValue(selectedLanguageCode)}}</b>
                        </div>
                        <div class="row">
                            {{getExerciseById(plannedExercise.exerciseId)?.subName?.GetValue(selectedLanguageCode)}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="spacer-25"></div>
        <div class="row">
            <div class="d-inline-block col-auto wrapper-session-selection">
                <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownSession" (click)="onOpenSessionSelection()" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getSelectedSessions()}}</button>
                <div class="dropdown-menu" aria-labelledby="dropdownSession">
                    <button class="dropdown-item {{selectedSessions?.length == 0 ? 'selected' : ''}}" type="button" (click)="onSessionSelectionChanged(null)">
                        Alle Einheiten
                        <svg *ngIf="selectedSessions?.length == 0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                        </svg>
                    </button>
                    <div *ngFor="let plan of availableTrainingPlans">
                        <div class="ml-1 label">{{plan.name}}</div>
                        <ng-container *ngFor="let session of plan.sessions">
                            <button class="dropdown-item {{selectedSessions.includes(session) ? 'selected' : ''}}" type="button" (click)="onSessionSelectionChanged(session)">
                                {{session.name || 'Einheit'}}
                                <svg *ngIf="selectedSessions.includes(session)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                                </svg>
                            </button>
                        </ng-container>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="spacer-10"></div>
        <div class="row">
            <ng-container *ngFor="let session of sessions">
                <div class="col-12">
                    <div class="layout-box sub-box">
                        <div class="exercise-group-heading">{{session?.sessionName || getPlannedSession(session)?.name || 'Einheit'}}</div>
                        <div>{{session?.endDate?.toLocaleString()}}</div>
                        <div class="spacer-10"></div>
                        <ng-container *ngFor="let exercise of session.trackedTrainingExercises">
                            <div *ngIf="exercise.exerciseId == plannedExercise.exerciseId" class="">                                
                                <div class="row margin-0" *ngIf="exercise.trackedSets?.length > 0">
                                    <table class="col-12 col-md-8 sets-table exercise-table">
                                        <thead>
                                            <tr>
                                                <th class="setnumber">
                                                    <div class="label">Satz</div>
                                                    <div class="text-center">#</div>
                                                </th>
                                                <th class="value-column" *ngFor="let setParameter of getAvailableSetParameters(exercise.trackedSets)">
                                                    <div class="label">{{setParameter2SubHeadingMapping[setParameter]}}</div>
                                                    <div>{{setParameter2LabelUnitMapping[setParameter]}}</div>
                                                </th>
                                                <th class="value-column" *ngIf="hasExertionValues(exercise.trackedSets)">
                                                    <div class="label">{{setParameter2SubHeadingMapping[setParameter.rir]}}</div>
                                                    {{getExertionUnitFromPlannedExercise(plannedExercise)}}
                                                </th>
                                                <th class="value-column" *ngIf="trackedTrainingSetHasAnyPauseDuration(exercise.trackedSets)">
                                                    <div class="label">Pausendauer</div>
                                                    <div>{{setParameter2LabelUnitMapping[setParameter.time]}}</div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let set of exercise.trackedSets; let i = index">
                                                <td class="setnumber" class="{{isPartOfDropset(set, exercise.trackedSets, i) ? 'dropset-line' : ''}}">
                                                    <ng-container *ngIf="set.isWarmupSet || set.isDropset; else defaultSet">
                                                        <div matTooltip="’W’ kennzeichnet einen Warmup-Satz. Dieser Satz fließt nicht in deine Statistik mit ein." class="setmode-indicator" *ngIf="set.isWarmupSet">
                                                            W
                                                        </div>
                                                        <div matTooltip="’D’ kennzeichnet einen Dropset." class="setmode-indicator" *ngIf="set.isDropset">
                                                            D
                                                        </div>
                                                    </ng-container>
                                                    <ng-template #defaultSet>
                                                        <span tooltip="Satz-Typ wählen">
                                                            {{exercise.getSetIndexWithoutWarmupSets(set) + 1}}
                                                        </span>
                                                    </ng-template>
                                                    <!-- {{set.setNumber + 1}} -->
                                                    <div *ngIf="exercise.getTrackedVideoRecordingBySetNumber(set.setNumber)" class="video-recording-indicator">
                                                        <div class="icon-button" (click)="onShowTrackedVideoRecording(exercise.getTrackedVideoRecordingBySetNumber(set.setNumber), exercise)">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-camera-video" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"/>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="value-column" *ngFor="let setParameter of getAvailableSetParameters(exercise.trackedSets)">
                                                    <ng-container *ngIf="setParameter == 'time'; else notduration;">
                                                        {{getSecondsAsTime(set[setParameter])}}<div class="label" style="display: inline;">{{getPlannedSetValue(session, exercise, i, setParameter)}}</div>
                                                    </ng-container>
                                                    <ng-template #notduration>
                                                        {{set[setParameter]}}<div class="label" style="display: inline;">{{getPlannedSetValue(session, exercise, i, setParameter)}}</div>
                                                    </ng-template>
                                                </td>
                                                <td class="value-column" *ngIf="hasExertionValues(exercise.trackedSets)">
                                                    {{set.exertionValue}}
                                                </td>
                                                <td class="value-column" *ngIf="trackedTrainingSetHasAnyPauseDuration(exercise.trackedSets)">
                                                    {{getSecondsAsTime(set.pauseDuration)}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="row" *ngIf="exercise.note">
                                    <div class="spacer-25"></div>
                                    <div class="col-12">
                                        <svg _ngcontent-aot-c1="" class="bi bi-sticky" fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path _ngcontent-aot-c1="" d="M2.5 1A1.5 1.5 0 0 0 1 2.5v11A1.5 1.5 0 0 0 2.5 15h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 15 8.586V2.5A1.5 1.5 0 0 0 13.5 1h-11zM2 2.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5V8H9.5A1.5 1.5 0 0 0 8 9.5V14H2.5a.5.5 0 0 1-.5-.5v-11zm7 11.293V9.5a.5.5 0 0 1 .5-.5h4.293L9 13.793z"></path></svg>
                                        {{exercise.note}}
                                    </div>
                                    <div class="spacer-25"></div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>