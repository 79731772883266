<div class="floating-task-button link" (click)="onShowTaskManagement()" *ngIf="!taskManagementService.taskManagementVisible">
    <i class="bi bi-check2-square"></i>
</div>
<div class="task-management-bar" [ngClass]="{'show': taskManagementService.taskManagementVisible}">
    <div class="task-management-top">
        <h3 class="display-inlineblock">Aufgaben</h3>
        <div class="icon-button marginleft-5" (click)="onCreateTask();$event.stopPropagation()">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
            </svg>
            Hinzufügen
        </div>
        <div class="icon-button display-inlineblock hide-sidebar-button" (click)="onHideTaskManagement()">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
        </div>
        <div class="row" *ngIf="coaches?.length > 1">
            <div class="d-inline-block wrapper-selection">
                <button class="w-100 white button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownRecipient" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{selectedCoach.name}}</button>
                <div class="dropdown-menu dropdown-recipient" aria-labelledby="dropdownNotificationTarget">
                    <div class="dropdown-item link" *ngFor="let coach of coaches" (click)="onSelectedCoachChanged(coach)">
                        {{coach.name}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="!loadingTasks">
        <div class="col-12">
            <div class="spacer-10"></div>
            <input id="tasksearch-input" class="input wide" (input)="onSearchInputChanged($event.target.value)" placeholder="Aufgaben durchsuchen" type="text">
            <div class="icon-button deletesearch-icon delete-recipesearch on-field-search" (click)="onDeleteSearchInput()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </div>
            <button class="filter-button-large display-inlineblock marginleft-10" data-toggle="collapse" href="#collapseFilterContainer" role="button" aria-expanded="false" aria-controls="collapseFilterContainer">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filter" viewBox="0 0 16 16">
                    <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
                </svg>
            </button>
            <div class="spacer-10"></div>
        </div>
        <div class="col-12 mobile-filter">
            <div class="collapse" id="collapseFilterContainer">
                <div class="card card-body">
                    <div class="row">
                        <div class="col-12 mobile-filter-container mobile-reset-filter">
                            <button class="filter-button" (click)="onRemoveAllFilters(); $event.stopPropagation()">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </button>
                            <span>Filter zurücksetzen</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="row">
                                <div class="col-12 col-md-6 mobile-filter-container">
                                    <app-filter-selection-dropdown [availableFilterObjects]="filterableTags" (filterChanged)="setVisibleTasks()" [selectedLanguage]="languageService.selectedLanguageCode"></app-filter-selection-dropdown>
                                    <span>Tags</span>
                                </div>
                                <div class="col-12 col-md-6 mobile-filter-container">
                                    <app-filter-selection-dropdown [availableFilterObjects]="filterableClients" (filterChanged)="setVisibleTasks()" [selectedLanguage]="languageService.selectedLanguageCode"></app-filter-selection-dropdown>
                                    <span>Kunde</span>
                                </div>
                                <div class="col-12 col-md-6 mobile-filter-container">
                                    <app-filter-selection-dropdown [availableFilterObjects]="filterableClientGroups" (filterChanged)="setVisibleTasks()" [selectedLanguage]="languageService.selectedLanguageCode"></app-filter-selection-dropdown>
                                    <span>Gruppen</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="spacer-10"></div>
    </div>
    
    <div class="row" *ngIf="selectedUserUid">
        <div class="col-12">
            <div class="tab-selection textalign-center tab-button-group adjust-mobile padding-0">
                <button class="tab-button {{!showAllTasks ? 'active' : ''}}" (click)="onSelectShowAll(false)">
                    Verknüpft mit {{selectedUserName}}
                    <div *ngIf="!showAllTasks" class="underline"></div>
                </button>
                <button class="tab-button {{showAllTasks ? 'active' : ''}}" (click)="onSelectShowAll(true)">
                    Alle
                    <div *ngIf="showAllTasks" class="underline"></div>
                </button>
            </div>
            <div class="spacer-10"></div>
        </div>
    </div>
    <!-- <ng-container>
        <div class="tab-selection textalign-center tab-button-group adjust-mobile padding-0">
            <button class="tab-button" (click)="setSelectedTaskTimeFrame(TaskTimeFrame.all)">
                Alle ({{getNumberOfTasksForTimeFrame(TaskTimeFrame.all)}})
                <div *ngIf="selectedTaskTimeFrame == TaskTimeFrame.all"  class="underline"></div>
            </button>
            <button class="tab-button" (click)="setSelectedTaskTimeFrame(TaskTimeFrame.past)">
                Überfällig ({{getNumberOfTasksForTimeFrame(TaskTimeFrame.past)}})
                <div *ngIf="selectedTaskTimeFrame == TaskTimeFrame.past"  class="underline"></div>
            </button>
            <button class="tab-button" (click)="setSelectedTaskTimeFrame(TaskTimeFrame.today)">
                Heute fällig ({{getNumberOfTasksForTimeFrame(TaskTimeFrame.today)}})
                <div *ngIf="selectedTaskTimeFrame == TaskTimeFrame.today"  class="underline"></div>
            </button>
            <button class="tab-button" (click)="setSelectedTaskTimeFrame(TaskTimeFrame.future)">
                Zukünftig ({{getNumberOfTasksForTimeFrame(TaskTimeFrame.future)}})
                <div *ngIf="selectedTaskTimeFrame == TaskTimeFrame.future" class="underline"></div>
            </button>
        </div>
        <div class="spacer-10"></div>
    </ng-container> -->
    <div class="task-management-content">
        <div class="task-list" [@.disabled]="animationIsDisabled">
            <div *ngIf="todayTasks?.length > 0">
                <div class="subheading">
                    Heute
                </div>
                <ng-container *ngFor="let task of todayTasks">
                    <div @fadeSlideGrowKeyframe *ngIf="!task.done && (showAllTasks || task.connectedClientUid == selectedUserUid || isUserPartOfGroup(task.connectedClientGroup)) && task.assignedCoachUid == selectedCoach.uid">
                        <app-task-element [task]="task" [(selectedTask)]="taskManagementService.selectedTask" [selectedSubTask]="selectedSubTask" (onCompleteTask)="onCompleteTask(task)" (onUnCompleteTask)="onUnCompleteTask(task)" (onDeleteTask)="onDeleteTask(task)" (onDuplicateTask)="onDuplicateTask(task)" (onEditTask)="onEditTask(task)" (onAddSubTask)="onAddSubTask(task)" (onSaveSubTasks)="onSaveSubTasks(task)" (onSaveAndAddSubTasks)="onSaveAndAddSubTasks(task, $event)" (onRemoveSubTask)="onRemoveSubTask(task, $event)" (onCompleteSubTask)="onCompleteSubTask(task, $event)" (onUnCompleteSubTask)="onUnCompleteSubTask(task, $event)"></app-task-element>
                    </div>
                </ng-container>
            </div>
            <div *ngIf="pastTasks?.length > 0">
                <div class="subheading">
                    Überfällig
                </div>
                <ng-container *ngFor="let task of pastTasks">
                    <div @fadeSlideGrowKeyframe *ngIf="!task.done && (showAllTasks || task.connectedClientUid == selectedUserUid || isUserPartOfGroup(task.connectedClientGroup)) && task.assignedCoachUid == selectedCoach.uid">
                        <app-task-element [task]="task" [(selectedTask)]="taskManagementService.selectedTask" [selectedSubTask]="selectedSubTask" (onCompleteTask)="onCompleteTask(task)" (onUnCompleteTask)="onUnCompleteTask(task)" (onDeleteTask)="onDeleteTask(task)" (onDuplicateTask)="onDuplicateTask(task)" (onEditTask)="onEditTask(task)" (onAddSubTask)="onAddSubTask(task)" (onSaveSubTasks)="onSaveSubTasks(task)" (onSaveAndAddSubTasks)="onSaveAndAddSubTasks(task, $event)" (onRemoveSubTask)="onRemoveSubTask(task, $event)" (onCompleteSubTask)="onCompleteSubTask(task, $event)" (onUnCompleteSubTask)="onUnCompleteSubTask(task, $event)"></app-task-element>
                    </div>
                </ng-container>
            </div>
            <div *ngIf="futureTasks?.length > 0">
                <div class="subheading">
                    Zukünftig
                </div>
                <ng-container *ngFor="let task of futureTasks">
                    <div @fadeSlideGrowKeyframe *ngIf="!task.done && (showAllTasks || task.connectedClientUid == selectedUserUid || isUserPartOfGroup(task.connectedClientGroup)) && task.assignedCoachUid == selectedCoach.uid">
                        <app-task-element [task]="task" [(selectedTask)]="taskManagementService.selectedTask" [selectedSubTask]="selectedSubTask" (onCompleteTask)="onCompleteTask(task)" (onUnCompleteTask)="onUnCompleteTask(task)" (onDeleteTask)="onDeleteTask(task)" (onDuplicateTask)="onDuplicateTask(task)" (onEditTask)="onEditTask(task)" (onAddSubTask)="onAddSubTask(task)" (onSaveSubTasks)="onSaveSubTasks(task)" (onSaveAndAddSubTasks)="onSaveAndAddSubTasks(task, $event)" (onRemoveSubTask)="onRemoveSubTask(task, $event)" (onCompleteSubTask)="onCompleteSubTask(task, $event)" (onUnCompleteSubTask)="onUnCompleteSubTask(task, $event)"></app-task-element>
                    </div>
                </ng-container>
            </div>
                <!-- <ng-container *ngFor="let task of tasks">
                    <div @fadeSlideGrowKeyframe *ngIf="!task.done && (showAllTasks || task.connectedClientUid == selectedUserUid || isUserPartOfGroup(task.connectedClientGroup)) && task.assignedCoachUid == selectedCoach.uid">
                        <app-task-element [task]="task" [(selectedTask)]="taskManagementService.selectedTask" [selectedSubTask]="selectedSubTask" (onCompleteTask)="onCompleteTask(task)" (onUnCompleteTask)="onUnCompleteTask(task)" (onDeleteTask)="onDeleteTask(task)" (onDuplicateTask)="onDuplicateTask(task)" (onEditTask)="onEditTask(task)" (onAddSubTask)="onAddSubTask(task)" (onSaveSubTasks)="onSaveSubTasks(task)" (onSaveAndAddSubTasks)="onSaveAndAddSubTasks(task, $event)" (onRemoveSubTask)="onRemoveSubTask(task, $event)" (onCompleteSubTask)="onCompleteSubTask(task, $event)" (onUnCompleteSubTask)="onUnCompleteSubTask(task, $event)"></app-task-element>
                    </div>
                </ng-container> -->
            <!-- </div> -->
            <!-- <ng-container *ngFor="let taskByDate of tasksByDate">
                <div class="task-list-date" *ngIf="taskByDate.tasks.length > 0">
                    <div class="task-list-date-title label">{{taskByDate.getPrintableDate()}}</div>
                </div>
                <ng-container *ngFor="let task of taskByDate.tasks">
                    <div @fadeSlideGrowKeyframe>
                        <app-task-element [task]="task" [(selectedTask)]="taskManagementService.selectedTask" [selectedSubTask]="selectedSubTask" (onCompleteTask)="onCompleteTask(task)" (onUnCompleteTask)="onUnCompleteTask(task)" (onDeleteTask)="onDeleteTask(task)" (onDuplicateTask)="onDuplicateTask(task)" (onEditTask)="onEditTask(task)" (onAddSubTask)="onAddSubTask(task)" (onSaveSubTasks)="onSaveSubTasks(task)" (onSaveAndAddSubTasks)="onSaveAndAddSubTasks(task, $event)" (onRemoveSubTask)="onRemoveSubTask(task, $event)" (onCompleteSubTask)="onCompleteSubTask(task, $event)" (onUnCompleteSubTask)="onUnCompleteSubTask(task, $event)"></app-task-element>
                    </div>
                </ng-container>
            </ng-container> -->
            <div *ngIf="!showDoneTasks" class="icon-button display-inlineblock showdone-button" (click)="onShowDoneTasks(true)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                </svg>
                Erledigte anzeigen
            </div>
            <div *ngIf="showDoneTasks" class="icon-button display-inlineblock" (click)="onShowDoneTasks(false)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                </svg>
                Erledigte verbergen
            </div>
            <ng-container *ngIf="showDoneTasks">
                <ng-container *ngFor="let task of tasks">
                    <div  @fadeSlideGrowKeyframe *ngIf="task.done && (showAllTasks || task.connectedClientUid == selectedUserUid || isUserPartOfGroup(task.connectedClientGroup)) && task.assignedCoachUid == selectedCoach.uid">
                        <app-task-element [task]="task" [(selectedTask)]="taskManagementService.selectedTask" [selectedSubTask]="selectedSubTask" (onCompleteTask)="onCompleteTask(task)" (onUnCompleteTask)="onUnCompleteTask(task)" (onDeleteTask)="onDeleteTask(task)" (onDuplicateTask)="onDuplicateTask(task)" (onEditTask)="onEditTask(task)" (onAddSubTask)="onAddSubTask(task)" (onSaveSubTasks)="onSaveSubTasks(task)" (onSaveAndAddSubTasks)="onSaveAndAddSubTasks(task, $event)" (onRemoveSubTask)="onRemoveSubTask(task, $event)" (onCompleteSubTask)="onCompleteSubTask(task, $event)" (onUnCompleteSubTask)="onUnCompleteSubTask(task, $event)"></app-task-element>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        
        <mat-spinner *ngIf="loadingTasks" class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
    </div>
    
    <div class="create-task-button" *ngIf="taskManagementService.taskManagementVisible && !taskManagementService.selectedTask">
        <button class="button-filled small float-right" (click)="onCreateTask()">
            <i class="bi bi-plus"></i>
        </button>
    </div>
    <div class="task-management-bottom">
        <div class="container" *ngIf="taskManagementService.taskManagementVisible && taskManagementService.selectedTask" @leaveToBottom>
            <div class="row">
                <div class="col-12">
                    <input type="text" (keyup.enter)="onSaveTask(taskManagementService.selectedTask)" class="white wide-input small" placeholder="Titel" [(ngModel)]="taskManagementService.selectedTask.title">
                </div>
            </div>
            <div class="spacer-10"></div>
            <div class="row">
                <div class="col-12">
                    <app-md-editor class="single-line mdeditor-whitefield" [placeHolder]="'Details'"
                            (valueChanged)="setDescription(taskManagementService.selectedTask, $event)"
                            [inputValue]="taskManagementService.selectedTask.description">
                    </app-md-editor>
                </div>
            </div>
            <div class="spacer-10"></div>
            <div class="row">
                <div class="col-6">
                    <label class="label" for="taskDate">Fälligkeitsdatum</label>
                    <div class="row">
                        <div class="col d-flex pr-0">
                            <input type="text" class="time-input wide-input small white margin-vertical-auto" placeholder="dd.MM.yyyy HH:mm" [ngModel]="getDueDateTimeForMask(taskManagementService.selectedTask)" (input)="onDueMaskInputChanged(taskManagementService.selectedTask, $event.target.value)" mask="d0.M0.0000 Hh:m0"/>
                        </div>
                        <div class="col-auto p-0" style="line-height: 1;">
                            <input [matDatepicker]="diaryDatePicker" class="visibility-hidden width-0 padding-0" (dateInput)="onDueDatepickerChanged(taskManagementService.selectedTask, $event.value?.toDate())">
                            <mat-datepicker-toggle matSuffix [for]="diaryDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #diaryDatePicker></mat-datepicker>
                        </div>
                    </div>
                    <!-- <input type="datetime-local" class="form-datetime-input white wide-input small" id="taskDate" [ngModel]="getDueDateTime(taskManagementService.selectedTask)" (input)="onDueDateTimeChanged(taskManagementService.selectedTask, $event.target.value)"> -->
                    
                    <!-- <div class="row">
                        <div class="col-6">
                            <input type="date" class="form-datetime-input white wide-input small" id="taskDate" [ngModel]="getDueDate(taskManagementService.selectedTask)" (input)="onDueDateChanged(taskManagementService.selectedTask, $event.target.value)">
                        </div>
                        <div class="col-6">
                            <input type="time" class="form-datetime-input white wide-input small" id="taskDate" [ngModel]="getDueTime(taskManagementService.selectedTask)" (input)="onDueTimeChanged(taskManagementService.selectedTask, $event.target.value)">
                        </div>
                    </div> -->
                </div>
                <div *ngIf="taskManagementService.selectedTask.dueDate != null" class="col-6">
                    <label class="label">Wiederholung</label>
                    <div class="d-inline-block wrapper-repetition-selection">
                        <button class="white button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{repetition2LabelMapping[taskManagementService.selectedTask?.repetition]}}</button>
                        <div class="dropdown-menu" aria-labelledby="dropdownBodyDataGraphTimeRange">
                            <div *ngFor="let rep of repetitionLabels">
                                <button class="dropdown-item" type="button" (click)="onRepetitionSelectionChanged(taskManagementService.selectedTask, rep)">{{rep}}</button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="taskManagementService.selectedTask?.repetition === 'everyXDays'">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                        </svg>
                        Wiederholung alle <input class="white" type="number" min="1" max="4000" step="1" required mdInput [ngModel]="taskManagementService.selectedTask?.repetitionMultiplier" (input)="onRepetitionMultiplierChanged(taskManagementService.selectedTask, $event.target.value)"> Tage
                    </div>
                    <div *ngIf="!isRepetitionMultiplierValid(taskManagementService.selectedTask)" class="label color-red">Die Auswahl muss zwischen 1 und 3000 liegen.</div>
                </div>
            </div>
            <div class="row">
                <div *ngIf="taskManagementService.selectedTask.dueDate != null && taskManagementService.selectedTask?.repetition == Repetition.weekly" class="col-12">
                    <label class="label">Wiederholt sich am</label>
                    <div class="row ml-0 mb-2">
                        <div class="day-item" *ngFor="let shortWeekDayString of shortWeekDayStrings;">
                            <button class="calendar-button label p-0" [ngClass]="{'active': taskManagementService.selectedTask.repetitionWeekDays.isDayNumberSelected(shortWeekDayStringsToNumberMapping[shortWeekDayString])}" type="button" (click)="taskManagementService.selectedTask.repetitionWeekDays.onSwitchDayNumber(shortWeekDayStringsToNumberMapping[shortWeekDayString])">{{shortWeekDayString}}</button>
                        </div>
                    </div>
                    <!-- <div class="d-inline-block wrapper-repetition-selection">
                        <button class="white button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{repetition2LabelMapping[taskManagementService.selectedTask?.repetition]}}</button>
                        <div class="dropdown-menu" aria-labelledby="dropdownBodyDataGraphTimeRange">
                            <div *ngFor="let rep of repetitionLabels">
                                <button class="dropdown-item" type="button" (click)="onRepetitionSelectionChanged(taskManagementService.selectedTask, rep)">{{rep}}</button>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <label class="label" for="taskPriority">Priorität</label>
                    <button class="w-100 white button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownPriority" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{priority2LabelMapping[taskManagementService.selectedTask.priority]}}</button>
                    <div class="dropdown-menu dropdown-recipient" aria-labelledby="dropdownPriority">
                        <div class="dropdown-item link" *ngFor="let priority of priorityLabels" (click)="onPrioritySelectionChanged(taskManagementService.selectedTask, priority)">
                            {{priority}}
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <label class="label">Verknüpfter Kunde</label>
                    <div class="d-inline-block wrapper-selection w-100">
                        <button class="w-100 white button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownRecipient" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getPrintableConnectedClientOrGroupName(taskManagementService.selectedTask)}}</button>
                        <div class="dropdown-menu dropdown-recipient" aria-labelledby="dropdownNotificationTarget">
                            <div class="dropdown-item link" (click)="onTargetSelectionChangedConnectedUser(taskManagementService.selectedTask, '')">
                            -
                            </div>
                            <div class="dropdown-divider"></div>
                            <div class="dropdown-item link" *ngFor="let clientGroup of getSelectableClientGroups()" (click)="onTargetSelectionChangedConnectedClientGroup(taskManagementService.selectedTask, clientGroup)">
                                {{clientGroup}}
                            </div>
                            <div class="dropdown-divider"></div>
                            <div class="dropdown-item link" *ngFor="let user of getSelectableClients()" (click)="onTargetSelectionChangedConnectedUser(taskManagementService.selectedTask, user.uid)">
                                {{user.getName()}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="coaches?.length > 1">
                <div class="col-6">
                    <label class="label">Zugewiesener Coach</label>
                    <div class="d-inline-block wrapper-selection w-100">
                        <button class="w-100 white button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownRecipient" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getPrintableAssignedCoachName(taskManagementService.selectedTask)}}</button>
                        <div class="dropdown-menu dropdown-recipient" aria-labelledby="dropdownNotificationTarget">
                            <div class="dropdown-item link" *ngFor="let coach of coaches" (click)="onTargetSelectionChangedAssignedCoach(taskManagementService.selectedTask, coach.uid)">
                                {{coach.name}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <label class="label" for="category-select">Tags</label>
                    <app-search-input-select id="category-select" [(SelectedItems)]="taskManagementService.selectedTask.tags" [SelectableItems]="taskManagementService.selectableTags" [LanguageCode]="languageService.selectedLanguageCode" [FreeInput]="true" [translatable]="false"></app-search-input-select>
                </div>
            </div>
            <div class="spacer-10"></div>
            <div class="">
                <div class="d-inline-block">
                    <button class="button-filled small" (click)="onSaveTask(taskManagementService.selectedTask)">
                        <span *ngIf="taskManagementService.selectedTask.id">Speichern</span>
                        <span *ngIf="!taskManagementService.selectedTask.id">Erstellen</span>
                    </button>
                </div>
                <div class="marginleft-25 d-inline-block">
                    <button class="button-filled light small" (click)="onCancelEdit()">
                        Abbrechen
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>