import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

    constructor() {}

    public windowWidth: number
    private eventSource = new BehaviorSubject('');
    public currentEvent = this.eventSource.asObservable();


    public onMobile(): boolean {
        return (this.windowWidth < 960)
    }
    public onSmallDisplay(): boolean {
        return (this.windowWidth < 576)
    }
    public restrictFeatures(): boolean {
      return (this.windowWidth < 700) || this.onNativeMobileApp()
    }

    public onNativeMobileApp(): boolean {
      return (Capacitor.getPlatform() != 'web');
      // return true;
    }

    public customMobileAppStyle(cssClass:string): Object{
      if(this.onNativeMobileApp()){
        switch(cssClass){
          case "layout-box": return {'min-height': '90px', 'margin': '5px 0'};
          case "dashboardOverview": return {'order': '0', 'margin': '5px 0'};
          case "dashboardCustomers": return {'order': '1'};
          case "dashboardH2": return {'margin-bottom': '0'};
          case "addToDashboard": return {'margin-top': '0', 'margin-bottom': '60px'};
          case "coach-area-customer-margin": return {'margin-bottom': '30px'};
        }
      }
      return {}
    }

    publishEvent(e: string) {
      this.eventSource.next(e)
    }

    
    onImageErrorHide(event){
      event.target.style.display = 'none';
    }

    getCSSVariableValue(variableName: string): string {
      return getComputedStyle(document.documentElement).getPropertyValue(variableName);
    }
}
