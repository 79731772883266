import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-mealsuggestions-settings-dialog',
  templateUrl: './mealsuggestions-settings-dialog.component.html',
  styleUrls: ['./mealsuggestions-settings-dialog.component.css']
})
export class MealsuggestionsSettingsDialogComponent {

  constructor(public dialogRef: MatDialogRef<MealsuggestionsSettingsDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { mealSuggestionAccuracy: string, allowWeightAdjustment: boolean }, public dialog: MatDialog) {
    this.mealSuggestionAccuracy = data.mealSuggestionAccuracy
    this.allowWeightAdjustment = data.allowWeightAdjustment
  }

  public mealSuggestionAccuracy: string = 'medium'
  public allowWeightAdjustment: boolean = false

  onSetMealSuggestionAccuracy(accuracy: string) {
    this.mealSuggestionAccuracy = accuracy
  }

  onSubmit(){
    this.dialogRef.close({mealSuggestionAccuracy: this.mealSuggestionAccuracy, allowWeightAdjustment: this.allowWeightAdjustment})
  }
}
