import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Repetition } from 'src/app/model/automatic-push-notification.model';
import { SubTask, Task } from 'src/app/model/task.model';
import { TaskManagementService } from 'src/app/services/task-management.service';
import { TaskManagementBase } from '../task-management-base';
import { MatDialog } from '@angular/material/dialog';
import { FirestoreService } from 'src/app/services/firestore.service';
import { Coach } from 'src/app/model/coach.model';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.css'],
  animations: [
    trigger('fadeSlideGrowKeyframe', [
      transition(':enter', [
          style({ opacity: 0, transform: 'scale(0.5) translateY(50px)' }),
          animate(
              '500ms',
              keyframes([
                  style({ opacity: 1, offset: 0.3 }),
                  style({ transform: 'translateY(0)', offset: 0.6 }),
                  style({ transform: 'scale(1)', offset: 1 }),
              ])
          ),
      ]),
      transition(':leave', [
          animate(
              '500ms',
              keyframes([
                  style({ transform: 'scale(1)', offset: 0.3 }),
                  style({ transform: 'translateY(50px)', offset: 0.6 }),
                  style({ opacity: 0, transform: 'scale(0.5)', offset: 1 }),
              ])
          ),
      ]),
    ]),
    trigger('fadeSlideGrowOnValuechangeKeyframe', [
      // state('false', style({ opacity: 0 })),
      // state('true', style({ opacity: 1, offset: 0 })),
      transition('true => false', [
          style({ opacity: 0, transform: 'scale(0.5) translateY(50px)' }),
          animate(
              '500ms',
              keyframes([
                  style({ opacity: 1, offset: 0.3 }),
                  style({ transform: 'translateY(0)', offset: 0.6 }),
                  style({ transform: 'scale(1)', offset: 1 }),
              ])
          ),
      ]),
      transition('false => true', [
          animate(
              '500ms',
              keyframes([
                  style({ transform: 'scale(1)', offset: 0.3 }),
                  style({ transform: 'translateY(50px)', offset: 0.6 }),
                  style({ opacity: 0, transform: 'scale(0.5)', offset: 1 }),
              ])
          ),
      ]),
    ]),
  ]
})
export class TaskListComponent extends TaskManagementBase implements OnInit {
  constructor(taskManagementService: TaskManagementService, toastr: ToastrService, dialog: MatDialog, userService: FirestoreService, spinner: NgxSpinnerService) {
    super(taskManagementService, toastr, dialog, userService, spinner);
  }

  ngOnInit(): void {
    this.selectedTaskTimeFrame = this.TaskTimeFrame.today;
    this.loadTasks();
  }

}
