<dx-chart *ngIf="monitoringGraphData"
    [dataSource]="monitoringGraphData"
    title="">

    <ng-container *ngFor="let graphItem of monitoringGraphItems">
        <dxi-series valueField="{{graphItem.valueField}}" name="{{graphItem.name}}" type="line"></dxi-series>
    </ng-container>

    <dxo-common-series-settings
        argumentField="day"
        type="line"
        valueField="count">
        <dxo-point [visible]="true" [size]="9"></dxo-point>
    </dxo-common-series-settings>
    <dxo-margin [bottom]="20"></dxo-margin>
    <dxo-common-axis-settings>
        <dxo-grid [visible]="true" color="var(--darkestBackgroundColor)"></dxo-grid>
    </dxo-common-axis-settings>
    <dxo-legend
        verticalAlignment="bottom"
        horizontalAlignment="center"
        itemTextPosition="top"
        visible="true">
    </dxo-legend>
    <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip"></dxo-tooltip>
</dx-chart>
<div *ngIf="!shouldLoad" class="icon-button" (click)="onLoadData()">Laden</div>
<br>
<div class="toggle-wrapper">
    <div class="toggle {{includeStarterPackage ? 'on' : ''}}" (click)="onIncludeStarterPackageToggled()">
        <div class="handle"></div>
    </div>
</div>
<div class="label display-inlineblock">Startpaket einbeziehen</div>