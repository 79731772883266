import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { CoachInvoice } from 'src/app/model/invoice-coach.model';
import { ChartExportService } from 'src/app/services/chart-export.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-mrr-graph',
  templateUrl: './mrr-graph.component.html',
  styleUrls: ['./mrr-graph.component.css']
})
export class MrrGraphComponent {

  constructor(public authService: AuthService, private router: Router, public userService: FirestoreService, public utilityService: UtilityService, public chartExportService: ChartExportService) { }

  invoices: CoachInvoice[]
  monitoringGraphData: any[]
  monitoringGraphItems: any[]

  includeStarterPackage = false
  shouldLoad = false

  ngOnInit(): void {
    if (!this.authService.isAdmin()) return;
    
    this.loadData()
  }

  customizeTooltip = (arg: any) => {
    var text = ''
    this.monitoringGraphItems.forEach(item => {
      var value = arg.point?.data[item.valueField]
      if (value) text += item.name + ': ' + value.toFixed(0) + '\n'
    })
    return { text: text }
  }

  onLoadData() {
    this.shouldLoad = true
    this.loadData()
  }

  onIncludeStarterPackageToggled() {
    this.includeStarterPackage = !this.includeStarterPackage
    this.updateGraph()
  }

  loadData() {
    if (!this.shouldLoad) return

    firstValueFrom(this.userService.getInvoicesOfCoaches()).then((invoices) => {
      this.invoices = invoices
      this.updateGraph()
    })
  }

  updateGraph() {
    if (!this.invoices) return
    
    this.monitoringGraphData = []
    this.monitoringGraphItems = []
    var invoicesPerMonth = new Map<string, CoachInvoice[]>()
    this.invoices.forEach(invoice => {
      var day = invoice.date.getDate()
      var month = invoice.date.getMonth() + '-' + invoice.date.getFullYear()
      if (!invoicesPerMonth.has(month)) invoicesPerMonth.set(month, [])
      if (this.includeStarterPackage || !invoice.containsStarterPackage) invoicesPerMonth.get(month).push(invoice)
      var dataItem: any = {
        day: day
      }
      this.monitoringGraphData.push(dataItem)
    })

    invoicesPerMonth.forEach((invoices, month) => {
      this.monitoringGraphItems.push({valueField: month, name: month})

      var invoiceAmountPerDay = new Map<number, number>()
      invoices.forEach(invoice => {
        var day = invoice.date.getDate()
        if (!invoiceAmountPerDay.has(day)) invoiceAmountPerDay.set(day, 0)
        invoiceAmountPerDay.set(day, invoiceAmountPerDay.get(day) + invoice.netAmount / 100)
      })

      var sum = 0
      for (var i = 1; i <= 31; i++) {
        var day = i
        var amount = invoiceAmountPerDay.has(day) ? invoiceAmountPerDay.get(day) : 0
        sum += amount
        this.monitoringGraphData.find(item => item.day == day)[month] = sum
      }
    })
    this.monitoringGraphItems = this.monitoringGraphItems.sort((a, b) => a.name.localeCompare(b.name))
  }

}
