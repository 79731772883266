import { Injectable } from '@angular/core';
import { FirestoreService } from './firestore.service';
import { Configuration, OpenAIApi } from 'openai';

@Injectable({
  providedIn: 'root'
})
export class OpenAIService {

  constructor(private userService: FirestoreService) { }

  async generateImage(prompt: string): Promise<string> {
    if (!this.userService.getLoggedInUser().chatGenerationKey){
      return null
    }
    try {
      const configuration = new Configuration({
        organization: "org-SuuOjptvkK9i4dHXRjZp7GWP",
          apiKey: this.userService.getLoggedInUser().chatGenerationKey,
      });
      const openai = new OpenAIApi(configuration);
      let response = await openai.createImage({
        prompt: prompt,
        n: 1,
        size: '512x512',
        //size: '1024x1024'
        response_format: 'b64_json'
      })
  
      console.log(response)
      const base64 = response.data.data[0].b64_json;
      return base64

      //return response.data.data[0].url
      
    } catch (error) {
      console.log(error)
    }
    
  }
}
