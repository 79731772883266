import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {MatRadioModule} from '@angular/material/radio'

@Component({
  selector: 'app-radio-buttons-confirmation-dialog',
  templateUrl: './radio-buttons-confirmation-dialog.component.html',
  styleUrls: ['./radio-buttons-confirmation-dialog.component.css'],
})
export class RadioButtonsConfirmationDialogComponent {

  public message: string
  public title: string
  public radioButtons: string[];
  public selectedRadioButton: string;

  public submitButtonText: string;
  public cancelButtonText: string;

  constructor(public dialogRef: MatDialogRef<RadioButtonsConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {message: string, title: string, radioButtons: string[], submitButtonText: string, cancelButtonText: string}) {
    this.message = data.message
    this.title = data.title
    this.submitButtonText = data.submitButtonText || 'Bestätigen';
    this.cancelButtonText = data.cancelButtonText || 'Abbrechen';
    this.radioButtons = data.radioButtons;
    if(this.radioButtons.length > 0){
      this.selectedRadioButton = this.radioButtons[0];
    }
  }

  ngOnInit(): void {
  }

  onCancelDialog() {
    this.dialogRef.close();
  }
  onConfirmDialog() {
    this.dialogRef.close(this.selectedRadioButton);
  }
}
