import { LanguageDictionary } from "./languagedictionary.model";
import { TrainingPlanAccess } from "./training-plan.model";

export class TrainingPlanTemplateFolder {

    public id: string;
    public licenceHolderUid: string;
    public assignedUids: string[];
    public assignedGroupNames: string[];

    public imagePath: string;
    public description: LanguageDictionary<string> = new LanguageDictionary<string>();
    public name: LanguageDictionary<string> = new LanguageDictionary<string>();
    
    public deleted: boolean;
    public timestamp: Date;

    public imageDownloadURL: string;
    
    public access: string;

    constructor();
    constructor(init: TrainingPlanTemplateFolder);
    constructor(init?: TrainingPlanTemplateFolder) {
        this.id = init && init.id || undefined
        this.licenceHolderUid = init && init.licenceHolderUid || null
        this.assignedUids = init && init.assignedUids.map(x => x) || []
        this.assignedGroupNames = init && init.assignedGroupNames.map(x => x) || []
        this.imagePath = init && init.imagePath || null
        this.name = init && new LanguageDictionary<string>(init.name?.de, init.name?.en) || new LanguageDictionary<string>()
        this.description = init && new LanguageDictionary<string>(init.description?.de, init.description?.en) || new LanguageDictionary<string>()
        
        this.deleted = init && init.deleted || false
        this.timestamp = init && init.timestamp || null;
        this.imageDownloadURL = init && init.imageDownloadURL || null;
        this.access = init && init.access || TrainingPlanAccess.none
    }

    public clone(): TrainingPlanTemplateFolder {
        return new TrainingPlanTemplateFolder(this);
    }

    private numberOfTemplates: number;
    setTempNumberOfTemplates(numberOfTemplates: number) {
        this.numberOfTemplates = numberOfTemplates;
    }
    getTempNumberOfTemplates(): number {
        return this.numberOfTemplates;
    }

    private sharedWith: string;
    setTempSharedWith(sharedWith: string) {
        this.sharedWith = sharedWith;
    }
    getTempSharedWith(): string {
        return this.sharedWith;
    }
}
