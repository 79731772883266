import { Component } from '@angular/core';

@Component({
  selector: 'app-mobile-navigation-bar',
  templateUrl: './mobile-navigation-bar.component.html',
  styleUrls: ['./mobile-navigation-bar.component.css']
})
export class MobileNavigationBarComponent {

}
