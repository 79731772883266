import { Component, NgZone, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IndividualFirebase } from 'src/app/app.module';
import { AuthService } from 'src/app/auth/auth.service';
import { MarketingKitComponent } from 'src/app/dialogs/marketing-kit/marketing-kit.component';
import { FirestoreService } from 'src/app/services/firestore.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { PaymentService } from 'src/app/services/payment.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.css']
})
export class NavigationBarComponent {

  public environment = environment

  @Output() logoutEvent = new EventEmitter<boolean>()

  constructor(private mainFirebase: IndividualFirebase, public authService: AuthService, private paymentService: PaymentService, public router: Router, private activatedRoute: ActivatedRoute, public userService: FirestoreService, public utilityService: UtilityService, public navService: NavbarService, public dialog: MatDialog, private ngZone: NgZone) {

  }

  get coach() {
    return this.userService.getLoggedInUser()?.coach
  }

  async logout() {
    this.logoutEvent.emit(true)
  }

  openMarketingKit() {
    const dialogRef = this.dialog.open(MarketingKitComponent, { data: { }, width: '1000px'})
  }

  toggle() { 
    document.getElementsByClassName('navigation-bar')[0].classList.toggle('show'); 
    document.getElementsByClassName('navigation-toggle')[0].classList.toggle('open');
    return false; 
  }
}
