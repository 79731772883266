import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OpenAIService } from 'src/app/services/openai.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-image-generation-dialog',
  templateUrl: './image-generation-dialog.component.html',
  styleUrls: ['./image-generation-dialog.component.css']
})
export class ImageGenerationDialogComponent {

  prompt: string = ''
  loading: boolean = false
  imageUrl: string
  base64: string

  constructor(public dialogRef: MatDialogRef<ImageGenerationDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { initialPrompt: string }, private openAiService: OpenAIService, private sanitizer: DomSanitizer) {
    this.prompt = data.initialPrompt ?? ''
  }

  ngOnInit(): void {
  }

  onGenerateImage() {
    this.loading = true
    this.openAiService.generateImage(this.prompt).then(base64 => {
      if (!base64) {
        this.loading = false
        return
      }
      this.loading = false
      this.base64 = base64
      this.imageUrl = 'data:image/jpg;base64,' + base64
    })
  }

  onCancelDialog() {
    this.dialogRef.close(null);
  }
  onConfirmDialog() {
    this.dialogRef.close({ success: true, imageUrl: this.imageUrl, base64: this.base64 });
  }
}
