import { FcmService } from './../services/fcm.service';
import { Injectable, destroyPlatform, Inject } from '@angular/core';

import { Router } from  "@angular/router";


import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { CommonFirebase, IndividualFirebase } from '../app.module';

// import { Auth, GoogleAuthProvider } from  'firebase/auth';
// import { AngularFireAuth, GoogleAuthProvider } from  "@angular/fire/compat/auth";
// import { User } from  'firebase/auth';
// import { User } from  '@angular/fire/compat/auth';
import { FirestoreService } from '../services/firestore.service';
import { GoogleAuthProvider, User } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
// import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  static firebaseProjectIndividual = environment.firebaseProjectIndividual

  public static ADMIN_UIDS = ['d3dsx0dDB1ZHRcfbDLKZGQuQI8H3', 'to4IhVEXA4U3gcwtZkOPVYS1yp13'];
  user: User;
  public redirectUrl: string;

  get firebaseAuth(): AngularFireAuth {
    return this.mainFirebase.auth
  }
  get firestore(): AngularFirestore {
    return this.mainFirebase.firestore
  }

  constructor(private mainFirebase: IndividualFirebase, public router: Router) {
  }

  getAuthState(): Observable<User> {
    return this.firebaseAuth.authState;
  }

  getSignedInUid() {
    return this.user?.uid
  }

  setUser(user: User) {
    this.user = user;
  }

  login(email: string, password: string) {
    return this.firebaseAuth.signInWithEmailAndPassword(email, password);
  }
  loginWithToken(token: string) {
    return this.firebaseAuth.signInWithCustomToken(token)
  }
  registerUser(email: string, password: string, name: string) {
    return this.firebaseAuth.createUserWithEmailAndPassword(email, password).then((result) => {
      this.firestore.collection('Users').doc(result.user.uid).set({
        uid: result.user.uid,
        name: name,
      });
    })
  }

  isEmailExisting(email: string): Promise<boolean> {
    return this.firebaseAuth.fetchSignInMethodsForEmail(email).then((result) => {
      return result.length > 0;
    });
  }

  async sendEmailVerification() {
    (await this.firebaseAuth.currentUser).sendEmailVerification();
    this.router.navigate(['verify-email']);
  }

  forgotPassword(passwordResetEmail) {
    return this.firebaseAuth.sendPasswordResetEmail(passwordResetEmail).then(() => {
      window.alert('Du erhältst in wenigen Minuten eine Email, um dein Passwort zurückzusetzen. Bitte prüfe auch deinen Spam-Ordner, falls du die Email nicht findest.');
    }).catch((error) => {
      window.alert(error)
    });
  }

  async logout(){
    if (!environment.isWhitelabel && environment.firebaseProjectId != 'traindoo-app') (window as any).Intercom('shutdown');
    return this.firebaseAuth.signOut().then(() => {
      localStorage.removeItem('user');
      localStorage.clear();
      this.user = null;
      //this.router.navigate(['login']);
    });
  }

  async logoutToPage(navigate: string){
    return this.firebaseAuth.signOut().then(() => {
      localStorage.removeItem('user');
      localStorage.clear();
      this.user = null;
      //this.router.dispose();
      if (navigate) this.router.navigate([navigate]);
    });
  }

  isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      this.user = user
      return true
    }
    return false
  }

  async loginWithGoogle(){
    await this.firebaseAuth.signInWithPopup(new GoogleAuthProvider())
    this.router.navigate(['admin/list']);
  }

  isAdmin(): boolean {
    return AuthService.isAdmin(this.user)
  }
  static isAdmin(user: User): boolean {
    if (AuthService.firebaseProjectIndividual.projectId == 'traindoo-app') return user?.uid == 'IAAsaah0p4gdNXwOVqviFsgpimM2'
    return AuthService.ADMIN_UIDS.includes(user?.uid)
  }

  canAccessTraining(): boolean {
    return true
  }
}
