import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dropdown-selection-dialog',
  templateUrl: './dropdown-selection-dialog.component.html',
  styleUrls: ['./dropdown-selection-dialog.component.css']
})
export class DropdownSelectionDialogComponent {

  public title: string = "";
  public availableDropdownItems: string[] = [];
  public selectedDropdownItem: string = "";
  public cancelButtonText: string = "Abbrechen";
  public submitButtonText: string = "Ok";


  constructor(public dialogRef: MatDialogRef<DropdownSelectionDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { title: string, availableDropdownItems: string[], selectedDropdownItem: string, cancelButtonText: string, submitButtonText: string })
  {
    this.title = data.title;
    this.availableDropdownItems = data.availableDropdownItems;
    this.selectedDropdownItem = data.selectedDropdownItem || "";
    this.cancelButtonText = data.cancelButtonText;
    this.submitButtonText = data.submitButtonText;
  }

  onSelectionChanged(item: string){
    this.selectedDropdownItem = item;
  }

  canSubmit(): boolean {
    return this.selectedDropdownItem !== "";
  }

  onCancel(): void {
    this.dialogRef.close({shouldTake: false});
  }

  onSubmit(): void {
    this.dialogRef.close({shouldTake: true, selectedDropdownItem: this.selectedDropdownItem});
  }
}
