<div class="row">
    <div class="col-md-12">
      <form>
        <input [(ngModel)]="email" [ngModelOptions]="{standalone: true}" type="email" class="form-control mb-2" placeholder="Email" required/>
        <div class="spacer-10"></div>
        <button *ngIf="!emailConfirmed" class="btn btn-lg btn-primary btn-block mb-1 button-filled  {{isEmailValid() ? '' : 'blocked'}}" type="submit" (click)="onConfirmEmail()">Weiter</button>

        <ng-container *ngIf="emailConfirmed">
            <input [(ngModel)]='password' [ngModelOptions]="{standalone: true}" type="password" class="form-control mb-2" placeholder="Passwort" required/>
            <div class="spacer-10"></div>

            <ng-container *ngIf="createAccount">
                <input [(ngModel)]='name' type="text" class="form-control mb-2" placeholder="Dein Vor- und Nachname" required/>
                <div class="spacer-10"></div>
            </ng-container>

            <button class="btn btn-lg btn-primary btn-block mb-1 button-filled" type="submit" (click)="onLogIn()">{{createAccount ? 'Registrieren' : 'Login'}}</button>
        </ng-container>

        <div class="spacer-25"></div>
        <div *ngIf="errorMessage" class="label">{{errorMessage}}</div>
        <div class="spacer-25"></div>
        <button *ngIf="emailConfirmed && !createAccount" class="btn button-filled light small" routerLink="/forgot-password">Passwort zurücksetzen</button>
      </form>
    </div>
  </div>