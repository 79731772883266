import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Coach } from 'src/app/model/coach.model';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-coach-notifications',
  templateUrl: './coach-notifications.component.html',
  styleUrls: ['./coach-notifications.component.css']
})
export class CoachNotificationsComponent {

  public notificationSettingsEntries: any[] = [
    { key: 'trainingCompleted', de: 'Training absolviert'},
    { key: 'questionaireCompleted', de: 'Fragebogen ausgefüllt'},
    { key: 'productPurchased', de: 'Produkt verkauft'},
  ]

  coach: Coach
  emailNotificationSettings: Map<string, boolean>
  pushNotificationSettings: Map<string, boolean>
  communicationEmailAddressChanged: boolean = false

  constructor(public userService: FirestoreService, public toastr: ToastrService) {
    this.coach = this.userService.getLoggedInUser().coach
    this.emailNotificationSettings = this.coach.emailNotificationSettings
    this.pushNotificationSettings = this.coach.pushNotificationSettings
  }

  onEmailNotificationEntryChanged(key: string, value: boolean) {
    this.emailNotificationSettings.set(key, value)
    this.coach.emailNotificationSettings = this.emailNotificationSettings
    this.coach.pushNotificationSettings = this.pushNotificationSettings
    this.userService.updateCoachNotificationSettings(this.coach)
  }
  onPushNotificationEntryChanged(key: string, value: boolean) {
    this.pushNotificationSettings.set(key, value)
    this.coach.emailNotificationSettings = this.emailNotificationSettings
    this.coach.pushNotificationSettings = this.pushNotificationSettings
    this.userService.updateCoachNotificationSettings(this.coach)
  }

  onCommunicationEmailAddressChanged(value: string) {
    this.coach.communicationEmailAddress = value
    this.communicationEmailAddressChanged = true
  }
  async onSaveCommunicationEmailAddress() {
    if (!this.coach.isEmailValid()) {
      this.toastr.error('Bitte gib eine korrekte Email-Adresse an.', "Speichern nicht möglich.",  {
        positionClass: 'toast-bottom-center'
      });
      return
    }
    await this.userService.updateCoachCommunicationEmailAddress(this.coach)
    this.communicationEmailAddressChanged = false
  }
}
