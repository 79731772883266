<div class="dialog-topbar">
    <button class="button-filled small create-Exercise" (click)="onSaveTrainingVariables()">Übernehmen</button>
    <!-- <button class="button-filled small create-Exercise" (click)="onCreateNewVariable()">Neue Variable anlegen</button> -->
    <div class="icon-button float-right link" (click)="onCloseDialog()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        <ng-container *ngIf="!utilityService.onMobile()">
            Schließen
        </ng-container>
    </div>
</div>
<div class="dialog-content-wrapper">
    <div class="col-12 col-xl-12 col-xxl-4 order-3 order-xxl-2">
        <div class="div textalign-center">
            <div class="spacer-10"></div>
            <button *ngIf="enabledTrainingVariableEditorDialogTab != TrainingVariableEditorDialogTab.cardioZones" class="tab-button {{showTrainingVariablesTab ? 'active' : ''}}" (click)="showVariables(true)">
                Variablen
                <div class="underline"></div>
            </button>
            <button *ngIf="enabledTrainingVariableEditorDialogTab != TrainingVariableEditorDialogTab.variables" class="tab-button {{!showTrainingVariablesTab ? 'active' : ''}}" (click)="showVariables(false)">
                {{trainingVariableEditorDialogType == TrainingVariableEditorDialogType.global ? 'Benennung' : ''}} Belastungszonen
                <div class="underline"></div>
            </button>
        </div>
    </div>
    <div *ngIf="showTrainingVariablesTab; else cardioGroups" class="container">
        <div class="spacer-10"></div>

        <div class="hint label">
            <i class="bi bi-info-circle"></i>
            <span>{{getChangesHintText()}}</span>
        </div>
        <div class="spacer-10"></div>

        <table *ngIf="trainingVariables?.length > 0" class="col-12 sets-table">
            <thead class="variable-header">
                <tr>
                    <th>
                        Variable
                    </th>
                    <th>
                        Gewicht
                    </th>
                    <th>
                        Pace
                    </th>
                    <th>
                        Herzfrequenz
                    </th>
                    <th>
                        Pace 500m
                    </th>
                    <th>
                        Wiederholungen
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr class="values-tr" *ngFor="let variable of trainingVariables">
                    <td>
                        <input id="input-variable-name" placeholder="VAR_123" maxlength="10" [disabled]="!variable.isNew" required class="input small form-control full-width {{isVariableNameInvalid(variable) ? 'invalid' : ''}}" [value]="variable.name" (input)="setName(variable, $event.target.value)" [pattern]="namePattern" placeholder="Name" type="text" oninput="this.value = this.value.toUpperCase()" (focusout)="nameLostFocus(variable)">
                    </td>
                    <td> 
                        <input min="0.1" step="0.1" class="input small form-control full-width {{isWeightValueInvalid(variable) ? 'invalid' : ''}}" [disabled]="!variable.weightAvailableOnInstantiation" [value]="variable.weight" (input)="setWeight(variable, $event.target.value)" type="number">
                    </td>
                    <td>    
                        <input min="0" class="input small form-control full-width {{isPaceValueInvalid(variable) ? 'invalid' : ''}}" [disabled]="!variable.paceAvailableOnInstantiation" (input)="setPace(variable, $event.target.value, false)" type="text" [pattern]="paceRangePattern" [value]="getPace(variable, false)" placeholder="00:00(-00:00)">
                    </td>
                    <td>
                        <input min="0" class="input small form-control full-width {{isHeartRateValueInvalid(variable) ? 'invalid' : ''}}" [disabled]="!variable.heartRateAvailableOnInstantiation" (input)="setHeartRate(variable, $event.target.value)" type="text" [pattern]="rangePattern" [value]="getHeartRate(variable)">
                    </td>
                    <td>    
                        <input min="0" class="input small form-control full-width {{isPace500ValueInvalid(variable) ? 'invalid' : ''}}" [disabled]="!variable.pace500AvailableOnInstantiation" (input)="setPace(variable, $event.target.value, true)" type="text" [pattern]="paceRangePattern" [value]="getPace(variable, true)" placeholder="00:00(-00:00)">
                    </td>
                    <td>
                        <input min="0" class="input small form-control full-width {{isRepsValueInvalid(variable) ? 'invalid' : ''}}" [disabled]="!variable.repsAvailableOnInstantiation" (input)="setReps(variable, $event.target.value)" type="text" [pattern]="rangePattern" [value]="getReps(variable)" placeholder="00(-00)">
                    </td>
                    <td *ngIf="trainingVariableEditorDialogType == TrainingVariableEditorDialogType.plan || trainingVariableEditorDialogType == TrainingVariableEditorDialogType.template">
                        <i class="bi bi-arrow-repeat link" (click)="onSynchronizeVariable(variable)" matTooltip="{{trainingVariableEditorDialogType == TrainingVariableEditorDialogType.plan ? 'Übernimmt die aktuell definierten Trainingsvariablen-Werte des Coachees' : 'Übernimmt die aktuell global definierten Trainingsvariablen-Werte.'}}"></i>
                    </td>
                    <td>
                        <i class="bi bi-x-lg link" *ngIf="trainingVariableEditorDialogType != TrainingVariableEditorDialogType.global || variable.isNew" (click)="removeVariable(variable)"></i>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="spacer-10"></div>
        <div class="icon-button" [matMenuTriggerFor]="menu">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
            </svg>
            Variable hinzufügen
        </div>
        <mat-menu #menu="matMenu">
            <div class="available-variables-area">
                <button mat-menu-item (click)="onCreateNewVariable()">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                    </svg>
                    Neue Variable anlegen
                </button>
                <mat-divider></mat-divider>
                <ng-container *ngFor="let variable of allAvailableForeignVariables">
                    <button *ngIf="isVariableSelectable(variable)" mat-menu-item (click)="onAddAvailableVariable(variable)">
                        {{variable.name}}
                    </button>
                </ng-container>
            </div>
        </mat-menu>
    </div>
    <ng-template #cardioGroups>
        <ng-container *ngIf="trainingVariableEditorDialogType == TrainingVariableEditorDialogType.global; else userCardioZones;">
            <app-global-cardio-zone-editor [zones]="globalCardioZones"></app-global-cardio-zone-editor>
        </ng-container>
        <ng-template #userCardioZones>
            <app-cardio-zone-groups-editor [cardioZoneGroups]="cardioZoneGroups" [globalCardioZones]="globalCardioZones" [dialogType]="trainingVariableEditorDialogType" [user]="user"></app-cardio-zone-groups-editor>
        </ng-template>
    </ng-template>
</div>
<ngx-spinner name="trainingVariablSpinner" bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>