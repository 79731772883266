<div class="dialog-topbar">
    <h3 class="float-left">{{title}}</h3>
    <div class="icon-button float-right" (click)="onCloseDialog()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
    </div>
</div>
<div class="d-inline-block wrapper-selection">
    <div class="container">
        <div class="row">
            <div class="col-12 col-sm-6">
                <div class="layout-box link" (click)="selectedItem(false)">
                    <div class="row h3">
                        Wiederholender Plan
                        <div class="icon-button marginleft-10" tooltip="Planung mit fortlaufenden Einheiten (wie bisher).">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                            </svg>
                        </div>
                    </div>
                    <div class="row">
                        <img draggable="false" class="selection-image" src="assets/images/ongoing_plan_graphic.png">
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="layout-box link" (click)="selectedItem(true)">
                    <div class="row h3">
                        Periodischer Plan
                        <div class="icon-button marginleft-10" tooltip="Planung mit konkreter Wochenstruktur.">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                            </svg>
                        </div>
                    </div>
                    <div class="row">
                        <img draggable="false" class="selection-image" src="assets/images/periodic_plan_graphic.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>