import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-link-creation',
  templateUrl: './link-creation.component.html',
  styleUrls: ['./link-creation.component.css']
})
export class LinkCreationComponent implements OnInit {

  public message: string
  public title: string
  public linkName: string
  public linkUrl: string

  constructor(public dialogRef: MatDialogRef<LinkCreationComponent>, @Inject(MAT_DIALOG_DATA) private data: {message: string, title: string, linkName: string, linkUrl: string}) {
    this.message = data.message
    this.title = data.title
    this.linkName = data.linkName
    this.linkUrl = data.linkUrl
  }

  ngOnInit(): void {
  }

  onCancelDialog() {
    this.dialogRef.close(null);
  }
  onConfirmDialog() {
    this.dialogRef.close({linkName: this.linkName, linkUrl: this.linkUrl});
  }

}
