export class NutritionalSummary {
    calories: number;
    carbohydrates: number;
    protein: number;
    fat: number;

    sugar: number;
    saturatedFat: number;
    fibre: number;
    salt: number;

    
    constructor();
    constructor(init: NutritionalSummary);
    constructor(init?: NutritionalSummary) {
        this.carbohydrates = init && init.carbohydrates || 0;
        this.protein = init && init.protein || 0;
        this.fat = init && init.fat || 0;
        this.calories = init && init.calories || 0;
        this.sugar = init && init.sugar || 0;
        this.saturatedFat = init && init.saturatedFat || 0;
        this.fibre = init && init.fibre || 0;
        this.salt = init && init.salt || 0;

    }

    isValid(caloriesRequired: boolean = true): boolean {
        return this.carbohydrates != undefined && this.protein != undefined && this.fat != undefined && (this.calories != undefined || !caloriesRequired) && this.sugar != undefined;
    }

    clone(): NutritionalSummary {
        var nutritionalSummary = new NutritionalSummary()
        nutritionalSummary.calories = this.calories
        nutritionalSummary.carbohydrates = this.carbohydrates
        nutritionalSummary.protein = this.protein
        nutritionalSummary.fat = this.fat
        nutritionalSummary.sugar = this.sugar
        nutritionalSummary.saturatedFat = this.saturatedFat;
        nutritionalSummary.fibre = this.fibre;
        nutritionalSummary.salt = this.salt;
        return nutritionalSummary
    }
}