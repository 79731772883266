<div class="row">
  <div class="col-md-12">
    <form>
      <input [(ngModel)]="mail" [ngModelOptions]="{standalone: true}" type="text" class="form-control mb-2" placeholder="Email" required autofocus/>
      <input [(ngModel)]=password [ngModelOptions]="{standalone: true}" type="password" class="form-control mb-2" placeholder="Password" required/>
      <button class="btn btn-lg btn-primary btn-block mb-1 button-filled float-right" type="submit" (click)="login()">Login</button>
      <br><br>
      <button class="btn button-filled light small" routerLink="/forgot-password">Passwort zurücksetzen</button>
    </form>
  </div>
</div>