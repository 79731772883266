export { }

declare global {
    interface Date {
        addDays(days: number): Date;
        isToday(): boolean;
        getDayNumber(): number;
        getWeekNumber(): number;
        getWeekOfYearNumber(): number;
        getYearOfWeekOfYearNumber(): number;
        clone(): Date;
        getStartOfWeek(): Date;
        getWithoutTime(): Date;
        isSameDate(date: Date): boolean;
        isSameOrBeforeDate(date: Date): boolean;
        isSameOrAfterDate(date: Date): boolean;
        asFormatedString(): string;
        asShortFormatedString(): string;
        asTimeFormatedString(): string;
        asDateTimeFormatedString(): string;
        getPrintableMonth(): string;
        getPrintableWeekday(): string;
        getPrintableWeekdayShort(): string;
        asEncodedString(): string;
        getMonthSinceYearZero(): number;
    }
    interface Number {
        roundToInt(): number;
        roundToPlaces(places: number): number;
        roundToBestPlaces(): number;
        asDurationString(): string;
        asDurationStringWithOptionalHours(): string;
        asAutomaticShortDurationString(): string;
    }
    interface File {
        isImage(): boolean;
        getFileName(): string
        getExtension(): string
    }
    // interface String {
    //     replaceAll(find: string, replaceString: string): string
    // }
}

Date.prototype.getWeekNumber = function () {
    return Math.floor((this.getTime() + 1000 * 60 * 60 * 24 * 4) / (1000 * 60 * 60 * 24 * 7));
};
Date.prototype.getDayNumber = function () {
    if (this.getDay() == 0) return 6
    return this.getDay() - 1
};
Date.prototype.getPrintableWeekday = function () {
    return ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'][this.getDay()]
};
Date.prototype.getPrintableWeekdayShort = function () {
    return this.getPrintableWeekday().substring(0, 2)
};
Date.prototype.getWeekOfYearNumber = function () {
    var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
    var dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1) / 7)
};
Date.prototype.getYearOfWeekOfYearNumber = function () {
    var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
    var dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return yearStart.getFullYear()
};
Date.prototype.isSameDate = function (date: Date): boolean {
    return date && this.getFullYear() === date.getFullYear() && this.getMonth() === date.getMonth() && this.getDate() === date.getDate();
};
Date.prototype.isSameOrBeforeDate = function (date: Date): boolean {
    return date && this.isSameDate(date) || this < date;
};
Date.prototype.isSameOrAfterDate = function (date: Date): boolean {
    return date && this.isSameDate(date) || this > date;
};
Date.prototype.isToday = function (): boolean {
    const date = new Date()
    return date && this.getFullYear() === date.getFullYear() && this.getMonth() === date.getMonth() && this.getDate() === date.getDate();
};
Date.prototype.clone = function (): Date {
    return new Date(+this);
};
Date.prototype.getWithoutTime = function (): Date {
    return new Date(
        this.getFullYear(),
        this.getMonth(),
        this.getDate()
    );
};
Date.prototype.getStartOfWeek = function (): Date {
    var endDate = this.getWithoutTime()
    endDate.addDays(-endDate.getDayNumber())
    return endDate
};
Date.prototype.addDays = function (days: number): Date {
    if (!days) return this;
    let date = this;
    date.setDate(date.getDate() + days);
    return date;
};
Date.prototype.asFormatedString = function (): string {
    return this.getDate() + '.' + (this.getMonth() + 1) + '.' + this.getFullYear();
};
Date.prototype.asShortFormatedString = function (): string {
    return (this.getDate() < 10 ? '0' : '') + this.getDate() + '.' + (this.getMonth() < 9 ? '0' : '') + (this.getMonth() + 1) + '.';
};

Date.prototype.asTimeFormatedString = function (): string {
    return this.toTimeString().slice(0, 5);
}
Date.prototype.asDateTimeFormatedString = function (): string {
    return this.getDate() + '.' + (this.getMonth() + 1) + '.' + this.getFullYear() + ' ' + this.toTimeString().slice(0, 5);
}
Date.prototype.asEncodedString = function (): string {
    return this.getFullYear() + '-' + (this.getMonth() < 9 ? '0' : '') + (this.getMonth() + 1) + '-' + (this.getDate() < 10 ? '0' : '') + this.getDate() + ' ' + this.getHours() + '-' + this.getMinutes() + '-' + this.getSeconds();
};

Date.prototype.getPrintableMonth = function (): string {
    return ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'][this.getMonth()]
};

Date.prototype.getMonthSinceYearZero = function (): number {
    return this.getFullYear() * 12 + this.getMonth();
}

Number.prototype.roundToInt = function (): number {
    return Math.round(this)
};
Number.prototype.roundToPlaces = function (places: number): number {
    return Math.round(this * Math.pow(10, places)) / Math.pow(10, places)
};

Number.prototype.roundToBestPlaces = function (): number {
    if (this == 0 || this == null) return 0;
    if (this >= 1) {
        return this.roundToPlaces(2)
    }
    else if (this >= 0.1) {
        return this.roundToPlaces(3);
    }
    else if (this >= 0.01) {
        return this.roundToPlaces(4);
    }
    else {
        return this.roundToPlaces(5);
    }
};

Number.prototype.asDurationStringWithOptionalHours = function (): string {
    var hours = Math.floor(this / 3600);
    var minutes = Math.floor((this - (hours * 3600)) / 60);
    var seconds = this - (hours * 3600) - (minutes * 60);

    var outputHours = hours.toString();
    var outputMinutes = minutes.toString();
    var outputSeconds = seconds.toString();

    if (minutes < 10) { outputMinutes = "0" + minutes; }
    if (seconds < 10) { outputSeconds = "0" + seconds; }
    if (hours == 0) {
        return outputMinutes + ':' + outputSeconds;
    }
    if (hours < 10) { outputHours = "0" + hours; }
    return outputHours + ':' + outputMinutes + ':' + outputSeconds;
}

Number.prototype.asDurationString = function (): string {
    var hours = Math.floor(this / 3600);
    var minutes = Math.floor((this - (hours * 3600)) / 60);
    var seconds = this - (hours * 3600) - (minutes * 60);

    var outputHours = hours.toString();
    var outputMinutes = minutes.toString();
    var outputSeconds = seconds.toString();


    if (hours < 10) { outputHours = "0" + hours; }
    if (minutes < 10) { outputMinutes = "0" + minutes; }
    if (seconds < 10) { outputSeconds = "0" + seconds; }
    return outputHours + ':' + outputMinutes + ':' + outputSeconds;
};

Number.prototype.asAutomaticShortDurationString = function (): string {
    var hours = Math.floor(this / 3600);
    var minutes = Math.floor((this - (hours * 3600)) / 60);
    var seconds = this - (hours * 3600) - (minutes * 60);

    var outputHours = hours.toString();
    var outputMinutes = minutes.toString();
    var outputSeconds = seconds.toString();


    if (hours < 10) { outputHours = "0" + hours; }
    if (minutes < 10) { outputMinutes = "0" + minutes; }
    if (seconds < 10) { outputSeconds = "0" + seconds; }
    if (hours == 0) {
        return outputMinutes + ':' + outputSeconds + ' min';
    }
    return outputHours + ':' + outputMinutes + ':' + outputSeconds + ' h';
};

File.prototype.isImage = function (): boolean {
    return this.name.toLowerCase().endsWith('.png') || this.name.toLowerCase().endsWith('.jpg') || this.name.toLowerCase().endsWith('.jpeg')
};
File.prototype.getFileName = function (): string {
    return this.name.substring(0, this.name.lastIndexOf('.'))
};
File.prototype.getExtension = function (): string {
    return this.name.substring(this.name.lastIndexOf('.'), this.name.length)
};

// String.prototype.replaceAll = function (find: string, replaceString: string): string {
//     return this?.replace(new RegExp(find, 'g'), replaceString);
// }
