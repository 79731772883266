import { UtilityService } from './../../services/utility.service';
import { EventLogService } from './../../services/event-log.service';
import { AssignedQuestionaire, QuestionaireResult } from 'src/app/model/questionaires.model';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA,MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MetricData } from 'src/app/model/metricdata.model';
import { MetricDataImageDialogComponent } from 'src/app/metric-data-image-dialog/metric-data-image-dialog.component';
import { SelectionDialogComponent } from 'src/app/selection-dialog/selection-dialog.component';
import { FirestoreService } from 'src/app/services/firestore.service';
import { User } from 'src/app/model/user.model';
import { QuestionairesService } from 'src/app/services/questionaires.service';
import { ToastrService } from 'ngx-toastr';
import { LanguageService } from 'src/app/services/language.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-completed-questionaire-results-dialog',
  templateUrl: './completed-questionaire-results-dialog.component.html',
  styleUrls: ['./completed-questionaire-results-dialog.component.css']
})
export class CompletedQuestionaireResultsDialogComponent implements OnInit {

  public EventLogService = EventLogService;
  questionaireResults: QuestionaireResult[]  = [];
  user: User;
  public hasFeedbackChanges: boolean = false

  private hasSavedChanges: boolean = false;

  private _allAvailableQuestionaireResults: QuestionaireResult[] = [];


  constructor(public dialogRef: MatDialogRef<CompletedQuestionaireResultsDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {selectedQuestionaireResults: QuestionaireResult[], allAvailableQuestionaireResults: QuestionaireResult[], user: User, editMode: boolean}, private dialog: MatDialog, public utilityService: UtilityService, public userService: FirestoreService, private questionaireService: QuestionairesService, private toastr: ToastrService, private languageService: LanguageService, private spinner: NgxSpinnerService) {
    this.init(data);
  }

  async init(data: any){
    let questionaireResults = [];
    this.user = data.user;
    data.selectedQuestionaireResults.forEach(async x => {
      questionaireResults.push(x);
    });
    await this.loadMediaLinks(questionaireResults)
    this.questionaireResults = questionaireResults;
    this._allAvailableQuestionaireResults = data.allAvailableQuestionaireResults?.filter(x => x.assignedQuestionaire?.completed) || [];
  }

  async loadMediaLinks(questionaireResults: QuestionaireResult[]) {
    try{
      this.spinner.show();
      for (var questionaireResult of questionaireResults) {
        var metricDatas = questionaireResult.metricData
        for (var metricData of metricDatas) {
          if (metricData.metric !== null) {
            if (metricData.metric.isMetricTypeImage() || metricData.metric.isMetricTypeVideo()) {
              if (!metricData.mediaLink) {
                metricData.mediaLink = await this.userService.getMetricDataMediaLink(metricData, this.user.uid)
              }
            }
          }
        }
        if (questionaireResult.assignedQuestionaire?.viewedByCoach == false) {
          await this.questionaireService.markAsViewed(questionaireResult.assignedQuestionaire, this.user)
        }
      }
    }
    catch(ex){
      console.error(ex);
    }
    finally{
      this.spinner.hide();
    }
  }

  ngOnInit(): void {
  }

  onOpenMetricDataImage(metricData: MetricData){
    const dialogRef = this.dialog.open(MetricDataImageDialogComponent, { data: { imageURL: metricData.mediaLink}});
  }

  addComparisonQuestionaireResult(){
    const dialogRef = this.dialog.open(SelectionDialogComponent, { data: { selectableItems: this._allAvailableQuestionaireResults.filter(x => this.questionaireResults.find(y => x.questionaireId == y.questionaireId) == null), relevantProperties: ['assignedQuestionaire.name', 'assignedQuestionaire.completionDate']}, width: '1000px'});
    dialogRef.afterClosed().subscribe(async result => {
      if (result){
        await this.loadMediaLinks([result.selectedItem])
        this.questionaireResults.push(result.selectedItem);
      }
    });
  }

  closeQuestionaire(questionaireResult: QuestionaireResult){
    if(this.questionaireResults.length > 1){
      this.questionaireResults = this.questionaireResults.filter(x => x.questionaireId != questionaireResult.questionaireId);
    }
  }

  

  questionaireResultChangesSaved(editedQuestionaireResult: QuestionaireResult){
    this.questionaireResults = this.questionaireResults.map(x => x.assignedQuestionaire.id == editedQuestionaireResult.assignedQuestionaire.id ? editedQuestionaireResult : x);
    this.hasSavedChanges = true;
  }

  onCloseDialog(){
    this.dialogRef.close({hasSavedChanges: this.hasSavedChanges});
  }
}
