<div class="dialog-topbar">
    <div class="icon-button display-inlineblock float-left" (click)="addComparisonTrackedSession()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
        </svg>
        Einheit zum Vergleichen hinzufügen
    </div>
    <div class="icon-button float-right" (click)="onCloseDialog()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        Schließen
    </div>
</div>
<div class="dialog-content-wrapper row flex-nowrap">
    <app-tracked-training-session [user]="user" [questionaireResults]="questionaireResults" [(TrainingSessionContainerList)]="trainingSessionContainerList"></app-tracked-training-session>
</div>