<div class="page-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-9 col-lg-9 col-xl-8 col-xxl-4 ">
                <h2 class="">Einstellungen</h2>
            </div>
            <div class="col-2">
                <div class="icon-button" (click)="onShowTeamManagement()">
                    Trainerverwaltung
                </div>
            </div>
        </div>

        <div class="row" *ngIf="!showTeamManagement">
            <div class="col-6">
                <div class="layout-box">
                    <h3>Abonnement</h3>
                    <div class="spacer-10"></div>
                    <div *ngIf="user.licenceHolder?.onTrial && !subscription" class="layout-box sub-box">
                        <div class="bold">Du testest TRAINDOO gerade für zwei Wochen. 💪</div>
                        <div class="spacer-10"></div>
                        Deine Testphase endet am {{user.licenceHolder?.trialEndDate | date:'dd.MM.yyyy'}}.
                    </div>

                    <div class="spacer-10"></div>
                    <div *ngIf="!stripeCustomerPortalLink" class="loading-animation">
                        <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
                        <div class="textalign-center label"><br>Dies kann einen Moment dauern...</div>
                    </div>
                    <a *ngIf="stripeCheckoutSessionLink && !subscription" href="{{stripeCheckoutSessionLink}}" target="_blank" class="display-inlineblock">
                        <div class="layout-box sub-box textalign-center create-subscription">
                            <img draggable="false" class="logo" src="assets/traindoo/Traindoo Full Logo.png">
                            <div class="spacer-25"></div>
                            Erstelle dein TRAINDOO-Abonnement:
                            <div class="spacer-25"></div>
                            <div class="label color-black">
                                Es gelten unsere <a href="https://www.traindoo.io/legal/agb" target="_blank">Allgemeinen Geschäftsbedingungen.</a>
                            </div>
                            <div class="spacer-25"></div>
                            <button class="button-filled small">
                                Abonnement erstellen
                            </button>
                        </div>
                    </a>
                    <div *ngIf="subscription" class="layout-box sub-box create-subscription display-inlineblock textalign-center">
                        <img draggable="false" class="logo" src="assets/traindoo/Traindoo Full Logo.png">
                        <div class="spacer-25"></div>
                        <h3>Du hast ein aktives TRAINDOO-Abonnement!</h3>
                        <div class="spacer-10"></div>
                        <a *ngIf="stripeCustomerPortalLink && subscription" href="{{stripeCustomerPortalLink}}" target="_blank" class="display-inlineblock">
                            <button class="button-filled small">
                                Abonnement verwalten
                            </button>
                        </a>
                    </div>
                    
                </div>
            </div>
            <div class="col-6">
                <div *ngIf="coach" class="layout-box">
                    <h3>Coach</h3>
                    <div class="spacer-10"></div>
                    <div class="label">Name</div>
                    <div class="spacer-10"></div>
                    <input [(ngModel)]="coach.name" class="input small wide {{coach.name.length > 0 ? '' : 'invalid'}}" type="text" placeholder="Name"/>
                    <div class="spacer-50"></div>

                    <h3>Team</h3>
                    <div class="spacer-10"></div>
                    <div class="label">Eigener Auftragsverarbeitungsvertrag mit Athleten</div>
                    <div class="spacer-10"></div>
                    <textarea id="input-description" class="input full-width" [ngModel]="customDataProcessingAgreement" (input)="onCustomDataProcessingAgreementChanged($event.target.value)"  type="text" rows="4"></textarea>
                    <div class="spacer-10"></div>
                    <div class="icon-button" *ngIf="customDataProcessingAgreementChanged" (click)="onSaveDataProcessingAgreement()">
                        Speichern
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="layout-box">
                    <h3>App</h3>
                    <div class="spacer-10"></div>

                    <div class="label">
                        Einheiten-System
                    </div>
                    <div class="spacer-10"></div>
                    <div class="d-inline-block wrapper-selection">
                        <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{languageService.getPrintableUnitSystem()}}</button>
                        <div class="dropdown-menu" aria-labelledby="dropdownExertionParameter">
                            <button class="dropdown-item" type="button" (click)="languageService.onSelectUnitSystem('metric')">Metrisch</button>
                            <button class="dropdown-item" type="button" (click)="languageService.onSelectUnitSystem('imperial')">Imperial</button>
                        </div>
                    </div>
                    <div class="spacer-10"></div>

                    <div class=" toggle-wrapper mobile-marginbottom-10 display-block">
                        <div class="toggle {{isNutritionEnabled ? 'on' : ''}}" (click)="onNutritionEnabledChanged(!isNutritionEnabled)">
                            <div class="handle"></div>
                        </div>
                        <div class="text">
                            Ernährung standardmäßig bei Kunden aktivieren
                        </div>
                    </div>
                    <div class="spacer-25"></div>

                    <div class=" toggle-wrapper mobile-marginbottom-10 display-block">
                        <div class="toggle {{isDarkModeEnabled ? 'on' : ''}}" (click)="onDarkModeEnabledChanged(!isDarkModeEnabled)">
                            <div class="handle"></div>
                        </div>
                        <div class="text">
                            Darkmode aktivieren
                        </div>
                    </div>
                    <div class="spacer-10" *ngIf="darkModeSettingChanged"></div>
                    <div class="label display-block" *ngIf="darkModeSettingChanged">
                        Lade die Seite neu, um die Änderungen zu übernehmen.
                    </div>
                    <div class="spacer-25"></div>

                    <div class="recipesharing-toggle toggle-wrapper">
                        <div class="toggle {{coach?.coacheeVoiceMessageEnabled ? 'on' : ''}}" (click)="onVoiceMessagesEnabledChanged()">
                            <div class="handle"></div>
                        </div>
                        <div class="text">
                            Versand von Sprachnachrichten durch Coachees erlauben
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="layout-box">
                    <h3>Benachrichtigungen</h3>
                    <div class="spacer-10"></div>
                    <app-coach-notifications></app-coach-notifications>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="showTeamManagement">
            <div class="col-12">
                <app-team-management></app-team-management>
            </div>
        </div>
    </div>
</div>