import { ProductPurchase } from "./product-purchase.model";
import { User } from "./user.model";

export class Licence {
    lid: string;
    coachUid: string;
    userUid: string;
    issued: boolean;
    active: boolean;
    isTrialLicence: boolean
    deactivationDate: Date;
    activationDate: Date;
    plannedActivationDate: Date;
    licenceHolderUid: string;
    licenceType: string;
    redeemDate: Date;
    expirationDate: Date;
    presetName: string
    email: string
    user: User
    onboardingQuestionaireIds: string[]
    assignedMetricIds: string[]
    assignedClientGroups: string[]
    hideNutritionValues: boolean
    hideChat: boolean
    productPurchaseId: string
    deleted: boolean

    // Tmp:
    productPurchase: ProductPurchase

    constructor();
    constructor(init: Licence);
    constructor(init?: Licence) {
        this.lid = init && init.lid || null
        this.coachUid = init && init.coachUid || null
        this.userUid = init && init.userUid || null
        this.licenceHolderUid = init && init.licenceHolderUid || this.coachUid
        this.licenceType = init && init.licenceType || null
        this.presetName = init && init.presetName || null
        this.email = init && init.email || null
        this.issued = init && init.issued || false
        this.active = init && init.active || false
        this.isTrialLicence = init && init.isTrialLicence || false
        this.deactivationDate = init && init.deactivationDate ? new Date((init as any).deactivationDate.seconds * 1000) : null
        this.activationDate = init && init.activationDate ? new Date((init as any).activationDate.seconds * 1000) : null
        this.redeemDate = init && init.redeemDate || null
        this.expirationDate = init && init.expirationDate || null
        this.plannedActivationDate = init && init.plannedActivationDate || null
        this.user = init && init.user || null
        this.onboardingQuestionaireIds = init && init.onboardingQuestionaireIds || []
        if (init && (init as any).onboardingQuestionaireId) {
            var id = (init as any).onboardingQuestionaireId
            if (id && !this.onboardingQuestionaireIds.includes(id)) this.onboardingQuestionaireIds.push(id)
        }
        this.assignedMetricIds = init && init.assignedMetricIds || []
        this.assignedClientGroups = init && init.assignedClientGroups || []
        this.productPurchaseId = init && init.productPurchaseId || null
        this.hideNutritionValues = init && init.hideNutritionValues || false
        this.hideChat = init && init.hideChat || false
        this.deleted = init && init.deleted || false
    }
}