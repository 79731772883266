<div class="dialog-topbar">
    <h3>{{title}}</h3>
</div>
<div *ngFor="let setting of availableSettings">
    <div class="recipe-sharing-settings">
        <div class="recipesharing-toggle toggle-wrapper display-inlineblock">
            <div class="toggle {{selectedSettings.includes(setting) ? 'on' : ''}}" (click)="onSelectSetting(setting)">
                <div class="handle"></div>
            </div>
            <div class="text">
                {{setting}}
            </div>
        </div>
    </div>
</div>

<mat-dialog-actions>
    <div class="actions">
        <button class="button-filled small light float-right marginright-25" [disabled]="!canSubmit()" (click)="onSubmit()">{{submitButtonText}}</button>
        <button class="button-filled small light float-right" (click)="onCancel()">{{cancelButtonText}}</button>
    </div>
</mat-dialog-actions>