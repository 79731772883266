import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FirestoreService } from 'src/app/services/firestore.service';
import { LanguageService } from 'src/app/services/language.service';
import { UtilityService } from 'src/app/services/utility.service';
import { WeightConversionPipe } from 'src/app/weight.pipe';

@Component({
  selector: 'app-rpe-table-dialog',
  templateUrl: './rpe-table-dialog.component.html',
  styleUrls: ['./rpe-table-dialog.component.css']
})
export class RpeTableDialogComponent {

  static repList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  static rpeList = [10, 9.5, 9, 8.5, 8, 7.5, 7, 6.5, 6, 5.5]

  rpeList = RpeTableDialogComponent.rpeList
  repList = RpeTableDialogComponent.repList

  weight: number = 100
  reps: number = 1
  rpe: number = 10
  fixedOneRM: number

  strgPressed: boolean = false

  constructor(public dialogRef: MatDialogRef<RpeTableDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { weight: number, reps: number, rpe: number, fixedOneRM: number }, public userService: FirestoreService, public utilityService: UtilityService, private toastr: ToastrService, public languageService: LanguageService, private weightPipe: WeightConversionPipe) {
    if (data.weight != null) this.weight = data.weight
    if (data.reps != null) this.reps = data.reps
    if (data.rpe != null) this.rpe = data.rpe
    if (data.fixedOneRM != null) this.fixedOneRM = data.fixedOneRM
  }

  get oneRM() {
    if (this.fixedOneRM) return this.fixedOneRM
    // (REPS + 10 - RPE) * 0.033 * LOAD + LOAD = 1RM
    return ((this.weight * (this.reps + 10 - this.rpe) * 0.0333) + this.weight)
  }
  getPrintableOneRm() {
    return this.weightPipe.transform(this.oneRM, this.languageService.selectedUnitSystem, true)
  }

  @HostListener('window:keydown', ['$event'])
  async onKeyDown(event: KeyboardEvent) {
      if ((event.metaKey || event.ctrlKey)) {
          event.preventDefault();
          this.strgPressed = true
      }
  }

  @HostListener('document:keyup', ['$event'])
  async onKeyUp(event: KeyboardEvent) {
      this.strgPressed = false
  }

  getWeight(rpe: number, reps: number) {
    var reps = reps + 10 - rpe
    var weight = this.oneRM / (reps * 0.0333 + 1)
    return weight
  }
  getPercentage(rpe: number, reps: number) {
    return (this.getWeight(rpe, reps) / this.oneRM) * 100
  }

  onClickValue(rpe: number, reps: number) {
    if (this.strgPressed) {
      this.dialogRef.close({ weight: parseFloat(this.getWeight(rpe, reps).toFixed(1)), reps: reps, rpe: rpe })
    } else {
      this.toastr.success("In Zwischenablage kopiert", "",  {
        positionClass: 'toast-bottom-center'
      })
    }
  }

  onCloseDialog() {
    this.dialogRef.close()
  }
}
