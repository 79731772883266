
<div class="dialog-topbar">
    <div *ngIf="!isDailyActivity" class="icon-button" (click)="onSaveActivity()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
        <ng-container *ngIf="!utilityService?.onSmallDisplay()">
            Speichern
        </ng-container>
    </div>
    <div class="icon-button float-right" (click)="onCloseActivity()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        <ng-container *ngIf="!utilityService?.onSmallDisplay()">
            Schließen
        </ng-container>
    </div>
    <div *ngIf="!isDailyActivity" class="icon-button float-right mr-4" (click)="onDeleteActivity()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash padding-4" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
        <ng-container *ngIf="!utilityService?.onSmallDisplay()">
            Löschen
        </ng-container>
    </div>
</div>
<div class="dialog-content-wrapper" id="meal-editor">
    <h3>Aktivität erstellen</h3>
    <div *ngIf="!isDailyActivity" class="row">
        <div class="col-md-6 col-sm-12">
            <div class="spacer-10"></div>
            <label for="input-time">Uhrzeit</label>
            <input id="input-time" class="input form-control wide-input" [ngModel]="getTimeOfDate()" (input)="setTimeOfDate($event.target.value)" type="time">
        </div>
    </div>
    <div *ngIf="isDailyActivity" class="row">
        <div class="col-md-6 col-sm-12">
            <div class="label">Diese Aktivität wird automatisch beim Datenimport von Health-Apps erstellt und kann deshalb nicht bearbeitet werden.</div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-sm-12">
            <div class="spacer-10"></div>
            <label for="activity-select">Aktivität</label>
            <input [disabled]="isDailyActivity" (focus)="onActivityFactSearchFocused()" type="text" class="wide-input" [ngModel]="getSelectedActivityFact()" (input)="onActivitySearchInput($event.target.value)">
            <div id="activityfactsearch-results" class="layout-box sub-box" *ngIf="filteredActivitySearchResults?.length > 0 && activityFactSearchFocused">
                <div *ngFor="let activity of filteredActivitySearchResults" class="search-result-item link" (click)="setSelectedActivityFact(activity)">
                    <div class="display-block">
                        {{activity.nameTranslation?.GetValue(languageService.selectedLanguageCode)}}
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="selectedActivityFact?.id == customActivityFactId" class="col-md-6 col-sm-12">
            <div class="spacer-10"></div>
            <label for="input-name">Name</label>
            <input [disabled]="isDailyActivity" id="input-name" maxlength="70" class="input activity-name form-control wide-input" [(ngModel)]="activity.name" placeholder="Name" type="text">
        </div>
    </div>
    <div class="spacer-10"></div>
    <div class="row">
        <div class="col-md-6 col-sm-12">
            <div class="row">
                <div *ngIf="!isDailyActivity" class="col-md-6 col-sm-12">
                    <div class="spacer-10"></div>
                    <label class="w-100" for="input-name">Dauer</label>
                    <input [disabled]="isDailyActivity" min="0" max="99999" step="1" class="input small form-control number-input d-inline-block" [ngModel]="activity.duration" (input)="setDurationNumber($event.target.value)" (click)="$event.stopPropagation()" type="number">
                    min
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="spacer-10"></div>
                    <label class="w-100" for="input-name">Verbrannte Kalorien</label>
                    <input [disabled]="isDailyActivity" min="0" max="99999" step="1" class="input small form-control number-input d-inline-block" [ngModel]="activity.caloriesBurned" (input)="setBurdnedCalories($event.target.value)" [placeholder]="getCalculatedCalories()" (click)="$event.stopPropagation()" type="number">
                    kcal
                </div>
            </div>
        </div>
    </div>
</div>