<div class="dialog-topbar">
    <div class="icon-button float-right" (click)="onCloseDialog()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        Schließen
    </div>
</div>
<div class="dialog-content-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="spacer-10"></div>
            <input id="templatesearch-input" class="input wide" (input)="onSessionTemplateSearchInputChanged($event.target.value)" placeholder="Vorlagen durchsuchen" type="text">
            <div class="icon-button deletesearch-icon delete-recipesearch" (click)="onDeleteSessionTemplateSearchInput()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </div>
            <div class="spacer-25"></div>
        </div>
    </div>
    <div *ngIf="trainingService.trainingPlanTemplates?.length > 0" class="row">
        <div *ngFor="let template of filteredTrainingPlanTemplates" class="col-12">
            <ng-container *ngIf="template.getAvailableSessionsWithoutRestDays()?.length > 0 && ((selectedSessionTemplateTab === 'OWN' && template.licenceHolderUid !== 'nutrilize') || (selectedSessionTemplateTab === 'NUTRILIZE' && template.licenceHolderUid === 'nutrilize'))">
                <b>{{template.nameTranslation.GetValue(languageService.selectedLanguageCode)}}</b>
                <div class="label" *ngIf="template.subNameTranslation.GetValue(languageService.selectedLanguageCode)">{{template.nameTranslation.GetValue(languageService.selectedLanguageCode)}}</div>
                <div class="layout-box mt-2" *ngIf="!template.isPeriodicPlan; else periodicTemplate">
                    <ng-container *ngFor="let session of template.sessions">
                        <div class="row layout-box sub-box link" *ngIf="!session.isRestDay && !session.deleted" (click)="selectSessionTemplate(session, template)">
                            <div class="checkbox-wrapper">
                                <input class="input checkbox" type="checkbox" [checked]="selectedSessions.includes(session)">
                            </div>
                            <div class="col">
                                {{session.name}}
                            </div>
                        </div>
                    </ng-container>
                </div>
                <ng-template #periodicTemplate>
                    <ng-container *ngFor="let week of template.weeks; let i = index;">
                    <div class="layout-box mt-2" *ngIf="template.getSessionsByWeekId(week.id)?.length > 0">
                            <div class="label link" (click)="toggleMenu(week)">
                                <ng-container *ngIf="week == expandedWeek; else settingsCollapsed">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                    </svg>
                                </ng-container>
                                <ng-template #settingsCollapsed>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                                      </svg>
                                </ng-template>
                                Woche {{i+1}}
                            </div>
                            <ng-container *ngIf="week == expandedWeek">
                                <ng-container *ngFor="let session of template.getSessionsByWeekId(week.id)">
                                    <div class="row layout-box sub-box link" *ngIf="!session.isRestDay && !session.deleted" (click)="selectSessionTemplate(session, template)">
                                        <div class="checkbox-wrapper">
                                            <input class="input checkbox" type="checkbox" [checked]="selectedSessions.includes(session)">
                                        </div>
                                        <div class="col">
                                            {{session.name}}
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-template>
            </ng-container>
        </div>
    </div>
    <div class="spacer-50"></div>
</div>
<div class="dialog-bottombar" mat-dialog-actions>
    <div class="label col-12">
        <span *ngFor="let session of selectedSessions; let isLast=last">
            {{session.name}}{{isLast ? '' : ', '}}
        </span>
    </div>
    <button *ngIf="selectedSessions?.length > 0" class="button-filled small create-Exercise" (click)="onTakeSelection(selectedSessions)">Auswahl übernehmen</button>
</div>
<ngx-spinner name="sessionTemplateSpinner" bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>