import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../services/firestore.service';

@Component({
  selector: 'app-news-board',
  templateUrl: './news-board.component.html',
  styleUrls: ['./news-board.component.css']
})
export class NewsBoardComponent implements OnInit {

  constructor(public userService: FirestoreService) { }

  ngOnInit(): void {
    if (this.userService.getLoggedInUser().isCoach && this.userService.getLoggedInUser().portalSettingsCoach?.maxVersionNumberNewsDisplayed < this.userService.APP_VERSION_NUMBER) {
      this.userService.getLoggedInUser().portalSettingsCoach.maxVersionNumberNewsDisplayed = this.userService.APP_VERSION_NUMBER
      this.userService.updateMaxVersionNumberNewsDisplayed(this.userService.getLoggedInUser())
    }
  }

}
