import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-md-editor',
  templateUrl: './md-editor.component.html',
  styleUrls: ['./md-editor.component.css']
})
export class MdEditorComponent {
  @Input() compiled: string;
  @Input() placeHolder: string;
  @Input() readOnlyMode: boolean = false;
  @Input() inputValue: string = ""

  @Output() valueChanged = new EventEmitter<string>();
  @Output() focusChanged = new EventEmitter<boolean>();

  onValueChange(e) {
    const body = e.target.value;

    this.valueChanged.emit(body);
  }

  onFocus(e) {
    this.focusChanged.emit(true)
  }
  onBlur(e) {
    this.focusChanged.emit(false)
  }
}
