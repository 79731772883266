<div class="row">
    <div class="col-12">
        <div class="layout-box adjust-mobile">
            <h3 class="display-inlineblock">Übungsdatenbank</h3>
            <button class="button-filled small create-Exercise marginleft-20" (click)="onCreateExercise()">{{utilityService.onSmallDisplay() ? 'Erstellen' : 'Übung erstellen'}}</button>
            <div *ngIf="trainingService.CanEnterAdministratorEditMode" class="toggle-wrapper display-inlineblock marginleft-25 mt-1">
                <div class="toggle {{trainingService.AdministratorEditModeActivated ? 'on' : ''}}" (click)="onAdministratorModeChanged(!trainingService.AdministratorEditModeActivated)">
                    <div class="handle"></div>
                </div>
                <div class="text">
                    Administrator Modus
                </div>
            </div>
            <div class="spacer-10"></div>
            <app-exercises-table [TrainingExercises]="trainingService?.MergedTrainingExercises" (trainingExerciseSelected)="onEditExercise($event)"></app-exercises-table>
        </div>
    </div>
</div>
<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true"><div *ngIf="spinnerText" class="spinner-overlaytext" [innerHTML]="spinnerText"></div></ngx-spinner>
<!-- 
<div id="training-plan-editor" class="layout-box {{(selectedTrainingPlan ? 'show' : '')}}">
    <app-training-plan-editor [TrainingPlan]="selectedTrainingPlan" (saveChangesEvent)="saveTrainingPlan($event)" (cancelChangesEvent)="cancelEditTrainingPlan()" ></app-training-plan-editor>
</div> -->
