import { Component, OnInit } from '@angular/core';
import { Recipe } from '../model/recipe.model';
import { NutritionService } from '../services/nutrition.service';
// import * as algoliasearch from 'algoliasearch'
import { FirestoreService } from '../services/firestore.service';
import { UtilityService } from '../services/utility.service';
import * as marked from 'marked';
import { Tag } from '../model/tag.model';
import { ToastrService } from 'ngx-toastr';
import {NgxImageCompressService } from 'ngx-image-compress';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClientComponent } from '../client/client-v1/client-v1.component';
import { NutritionalValueHolder } from '../model/basenutritionfact.model';

@Component({
  selector: 'app-recipes_client',
  templateUrl: './recipes_client.component.html',
  styleUrls: ['./recipes_client.component.css']
})
export class ClientRecipesComponent implements OnInit {

  public hasRecipeDatabaseAccess: boolean = false
  public allAssignedUsersSelected: boolean = false
  // public assignedUsersDropdownItems: DropdownItem[] = []
  public filteredRecipes: Recipe[]
  public selectedRecipe: Recipe
  public defaultTags: Tag[]
  public customTagName: string = ''
  public filteredDatabaseRecipes: Recipe[]

  public showRecipeNameErrorMessage = false;
  public showIngredientNutritionFactErrorMessage = false;
  public showIngredientWeightErrorMessage = false;

  public compiledMarkdown: string;
  editorValue: string;
  placeholder =
      'Formatierung:\n\n' +

      '**fett**\n oder *kursiv*\n\n' +

      'Ungeordnete Liste:\n' +
      '- Element 1\n\n' +

      'Geordnete Liste:\n' +
      '1. Element a\n' +
      '2. Element b';

  getPopover() {
    return 'Überschrift\nText'
  }

  constructor(public nutritionService: NutritionService, private userService: FirestoreService, public utilityService: UtilityService, private toastr: ToastrService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    if (!(this.nutritionService.clientRecipes?.length > 0)) {
      this.spinner.show()
      this.nutritionService.loadClientRecipes().toPromise().then(success => {
        this.spinner.hide()
      })
    }
    this.filteredRecipes = null
    this.filteredDatabaseRecipes = null
    this.hasRecipeDatabaseAccess = this.userService.getLoggedInUser().hasRecipeDatabaseAccess
    this.compiledMarkdown = this.compileMarkdown(this.placeholder);
    this.defaultTags = Tag.getAllTags()
  }
  ngOnDestroy(): void {
    this.spinner.hide()
  }

  getLastRecipeDatabaseOpenDate() {
    return this.userService.getLoggedInUser()?.portalSettingsCoach?.lastRecipeDatabaseOpenDate
  }
  getFilteredRecipes() {
    return this.filteredRecipes || this.nutritionService.clientRecipes
  }
  getFilteredDatabaseRecipes() {
    return this.filteredDatabaseRecipes || this.nutritionService.databaseRecipes
  }

  public nutritionalValueHolder: NutritionalValueHolder
  onNutritionalValuesFocused(nutritionalValueHolder: NutritionalValueHolder) {
    this.nutritionalValueHolder = nutritionalValueHolder
  }

  onRecipeSearchInputChanged(text: string) {
    if (!text || text.length == 0) {
      this.filteredRecipes = this.nutritionService.clientRecipes
    } else {
      var tagSearch = text.includes('#') ? text.replace('#', '').toLowerCase() : null
      this.filteredRecipes = []
      this.nutritionService.clientRecipes.forEach(recipe => {
        if ((tagSearch == null && recipe.getName().toLowerCase().includes(text.toLowerCase()))) {
          this.filteredRecipes.push(recipe)
        } else if (tagSearch != null) {
          var match = false
          recipe.getComputedTags().forEach(t => {
              if (t.printableIdentifier.toLowerCase().includes(tagSearch)) match = true
          })
          if (match) this.filteredRecipes.push(recipe)
        }
      })
    }
  }
  onDeleteRecipeSearchInput() {
    try {
      (<HTMLInputElement> document.getElementById('recipesearch-input')).value = ''
      this.filteredRecipes = this.nutritionService.clientRecipes
    }
    catch(ex){
      console.error(ex)
    }
  }

  async onRecipeSelected(recipe: Recipe) {
    if (!recipe.loaded) {
      this.spinner.show()
      await this.nutritionService.loadFullRecipe(recipe)
      if (!recipe.calories) recipe.recalculateNutritionalValues()
      recipe.loaded = true
      this.spinner.hide()
    }
    this.selectedRecipe = recipe.clone()
    this.editorValue = this.selectedRecipe.getInstructions()
    this.allAssignedUsersSelected = false
    var assignedToAllUsers = false
    if (this.selectedRecipe.assignedUsers.includes("all")) {
      assignedToAllUsers = true
      this.allAssignedUsersSelected = true
    }
    this.onDeleteRecipeSearchInput();
    if (this.editorValue?.length > 0) {
      (<HTMLInputElement> document.getElementById('editor-textarea')).value = this.editorValue
    } else {
      (<HTMLInputElement> document.getElementById('editor-textarea')).value = ""
    }
  }
  
  onCloseRecipe() {
    this.selectedRecipe = null
    this.showRecipeNameErrorMessage = false;
    this.showIngredientNutritionFactErrorMessage = false;
    this.showIngredientWeightErrorMessage = false;
    this.editorValue = null;
    (<HTMLInputElement> document.getElementById('editor-textarea')).value = ""
  }
  
  compileMarkdown(value: string): string {
    return marked.parser(marked.lexer(value));
  }
}