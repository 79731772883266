export class MetadataUser {

    userName: string
    assignedClientGroups: string[]

    // Temporary:
    latestNutritionPlanEndDate: Date

    constructor();
    constructor(init: MetadataUser);
    constructor(init?: MetadataUser) {
        this.userName = init && init.userName || null
        this.assignedClientGroups = init && init.assignedClientGroups || []
        this.latestNutritionPlanEndDate = init && init.latestNutritionPlanEndDate || null
    }
}

export class Metadata {

    nutritionPlanEndDate: Date
    trainingPlanEndDate: Date

    constructor();
    constructor(init: Metadata);
    constructor(init?: Metadata) {
        this.trainingPlanEndDate = init && init.trainingPlanEndDate ? new Date((init as any).trainingPlanEndDate.seconds * 1000) : null
        this.nutritionPlanEndDate = init && init.nutritionPlanEndDate ? new Date((init as any).nutritionPlanEndDate.seconds * 1000) : null
    }
}