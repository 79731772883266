import { SetParameter, SetParameter2LanguageDictionary, TrainingPlanAccess } from './../model/training-plan.model';
import { AuthService } from './../auth/auth.service';
import { MuscleInformation, ExerciseTypeTranslation, ITrainingExercise, ITrainingExerciseOverwrite, TrainingExerciseOverwrite, MergedTrainingExercise, TrainingExercise, MuscleGroupTranslation, MuscleTranslation, EquipmentTranslation, MovementTypeMap } from './../model/training-exercise';
import { LanguageDictionary } from '../model/languagedictionary.model';
import { FirestoreService } from './firestore.service';
import { Observable, firstValueFrom } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFirestore, DocumentData } from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';
import { IndividualConfig, ToastRef, ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonFirebase, FirebaseProject, IndividualFirebase } from '../app.module';
import { combineLatest } from 'rxjs';
import { PlannedTrainingExercise, SuperSetConfig, TrainingPlan, TrainingSession, VideoRecordingRequest } from '../model/training-plan.model';
import { Injectable } from '@angular/core';
import { LanguageService } from './language.service';
import { TrainingPlanTemplateFolder } from '../model/training-plan-template-folder.model';
import { FirebaseApp } from '@angular/fire/app';
import { FirestoreNutritionPlanService } from './firestore-nutritionplan.service';
import { resolve } from 'path';
import { TrainingVariable } from '../model/training-variables.model';

@Injectable({
  providedIn: 'root'
})
export class TrainingService {
  private trainingPlanTemplateFolders: TrainingPlanTemplateFolder[] = []
  
  public rpeTableCopyResult = null  // { weight: weight, reps: reps, rpe: rpe }

  async getTrainingPlanTemplateFoldersWithDeleted() {
    if (this.trainingPlanTemplateFolders.length == 0) await this.loadTrainingPlanTemplateFolders();
    return this.trainingPlanTemplateFolders.filter(x => this.TrainingTemplateAdministratorEditModeActivated || this.TemplateUserUid == x.licenceHolderUid || x.access == TrainingPlanAccess.all || x.access == TrainingPlanAccess.coaches);
  }

  async getTrainingPlanTemplateFolders() {
    return (await this.getTrainingPlanTemplateFoldersWithDeleted()).filter(x => !x.deleted);
  }

  async saveTrainingPlanTemplateFolder(folder: TrainingPlanTemplateFolder, removeThumbnail: boolean, thumbnailImage?: File, newTemplates: TrainingPlan[] = [], removedTemplates: TrainingPlan[] = []) {
    let folderId = folder.id;
    if (folderId) await this.updateTrainingPlanTemplateFolder(folder, removeThumbnail, thumbnailImage);
    else folderId = await this.insertTrainingPlanTemplateFolder(folder, thumbnailImage);

    if (newTemplates?.length > 0) {
      for (let template of newTemplates) {
        if (!template.folderIds.includes(folderId)) {
          template.folderIds.push(folderId);
        }
      }
      await this.updateTrainingPlanTemplateFolderIds(newTemplates);
    }
    if (removedTemplates?.length > 0) {
      for (let template of removedTemplates) {
        if (template.folderIds.includes(folderId)) {
          template.folderIds = template.folderIds.filter(x => x != folderId);
        }
      }
      await this.updateTrainingPlanTemplateFolderIds(removedTemplates);
    }
    if (newTemplates?.length > 0 || removedTemplates?.length > 0) {
      this.trainingPlanTemplates = [];
    }
  }

  async updateTrainingPlanTemplateFolderIds(templates: TrainingPlan[] = []) {
    if (templates?.length == 0) return;
    for (let template of templates) {
      if (template.id == null) {
        await this.saveTrainingPlanTemplate(template);
      }
      else {
        await this.firestore.collection('TrainingPlanTemplates').doc(template.id).set({
          timestamp: new Date(),
          folderIds: template.folderIds
        }, { merge: true });
      }
    }
  }

  async updateTrainingPlanTemplateFolder(folder: TrainingPlanTemplateFolder, removeThumbnail: boolean, thumbnailImage?: File) {
    if (removeThumbnail) {
      this.removeTrainingPlanTemplateFolderThumbnail(folder);
    }
    if (thumbnailImage) {
      folder.imagePath = this.getTrainingPlanTemplateFolderThumbnailPath(folder);
      await this.uploadTrainingPlanTemplateFolderThumbnailImage(thumbnailImage, folder);
    }
    await this.firestore.collection('TrainingPlanTemplateFolders').doc(folder.id).set(this.getTrainingPlanTemplateFolderForFirebase(folder));
    this.trainingPlanTemplateFolders = []
    return folder.id;
  }

  async insertTrainingPlanTemplateFolder(folder: TrainingPlanTemplateFolder, thumbnailImage?: File) {
    var folderRef = await this.firestore.collection('TrainingPlanTemplateFolders').add(this.getTrainingPlanTemplateFolderForFirebase(folder))
    folder.id = folderRef.id
    if (thumbnailImage) {
      folder.imagePath = this.getTrainingPlanTemplateFolderThumbnailPath(folder);
      await this.uploadTrainingPlanTemplateFolderThumbnailImage(thumbnailImage, folder)
      await this.firestore.collection('TrainingPlanTemplateFolders').doc(folder.id).set({
        timestamp: new Date(),
        imagePath: folder.imagePath
      }, { merge: true });
    }
    this.trainingPlanTemplateFolders = []
    return folderRef.id;
  }

  getTrainingPlanTemplateFolderThumbnailPath(folder: TrainingPlanTemplateFolder) {
    return "training_template_folders/" + folder.id + "/" + "thumbnail_" + FirestoreNutritionPlanService.generateUniqueString() + ".png";
  }

  async uploadTrainingPlanTemplateFolderThumbnailImage(thumbnailImage: File, folder: TrainingPlanTemplateFolder) {
    await this.fireStorage.ref(folder.imagePath).put(thumbnailImage)
    await this.fireStorage.ref(folder.imagePath).getDownloadURL().toPromise().then((link) => {
      folder.imageDownloadURL = link;
    }).catch(ex => console.log(ex));
  }

  removeTrainingPlanTemplateFolderThumbnail(folder: TrainingPlanTemplateFolder) {
    folder.imagePath = null;
    folder.imageDownloadURL = null;
  }

  async loadTrainingPlanTemplateFolders() {
    const documents = await this.firestore
      .collection('TrainingPlanTemplateFolders')
      .ref
      .where('licenceHolderUid', 'in', [this.userService.getLoggedInUser().licenceHolderUid, 'nutrilize'])
      .get();
    const trainingPlanTemplateFolders: TrainingPlanTemplateFolder[] = [];
    for (let document of documents.docs) {
      const folder = new TrainingPlanTemplateFolder(document.data() as TrainingPlanTemplateFolder)
      folder.id = document.id
      if (folder.imagePath?.length > 0) {
        firstValueFrom(this.fireStorage.ref(folder.imagePath).getDownloadURL()).then((link) => {
          folder.imageDownloadURL = link;
        }).catch(ex => console.log(ex));
      }
      trainingPlanTemplateFolders.push(folder)
    }
    this.trainingPlanTemplateFolders = trainingPlanTemplateFolders?.sort((x, y) => x.name?.GetValue(this.languageService.selectedLanguageCode)?.localeCompare(y.name?.GetValue(this.languageService.selectedLanguageCode)));
  }

  getTrainingPlanTemplateFolderForFirebase(folder: TrainingPlanTemplateFolder) {
    return {
      timestamp: new Date(),
      name: folder.name.AsMap(),
      description: folder.description.AsMap(),
      imagePath: folder.imagePath,
      licenceHolderUid: folder.licenceHolderUid,
      assignedUids: folder.assignedUids,
      assignedGroupNames: folder.assignedGroupNames,
      deleted: folder.deleted,
      access: folder.access
    }
  }

  get firestore(): AngularFirestore {
    return this.mainFirebase.firestore
  }
  get fireStorage(): AngularFireStorage {
    return this.mainFirebase.storage
  }

  private mergedTrainingExercises: MergedTrainingExercise[] = [];

  private trainingExercises: TrainingExercise[] = [];
  private observableTrainingExercises: Observable<TrainingExercise[]>;

  private trainingExerciseOverwrites: TrainingExerciseOverwrite[] = [];
  private observableTrainingExerciseOverwrites: Observable<TrainingExerciseOverwrite[]>;

  constructor(public userService: FirestoreService, private authService: AuthService, private mainFirebase: IndividualFirebase, private commonFirebase: CommonFirebase, private toastrService: ToastrService, private spinner: NgxSpinnerService, private languageService: LanguageService) {
    //this.transformImportedExercises()

    this.selectableMuscles = Array.from(MuscleTranslation.values()).sort((x, y) => x.GetValue(languageService.selectedLanguageCode)?.localeCompare(y.GetValue(languageService.selectedLanguageCode)));
    this.selectableMuscleGroups = Array.from(MuscleGroupTranslation.values()).sort((x, y) => x.GetValue(languageService.selectedLanguageCode)?.localeCompare(y.GetValue(languageService.selectedLanguageCode)));;
    this.selectableEquipments = Array.from(EquipmentTranslation.values()).sort((x, y) => x.GetValue(languageService.selectedLanguageCode)?.localeCompare(y.GetValue(languageService.selectedLanguageCode)));;
    this.selectableMovementTypes = Array.from(MovementTypeMap.values()).sort((x, y) => x.GetValue(languageService.selectedLanguageCode)?.localeCompare(y.GetValue(languageService.selectedLanguageCode)));;
    this.selectableExerciseTypes = Array.from(ExerciseTypeTranslation.values()).sort((x, y) => x.GetValue(languageService.selectedLanguageCode)?.localeCompare(y.GetValue(languageService.selectedLanguageCode)));;
  }

  transformImportedExercises() {
    var trainingExercises = this.firestore.collection<any>('TrainingExercises').ref.get().then((documents => {
      var newExercixes: TrainingExercise[] = []
      documents.forEach(document => {
        try {

          if (document.id.length < 5) {
            this.firestore.collection('TrainingExercises').add({
              timestamp: new Date(),
              creatorUid: 'nutrilize',
              name: {
                'de': document.data().nameDe,
                'en': document.data().nameEn
              },
              subName: {
                'de': document.data().subNameDe,
                'en': document.data().subNameEn
              },
              instructions: {
                'de': document.data().instructionsDe,
                'en': document.data().instructionsEn ?? null
              },
              isInvertedWeight: document.data().isInvertedWeight,
              muscleGroups: document.data().muscleGroups?.split(', ').map(x => x.replace('Arme', 'arms').replace('Schulter', 'delts').replace('Bauch', 'core').replace('Rücken', 'back').replace('Oberschenkel', 'quads').replace('Unterschenkel', 'calves').replace('Brust', 'chest'))?.filter(x => x != null && x.length > 0),
              mainMuscles: document.data().mainMuscles?.split(', ')?.filter(x => x != null && x.length > 0),
              subMuscles: document.data().subMuscles?.split(', ')?.filter(x => x != null && x.length > 0),
              exerciseType: document.data().exerciseType?.split(', ')?.filter(x => x != null && x.length > 0),
              movement: document.data().movement?.split(', ').map(x => x.replace('Pull', 'pull').replace('Row', 'row').replace('Press', 'press').replace('Push', 'push').replace('Squad', 'squad').replace('Lunge', 'lunge').replace('Bend', 'bend').replace('Core', 'core').replace('Mobility', 'mobility'))?.filter(x => x != null && x.length > 0),
              equipment: document.data().equipment?.split(', ').map(x => x.replace('Multipresse', 'multipress').replace('Medizinball', 'ball').replace('Kabelzug', 'cable').replace('Box', 'box').replace('Bank', 'bench').replace('PVC Stab', 'pvc').replace('Ringe', 'rings').replace('Trap Bar', 'trap_bar').replace('Kettlebell', 'kettlebell').replace('Wrist Roller', 'wrist_roller').replace('Band', 'band').replace('', '').replace('', '').replace('', '').replace('', ''))?.filter(x => x != null && x.length > 0),
            }).then(res => console.log('Saved: ' + res.id)).catch(error => console.log(error))

            document.ref.delete()
          }
        } catch (error) {
          console.log(error)
        }
      })
    }))
  }

  public get MergedTrainingExercises() {
    if (!this.observableTrainingExercises) this.refreshObservableTrainingExercises()
    if (!this.observableTrainingExerciseOverwrites) this.refreshObservableTrainingExerciseOverwrites()
    return this.mergedTrainingExercises;
  }
  private set MergedTrainingExercise(value: MergedTrainingExercise[]) {
    this.mergedTrainingExercises = value
  }

  private administratorEditModeActivated: boolean = false

  public get AdministratorEditModeActivated(): boolean {
    return this.administratorEditModeActivated
  }
  public set AdministratorEditModeActivated(value: boolean) {

    this.administratorEditModeActivated = value
    this.refreshObservableTrainingExercises()
    this.refreshObservableTrainingExerciseOverwrites()
  }

  public get CanEnterAdministratorEditMode(){
    if(this.authService?.isAdmin() || this.authService.user?.uid == 'jrYTvRFOH3aqqz04Pev4wVIyRcE2' /*|| this.userService.getLoggedInUser().licenceHolder?.isAdmin*/) return true
    return false;
  }

  public get UserUid() {
    if (this.administratorEditModeActivated == true) return "nutrilize"
    else {
      let loggedInUser = this.userService.getLoggedInUser();
      if(!loggedInUser?.isCoach) {
        return loggedInUser?.connectedLicenceHolderUid;
      }
      return loggedInUser?.licenceHolderUid;
    }
  }

  private trainingTemplateAdministratorEditModeActivated: boolean = false

  public get TrainingTemplateAdministratorEditModeActivated(): boolean {
    return this.trainingTemplateAdministratorEditModeActivated
  }

  public set TrainingTemplateAdministratorEditModeActivated(value: boolean) {
    this.trainingTemplateAdministratorEditModeActivated = value
  }

  public get CanEnterTrainingTemplateAdministratorEditMode() {
    if (this.authService?.isAdmin() || this.userService.getLoggedInUser().licenceHolder?.isAdmin) return true
    return false;
  }

  public get TemplateUserUid() {
    if (this.trainingTemplateAdministratorEditModeActivated == true) return "nutrilize"
    else return this.userService.getLoggedInUser()?.licenceHolderUid
  }


  public selectableMuscles: MuscleInformation<string>[] = [];
  public selectableMuscleGroups: MuscleInformation<string>[] = [];
  public selectableEquipments: LanguageDictionary<string>[] = [];
  public selectableMovementTypes: LanguageDictionary<string>[] = [];
  public selectableExerciseTypes: LanguageDictionary<string>[] = [];
  public selectableCustomCategories: LanguageDictionary<string>[];


  private downloadURLDictionary: Map<string, string> = new Map<string, string>();
  public GetFileDownloadURL(filePath: string, useCommonFirebaseProject: boolean = false): Observable<any> {
    return this.getFileDownloadURL(filePath, useCommonFirebaseProject ? this.commonFirebase : this.mainFirebase)
  }

  public refreshObservableTrainingExercises() {
    if (!this.UserUid) return;
    this.trainingExercises = []
    this.observableTrainingExercises = this.getTrainingExercises(["nutrilize", this.UserUid ?? ""])
    this.observableTrainingExercises.subscribe(x => {
      this.trainingExercises = x
      this.updateMergedExercises();
    });
  }

  public refreshObservableTrainingExerciseOverwrites() {
    if (!this.UserUid) return;
    this.trainingExerciseOverwrites = []
    this.observableTrainingExerciseOverwrites = this.getTrainingExerciseOverwrite(this.UserUid)
    this.observableTrainingExerciseOverwrites.subscribe(x => {
      this.trainingExerciseOverwrites = x
      this.setSelectableCustomCategories(this.trainingExerciseOverwrites.map(x => x.customCategories))
      this.updateMergedExercises();
    });
  }

  private setSelectableCustomCategories(array: LanguageDictionary<string>[][]) {
    this.selectableCustomCategories = []
    array.forEach(element => {
      element.forEach(languageDict => {
        if (this.selectableCustomCategories.filter(x => x.originObject.toString() === languageDict.originObject.toString()).length === 0) this.selectableCustomCategories.push(languageDict)
      })
    });
  }


  updateMergedExercises() {
    this.spinner.show()
    this.mergedTrainingExercises = []
    if (this.trainingExercises?.length > 0) {
      this.trainingExercises.forEach(exercise => {
        var overwrite = this.trainingExerciseOverwrites?.filter(x => x.sourceExerciseId == exercise.id)[0]
        if (overwrite) {
          this.mergedTrainingExercises.push(new MergedTrainingExercise(exercise, overwrite))
        }
        else {
          this.mergedTrainingExercises.push(new MergedTrainingExercise(exercise))
        }
      });
    }
    this.spinner.hide()
  }

  public getTranslation(value: LanguageDictionary<any>, languageCode: string): string {
    if (languageCode == "de") return value?.de ?? ""
    else if (languageCode == "en") return value?.en ?? ""
    else {
      console.log("ERROR: faulty languageCode:" + languageCode)
      return ""
    }
  }

  public setTranslation(languageDictionary: LanguageDictionary<any>, languageCode: string, value: string) {
    if (languageCode == "de") languageDictionary.de = value
    else if (languageCode == "en") languageDictionary.en = value
    else {
      console.log("ERROR: faulty languageCode:" + languageCode)
    }
  }


  insertTrainingExercise(mergedExercise: MergedTrainingExercise, thumbnailImage?: Blob, instructionVideo?: File, exercisePreview?: File) {
    var exercise = mergedExercise.trainingExercise
    this.firestore.collection('TrainingExercises').add({
      timestamp: new Date(),
      creatorUid: exercise.creatorUid,
      deleted: exercise.deleted,
      name: exercise.name?.AsMap(),
      subName: exercise.subName?.AsMap(),
      thumbnailPath: exercise.thumbnailPath,
      instructions: exercise.instructions?.AsMap(),
      isInvertedWeight: exercise.isInvertedWeight,
      muscleGroups: exercise.muscleGroups.map(x => x.originObject?.toString() || ""),
      mainMuscles: exercise.mainMuscles.map(x => x.originObject?.toString() || ""),
      subMuscles: exercise.subMuscles.map(x => x.originObject?.toString() || ""),
      exerciseType: exercise.exerciseType.map(x => x.originObject?.toString() || ""),
      movement: exercise.movement.map(x => x.originObject?.toString() || ""),
      equipment: exercise.equipment.map(x => x.originObject?.toString() || ""),
      exercisePreviewPath: exercise.exercisePreviewPath,
      instructionVideoPath: exercise.instructionVideoPath,
      defaultSetParameters: exercise.defaultSetParameters.map(x => x.originObject.toString()),
      isBasicExercise: exercise.isBasicExercise,
    }).then((res) => {
      exercise.id = res.id
      if (thumbnailImage) {
        this.uploadExerciseThumbnailImage(thumbnailImage, exercise)
      }
      if (instructionVideo) {
        this.uploadExerciseVideo(instructionVideo, exercise)
      }
      if (exercisePreview) {
        this.uploadExercisePreview(exercisePreview, exercise)
      }
      console.log(mergedExercise.trainingExerciseOverwrite)
      if ((mergedExercise.trainingExerciseOverwrite.hasValue() || instructionVideo) && !this.AdministratorEditModeActivated) {
        mergedExercise.trainingExerciseOverwrite.creatorUid = this.UserUid
        mergedExercise.sourceExerciseId = mergedExercise.trainingExercise.id
        this.insertTrainingExerciseOverwrite(mergedExercise.trainingExerciseOverwrite, instructionVideo)
      }
    });
  }

  getTrainingExerciseOverwriteForFirebase(exercise: TrainingExerciseOverwrite) {
    return {
      timestamp: new Date(),
      creatorUid: exercise.creatorUid,
      deleted: exercise.deleted,
      sourceExerciseId: exercise.sourceExerciseId,
      customVideoUrl: exercise.customVideoUrl,
      customInstructionVideoPath: exercise.customInstructionVideoPath,
      customCategories: exercise.customCategories.map(x => x.originObject?.toString() || ""),
      customNotes: exercise.customNotes,
      customPauseDuration: exercise.customPauseDuration,
      customInstructions: exercise.customInstructions?.AsMap(),
      customThumbnailPath: exercise.customThumbnailPath,
      customExercisePreviewPath: exercise.customExercisePreviewPath,
      customName: exercise.customName?.AsMap(),
      customSubName: exercise.customSubName?.AsMap(),
      customMuscleGroups: exercise.customMuscleGroups.map(x => x.originObject?.toString() || ""),
      customMainMuscles: exercise.customMainMuscles.map(x => x.originObject?.toString() || ""),
      customSubMuscles: exercise.customSubMuscles.map(x => x.originObject?.toString() || ""),
      customExerciseType: exercise.customExerciseType.map(x => x.originObject?.toString() || ""),
      customMovement: exercise.customMovement.map(x => x.originObject?.toString() || ""),
      customEquipment: exercise.customEquipment.map(x => x.originObject?.toString() || ""),
      customIsInvertedWeight: exercise.customIsInvertedWeight,
      hiddenExercise: exercise.hiddenExercise,
      customIsBasicExercise: exercise.customIsBasicExercise,
      customDefaultSetParameters: exercise.customDefaultSetParameters.map(x => x.originObject.toString()),
    }
  }

  saveOrUpdateMergedTrainingExercise(exercise: MergedTrainingExercise, newThumbnail: any, newVideo: any, newExercisePreview: any, deleteOldExercisePreviewVideo: boolean) {
    let editedOriginExercise = (exercise.creatorUid == this.UserUid);
    if (editedOriginExercise) {
      if (exercise.trainingExercise.id) {
        if (deleteOldExercisePreviewVideo && exercise.trainingExercise.instructionVideoPath) {
          this.deleteExerciseInstructionVideo(exercise.trainingExercise.instructionVideoPath)
          exercise.trainingExercise.instructionVideoPath = ""
        }
        this.updateTrainingExercise(exercise.trainingExercise, newThumbnail, newVideo, newExercisePreview)
      } else {
        this.insertTrainingExercise(exercise, newThumbnail, newVideo, newExercisePreview)
      }

      /*if (exercise.trainingExercise?.id?.length > 0) {
        this.hardDeleteTrainingExerciseOverwrite(exercise.trainingExerciseOverwrite)
      }*/
    }
    if ((exercise.trainingExerciseOverwrite.hasValue() || newVideo || newThumbnail || newExercisePreview) && !this.AdministratorEditModeActivated) {
      if (exercise.trainingExerciseOverwrite?.id?.length > 0) {
        if (deleteOldExercisePreviewVideo && exercise.trainingExerciseOverwrite.customInstructionVideoPath) {
          this.deleteExerciseInstructionVideo(exercise.trainingExerciseOverwrite.customInstructionVideoPath)
          exercise.trainingExerciseOverwrite.customInstructionVideoPath = ""
        }
        if (editedOriginExercise) {
          this.updateTrainingExerciseOverwrite(exercise.trainingExerciseOverwrite);
        } else {
          this.updateTrainingExerciseOverwrite(exercise.trainingExerciseOverwrite, newVideo, newThumbnail, newExercisePreview);
        }
      } else if (exercise.trainingExercise?.id?.length > 0) {
        exercise.trainingExerciseOverwrite.creatorUid = this.UserUid
        exercise.sourceExerciseId = exercise.trainingExercise.id
        this.insertTrainingExerciseOverwrite(exercise.trainingExerciseOverwrite, newVideo, newThumbnail, newExercisePreview);
      }
    }

  }

  insertTrainingExerciseOverwrite(exercise: TrainingExerciseOverwrite, newVideo: File, thumbnailImage?: Blob, exercisePreview?: File) {
    if (newVideo != null) exercise.customInstructionVideoPath = this.GetExerciseDirectoryPath(exercise.creatorUid, exercise.sourceExerciseId) + "instructionVideo." + newVideo.name.split('.').pop()
    this.firestore.collection('TrainingExerciseOverwrites').add(this.getTrainingExerciseOverwriteForFirebase(exercise)).then((res) => {
      if (newVideo && exercise.customInstructionVideoPath) {
        this.uploadCustomExerciseVideo(newVideo, exercise.customInstructionVideoPath, exercise)
      }
      if (thumbnailImage) {
        this.uploadCustomExerciseThumbnailImage(thumbnailImage, exercise)
      }
      if (exercisePreview) {
        this.uploadCustomExercisePreview(exercisePreview, exercise)
      }
    });
  }

  hardDeleteTrainingExerciseOverwrite(exercise: TrainingExerciseOverwrite) {
    this.firestore.collection('TrainingExerciseOverwrites').doc(exercise.id).delete()
  }

  updateTrainingExerciseOverwrite(exercise: TrainingExerciseOverwrite, newVideo?: File, thumbnailImage?: Blob, exercisePreview?: File) {
    if (newVideo != null) exercise.customInstructionVideoPath = this.GetExerciseDirectoryPath(exercise.creatorUid, exercise.sourceExerciseId) + "instructionVideo." + newVideo.name.split('.').pop()
    this.firestore.collection('TrainingExerciseOverwrites').doc(exercise.id).set(this.getTrainingExerciseOverwriteForFirebase(exercise)).then((res) => {
      if (newVideo && exercise.customInstructionVideoPath) {
        this.uploadCustomExerciseVideo(newVideo, exercise.customInstructionVideoPath, exercise)
      }
      if (thumbnailImage) {
        this.uploadCustomExerciseThumbnailImage(thumbnailImage, exercise)
      }
      if (exercisePreview) {
        this.uploadCustomExercisePreview(exercisePreview, exercise)
      }
    });
  }

  getTrainingExerciseOverwrite(creatorUid: string): Observable<TrainingExerciseOverwrite[]> {
    var trainingExerciseOverwrites = this.firestore.collection<TrainingExerciseOverwrite>('TrainingExerciseOverwrites', ref => ref.where('creatorUid', '==', creatorUid)).valueChanges({ idField: 'id' }).pipe(map(documents => {
      var exercises: TrainingExerciseOverwrite[] = [];
      (documents as any[]).forEach(document => {
        var exercise = new TrainingExerciseOverwrite(document as TrainingExerciseOverwrite)
        exercise.customCategories = ((document as any).customCategories as string[])?.map(x => new LanguageDictionary<string>("", "", x)) || []
        exercise.customExerciseType = ((document as any).customExerciseType as string[])?.map(x => ExerciseTypeTranslation.get(x))
        exercise.customMovement = ((document as any).customMovement as string[])?.map(x => x.replace('Squat', 'squat'))?.map(x => MovementTypeMap.get(x)) || []
        exercise.customEquipment = ((document as any).customEquipment as string[])?.filter(x => x != 'Ohne Equipment')?.map(x => EquipmentTranslation.get(x)) || []

        exercise.customMainMuscles = ((document as any).customMainMuscles as string[])?.map(x => MuscleTranslation.get(x)) || []
        exercise.customSubMuscles = ((document as any).customSubMuscles as string[])?.map(x => MuscleTranslation.get(x)) || []
        exercise.customMuscleGroups = ((document as any).customMuscleGroups as string[])?.map(x => MuscleGroupTranslation.get(x)) || []
        if (document.customThumbnailPath?.length > 0) {
          this.fireStorage.ref(document.customThumbnailPath).getDownloadURL().toPromise().then((link) => {
            exercise.customThumbnailDownloadURL = link;
          }).catch(ex => console.log(ex));
        }
        exercise.customDefaultSetParameters = ((document as any).customDefaultSetParameters as string[])?.map(x => SetParameter2LanguageDictionary[x]) || []
        exercise.timestamp = (document as any).timestamp.toDate()
        exercises.push(exercise)
      })
      return exercises;
    }))
    return trainingExerciseOverwrites
  }

  async duplicateMergedTrainingExercise(exercise: MergedTrainingExercise): Promise<MergedTrainingExercise> {
    // var newExercise = exercise.clone()
    // newExercise.trainingExercise.id = null
    // newExercise.trainingExercise.creatorUid = this.UserUid
    // newExercise.trainingExerciseOverwrite.id = null
    // newExercise.trainingExerciseOverwrite.creatorUid = this.UserUid
    // newExercise.trainingExerciseOverwrite.sourceExerciseId = exercise.trainingExercise.id
    // return newExercise
    var newExercise = exercise.clone()
    newExercise.trainingExercise.id = null
    newExercise.trainingExerciseOverwrite.sourceExerciseId = null
    newExercise.trainingExerciseOverwrite.id = null
    newExercise.trainingExercise.creatorUid = this.UserUid
    newExercise.trainingExerciseOverwrite.creatorUid = this.UserUid
    newExercise.name.de = newExercise.name.de + " (Kopie)"
    newExercise.name.en = newExercise.name.en + " (Copy)"

    let newInstructionVideo: File = null
    if (newExercise.instructionVideoPath) {
      newInstructionVideo = await this.getFileFromFirebaseStorage(newExercise.instructionVideoPath)
    }
    let newThumbnailBlob: Blob = null
    if (newExercise.thumbnailPath) {
      newThumbnailBlob = await this.getBlobFromFirebaseStorage(newExercise.thumbnailPath)
    }
    let newExercisePreview: File = null
    if (newExercise.exercisePreviewPath) {
      newExercisePreview = await this.getFileFromFirebaseStorage(newExercise.exercisePreviewPath)
    }

    this.insertTrainingExercise(newExercise, newThumbnailBlob, newInstructionVideo, newExercisePreview);
    return newExercise;
  }

  updateTrainingExercise(exercise: TrainingExercise, thumbnailImage?: Blob, instructionVideo?: File, exercisePreview?: File) {
    this.firestore.collection('TrainingExercises').doc(exercise.id).set({
      timestamp: new Date(),
      creatorUid: exercise.creatorUid,
      deleted: exercise.deleted,
      name: exercise.name?.AsMap(),
      subName: exercise.subName?.AsMap(),
      thumbnailPath: exercise.thumbnailPath,
      instructions: exercise.instructions?.AsMap(),
      isInvertedWeight: exercise.isInvertedWeight,
      muscleGroups: exercise.muscleGroups.map(x => x.originObject?.toString()),
      mainMuscles: exercise.mainMuscles.map(x => x.originObject?.toString()),
      subMuscles: exercise.subMuscles.map(x => x.originObject?.toString()),
      exerciseType: exercise.exerciseType.map(x => x.originObject?.toString()),
      movement: exercise.movement.map(x => x.originObject?.toString()),
      equipment: exercise.equipment.map(x => x.originObject?.toString()),
      exercisePreviewPath: exercise.exercisePreviewPath,
      instructionVideoPath: exercise.instructionVideoPath,
      defaultSetParameters: exercise.defaultSetParameters.map(x => x.originObject.toString()),
      isBasicExercise: exercise.isBasicExercise,
    }).then((res) => {
      if (thumbnailImage) {
        this.uploadExerciseThumbnailImage(thumbnailImage, exercise)
      }
      if (instructionVideo) {
        this.uploadExerciseVideo(instructionVideo, exercise)
      }
      if (exercisePreview) {
        this.uploadExercisePreview(exercisePreview, exercise)
      }
    });
  }

  getTrainingExercises(creatorUids: string[]): Observable<TrainingExercise[]> {
    if (this.commonFirebase.name == this.mainFirebase.name) return this._getTrainingExercises(creatorUids, this.mainFirebase).pipe(map(exercises => exercises.sort((x, y) => x?.name?.de?.localeCompare(y?.name?.de) || x?.subName?.de?.localeCompare(y?.subName?.de))))

    return combineLatest([this._getTrainingExercises(creatorUids, this.commonFirebase), this._getTrainingExercises(creatorUids, this.mainFirebase)]).pipe(map(([commonExercises, individualExercises]) => {
      return commonExercises.map(e => {
        e.isFromCommonFirebaseProject = true
        return e
      }).filter(x => x.creatorUid == 'nutrilize').concat(individualExercises).sort((x, y) => x?.name?.de?.localeCompare(y?.name?.de) || x?.subName?.de?.localeCompare(y?.subName?.de))
    }))
  }

  private _getTrainingExercises(creatorUids: string[], firebaseProject: FirebaseProject): Observable<TrainingExercise[]> {
    var trainingExercises = firebaseProject.firestore.collection<TrainingExercise>('TrainingExercises', ref => ref.where('creatorUid', 'in', creatorUids)).valueChanges({ idField: 'id' }).pipe(map(documents => {
      var exercises: TrainingExercise[] = [];
      (documents as any[]).forEach(document => {
        var exercise = new TrainingExercise(document as TrainingExercise)
        if (firebaseProject.name == this.commonFirebase.name && this.commonFirebase.name != this.mainFirebase.name) exercise.id = 'NUT_' + exercise.id
        exercise.exerciseType = ((document as any).exerciseType as string[])?.map(x => ExerciseTypeTranslation.get(x))
        exercise.movement = ((document as any).movement as string[])?.map(x => x.replace('Squat', 'squat'))?.map(x => MovementTypeMap.get(x)) || []
        exercise.equipment = ((document as any).equipment as string[])?.filter(x => x != 'Ohne Equipment')?.map(x => EquipmentTranslation.get(x)) || []

        exercise.mainMuscles = ((document as any).mainMuscles as string[])?.map(x => MuscleTranslation.get(x)) || []
        exercise.subMuscles = ((document as any).subMuscles as string[])?.map(x => MuscleTranslation.get(x)) || []
        exercise.muscleGroups = ((document as any).muscleGroups as string[])?.map(x => MuscleGroupTranslation.get(x)) || []

        exercise.defaultSetParameters = ((document as any).defaultSetParameters as string[])?.map(x => SetParameter2LanguageDictionary[x]) || [SetParameter2LanguageDictionary[SetParameter.weight], SetParameter2LanguageDictionary[SetParameter.reps]]
        exercise.timestamp = (document as any).timestamp.toDate()
        /*if(document.thumbnailPath?.length > 0){
          firstValueFrom(firebaseProject.storage.ref(document.thumbnailPath).getDownloadURL()).then((link) => {
            exercise.thumbnailDownloadURL = link;
          }).catch(ex => console.log(ex));
        }*/
        exercises.push(exercise)
      })
      return exercises.sort((x, y) => x?.name?.de?.localeCompare(y?.name?.de) || x?.subName?.de?.localeCompare(y?.subName?.de))
    }))
    return trainingExercises
  }

  async deleteTrainingExercise(exercise: TrainingExercise): Promise<void> {
    await this.firestore.collection('TrainingExercises').doc(exercise.id).set({
      timestamp: new Date(),
      deleted: true
    }, { merge: true });
  }

  private exerciseThumnailPromiseMap = new Map<string, Promise<string>>()

  getExerciseThumbnailUrl(exercise: MergedTrainingExercise) {
    if (!exercise) return null
    if (exercise.thumbnailPath?.length > 0) {
      if (exercise.thumbnailUrl) return new Promise<string>((resolve, reject) => resolve(exercise.thumbnailUrl))
      if (this.exerciseThumnailPromiseMap.has(exercise.sourceExerciseId)) {
        return this.exerciseThumnailPromiseMap.get(exercise.sourceExerciseId).then((link) => {
          //exercise.thumbnailDownloadURL = link;
          exercise.thumbnailUrl = link
          return link
        })
      }
      console.log('Fetch exercise thumbnail ', exercise.sourceExerciseId)
      var promise: Promise<string> = new Promise((resolve, reject) => {
        firstValueFrom((exercise.isFromCommonFirebaseProject ? this.commonFirebase : this.mainFirebase).storage.ref(exercise.thumbnailPath).getDownloadURL()).then(async (link) => {
          exercise.thumbnailUrl = link
          if (exercise.trainingExerciseOverwrite.id && exercise.trainingExerciseOverwrite.customThumbnailPath?.length > 0) {
            await this.mainFirebase.firestore.collection('TrainingExerciseOverwrites').doc(exercise.trainingExerciseOverwrite.id).update({ customThumbnailUrl: link })
          } else {
            await (exercise.isFromCommonFirebaseProject ? this.commonFirebase : this.mainFirebase).firestore.collection('TrainingExercises').doc(exercise.sourceExerciseId).update({ thumbnailUrl: link })
          }
          //exercise.thumbnailDownloadURL = link;
          resolve(link)
        }).catch(ex => console.log(ex));
      });
      this.exerciseThumnailPromiseMap.set(exercise.sourceExerciseId, promise)
      return promise;
    }
    return null
  }

  setThumbnailDownloadURL(exerciseId: string, url: string) {
    let exercise = this.getExerciseById(exerciseId)
    if (exercise) exercise.thumbnailDownloadURL = url
  }


  getExerciseById(exerciseId: string): MergedTrainingExercise {
    return this.MergedTrainingExercises.find(x => x.sourceExerciseId == exerciseId) || null
  }

  uploadExerciseThumbnailImage(thumbnail: Blob, exercise: TrainingExercise) {
    let activeToast = this.toastrService.info("Datei-Upload gestartet.", "", { extendedTimeOut: 0, timeOut: 0, disableTimeOut: true });
    var fileName = "thumbnail" + ".png"
    const file = new File([thumbnail], fileName, {
      type: 'image/png',
      lastModified: Date.now()
    });
    var filePath = this.GetExerciseDirectoryPath(exercise.creatorUid, exercise.id) + fileName;
    let uploadTask = this.fireStorage.upload(filePath, file);
    uploadTask.percentageChanges().subscribe(x => {
      if (x >= 100) {
        activeToast.message = "Datei-Upload beendet.";
        this.toastrService.clear(activeToast.toastId);
      }
      else activeToast.message = "Datei-Upload zu " + x.roundToInt() + "% abgeschlossen"
    });
    uploadTask.then(async (res) => {
      exercise.thumbnailPath = filePath
      this.exerciseThumnailPromiseMap.delete(exercise.id)
      exercise.thumbnailUrl = null
      exercise.thumbnailDownloadURL = null
      await this.firestore.collection('TrainingExercises').doc(exercise.id).update({
        timestamp: new Date(),
        thumbnailPath: filePath,
        thumbnailUrl: null,
      })
      activeToast.message = "Datei-Upload beendet.";
      this.toastrService.clear(activeToast.toastId);
      return true;
    });
    // this.fireStorage.upload(filePath, file).then((res) => {
    //   exercise.thumbnailPath = filePath
    //   this.firestore.collection('TrainingExercises').doc(exercise.id).update({
    //     timestamp: new Date(),
    //     thumbnailPath: filePath,
    //   })
    //   return true;
    // });
  }

  uploadCustomExerciseThumbnailImage(thumbnail: Blob, exercise: TrainingExerciseOverwrite) {
    var activeToast = this.toastrService.info("Datei-Upload gestartet.", "", { extendedTimeOut: 0, timeOut: 0, disableTimeOut: true });
    let toastrInstance = this.toastrService.toasts?.find(toast => toast.toastId == activeToast.toastId)?.toastRef?.componentInstance;
    var fileName = "thumbnail" + ".png"
    const file = new File([thumbnail], fileName, {
      type: 'image/png',
      lastModified: Date.now()
    });
    var filePath = this.GetExerciseDirectoryPath(exercise.creatorUid, exercise.id) + fileName;
    let uploadTask = this.fireStorage.upload(filePath, file);
    uploadTask.percentageChanges().subscribe(x => {
      if (x >= 100) {
        toastrInstance.message = "Datei-Upload beendet.";
        this.toastrService.clear(toastrInstance.toastId);
      }
      else toastrInstance.message = "Datei-Upload zu " + x.roundToInt() + "% abgeschlossen"
    });
    uploadTask.then((res) => {
      exercise.customThumbnailPath = filePath
      this.firestore.collection('TrainingExerciseOverwrites').doc(exercise.id).update({
        timestamp: new Date(),
        customThumbnailPath: filePath,
        customThumbnailUrl: null,
      })
      res.ref.getDownloadURL().then((link) => {
        exercise.customThumbnailDownloadURL = link;
      }).catch(ex => console.log(ex));
      toastrInstance.message = "Datei-Upload beendet.";
      this.toastrService.clear(toastrInstance.toastId);
      return true;
    });
    // this.fireStorage.upload(filePath, file).percentageChanges().subscribe(x => {
    //   if (x >= 100) {
    //     toastrInstance.message = "Datei-Upload beendet.";
    //     this.toastrService.clear(toastrInstance.toastId);
    //   }

    //   else toastrInstance.message = "Datei-Upload zu " + x.roundToInt() + "% abgeschlossen"
    // });
    // this.fireStorage.upload(filePath, file).then((res) => {
    //   exercise.customThumbnailPath = filePath
    //   this.firestore.collection('TrainingExerciseOverwrites').doc(exercise.id).update({
    //     timestamp: new Date(),
    //     customThumbnailPath: filePath
    //   })
    //   return true;
    // });
  }

  public async copyFirebaseFile(sourceFilePath: string, targetFilePath: string) {

    // this.toastrService.info("Kopie gestartet.")
    // var sourceRef = this.fireStorage.ref(sourceFilePath)
    // var targetRef = this.fireStorage.ref(targetFilePath)
    // let sourceDownloadURL = await sourceRef.getDownloadURL().toPromise();
    // let blob = await fetch(sourceDownloadURL).then(r => r.blob());
    // await targetRef.put(blob)
    // this.toastrService.info("Kopie beendet.")
  }

  private async getFileFromFirebaseStorage(filePath: string): Promise<File> {
    const ref = this.fireStorage.ref(filePath);
    const url = await ref.getDownloadURL().toPromise();
    return await this.getFileFromURL(url);
  }
  private async getBlobFromFirebaseStorage(filePath: string): Promise<Blob> {
    const ref = this.fireStorage.ref(filePath);
    const url = await ref.getDownloadURL().toPromise();
    return await this.getBlobFromURL(url);
  }
  private async getBlobFromURL(url: string): Promise<Blob> {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
  }
  private async getFileFromURL(url: string): Promise<File> {
    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], url.split('/').pop(), {
      type: blob.type,
      lastModified: Date.now()
    });
    return file;
  }


  private GetExerciseDirectoryPath(userUid: string, exerciseId: string): string {
    return '/training/' + userUid + "/" + exerciseId.replace('NUT_', '') + "/";
  }

  uploadExerciseVideo(video: File, exercise: TrainingExercise) {
    let activeToast = this.toastrService.info("Video-Upload gestartet.", "", { extendedTimeOut: 0, timeOut: 0, disableTimeOut: true });
    let toastrInstance = this.toastrService.toasts.find(toast => toast.toastId == activeToast.toastId).toastRef.componentInstance;
    var filePath = this.GetExerciseDirectoryPath(exercise.creatorUid, exercise.id) + "instructionVideo." + video.name.split('.').pop();
    let uploadTask = this.fireStorage.upload(filePath, video);
    uploadTask.percentageChanges().subscribe(x => {
      toastrInstance.message = "Video-Upload zu " + x.roundToInt() + "% abgeschlossen"
    });
    uploadTask.then((res) => {
      exercise.instructionVideoPath = filePath
      this.firestore.collection('TrainingExercises').doc(exercise.id).update({
        timestamp: new Date(),
        instructionVideoPath: filePath
      })
      toastrInstance.message = "Video-Upload beendet.";
      this.toastrService.clear(toastrInstance.toastId);
      return true;
    });
  }

  uploadCustomExerciseVideo(video: File, filePath: string, exerciseOverwrite: TrainingExerciseOverwrite) {
    var activeToast = this.toastrService.info("Video-Upload gestartet.", "", { extendedTimeOut: 0, timeOut: 0, disableTimeOut: true })
    let toastrInstance = this.toastrService.toasts.find(toast => toast.toastId == activeToast.toastId).toastRef.componentInstance
    let uploadTask = this.fireStorage.upload(filePath, video);
    uploadTask.percentageChanges().subscribe(x => {
      if (x >= 100) {
        toastrInstance.message = "Video-Upload beendet.";
        this.toastrService.clear(toastrInstance.toastId);
      }
      else toastrInstance.message = "Video-Upload zu " + x.roundToInt() + "% abgeschlossen"
    });
    uploadTask.then((res) => {
      exerciseOverwrite.customInstructionVideoPath = filePath
      this.firestore.collection('TrainingExerciseOverwrites').doc(exerciseOverwrite.id).update({
        timestamp: new Date(),
        customInstructionVideoPath: filePath
      })
      toastrInstance.message = "Video-Upload beendet.";
      this.toastrService.clear(toastrInstance.toastId);
      return true;
    });
  }

  async deleteExerciseInstructionVideo(filePath: string) {
    console.log(filePath)
    await this.fireStorage.ref(filePath).delete()
  }

  uploadExercisePreview(file: File, exercise: TrainingExercise) {
    let activeToast = this.toastrService.info("Datei-Upload gestartet.", "", { extendedTimeOut: 0, timeOut: 0, disableTimeOut: true });
    let toastrInstance = this.toastrService.toasts?.find(toast => toast.toastId == activeToast.toastId)?.toastRef?.componentInstance;
    var filePath = this.GetExerciseDirectoryPath(exercise.creatorUid, exercise.id) + "exercisePreview" + ".gif"
    let uploadTask = this.fireStorage.upload(filePath, file);
    uploadTask.percentageChanges().subscribe(x => {
      if (x >= 100) {
        toastrInstance.message = "Datei-Upload beendet.";
        this.toastrService.clear(toastrInstance.toastId);
      }
      else toastrInstance.message = "Datei-Upload zu " + x.roundToInt() + "% abgeschlossen"
    });
    uploadTask.then((res) => {
      exercise.exercisePreviewPath = filePath
      this.firestore.collection('TrainingExercises').doc(exercise.id).update({
        timestamp: new Date(),
        exercisePreviewPath: filePath
      })
      toastrInstance.message = "Datei-Upload beendet.";
      this.toastrService.clear(toastrInstance.toastId);
      return true;
    });
  }


  uploadCustomExercisePreview(file: File, exercise: TrainingExerciseOverwrite) {
    var activeToast = this.toastrService.info("Datei-Upload gestartet.", "", { extendedTimeOut: 0, timeOut: 0, disableTimeOut: true });
    let toastrInstance = this.toastrService.toasts?.find(toast => toast.toastId == activeToast.toastId)?.toastRef?.componentInstance;
    var filePath = this.GetExerciseDirectoryPath(exercise.creatorUid, exercise.id) + "exercisePreview" + ".gif"
    let uploadTask = this.fireStorage.upload(filePath, file);
    uploadTask.percentageChanges().subscribe(x => {
      if (x >= 100) {
        toastrInstance.message = "Datei-Upload beendet.";
        this.toastrService.clear(toastrInstance.toastId);
      }
      else toastrInstance.message = "Datei-Upload zu " + x.roundToInt() + "% abgeschlossen"
    });
    uploadTask.then((res) => {
      exercise.customExercisePreviewPath = filePath
      this.firestore.collection('TrainingExerciseOverwrites').doc(exercise.id).update({
        timestamp: new Date(),
        customExercisePreviewPath: filePath
      })
      toastrInstance.message = "Datei-Upload beendet.";
      this.toastrService.clear(toastrInstance.toastId);
      return true;
    });
  }

  getFileDownloadURL(filePath: string, firebaseProject: FirebaseProject = this.mainFirebase): Observable<any> {
    if (filePath) {
      return firebaseProject.storage.ref(filePath).getDownloadURL()
    }
  }


  public trainingPlanTemplates: TrainingPlan[] = []

  public async getTrainingPlanTemplates() {
    if (!(this.trainingPlanTemplates?.length > 0)) await this.loadTrainingPlanTemplates()
    return this.trainingPlanTemplates.filter(x => !x.deleted && (this.TrainingTemplateAdministratorEditModeActivated || this.TemplateUserUid == x.licenceHolderUid || x.access == TrainingPlanAccess.all || x.access == TrainingPlanAccess.coaches));
  }

  async loadTrainingPlanTemplates() {
    //this.spinner.show();
    this.trainingPlanTemplates = [];
    try {
      const documents = await this.firestore.collection('TrainingPlanTemplates').ref.where('licenceHolderUid', 'in', [this.userService.getLoggedInUser().licenceHolderUid, 'nutrilize']).get();

      // this.firestore.firestore.collectionGroup('PlannedTrainingExercises').get().then(x => console.log(x)).catch(err => console.error(err))
      // let check = this.firestore.collectionGroup('PlannedTrainingExercises').get().subscribe(x => console.log(x))
      // const plannedTrainingExerciseDocs = await firstValueFrom(this.firestore.collectionGroup('PlannedTrainingExercises').get());
      const trainingPlanTemplates: TrainingPlan[] = [];
      for (const document of documents.docs) {
        if ((document.data() as any).deleted) continue;
        const trainingPlan = new TrainingPlan(document.data() as TrainingPlan);

        trainingPlan.id = document.id;
        trainingPlan.isTemplate = true;

        trainingPlan.sessions = [];
        for (let sessionDoc of ((document.data() as any).sessions as any[])) {
          let session = new TrainingSession(sessionDoc.name, sessionDoc.id, sessionDoc.isRestDay, [], sessionDoc.deleted, sessionDoc.weekId, sessionDoc.plannedDate, sessionDoc.baseSessionId, sessionDoc.hide, sessionDoc.indicatorColor, sessionDoc.estimatedDurationInMinutes, sessionDoc.nameTranslation);
          if(sessionDoc.plannedDate as any && (sessionDoc.plannedDate as any)?.seconds != undefined){
            session.plannedDate = new Date((sessionDoc.plannedDate as any)?.seconds * 1000);
          }
          if(session?.plannedDate != null && isNaN(session?.plannedDate?.getTime())){
            console.log(session);
          }
          trainingPlan.sessions.push(session);
        }
        if((document.data() as TrainingPlan)?.startDate == null){
          trainingPlan.setStartDate();
        }
        else {
          trainingPlan.startDate = new Date((document.data() as any).startDate.seconds * 1000);
        }
        
        if(trainingPlan.imagePath?.length > 0){
          firstValueFrom(this.fireStorage.ref(trainingPlan.imagePath).getDownloadURL()).then((link) => {
            trainingPlan.imageDownloadURL = link;
          }).catch(ex => console.error(ex));
        }
        trainingPlanTemplates.push(trainingPlan);
      }
      this.trainingPlanTemplates = trainingPlanTemplates.sort((a, b) => a.nameTranslation?.GetValue(this.languageService.selectedLanguageCode)?.localeCompare(b.nameTranslation?.GetValue(this.languageService.selectedLanguageCode))).sort((a, b) => a.licenceHolderUid.localeCompare(b.licenceHolderUid));
    } catch (error) {
      console.error('Error loading training plan templates', error);
    }
  }

  async getTrainingPlanTemplatePlannedExercises(trainingPlanTemplate: TrainingPlan) {
    const exerciseDocuments = await this.firestore.collection<any>('TrainingPlanTemplates').doc(trainingPlanTemplate.id).collection('/PlannedTrainingExercises/').ref.get();
    const plannedTrainingExercises: PlannedTrainingExercise[] = [];
    for (const exerciseDocument of exerciseDocuments.docs) {
      const plannedExercise: PlannedTrainingExercise = new PlannedTrainingExercise(exerciseDocument.data() as PlannedTrainingExercise);
      plannedExercise.id = exerciseDocument.id;
      if ((exerciseDocument.data() as any).videoRecordingRequest) {
        const videoRecordingRequest = (exerciseDocument.data() as any).videoRecordingRequest as VideoRecordingRequest;
        plannedExercise.videoRecordingRequest = new VideoRecordingRequest(videoRecordingRequest.active, videoRecordingRequest.frequency, videoRecordingRequest.hint);
      }
      if ((exerciseDocument.data() as any).superSetConfig) {
        var superSetConfig = (exerciseDocument.data() as any).superSetConfig as SuperSetConfig;
        plannedExercise.superSetConfig = new SuperSetConfig(superSetConfig?.numberOfRounds, superSetConfig?.totalAvailableTime, superSetConfig?.roundAvailableTime, superSetConfig?.name, superSetConfig?.nameTranslation);
      }
      plannedTrainingExercises.push(plannedExercise);

    }
    for (const session of trainingPlanTemplate.sessions) {
      const plannedExercises = plannedTrainingExercises.filter(x => x.sessionId === session.id);
      if (plannedExercises?.length > 0)
        session.exercises = plannedExercises.sort((n1, n2) => n1.position - n2.position);
    }
  }

  async deleteTrainingPlanTemplate(trainingPlanTemplate: TrainingPlan) {
    await this.firestore.collection('TrainingPlanTemplates').doc(trainingPlanTemplate.id).set({
      timestamp: new Date(),
      deleted: true
    }, { merge: true });
    this.trainingPlanTemplates = []
    // this.trainingPlanTemplates = this.trainingPlanTemplates.filter(x => x.id != trainingPlanTemplate.id)
  }

  getTrainingPlanTemplateThumbnailPath(template: TrainingPlan) {
    return "training_templates/" + template.id + "/" + "thumbnail_" + FirestoreNutritionPlanService.generateUniqueString() + ".png";
  }

  async saveTrainingPlanTemplate(trainingPlanTemplate: TrainingPlan, thumbnailImage?: File) {
    try {
      trainingPlanTemplate.setDurationInDays();
      trainingPlanTemplate.sortSessionsByWeekAndDate();
      const result = await this.firestore
        .collection('TrainingPlanTemplates')
        .add(this.convertTrainingPlanTemplateToFirebaseData(trainingPlanTemplate));

      trainingPlanTemplate.id = result.id
      if (thumbnailImage) {
        trainingPlanTemplate.imagePath = this.getTrainingPlanTemplateThumbnailPath(trainingPlanTemplate);
        await this.uploadTrainingPlanTemplateThumbnailImage(thumbnailImage, trainingPlanTemplate)
        await this.firestore.collection('TrainingPlanTemplates').doc(trainingPlanTemplate.id).set({
          timestamp: new Date(),
          imagePath: trainingPlanTemplate.imagePath
        }, { merge: true });
      }

      for (let i = 0; i < trainingPlanTemplate.sessions.length; i++) {
        const session = trainingPlanTemplate.sessions[i];
        for (let j = 0; j < session.exercises.length; j++) {
          const exercise = session.exercises[j];
          await this.firestore
            .collection(`TrainingPlanTemplates/${result.id}/PlannedTrainingExercises/`)
            .add(this.convertExerciseToFirebaseData(j, session, exercise));
        }
      }

      await this.userService.setNotExistingGlobalTrainingVariables(trainingPlanTemplate.trainingVariables);

      this.trainingPlanTemplates = [];
    } catch (error) {
      console.error('Error saving training plan template:', error);
    }
  }

  async uploadTrainingPlanTemplateThumbnailImage(thumbnailImage: File, template: TrainingPlan) {
    await this.fireStorage.ref(template.imagePath).put(thumbnailImage)
    await this.fireStorage.ref(template.imagePath).getDownloadURL().toPromise().then((link) => {
      template.imageDownloadURL = link;
    }).catch(ex => console.log(ex));
  }


  removeTrainingPlanTemplateThumbnail(template: TrainingPlan) {
    template.imagePath = null;
    template.imageDownloadURL = null;
  }


  async updateTrainingPlanTemplate(trainingPlanTemplate: TrainingPlan, removeThumbnail: boolean, thumbnailImage?: File) {
    try {
      trainingPlanTemplate.setDurationInDays();
      trainingPlanTemplate.sortSessionsByWeekAndDate();
      if (removeThumbnail) {
        this.removeTrainingPlanTemplateThumbnail(trainingPlanTemplate);
      }
      if (thumbnailImage) {
        trainingPlanTemplate.imagePath = this.getTrainingPlanTemplateThumbnailPath(trainingPlanTemplate);
        await this.uploadTrainingPlanTemplateThumbnailImage(thumbnailImage, trainingPlanTemplate);
      }
      const result = await this.firestore
        .collection('TrainingPlanTemplates')
        .doc(trainingPlanTemplate.id)
        .update(this.convertTrainingPlanTemplateToFirebaseData(trainingPlanTemplate));

      for (let i = 0; i < trainingPlanTemplate.sessions.length; i++) {
        const session = trainingPlanTemplate.sessions[i];
        for (let j = 0; j < session.exercises.length; j++) {
          const exercise = session.exercises[j];
          if (exercise.id) {
            let firebaseData = this.convertExerciseToFirebaseData(j, session, exercise);
            await this.firestore
              .collection(`TrainingPlanTemplates/${trainingPlanTemplate.id}/PlannedTrainingExercises/`)
              .doc(exercise.id)
              .update(this.convertExerciseToFirebaseData(j, session, exercise));
          } else {
            await this.firestore
              .collection(`TrainingPlanTemplates/${trainingPlanTemplate.id}/PlannedTrainingExercises/`)
              .add(this.convertExerciseToFirebaseData(j, session, exercise));
          }
        }
      }

      await this.userService.setNotExistingGlobalTrainingVariables(trainingPlanTemplate.trainingVariables);

      this.trainingPlanTemplates = []
      // this.trainingPlanTemplates = this.trainingPlanTemplates.map(x => x.id == trainingPlanTemplate.id ? trainingPlanTemplate : x);
    } catch (error) {
      console.error('Error updating training plan template:', error);
    }
  }

  private convertTrainingPlanTemplateToFirebaseData(trainingPlanTemplate: TrainingPlan): unknown {
    return {
      timestamp: new Date(),
      name: trainingPlanTemplate.name,
      startDate: trainingPlanTemplate.startDate,
      nameTranslation: trainingPlanTemplate.nameTranslation.AsMap(),
      description: trainingPlanTemplate.description,
      descriptionTranslation: trainingPlanTemplate.descriptionTranslation.AsMap(),
      coachUid: trainingPlanTemplate.coachUid,
      licenceHolderUid: trainingPlanTemplate.licenceHolderUid,
      sessions: trainingPlanTemplate.sessions.map(x => x.asFirebaseMap()),
      hintTranslation: trainingPlanTemplate.hintTranslation.AsMap(),
      subNameTranslation: trainingPlanTemplate.subNameTranslation.AsMap(),
      folderIds: trainingPlanTemplate.folderIds,
      assignedUids: trainingPlanTemplate.assignedUids,
      assignedGroupNames: trainingPlanTemplate.assignedGroupNames,
      imagePath: trainingPlanTemplate.imagePath,
      durationInDays: trainingPlanTemplate.durationInDays,
      userEditable: trainingPlanTemplate.userEditable,
      deleted: trainingPlanTemplate.deleted,
      access: trainingPlanTemplate.access,
      isPeriodicPlan: trainingPlanTemplate.isPeriodicPlan,
      trainingVariables: trainingPlanTemplate.trainingVariables.map(x => x.asMap()),
      weeks: trainingPlanTemplate.weeks.map(x => x.asMap()),
    }
  }

  private convertExerciseToFirebaseData(position: number, session: TrainingSession, exercise: PlannedTrainingExercise): unknown {
    var map = {
      timestamp: new Date(),
      deleted: exercise.deleted,
      exerciseId: exercise.exerciseId,
      note: exercise.note,
      position: position,
      sessionId: session.id,
      sets: exercise.sets.map(x => x.asMap()),
      pauseDuration: exercise.pauseDuration,
      connectedSuperSetExercise: exercise.connectedSuperSetExercise || false,
      superSetConfig: exercise.superSetConfig?.asFirebaseMap() || null,
      setParameters: exercise.setParameters.map(x => x.toString()),
      groupHeading: exercise.groupHeading,
      groupHeadingTranslation: exercise.groupHeadingTranslation?.AsMap() || null,
      videoRecordingRequest: exercise.videoRecordingRequest?.asMap(),
      autoRun: exercise.autoRun,
      alternativeExerciseId: exercise.alternativeExerciseId,
    }
    if (exercise.exertionUnit != null && exercise.exertionUnit != undefined) {
      map['exertionUnit'] = exercise.exertionUnit
    }
    return map
  }

  logout() {
    this.trainingPlanTemplates = []
  }
}
