import { BaseNutritionFact, NutritionalValue, NutritionalValueHolder } from './basenutritionfact.model';
import { NutritionalSummary } from './nutritionalsummary.model';
import { Serving } from './serving.model';

export class Ingredient implements NutritionalValueHolder {
    id: string;
    weight: number;
    nutritionFactId: string;
    nutritionFact: BaseNutritionFact;
    nutritionalSummary: NutritionalSummary;
    name: string;
    servingSize: string;
    isDummy: boolean;
    position: number
    groupHeading: string
    allowWeightAdjustment: boolean

    tmpId: string;

    constructor();
    constructor(init: Ingredient);
    constructor(init?: Ingredient) {
        this.id = init && init.id || null
        this.weight = init && init.weight || 0
        this.nutritionFact = init && init.nutritionFact || null
        this.nutritionFactId = init && init.nutritionFactId || null
        if (init && this.nutritionFactId == null && (init as any).baseNutritionFactId) this.nutritionFactId = (init as any).baseNutritionFactId
        this.nutritionalSummary = init && init.nutritionalSummary || null
        this.name = init && init.name || null
        this.servingSize = init && init.servingSize || null
        //this.unit = init && init.unit || null
        this.isDummy = init && init.isDummy || false
        this.tmpId = init && init.tmpId || null
        this.position = init && init.position || 0
        this.groupHeading = init && init.groupHeading || null
        if (this.isDummy && init) {
            this.nutritionalSummary = new NutritionalSummary()
            this.nutritionalSummary.carbohydrates = (init as any).carbohydrates ?? 0
            this.nutritionalSummary.protein = (init as any).protein ?? 0
            this.nutritionalSummary.fat = (init as any).fat ?? 0
            this.nutritionalSummary.calories = (init as any).calories ?? 0
        }
        this.allowWeightAdjustment = init && init.allowWeightAdjustment || false
    }

    clone() {
        var ingredient = new Ingredient()
        ingredient.id = this.id
        ingredient.weight = this.weight
        ingredient.nutritionFact = this.nutritionFact
        ingredient.nutritionFactId = this.nutritionFactId
        if (this.nutritionalSummary) ingredient.nutritionalSummary = this.nutritionalSummary.clone()
        ingredient.isDummy = this.isDummy
        ingredient.name = this.name
        ingredient.servingSize = this.servingSize
        ingredient.tmpId = this.tmpId
        ingredient.position = this.position
        ingredient.groupHeading = this.groupHeading
        ingredient.allowWeightAdjustment = this.allowWeightAdjustment
        return ingredient
    }

    getServing() {
        if (!this.servingSize || this.servingSize.length == 0) return null;
        return Serving.servingFromString(this.servingSize)
    }

    getUnit() {
        return this.nutritionFact?.getUnit() || 'g'
    }

    getName() {
        return this.nutritionFact?.nameDe || this.isDummy && "Dummy" || ""
    }

    getNutritionalValue(nutritionalValue: string): number {
        if (nutritionalValue == 'carbohydrates') {
            return this.nutritionalSummary ? this.nutritionalSummary?.carbohydrates : this.weight * this.nutritionFact?.getNutritionalValue(nutritionalValue) / 100 || 0
        } else if (nutritionalValue == 'protein') {
            return this.nutritionalSummary ? this.nutritionalSummary?.protein : this.weight * this.nutritionFact?.getNutritionalValue(nutritionalValue) / 100 || 0
        } else if (nutritionalValue == 'fat') {
            return this.nutritionalSummary ? this.nutritionalSummary?.fat : this.weight * this.nutritionFact?.getNutritionalValue(nutritionalValue) / 100 || 0
        } else if (nutritionalValue == 'calories') {
            return this.nutritionalSummary ? this.nutritionalSummary?.calories : this.weight * this.nutritionFact?.getNutritionalValue(nutritionalValue) / 100 || 0
        }
        return this.weight * this.nutritionFact?.getNutritionalValue(nutritionalValue) / 100 || 0
    }
    getCarbohydrates() {
        return this.getNutritionalValue('carbohydrates')
    }
    getProtein() {
        return this.getNutritionalValue('protein')
    }
    getFat() {
        return this.getNutritionalValue('fat')
    }
    getCalories() {
        return this.getNutritionalValue('calories')
    }

    toMap() {
        if (!this.isDummy) {
            return {
                baseNutritionFactId: this.nutritionFactId, weight: this.weight, servingSize: this.servingSize || null, isDummy: false, position: this.position, groupHeading: this.groupHeading, allowWeightAdjustment: this.allowWeightAdjustment
            }
        } else {
            return {
                baseNutritionFactId: null, weight: this.weight || null, name: this.name || null, servingSize: this.servingSize || null, isDummy: true, carbohydrates: this.nutritionalSummary.carbohydrates || null, protein: this.nutritionalSummary.protein || null, fat: this.nutritionalSummary.fat || null, calories: this.nutritionalSummary.calories || null, position: this.position, groupHeading: this.groupHeading
            }
        }
    }
}