<div class="container">
    <div class="row">
        <div class="col-12 textalign-center padding-25">
            <div class="spacer-25"></div>
            <h2>Melde dich an, um loszulegen. 💪</h2>
            Erstelle einen Account für meine Coaching-App oder melde dich mit deinem bestehenden Account an.
            <div class="spacer-40"></div>
            <div *ngIf="!user" class="signup-wrapper layout-box sub-box">
                <h3>Anmelden oder Account erstellen</h3>
                <div class="spacer-10"></div>
                <div class="">
                  <app-sign-up></app-sign-up>
                </div>
            </div>
            <div *ngIf="user" class="user-selection">
                <div class="profile-container">
                    <div class="profile-picture">
                        <button class="profile-button" title="Profilbild hochladen">
                            <img draggable="false" src="{{user.profilePictureUrl}}">
                        </button>
                    </div>
                    <h3 class="name">{{user?.getName()}}</h3>
                </div>
                <div class="spacer-10"></div>
                Nicht du?<br>
                <div class="spacer-10"></div>
                <button class="button-filled small white" id="logout-button" (click)="onLogout()">Abmelden</button>
            </div>
            <div class="spacer-25"></div>
            
            <div class="col-12 textalign-center" *ngIf="!userSelected && user && canContinue()">
                <button class="button-filled small" (click)="onSelectUser()">Als {{user.getName()}} fortfahren</button>
            </div>

            <div class="col-12" *ngIf="user && !canContinue()">
                <div class="textalign-center"><h2>Du kannst mit diesem Account nicht auf das Angebot zugreifen.</h2></div>
            </div>
            <div class="spacer-25"></div>
        </div>
    </div>
</div>