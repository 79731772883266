import { Component, Input } from '@angular/core';
import { DashboardComponent } from 'src/app/dashboard/dashboard-v1/dashboard.component';
import { Note } from 'src/app/model/note.model';
import { User } from 'src/app/model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import * as marked from 'marked';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent {

  constructor(private userService: FirestoreService) { }

  public user: User
  public editableNote: Note = new Note()

  compiledMarkdown: string;
  editorValue: string;
  editorPlaceholder = 'Neue Notiz...';

  @Input() set setUser(value: User) {
    this.user = value
  }

  onEditNote(note: Note) {
    this.editableNote = note.clone();
    (<HTMLInputElement> document.getElementById('editor-textarea')).value = this.editableNote.content
  }
  onEditorValueChanged(value: string) {
    if (value == this.editorPlaceholder || value?.length == 0) {
      this.editableNote.content = null
    } else {
      this.editableNote.content = value
    }
    this.compiledMarkdown = this.compileMarkdown(value);
  }
  compileMarkdown(value: string): string {
    if (value == null) return ""
    return marked.parser(marked.lexer(value.replace(/\n/g, '<br>')));
  }
  onSelectAttachment() {
    document.getElementById('input-note-attachment').click()
  }
  onRemoveSelectedFile() {
    this.editableNote.attachment = null
    this.editableNote.attachedFileName = null
  }
  onFileSelected(e) {
    let file = e.target.files[0];
    this.editableNote.attachment = file
    this.editableNote.attachedFileName = file.name
  }
  onSaveNote() {
    if (this.editableNote.id == null) {
      this.editableNote.date = new Date()
      this.userService.insertNoteForUser(this.editableNote, this.user)
      DashboardComponent.delay(1000).then(() => {
        var notes = document.getElementById("notes-wrapper")
        try {
          notes.scrollTop = notes.scrollHeight;
        } catch(err) { }
      })
    } else {
      if ((this.editableNote.content == null || this.editableNote.content.length == 0) && this.editableNote.attachedFileName == null) {
        this.userService.deleteNoteForUser(this.editableNote, this.user)
      } else {
        this.userService.updateNoteForUser(this.editableNote, this.user)
      }
    }
    this.editableNote = new Note();
    (<HTMLInputElement> document.getElementById('editor-textarea')).value = ""
  }
}
