<div class="layout-box">
    <div class="row">
        <div class="col">
            <h3>Trainingshistorie</h3>
        </div>
    </div>
    <div class="row">
        <div class="col-12 calendar-box">
            <app-monthly-calendar [isCollapsible]="false" [calendarItems]="calendarItems"
                (selectedDayChanged)="onSelectedDayChanged($event)" [selectedDay]="selectedCalendarDay"
                (currentMonthChanged)="onSelectedCalendarMonthChanged($event)" [isBusy]="isBusy"
                [endDate]="endDate"></app-monthly-calendar>
        </div>
        <div class="col-12 sessions-list">
            <div class="row">
                <div class="col">
                    <div class="row" *ngIf="selectedTrainingHistoryData?.TrackedTrainingSessions?.length > 0 || selectedTrainingHistoryData?.TrackedActivities?.length > 0">
                        <div class="selected-date">{{selectedTrainingHistoryData?.date.asFormatedString()}}</div>
                    </div>
                    <div class="row mt-2" *ngIf="selectedTrainingHistoryData?.TrackedTrainingSessions?.length > 0">
                        <div class="col-auto label padding-4">Einheiten</div>
                        <div class="icon-button marginleft-25 padding-4"
                            (click)="onOpenSelectedTrackedTraininsessions()"
                            tooltip="Markiere die Einheiten, die du vergleichen möchtest, mit einem Haken.">
                            Ausgewählte Einheiten vergleichen
                        </div>
                    </div>
                    <div class="row"
                        *ngFor="let trackedSession of selectedTrainingHistoryData?.TrackedTrainingSessions;let i=index;">
                        <div class="col-12">
                            <div class="spacer-10"></div>
                            <div class="row layout-box sub-box link"
                                (click)="onOpenTrackedTrainingSession(trackedSession)">
                                <div class="checkbox-wrapper d-inline-block"
                                    (click)="onTrackedTrainingSessionSelected(trackedSession);$event.stopPropagation()">
                                    <input class="input checkbox margin-vertical-auto h-100" type="checkbox"
                                        [checked]="selectedTrackedTrainingSessions.includes(trackedSession)"
                                        tooltip="Zum Vergleich hinzufügen">
                                </div>
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <b>{{trackedSession?.sessionName ||
                                                getPlannedSession(trackedSession)?.name}}</b>
                                            <div class="indicator small marginleft-10 display-inlineblock"
                                                *ngIf="trackedSession.viewedByCoach == false"
                                                tooltip="Du hast dieses Training noch nicht angesehen."
                                                placement="right"></div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col label">
                                            {{trackedSession.trackedTrainingExercises?.length}} Übungen /
                                            {{getNumberOfSets(trackedSession)}} Sätze
                                        </div>
                                    </div>
                                </div>
                                <div class="float-right margin-vertical-auto">
                                    <div class="icon-button marginright-10">
                                        Öffnen
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="selectedTrainingHistoryData?.TrackedActivities?.length > 0" class="row mt-2">
                        <div class="col-12 label padding-4">
                            Aktivitäten
                        </div>
                    </div>
                    <div class="row" *ngFor="let trackedActivity of selectedTrainingHistoryData?.TrackedActivities;let i=index;">
                        <div class="col-12">
                            <div class="spacer-10"></div>
                            <div class="row layout-box sub-box {{user.spikeUserId || trackedActivity.heartRate ? 'link' : ''}}" (click)="onOpenTrackedActivity(trackedActivity)">
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <b>{{trackedActivity.name}}</b>
                                            <!-- <div class="indicator small marginleft-10 display-inlineblock" *ngIf="trackedSession.viewedByCoach == false" tooltip="Du hast dieses Training noch nicht angesehen." placement="right"></div> -->
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col label">
                                            {{trackedActivity.duration}} min
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="user.spikeUserId || trackedActivity.heartRate" class="float-right margin-vertical-auto">
                                    <div class="icon-button marginright-10">
                                        Öffnen
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ngx-spinner bdColor="rgba(0,0,0,0.33)" size="default" color="var(--accentColor)" type="ball-clip-rotate"
        [fullScreen]="true"></ngx-spinner>
</div>