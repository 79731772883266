import { TrainingplanExportService } from './../../services/trainingplan-export.service';
import { TrainingPlanEditorComponent } from './../training-plan-editor/training-plan-editor.component';
import { UtilityService } from './../../services/utility.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { TrainingService } from 'src/app/services/training.service';
import { TrainingPlan } from './../../model/training-plan.model';
import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/model/user.model';
import { FirestoreNutritionPlanService } from 'src/app/services/firestore-nutritionplan.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification.service';
import { PastePlanTemplateDialogComponent } from 'src/app/paste-plantemplate-dialog/paste-plantemplate-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { LanguageService } from 'src/app/services/language.service';
import { ToastrService } from 'ngx-toastr';
import { VisualSelectionDialogComponent } from 'src/app/dialogs/visual-selection-dialog/visual-selection-dialog.component';
import { TrainingVariableEditorDialogComponent, TrainingVariableEditorDialogType } from '../training-variable-editor-dialog/training-variable-editor-dialog.component';
import { BehaviorSubject } from 'rxjs';
import { TrainingPlanEditorHelper } from '../training-plan-editor/utilities/training-plan-editor-helper';

@Component({
  selector: 'app-user-training-plan-overview',
  templateUrl: './user-training-plan-overview.component.html',
  styleUrls: ['./user-training-plan-overview.component.css']
})
export class UserTrainingPlanOverviewComponent implements OnInit {

  public user: User;
  public coach: User;

  public trainingPlanEditorHelper: TrainingPlanEditorHelper
  public selectedTemplateTab: string = "OWN"

  @Input() set selectedUser(user: User){
    this.user = user
  }

  constructor(public dialog: MatDialog, public trainingService: TrainingService, public userService: FirestoreService, public utilityService: UtilityService, private spinner: NgxSpinnerService, public notificationService: NotificationService, private trainingPlanExportService: TrainingplanExportService, private languageSerivce: LanguageService, private toastr: ToastrService) {
    this.trainingPlanEditorHelper = new TrainingPlanEditorHelper(trainingService, dialog, spinner, userService, toastr);
  }

  ngOnInit(): void {
    this.notificationService.showBadge = false
    this.notificationService.showComposer = false
    this.coach = this.userService.getLoggedInUser()

    this.spinnerText = null;
  }

  public showTrainingPlanTemplates: boolean = false;
  public showTrainingPlanCopies: boolean = false;
  public filteredTrainingPlanTemplates: TrainingPlan[];

  public selectedTrainingPlan: TrainingPlan;
  public showAllTrainingPlanConfigs: boolean = false

  onShowAllTrainingPlanConfigs(value: boolean){
    this.showAllTrainingPlanConfigs = value;
  }

  public searchInput: string

  onTemplateSearchInputChanged(text: string) {
    this.searchInput = text
    this.updateFilteredTemplates()
  }

  isCoacheeTrainingPlanCreationEnabled() {
    if (this.user.coacheeTrainingPlanCreationEnabled != null) return this.user.coacheeTrainingPlanCreationEnabled
    return this.coach?.isCoacheeTrainingPlanCreationEnabled()
  }
  onEnableCoacheeTrainingPlanCreationEnabledChanged(value: boolean) {
    this.user.coacheeTrainingPlanCreationEnabled = value
    this.userService.updateLicenceSettings(this.user)
  }
  async updateFilteredTemplates() {
    if (!this.searchInput || this.searchInput.length === 0) {
      this.filteredTrainingPlanTemplates = await this.trainingService.getTrainingPlanTemplates();
    } else {
      this.filteredTrainingPlanTemplates = 
        (await this.trainingService.getTrainingPlanTemplates())
          .filter(template => template.getName()?.toLowerCase().includes(this.searchInput.toLowerCase()));
    }
  }

  async onDeleteTemplateSearchInput() {
    this.searchInput = null;
    (<HTMLInputElement> document.getElementById('templatesearch-input')).value = ''
    this.filteredTrainingPlanTemplates = await this.trainingService.getTrainingPlanTemplates()
  }

  onShowTrainingPlanCopies(){
    this.showTrainingPlanCopies = true
    this.showTrainingPlanTemplates = false
  }
  onHideTrainingPlanCopies(){
    this.showTrainingPlanCopies = false
  }
  
  async onShowTrainingPlanTemplates(){
    this.onDeleteTemplateSearchInput()
    this.showTrainingPlanTemplates = true
    this.showTrainingPlanCopies = false
  }
  onHideTrainingPlanTemplates(){
    this.showTrainingPlanTemplates = false
  }

  onSelectTemplateTab(tabName: string){
    this.selectedTemplateTab = tabName
  }


  hasDeprecatedPlans(plans: TrainingPlan[]):boolean{
    return plans.filter(plan => plan.isDeprecated).length > 0
  }

  draggedTemplate: TrainingPlan
  draggingDeprecatedTemplate = false
  draggingTemplate = false
  onDragStartDeprecatedTemplate(event, template: any) {
    this.draggedTemplate = template
    this.draggingTemplate = true
    this.draggingDeprecatedTemplate = true
    event.dataTransfer.setData('text', template.id)
    event.dataTransfer.effectAllowed = 'move'
  }
  onDragStartPlanningTemplate(event, template: any) {
    this.onDragStartTrainingPlanTemplate(template)
    event.dataTransfer.setData('text', template.id)
    event.dataTransfer.effectAllowed = 'move'
  }
  
  onDragEndTemplate(event, template: any) {
    this.draggedTemplate = null
    this.draggingTemplate = false
  }
  onDragOverTemplateDropzone(event) {
    event.preventDefault()
  }

  onDragStartTrainingPlanTemplate(template: TrainingPlan){
    this.draggedTemplate = template
    this.draggingTemplate = true
    this.draggingDeprecatedTemplate = false
  }


  async onDropOnTempateDropzone(event) {
    event.preventDefault()
    if (!this.draggingTemplate) return
    await this.trainingPlanTemplateSelected(this.draggedTemplate)
  }

  async trainingPlanTemplateSelected(trainingPlanTemplate: TrainingPlan){
    await this.trainingService.getTrainingPlanTemplatePlannedExercises(trainingPlanTemplate);
    var draggedTemplate: TrainingPlan = await this.trainingPlanEditorHelper.clonePlan(trainingPlanTemplate, this.user, this.coach)
    if(draggedTemplate == null) return
    draggedTemplate.isTemplate = false
    await this.saveTrainingPlan(draggedTemplate, false)
  }

  async selectTrainingPlanTemplate(template: TrainingPlan){
    var clonedTrainingPlan: TrainingPlan = await this.trainingPlanEditorHelper.clonePlan(template, this.user, this.coach)
    if(clonedTrainingPlan != null) {
      await this.saveTrainingPlan(clonedTrainingPlan, false)
    }

    this.onHideTrainingPlanCopies()
    this.onHideTrainingPlanTemplates()
    this.draggedTemplate = null
    this.draggingTemplate = false
  }

  setDate(date: Date):Date {
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    return date
  }

  async onDuplicateTrainingPlanConfig(trainingPlan:TrainingPlan){
    var newPlan: TrainingPlan = await this.trainingPlanEditorHelper.clonePlan(trainingPlan, this.user, this.coach)
    if(newPlan == null) return
    await this.saveTrainingPlan(newPlan, false)
  }

  onDeleteTrainingPlan(trainingPlan:TrainingPlan) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du diesen Trainingsplan wirklich löschen?', title: 'Trainingsplan löschen' },
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        await this.deleteTrainingPlan(trainingPlan)
      }
    })
  }


  
  onCreateTrainingPlan(){
    this.selectedTrainingPlan = new TrainingPlan();
    this.selectedTrainingPlan.startDate = this.setDate(new Date());
    this.coach = this.userService.getLoggedInUser()

    const visualSelectionDialogRef = this.dialog.open(VisualSelectionDialogComponent, {
      data: { title: 'Wähle den gewünschten Plantyp' }
    });
    visualSelectionDialogRef.afterClosed().subscribe(result => {
      if(result){
        if(result.isPeriodic == true) {
          this.selectedTrainingPlan.isPeriodicPlan = true;
        }
        const dialogRef = this.dialog.open(TrainingPlanEditorComponent, { data: { trainingPlan: this.selectedTrainingPlan, coach: this.userService.getLoggedInUser(), user: this.user}, width: '1000px', autoFocus: false})
        dialogRef.afterClosed().subscribe(async result => {
          if(result){
            if(result.save){
              await this.saveTrainingPlan(this.selectedTrainingPlan, result.removeThumbnail, result.newThumbnail)
            }
            else {
              this.cancelEditTrainingPlan()
            }
          }
        });
      }
    });

  }
  cancelEditTrainingPlan(){
    this.selectedTrainingPlan = null
  }
  onEditTrainingPlan(trainingPlan: TrainingPlan){
    this.selectedTrainingPlan = trainingPlan.clone()
    this.coach = this.userService.getLoggedInUser()
    const dialogRef = this.dialog.open(TrainingPlanEditorComponent, { data: { trainingPlan: this.selectedTrainingPlan, coach: this.userService.getLoggedInUser(), user: this.user}, width: '1000px', autoFocus: false})
    dialogRef.afterClosed().subscribe(async result => {
      if (result){
        if (result.save) {
          await this.saveTrainingPlan(this.selectedTrainingPlan, result.removeThumbnail, result.newThumbnail)
        } else if (result.delete) {
          await this.deleteTrainingPlan(this.selectedTrainingPlan)
        } else {
          this.cancelEditTrainingPlan()
        }
        await this.userService.updateTrainingPlanEndDateInMetadata(this.user)
      }
    })
  }  

  public spinnerText = null
  updateTrainingplanSaveSpinnerText(progress: string){
    this.spinnerText = 'Trainingsplan speichern (' + progress + ')'
  }
  async saveTrainingPlan(plan:TrainingPlan, removeThumbnail: boolean, thumbnailImage?: File){
    this.spinner.show()
    try{
      let progressBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>("0%");
      progressBehaviorSubject.subscribe(value => {
        this.updateTrainingplanSaveSpinnerText(value);
      });
      if(plan.id?.length > 0){
        await this.userService.updateTrainingPlan(this.user, this.coach, plan, removeThumbnail, thumbnailImage, progressBehaviorSubject)
        this.notificationService.composeTrainingPlanUpdateNotification()
      } else{
        await this.userService.saveTrainingPlan(this.user, this.coach, plan, removeThumbnail, thumbnailImage, progressBehaviorSubject)
        this.notificationService.composeTrainingPlanCreationNotification()
      }
      this.userService.updateTrainingPlanEndDateInMetadata(this.user)
    }
    catch(ex){
      console.error(ex);
      this.toastr.error("Beim Speichern des Trainingsplans ist ein Fehler aufgetreten. Bitte versuche es erneut.", "Fehler beim Speichern",  {
        positionClass: 'toast-bottom-center'
      });
    }
    finally{
      this.spinner.hide();
      this.spinnerText = null;
      this.selectedTrainingPlan = null
    }
  }

  async deleteTrainingPlan(plan:TrainingPlan){
    this.spinner.show()
    if(plan.id?.length > 0){
      plan.deleted = true
      await this.userService.updateTrainingPlan(this.user, this.coach, plan, true);
      await this.userService.updateTrainingPlanEndDateInMetadata(this.user)
    }
    this.spinner.hide();
    this.selectedTrainingPlan = null
  }

  openTrainingVariablesDialog(){
    let trainingVariables = this.user.trainingVariables;
    let globalForeignVariabels = this.userService.getLoggedInUser()?.trainingSettingsLicenceHolder?.trainingVariables?.filter(x => !trainingVariables?.find(y => y?.id == x?.id));
    let dialogRef = this.dialog.open(TrainingVariableEditorDialogComponent, {width: '800px', data: { user: this.user, trainingVariables: trainingVariables?.map(x => x.clone()), trainingVariableEditorDialogType: TrainingVariableEditorDialogType.user, foreignVariables: globalForeignVariabels}});
    dialogRef.afterClosed().subscribe(async result => {
      if(result){
        try{
          this.spinner.show()
          await this.userService.updateTrainingVariables(this.user, result.trainingVariables);
        }
        catch(ex){
          console.error(ex);
        }
        finally{
          this.spinner.hide();
        }
      }
    });
  }

}