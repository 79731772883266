import { ClientComponent } from '../client/client-v1/client-v1.component';
import { Day } from './day.model';
import { NutritionalGoal } from './nutritionalgoal.model';

export class NutritionPlan {
    public weekDays = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];

    id: string;
    name: string;
    receiverUid: string;
    creatorUid: string;
    startDate: Date;
    duration: number;
    mealsPerDay: number;
    customMealTypes: string[];
    distributed: boolean;
    referenceNutritionalGoal: NutritionalGoal;
    days: Day[]
    isTemplate: boolean;
    expiringNutritionPlanHintDismissed: boolean
    applied: boolean

    constructor();
    constructor(init: NutritionPlan);
    constructor(init?: NutritionPlan) {
        this.id = init && init.id || null
        this.name = init && init.name || "Plan"
        this.receiverUid = init && init.receiverUid || null
        this.creatorUid = init && init.creatorUid || null
        this.startDate = init && init.startDate || new Date()
        this.duration = init && init.duration || 0
        this.mealsPerDay = init && init.mealsPerDay || 0
        this.referenceNutritionalGoal = init && init.referenceNutritionalGoal || null
        this.days = init && init.days || null
        this.customMealTypes = init && init.customMealTypes || []
        this.distributed = init && init.distributed || false
        this.isTemplate = init && init.isTemplate || false
        this.applied = init && init.applied || false
        this.expiringNutritionPlanHintDismissed = init && init.expiringNutritionPlanHintDismissed || false
    }

    getCarbohydrates() {
        return this.referenceNutritionalGoal?.carbohydrates || 0
    }
    getProtein() {
        return this.referenceNutritionalGoal?.protein || 0
    }
    getFat() {
        return this.referenceNutritionalGoal?.fat || 0
    }
    getCalories() {
        return this.referenceNutritionalGoal?.calories || this.calculateCalories()
    }

    calculateCalories() {
        return (this.getCarbohydrates() + this.getProtein()) * 4.1 + this.getFat() * 9.3;
    }

    getPrintableStartDate() {
        return this.weekDays[this.startDate.getDayNumber()] + ', ' + this.startDate.getDate() + '.' + (this.startDate.getMonth() + 1) + '.' + this.startDate.getFullYear()
    }
    getPrintableEndDate() {
        var endDate = this.getEndDate()
        return this.weekDays[endDate.getDayNumber()] + ', ' + endDate.getDate() + '.' + (endDate.getMonth() + 1) + '.' + endDate.getFullYear()
    }
    getEndDate(): Date {
        var endDate = new Date(this.startDate.getTime())
        endDate.setDate(endDate.getDate() + this.duration - 1)
        return endDate
    }
    isOver() {
        return this.getEndDate().getTime() < Date.now() - 1000*60*60*24
    }
    isRunning() {
        return this.startDate.getTime() < Date.now() && !this.isOver()
    }

    clone() {
        var nutritionPlan = new NutritionPlan(this)
        nutritionPlan.id = this.id
        nutritionPlan.name = this.name
        nutritionPlan.receiverUid = this.receiverUid
        nutritionPlan.creatorUid = this.creatorUid
        nutritionPlan.startDate.setTime(this.startDate.getTime())
        nutritionPlan.duration = this.duration
        nutritionPlan.mealsPerDay = this.mealsPerDay
        nutritionPlan.customMealTypes = this.customMealTypes.slice(0)
        nutritionPlan.referenceNutritionalGoal = this.referenceNutritionalGoal?.clone()
        nutritionPlan.distributed = this.distributed
        if (this.days) {
            nutritionPlan.days = []
            this.days.forEach(day => nutritionPlan.days.push(day.clone()))
        }
        return nutritionPlan
    }
}