import { LanguageDictionary } from './languagedictionary.model';
import { TrainingService } from '../services/training.service';
import { FirestoreNutritionPlanService } from './../services/firestore-nutritionplan.service';
import { Timestamp } from '@angular/fire/firestore';
import { TrainingVariable } from './training-variables.model';
import { TrackedTrainingExercise, TrackedTrainingSession } from './training-monitoring.model';
import { TrainingPlanEditorComponent } from '../training/training-plan-editor/training-plan-editor.component';

export class TrainingPlan {
    public id: string;
    public get name() {
        return this.nameTranslation.de;
    }
    public set name(value: string) {
        this.nameTranslation.de = value;
    }
    public nameTranslation: LanguageDictionary<string> = new LanguageDictionary<string>();
    public subNameTranslation: LanguageDictionary<string> = new LanguageDictionary<string>();
    public deleted: boolean;
    public startDate: Date;
    public endDate: Date;
    public sessions: TrainingSession[] = []

    public isTemplate: boolean;
    public coachUid: string
    public licenceHolderUid: string
    public get description() {
        return this.descriptionTranslation.de;
    }
    public set description(value: string) {
        this.descriptionTranslation.de = value;
    }
    public descriptionTranslation: LanguageDictionary<string> = new LanguageDictionary<string>();

    public imagePath: string;
    public imageDownloadURL: string;

    public userEditable: boolean;

    public custom: boolean;

    //#region only for templates
    public assignedUids: string[];
    public assignedGroupNames: string[];
    public folderIds: string[];

    public durationInDays: number;

    //#endregion

    get isDeprecated(): boolean {
        return this.endDate && (new Date() > this.endDate)
    }

    public hintTranslation: LanguageDictionary<string> = new LanguageDictionary<string>();

    public access: string;

    public isPeriodicPlan: boolean = false;
    public trainingVariables: TrainingVariable[] = [];
    public weeks: Week[] = [];



    isOlderThanDate(date: Date) {
        return this.endDate && (this.endDate < date && !this.endDate.isSameDate(date))
    }
    isOlderThanToday() {
        return this.isOlderThanDate(new Date())
    }

    getWeekNumberForDate(date: Date) {
        let lastDayOfWeek = new Date(this.startDate);
        lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);
        let currentDay = new Date(date);
        let week = 0;
        while (currentDay > lastDayOfWeek && !currentDay.isSameDate(lastDayOfWeek)) {
            week++;
            lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 7);
        }
        return week;
    }

    getWeekForDate(date: Date) {
        let weekNumber = this.getWeekNumberForDate(date);
        if (weekNumber <= this.weeks.length) {
            return this.weeks[weekNumber];
        }
        return null;
    }

    getWeekIdForDate(date: Date) {
        return this.getWeekForDate(date)?.id;
    }

    getWeekNumbersList(startDate: Date = this.startDate ?? new Date(0), endDate: Date = this.endDate): Week[] {
        let weekIds = this.sessions.filter(x => !x.deleted).map(x => x.weekId).filter((value, index, self) => self.indexOf(value) === index);

        let weeks = this.weeks.filter(x => weekIds.includes(x.id));
        // let weekNumbers = this.sessions.filter(x => !x.deleted).map(x => x.weekId).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => a - b);

        if (startDate && endDate) {
            let diffTime = Math.abs(endDate.getTime() - startDate.getTime());
            let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
            let diffWeeks = Math.ceil(diffDays / 7);
            if (weeks.length < diffWeeks) {
                for (let i = weeks.length; i < diffWeeks; i++) {
                    let week = new Week()
                    week.name = new LanguageDictionary<string>("W " + (i), "W " + (i))
                    weeks.push(week)
                }
            }
        }
        else {
            let diffWeeks = 2;
            if (weeks.length < diffWeeks) {
                for (let i = 0; i < diffWeeks; i++) {
                    if (weeks.length < i) {
                        let week = new Week()
                        week.name = new LanguageDictionary<string>("W " + (i), "W " + (i))
                        weeks.push(week)
                    }
                }
            }
            // if(weekIds.length < diffWeeks){
            //     for(let i = 0; i < diffWeeks; i++){
            //         if(weekIds.indexOf(i) == -1){
            //             weekIds.push(i);
            //         }
            //     }
            // }
        }
        return weeks;
    }

    initWeeks(startDate: Date = this.startDate ?? new Date(0), endDate: Date = this.endDate) {
        // this.weeks = []
        let weekNumbers = this.getWeekNumbersList(startDate, endDate);


        let weekDiff = weekNumbers.length - this.weeks.length;
        if (weekDiff > 0) {
            for (let i = 0; i < weekDiff; i++) {
                let week = new Week()
                week.id = FirestoreNutritionPlanService.generateUniqueString(8)
                this.weeks.push(week)
            }
        }
        else if (weekDiff < 0) {
            this.weeks = this.weeks.slice(weekNumbers.length - 1, this.weeks.length);
        }
    }

    getSessionsByWeekId(weekId: string): TrainingSession[] {
        let sessions = this.sessions.filter(x => !x.deleted && x.weekId == weekId);
        return sessions;
    }

    getSessionsByDate(date: Date, weekId: string) {
        if (date == null) {
            let sessions = this.sessions.filter(x => !x.deleted && x.weekId == weekId && x.plannedDate == null);
            return sessions;
        }
        let sessions = this.sessions.filter(x => !x.deleted && x.weekId == weekId && x.plannedDate != null && x.plannedDate.isSameDate(date));
        return sessions;
    }

    getAvailableSessionsWithoutRestDays(): TrainingSession[] {
        return this.sessions.filter(x => !x.deleted && !x.isRestDay);
    }

    constructor();
    constructor(init: TrainingPlan);
    constructor(init?: TrainingPlan) {
        this.id = init && init.id || undefined
        this.name = init && init.name || ""
        this.nameTranslation = init && init.nameTranslation != null ? new LanguageDictionary<string>(init.nameTranslation.de, init.nameTranslation.en) : new LanguageDictionary<string>(this.name)
        this.subNameTranslation = init && init.subNameTranslation != null ? new LanguageDictionary<string>(init.subNameTranslation.de, init.subNameTranslation.en) : new LanguageDictionary<string>()
        this.deleted = init && init.deleted || false
        this.startDate = init && init.startDate as Date
        if (!this.startDate) {
            this.startDate = new Date(0);
            this.startDate.setHours(0, 0, 0, 0);
        }
        this.endDate = init && init.endDate as Date || null
        this.sessions = init && init.sessions?.map(x => new TrainingSession(x.name, x.id, x.isRestDay, x.exercises, x.deleted, x.weekId, x.plannedDate, x.baseSessionId, x.hide, x.indicatorColor, x.estimatedDurationInMinutes, x.nameTranslation)) || []

        this.isTemplate = init && init.isTemplate || false
        this.coachUid = init && init.coachUid || null
        this.licenceHolderUid = init && init.licenceHolderUid || null
        this.description = init && init.description || ""
        this.descriptionTranslation = init && init.descriptionTranslation != null ? new LanguageDictionary<string>(init.descriptionTranslation.de, init.descriptionTranslation.en) : new LanguageDictionary<string>(this.description)
        this.hintTranslation = init && init.hintTranslation != null ? new LanguageDictionary<string>(init.hintTranslation.de, init.hintTranslation.en) : new LanguageDictionary<string>()

        this.assignedUids = init && init.assignedUids?.map(x => x) || []
        this.assignedGroupNames = init && init.assignedGroupNames?.map(x => x) || []
        this.folderIds = init && init.folderIds?.map(x => x) || []
        this.imagePath = init && init.imagePath || null

        this.imageDownloadURL = init && init.imageDownloadURL || null

        this.userEditable = init && init.userEditable || false
        this.custom = init && init.custom || false

        this.durationInDays = init && init.durationInDays || null
        this.access = init && init.access || TrainingPlanAccess.none

        this.isPeriodicPlan = init && init.isPeriodicPlan || false
        this.trainingVariables = init && init.trainingVariables?.map(x => new TrainingVariable(x)) || []

        this.weeks = init && init.weeks?.map(x => new Week(x)) || []

        this.setDurationInDays()
    }

    setStartDate() {
        if (!this.startDate) {
            this.startDate = new Date(0);
            this.startDate.setHours(0, 0, 0, 0);
        }
        if (this.isPeriodicPlan && this.sessions.length > 0) {
            let firstSession = this.sessions.filter(x => x.plannedDate).sort((a, b) => a.plannedDate?.getTime() - b.plannedDate?.getTime())[0];
            if (firstSession) {
                let plannedDate = firstSession?.plannedDate;
                if (plannedDate && !isNaN(plannedDate?.getTime())) {
                    let hours = plannedDate.getHours();
                    if (hours > 12) {
                        this.startDate.setHours(hours);
                        this.startDate.addDays(-1);
                    }
                    else if (hours > 0) {
                        this.startDate.setHours(hours);
                    }
                }
            }
            // if(firstSession.plannedDate){
            //     this.startDate = new Date(firstSession.plannedDate);
            //     this.startDate.setHours(0,0,0,0);
            // }
        }
    }

    setDurationInDays() {
        try {
            if (this.isPeriodicPlan) {
                let maxWeekNumber = this.weeks?.length;
                this.durationInDays = maxWeekNumber * 7
            }
            else if (this.durationInDays == null) {
                if (this.endDate != null && this.startDate != null) {
                    if (this.startDate instanceof Timestamp) this.startDate = new Date((this.startDate as any).seconds * 1000)
                    if (this.endDate instanceof Timestamp) this.endDate = new Date((this.endDate as any).seconds * 1000)
                    const diffTime = Math.abs(this.endDate.getTime() - this.startDate.getTime());
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                    this.durationInDays = diffDays
                }
            }
        }
        catch (ex) {
            console.error(ex)
        }
    }

    getWeekIndexById(weekId: string): number {
        return this.weeks?.findIndex(x => x.id == weekId) ?? 0
    }

    sortSessionsByWeekAndDate() {
        try {
            this.sessions = this.sessions?.sort((a, b) => a.plannedDate?.getTime() - b.plannedDate?.getTime())?.sort((a, b) => this.getWeekIndexById(a.weekId) - this.getWeekIndexById(b.weekId))
        }
        catch (ex) {
            console.error(ex)
        }
    }

    clone(): TrainingPlan {
        let clonedTrainingPlan = new TrainingPlan(this)
        clonedTrainingPlan.sessions = this.sessions?.map(x => x.clone())
        return clonedTrainingPlan
    }

    getName() {
        return this.name || ''
    }

    getDescription() {
        return this.description || ''
    }

}

export class Week {
    public id: string;
    public hide: boolean;
    public name: LanguageDictionary<string>;
    public note: LanguageDictionary<string>;
    public collapsed: boolean;


    constructor();
    constructor(init: Week);
    constructor(init?: Week) {
        this.id = init && init.id || FirestoreNutritionPlanService.generateUniqueString(8)
        this.hide = init && init.hide || false
        this.name = init?.name != null ? new LanguageDictionary<string>(init.name.de, init.name.en) : new LanguageDictionary<string>()
        this.note = init?.note != null ? new LanguageDictionary<string>(init.note.de, init.note.en) : new LanguageDictionary<string>()
        this.collapsed = init && init.collapsed || false
    }

    clone(): Week {
        return new Week(this)
    }

    asMap(): Record<string, any> {
        return {
            ["id"]: this.id,
            ["hide"]: this.hide,
            ["name"]: this.name.AsMap(),
            ["note"]: this.note.AsMap(),
            ["collapsed"]: this.collapsed
        }
    }
}

export class SuperSet {
    public exercises: PlannedTrainingExercise[] = []
    public isLast: boolean = false
}

export class TrainingSession implements IFirebaseTrainingSession {
    public id: string;
    public deleted: boolean;

    public get name() {
        return this.nameTranslation.de;
    }
    public set name(value: string) {
        this.nameTranslation.de = value;
    }
    public nameTranslation: LanguageDictionary<string> = new LanguageDictionary<string>();

    public isRestDay: boolean;
    public superSets: SuperSet[];

    public estimatedDurationInMinutes: number;


    private _exercises: PlannedTrainingExercise[] = []
    get exercises(): PlannedTrainingExercise[] {
        return this._exercises
    }
    set exercises(value: PlannedTrainingExercise[]) {
        this._exercises = value
        this.initSuperSets()
    }

    public weekId: string;
    public plannedDate?: Date = null;
    public hide: boolean = false;

    public baseSessionId: string = null;

    public indicatorColor: string = null;

    //temp
    public isTracked: boolean = false;
    public trackedTrainingSession: TrackedTrainingSession = null;
    public trackedTrainingSessionList: TrackedTrainingSession[] = null;

    initSuperSets() {
        this.superSets = this.getExercisesBySuperSets()
    }
    getExercisesBySuperSets(): SuperSet[] {
        var connectedSuperSets: SuperSet[] = []
        var superSet: SuperSet = new SuperSet()
        let notDeletedExercises = this.exercises.filter(x => !x.deleted)
        connectedSuperSets.push(superSet);
        for (let i = 0; i < notDeletedExercises.length; i++) {
            if (!notDeletedExercises[i].connectedSuperSetExercise || notDeletedExercises[i].superSetConfig != null) {
                if (superSet.exercises.length > 0) {
                    if (superSet.exercises.length === 1) {
                        superSet.exercises[0].connectedSuperSetExercise = false
                        superSet.exercises[0].superSetConfig = null
                    }
                    else if (superSet.exercises[0].superSetConfig == null) {
                        superSet.exercises[0].superSetConfig = new SuperSetConfig()
                    }
                    superSet = new SuperSet()
                    connectedSuperSets.push(superSet)
                }
            }
            superSet.exercises.push(notDeletedExercises[i])
            if (i >= notDeletedExercises.length - 1) {
                superSet.isLast = true
                if (superSet.exercises.length > 0) {
                    if (superSet.exercises.length === 1) {
                        superSet.exercises[0].connectedSuperSetExercise = false
                        superSet.exercises[0].superSetConfig = null
                    }
                    else if (superSet.exercises[0].superSetConfig == null) {
                        superSet.exercises[0].superSetConfig = new SuperSetConfig()
                    }
                }
            }
        }
        return connectedSuperSets
    }

    constructor(name?: string, id?: string, isRestDay?: boolean, exercises?: PlannedTrainingExercise[], deleted?: boolean, weekId?: string, plannedDate?: Date, baseSessionId?: string, hide?: boolean, indicatorColor?: string, estimatedDurationInMinutes: number = null, nameTranslation: LanguageDictionary<string> = null) {
        this.name = name || ""
        this.nameTranslation = nameTranslation != null ? new LanguageDictionary<string>(nameTranslation.de, nameTranslation.en) : new LanguageDictionary<string>(this.name)
        this.id = id || FirestoreNutritionPlanService.generateUniqueString(8)
        this.isRestDay = isRestDay || false
        this.exercises = exercises || []
        this.deleted = deleted || false

        this.estimatedDurationInMinutes = estimatedDurationInMinutes || null


        this.weekId = weekId !== undefined ? weekId : null
        this.plannedDate = plannedDate ? new Date(plannedDate) : null

        this.baseSessionId = baseSessionId || null;
        this.hide = hide || false;

        this.indicatorColor = indicatorColor || null;

        this.initSuperSets()
        // this.exersices = exercises?.map(x => new PlannedTrainingExercise(x.id, x.sessionId, x.exerciseId, x.note, x.pause, x.sets)) || []
    }

    getExerciseListAsString(trainingService: TrainingService) {
        var text = ''
        this.exercises.forEach(exercise => {
            if (!exercise.deleted) text += trainingService.getExerciseById(exercise.exerciseId)?.name?.GetValue('de') + ', '
        })
        if (text.endsWith(', ')) text = text.substring(0, text.length - 2)
        return text
    }

    clone(): TrainingSession {
        return new TrainingSession(this.name, this.id, this.isRestDay, this.exercises.map(x => x.clone()), this.deleted, this.weekId, this.plannedDate, this.baseSessionId, this.hide, this.indicatorColor, this.estimatedDurationInMinutes, this.nameTranslation)
    }

    asFirebaseMap(): Record<string, any> {
        if(!this.indicatorColor || this.indicatorColor?.length == 0) {
            this.indicatorColor = null;
        }
        return {
            ["id"]: this.id,
            ["name"]: this.name,
            ["isRestDay"]: this.isRestDay,
            ["deleted"]: this.deleted,
            ["estimatedDurationInMinutes"]: this.estimatedDurationInMinutes,
            ["nameTranslation"]: this.nameTranslation.AsMap(),
            ["weekId"]: this.weekId ?? null,
            ["plannedDate"]: this.plannedDate,
            ["baseSessionId"]: this.baseSessionId ?? null,
            ["hide"]: this.hide,
            ["indicatorColor"]: this.indicatorColor,
        }
    }

    getSessionNameTranslationWithFallback(languageCode: string): string {
        if(this.nameTranslation.GetValue(languageCode) != null){
            return this.nameTranslation.GetValue(languageCode);
        }
        return this.nameTranslation.de;
    }
}

export interface IFirebaseTrainingSession {
    id: string;
    name: string;
    isRestDay: boolean;
}


export class PlannedTrainingExercise {
    public id: string;
    public deleted: boolean;
    public exerciseId: string;
    public sessionId: string;
    public note: string;
    public position: number;
    public sets: TrainingSet[] = [];
    public pauseDuration?: number;
    public connectedSuperSetExercise: boolean;
    public superSetConfig: SuperSetConfig;
    public setParameters: SetParameter[] = [];
    public exertionUnit?: string;
    public get groupHeading() {
        return this.groupHeadingTranslation?.de || null;
    }
    public set groupHeading(value: string) {
        if (this.groupHeadingTranslation == null) {
            this.groupHeadingTranslation = new LanguageDictionary<string>();
        }
        this.groupHeadingTranslation.de = value;
    }
    public groupHeadingTranslation: LanguageDictionary<string> = new LanguageDictionary<string>();
    public videoRecordingRequest: VideoRecordingRequest;
    public autoRun: boolean
    public noteUnit: string

    public alternativeExerciseId: string;


    constructor();
    constructor(init: PlannedTrainingExercise);
    constructor(init?: PlannedTrainingExercise) {
        this.id = init && init.id || null
        this.deleted = init && init.deleted || false
        this.exerciseId = init && init.exerciseId || ""
        this.sessionId = init && init.sessionId || ""
        this.note = init && init.note || ""
        this.position = init && init.position || 0
        this.sets = init && init.sets?.map(x => new TrainingSet(x)) || []
        this.pauseDuration = init && init.pauseDuration
        this.connectedSuperSetExercise = init && init.connectedSuperSetExercise || false
        this.superSetConfig = init && init.superSetConfig || null
        this.setParameters = init && init.setParameters || [SetParameter.weight, SetParameter.reps]
        this.exertionUnit = init && init.exertionUnit || null
        this.groupHeading = init && init.groupHeading || null
        this.groupHeadingTranslation = init && init.groupHeadingTranslation != null ? new LanguageDictionary<string>(init.groupHeadingTranslation.de, init.groupHeadingTranslation.en) : (this.groupHeading != null ? new LanguageDictionary<string>(this.groupHeading) : null)
        this.videoRecordingRequest = init && init.videoRecordingRequest || new VideoRecordingRequest(false, RecordingFrequency.NEXT_TRAINING, "")
        this.autoRun = init && init.autoRun || false
        this.noteUnit = init && init.noteUnit || null

        this.alternativeExerciseId = init && init.alternativeExerciseId || null

        if (this.exertionUnit) {
            if (this.exertionUnit == "RPE") {
                if (this.setParameters.filter(x => x == SetParameter.rpe).length <= 0) {
                    this.setParameters.push(SetParameter.rpe)
                }
                this.sets.forEach(set => {
                    set.rpe = set.exertion
                    set.exertion = undefined
                });
                this.exertionUnit = undefined
            }
            else if (this.exertionUnit == "RIR") {
                if (this.setParameters.filter(x => x == SetParameter.rir).length <= 0) {
                    this.setParameters.push(SetParameter.rir)
                }
                this.sets.forEach(set => {
                    set.rir = set.exertion
                    set.exertion = undefined
                });
                this.exertionUnit = undefined
            }
        }

        // var incompatibleSetParameters = this.setParameters.filter(x => !Object.keys(SetParameter).includes(x))
        // incompatibleSetParameters.forEach(setParameter => {
        //     var indexOfParameter = this.setParameters.indexOf(setParameter)
        //     if(indexOfParameter > -1) {
        //         this.setParameters.splice(indexOfParameter, 1);
        //     }
        // });

    }

    hasAnySetWithXRepMax(): boolean {
        return this.sets?.find(x => x.weightFormula?.match(TrainingPlanEditorComponent.xRepMaxPattern)) != null;
    }

    getRealSetIndex(set: TrainingSet): number {
        return this.sets?.filter(x => !x.isWarmupSet && !x.isDropset)?.indexOf(set);
    }

    getSetIndexWithoutWarmupSets(set: TrainingSet): number {
        return this.sets?.filter(x => !x.isWarmupSet)?.indexOf(set);
    }

    clone(): PlannedTrainingExercise {
        var clone = new PlannedTrainingExercise(this)
        clone.videoRecordingRequest = this.videoRecordingRequest.clone()
        clone.setParameters = this.setParameters?.map(x => x)
        clone.superSetConfig = this.superSetConfig?.clone()
        return clone
    }

    isSame(exercise: PlannedTrainingExercise): boolean {
        return JSON.stringify(this) === JSON.stringify(exercise);
    }

    asTemplateFirebaseMap(): Record<string, any> {
        let map = {
            ["id"]: this.id,
            ["deleted"]: this.deleted,
            ["exerciseId"]: this.exerciseId,
            ["note"]: this.note,
            ["position"]: this.position,
            ["sessionId"]: this.sessionId,
            ["sets"]: this.sets.map(x => x.asMap()),
            ["pauseDuration"]: this.pauseDuration,
            ["connectedSuperSetExercise"]: this.connectedSuperSetExercise || false,
            ["groupHeading"]: this.groupHeading,
            ["videoRecordingRequest"]: this.videoRecordingRequest?.asMap(),
            ["autoRun"]: this.autoRun,
            ["alternativeExerciseId"]: this.alternativeExerciseId,
            ["groupHeadingTranslation"]: this.groupHeadingTranslation?.AsMap() || null,
            ["noteUnit"]: this.noteUnit,
        }
        if (this.exertionUnit) {
            map["exertionUnit"] = this.exertionUnit
        }
        return map;
    }
}

export enum SetParameter {
    weight = "weight",
    reps = "reps",
    time = "time",
    rir = "rir",
    rpe = "rpe",
    speed = "speed",
    pace = "pace",
    timeUnderTension = "timeUnderTension",
    distance = "distance",
    calories = "calories",
    caloriesPerHour = "caloriesPerHour",
    rpm = "rpm",
    watts = "watts",
    heartRate = "heartRate",
    note = "note",
    pace500 = "pace500",
    pauseDuration = "pauseDuration",
}


export const SetParameter2LanguageDictionary: Record<SetParameter, LanguageDictionary<SetParameter>> = {
    [SetParameter.weight]: (new LanguageDictionary<SetParameter>("Gewicht", "weight", SetParameter.weight)),
    [SetParameter.reps]: new LanguageDictionary<SetParameter>("Wdh", "reps", SetParameter.reps),
    [SetParameter.time]: new LanguageDictionary<SetParameter>("Dauer", "duration", SetParameter.time),
    [SetParameter.rir]: new LanguageDictionary<SetParameter>("RIR", "RIR", SetParameter.rir),
    [SetParameter.rpe]: new LanguageDictionary<SetParameter>("RPE", "RPE", SetParameter.rpe),
    [SetParameter.pace]: new LanguageDictionary<SetParameter>("Pace", "pace", SetParameter.pace),
    [SetParameter.distance]: new LanguageDictionary<SetParameter>("Distanz", "distance", SetParameter.distance),
    [SetParameter.timeUnderTension]: new LanguageDictionary<SetParameter>("TUT", "TUT", SetParameter.timeUnderTension),
    [SetParameter.calories]: new LanguageDictionary<SetParameter>("Kalorien", "Calories", SetParameter.calories),
    [SetParameter.caloriesPerHour]: new LanguageDictionary<SetParameter>("Kalorien/h", "calories/h", SetParameter.caloriesPerHour),
    [SetParameter.rpm]: new LanguageDictionary<SetParameter>("RPM", "RPM", SetParameter.rpm),
    [SetParameter.watts]: new LanguageDictionary<SetParameter>("Watt", "watts", SetParameter.watts),
    [SetParameter.speed]: new LanguageDictionary<SetParameter>("Geschwindigkeit", "speed", SetParameter.speed),
    [SetParameter.heartRate]: new LanguageDictionary<SetParameter>("Herzfrequenz", "heart rate", SetParameter.speed),
    [SetParameter.note]: new LanguageDictionary<SetParameter>("Notiz", "note", SetParameter.note),
    [SetParameter.pace500]: new LanguageDictionary<SetParameter>("Pace 500m", "pace 500m", SetParameter.pace500),
    [SetParameter.pauseDuration]: new LanguageDictionary<SetParameter>("Pause", "pause", SetParameter.pauseDuration),
};

export const SetParameter2LabelMapping: Record<SetParameter, string> = {
    [SetParameter.weight]: "Gewicht",
    [SetParameter.reps]: "Wdh",
    [SetParameter.time]: "Dauer",
    [SetParameter.rir]: "RIR",
    [SetParameter.rpe]: "RPE",
    [SetParameter.pace]: "Pace",
    [SetParameter.distance]: "Distanz",
    [SetParameter.timeUnderTension]: "TUT",
    [SetParameter.calories]: "Kalorien",
    [SetParameter.caloriesPerHour]: "Kalorien/h",
    [SetParameter.rpm]: "RPM",
    [SetParameter.watts]: "Watt",
    [SetParameter.speed]: "Geschwindigkeit",
    [SetParameter.heartRate]: 'Herzfrequenz',
    [SetParameter.note]: "Notiz",
    [SetParameter.pace500]: "Pace500",
    [SetParameter.pauseDuration]: "Satzspezifische Pause",
};

export const SetParameter2ShortLabelMapping: Record<SetParameter, string> = {
    [SetParameter.weight]: "Gew.",
    [SetParameter.reps]: "Wdh.",
    [SetParameter.time]: "Dauer",
    [SetParameter.rir]: "RIR",
    [SetParameter.rpe]: "RPE",
    [SetParameter.pace]: "Pace",
    [SetParameter.distance]: "Dist.",
    [SetParameter.timeUnderTension]: "TUT",
    [SetParameter.calories]: "Kal.",
    [SetParameter.caloriesPerHour]: "Kal./h",
    [SetParameter.rpm]: "RPM",
    [SetParameter.watts]: "Watt",
    [SetParameter.speed]: "Geschw.",
    [SetParameter.heartRate]: 'Herzfrequ.',
    [SetParameter.note]: "Notiz",
    [SetParameter.pace500]: "Pace500",
    [SetParameter.pauseDuration]: "Pause",
};

export const Label2SetParameterMapping: Record<string, SetParameter> = {
    ["kg"]: SetParameter.weight,
    ["Wdh"]: SetParameter.reps,
    ["Dauer"]: SetParameter.time,
    ["RIR"]: SetParameter.rir,
    ["RPE"]: SetParameter.rpe,
    ["Pace"]: SetParameter.pace,
    ["Distanz"]: SetParameter.distance,
    ["TUT"]: SetParameter.timeUnderTension,
    ["Kalorien"]: SetParameter.calories,
    ["Kalorien/h"]: SetParameter.caloriesPerHour,
    ["RPM"]: SetParameter.rpm,
    ["Watts"]: SetParameter.watts,
    ["Geschwindigkeit"]: SetParameter.speed,
    ["Notiz"]: SetParameter.note,
    ["Herzfrequenz"]: SetParameter.heartRate,
    ["Pace500"]: SetParameter.pace500,
    ["Satzspezifische Pause"]: SetParameter.pauseDuration,
};

export const SetParameter2UnitMapping: Record<SetParameter, string> = {
    [SetParameter.weight]: "(kg)",
    [SetParameter.reps]: "(#)",
    [SetParameter.time]: "(Zeit)",
    [SetParameter.rir]: "",
    [SetParameter.rpe]: "",
    [SetParameter.pace]: "(Zeit/km)",
    [SetParameter.distance]: "(m)",
    [SetParameter.timeUnderTension]: "",
    [SetParameter.calories]: "(kcal)",
    [SetParameter.caloriesPerHour]: "(kcal/h)",
    [SetParameter.rpm]: "(#)",
    [SetParameter.watts]: "(W)",
    [SetParameter.speed]: "(km/h)",
    [SetParameter.heartRate]: '(bpm)',
    [SetParameter.note]: "",
    [SetParameter.pace500]: "(min/500m)",
    [SetParameter.pauseDuration]: "(s)",
};
export const SetParameter2LabelUnitMapping: Record<SetParameter, string> = {
    [SetParameter.weight]: "kg",
    [SetParameter.reps]: "#",
    [SetParameter.time]: "hh:mm:ss",
    [SetParameter.rir]: "RIR",
    [SetParameter.rpe]: "RPE",
    [SetParameter.pace]: "Zeit/km",
    [SetParameter.distance]: "m",
    [SetParameter.timeUnderTension]: "TUT",
    [SetParameter.calories]: "kcal",
    [SetParameter.caloriesPerHour]: "kcal/h",
    [SetParameter.rpm]: "#",
    [SetParameter.watts]: "W",
    [SetParameter.speed]: "km/h",
    [SetParameter.heartRate]: 'bpm',
    [SetParameter.note]: "",
    [SetParameter.pace500]: "min/500m",
    [SetParameter.pauseDuration]: "s",
};
export const SetParameter2SubHeadingMapping: Record<SetParameter, string> = {
    [SetParameter.weight]: "Gewicht",
    [SetParameter.reps]: "Wdh",
    [SetParameter.time]: "Dauer",
    [SetParameter.rir]: "Intensität ",
    [SetParameter.rpe]: "Intensität",
    [SetParameter.pace]: "Pace",
    [SetParameter.distance]: "Distanz",
    [SetParameter.timeUnderTension]: "TUT",
    [SetParameter.calories]: "Kalorien",
    [SetParameter.caloriesPerHour]: "Kalorien/h",
    [SetParameter.rpm]: "RPM",
    [SetParameter.watts]: "Watt",
    [SetParameter.speed]: "Geschwindigkeit",
    [SetParameter.heartRate]: 'Herzfrequenz',
    [SetParameter.note]: "Notiz",
    [SetParameter.pace500]: "Pace500",
    [SetParameter.pauseDuration]: "Pause",
};


export enum RecordingFrequency {
    EVERYTIME = 'EVERYTIME',
    NEXT_TRAINING = 'NEXT_TRAINING'
}


export const Frequency2LabelMapping: Record<RecordingFrequency, string> = {
    [RecordingFrequency.EVERYTIME]: "Jedes Training",
    [RecordingFrequency.NEXT_TRAINING]: "Nächstes Training"
};

export const Label2FrequencyMapping: Record<string, RecordingFrequency> = {
    ["Jedes Training"]: RecordingFrequency.EVERYTIME,
    ["Nächstes Training"]: RecordingFrequency.NEXT_TRAINING
}

export class VideoRecordingRequest {
    public active: boolean = false;
    public frequency: RecordingFrequency = RecordingFrequency.NEXT_TRAINING;
    public hint: string = "";

    constructor(active?: boolean, frequency?: RecordingFrequency, hint?: string) {
        this.active = active || false
        this.frequency = frequency || RecordingFrequency.NEXT_TRAINING
        this.hint = hint || ""
        if (!Object.keys(RecordingFrequency).includes(this.frequency)) this.frequency = RecordingFrequency.NEXT_TRAINING
    }

    asMap(): Record<string, any> {
        return {
            "active": this.active,
            "frequency": this.frequency.toString(),
            "hint": this.hint,
        }
    }

    clone(): VideoRecordingRequest {
        return new VideoRecordingRequest(this.active, this.frequency, this.hint)
    }
}


export class TrainingSet {

    public reps: number;
    public weight: number;
    public pauseDuration: number; //seconds after set
    public minReps: number;
    public maxReps: number;
    public repsFormula: string;
    public repsString: string;

    private _duration: number;

    get duration(): number {
        return this._duration
    }
    set duration(value: number) {
        if (!value) {
            this._duration = undefined
        }
        else {
            this._duration = value
        }
    }

    public minTime: number;
    public maxTime: number;

    public autoSetWeight: boolean;
    public exertion: number;


    //new parameters
    public _time: number;
    get time(): number {
        return this._time
    }
    set time(value: number) {
        if (!value) {
            this._time = undefined
        }
        else {
            this._time = value
        }
    }

    public pace: number;
    public distance: number;
    public timeUnderTension: string;
    public rpe: number;
    public minRpe: number;
    public maxRpe: number;
    public rir: number;
    public minRir: number;
    public maxRir: number;
    public calories: number;
    public caloriesPerHour: number;
    public rpm: number;
    public watts: number;
    public speed: number;
    public note: string;

    public isWarmupSet: boolean;
    public isDropset: boolean;
    public weightFormula: string;
    public weightString: string;

    public heartRate: number;

    public minHeartRate: number;
    public maxHeartRate: number;
    public heartRateFormula: string;

    public minPace: number;
    public maxPace: number;
    public paceFormula: string;

    public pace500: number;
    public minPace500: number;
    public maxPace500: number;
    public pace500Formula: string;

    public videoRecordingRequested: boolean

    /**
     * Set number only for planned sessions in tracked training sessions.
     */
    public setNumber: number;


    constructor();
    constructor(init: TrainingSet);
    constructor(init?: TrainingSet) {
        this.reps = init && init.reps !== null ? init.reps : undefined
        this.weight = init && init.weight !== null ? init.weight : undefined
        this.pauseDuration = init && init.pauseDuration !== null ? init.pauseDuration : undefined
        this.minReps = init && init.minReps !== null ? init.minReps : undefined
        this.maxReps = init && init.maxReps !== null ? init.maxReps : undefined
        this.repsFormula = init && init.repsFormula || undefined
        this.repsString = init && init.repsString || undefined
        this.duration = init && init.duration || undefined
        this.minTime = init && init.minTime || undefined
        this.maxTime = init && init.maxTime || undefined
        this.autoSetWeight = init && init.autoSetWeight || undefined
        this.exertion = init && init.exertion !== null ? init.exertion : undefined


        this.time = init && init.time || undefined
        this.pace = init && init.pace !== null ? init.pace : undefined
        this.distance = init && init.distance !== null ? init.distance : undefined
        this.timeUnderTension = init && init.timeUnderTension !== null ? init.timeUnderTension : undefined
        this.rpe = init && init.rpe !== null ? init.rpe : undefined
        this.minRpe = init && init.minRpe !== null ? init.minRpe : undefined
        this.maxRpe = init && init.maxRpe !== null ? init.maxRpe : undefined
        this.rir = init && init.rir !== null ? init.rir : undefined
        this.minRir = init && init.minRir !== null ? init.minRir : undefined
        this.maxRir = init && init.maxRir !== null ? init.maxRir : undefined
        this.calories = init && init.calories !== null ? init.calories : undefined
        this.caloriesPerHour = init && init.caloriesPerHour !== null ? init.caloriesPerHour : undefined
        this.rpm = init && init.rpm !== null ? init.rpm : undefined
        this.watts = init && init.watts !== null ? init.watts : undefined
        this.speed = init && init.speed !== null ? init.speed : undefined

        this.isWarmupSet = init && init.isWarmupSet || false
        this.isDropset = init && init.isDropset || false
        this.weightFormula = init && init.weightFormula || undefined
        this.weightString = init && init.weightString || undefined
        this.note = init && init.note !== null ? init.note : undefined

        this.heartRate = init && init.heartRate || undefined
        this.minHeartRate = init && init.minHeartRate || undefined
        this.maxHeartRate = init && init.maxHeartRate || undefined
        this.heartRateFormula = init && init.heartRateFormula || undefined

        this.minPace = init && init.minPace || undefined
        this.maxPace = init && init.maxPace || undefined
        this.paceFormula = init && init.paceFormula || undefined

        this.pace500 = init && init.pace500 || undefined
        this.minPace500 = init && init.minPace500 || undefined
        this.maxPace500 = init && init.maxPace500 || undefined
        this.pace500Formula = init && init.pace500Formula || undefined

        this.videoRecordingRequested = init && init.videoRecordingRequested || false

        this.setNumber = init && init.setNumber != undefined ? init.setNumber : undefined;
    }

    asMap(): Record<string, any> {
        var returnMap: Record<string, any> = {}
        if (this.reps !== undefined) returnMap["reps"] = this.reps
        if (this.weight !== undefined) returnMap["weight"] = this.weight
        if (this.weightFormula !== undefined) returnMap["weightFormula"] = this.weightFormula
        if (this.weightString !== undefined) returnMap["weightString"] = this.weightString
        if (this.pauseDuration !== undefined) returnMap["pauseDuration"] = this.pauseDuration
        if (this.minReps !== undefined) returnMap["minReps"] = this.minReps
        if (this.maxReps !== undefined) returnMap["maxReps"] = this.maxReps
        if (this.repsFormula !== undefined) returnMap["repsFormula"] = this.repsFormula
        if (this.repsString !== undefined) returnMap["repsString"] = this.repsString
        if (this.duration != undefined) returnMap["duration"] = this.duration
        if (this.autoSetWeight !== undefined) returnMap["autoSetWeight"] = this.autoSetWeight
        if (this.exertion !== undefined) returnMap["exertion"] = this.exertion
        if (this.videoRecordingRequested !== undefined) returnMap["videoRecordingRequested"] = this.videoRecordingRequested

        if (this.time !== undefined) returnMap["time"] = this.time
        if (this.minTime != undefined) returnMap["minTime"] = this.minTime
        if (this.maxTime != undefined) returnMap["maxTime"] = this.maxTime
        if (this.pace !== undefined) returnMap["pace"] = this.pace
        if (this.distance !== undefined) returnMap["distance"] = this.distance
        if (this.timeUnderTension !== undefined) returnMap["timeUnderTension"] = this.timeUnderTension
        if (this.rpe !== undefined) returnMap["rpe"] = this.rpe
        if (this.minRpe !== undefined) returnMap["minRpe"] = this.minRpe
        if (this.maxRpe !== undefined) returnMap["maxRpe"] = this.maxRpe
        if (this.rir !== undefined) returnMap["rir"] = this.rir
        if (this.minRir !== undefined) returnMap["minRir"] = this.minRir
        if (this.maxRir !== undefined) returnMap["maxRir"] = this.maxRir

        if (this.calories !== undefined) returnMap["calories"] = this.calories
        if (this.caloriesPerHour !== undefined) returnMap["caloriesPerHour"] = this.caloriesPerHour
        if (this.rpm !== undefined) returnMap["rpm"] = this.rpm
        if (this.watts !== undefined) returnMap["watts"] = this.watts
        if (this.speed !== undefined) returnMap["speed"] = this.speed
        if (this.note !== undefined) returnMap["note"] = this.note

        if (this.isWarmupSet !== undefined) returnMap["isWarmupSet"] = this.isWarmupSet
        if (this.isDropset !== undefined) returnMap["isDropset"] = this.isDropset

        if (this.heartRate !== undefined) returnMap["heartRate"] = this.heartRate
        if (this.minHeartRate !== undefined) returnMap["minHeartRate"] = this.minHeartRate
        if (this.maxHeartRate !== undefined) returnMap["maxHeartRate"] = this.maxHeartRate
        if (this.heartRateFormula !== undefined) returnMap["heartRateFormula"] = this.heartRateFormula

        if (this.minPace !== undefined) returnMap["minPace"] = this.minPace
        if (this.maxPace !== undefined) returnMap["maxPace"] = this.maxPace
        if (this.paceFormula !== undefined) returnMap["paceFormula"] = this.paceFormula

        if (this.pace500 !== undefined) returnMap["pace500"] = this.pace500
        if (this.minPace500 !== undefined) returnMap["minPace500"] = this.minPace500
        if (this.maxPace500 !== undefined) returnMap["maxPace500"] = this.maxPace500
        if (this.pace500Formula !== undefined) returnMap["pace500Formula"] = this.pace500Formula

        return returnMap

    }

    hasRefWeight() {
        return (this.weightFormula?.toLowerCase()?.includes("%") && !this.weightFormula?.toLowerCase()?.includes("%ref")) ?? false;
    }

    clear() {
        this.reps = undefined
        this.weight = undefined
        this.pauseDuration = undefined
        this.minReps = undefined
        this.maxReps = undefined
        this.repsFormula = undefined
        this.repsString = undefined
        this.duration = undefined
        this.minTime = undefined
        this.maxTime = undefined
        this.autoSetWeight = undefined
        this.exertion = undefined

        this.time = undefined
        this.pace = undefined
        this.distance = undefined
        this.timeUnderTension = undefined
        this.rir = undefined
        this.minRir = undefined
        this.maxRir = undefined
        this.rpe = undefined
        this.minRpe = undefined
        this.maxRpe = undefined

        this.calories = undefined
        this.caloriesPerHour = undefined
        this.rpm = undefined
        this.watts = undefined
        this.speed = undefined
        this.isWarmupSet = false
        this.isDropset = false
        this.weightFormula = undefined
        this.weightString = undefined
        this.paceFormula = undefined

        this.heartRate = undefined
        this.minHeartRate = undefined
        this.maxHeartRate = undefined

        this.pace500 = undefined
        this.minPace = undefined
        this.maxPace = undefined
        this.paceFormula = undefined

        this.note = undefined

        this.videoRecordingRequested = false
    }

    getValueBySetParameter(setParameter: SetParameter): string {
        if (this[setParameter] != null) {
            if (setParameter == SetParameter.time || setParameter == SetParameter.pace) {
                return this.getSecondsAsTime(this[setParameter]);
            }
            return this[setParameter].toString()
        }
        else {
            let upperCaseParameter = setParameter.charAt(0).toUpperCase() + setParameter.slice(1)
            if (this["min" + upperCaseParameter] != null && this["max" + upperCaseParameter] != null) {

                if (setParameter == SetParameter.time || setParameter == SetParameter.pace) {
                    return this.getSecondsAsTime(this["min" + upperCaseParameter]) + " - " + this.getSecondsAsTime(this["max" + upperCaseParameter]);
                }
                return this["min" + upperCaseParameter] + " - " + this["max" + upperCaseParameter]
            }
        }
        return "";
    }

    getSecondsAsTime(inputSeconds: number): string {
        if (inputSeconds == undefined) return "";
        if (inputSeconds < 0) inputSeconds = 0
        return inputSeconds.asDurationString();
    }

    getWeight() {
        return this.weight ?? 0
    }

    getRepCount() {
        return this.reps ?? 0
    }

    getE1RM() {
        var reps = this.getRepCount()
        if (this.rir != null) {
          reps = reps + this.rir
        } else if (this.rpe != null) {
          reps = reps + (10 - this.rpe)
        }
        return ((this.getWeight() * reps * 0.0333) + this.getWeight()).roundToPlaces(2)
      }
}

export enum NumberOfRounds {
    AMRAP = "AMRAP",
    PER_SETS = "PER_SETS"
}
export const NumberOfRounds2LabelMapping: Record<NumberOfRounds, string> = {
    [NumberOfRounds.AMRAP]: "AMRAP",
    [NumberOfRounds.PER_SETS]: "Feste Anzahl",
};

export const Label2NumberOfRoundsMapping: Record<string, NumberOfRounds> = {
    ["AMRAP"]: NumberOfRounds.AMRAP,
    ["Feste Anzahl"]: NumberOfRounds.PER_SETS,
}
export class SuperSetConfig {
    public numberOfRounds: NumberOfRounds;
    public totalAvailableTime: number = 300;
    public roundAvailableTime: number;
    public get name() {
        return this.nameTranslation.de;
    }
    public set name(value: string) {
        this.nameTranslation.de = value;
    }
    public nameTranslation: LanguageDictionary<string> = new LanguageDictionary<string>();


    constructor(numberOfRounds?: NumberOfRounds, totalAvailableTime?: number, roundAvailableTime?: number, name?: string, nameTranslation?: LanguageDictionary<string>) {
        this.numberOfRounds = numberOfRounds || NumberOfRounds.PER_SETS;
        this.totalAvailableTime = totalAvailableTime || null;
        this.roundAvailableTime = roundAvailableTime || null;
        this.name = name || null;
        this.nameTranslation = nameTranslation != null ? new LanguageDictionary<string>(nameTranslation.de, nameTranslation.en) : new LanguageDictionary<string>(this.name)
        if (!Object.keys(NumberOfRounds).includes(this.numberOfRounds)) this.numberOfRounds = NumberOfRounds.PER_SETS
    }

    asFirebaseMap(): Record<string, any> {
        return {
            ["numberOfRounds"]: this.numberOfRounds.toString(),
            ["totalAvailableTime"]: this.totalAvailableTime,
            ["roundAvailableTime"]: this.roundAvailableTime,
            ["name"]: this.name?.length > 0 ? this.name : null,
            ["nameTranslation"]: this.nameTranslation.AsMap(),
        }
    }

    clone(): SuperSetConfig {
        return new SuperSetConfig(this.numberOfRounds, this.totalAvailableTime, this.roundAvailableTime, this.name, this.nameTranslation)
    }
}

export enum TrainingPlanAccess {
    none = "none",
    all = "all",
    users = "users",
    coaches = "coaches",
}