<div *ngIf="authService?.isAdmin()" class="page-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="layout-box">
                    <h3 class="float-left">Lizenznutzung Coaching</h3>
                    <div class="icon-button float-right" (click)="createStripeUsageRecord()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
                            <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
                          </svg>
                        Nutzung übertragen
                    </div>
                    <div class="icon-button float-right marginright-25" (click)="createMonitoringItem()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-repeat" viewBox="0 0 16 16">
                            <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                            <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
                        </svg>
                        Aktualisieren
                    </div>
                    <!--div class="icon-button float-right marginright-25" (click)="onExport()">
                        Export
                    </div-->
                    <div class="spacer-50"></div>
                    Angezeigter Zeitraum:
                    <div class="display-inlineblock">
                        Start: 
                        <span class="marginleft-10">{{graphRangeStartDate?.asFormatedString()}}</span>
                        <input [matDatepicker]="startDatePicker" class="invisible-datetime-input" [value]="graphRangeStartDate" class="visibility-hidden width-0 padding-0" (dateInput)="onGraphRangeStartDateChanged($event.value?.toDate())">
                        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #startDatePicker ></mat-datepicker><br>
                    </div>
                    <div class="display-inlineblock">
                        Ende: 
                        <span class="marginleft-10">{{graphRangeEndDate?.asFormatedString()}}</span>
                        <input [matDatepicker]="endDatePicker" class="invisible-datetime-input" [value]="graphRangeEndDate" class="visibility-hidden width-0 padding-0" (dateInput)="onGraphRangeEndDateChanged($event.value?.toDate())">
                        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #endDatePicker ></mat-datepicker>
                    </div>
                    <div class="spacer-25"></div>

                    <dx-chart
                        [dataSource]="monitoringGraphData"
                        title=""
                        (onLegendClick)="legendClickHandler($event)">
                        <dxo-size
                            [height]="3000">
                        </dxo-size>

                        <ng-container *ngFor="let graphItem of monitoringGraphItems">
                            <dxi-series valueField="{{graphItem.valueField}}" name="{{graphItem.name}}" type="line" [visible]="visibleSeries.includes(graphItem.name) ? true : false"></dxi-series>
                        </ng-container>

                        <dxo-common-series-settings
                            argumentField="date"
                            type="line"
                            valueField="count">
                            <dxo-point [visible]="true" [size]="9"></dxo-point>
                        </dxo-common-series-settings>
                        <dxo-margin [bottom]="20"></dxo-margin>
                        <dxo-common-axis-settings>
                            <dxo-grid [visible]="true"></dxo-grid>
                        </dxo-common-axis-settings>
                        <dxo-legend
                            verticalAlignment="bottom"
                            horizontalAlignment="center"
                            itemTextPosition="top"
                            visible="true">
                        </dxo-legend>
                        <dxi-value-axis [autoBreaksEnabled]="true" [maxAutoBreakCount]="2">
                            <!--dxo-label [customizeText]="customizeBodyDataGraphYAxisText"></dxo-label-->
                        </dxi-value-axis>
                        <dxo-argument-axis [allowDecimals]="false" [axisDivisionFactor]="60">
                            <dxo-label>
                                <dxo-format type="dd. MMM"></dxo-format>
                            </dxo-label>
                        </dxo-argument-axis>
                        <dxo-export [enabled]="false"></dxo-export>
                        <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip"></dxo-tooltip>
                    </dx-chart>
                    <div class="icon-button" (click)="hideAllGraphLines()">
                        Alle ausblenden
                    </div>
                    <div class="icon-button marginleft-25" (click)="showAllGraphLines()">
                        Alle einblenden
                    </div>

                    <div class="spacer-50"></div>
                    <dx-data-grid id="monitoringTable"
                        [height]="'800px'"
                        [dataSource]="monitoringTableData"
                        [allowColumnReordering]="true"
                        [allowColumnResizing]="false"
                        [showBorders]="true"
                        [showRowLines]="true"
                        [columnAutoWidth]="true"
                        [columnMinWidth]="100" (onExporting)="chartExportService.onExporting($event)">

                        <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>

                        <dxi-column dataField="name"></dxi-column>
                        <dxi-column dataField="totalCoaching"></dxi-column>
                        <dxi-column dataField="trial"></dxi-column>
                        <dxi-column dataField="totalPremium"></dxi-column>
                        
                        <dxo-filter-row [visible]="true"></dxo-filter-row>

                        <dxo-export [enabled]="true"></dxo-export>

                        <dxo-summary>
                            <dxi-total-item
                                column="totalCoaching"
                                summaryType="sum">
                            </dxi-total-item>
                        </dxo-summary>
                    </dx-data-grid>
                </div>
            </div>
            <div class="col-12">
                <div class="layout-box">
                    <app-mrr-graph></app-mrr-graph>
                </div>
            </div>
        </div>
    </div>
</div>