<div class="layout-box checkin-view-wrapper {{showAll ? 'scrollable' : ''}}">
    <button *ngIf="selectedMetricDataForComparison?.length > 0" class="button-filled small compare-images-button" (click)="onOpenCompareImageDialog()">
        Ausgewählte Bilder vergleichen
    </button>
    <h3>Weekly und Daily Check-Ins</h3>
    <div class="checkin-table-wrapper">
        <ng-container *ngFor="let week of weeks; index as i">
            <div *ngIf="i < 4 || showAll" class="week-wrapper layout-box sub-box">
                <div class="week-header">
                    {{week.startDate?.asFormatedString()}} - {{week.getEndDate()?.asFormatedString()}}
                </div>
                <div class="week-metrics-wrapper">
                    <table>
                        <tr>
                            <th></th>
                            <th *ngFor="let metric of week.metrics" class="header-field {{metric.metricType?.toLowerCase()}}">
                                {{metric.getName(languageService.selectedLanguageCode)}}
                            </th>
                        </tr>
                        <tr *ngFor="let dailyCondition of week.dailyConditions">
                            <td class="weekday-field">{{dailyCondition.date.getPrintableWeekdayShort()}}</td>
                            <ng-container *ngFor="let metric of week.metrics">
                                <td *ngIf="!metric.isMetricTypeText() && !metric.isMetricTypeImage() && !metric.isMetricTypeVideo()" class="value-field">
                                    {{dailyCondition.getMetricDataByMetricId(metric.metricId)?.getPrintableValue() ?? '-'}}
                                </td>
                                <td *ngIf="metric.isMetricTypeText()" class="value-field text" tooltip="{{dailyCondition.getMetricDataByMetricId(metric.metricId)?.getPrintableValue()}}">
                                    {{dailyCondition.getMetricDataByMetricId(metric.metricId)?.getPrintableValue()}}
                                </td>
                                <td *ngIf="metric.isMetricTypeImage()" class="value-field image">
                                    <div *ngIf="dailyCondition.getMetricDataByMetricId(metric.metricId)?.value">
                                        <div class="icon-button" (click)="onOpenMetricImage(dailyCondition.getMetricDataByMetricId(metric.metricId))">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-card-image" viewBox="0 0 16 16">
                                                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
                                                <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54L1 12.5v-9a.5.5 0 0 1 .5-.5z"/>
                                            </svg>
                                        </div>
                                        <div class="compare-checkbox-container" (click)="onMetricDataSelectedForComparison(dailyCondition.getMetricDataByMetricId(metric.metricId));$event.stopPropagation()">
                                            <input class="input checkbox margin-horizontal-auto h-100 m-2" [checked]="selectedMetricDataForComparison.includes(dailyCondition.getMetricDataByMetricId(metric.metricId))" tooltip="Zum Vergleich hinzufügen" type="checkbox">
                                        </div>
                                    </div>
                                </td>
                                <td *ngIf="metric.isMetricTypeVideo()" class="value-field image">
                                    <div *ngIf="dailyCondition.getMetricDataByMetricId(metric.metricId)?.value">
                                        <div class="icon-button" (click)="onOpenMetricVideo(dailyCondition.getMetricDataByMetricId(metric.metricId))">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-camera-video" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"/>
                                            </svg>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>
                        </tr>
                    </table>
                </div>
                <div class="weekly-wrapper" *ngIf="week.questionaireResults?.length > 0">
                    <ng-container *ngFor="let weeklyQuestionaireResult of week.questionaireResults">
                        <ng-container *ngIf="weeklyQuestionaireResult.assignedQuestionaire?.completed; else pending_questionaire;">
                            <div class="row layout-box sub-box link" (click)="onOpenQuestionaire(weeklyQuestionaireResult);$event.stopPropagation()">
                                <div class="col-8 col-xl-9">
                                    <div class="row">
                                        <div class="col-12">
                                            <b>{{weeklyQuestionaireResult.assignedQuestionaire.name}}</b>
                                            <div class="indicator small marginleft-10 display-inlineblock" *ngIf="weeklyQuestionaireResult.assignedQuestionaire.viewedByCoach == false" tooltip="Du hast diesen Check-In noch nicht angesehen." placement="right"></div>
                                        </div>
                                        <div class="col-12">
                                            {{weeklyQuestionaireResult.assignedQuestionaire.completionDate?.asDateTimeFormatedString() || weeklyQuestionaireResult.assignedQuestionaire.assignmentDate?.asDateTimeFormatedString()}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3 col-xl-2">
                                    <div class="row full-height" *ngIf="weeklyQuestionaireResult?.canAccess(getCoach())">
                                        <div *ngIf="!utilityService.onSmallDisplay()" class="icon-button marginright-10 margin-vertical-auto">
                                            Öffnen
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#4AE6D7" class="bi bi-check" viewBox="0 0 16 16">
                                            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #pending_questionaire>
                            <div class="row layout-box sub-box link" (click)="onFilloutQuestionaire(weeklyQuestionaireResult);$event.stopPropagation()">
                                <div class="checkbox-wrapper d-inline-block">
                                </div>
                                <div class="col-8 col-xl-9">
                                    <div class="row">
                                        <div class="col-12">
                                            <b>{{weeklyQuestionaireResult.assignedQuestionaire.name}}</b>
                                        </div>
                                        <div class="col-12">
                                            {{weeklyQuestionaireResult.assignedQuestionaire.assignmentDate?.asDateTimeFormatedString()}} (Ausstehend)
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="row float-right full-height">
                                        <div class="icon-button margin-vertical-auto" (click)="onDeleteQuestionaire(weeklyQuestionaireResult);$event.stopPropagation()">
                                            Löschen
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <div class="spacer-25"></div>
        <div *ngIf="!showAll" class="icon-button display-inlineblock" (click)="onShowAll(true)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            </svg>
            Alle anzeigen
        </div>
        <div *ngIf="showAll" class="icon-button display-inlineblock" (click)="onShowAll(false)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
            </svg>
            Alte verbergen
        </div>
    </div>
</div>