import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Coach } from 'src/app/model/coach.model';
import { Licence } from 'src/app/model/lid.model';
import { ProductPurchase } from 'src/app/model/product-purchase.model';
import { Product } from 'src/app/model/product.model';
import { Questionaire } from 'src/app/model/questionaires.model';
import { User } from 'src/app/model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { PaymentService } from 'src/app/services/payment.service';
import { environment } from 'src/environments/environment';
import { interval, take, firstValueFrom, Subscription } from 'rxjs';
import { MetricsSelectionDialogComponent } from 'src/app/metrics-selection-dialog/metrics-selection-dialog.component';
import { EmailTemplate, PaymentSettings } from 'src/app/model/payment-settings.model';
import { EmailTemplateDialogComponent } from '../email-template-dialog/email-template-dialog.component';

@Component({
  selector: 'app-licence-dialog',
  templateUrl: './licence-dialog.component.html',
  styleUrls: ['./licence-dialog.component.css']
})
export class LicenceDialogComponent implements OnInit {
  
  public environment = environment
  
  public user: User

  public licence: Licence
  public name: string = null
  public email: string = null
  public client: User
  public coachesOfLicenceHolder: Coach[] = []
  public availableQuestionaires: Questionaire[] = []
  public headingText: string
  public invitationText: string
  public sendingEmail = false
  public invitationLink: string

  public purchase: ProductPurchase
  public stepNumber = 3
  public lockClient = false

  get paymentEnabled() {
    return this.paymentService.canUsePayment
  }

  get products(): Product[] {
    return this.paymentService.activeProducts
  }

  public static TAB_COPY = 'copy'
  public get tabCopy() {
    return LicenceDialogComponent.TAB_COPY
  }
  public static TAB_EMAIL = 'email'
  public get tabEmail() {
    return LicenceDialogComponent.TAB_EMAIL
  }
  public currentTab = LicenceDialogComponent.TAB_COPY

  public static TAB_NEW_CLIENT = 'new_client'
  public get tabNewClient() {
    return LicenceDialogComponent.TAB_NEW_CLIENT
  }
  public static TAB_EXISTING_CLIENT = 'existing_client'
  public get tabExistingClient() {
    return LicenceDialogComponent.TAB_EXISTING_CLIENT
  }
  public static TAB_OLD_CLIENT = 'old_client'
  public get tabOldClient() {
    return LicenceDialogComponent.TAB_OLD_CLIENT
  }
  public selectedClientSource = LicenceDialogComponent.TAB_NEW_CLIENT

  get paymentSettings() {
    return this.paymentService.paymentSettings
  }
  
  constructor(public dialogRef: MatDialogRef<LicenceDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { user: User, licence: Licence, coachesOfLicenceHolder: Coach[], availableQuestionaires: Questionaire[], createNew: boolean }, public dialog: MatDialog, public userService: FirestoreService, private paymentService: PaymentService, private toastr: ToastrService) {
    this.licence = data.licence
    if (this.licence) {
      this.name = this.licence.presetName
      this.email = this.licence.email
    }
    this.user = data.user
    this.coachesOfLicenceHolder = data.coachesOfLicenceHolder
    this.availableQuestionaires = data.availableQuestionaires
    if (data.createNew) {
      this.stepNumber = 0
    } else {
      this.lockClient = true
    }

    this.loadProductPurchase()
    this.updateRedemptionMessage()
  }

  async loadProductPurchase() {
    if (this.licence?.productPurchaseId) {
      await firstValueFrom(this.userService.getProductPurchase(this.licence.productPurchaseId)).then(subscription => {
        if (subscription.deleted || subscription.status != 'uncompleted') {
          this.licence.productPurchaseId = null
          return
        }
        this.purchase = subscription
        this.purchase.product = this.products.find(p => p.id == this.purchase.productId)
        this.updateRedemptionMessage()
      })
    }
  }

  ngOnInit(): void {
    this.dialogRef.backdropClick().subscribe(() => { this.onConfirmDialog() });
  }

  onChangeTab(tab: string) {
    this.currentTab = tab

    this.updateRedemptionMessage()
  }
  onChangeClientSource(tab: string) {
    this.selectedClientSource = tab

    if (tab == this.tabOldClient) {
      if (!this.oldClientsSubscription) {
        this.oldClientsSubscription = this.userService.getOldLicences().then(licences => {
          this.oldLicences = licences
          this.oldLicences.sort((a, b) => a.user?.getName()?.localeCompare(b.user?.getName()))
          this.oldClientsSubscription = null
        })
      }
    }
  }
  onShowEmailAddress(licence: Licence) {
    this.userService.getEmailForUser(licence.userUid).then(email => {
      if (email) {
        this.toastr.info(email, "",  {
          positionClass: 'toast-bottom-center'
        });
      }
    })
  }

  onSelectClient(client: User) {
    this.client = client
  }
  onSelectOldLicence(licence: Licence) {
    this.client = licence.user
    this.licence = licence
    this.name = licence.presetName
    this.email = licence.email
  }

  getClients() {
    return this.userService.getAccessibleClients()
  }

  oldClientsSubscription: Promise<any> = null
  oldLicences: Licence[] = []
  
  getOldClients() {
    return this.oldLicences
  }

  isOldClientSelected() {
    return this.selectedClientSource == this.tabOldClient
  }
  isActiveClientSelected() {
    return this.selectedClientSource == this.tabExistingClient
  }

  async goToNextStep() {
    console.log('Next step')
    if (this.stepNumber == 0) {
      if (this.selectedClientSource == this.tabExistingClient) {
        if (this.client == null) {
          this.toastr.error("Bitte wähle einen Coachee aus.", "",  {
            positionClass: 'toast-bottom-center'
          });
          return
        } else {
          this.licence = this.client.licence
          this.lockClient = true
          await this.loadProductPurchase()
          if (this.purchase) this.purchase.licenceId = this.licence.lid
          this.stepNumber++
        }
      } else if (this.selectedClientSource == this.tabOldClient) {
        if (this.client == null) {
          this.toastr.error("Bitte wähle einen Coachee aus.", "",  {
            positionClass: 'toast-bottom-center'
          });
          return
        } else {
          this.lockClient = true
          await this.loadProductPurchase()
          if (this.purchase) this.purchase.licenceId = this.licence.lid
          this.stepNumber++
        }
      } else {
        if (!this.licence) {
          if (this.name?.length > 0) {
            var onboardingQuestionaireIds = []
            this.availableQuestionaires.forEach(q => {
              if (q.eventTrigger == 'onboarding') onboardingQuestionaireIds.push(q.id)
            })
            if (onboardingQuestionaireIds.length > 1) onboardingQuestionaireIds = []
            this.licence = await this.userService.allocateLicence(onboardingQuestionaireIds, this.name, this.email)
          } else {
            this.toastr.error("Bitte gib einen Namen ein.", "",  {
              positionClass: 'toast-bottom-center'
            });
            return
          }
        }
        this.lockClient = true
        this.stepNumber++
      }
      if (!this.paymentEnabled) this.stepNumber++
    } else if (this.stepNumber == 1) {
      if (this.selectedClientSource == this.tabExistingClient && this.licence.productPurchaseId == null) {
        this.toastr.error("Bitte wähle ein Produkt aus.", "",  {
          positionClass: 'toast-bottom-center'
        });
        return
      }
      if (this.purchase) await this.userService.saveProductPurchase(this.purchase)
      this.updateRedemptionMessage()
      this.stepNumber++
    } else {
      this.updateRedemptionMessage()
      this.stepNumber++
    }
  }

  updateRedemptionMessage() {
    if (!this.licence) return

    var baseText = this.licence.productPurchaseId ? this.getPaymentInvitation() : this.getRedemptionMessage()
    this.invitationLink = this.licence.productPurchaseId ? this.getPaymentLink() : this.getRedemptionLink()

    if (this.currentTab == this.tabEmail) {
      this.headingText = this.getHeadingText() //'Hallo ' + (this.client?.getName() || this.licence.presetName || '') + '!'
      this.invitationText = baseText?.replaceAll('<br>', '\n')
    } else {
      this.invitationText = baseText?.replaceAll('<br>', '\n')
    }
  }

  canActivateOldLicence() {
    return this.selectedClientSource == this.tabOldClient && this.purchase?.product == null && this.licence?.active == false
  }

  activatingLicence: boolean = false

  async onActivateLicence() {
    if (this.licence.active == false && this.licence.userUid == this.client.uid) {
      
      console.log(this.user.uid)
      this.activatingLicence = true
      var res = await this.userService.reactivateLicence(this.licence, this.user.uid)
      console.log(res)
      this.activatingLicence = false
      if (res.success) {
        this.licence.active = true
        this.toastr.success("Lizenz wurde reaktiviert.", "",  {
          positionClass: 'toast-bottom-center'
        });
      } else {
        this.toastr.error("Es ist ein Fehler aufgetreten.", "",  {
          positionClass: 'toast-bottom-center'
        });
      }
    }
  }

  onCloseDialog() {
    this.onConfirmDialog()
  }
  async onConfirmDialog() {
    if (!this.licence) {
      this.dialogRef.close()
      return
    } else if (this.licence.productPurchaseId && this.purchase) {
      this.purchase = await this.userService.saveProductPurchase(this.purchase)
      this.licence.productPurchaseId = this.purchase.id
    } else if (!this.licence.productPurchaseId && this.purchase) {
      await this.userService.deleteProductPurchase(this.purchase)
    }
    await this.userService.updateLicence(this.licence)
    this.dialogRef.close()
  }

  onPreselectQuestionaire(questionaire: Questionaire) {
    if (this.licence.onboardingQuestionaireIds.includes(questionaire.id)) {
      this.licence.onboardingQuestionaireIds.forEach( (item, index) => {
        if (item == questionaire.id) this.licence.onboardingQuestionaireIds.splice(index, 1)
      })
    } else {
      this.licence.onboardingQuestionaireIds.push(questionaire.id)
    }
  }
  onAssignToClientGroup(group: string) {
    if (this.licence.assignedClientGroups.includes(group)) {
      this.licence.assignedClientGroups.forEach( (item, index) => {
        if (item == group) this.licence.assignedClientGroups.splice(index, 1)
      })
    } else {
      this.licence.assignedClientGroups.push(group)
    }
  }

  getSelectedOnboardingQuestionaires(): string{
    var text = '';
    if (this.licence.onboardingQuestionaireIds) {
      this.licence.onboardingQuestionaireIds.forEach(id => {
        var name = this.getQuestionaireById(id)?.name
        if (name) {
          if (text.length > 0){
            text = text.concat(', ')
          }
          text = text.concat(name)
        }
      })
    }
    if (text.length > 0) return text
    return null
  }
  getAssignedGroupNames(): string {
    var text = '';
    if (this.licence.assignedClientGroups) {
      this.licence.assignedClientGroups.forEach(name => {
        if (text.length > 0){
          text = text.concat(', ')
        }
        text = text.concat(name);
      });
    }
    if (text.length > 0) return text
    return null
  }

  getPrintableAssignedMetrics() {
    var checkedElements = '';
    if(this.licence.assignedMetricIds) {
      this.licence.assignedMetricIds.forEach(id => {
        var name = this.userService.getMetricByMetricId(id)?.nameTranslation['de']
        if (name) {
          if (checkedElements.length > 0){
            checkedElements = checkedElements.concat(', ')
          }
          checkedElements = checkedElements.concat(name);
        } else {
          this.userService.fetchMetricByMetricId(id).then(metric => {})
        }
      });
    }
    if (checkedElements.length > 0) return checkedElements
    return null
  }
  onChangeAssignedMetrics() {
    const dialogRef = this.dialog.open(MetricsSelectionDialogComponent, { data: { notAvailableMetricIds: this.licence?.assignedMetricIds || [], canShowMetricSets: true}, width: '1200px'})
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.shouldTake) {
          result.metrics.forEach(metric => {
            if (!this.licence?.assignedMetricIds.includes(metric.id)) {
              this.licence.assignedMetricIds.push(metric.id)
            }
          });
        }
      }
    });
  }
  onClearAssignedMetrics() {
    this.licence.assignedMetricIds = []
  }

  getLicenceForDistribution() {
    return this.licence;
  }
  onExpirationDateChanged(licence: Licence, date: Date) {
    licence.expirationDate = date
    this.userService.updateLicenceExpirationDate(licence)
  }
  onPresetNameChanged(name: string) {
    this.name = name
    if (this.licence) this.licence.presetName = name
  }
  getQuestionaireById(id: string) {
    return this.availableQuestionaires.filter(q => q.id == id).shift()
  }
  onEmailChanged(email: string) {
    this.email = email?.replaceAll(' ', '')
    if (this.licence) this.licence.email = email
  }
  isEmailAddressValid() {
    if (!this.email || this.email.length == 0) {
      if (this.licence?.productPurchaseId || this.currentTab == this.tabEmail) {
        return false
      } else {
        return true
      }
    }
    var re = /\S+@\S+\.\S+/;
    return re.test(this.email || '');
  }

  async onSendEmail() {
    if (!this.isEmailAddressValid()) return
    var imageUrl = null
    var snapshot = await this.userService.getBrandingSettings(this.user.licenceHolderUid || this.user.uid).toPromise()
    if (snapshot.exists) {
      var logoFileName = snapshot?.data()?.logoFileName
      if (logoFileName) {
        imageUrl = await this.userService.getBrandingImage(this.user.licenceHolderUid || this.user.uid, logoFileName).toPromise()
      }
    }
    this.sendingEmail = true
    var addAppButton = this.licence.productPurchaseId == null
    var buttonText = addAppButton ? 'App installieren' : 'Angebot öffnen'
    var buttonLink = addAppButton ? this.getRedemptionLink() : this.getPaymentLink()
    firstValueFrom(this.userService.sendInvitationEmail(this.licence.email, this.licence.presetName, this.headingText, this.invitationText.replaceAll('\n', '<br>'), buttonLink, buttonText, 'Einladung zum Coaching', imageUrl, this.paymentSettings?.email, this.user.licenceHolderUid)).then(res => {
      this.sendingEmail = false
      if (res?.result == 'success') {
        this.toastr.success("Email wurde versendet.", "",  {
          positionClass: 'toast-bottom-center'
        });
        this.onCloseDialog()
      } else {
        this.toastr.error("Beim Versenden der Email ist ein Fehler aufgetreten.", "",  {
          positionClass: 'toast-bottom-center'
        });
      }
    }, error => {
      console.log(error)
      this.sendingEmail = false
    })
  }
  onHideNutritionValuesChanged() {
    this.licence.hideNutritionValues = !this.licence.hideNutritionValues
  }
  onHideChatChanged() {
    this.licence.hideChat = !this.licence.hideChat
  }

  async onSelectProduct(product: Product) {
    if (product == null) {
      this.licence.productPurchaseId = null
    } else {
      this.onExpirationDateChanged(this.licence, null)
      if (this.purchase == null) {
        if (this.licence.productPurchaseId) {
          this.purchase = await firstValueFrom(this.userService.getProductPurchase(this.licence.productPurchaseId))
        }
        if (this.purchase == null) {
          this.purchase = new ProductPurchase()
          this.purchase.creationDate = new Date()
          this.purchase.licenceHolderUid = this.user.licenceHolderUid
          this.purchase.stripeAccountId = this.paymentService.stripeAccountId
          this.purchase.licenceId = this.getLicenceForDistribution().lid
          this.purchase.status = 'uncompleted'
        }
      }
      if (this.client) this.purchase.customerUid = this.client.uid
      this.purchase.product = product
      this.purchase.productId = product.id
      this.purchase.recurring = product.recurring
      this.purchase.price = product.price
      this.purchase.initialSetupFee = product.initialSetupFee
      this.purchase.currency = product.currency
      this.purchase.vatRate = product.vatRate
      this.purchase.autoRenew = product.autoRenew
      this.purchase.cancelationPeriod = product.cancelationPeriod
      this.purchase.licenceType = product.licenceType
      this.purchase.duration = product.duration
      this.purchase.durationUnit = product.durationUnit
      this.purchase.durationMultiplier = product.durationMultiplier
      this.purchase.deleted = false
      this.purchase = await this.userService.saveProductPurchase(this.purchase)
      this.licence.productPurchaseId = this.purchase.id
      this.licence.hideChat = product.hideChat
      this.licence.hideNutritionValues = product.hideNutritionValues
      this.licence.assignedMetricIds = product.assignedMetricIds
      this.licence.assignedClientGroups = product.assignedClientGroups
      this.licence.onboardingQuestionaireIds = product.onboardingQuestionaireIds
      await this.userService.updateLicence(this.licence)
    }
    this.updateRedemptionMessage()
  }
  onEnableSubscriptionStartDate() {
    this.purchase.startDate = new Date()
  }
  onSetSubscriptionStartDate(dateString: string) {
    var date = this.parseDateString(dateString)
    this.purchase.startDate = date
  }

  isPaymentMethodAvailable(method: string) {
    if (!this.purchase?.availablePaymentMethods) return this.paymentService.paymentSettings?.availablePaymentMethods?.includes(method) ?? false
    return this.purchase?.availablePaymentMethods.includes(method)
  }

  getPrintablePaymentMethods() {
    var result = ''
    var availablePaymentMethods = this.purchase?.availablePaymentMethods
    if (!availablePaymentMethods) availablePaymentMethods = this.paymentService.paymentSettings?.availablePaymentMethods
    if (!availablePaymentMethods || availablePaymentMethods.length == 0) return null
    if (availablePaymentMethods.includes('sepa_debit')) result += 'Sepa-Lastschrift, '
    if (availablePaymentMethods.includes('bank_transfer')) result += 'Banküberweisung, '
    if (availablePaymentMethods.includes('card')) result += 'Kreditkarte, '
    return result.substring(0, result.length - 2)
  }

  async onPaymentMethodSelected(method: string) {
    if (!this.purchase.availablePaymentMethods) this.purchase.availablePaymentMethods = this.paymentService.paymentSettings?.availablePaymentMethods
    if (this.purchase.availablePaymentMethods.includes(method)) {
      this.purchase.availablePaymentMethods = this.purchase.availablePaymentMethods.filter(x => x != method)
    } else {
      this.purchase.availablePaymentMethods.push(method)
    }
  }

  private parseDateString(date:string): Date {
    if(date == null) return null;
    date = date?.replace('T','-');
    var parts = date?.split('-');
    return new Date(+parts[0], +parts[1]-1, +parts[2]);
 }

  getCoachForUser(user: User) {
    return this.coachesOfLicenceHolder.filter(c => c.uid == user.coachUid).shift() || null
  }
  getCoachByUid(uid: string) {
    return this.coachesOfLicenceHolder.filter(c => c.uid == uid).shift() || null
  }

  onAssignCoachToLicence(coach: Coach, licence: Licence) {
    licence.coachUid = coach.uid
  }
  onRedeemLinkCopied() {
    this.toastr.success("Link in Zwischenablage kopiert.", "",  {
      positionClass: 'toast-bottom-center'
    });
  }
  onLicenceCodeCopied() {
    this.toastr.success("Lizenz-Code in Zwischenablage kopiert.", "",  {
      positionClass: 'toast-bottom-center'
    });
  }
  onMessageCopied() {
    this.toastr.success("Nachricht mit Einladung in Zwischenablage kopiert.", "",  {
      positionClass: 'toast-bottom-center'
    });
  }

  onEditInvitationEmail() {
    if (!this.paymentSettings) this.paymentService.paymentSettings = new PaymentSettings()
    var template = this.paymentSettings.customerInvitationEmailTemplate?.clone()
    if (!template) {
      template = this.getDefaultInvitationEmailTemplate()
    }
    const dialogRef = this.dialog.open(EmailTemplateDialogComponent, { data: { template: template, type: 'invitation' }, width: '1000px'})
    dialogRef.afterClosed().subscribe(async result => {
      console.log(result)
      if (result) {
        if (result.template) {
          this.paymentSettings.customerInvitationEmailTemplate = result.template
          await this.onSaveSettings()
        }
        this.updateRedemptionMessage()
      }
    });
  }
  getDefaultInvitationEmailTemplate() {
    var template = new EmailTemplate()
    template.subject = 'Einladung zum Coaching'
    template.heading = 'Einladung zum Coaching'
    template.body = 'Hallo {{Name}},<br><br>'
    + 'es sind nur noch wenige Schritte nötig, um dich mit mir in meiner App zu verbinden und zu starten!<br><br>'
    + 'Lade die App über diesen Link {{LicenceLink}} herunter, öffne sie und gehe direkt die Einrichtungsschritte durch.<br><br><br>'
    + 'Falls das nicht funktioniert hat, kannst du dich in ' + environment.brandName + ' unter Einstellungen -> Account selbst anmelden. Danach erscheint direkt darunter ein Eingabefeld, in das du den folgenden Code kopieren oder eintippen kannst:<br>'
    + '{{LicenceCode}} <br>'
    + 'Danach klickst du auf Einlösen und bist mit mir verbunden.<br><br>Viele Grüße'
    return template
  }
  onEditOfferingEmail() {
    if (!this.paymentSettings) this.paymentService.paymentSettings = new PaymentSettings()
    var template = this.paymentSettings.customerOfferingEmailTemplate?.clone()
    if (!template) {
      template = this.getDefaultOfferingEmailTemplate()
    }
    const dialogRef = this.dialog.open(EmailTemplateDialogComponent, { data: { template: template, type: 'offer' }, width: '1000px'})
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.template) {
          this.paymentSettings.customerOfferingEmailTemplate = result.template
          await this.onSaveSettings()
        }
        this.updateRedemptionMessage()
      }
    });
  }
  getDefaultOfferingEmailTemplate() {
    var template = new EmailTemplate()
    template.subject = 'Einladung zum Coaching'
    template.heading = 'Einladung zum Coaching'
    template.body = 'Hallo {{Name}},<br><br>'
    + 'lass uns gemeinsam loslegen!<br>'
    + 'Hier findest du mein Angebot und kannst dich direkt anmelden, um zu starten: {{PaymentLink}}<br><br>'
    + 'Ich freue mich auf dich!'
    + '<br><br>Viele Grüße'
    return template
  }
  async onSaveSettings() {
    await this.paymentService.onSavePaymentSettings()
  }

  getRedemptionMessage() {
    return (this.paymentSettings?.customerInvitationEmailTemplate ?? this.getDefaultInvitationEmailTemplate()).body.replaceAll('{{Name}}', this.client?.getName() || this.licence.presetName || '').replaceAll('{{LicenceLink}}', this.getRedemptionLink()).replaceAll('{{LicenceCode}}', this.getLicenceForDistribution()?.lid)
  }
  getHeadingText() {
    return (this.paymentSettings?.customerInvitationEmailTemplate ?? this.getDefaultInvitationEmailTemplate()).heading.replaceAll('{{Name}}', this.client?.getName() || this.licence.presetName || '')
  }
  getRedemptionLink() {
    return environment.redeemLinkPrefix + '?action=redeem&lid=' + this.getLicenceForDistribution()?.lid
  }
  getPaymentLink() {
    return environment.baseUrl + '/checkout/' + this.getLicenceForDistribution()?.productPurchaseId
  }

  getPaymentInvitation() {
    return (this.paymentSettings?.customerOfferingEmailTemplate ?? this.getDefaultOfferingEmailTemplate()).body.replaceAll('{{Name}}', this.client?.getName() || this.licence.presetName || '').replaceAll('{{PaymentLink}}', this.getPaymentLink())
  }
}
