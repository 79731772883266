import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SanitizedHtmlPipe } from './../model/event-log.model';
import { Component, Input, OnInit } from '@angular/core';
import { Filesystem, Directory, Encoding, WriteFileResult} from '@capacitor/filesystem'

const CACHE_FOLDER = 'IMG-CACHE'
@Component({
  selector: 'app-cached-image',
  templateUrl: './cached-image.component.html',
  styleUrls: ['./cached-image.component.css']
})
export class CachedImageComponent implements OnInit {

  constructor(private sanitizer: DomSanitizer) { }

  _src: string = '';
  _sanitizedSrc: SafeResourceUrl = null;
  _alt: string = '';
  _originURL = '';
  
  @Input() spinner:boolean = false;
  @Input() cached:boolean = true;


  @Input() set src(url: string){
    if (url == null) return;
    if (url == '') return;
    if (url == this._originURL) return;
    this._originURL = url;
    if(!this.cached){
      this._sanitizedSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      return;
    }
    const imageName = url.split('/').pop();
    const fileType = imageName.split('.').pop();

    Filesystem.readFile(
      {
        directory: Directory.Cache,
        path: CACHE_FOLDER+'/'+imageName
      }
    ).then(readFile => {
      this._src = 'data:image/'+fileType+';base64,'+readFile.data;
      this._sanitizedSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this._src)
    }).catch(async e => {
      console.log("file not exists")
      var writeFileResult = await this.saveImage(url, imageName).catch(async err => {
        console.log(err)
      });
      Filesystem.readFile(
        {
          directory: Directory.Cache,
          path: CACHE_FOLDER+'/'+imageName
        }
      ).then(readFile => {
        this._src =  'data:image/'+fileType+';base64,'+readFile.data;
        this._sanitizedSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this._src)
      }).catch(async err => {
        console.log(err);
        this._sanitizedSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      });

    });
  }

  async saveImage(url: string, name: string):Promise<WriteFileResult>{
    var response = await fetch(url, {credentials: 'same-origin'});
    // var response = await fetch(url, {method: 'GET', mode: 'no-cors', cache: 'no-cache', credentials: 'same-origin', redirect: 'follow', referrerPolicy: 'no-referrer'});

    var blob = await response.blob();
    var base64Data = await this.convertBlobToBase64(blob) as string;
    var result = Filesystem.writeFile({
      path: CACHE_FOLDER+'/'+name,
      data: base64Data,
      directory: Directory.Cache
    })
    return result;
  }


  convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
   reader.readAsDataURL(blob);
  });

  // async check(){
  //   var b = await cache.hasItem({src:this.imageSrc});
  //   // var c = await cache.get({src:this.imageSrc, overwrite: false});
  //   // console.log(c)
  //   console.log(b)
  // }

  @Input() set alt(value: string){
      this._alt = value;
  }
  ngOnInit(): void {
  }

}
