export class Note {
    id: string
    date: Date;
    content: string;
    attachedFileName: string;
    attachmentUrl: string;
    attachment: File
    isMainNote: boolean

    constructor();
    constructor(init: Note);
    constructor(init?: Note) {
        this.id = init && init.id || null
        this.date = init && init.date || null
        this.content = init && init.content || null
        this.attachedFileName = init && init.attachedFileName || null
        this.attachmentUrl = init && init.attachmentUrl || null
        this.isMainNote = init && init.isMainNote || false
    }
    
    hasImageAttachment() {
        return this.attachedFileName != null && (this.attachedFileName.endsWith(".png") || this.attachedFileName.endsWith(".jpg"))
    }
    hasAttachment() {
        return this.attachedFileName != null
    }

    getPrintableDate() {
        return (this.date.getDate() < 10 ? '0' : '') + this.date.getDate() + '.' + (this.date.getMonth() + 1 < 10 ? '0' : '') + (this.date.getMonth() + 1) + '.' + this.date.getFullYear() + ', ' + (this.date.getHours() < 10 ? '0' : '') + this.date.getHours() + ':' + (this.date.getMinutes() < 10 ? '0' : '') + this.date.getMinutes()
    }

    clone(): Note {
        var note = new Note()
        note.id = this.id
        note.date = this.date
        note.content = this.content
        note.attachedFileName = this.attachedFileName
        note.attachmentUrl = this.attachmentUrl
        note.attachment = this.attachment
        note.isMainNote = this.isMainNote
        return note
    }
}