import { PlannedFood } from './plannedfood.model';
import { BaseNutritionFact, NutritionalValue, NutritionalValueEditable } from './basenutritionfact.model';
import { Situation } from './nutritionconfig.model';
import { MealType, MealTypeTranslation } from './nutritionplanv2.model';
import { Recipe } from './recipe.model';
import { LanguageDictionary } from './languagedictionary.model';
import { Ingredient } from './ingredient.model';

export class PlannedMealV2 implements NutritionalValueEditable {

    id: string
    date: Date
    number: number
    nutritionPlanId: string
    dayNumber: number

    isRepeating: boolean
    repetitionDayNumber: number
    isAlternative: boolean

    name: string
    nameTranslation: LanguageDictionary<string>
    type: string
    customType: string
    mealConfigId: string
    thumbnailPath: string
    foods: PlannedFood[]

    comment: string
    instructions: string
    instructionsTranslation: LanguageDictionary<string>
    note: string
    mealTemplateId: string
    imageURL: string
    handled: boolean
    trackedMealId: number
    replacedMealId: number

    baseMealTemplateId: string
    baseMealTemplateServingMultiplier: number // When set, the meal is based on a recipe and the multiplier is used to calculate the nutritional values and ingredients

    // Temporary:
    baseRecipe: Recipe
    baseRecipeFoods: PlannedFood[]
    nutritionFactsLoaded = false

    // NutritionalValueHolder:

    calories: number
    carbohydrates: number
    protein: number
    fat: number
    sugar: number
    polyols: number
    fibre: number
    saturatedFat: number
    monounsaturatedFat: number
    polyunsaturatedFat: number
    transFat: number
    omega3Fat: number
    omega6Fat: number
    vitaminA: number
    vitaminC: number
    vitaminD: number
    vitaminB1: number
    vitaminB2: number
    vitaminB3: number
    vitaminB5: number
    vitaminB6: number
    vitaminB7: number
    vitaminB9: number
    vitaminB12: number
    vitaminE: number
    vitaminK: number
    iron: number
    magnesium: number
    zinc: number
    calcium: number
    sodium: number
    potassium: number
    phosphorus: number
    sulphur: number
    chlorine: number
    copper: number
    manganese: number
    fluorine: number
    iodine: number
    minerals: number
    organicAcids: number
    mannitol: number
    sorbitol: number
    xylitol: number
    glucose: number
    fructose: number
    galactose: number
    sucrose: number
    maltose: number
    lactose: number
    glycogen: number
    starch: number
    monosaccharides: number
    disaccharides: number
    oligosaccharides: number
    polysaccharides: number
    waterSolubleFibre: number
    waterInsolubleFibre: number
    essentialAminoAcids: number
    nonessentialAminoAcids: number
    leucine: number
    isoleucine: number
    valine: number
    uricAcid: number
    purine: number
    linoleicAcid: number
    alphaLinolenicAcid: number
    arachidonicAcid: number
    eicosapentaenoicAcid: number
    docosahexaenoicAcid: number
    cholesterol: number
    salt: number
    alcoholContent: number
    alcohol: number
    water: number

    constructor();
    constructor(init: PlannedMealV2);
    constructor(init?: PlannedMealV2) {
        this.id = init && init.id || null
        this.date = init && init.date || null
        this.dayNumber = init && init.dayNumber || 0
        this.number = init && init.number || 0
        this.nutritionPlanId = init && init.nutritionPlanId || null
        this.isRepeating = init && init.isRepeating || false
        this.repetitionDayNumber = init && init.repetitionDayNumber || 0
        this.isAlternative = init && init.isAlternative || false
        this.name = init && init.name || null
        this.nameTranslation = init && init.nameTranslation != null ? new LanguageDictionary(init.nameTranslation.de, init.nameTranslation.en) : null
        this.type = init && init.type || MealType.none
        this.customType = init && init.customType || null
        this.mealConfigId = init && init.mealConfigId || null
        if (init && init.foods) {
            this.foods = init && init.foods?.map(x => new PlannedFood(x)) || null
        } else {
            this.foods = null
        }

        this.comment = init && init.comment || null
        this.instructions = init && init.instructions || null
        this.instructionsTranslation = init && init.instructionsTranslation != null ? new LanguageDictionary(init.instructionsTranslation.de, init.instructionsTranslation.en) : null
        this.note = init && init.note || null
        this.thumbnailPath = init && init.thumbnailPath != null ? init.thumbnailPath : null
        this.imageURL = init && init.imageURL || null
        this.handled = init && init.handled || false
        this.trackedMealId = init && init.trackedMealId || null
        this.replacedMealId = init && init.replacedMealId || null

        this.baseMealTemplateId = init && init.baseMealTemplateId || null
        this.baseMealTemplateServingMultiplier = init && init.baseMealTemplateServingMultiplier || null
        this.baseRecipe = init && init.baseRecipe || null
        this.baseRecipeFoods = init && init.baseRecipeFoods || null
    }


    getType(): string {
        if (this.type && this.type != MealType.custom) {
            return MealTypeTranslation[this.type]
        }
        return this.customType
    }

    getName(languageCode: string = 'de'): string {
        if (this.nameTranslation && this.nameTranslation[languageCode]) return this.nameTranslation[languageCode]
        return this.name ?? this.baseRecipe?.getName(languageCode) ?? ''
    }

    getInstructions(languageCode: string = 'de') {
        if (this.instructionsTranslation && this.instructionsTranslation[languageCode]) return this.instructionsTranslation[languageCode]
        if (this.instructions != null) return this.instructions
        return this.baseRecipe?.getInstructions(languageCode) ?? null
    }

    getThumbnailPath() {
        if (this.thumbnailPath != null) return this.thumbnailPath
        if (this.baseRecipe) return this.baseRecipe?.getThumbnailPath()
        return null
    }
    isThumbnailPathFromCommonFirebase() {
        if (this.thumbnailPath != null) return false
        if (this.baseRecipe?.getThumbnailPath() != null) return this.baseRecipe.isThumbnailPathFromCommonFirebase()
        return false
    }

    getFoods() {
        return this.foods ?? this.baseRecipeFoods ?? this.baseRecipe?.getIngredients()
    }

    recalculateBaseRecipeFoods() {
        if (!this.foods && this.baseRecipe && this.baseMealTemplateServingMultiplier) {
            var foods = []
            //var firstFood: PlannedFood
            this.baseRecipe.getIngredients().forEach(ingredient => {
                var food = new PlannedFood()
                food.nutritionFactId = ingredient.nutritionFactId
                food.nutritionFact = ingredient.nutritionFact
                food.servingSize = ingredient.servingSize
                food.name = ingredient.name
                food.isDummy = ingredient.isDummy
                food.groupHeading = ingredient.groupHeading
                if (ingredient.isDummy) {
                    food.weight = ingredient.weight
                    if (food.weight) food.weight = (ingredient.weight / this.baseRecipe.getServings() * this.baseMealTemplateServingMultiplier).roundToPlaces(0)
                    food.calories = (ingredient.nutritionalSummary.calories / this.baseRecipe.getServings() * this.baseMealTemplateServingMultiplier).roundToPlaces(0)
                    food.carbohydrates = (ingredient.nutritionalSummary.carbohydrates / this.baseRecipe.getServings() * this.baseMealTemplateServingMultiplier).roundToPlaces(0)
                    food.protein = (ingredient.nutritionalSummary.protein / this.baseRecipe.getServings() * this.baseMealTemplateServingMultiplier).roundToPlaces(0)
                    food.fat = (ingredient.nutritionalSummary.fat / this.baseRecipe.getServings() * this.baseMealTemplateServingMultiplier).roundToPlaces(0)
                } else {
                    food.weight = (ingredient.weight / this.baseRecipe.getServings() * this.baseMealTemplateServingMultiplier).roundToPlaces(0)
                }
                foods.push(food)
            })
            this.baseRecipeFoods = foods
            this.recalculateNutritionalValues()
        }
    }

    recalculateNutritionalValues() {
        if (this.foods && this.nutritionFactsLoaded || this.baseRecipe?.getIngredients() && this.baseRecipe?.nutritionFactsLoaded) {
            var foods = this.foods ? (this.nutritionFactsLoaded ? this.foods : null) : this.getFoods()
            if (foods) {
                BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
                    var value = 0
                    foods.forEach(ingredient => {
                        if (ingredient instanceof PlannedFood) {
                            value += ingredient.getNutritionalValue(nutritionalValue) || 0
                        } else if (ingredient instanceof Ingredient) {
                            value += ingredient.getNutritionalValue(nutritionalValue) || 0
                        }
                    })
                    this.setNutritionalValue(nutritionalValue, value)
                })
            }
        } else if (this.baseRecipe && this.baseMealTemplateServingMultiplier && !this.foods) {
            this.calories = this.baseRecipe.getCalories() * this.baseMealTemplateServingMultiplier
            this.carbohydrates = this.baseRecipe.getCarbohydrates() * this.baseMealTemplateServingMultiplier
            this.protein = this.baseRecipe.getProtein() * this.baseMealTemplateServingMultiplier
            this.fat = this.baseRecipe.getFat() * this.baseMealTemplateServingMultiplier
        }
    }

    getCarbohydrates() {
        return this.getNutritionalValue('carbohydrates')
    }
    getProtein() {
        return this.getNutritionalValue('protein')
    }
    getFat() {
        return this.getNutritionalValue('fat')
    }
    getCalories() {
        return this.getNutritionalValue('calories')
    }

    setNutritionalValue(nutritionalValue: string, value: number) {
        switch (NutritionalValue[nutritionalValue].toString()) {
            case NutritionalValue.calories.toString(): this.calories = value; break;
            case NutritionalValue.carbohydrates.toString(): this.carbohydrates = value; break;
            case NutritionalValue.protein.toString(): this.protein = value; break;
            case NutritionalValue.fat.toString(): this.fat = value; break;
            case NutritionalValue.sugar.toString(): this.sugar = value; break;
            case NutritionalValue.polyols.toString(): this.polyols = value; break;
            case NutritionalValue.fibre.toString(): this.fibre = value; break;
            case NutritionalValue.saturatedFat.toString(): this.saturatedFat = value; break;
            case NutritionalValue.monounsaturatedFat.toString(): this.monounsaturatedFat = value; break;
            case NutritionalValue.polyunsaturatedFat.toString(): this.polyunsaturatedFat = value; break;
            case NutritionalValue.transFat.toString(): this.transFat = value; break;
            case NutritionalValue.omega3Fat.toString(): this.omega3Fat = value; break;
            case NutritionalValue.omega6Fat.toString(): this.omega6Fat = value; break;
            case NutritionalValue.vitaminA.toString(): this.vitaminA = value; break;
            case NutritionalValue.vitaminC.toString(): this.vitaminC = value; break;
            case NutritionalValue.vitaminD.toString(): this.vitaminD = value; break;
            case NutritionalValue.vitaminB1.toString(): this.vitaminB1 = value; break;
            case NutritionalValue.vitaminB2.toString(): this.vitaminB2 = value; break;
            case NutritionalValue.vitaminB3.toString(): this.vitaminB3 = value; break;
            case NutritionalValue.vitaminB5.toString(): this.vitaminB5 = value; break;
            case NutritionalValue.vitaminB6.toString(): this.vitaminB6 = value; break;
            case NutritionalValue.vitaminB7.toString(): this.vitaminB7 = value; break;
            case NutritionalValue.vitaminB9.toString(): this.vitaminB9 = value; break;
            case NutritionalValue.vitaminB12.toString(): this.vitaminB12 = value; break;
            case NutritionalValue.vitaminE.toString(): this.vitaminE = value; break;
            case NutritionalValue.vitaminK.toString(): this.vitaminK = value; break;
            case NutritionalValue.iron.toString(): this.iron = value; break;
            case NutritionalValue.magnesium.toString(): this.magnesium = value; break;
            case NutritionalValue.zinc.toString(): this.zinc = value; break;
            case NutritionalValue.calcium.toString(): this.calcium = value; break;
            case NutritionalValue.sodium.toString(): this.sodium = value; break;
            case NutritionalValue.potassium.toString(): this.potassium = value; break;
            case NutritionalValue.phosphorus.toString(): this.phosphorus = value; break;
            case NutritionalValue.sulphur.toString(): this.sulphur = value; break;
            case NutritionalValue.chlorine.toString(): this.chlorine = value; break;
            case NutritionalValue.copper.toString(): this.copper = value; break;
            case NutritionalValue.manganese.toString(): this.manganese = value; break;
            case NutritionalValue.fluorine.toString(): this.fluorine = value; break;
            case NutritionalValue.iodine.toString(): this.iodine = value; break;
            case NutritionalValue.minerals.toString(): this.minerals = value; break;
            case NutritionalValue.organicAcids.toString(): this.organicAcids = value; break;
            case NutritionalValue.mannitol.toString(): this.mannitol = value; break;
            case NutritionalValue.sorbitol.toString(): this.sorbitol = value; break;
            case NutritionalValue.xylitol.toString(): this.xylitol = value; break;
            case NutritionalValue.glucose.toString(): this.glucose = value; break;
            case NutritionalValue.fructose.toString(): this.fructose = value; break;
            case NutritionalValue.galactose.toString(): this.galactose = value; break;
            case NutritionalValue.sucrose.toString(): this.sucrose = value; break;
            case NutritionalValue.maltose.toString(): this.maltose = value; break;
            case NutritionalValue.lactose.toString(): this.lactose = value; break;
            case NutritionalValue.glycogen.toString(): this.glycogen = value; break;
            case NutritionalValue.starch.toString(): this.starch = value; break;
            case NutritionalValue.monosaccharides.toString(): this.monosaccharides = value; break;
            case NutritionalValue.disaccharides.toString(): this.disaccharides = value; break;
            case NutritionalValue.oligosaccharides.toString(): this.oligosaccharides = value; break;
            case NutritionalValue.polysaccharides.toString(): this.polysaccharides = value; break;
            case NutritionalValue.waterSolubleFibre.toString(): this.waterSolubleFibre = value; break;
            case NutritionalValue.waterInsolubleFibre.toString(): this.waterInsolubleFibre = value; break;
            case NutritionalValue.essentialAminoAcids.toString(): this.essentialAminoAcids = value; break;
            case NutritionalValue.nonessentialAminoAcids.toString(): this.nonessentialAminoAcids = value; break;
            case NutritionalValue.leucine.toString(): this.leucine = value; break;
            case NutritionalValue.isoleucine.toString(): this.isoleucine = value; break;
            case NutritionalValue.valine.toString(): this.valine = value; break;
            case NutritionalValue.uricAcid.toString(): this.uricAcid = value; break;
            case NutritionalValue.purine.toString(): this.purine = value; break;
            case NutritionalValue.linoleicAcid.toString(): this.linoleicAcid = value; break;
            case NutritionalValue.alphaLinolenicAcid.toString(): this.alphaLinolenicAcid = value; break;
            case NutritionalValue.arachidonicAcid.toString(): this.arachidonicAcid = value; break;
            case NutritionalValue.eicosapentaenoicAcid.toString(): this.eicosapentaenoicAcid = value; break;
            case NutritionalValue.docosahexaenoicAcid.toString(): this.docosahexaenoicAcid = value; break;
            case NutritionalValue.cholesterol.toString(): this.cholesterol = value; break;
            case NutritionalValue.salt.toString(): this.salt = value; break;
            case NutritionalValue.alcoholContent.toString(): this.alcoholContent = value; break;
            case NutritionalValue.alcohol.toString(): this.alcohol = value; break;
            case NutritionalValue.water.toString(): this.water = value; break;
            
            default: break
        }
    }

    getNutritionalValue(nutritionalValue: string): number {
        if (this.calories != null) {
            switch (NutritionalValue[nutritionalValue].toString()) {
                case NutritionalValue.calories.toString(): return this.calories
                case NutritionalValue.carbohydrates.toString(): return this.carbohydrates
                case NutritionalValue.protein.toString(): return this.protein
                case NutritionalValue.fat.toString(): return this.fat
                case NutritionalValue.sugar.toString(): return this.sugar
                case NutritionalValue.polyols.toString(): return this.polyols
                case NutritionalValue.fibre.toString(): return this.fibre
                case NutritionalValue.saturatedFat.toString(): return this.saturatedFat
                case NutritionalValue.monounsaturatedFat.toString(): return this.monounsaturatedFat
                case NutritionalValue.polyunsaturatedFat.toString(): return this.polyunsaturatedFat
                case NutritionalValue.transFat.toString(): return this.transFat
                case NutritionalValue.omega3Fat.toString(): return this.omega3Fat
                case NutritionalValue.omega6Fat.toString(): return this.omega6Fat
                case NutritionalValue.vitaminA.toString(): return this.vitaminA
                case NutritionalValue.vitaminC.toString(): return this.vitaminC
                case NutritionalValue.vitaminD.toString(): return this.vitaminD
                case NutritionalValue.vitaminB1.toString(): return this.vitaminB1
                case NutritionalValue.vitaminB2.toString(): return this.vitaminB2
                case NutritionalValue.vitaminB3.toString(): return this.vitaminB3
                case NutritionalValue.vitaminB5.toString(): return this.vitaminB5
                case NutritionalValue.vitaminB6.toString(): return this.vitaminB6
                case NutritionalValue.vitaminB7.toString(): return this.vitaminB7
                case NutritionalValue.vitaminB9.toString(): return this.vitaminB9
                case NutritionalValue.vitaminB12.toString(): return this.vitaminB12
                case NutritionalValue.vitaminE.toString(): return this.vitaminE
                case NutritionalValue.vitaminK.toString(): return this.vitaminK
                case NutritionalValue.iron.toString(): return this.iron
                case NutritionalValue.magnesium.toString(): return this.magnesium
                case NutritionalValue.zinc.toString(): return this.zinc
                case NutritionalValue.calcium.toString(): return this.calcium
                case NutritionalValue.sodium.toString(): return this.sodium
                case NutritionalValue.potassium.toString(): return this.potassium
                case NutritionalValue.phosphorus.toString(): return this.phosphorus
                case NutritionalValue.sulphur.toString(): return this.sulphur
                case NutritionalValue.chlorine.toString(): return this.chlorine
                case NutritionalValue.copper.toString(): return this.copper
                case NutritionalValue.manganese.toString(): return this.manganese
                case NutritionalValue.fluorine.toString(): return this.fluorine
                case NutritionalValue.iodine.toString(): return this.iodine
                case NutritionalValue.minerals.toString(): return this.minerals
                case NutritionalValue.organicAcids.toString(): return this.organicAcids
                case NutritionalValue.mannitol.toString(): return this.mannitol
                case NutritionalValue.sorbitol.toString(): return this.sorbitol
                case NutritionalValue.xylitol.toString(): return this.xylitol
                case NutritionalValue.glucose.toString(): return this.glucose
                case NutritionalValue.fructose.toString(): return this.fructose
                case NutritionalValue.galactose.toString(): return this.galactose
                case NutritionalValue.sucrose.toString(): return this.sucrose
                case NutritionalValue.maltose.toString(): return this.maltose
                case NutritionalValue.lactose.toString(): return this.lactose
                case NutritionalValue.glycogen.toString(): return this.glycogen
                case NutritionalValue.starch.toString(): return this.starch
                case NutritionalValue.monosaccharides.toString(): return this.monosaccharides
                case NutritionalValue.disaccharides.toString(): return this.disaccharides
                case NutritionalValue.oligosaccharides.toString(): return this.oligosaccharides
                case NutritionalValue.polysaccharides.toString(): return this.polysaccharides
                case NutritionalValue.waterSolubleFibre.toString(): return this.waterSolubleFibre
                case NutritionalValue.waterInsolubleFibre.toString(): return this.waterInsolubleFibre
                case NutritionalValue.essentialAminoAcids.toString(): return this.essentialAminoAcids
                case NutritionalValue.nonessentialAminoAcids.toString(): return this.nonessentialAminoAcids
                case NutritionalValue.leucine.toString(): return this.leucine
                case NutritionalValue.isoleucine.toString(): return this.isoleucine
                case NutritionalValue.valine.toString(): return this.valine
                case NutritionalValue.uricAcid.toString(): return this.uricAcid
                case NutritionalValue.purine.toString(): return this.purine
                case NutritionalValue.linoleicAcid.toString(): return this.linoleicAcid
                case NutritionalValue.alphaLinolenicAcid.toString(): return this.alphaLinolenicAcid
                case NutritionalValue.arachidonicAcid.toString(): return this.arachidonicAcid
                case NutritionalValue.eicosapentaenoicAcid.toString(): return this.eicosapentaenoicAcid
                case NutritionalValue.docosahexaenoicAcid.toString(): return this.docosahexaenoicAcid
                case NutritionalValue.cholesterol.toString(): return this.cholesterol
                case NutritionalValue.salt.toString(): return this.salt
                case NutritionalValue.alcoholContent.toString(): return this.alcoholContent
                case NutritionalValue.alcohol.toString(): return this.alcohol
                case NutritionalValue.water.toString(): return this.water
            
                default: return null
            }
        } else if (this.baseRecipe) {
            return this.baseRecipe.getNutritionalValue(nutritionalValue)
        }
    }

    getPrintableNutritionalValues() {
        return this.getNutritionalValue('carbohydrates')?.toFixed(0) + 'g K / ' + this.getNutritionalValue('protein')?.toFixed(0) + 'g E / ' + this.getNutritionalValue('fat')?.toFixed(0) + 'g F / ' + this.getNutritionalValue('calories')?.toFixed(0) + ' kcal'
    }

    getFoodByIngredientId(ingredientId: string): PlannedFood {
        return this.foods.find(f => f.sourceIngredientId == ingredientId)
    }

    clone() {
        var meal = new PlannedMealV2()
        meal.id = this.id
        meal.number = this.number
        meal.date = this.date
        meal.nutritionPlanId = this.nutritionPlanId
        meal.isRepeating = this.isRepeating
        meal.repetitionDayNumber = this.repetitionDayNumber
        meal.isAlternative = this.isAlternative
        meal.comment = this.comment
        meal.instructions = this.instructions
        meal.instructionsTranslation = this.instructionsTranslation?.clone()
        meal.note = this.note
        meal.name = this.name
        meal.nameTranslation = this.nameTranslation?.clone()
        meal.type = this.type
        meal.customType = this.customType
        meal.mealConfigId = this.mealConfigId
        meal.foods = this.foods?.map(f => f.clone()) ?? null
        meal.mealTemplateId = this.mealTemplateId
        meal.imageURL = this.imageURL
        meal.thumbnailPath = this.thumbnailPath
        meal.baseMealTemplateId = this.baseMealTemplateId
        meal.baseMealTemplateServingMultiplier = this.baseMealTemplateServingMultiplier
        meal.baseRecipe = this.baseRecipe
        meal.baseRecipeFoods = this.baseRecipeFoods?.map(f => f.clone()) ?? null
        meal.nutritionFactsLoaded = this.nutritionFactsLoaded
        BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
            meal.setNutritionalValue(nutritionalValue, this.getNutritionalValue(nutritionalValue))
        })
        return meal
    }

    toMap() {
        var data = {
            date: this.date,
            dayNumber: this.dayNumber,
            number: this.number,
            nutritionPlanId: this.nutritionPlanId,
            isRepeating: this.isRepeating,
            repetitionDayNumber: this.repetitionDayNumber,
            isAlternative: this.isAlternative,
            type: this.type,
            customType: this.customType,
            mealConfigId: this.mealConfigId,
            timestamp: new Date()
        }

        //this.recalculateNutritionalValues()
        if (this.foods) {
            BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
                if (this.getNutritionalValue(nutritionalValue) != null && this.getNutritionalValue(nutritionalValue) != undefined) {
                    data[nutritionalValue] = this.getNutritionalValue(nutritionalValue)
                }
            })
        }

        if (this.baseMealTemplateId) {
            data['baseMealTemplateId'] = this.baseMealTemplateId
            data['baseMealTemplateServingMultiplier'] = this.baseMealTemplateServingMultiplier

            if (this.name != null) data['name'] = this.name
            if (this.nameTranslation) data['nameTranslation'] = this.nameTranslation?.AsMap() || null
            if (this.thumbnailPath != null) data['thumbnailPath'] = this.thumbnailPath
            if (this.note != null) data['note'] = this.note
            if (this.instructions != null) data['instructions'] = this.instructions
            if (this.instructionsTranslation?.de != null) data['instructions'] = this.instructionsTranslation?.de
            if (this.instructionsTranslation) data['instructionsTranslation'] = this.instructionsTranslation?.AsMap() || null
            if (this.foods) {
                data['foods'] = this.foods?.map(f => f.toMap()) || null
            }

            return data
        }

        data['name'] = this.name
        data['nameTranslation'] = this.nameTranslation?.AsMap() || null
        data['thumbnailPath'] = this.thumbnailPath
        data['note'] = this.note
        data['instructions'] = this.instructions
        data['instructionsTranslation'] = this.instructionsTranslation?.AsMap() || null
        data['foods'] = this.foods?.map(f => f.toMap()) || null
        
        return data
    }
}
