import { Component, Input, OnInit, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { Chat } from '../model/chat.model';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.css']
})
export class AudioPlayerComponent implements OnInit, AfterViewInit {

  public currentSrc: string = "";

  public isDisabled: boolean
  public isLoaded: boolean = false

  @Input() playerId: string;
  @Input() set playingId(id: string) {
    if(id !== this.playerId) {
      this.pause()
    }
  }
  @Output() playingIdChange = new EventEmitter<string>()
  
  @Output() audioPlayerRef = new EventEmitter<AudioPlayerComponent>()

  @Input() set canPlay(value: boolean) {
    if(!value && this.playing) {
      this.pause();
    }
    if (!value) this.isDisabled = true
  }

  @Input() set chat(chat: Chat) {
    
  }

  @Input() set src(value: string) {
    if(this.currentSrc != value) this.audio?.pause()
    if(!value) {
      this.isDisabled = true;
      this.audio.src = ""
      return;
    }
    this.audio.src = value;
    this.audio.load();

    this.audio.addEventListener('loadedmetadata', () => {
      this.setCurrentTime()
      this.setSliderMax()
      this.isLoaded = true
    });

    this.isDisabled = false;    
  }

  public audio: HTMLAudioElement = new Audio();
  public currentPrintableTime: string = "00:00"
  public currentTime: number = 0

  public seekMax: number = 100;

  public buffered: number = 0;
  public seekable: number = 0;

  public playing: boolean = false;

  constructor() { }

  
  ngAfterViewInit(): void {
    this.audioPlayerRef.emit(this)
  }

  ngOnInit(): void {
  }

  setCurrentTime() {
    if (this.playing || this.audio.currentTime > 0) {
      this.currentTime = this.audio.currentTime
      this.currentPrintableTime = new Date(this.audio.currentTime * 1000).toISOString().substring(14, 19)
      if(this.audio.ended){
        this.togglePlay()
        this.audio.currentTime = 0
        this.currentTime = 0
      }
    } else {
      this.currentPrintableTime = new Date(this.audio.duration * 1000).toISOString().substring(14, 19)
    }
  }

  setSliderMax() {
    this.seekMax = Math.floor(this.audio.duration);
  }

  setBuffered(){
    this.buffered = this.audio.buffered.end(this.audio.buffered.length - 1);
  }

  setSeekable(){
    this.seekable = this.audio.seekable.end(this.audio.seekable.length - 1);
  }

  changeSeek(value: number) {
    this.audio.currentTime = value
    this.setCurrentTime()
  }



  private interval: NodeJS.Timeout;
  public togglePlay(){
    if(!this.playing) {
      this.play();
    } else {
      this.pause();
    }
  }


  public play(){
    this.playingIdChange.emit(this.playerId)
    this.audio.play();
    this.playing = true;
    this.interval = setInterval(() => {
      this.setCurrentTime()
    }, 100)
  }

  public pause() {
    clearTimeout(this.interval)
    this.interval = null
    this.audio.pause();
    this.playing = false;
  }

  


  public playbackRate: number = 1;
  public changePlaybackRate(){
    if(this.playbackRate === 1) this.audio.playbackRate = 1.5;
    if(this.playbackRate === 1.5) this.audio.playbackRate = 2;
    if(this.playbackRate === 2) this.audio.playbackRate = 1;

    this.playbackRate = this.audio.playbackRate;
  }

}
