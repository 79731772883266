<div class="{{environment.firebaseProjectId}} {{navService.collapsed ? 'navigation-collapsed' : ''}} darkmode">
    <div (window:resize)="onResize($event)"></div>
    <div *ngIf="router.url.includes('/body-data')" class="powered-by-nutrilize">
        <button *ngIf="!router.url.includes('/login')" class="logout-button button-small" (click)="logoutBodyData()" routerLink="/login/body-data">Ausloggen</button>
        <img draggable="false" src="assets/images/powered_by_nutrilize.png">
    </div>

    <div *ngIf="userService.getLoggedInUser()?.isCoach && !isInPublicView() && environment.firebaseProjectId != 'traindoo-app'" class="{{utilityService.onNativeMobileApp() ? 'mobile-app' : ''}}">
        <app-task-management></app-task-management>
    </div>

    <div *ngIf="utilityService.onNativeMobileApp() && !isInPublicView()">
        <div class="page-content tab-nav-container">
            <div class="tab {{navService.getDashboard()}}">
                <button routerLink="/dashboard">
                    <i class="bi bi-house"></i>
                </button>
            </div>
            <ng-container *ngIf="userService.getLoggedInUser()?.isCoach && !userService.getLoggedInUser()?.disabled">
                <div class="tab {{navService.getChat()}}">
                    <button routerLink="/chat">
                        <i class="bi bi-chat-left-dots"></i>
                    </button>
                    <div *ngIf="chatService.getUnreadChats()?.length > 0" class="unread-messages-indicator"><span *ngIf="chatService.getUnreadChats()?.length < 10">{{chatService.getUnreadChats()?.length}}</span></div>
                </div>
                <div class="tab {{navService.getCoach()}}">
                    <button routerLink="/clients">
                        <i class="bi bi-people"></i>
                    </button>
                </div>
                <div class="tab {{navService.getLicence()}}">
                    <button routerLink="/licence">
                        <i class="bi bi-person-plus"></i>
                    </button>
                </div>
                <div class="tab {{navService.getSettings()}}">
                    <button routerLink="/settings">
                        <i class="bi bi-gear"></i>
                    </button>
                </div>
            </ng-container>
        </div>
    </div>

    <app-navigation-bar *ngIf="environment.firebaseProjectId != 'traindoo-app' && !isInPublicView()" (logoutEvent)="logout()"></app-navigation-bar>
    <app-navigation-bar-traindoo *ngIf="environment.firebaseProjectId == 'traindoo-app' && !isInPublicView()" (logoutEvent)="logout()"></app-navigation-bar-traindoo>

    <div *ngIf="showHelpDialog" id="help-dialog" class="dialog-wrapper show">
        <div class="dialog mobile-fullscreen layout-box">
            <div class="dialog-topbar">
                <div class="row">
                    <div class="col">
                        <h2 class="margin-0">Video-Anleitungen für Coaches</h2>
                    </div>
                    <div class="col-2">
                        <div class="icon-button close float-right" (click)="toggleHelpDialog()">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dialog-content-wrapper">
                <div class="">
                    <ul class="tutorial-list">
                        <li *ngFor="let video of tutorialVideos;let i=index;" [ngClass]="{'open': visibleVideoNumber != null && visibleVideoNumber == i}">
                            <button type="button" class="" (click)="onShowVideo(i)">
                                <h3 class="display-inline">{{video.name}}</h3>
                            </button>
                            <div class="spacer-5"></div>
                            <div #video1 *ngIf="visibleVideoNumber != null && visibleVideoNumber == i">
                                <div style="position: relative; padding-bottom: 54.166666666666664%; height: 0;"><iframe [src]="video.url" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>
                            </div>
                            <div *ngIf="video.subtitle">{{video.subtitle}}</div>
                            <div class="spacer-20"></div>
                        </li>
                    </ul>
                </div>
                <div class="spacer-10"></div>
                <div class="container">
                    <h3>Außerdem haben wir Videoanleitungen für die App erstellt, die du teilen kannst mit deinen Coachees:</h3>
                    <a href="https://loom.com/share/folder/9e820e6b593349d7b364c9fac35f1c48" target="_blank" class="preview-link">
                        <div class="icon-button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                            </svg>
                            Tutorials aufrufen
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div id="app-loading">
        <div class="progress">
        <div class="progress-value"></div>
        </div>
        <div class="spacer-25"></div>
        <p>Loading Coaching Zone...</p>
    </div>
    <router-outlet></router-outlet>
</div>