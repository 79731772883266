<div class="page-content">
    <div *ngIf="chatService?.getChats()" class="container-fluid">
        <h2 class="">Deine Chats</h2>
        <div class="row">
            <input id="clientsearch-input" class="input wide white client col" (input)="onClientSearchInputChanged($event.target.value)" placeholder="Suchen" type="text">
            <div *ngIf="clientSearchInput?.length > 0" class="icon-button deletesearch-icon delete-clientsearch" (click)="onDeleteClientSearchInput()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </div>
            <div class="chat-filter-dropdown-wrapper col-auto">
              <button class="button-filled light small dropdown-toggle margin-inbetween m-0" type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getPrintableFilteredClients()}}</button>
              <div class="dropdown-menu dropdown-notificationTarget" aria-labelledby="dropdownNotificationTarget">
                  <div *ngIf="selectableGroupNames[0]?.name == 'Alle'" class="dropdown-item link" (click)="onFilterSelectionChangedGroup(selectableGroupNames[0], !selectableGroupNames[0].checked);$event.stopPropagation()">
                      <input class="input checkbox" type="checkbox" [checked]="selectableGroupNames[0].checked"><div class="display-inlineblock">{{selectableGroupNames[0].name}}</div>
                  </div>
                  <div class="dropdown-divider"></div>
                  <div class="label marginleft-10">Coaches</div>
                  <div class="dropdown-item link" *ngFor="let group of selectableCoachGroups" (click)="onFilterSelectionChangedCoachGroup(group, !group.checked);$event.stopPropagation()">
                      <input class="input checkbox" type="checkbox" [checked]="group.checked"><div class="display-inlineblock">{{group.name}}</div>
                  </div>
                  <div class="dropdown-divider"></div>
                  <div class="label marginleft-10">Gruppen</div>
                  <ng-container *ngFor="let group of selectableGroupNames">
                      <div *ngIf="group.name != 'Alle'" class="dropdown-item link" (click)="onFilterSelectionChangedGroup(group, !group.checked);$event.stopPropagation()">
                          <input class="input checkbox" type="checkbox" [checked]="group.checked"><div class="display-inlineblock">{{group.name}}</div>
                      </div>
                  </ng-container>
              </div>
            </div>
            <div class="broadcast-icon link  ml-auto mr-3" (click)="openBraodcastChat();$event.stopPropagation()" tooltip="Broadcast-Nachricht versenden">
              <i class="bi bi-megaphone"></i>
            </div>
        </div>
        <div *ngFor="let c of chatService.getFilteredChats(clientSearchInput, getSelectedCoachUids(), getSelectedGroupNames())">
            <div class="layout-box chat-box container-fluid" (click)="chatService.openChat(c)">
                <div class="row">
                    <div class="chat-image-col">
                        <div class="profile-picture">
                            <img draggable="false" src="{{c?.chatPartner?.profilePictureUrl}}">
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <strong>{{c.chatPartner.getName()}}</strong>
                            </div>
                            <div>
                                <div *ngIf="c?.messages?.length > 0">{{dateFormatter(c.messages[0]?.time)}}</div>
                            </div>
                        </div>
                        <div class="row" *ngIf="c?.messages?.length > 0">
                            <div class="col" *ngIf="!c.messages[0]?.isDeleted">
                                <div *ngIf="c.messages[0]?.hasAttachment()">
                                    <div *ngIf="c.messages[0]?.hasImageAttachment()">
                                        <i class="bi bi-image"></i>&nbsp;
                                    </div>
                                    <div *ngIf="c.messages[0]?.hasAudioAttachment(); else file_attached">
                                        <i class="bi bi-mic"></i>
                                        {{c.messages[0]?.getAudioDuration()}}
                                    </div>
                                    <ng-template #file_attached><i class="bi bi-file-text"></i>&nbsp;</ng-template>
                                </div>
                                <div class="message-preview">{{c.messages[0]?.message}}</div>
                            </div>
                            <div class="col" *ngIf="c.messages[0]?.isDeleted === true">
                                <div class="deleted-message">
                                    <svg id="slash-circle" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
                                        <path id="Pfad_113" data-name="Pfad 113" d="M8,15a7,7,0,1,1,7-7,7,7,0,0,1-7,7Zm0,1A8,8,0,1,0,0,8,8,8,0,0,0,8,16Z"/>
                                        <path id="Pfad_114" data-name="Pfad 114" d="M13.438,2.562a.5.5,0,0,0-.708,0L2.56,12.732a.5.5,0,1,0,.708.708L13.438,3.27a.5.5,0,0,0,0-.708Z"/>
                                    </svg>
                                    <ng-container *ngIf="c.messages[0]?.uid != this.authService.user.uid; else mymessagedeleted;">Diese Nachricht wurde gelöscht.</ng-container>
                                    <ng-template #mymessagedeleted>Du hast diese Nachricht gelöscht.</ng-template>
                                </div>
                            </div>
                            <div>
                                <div *ngIf="c.unreadMessages?.length > 0" class="message-indication single-chat glyphicon"></div>
                            </div>
                            <div>
                                <button mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                      <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                    </svg>
                                  </button>
                                  <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="chatService.markLastMessageAsUnread(c)">Als ungelesen markieren</button>
                                  </mat-menu>
                            </div>
                            <!-- {{c.messages[c.messages?.length-1]?.message}} -->

                        </div>
                    </div>
                    <!-- <div class="last-message-info">
                        <div class="row" *ngIf="c?.messages?.length > 0">
                            {{dateFormatter(c.messages[0]?.time)}}
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div *ngIf="c.unreadMessages?.length > 0" class="message-indication single-chat glyphicon"></div>
                            </div>
                            <div class="col-6">
                                <button mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                      <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                    </svg>
                                  </button>
                                  <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="chatService.onOpenChat(c)">Als ungelesen markieren</button>
                                  </mat-menu>
                            </div>
                        </div>
                        </div> -->
                    </div>
                <!-- <div class="profile">
                    <div class="profile-picture">
                        <img src="{{c?.chatPartner?.profilePictureUrl}}">
                    </div>
                </div>
                <div class="chat-name display-inlineblock">
                    <div class="row">
                        {{c.chatPartner.getName()}}
                    </div>
                    <div class="row">
                        Das ist eine Chatnachricht...
                    </div>
                </div>
                <div *ngIf="c.unreadMessages?.length > 0" class="message-indication single-chat glyphicon"></div>
                <div class="message-preview"></div> -->
            </div>
        </div>
    </div>
</div>
<chat>
</chat>