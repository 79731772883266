<div class="container-fluid session-container padding-0 layout-box">
    <div class="scrollable-session-column">
        <app-sessions-column class="col-12 col-lg-auto sessions-column {{sessionsColumnClosed ? 'closed' : ''}}" *ngIf="getMainTrainingPlan()" [(sessionsColumnClosed)]="sessionsColumnClosed" [(draggingWeek)]="draggingWeek"
            [(draggingSession)]="draggingSession" [(focusedBeforeDropzoneWeek)]="focusedBeforeDropzoneWeek"
            [(focusedAfterDropzoneWeek)]="focusedAfterDropzoneWeek"
            [(focusedWeekDayForSessionDrop)]="focusedWeekDayForSessionDrop"
            [(focusedWeekNumberForSessionDrop)]="focusedWeekNumberForSessionDrop"
            [(weekDaysByWeekId)]="weekDaysByWeekId" [(selectedSession)]="selectedSession"
            [(focusedAfterDropzoneSession)]="focusedAfterDropzoneSession" [(hasChanges)]="hasChanges"
            [trainingPlan]="trainingPlan" [readOnlyMode]="readOnlyMode" (addWeek)="addWeek()"
            (removeWeek)="removeWeek($event)" (duplicateWeek)="duplicateWeek($event)"
            (dropWeek)="onDropWeek($event.week, $event.after)"
            (dropOnWeekDayItem)="onDropOnWeekDayItem($event.weekDay, $event.weekId)"
            (addSessionToDay)="addSessionToDay($event.date, $event.weekId)"
            (addSessionFromTemplate)="addSessionFromTemplate($event.plannedDay, $event.week)"
            (addSession)="addSession($event.isRest, $event.addToNewWeek, $event.newSession)"
            (duplicateSession)="duplicateSession($event)" (removeSession)="removeSession($event)"
            (dropAfterSession)="onDropAfterSession($event)" (setCalendarItems)="setCalendarItems()"
            (connectToBaseSession)="connectToBaseSession($event.session, $event.baseSession)"
            (saveSessionAsTemplate)="saveSessionAsTemplate($event)"
            (openTrackedTrainingSession)="onOpenTrackedTrainingSession($event)">
        </app-sessions-column>
    </div>
    <div class="col-12 col-lg">
        <div *ngIf="getMainTrainingPlan()" class="week-editor">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-7">
                    <div *ngIf="getWeekOfSelectedSession()" class="">
                        <h3 class="display-inlineblock">Woche {{getWeekIndexOfSelectedSession()}}:</h3>
                        <input id="input-weekname" class="input weekname form-control display-inlineblock marginleft-10" [(ngModel)]="getWeekOfSelectedSession().name[languageService.selectedLanguageCode]" placeholder="Wochenname" type="text">
                        <div class="icon-button display-inlineblock marginleft-25 button-weeknote {{getWeekOfSelectedSession().note[languageService.selectedLanguageCode]?.length > 0 ? 'active bi-highlight' : ''}}" (click)="showWeekNote = !showWeekNote">
                            <i class="bi bi-chat-left"></i>
                        </div>
                        <div *ngIf="showWeekNote">
                            <textarea id="editor-weeknote" class="" [(ngModel)]="getWeekOfSelectedSession().note[languageService.selectedLanguageCode]" placeholder="Hinweis für den Athleten"></textarea>
                        </div>
                        <div *ngIf="selectedSession">
                            <div class="spacer-10"></div>
                            <h3 class="display-inlineblock">Einheit:</h3>
                            <input id="input-sessionname" class="input weekname form-control display-inlineblock marginleft-10" [(ngModel)]="selectedSession.name" placeholder="Einheitenname" type="text">
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-lg-2">
                    <button class="button-filled small" (click)="saveTrainingPlan()">Speichern</button>
                </div>
                <div class="col-6 col-md-4 col-lg-3">
                    <button class="button-filled small light marginright-20" (click)="onCreateTrainingPlan()">Neuer Plan/Zyklus</button>
                    <div class="plan-selector display-inlineblock" *ngIf="trainingPlan && user.trainingPlans?.length > 1">
                        <div class="spacer-10"></div>
                        <div class="d-inline-block wrapper-selection">
                            <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{trainingPlan.name}}</button>
                            <div class="dropdown-menu" aria-labelledby="dropdownBodyDataGraphTimeRange">
                                <div *ngFor="let trainingPlan of user.trainingPlans">
                                    <button class="dropdown-item" type="button" (click)="onSelectTrainingPlan(trainingPlan)">{{trainingPlan.name}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button mat-button [matMenuTriggerFor]="menu" class="display-inlineblock" (click)="$event.stopPropagation()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                        </svg>
                    </button>
                    <mat-menu #menu="matMenu" class="chat-cdk-overlay-container">
                        <button mat-menu-item (click)="savePlanAsTemplate(trainingPlan)">Als Vorlage speichern</button>
                        <button *ngIf="canDeleteTrainingPlan(trainingPlan)" mat-menu-item (click)="onDeleteTrainingPlan()">Löschen</button>
                    </mat-menu>
                </div>
            </div>
        </div>
        <app-training-session-editor *ngIf="getMainTrainingPlan()" [headerAreaEnabled]="false" [readOnlyMode]="false" [trainingPlan]="getMainTrainingPlan()" [user]="user"
            [userCardioZoneGroups]="getUserCardioZoneGroups()" [globalCardioZones]="getGlobalCardioZones()"
            [selectedCoach]="selectedCoach" [selectedSession]="selectedSession"
            [trackedTrainingSessions]="trackedTrainingSessions" [(hasChanges)]="hasChanges"
            [availableTrainingVariables]="availableTrainingVariables"
            [(copyExercise)]="copyExercise" [(copyExercisesList)]="copyExercisesList" [(expandedExercise)]="expandedExercise"
            (closeSessionsColumn)="closeSessionsColumn()"
            (openTrainingVariablesDialog)="openTrainingVariablesDialog()"
            (showVariableNotAvailableDialog)="showVariableNotAvailableDialog()">
        </app-training-session-editor>
        <ng-container *ngIf="!getMainTrainingPlan()">
            <div class="padding-15 textalign-center">
                <button class="button-filled small create-Exercise" (click)="onCreateTrainingPlan()">Plan erstellen</button>
            </div>
        </ng-container>

    </div>
</div>
<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>