import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { User } from '../model/user.model';
import { FirestoreService } from '../services/firestore.service';
import { Licence } from '../model/lid.model';
import { BroadcastChat, Chat } from '../model/chat.model';
import { NavbarService } from '../services/navbar.service';
import { ChatService } from '../services/chat.service';
import { UtilityService } from '../services/utility.service';
import { InputFieldDialogComponent } from '../inputfield-dialog/inputfield-dialog.component';
import {MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { Coach } from '../model/coach.model';
import { firstValueFrom } from 'rxjs';
import { Metric } from '../model/metric.model';
import { environment } from 'src/environments/environment';
import { FilterObject } from '../filter-selection-dropdown/filter-selection-dropdown.component';
import { ILanguageDictionary, LanguageDictionary } from '../model/languagedictionary.model';
import { AssignedQuestionaire, Questionaire } from '../model/questionaires.model';
import { CompletedQuestionaireResultsDialogComponent } from '../questionaire/completed-questionaire-results-dialog/completed-questionaire-results-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from '../services/payment.service';
import { TrainingHistoryDialogComponent } from '../training-monitoring/training-history-dialog/training-history-dialog.component';
import { TrackedTrainingSession } from '../model/training-monitoring.model';
import { TrainingSession } from '../model/training-plan.model';
import { TrainingService } from '../services/training.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-coach-area',
  templateUrl: './coach-area.component.html',
  styleUrls: ['./coach-area.component.css']
})
export class CoachAreaComponent implements OnInit {

  public selectedCoach: Coach
  public coaches: Coach[] = []
  public clients: User[];
  public filteredClients: User[];
  public clientSearchInput: string
  public user: User;
  public chats = [];
  public uid: string;
  public selectedClientGroup: string = null

  public get inListView() {
    return this.user?.portalSettingsCoach?.showClientsInListView ?? false
  }

  constructor(public chatService: ChatService, private authService: AuthService, private router: Router, public userService: FirestoreService, public navService: NavbarService, public utilityService: UtilityService, public dialog: MatDialog, private toastr: ToastrService, private paymentService: PaymentService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.user = this.userService.getLoggedInUser();
    this.selectedCoach = this.userService.getLoggedInUser().coach
    this.userService.getAllCoachesByLicenceHolderUid(this.selectedCoach.licenceHolderUid).subscribe(coaches => {
      this.coaches = coaches
    })
    this.uid = this.authService.user.uid;
    this.clients = [];
    this.clients = this.userService.getAccessibleClients();
    if (this.userService.areLicencesLoaded()) this.areLicencesLoaded = true
    this.updateFilteredClients()
    this.userService.observableClients.subscribe(clients => {
      this.clients = this.userService.getAccessibleClients()
      if (this.userService.areLicencesLoaded()) this.areLicencesLoaded = true
      this.updateFilteredClients()
    })
    if (!this.authService.isLoggedIn) {
      this.router.navigate['login'];
    }
  }

  public areLicencesLoaded = false

  getChatForClient(client: User): Chat {
    return this.chatService.getChatForUid(client.uid)
  }
  onOpenChat(chat: Chat) {
    this.chatService.openChat(chat)
  }

  onToggleListView() {
    this.user.portalSettingsCoach.showClientsInListView = !this.user.portalSettingsCoach.showClientsInListView
    this.userService.updatePortalSettingsForCoach(this.user)
  }

  public availableQuestionaireTypes: FilterObject[] = [
    new FilterObject(new LanguageDictionary('Noch nicht angesehen', 'Not viewed', 'unviewed'), false)
  ];
  public clientsSortAttribute = 'creationDate'
  public clientsSortDirection = 'desc'
  
  isQuestionareFilterSelected(filterObjects:FilterObject[]):boolean{
    return filterObjects?.filter(x => x.isFiltered)?.length > 0
  }
  fallsInQuestionaireFilter(filters:FilterObject[], user: User, isTranslatable: boolean = true):boolean{
    if (!this.isQuestionareFilterSelected(filters)) return true
    return user.getAssignedQuestionaires().filter(q => filters.filter(f => f.isFiltered && ( this.getCompareText(f.originObject, isTranslatable) == 'unviewed' && user.getNumberOfNotViewedQuestionaires() > 0 || !q.completed && this.getCompareText(f.originObject, isTranslatable) === q.name ) ).length > 0).length > 0
  }
  getCompareText(filterObject: ILanguageDictionary<any>, isTranslatable: boolean = true):string{
    if (isTranslatable) return filterObject.comparableValue
    else return filterObject.originObject.toString()
  }

  onClientsSortChange(sortAttribute: string) {
    if (this.clientsSortAttribute == sortAttribute) {
      if (this.clientsSortDirection == 'desc') {
        this.clientsSortDirection = 'asc'
      } else {
        this.clientsSortDirection = 'desc'
        this.clientsSortAttribute = 'creationDate'
      }
    } else {
      this.clientsSortDirection = 'desc'
      this.clientsSortAttribute = sortAttribute
    }
  }
  private userDataPromiseMap = new Map<string, Promise<User>>()

  getDataForUser(client: User) {
    if (!client) return null
    if (this.userDataPromiseMap.has(client.uid)) {
      return this.userDataPromiseMap.get(client.uid)
    }
    var promise: Promise<User> = new Promise(async (resolve, reject) => {
      //await this.userService.loadAllAssignedQuestionairesForUser(client)
      var startDate = new Date()
      startDate.setDate(startDate.getDate() - 21)
      var endDate = new Date()
      var dailyConditions = (await firstValueFrom(this.userService.getDailyConditionsForUser(client, startDate, endDate))).filter(x => x.date >= startDate && x.date <= endDate)
      var firstBodyWeight = null
      var lastBodyWeight = null
      var metricId = environment.isWhitelabel ? 'NUT_bodyWeight' : 'bodyWeight'
      dailyConditions.forEach(condition => {
        if (condition.getMetricDataByMetricId(metricId)?.value != null) {
          if (!firstBodyWeight) firstBodyWeight = condition.getMetricDataByMetricId(metricId).value
          lastBodyWeight = condition.getMetricDataByMetricId(metricId).value
        }
      })
      if (firstBodyWeight && lastBodyWeight) {
        var difference = lastBodyWeight - firstBodyWeight
        client.bodyWeightDelta = difference
      }
      client.getUncompletedQuestionaires().forEach(questionaire => {
        if (this.availableQuestionaireTypes.filter(x => x.originObject.originObject == questionaire.name).length == 0) {
          this.availableQuestionaireTypes.push(new FilterObject(new LanguageDictionary(questionaire.name, questionaire.name, questionaire.name), false),)
        }
      })
      var productPurchase = this.paymentService.productPurchases.find(x => x.customerUid == client.uid)
      if (productPurchase) {
        client.productPurchaseEndDate = productPurchase.endDate
      }

      var trackedSessions = await this.userService.getTrackedTrainingSessionsWithExercises(client, startDate.clone(), endDate)
      client.trackedTrainingSessions = trackedSessions
      resolve(client)
    });
    this.userDataPromiseMap.set(client.uid, promise)
    return promise;
  }

  logout() {
    this.authService.logout();
    this.userService.logout();
  }

  onSelectCoach(coach: Coach) {
    this.selectedCoach = coach
    this.selectedClientGroup = null
    this.updateFilteredClients()
  }

  getSelectableCoaches() {
    return this.coaches.filter(coach => this.userService.getLoggedInUser().coach?.canAccessCoachClients(coach))
  }

  onClientSearchInputChanged(text: string) {
    this.clientSearchInput = text
    this.updateFilteredClients()
  }

  onShowAllClients() {
    this.selectedCoach = null 
    this.selectedClientGroup = null
    this.updateFilteredClients()
  }

  updateFilteredClients() {
    var accessibleClients = []
    if (!this.selectedCoach && !this.selectedClientGroup) {
      accessibleClients = this.clients
    } else {
      this.clients.forEach(client => {
        if (this.selectedCoach && this.selectedCoach.canAccessUser(client) && this.selectedCoach.uid == client.coachUid) {
          accessibleClients.push(client)
        } else if (this.selectedClientGroup && this.userService.getLoggedInUser().coach?.canAccessUser(client) && client.metadataUser?.assignedClientGroups.includes(this.selectedClientGroup)) {
          accessibleClients.push(client)
        }
      })
    }
    if (!this.clientSearchInput || this.clientSearchInput.length == 0) {
      this.filteredClients = accessibleClients
    } else {
      this.filteredClients = []
      accessibleClients.forEach(client => {
        if (client.getName()?.toLowerCase().includes(this.clientSearchInput.toLowerCase())) {
          this.filteredClients.push(client)
        }
      })
    }
  }

  onDeleteClientSearchInput() {
    (<HTMLInputElement> document.getElementById('clientsearch-input')).value = ''
    this.filteredClients = this.clients
    this.clientSearchInput = null
  }

  onLicenceIssuedChanged(licence: Licence, event: any){
    licence.issued = event.currentTarget.checked;
    this.userService.updateLicenceIssued(licence);
 }

 onClientGroupSelected(tag: string) {
   this.selectedClientGroup = tag
   this.selectedCoach = null
   this.updateFilteredClients()
 }

 getClientGroups(): string[] {
   return this.userService.getClientGroups()
 }

 onCreateClientGroup() {
  const dialogRef = this.dialog.open(InputFieldDialogComponent, {
    data: { message: '', title: 'Gruppe erstellen' },
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result && result.input) {
      var clientGroup = result.input
      this.user.portalSettingsCoach.clientGroups.push(clientGroup)
      this.userService.clientGroups.push(clientGroup)
      this.userService.sortClientGroups()
      this.userService.updatePortalSettingsForCoach(this.user)
    }
  });
 }
 onDeleteClientGroup(clientGroup: string) {
  const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
    data: { message: 'Möchtest du die Gruppe wirklich löschen? Alle Nutzer:innen werden aus der Gruppe entfernt.', title: 'Gruppe löschen' },
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      if (this.userService.clientGroups.includes(clientGroup)) this.userService.clientGroups.splice(this.userService.clientGroups.indexOf(clientGroup), 1)
      if (this.user.portalSettingsCoach.clientGroups.includes(clientGroup)) this.user.portalSettingsCoach.clientGroups.splice(this.user.portalSettingsCoach.clientGroups.indexOf(clientGroup), 1)
      this.userService.updatePortalSettingsForCoach(this.user)
      this.userService.getAccessibleClients().forEach(client => {
        this.userService.removeClientFromGroup(client, clientGroup)
      })
      if (this.user.portalSettingsCoach.dashboardClientGroups.includes(clientGroup)) this.user.portalSettingsCoach.dashboardClientGroups.splice(this.user.portalSettingsCoach.dashboardClientGroups.indexOf(clientGroup), 1)
      if (clientGroup == this.selectedClientGroup) this.selectedClientGroup = null
    }
  });
 }

 async openNextNotViewedQuestionaire(user: User) {
  var questionaire = user.getNextNotViewedQuestionaire()
  if (questionaire) {
    var questionaireResult = user.questionaireResults.find(x => x.questionaireId == questionaire.id)
    if (!questionaireResult && questionaire.completionDate) {
      var dailyCondition = await firstValueFrom(this.userService.getDailyConditionByDate(user, questionaire.completionDate))
      if (dailyCondition && dailyCondition.questionaireResults) {
        questionaireResult = dailyCondition.questionaireResults.find(x => x.questionaireId == questionaire.id)
      }
    }
    if (questionaireResult) {
      questionaireResult.assignedQuestionaire = questionaire
      const dialogRef = this.dialog.open(CompletedQuestionaireResultsDialogComponent, { data: { selectedQuestionaireResults: [questionaireResult], allAvailableQuestionaireResults: null, user: user}, autoFocus: false});
    } else {
      this.toastr.info("Öffne den Coachee, um die ungesehenen Check-Ins anzusehen.", "",  {
        positionClass: 'toast-bottom-center'
      });
    }
  }
 }
 openNextNotViewedTraining(user: User) {
    var trackedTrainingSession = user.getNextNotViewedTraining()
    if (trackedTrainingSession) {
      this.openTrackedTrainingSession(trackedTrainingSession, user)
    } else {
      this.toastr.info("Öffne den Coachee, um die ungesehenen Trainings anzusehen.", "",  {
        positionClass: 'toast-bottom-center'
      });
    }
 }
 openNextNotViewedExerciseRecording(user: User) {
  var trackedTrainingSession = user.getNextTrackedTrainingWithNotViewedExerciseRecording()
  if (trackedTrainingSession) {
    this.openTrackedTrainingSession(trackedTrainingSession, user)
  } else {
    this.toastr.info("Öffne den Coachee, um die ungesehenen Videoaufnahmen anzusehen.", "",  {
      positionClass: 'toast-bottom-center'
    });
  }
 }
 async openTrackedTrainingSession(trackedTrainingSession: TrackedTrainingSession, user: User) {
  let questionaireResults = this.user.questionaireResults?.filter(x => x.assignedQuestionaire?.trackedSessionId == trackedTrainingSession?.id);
  let plannedSession = await this.getPlannedSession(trackedTrainingSession, user);
  const dialogRef = this.dialog.open(TrainingHistoryDialogComponent, { data: { user: user, selectedTrackedTrainingSession: trackedTrainingSession, selectedPlannedTrainingSession: plannedSession, questionaireResults: questionaireResults}})
 }
 async getPlannedSession(trackedTrainingSession: TrackedTrainingSession, user: User):Promise<TrainingSession>{
  try{
    this.spinner.show();
    await this.userService.loadTrainingPlansIfNotLoaded(user, this.user, false)
    var session = user.trainingPlans.filter(x => x.id == trackedTrainingSession.trainingPlanId)[0]?.sessions?.filter(x => x.id == trackedTrainingSession.plannedSessionId)[0]
    return session
  }
  catch(e){
    console.error(e);
  }
  finally{
    this.spinner.hide();
  }
  return null;
}

 getClientsForSelectedGroup(): User[] {
  return this.filteredClients?.filter(x => this.fallsInQuestionaireFilter(this.availableQuestionaireTypes, x, false)).sort((a, b) => {
    if (this.clientsSortAttribute == 'expiringNutritionPlanDate') {
      if (this.clientsSortDirection == 'asc') {
        if (a.getExpiringNutritionPlanDate() == null) return 1
        if (b.getExpiringNutritionPlanDate() == null) return -1
        return a.getExpiringNutritionPlanDate().getTime() - b.getExpiringNutritionPlanDate().getTime()
      } else {
        if (a.getExpiringNutritionPlanDate() == null) return -1
        if (b.getExpiringNutritionPlanDate() == null) return 1
        return b.getExpiringNutritionPlanDate().getTime() - a.getExpiringNutritionPlanDate().getTime()
      }
    } else if (this.clientsSortAttribute == 'expiringTrainingPlanDate') {
      if (this.clientsSortDirection == 'asc') {
        if (a.getExpiringTrainingPlanDate() == null) return 1
        if (b.getExpiringTrainingPlanDate() == null) return -1
        return a.getExpiringTrainingPlanDate().getTime() - b.getExpiringTrainingPlanDate().getTime()
      } else {
        if (a.getExpiringTrainingPlanDate() == null) return -1
        if (b.getExpiringTrainingPlanDate() == null) return 1
        return b.getExpiringTrainingPlanDate().getTime() - a.getExpiringTrainingPlanDate().getTime()
      }
    } else if (this.clientsSortAttribute == 'lastChatMessageDate') {
      var aChat = this.chatService.getChatForUid(a.uid)
      var bChat = this.chatService.getChatForUid(b.uid)
      if (this.clientsSortDirection == 'asc') {
        if (aChat?.getLatestMessage() == null) return 1
        if (bChat?.getLatestMessage() == null) return -1
        return aChat.getLatestMessage()?.time.getTime() - bChat.getLatestMessage().time.getTime()
      } else {
        if (aChat?.getLatestMessage() == null) return -1
        if (bChat?.getLatestMessage() == null) return 1
        return bChat.getLatestMessage()?.time.getTime() - aChat.getLatestMessage().time.getTime()
      }
    } else if (this.clientsSortAttribute == 'endDate') {
      if (this.clientsSortDirection == 'asc') {
        if (a.getCoachingEndDate() == null) return 1
        if (b.getCoachingEndDate() == null) return -1
        return a.getCoachingEndDate().getTime() - b.getCoachingEndDate().getTime()
      } else {
        if (a.getCoachingEndDate() == null) return -1
        if (b.getCoachingEndDate() == null) return 1
        return b.getCoachingEndDate().getTime() - a.getCoachingEndDate().getTime()
      }
    }
  })
 }

  dateFormatter(date:Date):string{
    return date.asFormatedString()
  }


  createNewGroupAndAddUser(user: User) {
    const dialogRef = this.dialog.open(InputFieldDialogComponent, {
      data: { message: '', title: 'Gruppe erstellen' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.input) {
        var clientGroup = result.input
        if (!this.getCoach().portalSettingsCoach.clientGroups.includes(clientGroup)) this.getCoach().portalSettingsCoach.clientGroups.push(clientGroup)
        this.userService.updatePortalSettingsForCoach(this.getCoach())
        if (!this.userService.clientGroups.includes(clientGroup)) this.userService.clientGroups.push(clientGroup)
        this.userService.sortClientGroups()
        this.userService.addClientToGroup(user, result.input)
      }
    });
  }

  onDeactivateLicenceOfClient(user: User) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du die Lizenz wirklich deaktivieren? ' + user.getName() + ' kann danach nicht mehr in der Coaching Zone von dir betreut werden.', title: 'Lizenz deaktivieren' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userService.deactivateLicence(user.licence.lid);
      }
    });
  }

  onRenameClient(user: User) {
    const dialogRef = this.dialog.open(InputFieldDialogComponent, {
      data: { message: '', title: 'Kund:in umbenennen' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.input) {
        user.metadataUser.userName = result.input
        this.userService.updateCoachingMetaData(user, false)
      }
    });
  }

  getCoach() {
    return this.userService.getLoggedInUser()
  }
}
