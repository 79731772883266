import { ClientComponent } from '../../client/client-v1/client-v1.component';
import { SessionTemplatesDialogComponent } from './../session-templates-dialog/session-templates-dialog.component';
import { UtilityService } from './../../services/utility.service';
import { ExercisesTableDialogComponent } from './../exercises-table-dialog/exercises-table-dialog.component';
import { MergedTrainingExercise } from './../../model/training-exercise';
import { TrainingService } from './../../services/training.service';
import { TrainingPlan, TrainingSession, PlannedTrainingExercise, TrainingSet, Label2FrequencyMapping, Frequency2LabelMapping, SuperSet, NumberOfRounds2LabelMapping, Label2NumberOfRoundsMapping, NumberOfRounds, SetParameter2LabelMapping, Label2SetParameterMapping, SetParameter, SuperSetConfig, SetParameter2UnitMapping, SetParameter2LabelUnitMapping, SetParameter2SubHeadingMapping, TrainingPlanAccess, Week } from './../../model/training-plan.model';
import { Component, Input, OnInit, Output, EventEmitter, Inject, NgZone } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import '../../prototypes'
import { CdkDrag, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ToastrService } from 'ngx-toastr';
import { FirestoreNutritionPlanService } from 'src/app/services/firestore-nutritionplan.service';
import { CreateTemplateDialogComponent } from 'src/app/create-template-dialog/create-template-dialog.component';
import { User } from 'src/app/model/user.model';
import { AuthService } from 'src/app/auth/auth.service';
import { ExerciseHistoryDialogComponent } from 'src/app/training-monitoring/exercise-history-dialog/exercise-history-dialog.component';
import { environment } from 'src/environments/environment';
import { LanguageService } from 'src/app/services/language.service';
import { TrainingplanExportService } from 'src/app/services/trainingplan-export.service';
import { SingleExerciseComponent } from '../single-exercise/single-exercise.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomSettingsDialogComponent } from 'src/app/dialogs/custom-settings-dialog/custom-settings-dialog.component';
import { FirestoreService } from 'src/app/services/firestore.service';
import { DropdownItem } from 'src/app/model/automatic-push-notification.model';
import { ImageEditorComponent } from 'src/app/utilities/image-editor/image-editor.component';
import { TrainingPlanTemplateFolder } from 'src/app/model/training-plan-template-folder.model';
import { time } from 'console';
import { LanguageDictionary } from 'src/app/model/languagedictionary.model';
import { TrainingVariable } from 'src/app/model/training-variables.model';
import {MatDividerModule} from '@angular/material/divider';
import { TrainingVariableEditorDialogComponent, TrainingVariableEditorDialogType } from '../training-variable-editor-dialog/training-variable-editor-dialog.component';
import { VisualSelectionDialogComponent } from 'src/app/dialogs/visual-selection-dialog/visual-selection-dialog.component';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { TrainingHistoryDialogComponent, TrainingSessionContainer } from 'src/app/training-monitoring/training-history-dialog/training-history-dialog.component';
import { NumberToShortWeekDayStringsMapping } from 'src/app/model/task.model';
import { timeout } from 'rxjs';
import { ExerciseGroupTemplatesDialogComponent } from '../exercise-group-templates-dialog/exercise-group-templates-dialog.component';
import { Questionaire, QuestionaireResult } from 'src/app/model/questionaires.model';
import { CalendarItem, SingleCalendarDay } from 'src/app/monthly-calendar/monthly-calendar.component';
import { TrackedTrainingExercise, TrackedTrainingSession, TrackedTrainingSet } from 'src/app/model/training-monitoring.model';
import { CardioZone, CardioZoneGroup } from 'src/app/model/cardio-zone-group.model';
import { Moment } from 'moment';
import { TrainingPlanEditorHelper } from './utilities/training-plan-editor-helper';
import { ClientBaseComponent } from 'src/app/client/client-base/client-base.component';
import { BaseTrainingEditor } from './base-training-plan-editor';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-training-plan-editor',
  templateUrl: './training-plan-editor.component.html',
  styleUrls: ['./training-plan-editor.component.css']
})
export class TrainingPlanEditorComponent extends BaseTrainingEditor implements OnInit {

  public assignedTrainingTemplateFolders: TrainingPlanTemplateFolder[] = [];



  public calendarItems: CalendarItem<TrainingSession>[] = [];

  public isCalendarCollapsible: boolean = true;
  
  public calendarDropped(event){
    event.event?.stopPropagation();
  }

  disableCalendarCollapsible(){
    this.isCalendarCollapsible = false;
  }
  enableCalendarCollapsible(){
    setTimeout(() => {
      this.isCalendarCollapsible = true;
    }, 200);
  }

  
  constructor(public dialogRef: MatDialogRef<TrainingPlanEditorComponent>, @Inject(MAT_DIALOG_DATA) private data: {trainingPlan: TrainingPlan, coach: User, user: User, trainingTemplateFolders: TrainingPlanTemplateFolder[], readOnlyMode: boolean }, public trainingService: TrainingService, public toastr: ToastrService, public dialog: MatDialog, public authService: AuthService, public utilityService: UtilityService, public languageService: LanguageService, public ngZone: NgZone, public trainingPlanExportService: TrainingplanExportService, public spinner: NgxSpinnerService, public userService: FirestoreService, public notificationService: NotificationService) 
  {
    super(trainingService, toastr, dialog, authService, utilityService, languageService, ngZone, trainingPlanExportService, spinner, userService, notificationService, dialogRef)

    this.TrainingPlan = data.trainingPlan
    this.selectedCoach = data.coach
    this.user = data.user
    this.assignedTrainingTemplateFolders = data.trainingTemplateFolders || []
    this.readOnlyMode = data.readOnlyMode || false

    dialogRef.disableClose = true
  }



  async ngOnInit(): Promise<void> {
    this.spinnerText = null;
    this.dialogRef.backdropClick().subscribe(() => {
      this.cancelEditTrainingPlan()
    })
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
        this.cancelEditTrainingPlan()
      }
    });
    this.init()
    
  }

  @Input() set TrainingPlan(value: TrainingPlan){
    this.trainingPlan = value;
    this.setCurrentSession();
    this.thumbnailImageSrc = this.trainingPlan.imageDownloadURL || "";
  }

  @Input() set SelectedCoach(value: User) {
    this.selectedCoach = value;
  }

  doBeforeUnload() {
    console.log('doBeforeUnload')
    // Show confirmation dialog before closing tab/browser.
    if (this.hasChanges) return false;
    return true
  }
 
  public selectableSetOptions: string[] = [null, "RPE", "RIR"]
  public selectedSetOption: string = this.selectableSetOptions[0]

  trainingExercisesLoaded(): boolean {
    return this.trainingService?.MergedTrainingExercises?.length > 0
  }

  dragOverCalendarDay(event, date: Date){
    if(this.draggingSession && !this.isDayInThePast(date) && !this.readOnlyMode){
      event.preventDefault();
    }
  }

  onDroppedOnCalendarDay(event: Date) {
    if(this.draggingSession && !this.isDayInThePast(event) && !this.readOnlyMode){
      if(event < this.trainingPlan.startDate) return;
      if(this.trainingPlan.endDate && event > this.trainingPlan.endDate){
        this.trainingPlan.endDate = event;
        this.trainingPlan.initWeeks();
      }
      let weekId = this.trainingPlan.getWeekIdForDate(event);
      if(!weekId){
        this.trainingPlan.initWeeks(this.trainingPlan.startDate, event);
        this.setWeekDays();
        // this.addWeek();
      }
      weekId = this.trainingPlan.getWeekIdForDate(event);
      if(weekId){
        this.onDropOnWeekDayItem(event, weekId);
      }
      this.draggingSession = null;
    }
  }

  dropTrainingSessionOnDay(event: CdkDragDrop<TrainingSession[]>, day: Date, weekId: string){
    if(event.previousContainer === event.container){
      let sessionAtIndex = this.trainingPlan.sessions.filter(x => !x.deleted)[event.previousIndex];
      sessionAtIndex.weekId = weekId
      sessionAtIndex.plannedDate = day
      moveItemInArray(this.trainingPlan.sessions, event.previousIndex, event.currentIndex);
    }
    else {
      let session = event.previousContainer.data[event.previousIndex];
      session.weekId = weekId
      session.plannedDate = day
      this.hasChanges = true
    }
    this.hasChanges = true
  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // onMouseDownWeekDragHandle(weekNumber: number, htmlElement: HTMLElement){
  //   this.draggingWeekNumber = weekNumber;
  //   htmlElement.draggable = true;
  //   return true;
  // }

  // onMouseUpWeekDragHandle(htmlElement: HTMLElement){
  //   this.draggingWeekNumber = -1;
  //   htmlElement.draggable = false;
  // }

  static getExercisesByGroupName(exercises: PlannedTrainingExercise[], groupNameTranslation: LanguageDictionary<string>):PlannedTrainingExercise[]{
    let groupExercises = [];
    for(let exercise of exercises){
      if(exercise.groupHeadingTranslation){
        if(exercise.groupHeadingTranslation == groupNameTranslation){
          groupExercises.push(exercise);
        }
        else if(groupExercises.length > 0){
          break;
        }
      }
      else if(groupExercises.length > 0){
        groupExercises.push(exercise);
      }
    }

    return groupExercises;
  }


  public spinnerText = null
  updateTrainingplanExportSpinnerText(progress: string){
    this.spinnerText = 'Trainingsplan exportieren (' + progress + ')'
  }
  async onExportTrainingPlan(){
    if(!this.setAndCheckAllValues()){
      return;
    }
    let displayTrackingSheetString = "Tracking-Sheet anzeigen";
    let displayExerciseDetailsString = "Übungsdetails anzeigen";
    let dialogRef = this.dialog.open(CustomSettingsDialogComponent, { data: { title: "Trainingsplan exportieren", availableSettings: [displayTrackingSheetString, displayExerciseDetailsString], selectedSettings: [displayTrackingSheetString, displayExerciseDetailsString], minimumSelectedItems: 1, cancelButtonText: "Abbrechen", submitButtonText: "Erstellen"}})
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if(result.shouldTake){
          this.spinner.show("trainingPlanSpinner")
          let displayTrackingSheet = result.selectedSettings?.includes(displayTrackingSheetString);
          let displayExerciseDetails = result.selectedSettings?.includes(displayExerciseDetailsString);
          let progressSubscription = this.trainingPlanExportService.exportProgress.subscribe((progress) => {
            this.updateTrainingplanExportSpinnerText(progress);
          });
          try{
            let cardioZoneGroups = this.getUserCardioZoneGroups();
            await this.trainingPlanExportService.onExportTrainingPlan(this.trainingPlan, this.user, this.languageService.selectedLanguageCode, displayTrackingSheet, displayExerciseDetails, cardioZoneGroups);
          }
          catch(error){
            console.error(error)
            this.toastr.error("Es ist ein unbekannter Fehler aufgetreten.", "Fehler", {
              positionClass: 'toast-bottom-center'
            })
          }
          finally{
            this.spinnerText = null;
            this.spinner.hide("trainingPlanSpinner");
            progressSubscription.unsubscribe();
            this.spinnerText = null;
          }
        }
      }
    });
  }

  @Output() deleteTrainingPlanEvent = new EventEmitter<TrainingPlan>();
  @Output() saveChangesEvent = new EventEmitter<TrainingPlan>();
  @Output() cancelChangesEvent = new EventEmitter();

  public cancelEditTrainingPlan(){
    if (!this.hasChanges || this.readOnlyMode) {
      this.cancelChangesEvent.emit()
      this.dialogRef.close({delete: false, save: false})
      return
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du den Trainingsplan vor dem Schließen speichern?', title: 'Trainingsplan speichern', positiveButton: 'Ja', negativeButton: 'Nein' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.saveTrainingPlan()
      } else if (result == false) {
        this.cancelChangesEvent.emit()
        this.dialogRef.close({delete: false, save: false})
      }
      else
      {
        return;
      }
    })
  }

  public deleteTrainingPlan(){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du diesen Trainingsplan wirklich löschen?', title: 'Trainingsplan löschen' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteTrainingPlanEvent.emit(this.trainingPlan)
        this.dialogRef.close({delete: true, save: false})
      }
    })
  }

  isAdmin() {
    return this.authService.isAdmin()
  }


  //#region progression

  showVariableNotAvailableDialog(){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Die Variable existiert nicht. Möchtest du eine neue Variable anlegen?', title: 'Variable anlegen', positiveButton: 'Ja', negativeButton: 'Abbrechen' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.openTrainingVariablesDialog();
      }
    })
  }

  public static cardioZonePrefix: string = "#CZ_";
  public static xRepMaxPattern: string = "^([1-9][0-9]?[0-9]?)%([1-9]|10)RM$";
  public static oneRmTopsetPattern: string = "^([1-9][0-9]?[0-9]?)%1RM_TOPSET$";
  public static refSetPattern: string = "^[+-]?([1-9]?[0-9]?[0-9])(%#)([1-9]?[0-9]?[0-9])$";

  public static cardioZonePattern: string = `^[+-]?([1-9]?[0-9]?[0-9])(%${TrainingPlanEditorComponent.cardioZonePrefix})([a-zA-Z0-9_. ]+)$`;

  
  public static getSetPace(set: TrainingSet, setParameter: SetParameter, availableTrainingVariables: TrainingVariable[], cardioZones: CardioZone[]): string{
    let parameter = setParameter.toString();
    let upperCaseParameter = parameter.charAt(0).toUpperCase() + parameter.slice(1);
    let formulaParameter = parameter + 'Formula';
    let minParameter = 'min' + upperCaseParameter;
    let maxParameter = 'max' + upperCaseParameter;
    let value = set[parameter];
    let formulaValue = set[formulaParameter];
    let minValue = set[minParameter];
    let maxValue = set[maxParameter];

    if(formulaValue != undefined) {
      if(formulaValue.match(TrainingPlanEditorComponent.xRepMaxPattern)){
        return formulaValue;
      }
      else if(formulaValue.includes(`%${TrainingPlanEditorComponent.cardioZonePrefix}{`)){
        let zoneIdPart = formulaValue.split(`%${TrainingPlanEditorComponent.cardioZonePrefix}{`)[1];
        if(zoneIdPart){
          let zoneId = zoneIdPart.split('}')[0];
          let zone = TrainingPlanEditorComponent.getCardioZoneById(zoneId, cardioZones);
          if(zone){
            return zone.name;
          }
        }
      }
      else{
        let variable = TrainingPlanEditorComponent.getVariableByFormula(formulaValue, availableTrainingVariables);
        if(variable){
          return formulaValue.replace('{' + variable.id + '}', variable.name);
        }
      }
      return formulaValue;
    }
    else if(value != undefined) {
      let secondsDate = new Date(0,0,0,0,0,value,0)?.toTimeString()?.slice(3,8) ?? "00:00";
      return secondsDate;
    }
    else if(minValue != undefined && maxValue != undefined) {
      let minSecondsDate = minValue ? new Date(0,0,0,0,0,minValue,0)?.toTimeString()?.slice(3,8) : "00:00";
      let maxSecondsDate = maxValue ? new Date(0,0,0,0,0,maxValue,0)?.toTimeString()?.slice(3,8) : "00:00";
      return minSecondsDate + '-' + maxSecondsDate;
    }
    return ""
  }
  
  public static getSetWeight(set: TrainingSet, availableTrainingVariables: TrainingVariable[]): string {
    if(set.weightFormula != undefined) {
      if(set.weightFormula.match(TrainingPlanEditorComponent.xRepMaxPattern)){
        return set.weightFormula;
      }
      else if(set.weightFormula.match(TrainingPlanEditorComponent.refSetPattern)){
        return set.weightFormula;
      }
      else{
        let variable = TrainingPlanEditorComponent.getVariableByFormula(set.weightFormula, availableTrainingVariables);
        if(variable){
          return set.weightFormula.replace('{' + variable.id + '}', variable.name);
        }
      }
      return set.weightFormula;
    }
    else if(set.weight != undefined) return set.weight?.toString() ?? ""
    else if (set.weightString != undefined) return set.weightString
    return ""
  }

  public static getSetValueWithVariable(set: TrainingSet, setParameter: SetParameter, availableTrainingVariables: TrainingVariable[]): string {
    let parameterString = setParameter.toString();
    let upperCaseParameter = parameterString.charAt(0).toUpperCase() + parameterString.slice(1);
    if(set[parameterString + "Formula"] != undefined) {
      if(set[parameterString + "Formula"].match(TrainingPlanEditorComponent.xRepMaxPattern)){
        return set[parameterString + "Formula"];
      }
      else if(set[parameterString + "Formula"].match(TrainingPlanEditorComponent.refSetPattern)){
        return set[parameterString + "Formula"];
      }
      else{
        let variable = TrainingPlanEditorComponent.getVariableByFormula(set[parameterString + "Formula"], availableTrainingVariables);
        if(variable){
          return set[parameterString + "Formula"].replace('{' + variable.id + '}', variable.name);
        }
      }
      return set[parameterString + "Formula"];
    }
    else if(set[parameterString] != undefined) return set[parameterString]?.toString() ?? ""
    else if(set["min" + upperCaseParameter] != undefined && set["max" + upperCaseParameter] != undefined) return set["min" + upperCaseParameter] + '-' + set["max" + upperCaseParameter]
    else if(set[parameterString + "String"] != undefined) return set[parameterString + "String"]
    return ""
  }

  static getVariableIdByFormula(formula: string){
    let varId = formula.split('%{')[1]?.split('}')[0];
    return varId;
  }

  static getVariableByFormula(formula: string, availableTrainingVariables: TrainingVariable[]){
    let varId = TrainingPlanEditorComponent.getVariableIdByFormula(formula);
    let variable = availableTrainingVariables.find(x => x.id == varId);
    if(variable){
      return variable;
    }
    return null;
  }

  
  public static getSetHeartRate(set: TrainingSet, availableTrainingVariables: TrainingVariable[], cardioZones: CardioZone[]): string{
    if(set.heartRateFormula != undefined) {
      if(set.heartRateFormula.match(TrainingPlanEditorComponent.xRepMaxPattern)){
        return set.heartRateFormula;
      }
      else if(set.heartRateFormula.includes(`${this.cardioZonePrefix}{`)){
        let zoneIdPart = set.heartRateFormula.split(`%${TrainingPlanEditorComponent.cardioZonePrefix}{`)[1];
        if(zoneIdPart){
          let zoneId = zoneIdPart.split('}')[0];
          let zone = TrainingPlanEditorComponent.getCardioZoneById(zoneId, cardioZones);
          if(zone){
            return zone.name;
            // return set.heartRateFormula.replace('{' + zone.id + '}', zone.name);
          }
        }
      }
      else{
        let variable = TrainingPlanEditorComponent.getVariableByFormula(set.heartRateFormula, availableTrainingVariables);
        if(variable){
          return set.heartRateFormula.replace('{' + variable.id + '}', variable.name);
        }
      }
      return set.heartRateFormula;
    }
    else if(set.heartRate != undefined) return set.heartRate?.toString() ?? ""
    else if(set.minHeartRate != undefined && set.maxHeartRate != undefined) return set.minHeartRate + '-' + set.maxHeartRate
    return ""
  }

  static getUserCardioZoneGroupByExerciseId(exerciseId: string, setParameter: SetParameter, cardioZoneGroups: CardioZoneGroup[]){
    return cardioZoneGroups.find(x => x.exerciseIds.includes(exerciseId) && x[setParameter + "Available"]) ?? cardioZoneGroups.find(x => x.isDefaultZoneGroup && x[setParameter + "Available"]);
  }


  
  static getCardioZoneByName(zoneName: string, availableCardioZones: CardioZone[]){
    return availableCardioZones.find(x => x.name == zoneName);
  }

  static getCardioZoneById(zoneId: string, availableCardioZones: CardioZone[]){
    return availableCardioZones.find(x => x.id == zoneId);
  }
  
  static getAllVariablesOfSession(session: TrainingSession, availableVariables: TrainingVariable[]): {availableVariables: TrainingVariable[], missingVariableIds: string[]} {
    let usedVariables: TrainingVariable[] = [];
    let missingVariableIds: string[] = [];
    session.exercises.forEach(exercise => {
      if(!exercise.deleted){
        exercise.setParameters.forEach(setParameter => {
          exercise.sets.forEach(set => {
            let formula = set[setParameter + 'Formula'];
            if(formula?.includes('%')){
              let parsedVariable = TrainingPlanEditorComponent.getVariableByFormula(formula, availableVariables)
              if(parsedVariable){
                let found = usedVariables.find(x => x == parsedVariable);
                if(!found){
                  usedVariables.push(parsedVariable)
                }
              }
              else {
                missingVariableIds.push(TrainingPlanEditorComponent.getVariableIdByFormula(formula));
              }
            }
          })
        });
      }
    });
    return { availableVariables: usedVariables, missingVariableIds: missingVariableIds };
  }

  static getAllVariablesOfTrainingPlan(trainingPlan: TrainingPlan, availableVariables: TrainingVariable[]): { availableVariables: TrainingVariable[]; missingVariableIds: any; } {
    let usedVariables: TrainingVariable[] = [];
    let missingVariableIds: string[] = [];
    trainingPlan.sessions.forEach(session => {
      if(!session.deleted) {
        let variables = TrainingPlanEditorComponent.getAllVariablesOfSession(session, availableVariables);
        usedVariables = usedVariables.concat(variables.availableVariables);
        missingVariableIds = missingVariableIds.concat(variables.missingVariableIds);
      }
    });
    return { availableVariables: usedVariables, missingVariableIds: missingVariableIds };
  }


  static setCalculatedFormulaValue(set: TrainingSet, setParameter: SetParameter, availableTrainingVariables: TrainingVariable[], availableCardioZones: CardioZone[]): boolean {
    let setProperty = setParameter.toString();
    let formulaProperty = setProperty + 'Formula';
    let uppperCaseSetProperty = setProperty.charAt(0).toUpperCase() + setProperty.slice(1);
    if(set[formulaProperty]?.toLowerCase()?.includes("%") && !set[formulaProperty].match(TrainingPlanEditorComponent.xRepMaxPattern) && !set[formulaProperty].match(TrainingPlanEditorComponent.oneRmTopsetPattern) && !set[formulaProperty].match(TrainingPlanEditorComponent.refSetPattern) && !set[formulaProperty].includes(`%${TrainingPlanEditorComponent.cardioZonePrefix}{`)){
      let variable = TrainingPlanEditorComponent.getVariableByFormula(set[formulaProperty], availableTrainingVariables);
      if(variable){
        let percentage = parseInt(set[formulaProperty]?.split('%')[0]);
        if(percentage > 0){
          if(variable[setProperty] === undefined){
            let minVariableValue = variable["min"+ uppperCaseSetProperty];
            let maxVariableValue = variable["max"+ uppperCaseSetProperty];

            if(minVariableValue != undefined && maxVariableValue != undefined){
              if(setParameter == SetParameter.pace || setParameter == SetParameter.pace500){
                  let minPropertyValue = minVariableValue / (percentage / 100);
                  let maxPropertyValue = maxVariableValue / (percentage / 100);
                  if(minPropertyValue > 0 && maxPropertyValue > 0){
                    set[setProperty] = undefined;
                    set["min" + uppperCaseSetProperty] = Math.round(minPropertyValue);
                    set["max" + uppperCaseSetProperty] = Math.round(maxPropertyValue);
                    return true;
                  }
                }
                else {
                  let minPropertyValue = minVariableValue * percentage / 100;
                  let maxPropertyValue = maxVariableValue * percentage / 100;
                  if(minPropertyValue > 0 && maxPropertyValue > 0){
                    set[setProperty] = undefined;
                    if(setParameter == SetParameter.heartRate || setParameter == SetParameter.reps){
                      set["min" + uppperCaseSetProperty] = Math.round(minPropertyValue);
                      set["max" + uppperCaseSetProperty] = Math.round(maxPropertyValue);
                    }
                    else {
                      set["min" + uppperCaseSetProperty] = minPropertyValue;
                      set["max" + uppperCaseSetProperty] = maxPropertyValue;
                    }
                    return true;
                  }
                } 
              }
          }
          else {
            
            if(setParameter == SetParameter.pace || setParameter == SetParameter.pace500){
              let propertyValue = variable[setProperty] / (percentage / 100);
              if(propertyValue > 0){
                set[setProperty] = Math.round(propertyValue);
                set["min" + uppperCaseSetProperty] = undefined;
                set["max" + uppperCaseSetProperty] = undefined;
                return true;
              }
            }
            else {
              let propertyValue = variable[setProperty] * percentage / 100;
              if(propertyValue > 0){
                if(setParameter == SetParameter.heartRate || setParameter == SetParameter.reps){
                  set[setProperty] = Math.round(propertyValue);
                }
                else {
                  set[setProperty] = propertyValue;
                }
                set["min" + uppperCaseSetProperty] = undefined;
                set["max" + uppperCaseSetProperty] = undefined;
                return true;
              }
            }
          }
        }
      }
      return false;
    }
    else if(set[formulaProperty]?.includes(`%${TrainingPlanEditorComponent.cardioZonePrefix}{`)){
      let splitted = set[formulaProperty].split(`%${TrainingPlanEditorComponent.cardioZonePrefix}{`);
      let percentage = parseInt(splitted[0]);
      let zoneId = splitted[1]?.replace('}', '');
      let zone = TrainingPlanEditorComponent.getCardioZoneById(zoneId, availableCardioZones);
      if(zone && !isNaN(percentage)){
        if(zone.isMaxCardioZone){
          if(setParameter == SetParameter.pace || setParameter == SetParameter.pace500){
            if(zone["min" + uppperCaseSetProperty] != undefined){
              set[setProperty] = Math.round(zone["min" + uppperCaseSetProperty] * percentage / 100);
              set["min" + uppperCaseSetProperty] = undefined;
              set["max" + uppperCaseSetProperty] = undefined;
              return true;
            }
          }
          else if(setParameter == SetParameter.heartRate){
            if(zone.minHeartRate != undefined){
              set[setProperty] = Math.round(zone.minHeartRate * percentage / 100);
              set["min" + uppperCaseSetProperty] = undefined;
              set["max" + uppperCaseSetProperty] = undefined;
              return true;
            }
          }
        }
        else {
          if(setParameter == SetParameter.pace || setParameter == SetParameter.pace500){
            if(zone["min" + uppperCaseSetProperty] != undefined && zone["max" + uppperCaseSetProperty] != undefined){
              set[setProperty] = undefined;
              set["min" + uppperCaseSetProperty] = Math.round(zone["min" + uppperCaseSetProperty] / (percentage / 100));
              set["max" + uppperCaseSetProperty] = Math.round(zone["max" + uppperCaseSetProperty] / (percentage / 100));
              return true;
            }
          }
          else if(setParameter == SetParameter.heartRate){
            if(zone.minHeartRate != undefined && zone.maxHeartRate != undefined){
              set[setProperty] = undefined;
              set["min" + uppperCaseSetProperty] = Math.round(zone.minHeartRate * percentage / 100);
              set["max" + uppperCaseSetProperty] = Math.round(zone.maxHeartRate * percentage / 100);
              return true;
            }
          }
        }
      }
    }
    return true;
  }



  getWeekNumberByIndex(index: number): number {
    return Math.trunc(index / 7);
  }

  getMaxWeekDate(weekNumber: number): Date {
    let date = new Date(this.trainingPlan.startDate)
    date.setDate(date.getDate() + (weekNumber + 1) * 7)
    return date
  }

  getMinWeekDate(weekNumber: number): Date {
    let date = new Date(this.trainingPlan.startDate)
    date.setDate(date.getDate() + weekNumber * 7)
    return date
  }

  onSessionPlannedDateChanged(value: Date, session: TrainingSession){
    session.plannedDate = value
  }

  static getBaseSessionReferenceColor(session: TrainingSession, availableSessions: TrainingSession[]){
    if(session.baseSessionId){
      return TrainingPlanEditorComponent.getSessionColor(session.baseSessionId, availableSessions);
    }
    else {
      let duplicatedSession = availableSessions?.find(x => !x.deleted && x.baseSessionId == session.id)
      if(duplicatedSession){
        let index = availableSessions?.filter(x => !x.deleted && !x.baseSessionId)?.indexOf(session);
        return session.indicatorColor ?? BaseTrainingEditor.getColorOfIndex(index);
      }
      else {
        return session.indicatorColor;
      }
    }
  }

  getBaseSessionReferenceColor(session: TrainingSession){
    return TrainingPlanEditorComponent.getBaseSessionReferenceColor(session, this.trainingPlan.sessions);
  }

  static getSessionColor(sessionId: string, availableSessions: TrainingSession[]){
    let baseSession = availableSessions?.find(x => !x.deleted && x.id == sessionId);
    if(baseSession){
      let index = availableSessions?.filter(x => !x.deleted && !x.baseSessionId)?.indexOf(baseSession);
      return baseSession.indicatorColor ?? BaseTrainingEditor.getColorOfIndex(index);
    }
    return '';
  }

  getSessionColor(sessionId: string){
    return TrainingPlanEditorComponent.getSessionColor(sessionId, this.trainingPlan.sessions);
  }

  public weekDaysByWeekId: Map<string, Date[]> = new Map<string, Date[]>();

  setWeekDays(){
    this.weekDaysByWeekId = new Map<string, Date[]>();
    let weeks = this.trainingPlan.weeks;
    for(let week of weeks){
      this.weekDaysByWeekId[week.id] = this.getWeekDays(week.id);
    }
    this.trainingPlan.setDurationInDays();
    this.setCalendarItems();

    if(this.trainingPlan.isPeriodicPlan){
      let lastWeek = this.trainingPlan.weeks[this.trainingPlan.weeks.length - 1];
      if(lastWeek){
        let lastWeekDays = this.weekDaysByWeekId[lastWeek.id];
        if(lastWeekDays){
          let lastDay = lastWeekDays[lastWeekDays.length - 2];
          if(lastDay) {
            this.trainingPlan.endDate = lastDay.clone();
          }
        }
      }
    }
  }

  getWeekDays(weekId: string): Date[]{
    let weekNumber = TrainingPlanEditorComponent.getWeekNumberByWeekId(this.trainingPlan, weekId);
    let startDate = this.trainingPlan.startDate ?? new Date(0);
    let weekStartDate = new Date(startDate);
    weekStartDate.setDate(weekStartDate.getDate() + weekNumber * 7);
    weekStartDate.setHours(0,0,0,0);
    let weekEndDate = new Date(weekStartDate);
    weekEndDate.setDate(weekEndDate.getDate() + 6);
    weekEndDate.setHours(0,0,0,0);
    let weekDays = [];
    for(let index = 0; index < 7; index++){
      let date = new Date(weekStartDate);
      date.setDate(date.getDate() + index);
      date.setHours(0,0,0,0);
      weekDays.push(date);
    }

    weekDays.push(null);
    return weekDays;
  }

  isFirstDayOfWeekInThePast(week: Week){
    let weekDays = this.weekDaysByWeekId[week.id];
    if(weekDays){
      let today = new Date();
      today.setHours(0,0,0,0);
      let firstDay = weekDays[0];
      if(firstDay){
        return firstDay < today;
      }
    }
    return false;
  }


  isWeekInThePast(week: Week){
    if(this.trainingPlan.isTemplate) return false;
    let weekDays = this.weekDaysByWeekId[week.id];
    if(weekDays){
      let today = new Date();
      today.setHours(0,0,0,0);
      let lastDay = weekDays[weekDays.length - 2];
      if(lastDay){
        return lastDay < today;
      }
    }
    return false;
  }


  isDayInThePast(weekDay: Date){
    if(this.trainingPlan.isTemplate) return false;
    let today = new Date();
    today.setHours(0,0,0,0);
    return weekDay != null && weekDay < today;
  }

  getWeekDayString(weekDay: Date){
    if(this.trainingPlan.isTemplate) {
      let diffToMonday = 8 - (this.trainingPlan?.startDate?.getDay() ?? 0);
      let dayNumber = (weekDay.getDay() + diffToMonday) % 7;
      return '<span class="weekday">' + NumberToShortWeekDayStringsMapping[dayNumber] + '</span>'
    }
    return '<span class="weekday">' + NumberToShortWeekDayStringsMapping[weekDay.getDay()] + '</span>, ' + weekDay.asShortFormatedString();
  }

  
}