<div class="card-container">
    <div class="card-header" (click)="foldChat()">
        <div class="profile-container">
            <div class="row flex-nowrap">
                <div class="col profile-header">
                    <div class="profile-picture" *ngIf="!chat.isBroadcastChat">
                        <img draggable="false" src="{{chat.chatPartner.profilePictureUrl}}">
                    </div>
                    <div class="d-inline-block broadcast-icon" *ngIf="chat.isBroadcastChat">
                        <i class="d-flex bi bi-megaphone"></i>
                    </div>
                    <h3 *ngIf="!chat.isBroadcastChat" class="profile-name hover-underline link" routerLink="/client/{{chat.chatPartner.uid}}" (click)="onClientRoute();$event.stopPropagation()">
                        {{chat.chatPartner.getName()}}
                    </h3>
                    <h3 *ngIf="chat.isBroadcastChat" class="profile-name">
                        Broadcast-Nachricht
                    </h3>
                    <div *ngIf="chat.unreadMessages?.length > 0" tooltip="Als gelesen markieren" (click)="userService.markMessagesAsRead(chat);$event.stopPropagation()" class="message-indication single-chat glyphicon link"></div>
                </div>
                <div class="col-auto option-buttons-column">
                    <div class="row textalign-right">
                        <div class="col-5 col-sm-12" >
                            <!-- <div class="icon-button small" routerLink="/client/{{chat.chatPartner.uid}}" (click)="$event.stopPropagation()">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-up-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                </svg>
                                Coachee öffnen
                            </div> -->
                            <div *ngIf="!chat.isBroadcastChat" class="display-inlineblock marginleft-10">
                                <button mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="25" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                      <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                    </svg>
                                </button>
                                <mat-menu #menu="matMenu" class="padding-15 chat-cdk-overlay-container">
                                    <button mat-menu-item (click)="chatService.markLastMessageAsUnread(chat)">Als ungelesen markieren</button>
                                    <div class="spacer-10"></div>
                                    <mat-divider></mat-divider>
                                    <div class="spacer-10"></div>
                                    <div class="recipe-sharing-settings">
                                        <div *ngIf="showHideChatSpinner" class="display-inlineblock">
                                            <mat-spinner class="loading-statistics-spinner" diameter="25" strokeWidth="2"></mat-spinner>
                                        </div>
                                        <div *ngIf="!showHideChatSpinner" class="recipesharing-toggle toggle-wrapper marginleft-0 display-inlineblock">
                                            <div class="toggle {{isHideChatEnabled() ? 'on' : ''}}" (click)="onHideChatChanged(!isHideChatEnabled());$event.stopPropagation()">
                                                <div class="handle"></div>
                                            </div>
                                            <div class="text">
                                                Chat deaktivieren
                                            </div>
                                        </div>
                                    </div>
                                    <div class="spacer-10"></div>
                                    <div class="recipe-sharing-settings">
                                        <div *ngIf="showEnableVoiceMessageSpinner" class="display-inlineblock">
                                            <mat-spinner class="loading-statistics-spinner" diameter="25" strokeWidth="2"></mat-spinner>
                                        </div>
                                        <div *ngIf="!showEnableVoiceMessageSpinner" class="recipesharing-toggle toggle-wrapper marginleft-0 display-inlineblock">
                                            <div class="toggle {{isVoiceMessageEnabled() ? 'on' : ''}}" (click)="onEnableVoiceMessageChanged(!isVoiceMessageEnabled());$event.stopPropagation()">
                                                <div class="handle"></div>
                                            </div>
                                            <div class="text">
                                                Sprachnachrichten aktivieren <span *ngIf="user.coacheeVoiceMessageEnabled == null">(Standardeinstellung)</span>
                                            </div>
                                        </div>
                                    </div>
                                </mat-menu>
                            </div>
                            <div *ngIf="canFold()" class="display-inlineblock marginleft-10 link" (click)="foldChat();$event.stopPropagation()">
                                <ng-container *ngIf="isFolded; else foldedIndicator;">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                                      </svg>
                                </ng-container>
                                <ng-template #foldedIndicator>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                                    </svg>
                                </ng-template>
                            </div>
                            <div class="display-inlineblock marginleft-10 link" (click)="closeChat()">
                                <svg width="30" height="30" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="load-more-messages float-right" *ngIf="loading">
                <mat-spinner class="loading-statistics-spinner" diameter="30" strokeWidth="2"></mat-spinner>
            </div>
        </div>
    </div>
    <div class="card-body"  [@panelState]="{value: isFolded}">
        <div *ngIf="chat.isBroadcastChat" class="broadcast-body">
            <div class="form-group">
                <label for="dropdownRepetion">Senden an </label>
                <div class="d-inline-block wrapper-repetition-selection">
                    <button class="button-filled light small dropdown-toggle margin-inbetween" [class.button-invalid]='getPrintableBroadcastTarget()?.length === 0' type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getPrintableBroadcastTarget()}}</button>
                    <div class="dropdown-menu dropdown-notificationTarget" aria-labelledby="dropdownNotificationTarget">
                        <div class="dropdown-item link" (click)="addClientGroupToBroadcast('Alle');$event.stopPropagation()">
                            <input [disabled]="!canAccessGroup('Alle')" class="input checkbox" type="checkbox" [checked]="getBroadcastChat(chat)?.selectedClientGroups?.includes('Alle')"><div class="display-inlineblock">Alle</div>
                        </div>
                        <ng-container *ngIf="userService.getClientGroups()?.length > 0">
                            <div class="dropdown-divider"></div>
                            <div class="label marginleft-10">Gruppen</div>
                            <div class="dropdown-item link" *ngFor="let clientGroup of userService.getClientGroups()" (click)="addClientGroupToBroadcast(clientGroup);$event.stopPropagation()">
                                <input [disabled]="clientGroup != 'Alle' && areAllUsersTargeted() || !canAccessGroup(clientGroup)" class="input checkbox" type="checkbox" [checked]="getBroadcastChat(chat)?.selectedClientGroups?.includes(clientGroup)"><div class="display-inlineblock">{{clientGroup}}</div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="selectableCoaches?.length > 0">
                            <div class="dropdown-divider"></div>
                            <div class="label marginleft-10">Zuordnungen</div>
                            <ng-container *ngFor="let coach of selectableCoaches">
                                <div class="dropdown-item link" (click)="addCoachToBroadcast(coach);$event.stopPropagation()">
                                    <input [disabled]="areAllUsersTargeted()" class="input checkbox" type="checkbox" [checked]="getBroadcastChat(chat)?.selectedCoaches.includes(coach)"><div class="display-inlineblock">{{coach.name}}'s Kunden</div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <div class="dropdown-divider"></div>
                        <div class="label marginleft-10">Kunden</div>
                        <div class="dropdown-item link" *ngFor="let c of chatService.getChats()" (click)="addChatToBroadcast(c);$event.stopPropagation()">
                            <input [disabled]="areAllUsersTargeted()" class="input checkbox" type="checkbox" [checked]="getBroadcastChat(chat)?.selectedChats?.includes(c)"><div class="display-inlineblock">{{c.chatPartner.getName()}}</div>
                        </div>
                    </div>
                </div>
                <div class="label">
                    Wähle alle Empfänger aus, an die du eine Chat-Nachricht senden möchtest. Alle Empfänger erhalten eine eigene, private Chatnachricht.
                </div>
            </div>
        </div>
        <div *ngIf="!chat.isBroadcastChat" class="inner-chat  {{selectedFiles?.length > 0 ? 'with-attachement' : ''}}" id="{{id}}-innerchat" (scroll)="onScroll($event)">
            <div *ngFor="let m of messages">
                <div class="d-flex {{m.isFromPartner ? 'justifycontent-left' : 'justifycontent-right'}}">
                    <div *ngIf="!m.isFromPartner"></div>
                    <div *ngIf="!m.isDeleted" class="message {{m.isFromPartner ? 'left' : 'right'}} {{m.hasAudioAttachment() ? 'audio-message' : ''}}">
                        <div class="col-12" *ngIf="m.hasSharedFileReference() && m.sharedFile" (click)="onOpenReferencedSharedFile(m)">
                            <div class="row template-file-box link">
                                <img draggable="false" *ngIf="m.sharedFile.thumbnailLink" class="col-auto file-thumbnail-image p-0" [src]="m.sharedFile.thumbnailLink">
                                <div *ngIf="!m.sharedFile.thumbnailLink" class="col-auto file-thumbnail-image">
                                    <div class="create-template-folder-button" *ngIf="m.sharedFile.isVideo()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-camera-video" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z"/>
                                        </svg>
                                    </div>
                                    <div class="create-template-folder-button" *ngIf="!m.sharedFile.isVideo()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
                                            <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
                                        </svg>
                                    </div>
                                </div>
                                <div class="col file-info-container">
                                    <strong class="folder-name">
                                        {{m.sharedFile.name?.GetValue('de')}}
                                    </strong>
                                </div>
                            </div>
                            <!-- <div class="col">
                                <div *ngIf="m.sharedFile" class="image-wrapper">
                                    <a class="attached-file" (click)="onOpenReferencedSharedFile(m)" ><app-cached-image class="inner-img" [src]="m.sharedFile?.thumbnailLink" [alt]="" [spinner]="true" [cached]="false"></app-cached-image></a>
                                    <div class="link label text-center" (click)="onOpenReferencedSharedFile(m)">Verknüpfte Datei anzeigen</div>
                                </div>
                            </div> -->
                        </div>
                        <div class="row">
                            <div class="col">
                                <div (mousedown)="$event.stopPropagation()" [innerHtml]="m.message | urlify "></div>
                                <div *ngIf="m.hasAttachment() && m.hasImageAttachment()" class="image-wrapper">
                                    <!-- <a class="attached-file" (click)="onOpenAttachedImage(m.attachmentUrl)" ><img src="{{m.attachmentUrl}}" alt=""></a> -->
                                    <a class="attached-file" (click)="onOpenAttachedImage(m.attachmentUrl)" ><app-cached-image class="inner-img" [src]="m.attachmentUrl" [alt]="" [spinner]="true" [cached]="false"></app-cached-image></a>
                                </div>
                                <div *ngIf="m.hasAttachment() && m.hasAudioAttachment()" class="audio-wrapper">
                                    <app-audio-player [src]="m.attachmentUrl" (audioPlayerRef)="m.audioPlayerRef = $event" [canPlay]="chat.isOpen" [playerId]="m.messageId" [(playingId)]="chatService.playingMessageId"></app-audio-player>
                                </div>
                                <div *ngIf="m.hasAttachment() && !m.hasImageAttachment() && !m.hasAudioAttachment()" class="file-wrapper">
                                    <a class="attached-file" href="{{m.attachmentUrl}}" target="_blank">{{m.attachedFileName}}</a>
                                </div>
                            </div>
                            <div class="message-menu">
                                <div *ngIf="m.message || m.uid === uid">
                                    <button mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                        </svg>
                                    </button>
                                    <mat-menu #menu="matMenu" class="chat-cdk-overlay-container">
                                        <button *ngIf="m.message" mat-menu-item (click)="onCopyMessage(m)">Kopieren</button>
                                        <button *ngIf="m.uid === uid" mat-menu-item (click)="onDeleteMessage(m)">Löschen</button>
                                        <!-- <button *ngIf="m.uid !== uid" mat-menu-item (click)="chatService.markMessageAsUnread(m)">Als ungelesen markieren</button> -->
                                    </mat-menu>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col" *ngIf="m.hasAttachment() && m.hasAudioAttachment()">
                                <div *ngIf="m.audioPlayerRef?.isLoaded == true" class="label">{{m.audioPlayerRef?.currentPrintableTime}}</div>
                                <div *ngIf="m.audioPlayerRef?.isLoaded == false" class="">
                                    <mat-spinner class="loading-statistics-spinner" diameter="15" strokeWidth="2"></mat-spinner>
                                </div>
                            </div>
                            <div class="col">
                                <div class="message-time label">{{m.getPrintableDate()}}</div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="m.isDeleted" class="message {{m.uid != uid ? 'left' : 'right'}} deleted-message">
                        <svg id="slash-circle" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
                            <path id="Pfad_113" data-name="Pfad 113" d="M8,15a7,7,0,1,1,7-7,7,7,0,0,1-7,7Zm0,1A8,8,0,1,0,0,8,8,8,0,0,0,8,16Z"/>
                            <path id="Pfad_114" data-name="Pfad 114" d="M13.438,2.562a.5.5,0,0,0-.708,0L2.56,12.732a.5.5,0,1,0,.708.708L13.438,3.27a.5.5,0,0,0,0-.708Z"/>
                        </svg>
                        <ng-container *ngIf="m.uid != uid; else mymessagedeleted;">Diese Nachricht wurde gelöscht.</ng-container>
                        <ng-template #mymessagedeleted>Du hast diese Nachricht gelöscht.</ng-template>
                    </div>
                    
                    <div *ngIf="!m.isFromPartner && !m.senderDetails" class="sender-image-wrapper">
                        <ng-container *ngIf="chatService.getSenderDetailsForMessage(m) | async; let details">
                            <div class="profile-picture">
                                <img draggable="false" src="{{details.profileImageUrl}}">
                            </div>
                        </ng-container>
                    </div>
                    <div *ngIf="!m.isFromPartner && m.senderDetails" class="sender-image-wrapper">
                        <div class="profile-picture" tooltip="{{m.senderDetails.name}}">
                            <img draggable="false" src="{{m.senderDetails.profileImageUrl}}">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!chat.isBroadcastChat && isHideChatEnabled()" class="chat-bottom">
            <div class="padding-10">
                Der Chat ist deaktiviert.
            </div>
        </div>
        <div *ngIf="chat.isBroadcastChat || !isHideChatEnabled()" class="chat-bottom {{selectedFiles?.length > 0 ? 'with-attachement' : ''}}">
            <div class="container row" *ngIf="chat.isBroadcastChat">
                <b>
                    Platzhalter einfügen: 
                </b>
                <div class="link message-place-holder" (click)="placeHolderSelected(MessagePlaceHolder.Name)">
                    Name
                </div>
            </div>
            <div class="message-field">
                <textarea id="messageTextArea" (mousedown)="$event.stopPropagation()" *ngIf="!audioRecorder.IsRecording() && !voiceRecordingFile" [(ngModel)]="message" [disabled]="isBusy" class="input-message" type="text" required autofocus></textarea>
                <div class="voice-recording-area" *ngIf="audioRecorder.IsRecording() || voiceRecordingFile">
                    <div class="recorder-time">
                        <svg *ngIf="audioRecorder.IsRecording()" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="red" class="bi bi-mic blink-red" viewBox="0 0 16 16">
                            <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"/>
                            <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z"/>
                        </svg>
                        {{audioRecorder.printableSeconds}}
                    </div>
                </div>
                <button class="add-attachment" (click)="onSelectAttachment()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-paperclip" viewBox="0 0 16 16">
                        <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"/>
                    </svg>
                        <input id="input-chat-attachment-{{chat?.chatId}}" type="file" multiple style="display: none;" accept=".jpg, .png, .pdf" (change)="onAttachmentSelected($event)">
                </button>
                <button class="toggle-emoji-picker" (click)="isEmojiPickerVisible = !isEmojiPickerVisible;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-emoji-smile" viewBox="0 0 18 18">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"/>
                    </svg>
                </button>
                <button class="send-message" (click)="sendMessage()" *ngIf="!isBusy && (message || selectedFiles?.length > 0 || voiceRecordingFile)">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </button>
                <button class="send-message" (mouseup)="toggleAudioRecording($event);$event.stopPropagation()" (touchstart)="touchstartAudioRecording($event);$event.stopPropagation()" (touchend)="touchendAudioRecording($event);$event.stopPropagation()" *ngIf="!isBusy && !message && !(selectedFiles?.length > 0) && !voiceRecordingFile">
                    <div class="{{audioRecorder.IsRecording() ? 'hide' : ''}}">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-mic" viewBox="0 0 16 16">
                            <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"/>
                            <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z"/>
                        </svg>
                    </div>
                    <div class="{{!audioRecorder.IsRecording() ? 'hide' : ''}}">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                </button>
                <div class="send-message" *ngIf="isBusy">
                    <mat-spinner class="loading-statistics-spinner" diameter="30" strokeWidth="2"></mat-spinner>
                </div>
                <button class="cancel-recording" (click)="cancelAudioRecording()" *ngIf="!isBusy && (voiceRecordingFile || audioRecorder.IsRecording())">
                    <svg _ngcontent-vsm-c56="" class="bi bi-trash" fill="currentColor" height="1em" viewBox="0 0 16 16" width="1em" xmlns="http://www.w3.org/2000/svg"><path _ngcontent-vsm-c56="" d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"></path><path _ngcontent-vsm-c56="" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" fill-rule="evenodd"></path></svg>
                </button>
            </div>
        </div>
        <div *ngIf="selectedFiles?.length > 0" class="selected-attachment" [matTooltip]="getAttachedFileTooltip(selectedFiles)" matTooltipClass="day-item-tooltip">
            <div class="chip attached-file-chip" target="_blank">
                <div class="icon-button" (click)="onRemoveSelectedFiles()">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </div>
                {{getAttachementString(selectedFiles)}}
            </div>
        </div>
    </div> 
</div>
<emoji-mart [enableSearch]="false" [style]="{ position: 'absolute', bottom: '0px'}" class="emoji-mart" *ngIf="isEmojiPickerVisible" (emojiSelect)="handleEmojiSelection($event)" title="Emoji wählen"></emoji-mart> 