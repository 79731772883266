import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-training-tab-page',
  templateUrl: './training-tab-page.component.html',
  styleUrls: ['./training-tab-page.component.css']
})
export class TrainingTabPageComponent implements OnInit {

  public showTrainingExercises = true
  public showTrainingPlanTemplates = false

  constructor() { }

  ngOnInit(): void {
  }

  resetTabs() {
    this.showTrainingExercises = false;
    this.showTrainingPlanTemplates = false;
  }

  swapTab(tab: string) {
    this.resetTabs();
    switch(tab) {
      case "showTrainingExercises":
        this.showTrainingExercises = true;
        break;
      case "showTrainingPlanTemplates":
        this.showTrainingPlanTemplates = true;
        break;
      default:
        break;
    }
  }

}
