<div class="dialog-topbar">
    <h3>Bild generieren</h3>
</div>
<div class="dialog-content-wrapper">

    Prompt:
    <div class="spacer-10"></div>
    <textarea class="input full-width" [(ngModel)]="prompt" placeholder="Prompt" type="text" rows="5"></textarea>

    <div class="spacer-10"></div>
    <button *ngIf="!loading" class="button-filled small turquiose float-right" (click)="onGenerateImage()">Generieren</button>
    <div class="spacer-25"></div>
    <div *ngIf="imageUrl && !loading">
        Ergebnis:
        <img draggable="false" src="{{imageUrl}}" class="image-result">
    </div>
    <div *ngIf="loading" class="loading-animation">
        <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
    </div>

    <br>
    <div class="actions">
        <button class="button-filled small light" (click)="onCancelDialog()">Abbrechen</button>
        <button class="button-filled small light float-right" (click)="onConfirmDialog()">Übernehmen</button>
    </div>
</div>