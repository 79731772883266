import { Meal } from './meal.model';
import { Activity } from './activity.model';
import { BodyData } from './bodydata.model';
import { NutritionalRequirements } from './nutritionalrequirements.model';
import { NutritionalGoal } from './nutritionalgoal.model';
import { NutritionalSummary } from './nutritionalsummary.model';
import { PortalSettingsCoach } from './portalsettings-coach.model';
import { NutritionPlan } from './nutritionplan.model';
import { NutritionStatisticsItem } from './nutritionstatistics.model';
import { DailyCondition } from './dailycondition.model';
import { Note } from './note.model';
import { Metric } from './metric.model';
import { Licence } from './lid.model';
import { NutritionalValueHolder } from './basenutritionfact.model';
import { Metadata, MetadataUser } from './metadata-user.model';
import { CoachSubscription } from './subscription.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Coach } from './coach.model';
import { PortalSettingsLicenceHolder } from './portalsettings-licenceholder.model';
import { CycleConfig } from './nutritionconfig.model';
import { NutritionPlanConfig } from './nutritionplanconfig.model';
import { environment } from 'src/environments/environment';
import { TrainingPlan, TrainingSession } from './training-plan.model';
import { LicenceHolder } from './licenceholder.model';
import { TrackedTrainingSession } from './training-monitoring.model';
import { AssignedQuestionaire, QuestionaireResult } from './questionaires.model';
import { EventEmitter } from '@angular/core';
import { TrainingVariable } from './training-variables.model';
import { TrainingSettingsLicenceHolder } from './training-settings-licence-holder.model';
import { ProductPurchase } from './product-purchase.model';
import { CardioZoneGroup } from './cardio-zone-group.model';

export const GENDER_MALE = "m"
export const GENDER_FEMALE = "f"
export const GENDER_FEMALE_ALT = "w"
export const GENDER_DIV = "d"

export const PHYSICAL_ACTIVITY_LEVEL_INACTIVE = "inactive"
export const PHYSICAL_ACTIVITY_LEVEL_LITTLE_ACTIVE = "little_active"
export const PHYSICAL_ACTIVITY_LEVEL_ACTIVE = "active"
export const PHYSICAL_ACTIVITY_LEVEL_VERY_ACTIVE = "very_active"

export class PalValue {
    name: string
    value: number
    encoding: string
}
export const PalValues: PalValue[] = [
    { name: 'Inaktiv (Sitzend)', value: 1.4, encoding: PHYSICAL_ACTIVITY_LEVEL_INACTIVE },
    { name: 'Etwas aktiv (Stehend)', value: 1.6, encoding: PHYSICAL_ACTIVITY_LEVEL_LITTLE_ACTIVE },
    { name: 'Aktiv (Gehend)', value: 1.8, encoding: PHYSICAL_ACTIVITY_LEVEL_ACTIVE },
    { name: 'Sehr aktiv (Körperlich anstrengend)', value: 2.0, encoding: PHYSICAL_ACTIVITY_LEVEL_VERY_ACTIVE },
]

export class User implements NutritionalValueHolder {
    
    uid: string;
    name: string;
    lastName: string;
    lid: string;
    licence: Licence;
    fcmToken: string;
    gender: string;
    physicalActivityLevel: string;
    appLocale: string
    meals: Meal[];
    activities: Activity[];
    bodyData: BodyData[];
    stripeCustomerIds: Map<string, string>
    activitiesOfCurrentCalendarWeek: Activity[];

    dailyConditionChanged = new EventEmitter<DailyCondition[]>()
    private _dailyConditions: DailyCondition[];

    get dailyConditions(): DailyCondition[] {
        return this._dailyConditions;
    }
    set dailyConditions(value: DailyCondition[]){
        this._dailyConditions = value;
        this.dailyConditionChanged.emit(value);
    }

    dailyCondition: DailyCondition;
    nutritionalGoal: NutritionalGoal;
    nutritionalGoalFromCoach: NutritionalGoal;
    nutritionalGoalsFromCoach: NutritionalGoal[]
    assignedMetrics: Metric[]
    nutritionalSummary: NutritionalSummary;
    nutritionalRequirement: NutritionalRequirements[];
    statistics: NutritionStatisticsItem[]
    statisticsUpdated: BehaviorSubject<boolean>
    currentlyLoadingNutritionStatistics = false
    currentlyLoadingBodyDataStatistics = false
    remoteControlEnabled: boolean;
    usesNutritionalRequirement: boolean;
    basicCaloriesRequirement: number;
    basicCarbohydratesRequirement: number;
    basicProteinRequirement: number;
    basicFatRequirement: number;
    adjustNutritionalGoalWithActivities: boolean;
    autoCalculateCalorieRequirement: boolean;
    birthDate: Date;
    age: number;
    bodyHeight: number;
    bodyWeight: number;
    latestBodyWeight: number;
    firstBodyWeight: number;
    nutritionPlans: NutritionPlan[]
    trainingPlans: TrainingPlan[]
    notes: Note[]
    metadataUser: MetadataUser
    metadata: Metadata
    hideNutritionValues: boolean;
    hideChat: boolean
    recipeSuggestionsEnabled: boolean
    nutritionEnabled: boolean
    coacheeTrainingPlanCreationEnabled: boolean
    coacheeNutritionalGoalEditingEnabled: boolean
    coacheeVoiceMessageEnabled: boolean
    nextCompetitionDate: Date
    defaultExertionParameter: string
    profileImagePath: string = undefined
    spikeUserId: string
    spikeProvider: string

    trackedTrainingSessions: TrackedTrainingSession[]
    trainingSessionQueryStartDate: Date
    trainingSessionQueryEndDate: Date
    observableTrainingSessionLoader: BehaviorSubject<boolean> = new BehaviorSubject(false)
    observableTrainingPlansLoader: BehaviorSubject<boolean> = new BehaviorSubject(false)

    isCoach: boolean;
    isUser: boolean;
    disabled: boolean;
    operatingSystem: string;
    versionCode: number
    portalSettingsLicenceHolder: PortalSettingsLicenceHolder;
    trainingSettingsLicenceHolder: TrainingSettingsLicenceHolder;
    licenceHolderUid: string;
    autoRecipeSharingEnabled: boolean;
    coachUid: string

    isLicenceHolder: boolean
    licenceHolder: LicenceHolder
    coach: Coach

    // Connected by licence
    licenceId: string
    connectedCoach: Coach
    connectedLicenceHolderUid: string

    assignedQuestionaires: AssignedQuestionaire[];
    chatGenerationKey: string;

    questionaireResults: QuestionaireResult[];

    trainingVariables: TrainingVariable[];
    cardioZoneGroups: CardioZoneGroup[] = [CardioZoneGroup.getEmptyDefaultZoneGroup()];

    // From AssignedQuestionaires/Settings
    weeklyQuestionaireId: string
    nextWeeklyQuestionaireDate: Date
    dailyQuestionaireId: string
    trainingFeedbackQuestionaireId: string

    // Tmp:
    profilePictureUrl: string = undefined
    bodyWeightDelta: number = null
    loadedTrainingPlans: boolean = false
    productPurchaseEndDate: Date = null

    get bodyDataMigrated(): boolean {
        return this.versionCode >= 197;
    }

    get portalSettingsCoach(): PortalSettingsCoach {
        return this.coach?.portalSettingsCoach || null
    }

    getMainTrainingPlan() {
        if (this.trainingPlans?.filter(x => !x.deleted)?.length == 0) return null
        return this.trainingPlans?.filter(x => !x.deleted)[0]
    }

    getCurrentTrainingWeek() {
        var plan = this.getMainTrainingPlan()
        if (plan) {
            var weekNumber = plan.getWeekNumberForDate(new Date())
            if (weekNumber != null && weekNumber < plan.weeks?.length) return weekNumber + 1
            return plan.weeks?.length
        }
        return null
    }
    getPrintableCurrentTrainingWeek() {
        var number = this.getCurrentTrainingWeek()
        if (number) return "Woche " + number + ' | '
        return null
    }
    getLastTrainingPlanDate() {
        return this.getExpiringTrainingPlanDate()
    }
    getPrintableLastTrainingPlanDate() {
        var date = this.getExpiringTrainingPlanDate()
        if (date) {
            return 'Ende Trainingsplan: ' + date.asFormatedString()
        }
        return null
    }
    getPrintableTrainingPlan() {
        var plan = this.getMainTrainingPlan()
        if (plan) return plan.getName() + ' | '
        return null
    }


    getNumberOfUncompletedQuestionaires() {
        return this.getUncompletedQuestionaires()?.length ?? null
    }
    getNumberOfNotViewedQuestionaires() {
        return this.assignedQuestionaires.filter(x => x.completed && x.viewedByCoach == false)?.length ?? null
    }
    getNextNotViewedQuestionaire() {
        return this.assignedQuestionaires.filter(x => x.completed && x.viewedByCoach == false)?.pop() ?? null
    }
    getUncompletedQuestionaires() {
        return this.assignedQuestionaires.filter(x => !x.completed && !x.deleted && x.assignmentDate)
    }
    getAssignedQuestionaires() {
        return this.assignedQuestionaires.filter(x => !x.deleted && x.assignmentDate)
    }
    getUncompletedQuestionairesByName() {
        var questionaires = this.getUncompletedQuestionaires()
        var map = new Map<string, AssignedQuestionaire[]>()
        questionaires.forEach(q => {
            var list = map.get(q.name)
            if (list == null) {
                list = []
                map.set(q.name, list)
            }
            list.push(q)
        })
        return map
    }

    getNumberOfNotViewedTrainings() {
        return this.trackedTrainingSessions?.filter(x => x.viewedByCoach == false).length ?? null
    }
    getNextNotViewedTraining() {
        return this.trackedTrainingSessions?.filter(x => x.viewedByCoach == false)?.pop() ?? null
    }
    getNumberOfNotViewedExerciseRecordings() {
        return this.trackedTrainingSessions?.map(x => x.trackedTrainingExercises.map(x => x.recordings.filter(x => x.viewedByCoach == false).length).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0) ?? null
    }
    getNextTrackedTrainingWithNotViewedExerciseRecording() {
        return this.trackedTrainingSessions?.find(x => x.trackedTrainingExercises.find(x => x.recordings.find(x => x.viewedByCoach == false))) ?? null
    }

    hasOtherLanguageSettings() {
        return this.appLocale != 'de'
    }

    hasRecipeDatabaseAccess: boolean
    // Active product subscription of the LicenceHolder. null -> not loaded yet. Subject.value = null -> no subscription
    observableSubscription: BehaviorSubject<CoachSubscription>

    configs: CycleConfig[]
    planConfigs: NutritionPlanConfig[]

    isRecipeSharingEnabled() {
        return this.coach?.recipeSharingEnabled ?? false
    }
    isTrainingEnabled() {
        return this.coach?.trainingEnabled ?? false
    }
    isRecipeSuggestionsEnabled() {
        return this.coach?.recipeSuggestionsEnabled ?? false
    }
    isCoacheeTrainingPlanCreationEnabled() {
        return this.coach?.coacheeTrainingPlanCreationEnabled ?? false
    }
    isNutritionEnabled() {
        return this.coach?.nutritionEnabled ?? true
    }

    getLid() {
        return this.licenceId || this.lid
    }
    getCoachingEndDate() {
        if (this.productPurchaseEndDate) return this.productPurchaseEndDate
        return this.licence?.expirationDate
    }

    getExpiringNutritionPlanDate() {
        return this.metadata?.nutritionPlanEndDate || this.metadataUser?.latestNutritionPlanEndDate || null
    }
    hasExpiringNutritionPlan() {
        return this.getExpiringNutritionPlanDate()?.isSameOrBeforeDate(new Date().addDays(2))
    }
    getExpiringTrainingPlanDate() {
        return this.metadata?.trainingPlanEndDate || null
    }
    hasActiveTrainingPlan() {
        return this.getExpiringTrainingPlanDate()?.getTime() > new Date().getTime()
    }
    hasExpiringTrainingPlan() {
        return this.getExpiringTrainingPlanDate()?.isSameOrBeforeDate(new Date().addDays(2))
    }

    constructor();
    constructor(init: User);
    constructor(init?: User) {
        this.uid = init && init.uid || undefined
        this.name = init && init.name || undefined
        this.lastName = init && init.lastName || undefined
        this.lid = init && init.lid || undefined
        this.fcmToken = init && init.fcmToken || null
        this.gender = init && init.gender || GENDER_MALE
        this.physicalActivityLevel = init && init.physicalActivityLevel || PHYSICAL_ACTIVITY_LEVEL_INACTIVE
        this.appLocale = init && init.appLocale || 'de'
        this.meals = init && init.meals || []
        this.activities = init && init.activities || []
        this.bodyData = init && init.bodyData || []
        this.dailyConditions = init && init.dailyConditions || []
        this.statistics = init && init.statistics || []
        this.nutritionalGoal = init && init.nutritionalGoal ||  new NutritionalGoal();
        this.nutritionalSummary = init && init.nutritionalSummary || new NutritionalSummary();
        this.nutritionalRequirement = []
        this.usesNutritionalRequirement = init && init.usesNutritionalRequirement || false
        this.basicCaloriesRequirement = init && init.basicCaloriesRequirement || 0
        this.basicCarbohydratesRequirement = init && init.basicCarbohydratesRequirement || 0
        this.basicProteinRequirement = init && init.basicProteinRequirement || 0
        this.basicFatRequirement = init && init.basicFatRequirement || 0
        this.adjustNutritionalGoalWithActivities = init && init.adjustNutritionalGoalWithActivities || true;
        this.autoCalculateCalorieRequirement = init && init.autoCalculateCalorieRequirement || true;
        this.age = init && init.age || 0
        this.birthDate = init && init.birthDate ? new Date((init as any).birthDate.seconds * 1000) : null
        this.bodyHeight = init && init.bodyHeight || 0
        this.bodyWeight = init && init.bodyWeight || 0
        this.latestBodyWeight = init && init.latestBodyWeight || null
        this.firstBodyWeight = init && init.firstBodyWeight || null
        this.isCoach = init && init.isCoach || false;
        this.isLicenceHolder = init && init.isLicenceHolder || false;
        this.isUser = init && init.isUser || false;
        this.operatingSystem = init && init.operatingSystem || undefined
        this.versionCode = init && init.versionCode || 0
        this.portalSettingsLicenceHolder = init && init.portalSettingsLicenceHolder || new PortalSettingsLicenceHolder()
        this.remoteControlEnabled = init && init.remoteControlEnabled || false
        this.licenceHolderUid = init && init.licenceHolderUid || null
        this.connectedLicenceHolderUid = init && init.connectedLicenceHolderUid || null
        this.disabled = init && init.disabled || false
        this.nutritionPlans = init && init.nutritionPlans || []
        this.trainingPlans = init && init.trainingPlans || []
        this.trackedTrainingSessions = init && init.trackedTrainingSessions || null
        this.notes = init && init.notes || null
        this.autoRecipeSharingEnabled = init && init.autoRecipeSharingEnabled || false
        this.assignedMetrics = init && init.assignedMetrics || []
        this.hasRecipeDatabaseAccess = init && init.hasRecipeDatabaseAccess || false
        this.observableSubscription = init && init.observableSubscription || null
        this.licenceHolder = init && init.licenceHolder || null
        this.coach = init && init.coach || null
        this.connectedCoach = init && init.connectedCoach || null
        this.coachUid = init && init.coachUid || null
        this.licenceId = init && init.licenceId || null
        this.statisticsUpdated = new BehaviorSubject(undefined)
        this.metadata = init && init.metadata ? new Metadata(init.metadata) : null
        this.hideNutritionValues = init && init.hideNutritionValues || false
        this.hideChat = init && init.hideChat || false
        this.assignedQuestionaires = init && init.assignedQuestionaires || []
        this.stripeCustomerIds = init && init.stripeCustomerIds || null
        this.questionaireResults = init && init.questionaireResults || []
        this.recipeSuggestionsEnabled = init && init.recipeSuggestionsEnabled || false
        this.nutritionEnabled = init && init.nutritionEnabled || null
        this.coacheeTrainingPlanCreationEnabled = init && init.coacheeTrainingPlanCreationEnabled || false
        this.coacheeNutritionalGoalEditingEnabled = init && init.coacheeNutritionalGoalEditingEnabled || false
        this.coacheeVoiceMessageEnabled = init && init.coacheeVoiceMessageEnabled || false
        this.profileImagePath = init && init.profileImagePath !== undefined ? init.profileImagePath : undefined
        this.bodyWeightDelta = init && init.bodyWeightDelta != null ? init.bodyWeightDelta : null
        this.trainingSettingsLicenceHolder = init && init.trainingSettingsLicenceHolder || new TrainingSettingsLicenceHolder()
        this.spikeUserId = init && init.spikeUserId || null
        this.spikeProvider = init && init.spikeProvider || null
        this.nextCompetitionDate = init && init.nextCompetitionDate ? new Date((init as any).nextCompetitionDate.seconds * 1000) : null
        this.weeklyQuestionaireId = init && init.weeklyQuestionaireId || null
        this.dailyQuestionaireId = init && init.dailyQuestionaireId || null
        this.trainingFeedbackQuestionaireId = init && init.trainingFeedbackQuestionaireId || null
        this.nextWeeklyQuestionaireDate = init && init.nextWeeklyQuestionaireDate ? new Date((init as any).nextWeeklyQuestionaireDate.seconds * 1000) : null
        this.defaultExertionParameter = init && init.defaultExertionParameter || null
    }

    clone(): User{
        let clonedUser = new User(this);
        clonedUser.birthDate = new Date(this.birthDate);
        return clonedUser;
    }

    getName() {
        if (this.metadataUser && this.metadataUser.userName) return this.metadataUser.userName
        if (this.lastName?.length > 0) return this.name + ' ' + this.lastName
        return this.name
    }

    getStripeCustomerId() {
        return this.licenceHolder?.stripeCustomerId || null
    }
    getStripeAccountId() {
        return this.licenceHolder?.stripeAccountId || null
    }
    
    getStripeCustomerIdOfClient(stripeAccountId: string) {
        if (this.stripeCustomerIds) {
            return this.stripeCustomerIds[stripeAccountId] || null
        }
        return null
    }

    hasPortalAccess() {
        if (environment.isWhitelabel && !this.disabled) return true
        if ((this.observableSubscription == null || this.observableSubscription.value != null || this.licenceHolder?.overwriteHasPortalAccess || this.licenceHolder?.onTrial) && !this.disabled) return true
        return false
    }

    adjustNutritionalGoalWithActivitiesIfEnabled() {
        if (this.activities.length == 0 || this.nutritionalGoal?.calories == 0) return
        if (this.nutritionalGoal?.adjustWithActivityCalories != null) {
            if (!this.nutritionalGoal.adjustWithActivityCalories) return
        } else {
            if (!this.adjustNutritionalGoalWithActivities) return
        } 
        var caloriesBurned = 0
        this.activities.forEach(activity => {
            caloriesBurned = caloriesBurned + activity.caloriesBurned
        })
        this.nutritionalGoal.carbohydrates += this.nutritionalGoal.carbohydrates / this.nutritionalGoal.calories * caloriesBurned
        this.nutritionalGoal.protein += this.nutritionalGoal.protein / this.nutritionalGoal.calories * caloriesBurned
        this.nutritionalGoal.fat += this.nutritionalGoal.fat / this.nutritionalGoal.calories * caloriesBurned
        this.nutritionalGoal.calories += caloriesBurned
        this.nutritionalGoal.carbohydrates = Math.round(this.nutritionalGoal.carbohydrates)
        this.nutritionalGoal.protein = Math.round(this.nutritionalGoal.protein)
        this.nutritionalGoal.fat = Math.round(this.nutritionalGoal.fat)
        this.nutritionalGoal.calories = Math.round(this.nutritionalGoal.calories)
    }

    getCalculatedCalorieRequirement(): number {
        var physicalActivityLevelFactor = 1.2;
        if (this.physicalActivityLevel == PHYSICAL_ACTIVITY_LEVEL_INACTIVE) {
            physicalActivityLevelFactor = 1.4;
        } else if (this.physicalActivityLevel == PHYSICAL_ACTIVITY_LEVEL_LITTLE_ACTIVE) {
            physicalActivityLevelFactor = 1.6;
        } else if (this.physicalActivityLevel == PHYSICAL_ACTIVITY_LEVEL_ACTIVE) {
            physicalActivityLevelFactor = 1.8;
        } else if (this.physicalActivityLevel == PHYSICAL_ACTIVITY_LEVEL_VERY_ACTIVE) {
            physicalActivityLevelFactor = 2.0;
        }
        var genderConstant = this.gender == GENDER_FEMALE || this.gender == GENDER_FEMALE_ALT ? -161 : 5;
        var basicCalorieNeed = 10 * (this.latestBodyWeight || this.bodyWeight) + 6.25 * this.bodyHeight - 5 * this.age + genderConstant;
        var activityFactor = ((16 * physicalActivityLevelFactor) + 7.6) / 24;
        return (basicCalorieNeed * activityFactor);
    }

    getBurnedCalories() {
        if (!this.activities) return 0
        var calories = 0
        this.activities.forEach(activity => {
            calories += activity.caloriesBurned
        })
        return calories
    }

    getBurnedCaloriesForCalendarWeek() {
        if (!this.activitiesOfCurrentCalendarWeek) return null
        var duration = 0
        var calories = 0
        this.activitiesOfCurrentCalendarWeek.forEach(a => {
            if (a.activityFactId != 'acf71') duration += a.duration
            calories += a.caloriesBurned
        })
        return calories
    }

    getCycleConfigForDate(date: Date): CycleConfig {
        var result: CycleConfig
        this.configs?.forEach(config => {
            if (date >= config.startDate && (config.endDate == null || date < config.endDate) || date?.isSameDate(config.startDate) || date?.isSameDate(config.endDate)) {
                result = config
            }
        })
        return result
    }

    getCalorieGoal(date: Date) {
        if (this.dailyCondition?.hasTargetValuesSet()) return this.dailyCondition.getTargetCalories()
        var goal = this.getCycleConfigForDate(date)?.getNutritionalGoalForDateAndSituation(date, this.dailyCondition?.selectedSituationId ?? null)
        if (goal) {
            if (goal.adjustWithActivityCalories && this.activities?.length > 0) {
                var caloriesBurned = 0
                this.activities.forEach(activity => {
                    caloriesBurned = caloriesBurned + activity.caloriesBurned
                })
                goal = goal.getAdjustedWithCalories(caloriesBurned)
            }
            return goal.getCalories()
        }
        return this.nutritionalGoal?.calories || this.basicCaloriesRequirement
    }
    getCarbohydratesGoal(date: Date) {
        if (this.dailyCondition?.hasTargetValuesSet()) return this.dailyCondition.getTargetCarbohydrates()
        var goal = this.getCycleConfigForDate(date)?.getNutritionalGoalForDateAndSituation(date, this.dailyCondition?.selectedSituationId ?? null)
        if (goal) {
            if (goal.adjustWithActivityCalories && this.activities?.length > 0) {
                var caloriesBurned = 0
                this.activities.forEach(activity => {
                    caloriesBurned = caloriesBurned + activity.caloriesBurned
                })
                goal = goal.getAdjustedWithCalories(caloriesBurned)
            }
            return goal.getCarbohydrates()
        }
        return this.nutritionalGoal?.carbohydrates || this.basicCarbohydratesRequirement
    }
    getProteinGoal(date: Date) {
        if (this.dailyCondition?.hasTargetValuesSet()) return this.dailyCondition.getTargetProtein()
        var goal = this.getCycleConfigForDate(date)?.getNutritionalGoalForDateAndSituation(date, this.dailyCondition?.selectedSituationId ?? null)
        if (goal) {
            if (goal.adjustWithActivityCalories && this.activities?.length > 0) {
                var caloriesBurned = 0
                this.activities.forEach(activity => {
                    caloriesBurned = caloriesBurned + activity.caloriesBurned
                })
                goal = goal.getAdjustedWithCalories(caloriesBurned)
            }
            return goal.getProtein()
        }
        return this.nutritionalGoal?.protein || this.basicProteinRequirement
    }
    getFatGoal(date: Date) {
        if (this.dailyCondition?.hasTargetValuesSet()) return this.dailyCondition.getTargetFat()
        var goal = this.getCycleConfigForDate(date)?.getNutritionalGoalForDateAndSituation(date, this.dailyCondition?.selectedSituationId ?? null)
        if (goal) {
            if (goal.adjustWithActivityCalories && this.activities?.length > 0) {
                var caloriesBurned = 0
                this.activities.forEach(activity => {
                    caloriesBurned = caloriesBurned + activity.caloriesBurned
                })
                goal = goal.getAdjustedWithCalories(caloriesBurned)
            }
            return goal.getFat()
        }
        return this.nutritionalGoal?.fat || this.basicFatRequirement
    }

    getCommonTargetValue(key: string, date: Date) {
        var config = this.getCycleConfigForDate(date)
        if (config) {
            return config?.commonTargetValues[key]
        }
        return null
    }

    getSelectedSituationName(date: Date) {
        if (!this.dailyCondition || this.dailyCondition?.selectedSituationId == null || this.dailyCondition?.selectedSituationId == 'DEFAULT') return null
        var goal = this.getCycleConfigForDate(date)?.getNutritionalGoalForDateAndSituation(date, this.dailyCondition?.selectedSituationId ?? null)
        return goal.situation.getName()
    }

    getPrintableGender(): string {
        return (this.gender == GENDER_FEMALE || this.gender == GENDER_FEMALE_ALT ? "Weiblich" : this.gender == GENDER_MALE ? "Männlich" : "Divers")
    }

    setGender(value: string) {
        this.gender = value;
    }

    getPrintablePhysicalActivityLevel(): string {
        switch (this.physicalActivityLevel) {
            case PHYSICAL_ACTIVITY_LEVEL_VERY_ACTIVE:
                return "Sehr aktiv";
            case PHYSICAL_ACTIVITY_LEVEL_ACTIVE:
                return "Aktiv";
            case PHYSICAL_ACTIVITY_LEVEL_LITTLE_ACTIVE:
                return "Etwas aktiv";
            case PHYSICAL_ACTIVITY_LEVEL_INACTIVE:
            default:
                return "Inaktiv";
        }
    }

    setPhysicalActivityLevel(value: string) {
        this.physicalActivityLevel = value;
    }

    getPalValue() {
        switch (this.physicalActivityLevel) {
            case PHYSICAL_ACTIVITY_LEVEL_VERY_ACTIVE:
                return PalValues[3]
            case PHYSICAL_ACTIVITY_LEVEL_ACTIVE:
                return PalValues[2]
            case PHYSICAL_ACTIVITY_LEVEL_LITTLE_ACTIVE:
                return PalValues[1]
            case PHYSICAL_ACTIVITY_LEVEL_INACTIVE:
            default:
                return PalValues[0]
        }
    }
    
    static getPalValues() {
        return PalValues
    }

    getDailyConditionForDate(date: Date) {
        if (this.dailyConditions == null || this.dailyConditions.length == 0) return null;
        var startOfToday = new Date();
        startOfToday.setTime(date.getTime());
        startOfToday.setHours(0,0,0,0);
        var endOfToday = new Date();
        endOfToday.setTime(startOfToday.getTime() + (1000*60*60*24) - 1);
        for (const d of this.dailyConditions) {
            if (d.date.getTime() >= startOfToday.getTime() && d.date.getTime() <= endOfToday.getTime()) return d
        }
        return null
    }

    getNutritionStatisticsItemForDate(date: Date) {
        if (this.statistics == null || this.statistics.length == 0) return null;
        var startOfToday = new Date();
        startOfToday.setTime(date.getTime());
        startOfToday.setHours(0,0,0,0);
        var endOfToday = new Date();
        endOfToday.setTime(startOfToday.getTime() + (1000*60*60*24) - 1);
        for (const item of this.statistics) {
            if (item.date.getTime() >= startOfToday.getTime() && item.date.getTime() <= endOfToday.getTime()) return item
        }
        return null
    }
    getBodyDataItemForDate(date: Date) {
        if (this.bodyData == null || this.bodyData.length == 0) return null;
        var startOfToday = new Date();
        startOfToday.setTime(date.getTime());
        startOfToday.setHours(0,0,0,0);
        var endOfToday = new Date();
        endOfToday.setTime(startOfToday.getTime() + (1000*60*60*24) - 1);
        for (const item of this.bodyData) {
            if (item.date.getTime() >= startOfToday.getTime() && item.date.getTime() <= endOfToday.getTime()) return item
        }
        return null
    }

    getFirstBodyDataDate(): Date{
        // var date = this.bodyData.sort(x => x.date.getDate()).map(x => x.date)[0]
        if(this.bodyData?.length > 0){
            var date = this.bodyData.map(x => x.date).sort((a, b) => a.getTime()- b.getTime())[0]
            return date
        }
            
    }

    getFirstDailyConditionDate(): Date{
        if(this.dailyConditions?.length > 0){
            var date = this.dailyConditions.map(x => x.date).sort((a, b) => a.getTime()- b.getTime())[0]
            return date
        }
    }

    isFemale(): boolean {
        return this.gender == GENDER_FEMALE || this.gender == GENDER_FEMALE_ALT
    }

    isAndroidUser(): boolean {
        return this.operatingSystem == 'Android'
    }

    getNutritionalValue(nutritionalValue: string): number {
        var value = 0
        this.meals.forEach(m => {
            value += m.getNutritionalValue(nutritionalValue) || 0
        })
        return value
    }
    getCarbohydratesSummaryForMeals() {
        var value = 0
        this.meals.forEach(meal => {
            value = value + meal.getCarbohydrates();
        })
        return value
    }
    getProteinSummaryForMeals() {
        var value = 0
        this.meals.forEach(meal => {
            value = value + meal.getProtein();
        })
        return value
    }
    getFatSummaryForMeals() {
        var value = 0
        this.meals.forEach(meal => {
            value = value + meal.getFat();
        })
        return value
    }
    getCalorieSummaryForMeals() {
        var value = 0
        this.meals.forEach(meal => {
            value = value + meal.getCalories();
        })
        return value
    }
    getSugarSummaryForMeals() {
        var value = 0
        this.meals.forEach(meal => {
            value = value + meal.getSugar();
        })
        return value
    }
    getSaturatedFatSummaryForMeals() {
        var value = 0
        this.meals.forEach(meal => {
            value = value + meal.getSaturatedFat();
        })
        return value
    }
    getFibreSummaryForMeals() {
        var value = 0
        this.meals.forEach(meal => {
            value = value + meal.getFibre();
        })
        return value
    }
    getSaltSummaryForMeals() {
        var value = 0
        this.meals.forEach(meal => {
            value = value + meal.getSalt();
        })
        return value
    }

    getNutritionalGoalBackup() {
        return new NutritionalGoal({calories: this.basicCaloriesRequirement, carbohydrates: this.basicCarbohydratesRequirement, protein: this.basicProteinRequirement, fat: this.basicFatRequirement} as NutritionalGoal)
    }

    getPlannedSessionById(trainingPlanId: string, plannedSessionId: string): TrainingSession {
        var plan = this.trainingPlans.filter(plan => plan.id == trainingPlanId)
        if (plan.length > 0) {
            var session = plan[0].sessions.filter(session => session.id == plannedSessionId)
            if (session.length > 0) return session[0]
        }
        return null
    }

    getAgeByBirthDate(){
        if(this.birthDate == null){
            return null;
        }
        var today = new Date();
        var age = today.getFullYear() - this.birthDate.getFullYear();
        var m = today.getMonth() - this.birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < this.birthDate.getDate())) {
            age--;
        }
        return age;
    }

    static calculateCalorieNeedFromHarrisBenedict(weight: number, height: number, age: number, gender: string): number {
        if (gender == GENDER_FEMALE) {
            return 655.1 + (9.6 * weight) + (1.85 * height) - (4.68 * age)
        } else {
            return 66.47 + (13.75 * weight) + (5 * height) - (6.76 * age)
        }
    }
    static calculateCalorieNeedFromHarrisBenedictWithPAL(weight: number, height: number, age: number, gender: string, physicalActivityLevel: string): number {
        var physicalActivityLevelFactor = 1.2;
        if (physicalActivityLevel == PHYSICAL_ACTIVITY_LEVEL_INACTIVE) {
            physicalActivityLevelFactor = 1.4;
        } else if (physicalActivityLevel == PHYSICAL_ACTIVITY_LEVEL_LITTLE_ACTIVE) {
            physicalActivityLevelFactor = 1.6;
        } else if (physicalActivityLevel == PHYSICAL_ACTIVITY_LEVEL_ACTIVE) {
            physicalActivityLevelFactor = 1.8;
        } else if (physicalActivityLevel == PHYSICAL_ACTIVITY_LEVEL_VERY_ACTIVE) {
            physicalActivityLevelFactor = 2.0;
        }
        var basicCalorieNeed = 0
        if (gender == GENDER_FEMALE) {
            basicCalorieNeed = 655.1 + (9.6 * weight) + (1.85 * height) - (4.68 * age)
        } else {
            basicCalorieNeed = 66.47 + (13.75 * weight) + (5 * height) - (6.76 * age)
        }
        var activityFactor = ((8 * physicalActivityLevelFactor) + 8 * 1.2 + 8) / 24;
        return (basicCalorieNeed * activityFactor);
    }
    static calculateCalorieNeedFromMifflin(weight: number, height: number, age: number, gender: string): number {
        if (gender == GENDER_FEMALE) {
            return 10 * weight + 6.25 * height - 5 * age - 161
        } else {
            return 10 * weight + 6.25 * height - 5 * age + 5
        }
    }
    static calculateCalorieNeedFromMifflinWithPAL(weight: number, height: number, age: number, gender: string, physicalActivityLevel: string): number {
        var physicalActivityLevelFactor = 1.2;
        if (physicalActivityLevel == PHYSICAL_ACTIVITY_LEVEL_INACTIVE) {
            physicalActivityLevelFactor = 1.4;
        } else if (physicalActivityLevel == PHYSICAL_ACTIVITY_LEVEL_LITTLE_ACTIVE) {
            physicalActivityLevelFactor = 1.6;
        } else if (physicalActivityLevel == PHYSICAL_ACTIVITY_LEVEL_ACTIVE) {
            physicalActivityLevelFactor = 1.8;
        } else if (physicalActivityLevel == PHYSICAL_ACTIVITY_LEVEL_VERY_ACTIVE) {
            physicalActivityLevelFactor = 2.0;
        }
        var genderConstant = gender == GENDER_FEMALE || gender == GENDER_FEMALE_ALT ? -161 : 5;
        var basicCalorieNeed = 10 * weight + 6.25 * height - 5 * age + genderConstant;
        var activityFactor = ((8 * physicalActivityLevelFactor) + 8 * 1.2 + 8) / 24;
        return (basicCalorieNeed * activityFactor);
    }
}
