<div class="row">
    <div class="col-12">        
        <div *ngIf="user?.operatingSystem == 'Android' && user?.versionCode >= 73 || user?.operatingSystem == 'iOS' && user?.versionCode >= 87">
            <div class="bold display-inlineblock">Geplante Nährwertziele:</div>
            <div *ngIf="mode == 'common'" class="icon-button marginleft-25" tooltip="Setze Nährwertziele für alle oder spezifische Wochentage, die in der App automatisch angewandt werden.">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                </svg>
            </div>
            <br><br>
            <ng-container *ngFor="let index of createRange(nutritionalGoals?.length)">
                <div class="nutritionalgoal-wrapper {{index == 0 ? 'first' : ''}}">
                    <input class="input-nutrition light small textalign-right {{nutritionalGoals[index].getCarbohydrates() > 0 ? '' : 'invalid'}}" [ngModel]="nutritionalGoals[index].getCarbohydrates()" (input)="onNutritionalGoalValueChanged(index, 'C', $event.target.value)"> g K
                    <input class="input-nutrition light small textalign-right marginleft-25 {{nutritionalGoals[index].getProtein() > 0 ? '' : 'invalid'}}" [ngModel]="nutritionalGoals[index].getProtein()" (input)="onNutritionalGoalValueChanged(index, 'P', $event.target.value)"> g E
                    <input class="input-nutrition light small textalign-right marginleft-25 {{nutritionalGoals[index].getFat() > 0 ? '' : 'invalid'}}" [ngModel]="nutritionalGoals[index].getFat()" (input)="onNutritionalGoalValueChanged(index, 'F', $event.target.value)"> g F
                    <div class="calorie-result display-inlineblock marginright-25">= {{nutritionalGoals[index].getCalories()}} kcal</div>
                    
                    <div class="calculate-macros display-inlineblock">
                        <div class="icon-button float-right" (click)="onOpenMacroCalculator(nutritionalGoals[index])">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calculator" viewBox="0 0 16 16">
                                <path d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z"/>
                                <path d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z"/>
                            </svg>
                            Rechner öffnen
                        </div>
                    </div>
                    <div class="display-inlineblock padding-10"><input class="input checkbox marginleft-50 mobile-marginleft-10" type="checkbox" [checked]="nutritionalGoals[index]?.adjustWithActivityCalories != false" (change)="onNutritionalGoalFromCoachIncludeActivitiesChanged(index, $event.target.checked)"> Aktivitäten dazurechnen 
                        <div class="icon-button marginleft-10" tooltip="Setze diesen Haken, wenn die konfigurierten Kalorien- und Nährwertziele durch verbrannte Aktivitätskalorien automatisch erhöht werden sollen.">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                            </svg>
                        </div>
                    </div>

                    <div *ngIf="nutritionalGoals.length > 1" class="weekdayselection">
                        <div class="chip {{nutritionalGoals[index].applicableWeekdays.includes('all') || nutritionalGoals[index].applicableWeekdays.includes('mon') ? 'selected' : 'link'}}" (click)="onNutritionalGoalWeekdayChanged(index, 'mon')">Mo</div>
                        <div class="chip {{nutritionalGoals[index].applicableWeekdays.includes('all') || nutritionalGoals[index].applicableWeekdays.includes('tue') ? 'selected' : 'link'}}" (click)="onNutritionalGoalWeekdayChanged(index, 'tue')">Di</div>
                        <div class="chip {{nutritionalGoals[index].applicableWeekdays.includes('all') || nutritionalGoals[index].applicableWeekdays.includes('wed') ? 'selected' : 'link'}}" (click)="onNutritionalGoalWeekdayChanged(index, 'wed')">Mi</div>
                        <div class="chip {{nutritionalGoals[index].applicableWeekdays.includes('all') || nutritionalGoals[index].applicableWeekdays.includes('thu') ? 'selected' : 'link'}}" (click)="onNutritionalGoalWeekdayChanged(index, 'thu')">Do</div>
                        <div class="chip {{nutritionalGoals[index].applicableWeekdays.includes('all') || nutritionalGoals[index].applicableWeekdays.includes('fri') ? 'selected' : 'link'}}" (click)="onNutritionalGoalWeekdayChanged(index, 'fri')">Fr</div>
                        <div class="chip {{nutritionalGoals[index].applicableWeekdays.includes('all') || nutritionalGoals[index].applicableWeekdays.includes('sat') ? 'selected' : 'link'}}" (click)="onNutritionalGoalWeekdayChanged(index, 'sat')">Sa</div>
                        <div class="chip {{nutritionalGoals[index].applicableWeekdays.includes('all') || nutritionalGoals[index].applicableWeekdays.includes('sun') ? 'selected' : 'link'}}" (click)="onNutritionalGoalWeekdayChanged(index, 'sun')">So</div>
                    </div>

                    <div class="display-inlineblock" *ngIf="nutritionalGoals[index]?.adjustWithActivityCalories == true">
                        <div class="spacer-10"></div>
                        <div class="layout-box sub-box">
                            <div class="row">
                                <div class="col-8">
                                    <div class="bold">Einstellungen für Aktivitätskalorien:</div>
                                    Verteilung<br>
                                    <div class="label">Nährstoffverteilung für zusätzlich verbrannte Kalorien anpassen.</div>
                                    <div class="spacer-10"></div>
                                    <app-macro-slider [classes]="'white-fields'" [initialDistribution]="nutritionalGoals[index]?.activityCaloriesDistribution ?? [34,33,33]" (onDistributionChanged)="onAdditionalCaloriesDistributionChanged(nutritionalGoals[index], $event)"></app-macro-slider>
                                </div>
                                <div class="col-4">
                                    <div class="spacer-25"></div>
                                    Multiplikator<br>
                                    <div class="label">Aktivitätskalorien anteilig verrechnen.</div>
                                    <div class="spacer-10"></div>
                                    <input class="input-nutrition input-multiplier small textalign-right {{nutritionalGoals[index]?.activityCaloriesMultiplier == null ? 'invalid' : ''}}" [ngModel]="nutritionalGoals[index]?.activityCaloriesMultiplier" (input)="onNutritionalGoalActivityCaloriesMultiplierChanged(nutritionalGoals[index], $event.target.value)"> x
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="index > 0" class="icon-button marginleft-10 button-deletegoal margintop-10" (click)="onDeleteNutritionalGoal(index)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                        </svg>
                        Ziel löschen
                    </div>
                </div>
            </ng-container>
            <div *ngIf="nutritionalGoals?.length < 7" class="icon-button" (click)="onAddNewNutritionalGoal()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>
                Weiteres Ziel hinzufügen
            </div>
        </div>

    </div>
</div>