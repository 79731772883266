import { FcmService } from './../services/fcm.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { Component, NgZone, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { BroadcastChat, Chat } from '../model/chat.model';
import { ChatService } from '../services/chat.service';
import { FirestoreService } from '../services/firestore.service';
import { UtilityService } from '../services/utility.service';
import { ChatOverviewBase } from '../chat/chat-overview-base';

@Component({
  selector: 'app-mobile-app-chat',
  templateUrl: './mobile-app-chat.component.html',
  styleUrls: ['./mobile-app-chat.component.css']
})
export class MobileAppChatComponent extends ChatOverviewBase implements OnInit {

  public message = "";
  public clientSearchInput: string
  
  constructor(public userService: FirestoreService, public authService: AuthService, public utilityService: UtilityService, public chatService: ChatService, private navService: NavbarService, private fcmService: FcmService, ngZone: NgZone) {
    super(userService, ngZone);
  }

  ngOnInit(): void {
    this.fcmService.RemoveAllDeliveredNotifications();
    this.init();
  }

  dateFormatter(date:Date):string{
    if(this.isToday(date)){
      return date.asTimeFormatedString()
    }
    else{
      return date.asFormatedString()
    }
  }

  isToday = (someDate) => {
    const today = new Date()
    return someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
  }

  onClientSearchInputChanged(text: string) {
    this.clientSearchInput = text
    
  }
  onDeleteClientSearchInput() {
    (<HTMLInputElement> document.getElementById('clientsearch-input')).value = ''
    this.clientSearchInput = null
  }

  
  openBraodcastChat() {
    let chat = new BroadcastChat();
    this.chatService.openChat(chat);
  }

}
