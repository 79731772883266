import { Injectable } from '@angular/core';
import { Questionaire, Question, AssignedQuestionaire, TriggerType } from '../model/questionaires.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { AngularFirestore, DocumentData } from '@angular/fire/compat/firestore';
import { FirestoreService } from './firestore.service';
// import { firestore } from 'firebase';
import { User } from '../model/user.model';
import { ToastrService } from 'ngx-toastr';
import { CommonFirebase, IndividualFirebase } from '../app.module';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Metric } from '../model/metric.model';

@Injectable({
  providedIn: 'root'
})
export class QuestionairesService {
  
  get firestore(): AngularFirestore {
    return this.mainFirebase.firestore
  }
  get fireStorage(): AngularFireStorage {
    return this.mainFirebase.storage
  }
  
  constructor(public userService: FirestoreService, private mainFirebase: IndividualFirebase, private commonFirebase: CommonFirebase, private spinner: NgxSpinnerService, private tostr: ToastrService) {}

  public questionaires: Questionaire[] = []

  public async getQuestionaires(){
    if (!(this.questionaires?.length > 0)) await this.loadQuestionaires()
    return this.questionaires
  }
  
  async loadQuestionaires() {
    //this.spinner.show();
    this.questionaires = [];
    try {
      const documents = await this.firestore
        .collection('Questionaires')
        .ref
        .where('licenceHolderUid', '==', this.userService.getLoggedInUser().licenceHolderUid)
        .get();
      const questionaires: Questionaire[] = [];
      for (const document of documents.docs) {
        const questionaire = new Questionaire(document.data() as Questionaire);
        questionaire.id = document.id;
        if (questionaire.deleted) continue;
        let nextExecutionDate = (document.data() as any)?.nextExecutionDate;
        questionaire.nextExecutionDate = nextExecutionDate ? new Date(nextExecutionDate.seconds * 1000) : null;
        questionaire.timestamp = new Date((document.data() as any)?.timestamp?.seconds * 1000)
  
        const questions = ((document.data() as any)?.questions as any[])?.map(x => new Question(x.metricId, x.position, this.userService.getMetricByMetricId(x.metricId), x.groupHeading, x.content, x.imagePath, x.required)) || [];
        for (var question of questions) {
          if (question.metricId && !question.metric) {
            question.metric = await this.userService.fetchMetricByMetricId(question.metricId);
          }
        }
        questionaire.questions = questions.sort((a, b) => a.position - b.position);
        questionaires.push(questionaire);
      }
      this.questionaires = questionaires;
    } catch (error) {
      console.error('Error loading Questionaires', error);
    } finally {
      //this.spinner.hide();
    }
  }

  
  
  async deleteQuestionaire(questionaire: Questionaire) {
    try {
      this.spinner.show();
      const result = await this.firestore
      .collection('Questionaires')
      .doc(questionaire.id).update({deleted: true, timestamp: new Date()});
    } catch (error) {
      console.log(error);
    } finally {
      this.spinner.hide();
      await this.loadQuestionaires();
    }
  }

  async updateQuestionaire(questionaire: Questionaire, updateExecutionDate: boolean = false) {
    try {
      this.spinner.show();
      const result = await this.firestore
      .collection('Questionaires')
      .doc(questionaire.id)
      .update(this.convertQuestionaireToFirebaseData(questionaire, updateExecutionDate));
      
      await this.uploadQuestionImages(questionaire);
    } catch (error) {
      console.log(error);
    }
    finally {
      this.spinner.hide();
      await this.loadQuestionaires();
    }
  }

  async updateAssignedQuestionaireInMetrics(questionaire: Questionaire) {
    var userIds = await this.userService.getUserIdsWithAssignedDailyCheckId(questionaire.id)
    for (let userUid of userIds) {
      var user = this.userService.getClient(userUid)
      if (!user) continue
      var assignedMetrics = questionaire.questions.map(x => {
        var metric = x.metric?.clone() ?? new Metric()
        metric.groupHeading = x.groupHeading
        metric.content = x.content
        metric.imagePath = x.imagePath
        metric.required = x.required
        return metric
      })
      assignedMetrics[0].questionaireId = questionaire.id
      var date = new Date()
      assignedMetrics.forEach(metric => {
        metric.applied = false
        metric.assignmentDate = date
      });
      user.assignedMetrics = assignedMetrics
      await this.userService.insertAssignedMetrics(user, assignedMetrics)
    }
  }

  convertQuestionaireToFirebaseData(questionaire: Questionaire, includeExecutionDate: boolean = true) {
    questionaire.questions.forEach( (item, index) => {
      item.position = index;
    });
    var data = {
      licenceHolderUid: questionaire.licenceHolderUid,
      internalName: questionaire.internalName,
      name: questionaire.name,
      assignedUids: questionaire.assignedUids,
      assignedGroupNames: questionaire.assignedGroupNames,
      trigger: (TriggerType[questionaire.trigger] != TriggerType.manually) ? questionaire.trigger : null,
      type: questionaire.type,
      eventTrigger: questionaire.eventTrigger,
      repetition: questionaire.repetition,
      repetitionMultiplier: questionaire.repetitionMultiplier,
      questions: questionaire.questions.map(x => x.asMap()),
      timestamp: new Date()
    }
    if (includeExecutionDate) {
      data['nextExecutionDate'] = questionaire.nextExecutionDate
    }
    return data
  }

  async uploadQuestionImages(questionaire: Questionaire){
    let relevantQuestions = questionaire.questions.filter(x => x.updatedImage);
    if(relevantQuestions.length > 0){
      for (let index = 0; index < relevantQuestions.length; index++) {
        const question = relevantQuestions[index];
        const file = question.updatedImage;
        let fileName = (new Date()).asEncodedString() + file.getExtension()
        const filePath = `questionaires/${questionaire.id}/${fileName}`;
        // const fileRef = this.storage.ref(filePath);
        await this.fireStorage.upload(filePath, file);
        question.imagePath = filePath;
        question.updatedImage = null;
      }
      await this.firestore.collection('Questionaires').doc(questionaire.id).update({
        questions: questionaire.questions.map(x => x.asMap()),
      });
    }
  }

  // async deleteQuestionImages(questionaire: Questionaire){
  //   let relevantQuestions = questionaire.questions.filter(x => x.imagePath);
  //   if(relevantQuestions.length > 0){
  //     for (let index = 0; index < relevantQuestions.length; index++) {
  //       const question = relevantQuestions[index];
  //       this.fireStorage.ref(question.imagePath).delete();
  //       question.imagePath = null;
  //       question.updatedImage = null;
  //     }
  //   }
  // }

  async createQuestionaire(questionaire: Questionaire) {
    try {
      this.spinner.show();
      const result = await this.firestore
      .collection('Questionaires')
      .add(this.convertQuestionaireToFirebaseData(questionaire, true));
      
      await this.uploadQuestionImages(questionaire);
    } catch (error) {
      console.log(error);
    } finally {
      this.spinner.hide();
      await this.loadQuestionaires();
    }
  }
  
  async loadAssignedQuestionaires(){
    let clients = this.userService.getAccessibleClients();
    for (let index = 0; index < clients.length; index++) {
      const client = clients[index];
      
    }
  }

  async sendFeedback(assignedQuestionaire: AssignedQuestionaire, user: User):Promise<boolean>{
    try {
      await this.firestore.collection('Users/' + user.uid + '/AssignedQuestionaires/').doc(assignedQuestionaire.id).update({
        feedback: assignedQuestionaire.feedback,
        feedbackRead: false,
        timestamp: new Date(),
      })
      let originalAssignedQuestionaire = user.assignedQuestionaires.find(x => x.id == assignedQuestionaire.id)
      if(originalAssignedQuestionaire){
        originalAssignedQuestionaire.feedback = assignedQuestionaire.feedback
      }
      this.userService.sendCheckInFeedback(user, assignedQuestionaire.id)
      return true;
    }
    catch(ex){
      console.log(ex)
      return false;
    }
  }

  async markAsViewed(assignedQuestionaire: AssignedQuestionaire, user: User) {
    assignedQuestionaire.viewedByCoach = true
    try {
      await this.firestore.collection('Users/' + user.uid + '/AssignedQuestionaires/').doc(assignedQuestionaire.id).update({
        viewedByCoach: true
      })
    } catch(ex){
      console.log(ex)
    }
  }

  async sendQuestionaire(questionaire: Questionaire, user?: User){
    let assignedQuestionaire = new AssignedQuestionaire();
    assignedQuestionaire.assignmentDate = new Date();
    assignedQuestionaire.eventTrigger = null;// questionaire.eventTrigger;
    assignedQuestionaire.licenceHolderUid = questionaire.licenceHolderUid;
    assignedQuestionaire.name = questionaire.name;
    assignedQuestionaire.questionaireId = questionaire.id;
    assignedQuestionaire.questions = questionaire.questions;
    assignedQuestionaire.timestamp = new Date()
    
    let assignedUsers: User[] = []
    if(user != null){
      assignedUsers.push(user);
    }
    else {
      let clients = this.userService.getAccessibleClients();
      for (let index = 0; index < clients.length; index++) {
        const client = clients[index];
        if(questionaire.assignedUids.includes(client.uid) && assignedUsers.find(x => x.uid == client.uid) == null){
          assignedUsers.push(client);
          continue;
        }
        if(questionaire.assignedGroupNames.includes('Alle') || questionaire.assignedGroupNames.includes('all') || client.metadataUser.assignedClientGroups.filter(groupName => questionaire.assignedGroupNames.includes(groupName)).length > 0){
          if(assignedUsers.find(x => x.uid == client.uid) == null){
            assignedUsers.push(client);
            continue;
          }
        }
      }
    }
    for (let index = 0; index < assignedUsers.length; index++) {
      const client = assignedUsers[index];
      try {
        var ref = await this.firestore.collection('Users')
        .doc(client.uid)
        .collection('AssignedQuestionaires')
        .add(this.convertAssignedQuestionaireToFirebaseData(assignedQuestionaire));

        this.userService.sendCheckInNotification(client, ref?.id)

        await this.userService.loadAllAssignedQuestionairesForUser(client);
        this.userService.addPendingQuestionairesToDailyConditions(client.dailyConditions, client.assignedQuestionaires);
      } catch (error) {
        console.log(error);
      } finally {
      }
      
    }
    
    this.tostr.success("Check-In wurde erfolgreich versendet.");

  }

  convertAssignedQuestionaireToFirebaseData(assignedQuestionaire: AssignedQuestionaire) {
    return {
      "questionaireId": assignedQuestionaire.id,
      "name": assignedQuestionaire.name,
      "assignmentDate": new Date(),
      "licenceHolderUid": assignedQuestionaire.licenceHolderUid,
      "completed": assignedQuestionaire.completed,
      "completionDate": assignedQuestionaire.completionDate,
      "eventTrigger": assignedQuestionaire.eventTrigger,
      "questions": assignedQuestionaire.questions.map(x => x.asMap()),
      "timestamp": assignedQuestionaire.timestamp
    }
  }
}
