<ng-container>
    <div class="tab-selection textalign-center tab-button-group adjust-mobile padding-0">
        <button class="tab-button" (click)="setSelectedTaskTimeFrame(TaskTimeFrame.past)">
            Überfällig ({{getNumberOfTasksForTimeFrame(TaskTimeFrame.past)}})
            <div *ngIf="selectedTaskTimeFrame == TaskTimeFrame.past"  class="underline"></div>
        </button>
        <button class="tab-button" (click)="setSelectedTaskTimeFrame(TaskTimeFrame.today)">
            Heute ({{getNumberOfTasksForTimeFrame(TaskTimeFrame.today)}})
            <div *ngIf="selectedTaskTimeFrame == TaskTimeFrame.today"  class="underline"></div>
        </button>
        <button class="tab-button" (click)="setSelectedTaskTimeFrame(TaskTimeFrame.future)">
            Zukünftig ({{getNumberOfTasksForTimeFrame(TaskTimeFrame.future)}})
            <div *ngIf="selectedTaskTimeFrame == TaskTimeFrame.future" class="underline"></div>
        </button>
    </div>
    <div class="spacer-10"></div>
</ng-container>
<div class="task-list" [@.disabled]="animationIsDisabled">
    <ng-container *ngFor="let taskByDate of tasksByDate">
        <div class="task-list-date" *ngIf="taskByDate.tasks.length > 0">
            <div class="task-list-date-title label">{{taskByDate.getPrintableDate()}}</div>
        </div>
        <ng-container *ngFor="let task of taskByDate.tasks">
            <div [@fadeSlideGrowKeyframe]="false" *ngIf="!task.done && task.assignedCoachUid == loggedInCoach.uid">
                <app-task-element [task]="task" [(selectedTask)]="taskManagementService.selectedTask" [selectedSubTask]="selectedSubTask" (onCompleteTask)="onCompleteTask(task)" (onUnCompleteTask)="onUnCompleteTask(task)" (onDeleteTask)="onDeleteTask(task)" (onDuplicateTask)="onDuplicateTask(task)" (onEditTask)="onEditTask(task)" (onAddSubTask)="onAddSubTask(task)" (onSaveSubTasks)="onSaveSubTasks(task)" (onSaveAndAddSubTasks)="onSaveAndAddSubTasks(task, $event)" (onRemoveSubTask)="onRemoveSubTask(task, $event)" (onCompleteSubTask)="onCompleteSubTask(task, $event)" (onUnCompleteSubTask)="onUnCompleteSubTask(task, $event)"></app-task-element>
            </div>
        </ng-container>
    </ng-container>
</div>