import { Injectable } from '@angular/core';
import { ExportingEvent } from 'devextreme/ui/data_grid';
import {Workbook, Worksheet} from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ChartExportService {

  constructor(private toastr: ToastrService) { }

  
  onExporting(e: ExportingEvent) {
    try{
      const workbook = new Workbook();    
      const worksheet = workbook.addWorksheet('Main sheet');
      exportDataGrid({
          component: e.component,
          worksheet: worksheet,
          customizeCell: function(options) {
              options.excelCell.font = { name: 'Arial', size: 12 };
              options.excelCell.alignment = { horizontal: 'left' };
          } 
      }).then(function() {
          workbook.xlsx.writeBuffer()
              .then(function(buffer: BlobPart) {
                  saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
              });
      }).catch(ex => {
        console.error(ex);
        this.toastr.error("Es ist ein unbekannter Fehler aufgetreten.", "Export nicht möglich",  {
          positionClass: 'toast-bottom-center'
        });
      });
    }
    catch(ex){
      console.error(ex);
      this.toastr.error("Es ist ein unbekannter Fehler aufgetreten.", "Export nicht möglich",  {
        positionClass: 'toast-bottom-center'
      });
    }
  }
}
