<div class="row {{expertMode ? ' expert-mode' : ''}}">
    <div *ngIf="config" class="col-6">
        <input id="input-mealname" class="input plannedmeal-name {{config.name?.length > 0 ? '' : ' invalid'}}" [(ngModel)]="config.name" placeholder="Name" type="text">
    </div>
    <div *ngIf="config" class="col-12">
        <div class="spacer-10"></div>
        Start: 
        <span class="marginleft-10">{{config.startDate?.asFormatedString()}}</span>
        <input [matDatepicker]="startDatePicker" [value]="config?.startDate" [matDatepickerFilter]="startDateFilterNutritionConfig" class="visibility-hidden width-0 padding-0" (dateInput)="onStartDateChanged($event.value?.toDate())">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker ></mat-datepicker><br>
        
        Ende: 
        <span class="marginleft-10">{{config.endDate?.asFormatedString()}}</span>
        <input [matDatepicker]="endDatePicker" [value]="config?.endDate" [matDatepickerFilter]="endDateFilterNutritionConfig" class="visibility-hidden width-0 padding-0" (dateInput)="onEndDateChanged($event.value?.toDate())">
        <span *ngIf="!config.endDate">unbegrenzt</span>
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker ></mat-datepicker>
        <div *ngIf="config.endDate && canRemoveEndDate()" class="icon-button display-inlineblock marginleft-25" (click)="onRemoveEndDate()">
            Enddatum entfernen
        </div>

        <div class="spacer-25"></div>
        <div *ngIf="config.situations?.length > 0" class="display-inlineblock">
            <ng-container *ngFor="let situation of config.situations">
                <button class="tab-button {{situation == selectedSituation && !extendedGoalsSelected ? ' active' : ''}}" (click)="onSelectSituation(situation)">
                    {{situation.getName()}}
                    <div class="icon-button link icon-deletesituation" *ngIf="situation.id != 'DEFAULT'" (click)="onDeleteSituation(situation);$event.stopPropagation()">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                </button>
            </ng-container>
            <button class="tab-button button-addsituation" (click)="onAddSituation()" tooltip="Situation hinzufügen">
                +
            </button>
        </div>
        <div class="display-inlineblock">
            <div class="vertical-line"></div>
            <button class="tab-button {{extendedGoalsSelected ? ' active' : ''}}" (click)="onSelectExtendedGoals()">
                Erweiterte Ziele
            </button>
        </div>
        <div class="spacer-25"></div>
        <ng-container *ngIf="!extendedGoalsSelected; else extendedGoalsTemplate;">
            <div *ngIf="selectedSituation?.id != 'DEFAULT'">
                <div class="d-inline-block wrapper-mealtype-selection">
                    <button class="button-filled light small dropdown-toggle margin-inbetween {{selectedSituation.type == null ? ' button-invalid' : ''}}" type="button" id="dropdownMealType" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{situationTranslation[selectedSituation.type]}}</button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMealType">
                        <div *ngFor="let type of situationTypes">
                            <button class="dropdown-item" type="button" (click)="onSituationTypeSelected(type)">{{situationTranslation[type]}}</button>
                        </div>
                    </div>
                </div>
                <input *ngIf="selectedSituation.type == 'CUSTOM'" class="input mealconfig small wide {{selectedSituation.customName?.length > 0 ? '' : ' invalid'}}" [(ngModel)]="selectedSituation.customName" type="text" placeholder="Name"/><br>
                <div class="spacer-10"></div>
            </div>
            <div>
                <app-nutritional-goal-setup
                    [user]="user"
                    [(nutritionalGoals)]="selectedSituation.nutritionalGoals"
                    [selectedSituation]="selectedSituation">
                </app-nutritional-goal-setup>
            </div>
        </ng-container>
        <ng-template #extendedGoalsTemplate>
            <div *ngFor="let group of BaseNutritionFact.nutritionalValueGroupNames; let i = index;">
                <b>{{group}}:</b>
                <ng-container *ngFor="let v of extendedGoals">
                    <div *ngIf="v.group == i" class="row extended-input-container">
                        <div class="col-7 col-md-6">
                            {{BaseNutritionFact.getNutritionalValueTranslation(v.name)}}:
                        </div>
                        <div class="auto">
                            <input tooltip="{{getRDA(v.name)}}" class="input small form-control white-input d-inline-block" [ngModel]="getCommonTargetValue(v.name)" (input)="setCommonTargetValue(v.name, $event.target.value)" pattern="[0-9]+([\.,][0-9]+)?" (focusout)="setCommonTargetValue(v.name, $event.target.value, true)" type="text" min="0" step=".001">
                            <div class="ml-1 d-inline-block">
                                {{BaseNutritionFact.getDefaultUnit(v.name)}}
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="spacer-25"></div>
            </div>
        </ng-template>
    </div>
</div>