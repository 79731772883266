import { FirestoreNutritionPlanService } from '../services/firestore-nutritionplan.service';
import { Chatmessage } from './chatmessage.model';
import { Coach } from './coach.model';
import { User } from './user.model';

export class Chat {
    chatId: string;
    participantsUids: string[];
    messages: Chatmessage[];
    unreadMessages: Chatmessage[];
    chatPartner: User;
    isOpen: boolean;
    lastMessageTimeAsNumber: number;

    tempMessage: string = "";
    tempVoiceRecordingFile: File = null;
    tempIsFolded: boolean = false;
    tempSelectedFiles: File[] = [];

    isBroadcastChat: boolean = false
    hasMoreMessages: boolean = true

    constructor();
    constructor(init: Chat);
    constructor(init?: Chat) {
        this.chatId = init && init.chatId || null
        this.participantsUids = init && init.participantsUids || []
        this.messages = init && init.messages || []
        this.unreadMessages = init && init.unreadMessages || []
        this.chatPartner = init && init.chatPartner || null
        this.isOpen = init && init.isOpen || false;
        this.lastMessageTimeAsNumber = 0;
    }

    getLatestMessage(): Chatmessage {
        if (this.messages.length > 0) {
            return this.messages[this.messages.length - 1];
        }
        return null;
    }
}

export class BroadcastChat extends Chat {
    selectedChats: Chat[] = [];
    selectedClientGroups: string[] = [];
    selectedCoaches: Coach[] = [];

    constructor() {
        super();
        this.isBroadcastChat = true
        this.chatId = FirestoreNutritionPlanService.generateUniqueString();
    }
}