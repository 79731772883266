import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { User } from 'src/app/model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-recipe-sharing-dialog',
  templateUrl: './recipe-sharing-dialog.component.html',
  styleUrls: ['./recipe-sharing-dialog.component.css']
})
export class RecipeSharingDialogComponent {

  shared: boolean;
  assignedUsers: string[];
  assignedGroupNames: string[];

  constructor(public dialogRef: MatDialogRef<RecipeSharingDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { shared: boolean, assignedUsers: string[], assignedGroupNames: string[] }, public dialog: MatDialog, private userService: FirestoreService) {
    this.shared = data.shared
    this.assignedUsers = data.assignedUsers
    this.assignedGroupNames = data.assignedGroupNames
  }

  onShareSelectedRecipeChanged(shared: boolean) {
    this.shared = shared
  }
  onAllAssignedUserDropdownValueChanged(checked: boolean) {
    if (checked) {
      this.assignedUsers = ["all"]
    } else {
      this.assignedUsers = []
    }
  }
  onAssignedGroupDropdownValueChanged(group: string){
    if (this.assignedGroupNames.includes(group)) {
      this.assignedGroupNames.forEach( (item, index) => {
        if (item == group) this.assignedGroupNames.splice(index, 1)
      })
    } else {
      this.assignedGroupNames.push(group)
    }
  }
  onAssignedUserDropdownValueChanged(user: User){
    if (this.assignedUsers.includes(user.uid)) {
      this.assignedUsers.forEach( (item, index) => {
        if (item == user.uid) this.assignedUsers.splice(index, 1)
      })
    } else {
      this.assignedUsers.push(user.uid)
    }
  }
  getSelectableClientGroups() {
    return this.userService.getClientGroups()
  }
  getSelectableClients() {
    return this.userService.getAccessibleClients()
  }
  areAllUsersTargeted() {
    return this.assignedUsers?.includes('all')
  }

  onSubmit() {
    this.dialogRef.close({ shared: this.shared, assignedUsers: this.assignedUsers, assignedGroupNames: this.assignedGroupNames })
  }
  onCancel() {
    this.dialogRef.close()
  }
}
