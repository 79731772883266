<div class="dialog-topbar">
    <div class="row">
        <div class="col">
            <h3 class="margin-0">Coachee hinzufügen</h3>
        </div>
        <div class="col-2">
            <div class="icon-button close float-right" (click)="onCloseDialog()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </div>
        </div>
    </div>
</div>
<div class="dialog-content-wrapper">
    <div class="row">
        <div *ngIf="stepNumber >= 0" class="step-wrapper col-12">
            <div class="step-indicator">
                <div class="step-number">1</div>
                <div class="step-line"></div>
            </div>
            <div class="step-content">
                <div class="row">
                    <div class="col-12">
                        <div class="step-name">Kunde</div>
                    </div>
                    <div class="spacer-10"></div>
                    <div *ngIf="!lockClient" class="col-12 textalign-center tab-button-group adjust-mobile">
                        <button class="tab-button {{selectedClientSource == tabNewClient ? 'active' : ''}}" (click)="onChangeClientSource(tabNewClient)">
                            Neuer Coachee
                            <div class="underline"></div>
                        </button>
                        <button *ngIf="paymentEnabled" class="tab-button {{selectedClientSource == tabExistingClient ? 'active' : ''}}" (click)="onChangeClientSource(tabExistingClient)">
                            Aktiver Coachee
                            <div class="underline"></div>
                        </button>
                        <button class="tab-button {{selectedClientSource == tabOldClient ? 'active' : ''}}" (click)="onChangeClientSource(tabOldClient)">
                            Deaktivierter Coachee
                            <div class="underline"></div>
                        </button>
                    </div>
                    <div class="spacer-25"></div>
                    
                    <ng-container *ngIf="selectedClientSource == tabNewClient">
                        <div class="col-12 col-md-6">
                            <div class="label">Name der:des Coachee</div>
                            <div class="spacer-10"></div>
                            <input id="client-name" [ngModel]="name" (input)="onPresetNameChanged($event.target.value)" class="input wide {{name?.length > 0 ? '' : ' invalid'}}" type="text">
                            <div class="spacer-25"></div>
                        </div>
                        <div class="col-12 col-md-6">
                            <label class="label">Email-Adresse der:des Coachee</label>
                            <div>
                                <input id="client-email" [ngModel]="email" (input)="onEmailChanged($event.target.value)" class="input wide {{isEmailAddressValid() ? '' : ' invalid'}}" type="text" placeholder="Email">
                            </div>
                            <div class="spacer-25"></div>
                        </div>
                        
                        <div class="col-12" *ngIf="lockClient && coachesOfLicenceHolder?.length > 1">
                            <div class="label">Coach zuweisen:</div>
                            <div class="spacer-10"></div>
                            <div class="d-inline-block wrapper-timerange-selection">
                                <button class="button-filled light small dropdown-toggle" type="button" id="dropdownCoaches" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [disabled]="!user.coach?.canAssignCoachesAndLicences()">{{getCoachByUid(getLicenceForDistribution()?.coachUid)?.name}}</button>
                                <div class="dropdown-menu" aria-labelledby="dropdownCoaches">
                                    <div *ngFor="let coach of coachesOfLicenceHolder">
                                        <button class="dropdown-item" type="button" (click)="onAssignCoachToLicence(coach, getLicenceForDistribution())">{{coach.name}}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="spacer-25"></div>
                        </div>
                    </ng-container>
            
                    <ng-container *ngIf="selectedClientSource == tabExistingClient">
                        <div class="col">
                            <div class="label">Coachee</div>
                            <div class="spacer-10"></div>
                            <button class="button-filled light small {{lockClient ? '' : 'dropdown-toggle'}} {{!client ? 'button-invalid' : ''}}" [disabled]="lockClient" type="button" id="dropdownUsers" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{client?.getName() ?? 'Auswählen'}}</button>
                            <div class="dropdown-menu dropdown-assignedusers" aria-labelledby="dropdownUsers">
                                <div class="dropdown-item link" *ngFor="let user of getClients()" (click)="onSelectClient(user)">
                                    <div class="display-inlineblock">{{user.getName()}}</div>
                                </div>
                            </div>
                            <div *ngIf="stepNumber > 0">
                                <div class="spacer-25"></div>
                                <label class="label">Email-Adresse der:des Coachee</label>
                                <div>
                                    <input [ngModel]="email" (input)="onEmailChanged($event.target.value)" class="input wide {{isEmailAddressValid() ? '' : ' invalid'}}" type="text" placeholder="Email">
                                </div>
                            </div>
                            <div class="spacer-25"></div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="selectedClientSource == tabOldClient">
                        <div class="col">
                            <div class="label">Coachee</div>
                            <div class="spacer-10"></div>

                            <ng-container *ngIf="oldClientsSubscription">
                                <div class="spacer-25"></div>
                                <mat-spinner class="loading-statistics-spinner" diameter="30" strokeWidth="2"></mat-spinner>
                                <div class="spacer-10"></div>
                            </ng-container>

                            <ng-container *ngIf="!oldClientsSubscription">
                                <button class="button-filled light small {{lockClient ? '' : 'dropdown-toggle'}} {{!client ? 'button-invalid' : ''}}" [disabled]="lockClient" type="button" id="dropdownUsers" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{client?.getName() ?? 'Auswählen'}}</button>
                                <div class="dropdown-menu dropdown-assignedusers" aria-labelledby="dropdownUsers">
                                    <div class="dropdown-item link" *ngFor="let licence of oldLicences" (click)="onSelectOldLicence(licence)">
                                        <div class="display-inlineblock">{{licence.presetName ?? licence.user?.getName() ?? licence.lid}}</div>
                                        <div class="icon-button display-inline float-right" tooltip="Email-Adresse anzeigen" (click)="onShowEmailAddress(licence);$event.stopPropagation()">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-at" viewBox="0 0 16 16">
                                                <path d="M13.106 7.222c0-2.967-2.249-5.032-5.482-5.032-3.35 0-5.646 2.318-5.646 5.702 0 3.493 2.235 5.708 5.762 5.708.862 0 1.689-.123 2.304-.335v-.862c-.43.199-1.354.328-2.29.328-2.926 0-4.813-1.88-4.813-4.798 0-2.844 1.921-4.881 4.594-4.881 2.735 0 4.608 1.688 4.608 4.156 0 1.682-.554 2.769-1.416 2.769-.492 0-.772-.28-.772-.76V5.206H8.923v.834h-.11c-.266-.595-.881-.964-1.6-.964-1.4 0-2.378 1.162-2.378 2.823 0 1.737.957 2.906 2.379 2.906.8 0 1.415-.39 1.709-1.087h.11c.081.67.703 1.148 1.503 1.148 1.572 0 2.57-1.415 2.57-3.643zm-7.177.704c0-1.197.54-1.907 1.456-1.907.93 0 1.524.738 1.524 1.907S8.308 9.84 7.371 9.84c-.895 0-1.442-.725-1.442-1.914"/>
                                            </svg>
                                        </div>
                                        <div class="label" *ngIf="licence.deactivationDate">Deaktiviert am {{licence.deactivationDate.asFormatedString()}}</div>
                                    </div>
                                </div>
                                <div *ngIf="stepNumber > 0">
                                    <div class="spacer-25"></div>
                                    <label class="label">Email-Adresse der:des Coachee</label>
                                    <div>
                                        <input [ngModel]="email" (input)="onEmailChanged($event.target.value)" class="input wide {{isEmailAddressValid() ? '' : ' invalid'}}" type="text" placeholder="Email">
                                    </div>
                                </div>
                            </ng-container>

                            <div class="spacer-25"></div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div *ngIf="stepNumber >= 1" class="step-wrapper col-12">
            <div class="step-indicator">
                <div class="step-number">2</div>
                <div class="step-line"></div>
            </div>
            <div class="step-content">
                <div class="row">
                    <div class="col-12">
                        <div class="step-name">Zahlung</div>
                    </div>
                    <div class="spacer-10"></div>
                    <div *ngIf="paymentEnabled" class="col-12">
                        <div class="spacer-10"></div>
                        <div class="label">Produkt</div>
                        <div class="spacer-10"></div>
                        <div class="d-inline-block wrapper-selection">
                            <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownTrigger" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ getLicenceForDistribution()?.productPurchaseId ? purchase?.product?.name || 'Unbekanntes Produkt' : 'Keine Zahlung'}}</button>
                            <div class="dropdown-menu" aria-labelledby="dropdownProduct">
                                <button class="dropdown-item" type="button" (click)="onSelectProduct(null)">Keine Zahlung</button>
                                <div *ngFor="let product of products">
                                    <button class="dropdown-item" type="button" (click)="onSelectProduct(product)">
                                        {{product.name}}
                                        <div class="label">{{product.getPrintablePriceWithCurrency()}} {{product.getPrintablePaymentFrequency()}}<ng-container *ngIf="product.getPrintableInitialSetupFee() != null"> + {{product.getPrintableInitialSetupFeeWithCurrency()}} Einmalgebühr</ng-container></div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="purchase">
                            <div class="spacer-10"></div>
                            <div class="">
                                <div class="display-inlineblock">
                                    <div class="label">Coaching-Start</div>
                                    <div class="spacer-10"></div>
                                    <div class="dual-switch">
                                        <div class="option {{purchase.startDate == null ? 'active' : ''}}" (click)="onSetSubscriptionStartDate(null)">Sofort</div>
                                        <div class="option {{purchase.startDate != null ? 'active' : ''}}" (click)="onEnableSubscriptionStartDate()">Ab Datum</div>
                                    </div>
                                </div>
                                <div class="display-inlineblock">
                                    <div *ngIf="purchase.startDate != null" class="display-inlineblock marginleft-25">
                                        <div class="label" for="datetime">Startdatum</div>
                                        <div class="spacer-10"></div>
                                        <input type="date" required class="form-datetime-input" id="datetime" [ngModel]="purchase.startDate | date:'yyyy-MM-dd'" (input)="onSetSubscriptionStartDate($event.target.value)">
                                    </div>
                                </div>
                            </div>
                            <div class="spacer-25"></div>
                            <ng-container *ngIf="purchase">
                                <div class="label">Verfügbare Zahlungsmethoden</div>
                                <div class="d-inline-block wrapper-selection">
                                    <button class="button-filled light small dropdown-toggle margin-inbetween {{getPrintablePaymentMethods() != null ? '' : 'invalid'}}" type="button" id="dropdownTrigger" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getPrintablePaymentMethods() || 'Auswählen'}}</button>
                                    <div class="dropdown-menu checkin-dropdown" aria-labelledby="dropdownBodyDataGraphTimeRange">
                                        <div class="dropdown-item link" (click)="onPaymentMethodSelected('sepa_debit');$event.stopPropagation()">
                                            <input class="input checkbox" type="checkbox" [checked]="isPaymentMethodAvailable('sepa_debit')"><div class="display-inline">Sepa-Lastschrift</div>
                                        </div>
                                        <div class="dropdown-item link" (click)="onPaymentMethodSelected('bank_transfer');$event.stopPropagation()">
                                            <input class="input checkbox" type="checkbox" [checked]="isPaymentMethodAvailable('bank_transfer')"><div class="display-inline">Banküberweisung</div>
                                        </div>
                                        <div class="dropdown-item link" (click)="onPaymentMethodSelected('card');$event.stopPropagation()">
                                            <input class="input checkbox" type="checkbox" [checked]="isPaymentMethodAvailable('card')"><div class="display-inline">Kreditkarte</div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div *ngIf="!paymentEnabled" class="col-12">
                        Richte deinen Account ein, um Zahlungen zu aktivieren.
                    </div>
                    <div class="spacer-25"></div>
                </div>
            </div>
        </div>

        <div *ngIf="stepNumber >= 2" class="step-wrapper col-12">
            <div class="step-indicator">
                <div class="step-number">3</div>
                <div class="step-line"></div>
            </div>
            <div class="step-content">
                <div class="row">
                    <div class="col-12">
                        <div class="step-name">Voreinstellungen</div>
                    </div>
                    <div class="spacer-10"></div>
                    <div *ngIf="!getLicenceForDistribution()?.productPurchaseId" class="col-12 col-md-6">
                        <div class="label display-inlineblock">Ablaufdatum der Lizenz</div>
                        <div class="icon-button marginleft-25" tooltip="Wenn du ein Ablaufdatum setzt, erhältst du ab zwei Tage vor Ablaufdatum einen Hinweis auf dem Dashboard. Die Deaktivierung musst du manuell vornehmen!">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                            </svg>
                        </div><br>
                        <input [ngModel]="getLicenceForDistribution()?.expirationDate?.asFormatedString()" class="input full-date" type="text" placeholder="optional" disabled>
                        <input [matDatepicker]="expirationDatePicker" class="visibility-hidden width-0 padding-0" (dateInput)="onExpirationDateChanged(getLicenceForDistribution(), $event.value?.toDate())">
                        <div class="icon-button" *ngIf="getLicenceForDistribution()?.expirationDate != null" (click)="onExpirationDateChanged(getLicenceForDistribution(), null)">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </div>
                        <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #expirationDatePicker></mat-datepicker>
                    </div>
                    <div class="spacer-25"></div>

                    <ng-container *ngIf="!isActiveClientSelected()">
                        <div class="col-12">
                            <div class="label">Gruppen zuweisen</div>
                            <div class="spacer-10"></div>
                            <div class="d-inline-block wrapper-selection">
                                <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownTrigger" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getAssignedGroupNames() || 'Keine Gruppe'}}</button>
                                <div class="dropdown-menu checkin-dropdown" aria-labelledby="dropdownBodyDataGraphTimeRange">
                                    <div *ngFor="let group of userService.getClientGroups()" class="dropdown-item link" (click)="onAssignToClientGroup(group);$event.stopPropagation()">
                                        <input class="input checkbox" type="checkbox" [checked]="licence?.assignedClientGroups.includes(group)"><div class="display-inline">{{group}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="spacer-10"></div>

                        <div class="col-12">
                            <div class="label">Check-Ins für Anamnese</div>
                            <div class="spacer-10"></div>
                            <div class="d-inline-block wrapper-selection">
                                <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownTrigger" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getSelectedOnboardingQuestionaires() || 'Kein Formular'}}</button>
                                <div class="dropdown-menu checkin-dropdown" aria-labelledby="dropdownBodyDataGraphTimeRange">
                                    <div *ngFor="let questionaire of availableQuestionaires" class="dropdown-item link" (click)="onPreselectQuestionaire(questionaire);$event.stopPropagation()">
                                        <input class="input checkbox" type="checkbox" [checked]="licence?.onboardingQuestionaireIds.includes(questionaire.id)"><div class="display-inline">{{questionaire.internalName}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="spacer-10"></div>
                        
                        <div class="col-12">
                            <div class="label">Metriken zuweisen</div>
                            <div class="spacer-10"></div>
                            {{getPrintableAssignedMetrics() || 'Keine Metriken'}}

                            <button class="icon-button marginleft-25" (click)="onChangeAssignedMetrics()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16">
                                    <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                                </svg>
                                Anpassen
                            </button>
                            <button *ngIf="licence?.assignedMetricIds?.length > 0" class="icon-button marginleft-25" (click)="onClearAssignedMetrics()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"/>
                                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"/>
                                </svg>
                                Leeren
                            </button>
                        </div>
                        <div class="spacer-25"></div>
    
                        <div class="recipe-sharing-settings display-inlineblock">
                            <div class="recipesharing-toggle toggle-wrapper display-inlineblock marginright-25" tooltip="Blende alle Kalorien- und Ernährungs-relevanten Zahlen in der App aus.">
                                <div class="toggle {{getLicenceForDistribution().hideNutritionValues ? 'on' : ''}}" (click)="onHideNutritionValuesChanged()">
                                    <div class="handle"></div>
                                </div>
                                <div class="text">
                                    Kalorien/Nährstoffe in der App ausblenden
                                </div>
                            </div>
                        </div>
                        <div class="spacer-10"></div>
    
                        <div class="recipe-sharing-settings display-inlineblock">
                            <div class="recipesharing-toggle toggle-wrapper display-inlineblock marginright-25" tooltip="Blende den Chat in der App aus.">
                                <div class="toggle {{getLicenceForDistribution().hideChat ? 'on' : ''}}" (click)="onHideChatChanged()">
                                    <div class="handle"></div>
                                </div>
                                <div class="text">
                                    Chat deaktivieren
                                </div>
                            </div>
                        </div>
    
                        <div class="spacer-25"></div>
                    </ng-container>
                    
                </div>
            </div>
        </div>

        <div *ngIf="stepNumber >= 3" class="step-wrapper col-12">
            <div class="step-indicator">
                <div class="step-number">4</div>
                <div class="step-line"></div>
            </div>
            <div class="step-content">
                <div *ngIf="!isOldClientSelected() || purchase?.product != null" class="row">
                    <div class="col-12">
                        <div class="step-name">Einladung</div>
                    </div>
                    <div class="col-12 textalign-center tab-button-group adjust-mobile">
                        <button class="tab-button {{currentTab == tabCopy ? 'active' : ''}}" (click)="onChangeTab(tabCopy)">
                            Einladung kopieren
                            <div class="underline"></div>
                        </button>
                        <button class="tab-button {{currentTab == tabEmail ? 'active' : ''}}" (click)="onChangeTab(tabEmail)">
                            Per Email versenden
                            <div class="underline"></div>
                        </button>
                    </div>
                    <div class="spacer-25"></div>
    
                    <div class="col-12">
                        <ng-container *ngIf="currentTab == tabCopy">
                            <div class="row">
                                <div class="col-10 bold">
                                    Einladung
                                    <button class="icon-button marginleft-25" ngxClipboard [cbContent]="invitationText" (click)="onMessageCopied()">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-files padding-4" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z"/>
                                            <path d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z"/>
                                        </svg>
                                        Kopieren
                                    </button>
                                    <button *ngIf="!licence.productPurchaseId" class="button-filled light small marginleft-25" (click)="onEditInvitationEmail()">Vorlage anpassen</button>
                                    <button *ngIf="licence.productPurchaseId" class="button-filled light small marginleft-25" (click)="onEditOfferingEmail()">Vorlage anpassen</button>
                                </div>
                                <div class="spacer-10"></div>
                                <div class="col-12">
                                    <textarea [(ngModel)]="invitationText" class="input welcome-message full-width" type="text" rows="20" placeholder="Einladungstext"></textarea>
                                    <div class="spacer-25"></div>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12">
                                    Link, um direkt in die App zum Einlösen zu gelangen:
                                </div>
                                <div class="spacer-10"></div>
                                <div class="col-12 text-to-copy">
                                    {{invitationLink}}
                                    <button class="icon-button marginleft-25" ngxClipboard [cbContent]="invitationLink" (click)="onRedeemLinkCopied()">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-files padding-4" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z"/>
                                            <path d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z"/>
                                        </svg>
                                        Kopieren
                                    </button>
                                </div>
                            </div>
                            <br>
                            <div *ngIf="!getLicenceForDistribution()?.productPurchaseId" class="row">
                                <div class="col-12">
                                    Lizenzcode zur Eingabe unter Einstellungen -> Account:
                                </div>
                                <div class="spacer-10"></div>
                                <div class="col-12">
                                    <div class="text-to-copy display-inlineblock">
                                        {{getLicenceForDistribution()?.lid}}
                                    </div>
                                    <div class="display-inlineblock">
                                        <div #container>
                                            <button class="icon-button copy-to-clipboard marginleft-25" ngxClipboard [cbContent]="getLicenceForDistribution()?.lid" (click)="onLicenceCodeCopied()">
                                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-files padding-4" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z"/>
                                                    <path d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z"/>
                                                </svg>
                                                Kopieren
                                            </button>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="spacer-25"></div>
                            
                        </ng-container>
                    
                        <ng-container *ngIf="currentTab == tabEmail">
                            <div class="">
                                
                                <h3 class="display-inlineblock">Email-Inhalt</h3>
                                <button *ngIf="!licence.productPurchaseId" class="button-filled light small marginleft-25" (click)="onEditInvitationEmail()">Vorlage anpassen</button>
                                <button *ngIf="licence.productPurchaseId" class="button-filled light small marginleft-25" (click)="onEditOfferingEmail()">Vorlage anpassen</button>
                                <div class="spacer-10"></div>
                                <label class="label">Überschrift</label><br>
                                <input [(ngModel)]="headingText" class="input wide" type="text" placeholder="Überschrift">
                                <div class="spacer-10"></div>
                                <label class="label">Inhalt</label>
                                <textarea [(ngModel)]="invitationText" class="input welcome-message full-width" type="text" rows="20" placeholder="Einladungstext"></textarea>
                                <div class="spacer-25"></div>
                    
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div *ngIf="selectedClientSource == tabOldClient && purchase?.product == null" class="row">
                    <div class="col-12">
                        <div *ngIf="canActivateOldLicence()">Klicke auf Reaktivieren, um die Lizenz auf aktiv zu stellen.</div>
                        Dein Coachee kann sich mit seinem bestehenden Account wieder anmelden und ist mit dir verbunden.
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="stepNumber < 3" class="col-12">
            <div class="spacer-25"></div>
            <button *ngIf="stepNumber == 0" id="button-continue-0" class="button-filled small light" (click)="goToNextStep()">Weiter</button>
            <button *ngIf="stepNumber == 1" id="button-continue-1" class="button-filled small light" (click)="goToNextStep()">Weiter</button>
            <button *ngIf="stepNumber == 2" id="button-continue-2" class="button-filled small light" (click)="goToNextStep()">Weiter</button>
            <div class="spacer-25"></div>
        </div>
        <div *ngIf="stepNumber >= 3" class="col-12">
            <div class="spacer-25"></div>
            <div class="label">Fertig!</div>
        </div>

    </div>
</div>

<div *ngIf="stepNumber == 3" class="dialog-bottombar">
    <div class="row">
        <button *ngIf="selectedClientSource != tabOldClient" class="button-filled small" (click)="onConfirmDialog()">Fertig</button>
        <ng-container *ngIf="currentTab == tabEmail">
            <button class="button-filled small marginleft-25 {{isEmailAddressValid() ? '' : 'blocked'}}" (click)="onSendEmail()">Email versenden</button>
            <div *ngIf="!isEmailAddressValid()" class="padding-4 marginleft-25">Bitte gib eine gültige Email-Adresse ein.</div>
            <div class="spinner-wrapper">
                <mat-spinner *ngIf="sendingEmail" class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
            </div>
        </ng-container>
        <button *ngIf="canActivateOldLicence() && !activatingLicence" class="button-filled small marginleft-25" (click)="onActivateLicence()">Reaktivieren</button>
        <div class="spinner-wrapper" *ngIf="activatingLicence">
            <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
        </div>
    </div>
</div>