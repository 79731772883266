import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-metric-data-image-dialog',
  templateUrl: './metric-data-image-dialog.component.html',
  styleUrls: ['./metric-data-image-dialog.component.css']
})
export class MetricDataImageDialogComponent implements OnInit {

  // public imageURL: string;
  public compareImages: any[] = [];
  constructor(public dialogRef: MatDialogRef<MetricDataImageDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {imageURL: string, compareImages: any[]}) {
    // this.imageURL = data.imageURL;
    if (data.imageURL != null){
      this.compareImages.push({imageURL: data.imageURL});
    }
    if (data.compareImages != null){
      data.compareImages.forEach(image => {
        if(!this.compareImages.includes(image)){
          this.compareImages.push(image)
        }
      });
    }
    dialogRef.disableClose = false
  }

  ngOnInit(): void {
  }

  onCloseDialog(){
    this.dialogRef.close();
  }


}
