import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ILanguageDictionary } from 'src/app/model/languagedictionary.model';

@Component({
  selector: 'app-filter-selection-dropdown',
  templateUrl: './filter-selection-dropdown.component.html',
  styleUrls: ['./filter-selection-dropdown.component.css']
})
export class FilterSelectionDropdownComponent implements OnInit {

  @Input() availableFilterObjects: FilterObject[] = [];
  @Input() isTranslatable: boolean = true;
  @Input() selectedLanguage: string = "de"
  @Input() hasSearchField: boolean = false;

  @Output() filterChanged = new EventEmitter()
  @Output() onFilterOpened = new EventEmitter()

  searchInput: string = null

  constructor() { }
  
  ngOnInit(): void {
  }

  public get hasAnyFilter():boolean{
    return this.availableFilterObjects?.filter(x => x.isFiltered)?.length > 0
  }

  getSearchResultFilterObjects(): FilterObject[] {
    if (!this.searchInput || this.searchInput.length == 0) return this.availableFilterObjects
    return this.availableFilterObjects?.filter(x => this.getText(x).toLowerCase().includes(this.searchInput.toLowerCase()))
  }

  openFilter() {
    this.onFilterOpened.emit()
  }

  onSetFilteredItem(filterObject: FilterObject){
    filterObject.isFiltered = !filterObject.isFiltered
    this.filterChanged.emit()
  }

  public getText(filterObject:FilterObject):string{
    if(this.isTranslatable) return filterObject.originObject.GetValue(this.selectedLanguage)
    return filterObject.originObject.originObject.toString()
  }

  onSearchInputChanged(text: string) {
    this.searchInput = text
  }
  onDeleteSearchInput() {
    (<HTMLInputElement> document.getElementById('search-input')).value = ''
    this.searchInput = null
  }
}


export class FilterObject{
  public isFiltered: boolean = false
  public originObject: ILanguageDictionary<any>;
  constructor(originObject: ILanguageDictionary<any>, isFiltered: boolean = false){
    this.originObject = originObject
    this.isFiltered = isFiltered
  }
}
