<div *ngIf="displayedUser" class="page-content">
    <div class="container-fluid" *ngIf="loaded">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-3 order-md-2 order-lg-3">
                <div class="layout-box profile-container link" (click)="openUserProfile()">
                    <div class="profile-picture">
                        <img draggable="false" *ngIf="displayedUser?.profilePictureUrl" loading="lazy" (error)="utilityService.onImageErrorHide($event)" src="{{displayedUser?.profilePictureUrl}}">
                        <div *ngIf="!displayedUser?.profilePictureUrl">
                            <img draggable="false" loading="lazy" (error)="utilityService.onImageErrorHide($event)" src="{{ (userService.getProfilePictureForUser(displayedUser) | async) }}">
                        </div>
                      </div>
                    <div class="profile-text {{displayedUser.hasOtherLanguageSettings() ? ' has-languagesettings' : ''}}">
                        <h3 class="profile-name">{{displayedUser.getName()}}</h3>
                        <div *ngIf="displayedUser.hasOtherLanguageSettings()" tooltip="Dein Coachee hat eine andere App-Sprache eingestellt."><img class="language-flag" src="assets/icons/icon-united-kingdom.png" alt=""></div>
                    </div><br>
                    <div class="profile-information display-block">
                        <div *ngIf="displayedUser.nextCompetitionDate">
                            Nächster Wettkampf: {{displayedUser.nextCompetitionDate?.asFormatedString()}}
                        </div>
                        <div>
                            {{displayedUser?.getPrintableTrainingPlan() ?? 'Kein Training geplant'}}{{displayedUser?.getPrintableCurrentTrainingWeek() ?? ''}}{{displayedUser?.getPrintableLastTrainingPlanDate()}}
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <button class="button-filled small light">Athleten-Einstellungen</button>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 order-md-1 order-lg-1">
                <div class="layout-box quicknote-container">
                    <app-quick-note-view [setUser]="displayedUser"></app-quick-note-view>
                </div>
            </div>
            <div class="col-12 col-lg-6 order-md-3 order-lg-2">
                <div class="layout-box onerm-container">
                    <app-one-rm-editor [User]="displayedUser"></app-one-rm-editor>
                </div>
            </div>
        </div>

        <ng-container *ngIf="isClientsTrainingMenuItemActive()">
            <div class="row">
                <div class="col-12">
                    <app-training-plan-editor-inline [SelectedCoach]="user" [User]="displayedUser">
                    </app-training-plan-editor-inline>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="isClientsAnalyticsMenuItemActive()">
            <div class="row">
                <div *ngIf="displayedUser" class="col-12">
                    <app-checkin-table-view [User]="displayedUser"></app-checkin-table-view>
                </div>
            </div>
            <div class="row">
                <div *ngIf="displayedUser" class="col-12">
                    <app-body-data [selectedUserUid]="displayedUser.uid"></app-body-data>
                </div>
            </div>
            <!--div class="row">
                <div class="col-12 col-lg-6 order-2 order-lg-1">
                    <ng-container *ngIf="!displayedUser.loadedTrainingPlans; else trainingHistoryTemplate">
                        <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
                    </ng-container>
                    <ng-template #trainingHistoryTemplate>
                        <app-training-history [User]="displayedUser"></app-training-history>
                    </ng-template>
                </div>
                <div class="col-12 col-lg-6 order-1 order-lg-2">
                    <div class="layout-box adjust-mobile">
                        <app-completed-questionaires-overview [user]="displayedUser" [relevantDailyConditions]="displayedUser.dailyConditions"></app-completed-questionaires-overview>
                    </div>
                </div>
            </div-->
            <div class="row">
                <div class="col-12">
                    <div class="layout-box adjust-mobile">
                        <h3>Trainingsstatistik</h3>
                        <app-training-graph [User]="displayedUser"></app-training-graph>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="isNutritionEnabled()">
                <div class="col-12">
                    <div class="layout-box">
                        <app-nutrition-graph [User]="displayedUser"></app-nutrition-graph>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="isClientsNutritionMenuItemActive()">
            <ng-container *ngIf="isNutritionEnabled()">
                <div class="row {{nutritionPlanOpened ? 'nutritionplan-opened' : ''}}">
                    <div class="col-12">
                        <h2>Ernährungsplanung</h2>
                        <app-nutrition-plan [selectedUser]="displayedUser" [selectedCoach]="user"  (openPlanEvent)="onNutritionPlanOpened($event)"></app-nutrition-plan>
                    </div>
                    <div class="spacer-25"></div>
                    <div class="col-12 diary-wrapper">
                        <h2>Tagebuch</h2>
                        <app-diary [setDisplayedUser]="displayedUser"></app-diary>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!isNutritionEnabled()">
                <div class="row">
                    <div class="col-12">
                        <div class="spacer-25"></div>
                        <h3>Ernährungsbereich deaktiviert.</h3>
                        Wenn du die Ernährung des Athleten planen und den Ernährungs-Tab in der Kunden-App hinzufügen möchtest, aktiviere den Ernährungsbereich in den Athleten-Einstellungen.
                    </div>
                    <div class="spacer-25"></div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>