import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from  './user/login/login.component';
import { RegisterComponent } from  './user/register/register.component';
import { ForgotPasswordComponent } from  './user/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from  './user/verify-email/verify-email.component';
import { ProfileScreenComponent } from './profile-screen/profile-screen.component';
import { CoachAreaComponent } from './coach-area/coach-area.component';
import { ClientComponent } from './client/client-v1/client-v1.component';
import { ClientRecipesComponent } from './recipes_client/recipes_client.component';
import { LicenceManagementComponent } from './licence-management/licence-management.component';
import { RecipesComponent } from './recipes/recipes.component';
import { DatabaseAdminComponent } from './database-admin/database-admin.component';
import { LicenceMonitoringComponent } from './licence-monitoring/licence-monitoring.component';
import { SettingsBaseComponent } from './settings/settings-base/settings.component';
import { BodyDataComponent } from './body-data/body-data.component';
import { EventLogComponent } from './event-log/event-log.component';
import { AccountComponent } from './account/account.component';
import { SubscriptionSuccessComponent } from './subscription-success/subscription-success.component';
import { MobileAppChatComponent } from './mobile-app-chat/mobile-app-chat.component';
import { NewsBoardComponent } from './news-board/news-board.component';
import { TeamManagementComponent } from './team-management/team-management.component';
import { TrainingTabPageComponent } from './training/training-tab-page/training-tab-page.component';
import { QuestionaireOverviewComponent } from './questionaire/questionaire-overview/questionaire-overview.component';
import { QuestionaireOverviewPageComponent } from './questionaire/questionaire-overview-page/questionaire-overview-page.component';
import { CoachingToolsComponent } from './coaching-tools/coaching-tools.component';
import { PaymentComponent } from './payment/payment/payment.component';
import { CheckoutComponent } from './payment/checkout/checkout.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ProductPageComponent } from './payment/product-page/product-page.component';
import { ProfileViewComponent } from './payment/profile-view/profile-view.component';
import { InvoiceViewComponent } from './payment/invoice-view/invoice-view.component';
import { ProductViewComponent } from './payment/product-view/product-view.component';
import { PartnersComponent } from './partners/partners.component';
import { ClientBaseComponent } from './client/client-base/client-base.component';
import { DashboardBaseComponent } from './dashboard/dashboard-base/dashboard-base.component';

const routes: Routes = [
  // { path: '', redirectTo: '/login', pathMatch: 'full'},
  { path: '', redirectTo: '', pathMatch: 'full'},
  { path: 'login', component: LandingPageComponent },
  { path: 'login/:redirectURL', component: LandingPageComponent },
  { path: 'get-started', component: RegisterComponent },
  { path: 'forgot-password', component:  ForgotPasswordComponent },
  { path: 'verify-email', component:  VerifyEmailComponent },
  { path: 'dashboard', component: DashboardBaseComponent },
  { path: 'user-profile', component: ProfileScreenComponent },
  { path: 'clients', component: CoachAreaComponent },
  { path: 'client/:uid', component: ClientBaseComponent },
  { path: 'recipes_client', component: ClientRecipesComponent},
  { path: 'licence', component: LicenceManagementComponent},
  { path: 'recipes', component: RecipesComponent},
  { path: 'database', component: DatabaseAdminComponent},
  { path: 'monitoring', component: LicenceMonitoringComponent},
  { path: 'settings', component: SettingsBaseComponent},
  { path: 'settings/:param', component: SettingsBaseComponent},
  { path: 'account', component: AccountComponent},
  { path: 'subscription-success', component: SubscriptionSuccessComponent},
  { path: 'body-data/:uid', component: BodyDataComponent},
  { path: 'body-data', component: BodyDataComponent},
  { path: 'event-log', component: EventLogComponent},
  { path: 'chat', component: MobileAppChatComponent},
  { path: 'chat/:chatId', component: MobileAppChatComponent},
  { path: 'training', component: TrainingTabPageComponent},
  { path: 'questionnaires', component: QuestionaireOverviewPageComponent},
  { path: 'news-board', component: NewsBoardComponent},
  { path: 'team', component: TeamManagementComponent},
  { path: 'tools', component: CoachingToolsComponent},
  { path: 'payment', component: PaymentComponent},
  { path: 'checkout', component: CheckoutComponent},
  { path: 'checkout/:id', component: CheckoutComponent},
  { path: 'product', component: ProductPageComponent},
  { path: 'product/:id', component: ProductPageComponent},
  { path: 'profile', component: ProfileViewComponent},
  { path: 'profile/:id', component: ProfileViewComponent},
  { path: 'invoice', component: InvoiceViewComponent},
  { path: 'invoice/:id', component: InvoiceViewComponent},
  { path: 'product-iframe', component: ProductViewComponent},
  { path: 'product-iframe/:id', component: ProductViewComponent},
  { path: 'partners', component: PartnersComponent},
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
