import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-update-email-dialog',
  templateUrl: './update-email-dialog.component.html',
  styleUrls: ['./update-email-dialog.component.css']
})
export class UpdateEmailDialogComponent {

  constructor(public dialogRef: MatDialogRef<UpdateEmailDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { }, public dialog: MatDialog, private toastr: ToastrService, private userService: FirestoreService) {}

  public oldEmail: string
  public newEmail: string
  public loading: boolean = false
  public message: string = null
  

  onUpdateEmail() {
    if (!this.oldEmail || !this.newEmail) return
    if (this.oldEmail.length < 5 || this.newEmail.length < 5) return
    this.loading = true
    this.userService.updateEmailOfUser(this.oldEmail, this.newEmail).then((res) => {
      this.loading = false
      console.log(res)
      if (res.success) {
        this.toastr.success('Email-Adresse aktualisiert')
        this.dialogRef.close()
      } else if (res.message) {
        if (!res.success) this.message = res.message
      } else if (res.error) {
        this.message = res.error
      }
    })
  }

  onClose() {
    this.dialogRef.close();
  }
}
