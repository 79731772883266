import { NutritionalValuePopoverComponent } from '../nutritional-value-popover/nutritional-value-popover.component';
import { Serving } from './serving.model';
import { LanguageDictionary } from './languagedictionary.model';

export enum NutritionalValue {
    calories,
    fat,
    saturatedFat,
    carbohydrates,
    sugar,
    protein,
    fibre,
    salt,
    polyols,
    monounsaturatedFat,
    polyunsaturatedFat,
    transFat,
    omega3Fat,
    omega6Fat,
    vitaminA,
    vitaminC,
    vitaminD,
    vitaminB1,
    vitaminB2,
    vitaminB3,
    vitaminB5,
    vitaminB6,
    vitaminB7,
    vitaminB9,
    vitaminB12,
    vitaminE,
    vitaminK,
    iron,
    magnesium,
    zinc,
    calcium,
    sodium,
    potassium,
    phosphorus,
    sulphur,
    chlorine,
    copper,
    manganese,
    fluorine,
    iodine,
    minerals,
    organicAcids,
    mannitol,
    sorbitol,
    xylitol,
    glucose,
    fructose,
    galactose,
    sucrose,
    maltose,
    lactose,
    glycogen,
    starch,
    monosaccharides,
    disaccharides,
    oligosaccharides,
    polysaccharides,
    waterSolubleFibre,
    waterInsolubleFibre,
    essentialAminoAcids,
    nonessentialAminoAcids,
    leucine,
    isoleucine,
    valine,
    uricAcid,
    purine,
    linoleicAcid,
    alphaLinolenicAcid,
    arachidonicAcid,
    eicosapentaenoicAcid,
    docosahexaenoicAcid,
    cholesterol,
    alcoholContent,
    alcohol,
    water,

    vitaminARetinol, vitaminABetaCarotene, vitaminEAlphaTocopherol, vitaminB3Niacin, lysine, methionine, cysteine, phenylalanine, tyrosine, threonine, tryptophan, arginine, histidine, alanine, asparagine, glutamicAcid, glycine, proline, serine
}

export interface NutritionalValueHolder {
    getNutritionalValue(string): number
}
export interface NutritionalValueEditable extends NutritionalValueHolder {
    setNutritionalValue(string, number)
    recalculateNutritionalValues()
}

export class BaseNutritionFact implements NutritionalValueHolder {

    static getNutritionalValues(): string[] {
        return Object.keys(NutritionalValue).filter((item) => {
            return isNaN(Number(item));
        })
    }
    static getNutritionalValuesForGroup(group: number): string[] {
        var nutritionalValues = []
        BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
            if (this.getNutritionalValueGroup(nutritionalValue) == group) {
                nutritionalValues.push(nutritionalValue)
            }
        })
        return nutritionalValues
    }

    getName(languageCode: string = 'de') {
        if (languageCode == 'de') return this.nameDe
        else return this.nameEn ?? this.nameDe
    }

    static getNutritionalValueTranslation(nutritionalValue: string) {
        switch (NutritionalValue[nutritionalValue].toString()) {
            case NutritionalValue.calories.toString(): return "Kalorien"
            case NutritionalValue.carbohydrates.toString(): return "Kohlenhydrate"
            case NutritionalValue.protein.toString(): return "Eiweiß"
            case NutritionalValue.fat.toString(): return "Fett"
            case NutritionalValue.sugar.toString(): return "Zucker"
            case NutritionalValue.polyols.toString(): return "Polyole"
            case NutritionalValue.fibre.toString(): return "Ballaststoffe"
            case NutritionalValue.saturatedFat.toString(): return "Gesättigte Fettsäuren"
            case NutritionalValue.monounsaturatedFat.toString(): return "Einfach ungesätt. Fettsäuren"
            case NutritionalValue.polyunsaturatedFat.toString(): return "Mehrfach ungesätt. Fettsäuren"
            case NutritionalValue.transFat.toString(): return "Transfette"
            case NutritionalValue.omega3Fat.toString(): return "Omega 3-Fettsäuren"
            case NutritionalValue.omega6Fat.toString(): return "Omega 6-Fettsäuren"
            case NutritionalValue.vitaminA.toString(): return "Vitamin A"
            case NutritionalValue.vitaminC.toString(): return "Vitamin C"
            case NutritionalValue.vitaminD.toString(): return "Vitamin D"
            case NutritionalValue.vitaminB1.toString(): return "Vitamin B1"
            case NutritionalValue.vitaminB2.toString(): return "Vitamin B2"
            case NutritionalValue.vitaminB3.toString(): return "Vitamin B3"
            case NutritionalValue.vitaminB5.toString(): return "Vitamin B5"
            case NutritionalValue.vitaminB6.toString(): return "Vitamin B6"
            case NutritionalValue.vitaminB7.toString(): return "Vitamin B7"
            case NutritionalValue.vitaminB9.toString(): return "Vitamin B9"
            case NutritionalValue.vitaminB12.toString(): return "Vitamin B12"
            case NutritionalValue.vitaminE.toString(): return "Vitamin E"
            case NutritionalValue.vitaminK.toString(): return "Vitamin K"
            case NutritionalValue.minerals.toString(): return "Mineralstoffe"
            case NutritionalValue.sodium.toString(): return "Sodium"
            case NutritionalValue.potassium.toString(): return "Kalium"
            case NutritionalValue.phosphorus.toString(): return "Phosphor"
            case NutritionalValue.sulphur.toString(): return "Schwefel"
            case NutritionalValue.chlorine.toString(): return "Chlorid"
            case NutritionalValue.copper.toString(): return "Kupfer"
            case NutritionalValue.manganese.toString(): return "Mangan"
            case NutritionalValue.fluorine.toString(): return "Fluorid"
            case NutritionalValue.iodine.toString(): return "Iodid"
            case NutritionalValue.water.toString(): return "Wasser"
            case NutritionalValue.alcoholContent.toString(): return "Alkoholanteil"
            case NutritionalValue.nonessentialAminoAcids.toString(): return "Nichtessentielle Aminosäuren"
            case NutritionalValue.essentialAminoAcids.toString(): return "Essentielle Aminosäuren"
            case NutritionalValue.cholesterol.toString(): return "Cholesterin"
            case NutritionalValue.starch.toString(): return "Stärke"
            case NutritionalValue.glycogen.toString(): return "Glycogen"
            case NutritionalValue.lactose.toString(): return "Lactose"
            case NutritionalValue.maltose.toString(): return "Maltose"
            case NutritionalValue.sucrose.toString(): return "Sucrose"
            case NutritionalValue.galactose.toString(): return "Galactose"
            case NutritionalValue.fructose.toString(): return "Fructose"
            case NutritionalValue.glucose.toString(): return "Glucose"
            case NutritionalValue.xylitol.toString(): return "Xylit"
            case NutritionalValue.sorbitol.toString(): return "Sorbit"
            case NutritionalValue.mannitol.toString(): return "Mannit"
            case NutritionalValue.monosaccharides.toString(): return "Monosaccharide"
            case NutritionalValue.disaccharides.toString(): return "Disaccharide"
            case NutritionalValue.oligosaccharides.toString(): return "Oligosaccharide"
            case NutritionalValue.polysaccharides.toString(): return "Polysaccharide"
            case NutritionalValue.leucine.toString(): return "Leucin"
            case NutritionalValue.isoleucine.toString(): return "Isoleucin"
            case NutritionalValue.valine.toString(): return "Valin"
            case NutritionalValue.organicAcids.toString(): return "Organische Säuren"
            case NutritionalValue.polyols.toString(): return "Mehrw. Alkohole"
            case NutritionalValue.iron.toString(): return "Eisen"
            case NutritionalValue.magnesium.toString(): return "Magnesium"
            case NutritionalValue.zinc.toString(): return "Zink"
            case NutritionalValue.calcium.toString(): return "Calcium"
            case NutritionalValue.waterSolubleFibre.toString(): return "Wasserlösliche Ballaststoffe"
            case NutritionalValue.waterInsolubleFibre.toString(): return "Wasserunlösliche Ballaststoffe"
            case NutritionalValue.uricAcid.toString(): return "Harnsäure"
            case NutritionalValue.purine.toString(): return "Purin"
            case NutritionalValue.linoleicAcid.toString(): return "Linolsäure"
            case NutritionalValue.alphaLinolenicAcid.toString(): return "Linolensäure"
            case NutritionalValue.arachidonicAcid.toString(): return "Arachidonsäure"
            case NutritionalValue.eicosapentaenoicAcid.toString(): return "Eicosapentaensäure"
            case NutritionalValue.docosahexaenoicAcid.toString(): return "Docosahexaensäure"
            case NutritionalValue.salt.toString(): return "Salz"
            case NutritionalValue.alcohol.toString(): return "Alkohol"
        
            default: return null
        }
    }
    static getNutritionalValueGroup(nutritionalValue: string) {
        switch (NutritionalValue[nutritionalValue].toString()) {
            case NutritionalValue.calories.toString(): return 0
            case NutritionalValue.carbohydrates.toString(): return 0
            case NutritionalValue.protein.toString(): return 0
            case NutritionalValue.fat.toString(): return 0
            case NutritionalValue.sugar.toString(): return 0
            case NutritionalValue.polyols.toString(): return 2
            case NutritionalValue.fibre.toString(): return 0
            case NutritionalValue.saturatedFat.toString(): return 0
            case NutritionalValue.monounsaturatedFat.toString(): return 1
            case NutritionalValue.polyunsaturatedFat.toString(): return 1
            case NutritionalValue.transFat.toString(): return 1
            case NutritionalValue.omega3Fat.toString(): return 1
            case NutritionalValue.omega6Fat.toString(): return 1
            case NutritionalValue.vitaminA.toString(): return 3
            case NutritionalValue.vitaminC.toString(): return 3
            case NutritionalValue.vitaminD.toString(): return 3
            case NutritionalValue.vitaminB1.toString(): return 3
            case NutritionalValue.vitaminB2.toString(): return 3
            case NutritionalValue.vitaminB3.toString(): return 3
            case NutritionalValue.vitaminB5.toString(): return 3
            case NutritionalValue.vitaminB6.toString(): return 3
            case NutritionalValue.vitaminB7.toString(): return 3
            case NutritionalValue.vitaminB9.toString(): return 3
            case NutritionalValue.vitaminB12.toString(): return 3
            case NutritionalValue.vitaminE.toString(): return 3
            case NutritionalValue.vitaminK.toString(): return 3
            case NutritionalValue.minerals.toString(): return 4
            case NutritionalValue.sodium.toString(): return 4
            case NutritionalValue.potassium.toString(): return 4
            case NutritionalValue.phosphorus.toString(): return 4
            case NutritionalValue.sulphur.toString(): return 4
            case NutritionalValue.chlorine.toString(): return 4
            case NutritionalValue.copper.toString(): return 4
            case NutritionalValue.manganese.toString(): return 4
            case NutritionalValue.fluorine.toString(): return 4
            case NutritionalValue.iodine.toString(): return 4
            case NutritionalValue.water.toString(): return 1
            case NutritionalValue.alcoholContent.toString(): return 1
            case NutritionalValue.nonessentialAminoAcids.toString(): return 5
            case NutritionalValue.essentialAminoAcids.toString(): return 5
            case NutritionalValue.cholesterol.toString(): return 1
            case NutritionalValue.starch.toString(): return 2
            case NutritionalValue.glycogen.toString(): return 2
            case NutritionalValue.lactose.toString(): return 2
            case NutritionalValue.maltose.toString(): return 2
            case NutritionalValue.sucrose.toString(): return 2
            case NutritionalValue.galactose.toString(): return 2
            case NutritionalValue.fructose.toString(): return 2
            case NutritionalValue.glucose.toString(): return 2
            case NutritionalValue.xylitol.toString(): return 2
            case NutritionalValue.sorbitol.toString(): return 2
            case NutritionalValue.mannitol.toString(): return 2
            case NutritionalValue.monosaccharides.toString(): return 2
            case NutritionalValue.disaccharides.toString(): return 2
            case NutritionalValue.oligosaccharides.toString(): return 2
            case NutritionalValue.polysaccharides.toString(): return 2
            case NutritionalValue.leucine.toString(): return 5
            case NutritionalValue.isoleucine.toString(): return 5
            case NutritionalValue.valine.toString(): return 5
            case NutritionalValue.organicAcids.toString(): return 1
            case NutritionalValue.polyols.toString(): return 1
            case NutritionalValue.iron.toString(): return 4
            case NutritionalValue.magnesium.toString(): return 4
            case NutritionalValue.zinc.toString(): return 4
            case NutritionalValue.calcium.toString(): return 4
            case NutritionalValue.waterSolubleFibre.toString(): return 1
            case NutritionalValue.waterInsolubleFibre.toString(): return 1
            case NutritionalValue.uricAcid.toString(): return 6
            case NutritionalValue.purine.toString(): return 6
            case NutritionalValue.linoleicAcid.toString(): return 6
            case NutritionalValue.alphaLinolenicAcid.toString(): return 6
            case NutritionalValue.arachidonicAcid.toString(): return 6
            case NutritionalValue.eicosapentaenoicAcid.toString(): return 6
            case NutritionalValue.docosahexaenoicAcid.toString(): return 6
            case NutritionalValue.salt.toString(): return 0
            case NutritionalValue.alcohol.toString(): return 1
        
            default: return null
        }
    }

    public static nutritionalValueGroupNames: string[]  = [
        "Nährstoffe",
        "Nährstoffdetails",
        "Kohlenhydratzusammensetzung",
        "Vitamine",
        "Mineralstoffe und Spurenelemente",
        "Aminosäuren",
        "Fettzusammensetzung",
    ];
    
    static getNutritionalValueUnit(nutritionalValue: string) {
        switch (NutritionalValue[nutritionalValue].toString()) {
            case NutritionalValue.calories.toString(): return "kcal"
            case NutritionalValue.alcoholContent.toString(): return "%"
            default: return "g"
        }
    }
    static getPrintableNutritionalValue(nutritionalValue: string, value: number, goal: number = undefined) {
        switch (NutritionalValue[nutritionalValue].toString()) {
            case NutritionalValue.calories.toString(): return BaseNutritionFact.getNutritionalValueTranslation(nutritionalValue) + ": " + value?.roundToPlaces(1) + " kcal"
            case NutritionalValue.alcoholContent.toString(): return BaseNutritionFact.getNutritionalValueTranslation(nutritionalValue) + ": " + value + " %"
            default: break;
        }
        if (value < 0.0001 && value > 0) {
            let goalString = (goal == undefined) ? BaseNutritionFact.getRecommendedDailyAllowance(nutritionalValue, "µg") : this.coachGoalFormatter((goal * 1000000)?.roundToPlaces(2), "µg")
            return BaseNutritionFact.getNutritionalValueTranslation(nutritionalValue) + ": " + (value * 1000000)?.roundToPlaces(2) + " µg" + goalString
        } else if (value < 0.1 && value > 0) {
            let goalString = (goal == undefined) ? BaseNutritionFact.getRecommendedDailyAllowance(nutritionalValue, "mg") : this.coachGoalFormatter((goal * 1000)?.roundToPlaces(2), "mg")
            return BaseNutritionFact.getNutritionalValueTranslation(nutritionalValue) + ": " + (value * 1000)?.roundToPlaces(2) + " mg" + goalString
        } else {
            let goalString = (goal == undefined) ? BaseNutritionFact.getRecommendedDailyAllowance(nutritionalValue, "mg") : this.coachGoalFormatter(goal, "g")
            return BaseNutritionFact.getNutritionalValueTranslation(nutritionalValue) + ": " + value?.roundToPlaces(2) + " g" + goalString
        }
    }


    static getDefaultUnit(nutritionalValue: string) {
        switch (NutritionalValue[nutritionalValue].toString()) {
            case NutritionalValue.calories.toString(): return "kcal"
            case NutritionalValue.vitaminA.toString(): return "mg"
            case NutritionalValue.vitaminC.toString(): return "mg"
            case NutritionalValue.vitaminD.toString(): return "mg"
            case NutritionalValue.vitaminB1.toString(): return "mg"
            case NutritionalValue.vitaminB2.toString(): return "mg"
            case NutritionalValue.vitaminB3.toString(): return "mg"
            case NutritionalValue.vitaminB5.toString(): return "mg"
            case NutritionalValue.vitaminB6.toString(): return "mg"
            case NutritionalValue.vitaminB7.toString(): return "mg"
            case NutritionalValue.vitaminB9.toString(): return "mg"
            case NutritionalValue.vitaminB12.toString(): return "mg"
            case NutritionalValue.vitaminE.toString(): return "mg"
            case NutritionalValue.vitaminK.toString(): return "mg"
            case NutritionalValue.minerals.toString(): return "mg"
            case NutritionalValue.sodium.toString(): return "mg"
            case NutritionalValue.potassium.toString(): return "mg"
            case NutritionalValue.phosphorus.toString(): return "mg"
            case NutritionalValue.sulphur.toString(): return "mg"
            case NutritionalValue.chlorine.toString(): return "mg"
            case NutritionalValue.copper.toString(): return "mg"
            case NutritionalValue.manganese.toString(): return "mg"
            case NutritionalValue.fluorine.toString(): return "mg"
            case NutritionalValue.iodine.toString(): return "mg"
            case NutritionalValue.alcoholContent.toString(): return "%"
            case NutritionalValue.iron.toString(): return "mg"
            case NutritionalValue.magnesium.toString(): return "mg"
            case NutritionalValue.zinc.toString(): return "mg"
            case NutritionalValue.calcium.toString(): return "mg"
        
            default: return "g"
        }
    }

    static getBestUnitForValue(value: number):string{
        if (value < 0.0001 && value > 0) {
            return "µg"
        } else if (value < 0.1 && value > 0) {
            return "mg"
        } else {
            return "g"
        }
    }
    static getConvertedValueByUnit(value: number, unit: string):number{
        if(value == null) {
            return null;
        }
        switch (unit) {
            case 'µg'.toString(): 
            {
                return value * 1000000;
            }
            case 'mg'.toString(): 
            {
                return value * 1000;
            }
            default: 
            {
                return value;
            }
        }
    }

    static convertBackByUnit(value: number, unit: string){
        var factor = this.getConvertedValueByUnit(1, unit);
        return value/factor;
    }


    static getRawRecommendedDailyAllowance(nutritionalValue: string, unit: string):number {
        var unitVal = 1;
        switch (unit) {
            case 'µg'.toString(): 
            {
                unitVal = 1000000
                break;
            }
            case 'mg'.toString(): 
            {
                unitVal = 1000
                break;
            }
            default: 
            {
                unitVal = 1
                break;
            }
        }
        switch (NutritionalValue[nutritionalValue].toString()) {
            case NutritionalValue.vitaminA.toString(): return 0.0008 * unitVal
            case NutritionalValue.vitaminC.toString(): return 0.08 * unitVal
            case NutritionalValue.vitaminD.toString(): return 0.000005 * unitVal
            case NutritionalValue.vitaminB1.toString(): return 0.0011 * unitVal
            case NutritionalValue.vitaminB2.toString(): return 0.0014 * unitVal
            case NutritionalValue.vitaminB3.toString(): return 0.016 * unitVal
            case NutritionalValue.vitaminB5.toString(): return 0.006 * unitVal
            case NutritionalValue.vitaminB6.toString(): return 0.0014 * unitVal
            case NutritionalValue.vitaminB7.toString(): return 0.00005 * unitVal
            case NutritionalValue.vitaminB9.toString(): return 0.0002 * unitVal
            case NutritionalValue.vitaminB12.toString(): return 0.0000025 * unitVal
            case NutritionalValue.vitaminE.toString(): return 0.012 * unitVal
            case NutritionalValue.vitaminK.toString(): return 0.000075 * unitVal
            case NutritionalValue.potassium.toString(): return 2 * unitVal
            case NutritionalValue.phosphorus.toString(): return 0.7 * unitVal
            case NutritionalValue.chlorine.toString(): return 0.8 * unitVal
            case NutritionalValue.copper.toString(): return 0.001 * unitVal
            case NutritionalValue.manganese.toString(): return 0.002 * unitVal
            case NutritionalValue.fluorine.toString(): return 0.0035 * unitVal
            case NutritionalValue.iodine.toString(): return 0.00015 * unitVal
            case NutritionalValue.iron.toString(): return 0.014 * unitVal
            case NutritionalValue.magnesium.toString(): return 0.375 * unitVal
            case NutritionalValue.zinc.toString(): return 0.01 * unitVal
            case NutritionalValue.calcium.toString(): return 0.8 * unitVal
        
            default: return undefined
        }
    }

    static getRecommendedDailyAllowance(nutritionalValue: string, unit: string) {
        let rawRda = BaseNutritionFact.getRawRecommendedDailyAllowance(nutritionalValue, unit);
        if(rawRda) return BaseNutritionFact.dailyAllowencesFormatter(rawRda, unit)

        return "";
    }

    static dailyAllowencesFormatter(value: number, unit: string){
        return  " / " + value + " " + unit + "*";
    }

    static coachGoalFormatter(value: number, unit: string){
        return  " / " + value + " " + unit + "**";
    }


    id: string
    barcode: string

    set nameDe(value: string) {
        this.nameTranslation.de = value
    }
    get nameDe() {
        return this.nameTranslation?.de
    }

    set nameEn(value: string) {
        this.nameTranslation.en = value
    }
    get nameEn() {
        return this.nameTranslation?.en
    }


    nameTranslation: LanguageDictionary<string> = new LanguageDictionary<string>()
    synonymsDe: string[]
    brands: string
    servingSizes: string
    parsedServingSizes: Serving[]
    unit: string
    region: string
    valid: boolean
    
    nutriScore: number
    nutriScoreGrade: string
    offCategory1: string
    offCategory2: string
    imageUrl: string

    date: Date
    lastChangeDate: Date
    baseNutritionFactId: string
    fromOFF: boolean
    detailed: boolean
    fromBLS: boolean

    calories: number
    carbohydrates: number
    protein: number
    fat: number
    sugar: number
    polyols: number
    fibre: number
    saturatedFat: number
    monounsaturatedFat: number
    polyunsaturatedFat: number
    transFat: number
    omega3Fat: number
    omega6Fat: number
    vitaminA: number
    vitaminC: number
    vitaminD: number
    vitaminB1: number
    vitaminB2: number
    vitaminB3: number
    vitaminB5: number
    vitaminB6: number
    vitaminB7: number
    vitaminB9: number
    vitaminB12: number
    vitaminE: number
    vitaminK: number
    iron: number
    magnesium: number
    zinc: number
    calcium: number
    sodium: number
    potassium: number
    phosphorus: number
    sulphur: number
    chlorine: number
    copper: number
    manganese: number
    fluorine: number
    iodine: number
    minerals: number
    organicAcids: number
    mannitol: number
    sorbitol: number
    xylitol: number
    glucose: number
    fructose: number
    galactose: number
    sucrose: number
    maltose: number
    lactose: number
    glycogen: number
    starch: number
    monosaccharides: number
    disaccharides: number
    oligosaccharides: number
    polysaccharides: number
    waterSolubleFibre: number
    waterInsolubleFibre: number
    essentialAminoAcids: number
    nonessentialAminoAcids: number
    leucine: number
    isoleucine: number
    valine: number
    uricAcid: number
    purine: number
    linoleicAcid: number
    alphaLinolenicAcid: number
    arachidonicAcid: number
    eicosapentaenoicAcid: number
    docosahexaenoicAcid: number
    cholesterol: number
    salt: number
    alcoholContent: number
    alcohol: number
    water: number

    // Unused:
    vitaminARetinol: number
    vitaminABetaCarotene: number
    vitaminEAlphaTocopherol: number
    vitaminB3Niacin: number
    lysine: number
    methionine: number
    cysteine: number
    phenylalanine: number
    tyrosine: number
    threonine: number
    tryptophan: number
    arginine: number
    histidine: number
    alanine: number
    asparagine: number
    glutamicAcid: number
    glycine: number
    proline: number
    serine: number

    constructor();
    constructor(init: BaseNutritionFact);
    constructor(init?: BaseNutritionFact) {
        this.barcode = init && init.barcode?.toString() || null
        this.brands = init && init.brands || null
        this.id = init && init.id || null
        this.imageUrl = init && init.imageUrl || null
        this.nameDe = init && init.nameDe || null
        this.nameEn = init && init.nameEn || null
        this.nameTranslation = init && init.nameTranslation && new LanguageDictionary<string>(init.nameTranslation.de, init.nameTranslation.en) || new LanguageDictionary<string>(this.nameDe, this.nameEn)
        this.synonymsDe = init && init.synonymsDe || null
        this.nutriScore = init && init.nutriScore != null && parseInt(init.nutriScore.toString()) >= 0 ? parseInt(init.nutriScore.toString()) : null
        this.nutriScoreGrade = init && init.nutriScoreGrade || null
        this.offCategory1 = init && init.offCategory1 || null
        this.offCategory2 = init && init.offCategory2 || null
        this.region = init && init.region || "de"
        this.servingSizes = init && init.servingSizes || null
        this.parsedServingSizes = Serving.servingsFromString(this.servingSizes)
        this.unit = init && init.unit || null
        this.valid = init && init.valid || false
        this.detailed = init && init.detailed || false
        this.fromBLS = init && init.fromBLS || false

        this.date = init && init.date || null
        this.lastChangeDate = init && init.lastChangeDate || null
        this.baseNutritionFactId = init && init.baseNutritionFactId || null
        this.fromOFF = false
    }

    getCarbohydrates() {
        return this.carbohydrates || 0
    }
    getProtein() {
        return this.protein || 0
    }
    getFat() {
        return this.fat || 0
    }
    getCalories() {
        return this.calories || 0
    }

    getUnit() {
        return this.unit || 'g'
    }

    getBrands(languageCode: string = 'de') {
        if (this.brands == 'Durchschnitt' && languageCode != 'de') return 'Average'
        return this.brands
    }

    getNutritionalValue(nutritionalValue: string): number {
        switch (NutritionalValue[nutritionalValue].toString()) {
            case NutritionalValue.calories.toString(): return this.calories
            case NutritionalValue.carbohydrates.toString(): return this.carbohydrates
            case NutritionalValue.protein.toString(): return this.protein
            case NutritionalValue.fat.toString(): return this.fat
            case NutritionalValue.sugar.toString(): return this.sugar
            case NutritionalValue.polyols.toString(): return this.polyols
            case NutritionalValue.fibre.toString(): return this.fibre
            case NutritionalValue.saturatedFat.toString(): return this.saturatedFat
            case NutritionalValue.monounsaturatedFat.toString(): return this.monounsaturatedFat
            case NutritionalValue.polyunsaturatedFat.toString(): return this.polyunsaturatedFat
            case NutritionalValue.transFat.toString(): return this.transFat
            case NutritionalValue.omega3Fat.toString(): return this.omega3Fat
            case NutritionalValue.omega6Fat.toString(): return this.omega6Fat
            case NutritionalValue.vitaminA.toString(): return this.vitaminA
            case NutritionalValue.vitaminC.toString(): return this.vitaminC
            case NutritionalValue.vitaminD.toString(): return this.vitaminD
            case NutritionalValue.vitaminB1.toString(): return this.vitaminB1
            case NutritionalValue.vitaminB2.toString(): return this.vitaminB2
            case NutritionalValue.vitaminB3.toString(): return this.vitaminB3
            case NutritionalValue.vitaminB5.toString(): return this.vitaminB5
            case NutritionalValue.vitaminB6.toString(): return this.vitaminB6
            case NutritionalValue.vitaminB7.toString(): return this.vitaminB7
            case NutritionalValue.vitaminB9.toString(): return this.vitaminB9
            case NutritionalValue.vitaminB12.toString(): return this.vitaminB12
            case NutritionalValue.vitaminE.toString(): return this.vitaminE
            case NutritionalValue.vitaminK.toString(): return this.vitaminK
            case NutritionalValue.iron.toString(): return this.iron
            case NutritionalValue.magnesium.toString(): return this.magnesium
            case NutritionalValue.zinc.toString(): return this.zinc
            case NutritionalValue.calcium.toString(): return this.calcium
            case NutritionalValue.sodium.toString(): return this.sodium
            case NutritionalValue.potassium.toString(): return this.potassium
            case NutritionalValue.phosphorus.toString(): return this.phosphorus
            case NutritionalValue.sulphur.toString(): return this.sulphur
            case NutritionalValue.chlorine.toString(): return this.chlorine
            case NutritionalValue.copper.toString(): return this.copper
            case NutritionalValue.manganese.toString(): return this.manganese
            case NutritionalValue.fluorine.toString(): return this.fluorine
            case NutritionalValue.iodine.toString(): return this.iodine
            case NutritionalValue.minerals.toString(): return this.minerals
            case NutritionalValue.organicAcids.toString(): return this.organicAcids
            case NutritionalValue.mannitol.toString(): return this.mannitol
            case NutritionalValue.sorbitol.toString(): return this.sorbitol
            case NutritionalValue.xylitol.toString(): return this.xylitol
            case NutritionalValue.glucose.toString(): return this.glucose
            case NutritionalValue.fructose.toString(): return this.fructose
            case NutritionalValue.galactose.toString(): return this.galactose
            case NutritionalValue.sucrose.toString(): return this.sucrose
            case NutritionalValue.maltose.toString(): return this.maltose
            case NutritionalValue.lactose.toString(): return this.lactose
            case NutritionalValue.glycogen.toString(): return this.glycogen
            case NutritionalValue.starch.toString(): return this.starch
            case NutritionalValue.monosaccharides.toString(): return this.monosaccharides
            case NutritionalValue.disaccharides.toString(): return this.disaccharides
            case NutritionalValue.oligosaccharides.toString(): return this.oligosaccharides
            case NutritionalValue.polysaccharides.toString(): return this.polysaccharides
            case NutritionalValue.waterSolubleFibre.toString(): return this.waterSolubleFibre
            case NutritionalValue.waterInsolubleFibre.toString(): return this.waterInsolubleFibre
            case NutritionalValue.essentialAminoAcids.toString(): return this.essentialAminoAcids
            case NutritionalValue.nonessentialAminoAcids.toString(): return this.nonessentialAminoAcids
            case NutritionalValue.leucine.toString(): return this.leucine
            case NutritionalValue.isoleucine.toString(): return this.isoleucine
            case NutritionalValue.valine.toString(): return this.valine
            case NutritionalValue.uricAcid.toString(): return this.uricAcid
            case NutritionalValue.purine.toString(): return this.purine
            case NutritionalValue.linoleicAcid.toString(): return this.linoleicAcid
            case NutritionalValue.alphaLinolenicAcid.toString(): return this.alphaLinolenicAcid
            case NutritionalValue.arachidonicAcid.toString(): return this.arachidonicAcid
            case NutritionalValue.eicosapentaenoicAcid.toString(): return this.eicosapentaenoicAcid
            case NutritionalValue.docosahexaenoicAcid.toString(): return this.docosahexaenoicAcid
            case NutritionalValue.cholesterol.toString(): return this.cholesterol
            case NutritionalValue.salt.toString(): return this.salt
            case NutritionalValue.alcoholContent.toString(): return this.alcoholContent
            case NutritionalValue.alcohol.toString(): return this.alcohol
            case NutritionalValue.water.toString(): return this.water

            case NutritionalValue.vitaminARetinol.toString(): return this.vitaminARetinol
            case NutritionalValue.vitaminABetaCarotene.toString(): return this.vitaminABetaCarotene
            case NutritionalValue.vitaminEAlphaTocopherol.toString(): return this.vitaminEAlphaTocopherol
            case NutritionalValue.vitaminB3Niacin.toString(): return this.vitaminB3Niacin
            case NutritionalValue.lysine.toString(): return this.lysine
            case NutritionalValue.methionine.toString(): return this.methionine
            case NutritionalValue.cysteine.toString(): return this.cysteine
            case NutritionalValue.phenylalanine.toString(): return this.phenylalanine
            case NutritionalValue.tyrosine.toString(): return this.tyrosine
            case NutritionalValue.threonine.toString(): return this.threonine
            case NutritionalValue.tryptophan.toString(): return this.tryptophan
            case NutritionalValue.arginine.toString(): return this.arginine
            case NutritionalValue.histidine.toString(): return this.histidine
            case NutritionalValue.alanine.toString(): return this.alanine
            case NutritionalValue.asparagine.toString(): return this.asparagine
            case NutritionalValue.glutamicAcid.toString(): return this.glutamicAcid
            case NutritionalValue.glycine.toString(): return this.glycine
            case NutritionalValue.proline.toString(): return this.proline
            case NutritionalValue.serine.toString(): return this.serine
        
            default: return null
        }
    }
    
    setNutritionalValue(nutritionalValue: string, value: number) {
        switch (NutritionalValue[nutritionalValue].toString()) {
            case NutritionalValue.calories.toString(): this.calories = value; break;
            case NutritionalValue.carbohydrates.toString(): this.carbohydrates = value; break;
            case NutritionalValue.protein.toString(): this.protein = value; break;
            case NutritionalValue.fat.toString(): this.fat = value; break;
            case NutritionalValue.sugar.toString(): this.sugar = value; break;
            case NutritionalValue.polyols.toString(): this.polyols = value; break;
            case NutritionalValue.fibre.toString(): this.fibre = value; break;
            case NutritionalValue.saturatedFat.toString(): this.saturatedFat = value; break;
            case NutritionalValue.monounsaturatedFat.toString(): this.monounsaturatedFat = value; break;
            case NutritionalValue.polyunsaturatedFat.toString(): this.polyunsaturatedFat = value; break;
            case NutritionalValue.transFat.toString(): this.transFat = value; break;
            case NutritionalValue.omega3Fat.toString(): this.omega3Fat = value; break;
            case NutritionalValue.omega6Fat.toString(): this.omega6Fat = value; break;
            case NutritionalValue.vitaminA.toString(): this.vitaminA = value; break;
            case NutritionalValue.vitaminC.toString(): this.vitaminC = value; break;
            case NutritionalValue.vitaminD.toString(): this.vitaminD = value; break;
            case NutritionalValue.vitaminB1.toString(): this.vitaminB1 = value; break;
            case NutritionalValue.vitaminB2.toString(): this.vitaminB2 = value; break;
            case NutritionalValue.vitaminB3.toString(): this.vitaminB3 = value; break;
            case NutritionalValue.vitaminB5.toString(): this.vitaminB5 = value; break;
            case NutritionalValue.vitaminB6.toString(): this.vitaminB6 = value; break;
            case NutritionalValue.vitaminB7.toString(): this.vitaminB7 = value; break;
            case NutritionalValue.vitaminB9.toString(): this.vitaminB9 = value; break;
            case NutritionalValue.vitaminB12.toString(): this.vitaminB12 = value; break;
            case NutritionalValue.vitaminE.toString(): this.vitaminE = value; break;
            case NutritionalValue.vitaminK.toString(): this.vitaminK = value; break;
            case NutritionalValue.iron.toString(): this.iron = value; break;
            case NutritionalValue.magnesium.toString(): this.magnesium = value; break;
            case NutritionalValue.zinc.toString(): this.zinc = value; break;
            case NutritionalValue.calcium.toString(): this.calcium = value; break;
            case NutritionalValue.sodium.toString(): this.sodium = value; break;
            case NutritionalValue.potassium.toString(): this.potassium = value; break;
            case NutritionalValue.phosphorus.toString(): this.phosphorus = value; break;
            case NutritionalValue.sulphur.toString(): this.sulphur = value; break;
            case NutritionalValue.chlorine.toString(): this.chlorine = value; break;
            case NutritionalValue.copper.toString(): this.copper = value; break;
            case NutritionalValue.manganese.toString(): this.manganese = value; break;
            case NutritionalValue.fluorine.toString(): this.fluorine = value; break;
            case NutritionalValue.iodine.toString(): this.iodine = value; break;
            case NutritionalValue.minerals.toString(): this.minerals = value; break;
            case NutritionalValue.organicAcids.toString(): this.organicAcids = value; break;
            case NutritionalValue.mannitol.toString(): this.mannitol = value; break;
            case NutritionalValue.sorbitol.toString(): this.sorbitol = value; break;
            case NutritionalValue.xylitol.toString(): this.xylitol = value; break;
            case NutritionalValue.glucose.toString(): this.glucose = value; break;
            case NutritionalValue.fructose.toString(): this.fructose = value; break;
            case NutritionalValue.galactose.toString(): this.galactose = value; break;
            case NutritionalValue.sucrose.toString(): this.sucrose = value; break;
            case NutritionalValue.maltose.toString(): this.maltose = value; break;
            case NutritionalValue.lactose.toString(): this.lactose = value; break;
            case NutritionalValue.glycogen.toString(): this.glycogen = value; break;
            case NutritionalValue.starch.toString(): this.starch = value; break;
            case NutritionalValue.monosaccharides.toString(): this.monosaccharides = value; break;
            case NutritionalValue.disaccharides.toString(): this.disaccharides = value; break;
            case NutritionalValue.oligosaccharides.toString(): this.oligosaccharides = value; break;
            case NutritionalValue.polysaccharides.toString(): this.polysaccharides = value; break;
            case NutritionalValue.waterSolubleFibre.toString(): this.waterSolubleFibre = value; break;
            case NutritionalValue.waterInsolubleFibre.toString(): this.waterInsolubleFibre = value; break;
            case NutritionalValue.essentialAminoAcids.toString(): this.essentialAminoAcids = value; break;
            case NutritionalValue.nonessentialAminoAcids.toString(): this.nonessentialAminoAcids = value; break;
            case NutritionalValue.leucine.toString(): this.leucine = value; break;
            case NutritionalValue.isoleucine.toString(): this.isoleucine = value; break;
            case NutritionalValue.valine.toString(): this.valine = value; break;
            case NutritionalValue.uricAcid.toString(): this.uricAcid = value; break;
            case NutritionalValue.purine.toString(): this.purine = value; break;
            case NutritionalValue.linoleicAcid.toString(): this.linoleicAcid = value; break;
            case NutritionalValue.alphaLinolenicAcid.toString(): this.alphaLinolenicAcid = value; break;
            case NutritionalValue.arachidonicAcid.toString(): this.arachidonicAcid = value; break;
            case NutritionalValue.eicosapentaenoicAcid.toString(): this.eicosapentaenoicAcid = value; break;
            case NutritionalValue.docosahexaenoicAcid.toString(): this.docosahexaenoicAcid = value; break;
            case NutritionalValue.cholesterol.toString(): this.cholesterol = value; break;
            case NutritionalValue.salt.toString(): this.salt = value; break;
            case NutritionalValue.alcoholContent.toString(): this.alcoholContent = value; break;
            case NutritionalValue.alcohol.toString(): this.alcohol = value; break;
            case NutritionalValue.water.toString(): this.water = value; break;

            case NutritionalValue.vitaminARetinol.toString(): this.vitaminARetinol = value; break;
            case NutritionalValue.vitaminABetaCarotene.toString(): this.vitaminABetaCarotene = value; break;
            case NutritionalValue.vitaminEAlphaTocopherol.toString(): this.vitaminEAlphaTocopherol = value; break;
            case NutritionalValue.vitaminB3Niacin.toString(): this.vitaminB3Niacin = value; break;
            case NutritionalValue.lysine.toString(): this.lysine = value; break;
            case NutritionalValue.methionine.toString(): this.methionine = value; break;
            case NutritionalValue.cysteine.toString(): this.cysteine = value; break;
            case NutritionalValue.phenylalanine.toString(): this.phenylalanine = value; break;
            case NutritionalValue.tyrosine.toString(): this.tyrosine = value; break;
            case NutritionalValue.threonine.toString(): this.threonine = value; break;
            case NutritionalValue.tryptophan.toString(): this.tryptophan = value; break;
            case NutritionalValue.arginine.toString(): this.arginine = value; break;
            case NutritionalValue.histidine.toString(): this.histidine = value; break;
            case NutritionalValue.alanine.toString(): this.alanine = value; break;
            case NutritionalValue.asparagine.toString(): this.asparagine = value; break;
            case NutritionalValue.glutamicAcid.toString(): this.glutamicAcid = value; break;
            case NutritionalValue.glycine.toString(): this.glycine = value; break;
            case NutritionalValue.proline.toString(): this.proline = value; break;
            case NutritionalValue.serine.toString(): this.serine = value; break;
            
            default: break
        }
    }

}