import { Component, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DropdownItem } from 'src/app/model/automatic-push-notification.model';
import { ILanguageDictionary } from 'src/app/model/languagedictionary.model';
import { TrainingPlanTemplateFolder } from 'src/app/model/training-plan-template-folder.model';
import { User } from 'src/app/model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { LanguageService } from 'src/app/services/language.service';
import { SingleExerciseComponent } from '../single-exercise/single-exercise.component';
import { TrainingPlan, TrainingPlanAccess } from 'src/app/model/training-plan.model';
import { TrainingPlanTemplatesSelectionDialogComponent } from '../training-plan-templates-selection-dialog/training-plan-templates-selection-dialog.component';
import { TrainingService } from 'src/app/services/training.service';
import { ImageEditorComponent } from 'src/app/utilities/image-editor/image-editor.component';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-training-plan-template-folder-editor',
  templateUrl: './training-plan-template-folder-editor.component.html',
  styleUrls: ['./training-plan-template-folder-editor.component.css']
})
export class TrainingPlanTemplateFolderEditorComponent {

  public selectedTrainingPlanTemplateFolder: TrainingPlanTemplateFolder;
  public filteredTrainingPlanTemplates: TrainingPlan[] = [];

  public newTrainingPlanTemplates: TrainingPlan[] = [];
  public removedTrainingPlanTemplates: TrainingPlan[] = [];

  public allTrainingPlanTemplates: TrainingPlan[] = [];

  public changedTrainingPlanTemplates: TrainingPlan[] = [];
  
  private newThumbnail: File = null;
  public thumbnailImageSrc: any;
  private removeThumbnail: boolean = false;

  public selectableGroupNames: DropdownItem[] = [];
  public selectableClients: DropdownItem[] = [];
  public templateSharingEnabled: boolean = false;
  
  public selectableTrainingPlanAccess: string[] = Object.values(TrainingPlanAccess).filter(value => typeof value === 'string');


  constructor(public dialogRef: MatDialogRef<TrainingPlanTemplateFolderEditorComponent>, @Inject(MAT_DIALOG_DATA) private data: {selectedTrainingPlanTemplateFolder: TrainingPlanTemplateFolder}, private toastr: ToastrService, public dialog: MatDialog, public languageService: LanguageService, private userService: FirestoreService, private ngZone: NgZone, public trainingService: TrainingService) {
    this.selectedTrainingPlanTemplateFolder = data.selectedTrainingPlanTemplateFolder;
    console.log(this.selectedTrainingPlanTemplateFolder)
    this.thumbnailImageSrc = this.selectedTrainingPlanTemplateFolder.imageDownloadURL || "";
    this.selectableGroupNames = [new DropdownItem('', 'Alle', this.selectedTrainingPlanTemplateFolder.assignedGroupNames.includes('Alle'))]
    this.userService.getAccessibleClients().forEach(client => {
      this.selectableClients.push(new DropdownItem(client.uid, client.getName(), this.selectedTrainingPlanTemplateFolder.assignedUids.includes(client.uid)))
    });
    this.userService.getClientGroups().forEach(group => {
      this.selectableGroupNames.push(new DropdownItem('', group, this.selectedTrainingPlanTemplateFolder.assignedGroupNames.includes(group)))
    });
    this.refreshFilteredTrainingPlanTemplates();
    this.templateSharingEnabled = this.userService.getLoggedInUser()?.coach?.trainingPlanTemplateSharingEnabled || false;
  }

  async refreshFilteredTrainingPlanTemplates(){
    this.filteredTrainingPlanTemplates = (await this.trainingService.getTrainingPlanTemplates()).filter(x => x.folderIds?.includes(this.selectedTrainingPlanTemplateFolder.id) && !this.removedTrainingPlanTemplates.includes(x)).concat(this.newTrainingPlanTemplates);
  }

  async onAddTrainingPlanTemplates(){
    // this.newTrainingPlanTemplates.push(new TrainingPlan());
    this.dialog.open(TrainingPlanTemplatesSelectionDialogComponent, {data: {availableTemplates: (await this.trainingService.getTrainingPlanTemplates()).filter(x => !x.folderIds?.includes(this.selectedTrainingPlanTemplateFolder.id) && !this.newTrainingPlanTemplates.map(x => x.id).includes(x.id)), availableTrainingPlanTemplateFolders: (await this.trainingService.getTrainingPlanTemplateFolders())}, width: '1000px', autoFocus: false}).afterClosed().subscribe(result => {
      if (result?.shouldTake){
        console.log(result)
        result.selectedTemplates.forEach(selectedTemplate => {
          let template = selectedTemplate?.clone();
          if(template.folderIds == null) template.folderIds = [];
          // if(!template.folderIds?.includes(this.selectedTrainingPlanTemplateFolder.id)){
          //   template.folderIds.push(this.selectedTrainingPlanTemplateFolder.id);
          // }
          if (!this.newTrainingPlanTemplates.includes(template)){
            this.newTrainingPlanTemplates.push(template);
            this.refreshFilteredTrainingPlanTemplates();
          }
          if(this.removedTrainingPlanTemplates.find(x => x.id == template.id)){
            this.removedTrainingPlanTemplates = this.removedTrainingPlanTemplates.filter(x => x.id != template.id);
          }
        });
        this.refreshFilteredTrainingPlanTemplates();
      }
    });
  }

  onRemoveTrainingPlanTemplate(template: TrainingPlan){
    // template.folderIds = template.folderIds.filter(x => x != this.selectedTrainingPlanTemplateFolder.id);
    if(this.newTrainingPlanTemplates.find(x => x.id == template.id)){
      this.newTrainingPlanTemplates = this.newTrainingPlanTemplates.filter(x => x.id != template.id);
    }
    else {
      this.removedTrainingPlanTemplates.push(template);
    }
    this.refreshFilteredTrainingPlanTemplates();
  }

  onSaveTrainingPlanTemplateFolder(){
    if (this.selectedTrainingPlanTemplateFolder.name == null || this.selectedTrainingPlanTemplateFolder.name.GetValue(this.languageService.selectedLanguageCode) == ''){
      this.toastr.error('Bitte gib einen Namen ein', 'Fehler')
      return;
    }
    this.dialogRef.close({save: true, newThumbnail: this.newThumbnail, removeThumbnail: this.removeThumbnail, newTemplates: this.newTrainingPlanTemplates, removedTemplates: this.removedTrainingPlanTemplates});
  }

  onCancelTrainingPlanTemplateFolder(){
    this.dialogRef.close();
  }

  onDeleteTrainingPlanTemplateFolder(){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du diesen Ordner wirklich löschen?', title: 'Ordner löschen' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dialogRef.close({delete: true});
      }
    });
  }


  setLanguageDictionaryValue(trainingPlanTemplateFolder, propertyName: string, value: string){
    let languageDictionary = trainingPlanTemplateFolder[propertyName] as ILanguageDictionary<any>;
    languageDictionary.SetValue(this.languageService.selectedLanguageCode, value);
    trainingPlanTemplateFolder[propertyName] = languageDictionary;
  }

  canAccessUser(user: User) {
    return this.userService.getLoggedInUser().coach.canAccessUser(user)
  }
  canAccessGroup(group: string) {
    return this.userService.getLoggedInUser().coach.canAccessClientGroup(group)
  }

  getPrintableAssignments(folder: TrainingPlanTemplateFolder):string{
    var checkedElements = '';
    if(folder.assignedGroupNames != null){
      if (folder.assignedGroupNames?.includes('Alle')) return 'Alle'
      folder.assignedGroupNames.forEach(name => {
        if(this.getSelectableClientGroups()?.includes(name)){
          if(checkedElements.length > 0){
            checkedElements = checkedElements.concat(', ')
          }
          checkedElements = checkedElements.concat(name);
        }
      });
    }
    if (this.selectableClients != null){
      this.selectableClients.forEach(element => {
        if(folder.assignedUids?.includes(element.uid)){
          if(checkedElements.length > 0){
            checkedElements = checkedElements.concat(', ')
          }
          checkedElements = checkedElements.concat(element.name);
        }
      });
    }
    return checkedElements;
  }

  areAllUsersTargeted() {
    return this.selectedTrainingPlanTemplateFolder.assignedGroupNames?.includes('Alle')
  }

  getSelectableClients() {
    return this.userService.getAccessibleClients()
  }

  getSelectableClientGroups() {
    return this.userService.getClientGroups()
  }

  onTargetSelectionChangedUser(user: User){
    if (!this.canAccessUser(user)) return
    if (this.selectedTrainingPlanTemplateFolder.assignedUids.includes(user.uid)) {
      this.selectedTrainingPlanTemplateFolder.assignedUids.forEach( (item, index) => {
        if (item == user.uid) this.selectedTrainingPlanTemplateFolder.assignedUids.splice(index, 1)
      })
    } else {
      this.selectedTrainingPlanTemplateFolder.assignedUids.push(user.uid)
    }
  }

  onTargetSelectionChangedGroup(group: string){
    if (!this.canAccessGroup(group)) return
    if (this.selectedTrainingPlanTemplateFolder.assignedGroupNames.includes(group)) {
      this.selectedTrainingPlanTemplateFolder.assignedGroupNames.forEach( (item, index) => {
        if (item == group) this.selectedTrainingPlanTemplateFolder.assignedGroupNames.splice(index, 1)
      })
    } else {
      this.selectedTrainingPlanTemplateFolder.assignedGroupNames.push(group)
    }
  }

  addTrainingPlanTemplate(){
    
  }

  
  onDragOver(event) {
    event.preventDefault();
  }

  onRemoveThumbnail(){
    this.removeThumbnail = true;
    this.thumbnailImageSrc = null;
    this.newThumbnail = null;
    // this.hasChanges = true;
  }
  
  openThumbnailDialog() {
    (document.querySelector('#thumbnail-input') as HTMLElement).click()
  }
  
  onDropThumbnail(event) {
    event.preventDefault();
    if (event.dataTransfer.files?.length > 0) {
      var file = event.dataTransfer.files[0]
      if (!SingleExerciseComponent.checkUploadFile(file, 50000000, ["jpg", "jpeg", "png", "gif"], this.toastr)) return
      this.convertThumbnail(file);
    }
  }

  uploadThumbnail(e) {
    if (e.target.files && e.target.files[0]) {
      if (!SingleExerciseComponent.checkUploadFile(e.target.files[0], 50000000, ["jpg", "jpeg", "png"], this.toastr)) return;
      this.convertThumbnail(e.target.files[0])
    }
  }

  convertThumbnail(thumbnail: File) {
    const dialogRef = this.dialog.open(ImageEditorComponent, {
      data: { imageFile: thumbnail, aspectRatio: 1, maintainAspectRatio: true, containWithinAspectRatio: true, maxDimension: 720 },
      width: '1000px',
    });
    dialogRef.afterClosed().subscribe(async result => {
      if(result?.croppedImage) {
        this.thumbnailImageSrc = await this.blobToBase64(result.croppedImage);
        this.newThumbnail = result.croppedImage;
        this.removeThumbnail = false;
        // this.hasChanges = true;
      }
    });
  }

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
}
