import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../auth/auth.service';
import { Coach } from '../../model/coach.model';
import { FirestoreService } from '../../services/firestore.service';
import { UtilityService } from '../../services/utility.service';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'app-settings-base',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsBaseComponent implements OnInit {

  constructor(private router: Router, public userService: FirestoreService, public utilityService: UtilityService, private toastr: ToastrService, private authService: AuthService, private route: ActivatedRoute, private imageCompressor: NgxImageCompressService) { }

  public environment = environment;

  ngOnInit(): void {
  }

}
