import { TrainingPlan } from './../../model/training-plan.model';
import { TrainingService } from './../../services/training.service';
import { SingleExerciseComponent } from './../single-exercise/single-exercise.component';
import { MuscleInformation, ExerciseTypeTranslation, ITrainingExercise, ITrainingExerciseOverwrite, TrainingExerciseOverwrite, MergedTrainingExercise, TrainingExercise } from './../../model/training-exercise';
import { LanguageDictionary } from 'src/app/model/languagedictionary.model';
import { Component, OnInit } from '@angular/core';
import {MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { FirestoreService } from 'src/app/services/firestore.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-training-database',
  templateUrl: './training-database.component.html',
  styleUrls: ['./training-database.component.css']
})
export class TrainingDatabaseComponent implements OnInit {

  selectedTrainingExercise: MergedTrainingExercise;
  spinnerText: string = "";

  constructor(public dialog: MatDialog, private spinner: NgxSpinnerService, public userService: FirestoreService, public trainingService:TrainingService, public utilityService: UtilityService) { }

  ngOnInit(): void {}

  onAdministratorModeChanged(value:boolean){
    if(this.trainingService.CanEnterAdministratorEditMode == true){
      this.trainingService.AdministratorEditModeActivated = value
    }
  }



  onCreateExercise(){
    this.selectedTrainingExercise = new MergedTrainingExercise();
    this.selectedTrainingExercise.creatorUid = this.trainingService.UserUid
    const dialogRef = this.dialog.open(SingleExerciseComponent, { data: { selectedExercise: this.selectedTrainingExercise, editExerciseDisabled: this.trainingService.UserUid != this.selectedTrainingExercise.creatorUid, overwriteExerciseDisabled: this.trainingService.AdministratorEditModeActivated}, width: '1000px'})
    dialogRef.afterClosed().subscribe(async result => {
      if(result){
        if(result.shouldSave){
          this.spinner.show()
          var exercise = result.exercise as MergedTrainingExercise
          if(exercise) 
          {
            if(result.shouldDelete) 
            {
              exercise.deleted = true
              this.selectedTrainingExercise = null
            }
            else {
              this.trainingService.saveOrUpdateMergedTrainingExercise(exercise, result.newThumbnail, result.newVideo, result.newExercisePreview, result.deletedVideo)
            }
          }
          this.spinner.hide()
        }
      }
    })
  }

  onEditExercise(exercise:MergedTrainingExercise){
    if(exercise){
      this.selectedTrainingExercise = exercise.clone()
      const dialogRef = this.dialog.open(SingleExerciseComponent, { data: { selectedExercise: this.selectedTrainingExercise, editExerciseDisabled: this.trainingService.UserUid != this.selectedTrainingExercise.creatorUid, overwriteExerciseDisabled: this.trainingService.AdministratorEditModeActivated}, width: '1000px', autoFocus: false, restoreFocus: false})
      dialogRef.afterClosed().subscribe(async result => {
        if(result){
          if(result.shouldSave){
            this.spinner.show()
            var exercise = result.exercise as MergedTrainingExercise
            if(exercise) 
            {
              if(result.shouldDelete) {
                if(exercise.creatorUid ==  this.trainingService.UserUid ){
                  exercise.trainingExercise.deleted = true;
                }
                else {
                  exercise.trainingExerciseOverwrite.deleted = true;
                  exercise.trainingExerciseOverwrite.hiddenExercise = true;
                }
              }
              this.trainingService.saveOrUpdateMergedTrainingExercise(exercise, result.newThumbnail, result.newVideo, result.newExercisePreview, result.deletedVideo)
            }
            this.spinner.hide()
          }
        }
      })
    }
  }

}
