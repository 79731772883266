<div class="dialog-topbar">
    <h3>{{title}}</h3>
</div>
<div class="d-inline-block wrapper-selection">
    <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{selectedDropdownItem}}</button>
    <div class="dropdown-menu" aria-labelledby="dropdownBodyDataGraphTimeRange">
        <div *ngFor="let item of availableDropdownItems">
            <button class="dropdown-item" type="button" (click)="onSelectionChanged(item)">{{item}}</button>
        </div>
    </div>
</div>

<mat-dialog-actions>
    <div class="actions">
        <button class="button-filled small light float-right" (click)="onCancel()">{{cancelButtonText}}</button>
        <button class="button-filled small light float-right marginright-25" [disabled]="!canSubmit()" (click)="onSubmit()">{{submitButtonText}}</button>
    </div>
</mat-dialog-actions>