<div class="dialog-topbar">
    <button class="button-filled small create-Exercise" (click)="onTakeSelection()">Übernehmen</button>
    <div class="icon-button float-right" (click)="onCancelSelection()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        <ng-container *ngIf="!utilityService.onSmallDisplay()">Schließen</ng-container>
    </div>
</div>
<div class="dialog-content-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="spacer-10"></div>
            <input id="search-input" class="input wide" (input)="onSearchInputChanged($event.target.value)" placeholder="Check-Ins durchsuchen" type="text">
            <div class="icon-button deletesearch-icon delete-recipesearch" (click)="onDeleteSearchInput()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </div>
            <div class="spacer-25"></div>
        </div>
    </div>
    <div>
        <div class="spacer-10"></div>
        <div class="col-12 justify-content-center">
            <div class="row layout-box sub-box link" *ngFor="let selectable of filteredSelectableQuestionaires"  (click)="selectQuestionaire(selectable)">
                <div class="checkbox-wrapper d-inline-block">
                    <input class="input checkbox" type="checkbox" [(ngModel)]="selectable.IsSelected">
                </div>
                <div class="col">
                    <div class="row">
                        <div class="col">
                            <b>{{selectable.Item.internalName}}</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            {{selectable.Item.name}}
                        </div>
                    </div>
                </div>
                <div class="col only-desktop">
                    {{getTriggerString(selectable.Item)}}
                </div>
                <div class="col only-desktop">
                    {{getRecipientsString(selectable.Item)}}
                </div>
            </div>
        </div>
    </div>
</div>