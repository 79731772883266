import { ImageCroppedEvent } from './../../../../node_modules/ngx-image-cropper/lib/interfaces/image-cropped-event.interface.d';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';
import { Dimensions, ImageTransform } from 'ngx-image-cropper';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import heic2any from "heic2any";

@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.css']
})
export class ImageEditorComponent {
  public imageFile: File;
  public maintainAspectRatio: boolean = true;
  public aspectRatio: number = 1;
  public containWithinAspectRatio: boolean = false;
  public maxDimension: number = null

  public scale = 1;
  public transform: ImageTransform = {
    translateUnit: 'px'
  };
  public canvasRotation = 0;
  public rotation?: number;
  private translateH = 0;
  private translateV = 0;

  public croppedImage: Blob;
  public croppedImageDataUrl: string;

  public colorLightGrey = getComputedStyle(document.documentElement).getPropertyValue('--colorLightGrey') || '#F6F8FC';

  constructor(public dialogRef: MatDialogRef<ImageEditorComponent>, @Inject(MAT_DIALOG_DATA) private data: { imageFile: File, aspectRatio: number, maintainAspectRatio: boolean, containWithinAspectRatio: boolean, maxDimension: number }, private spinner: NgxSpinnerService, private toastr: ToastrService, private imageCompressor: NgxImageCompressService) 
  {
    this.spinner.show("image-editor-spinner");
    // this.imageFile = data.imageFile;
    this.convertAndSetImageFile(data.imageFile);
    this.aspectRatio = data.aspectRatio || 1;
    this.maintainAspectRatio = data.maintainAspectRatio ?? true;
    this.containWithinAspectRatio = data.containWithinAspectRatio ?? false;
    this.maxDimension = data.maxDimension || null;
  }

  async convertAndSetImageFile(file: File) {
    try{
      if(file.name?.toLowerCase()?.endsWith('.heic') || file.name?.toLowerCase()?.endsWith('.heif')) {
        let blob: Blob = file as Blob;
        let blobResult = await heic2any({blob,toType:"image/jpeg",quality:0.9});
        let newName = file.name.replace(/\.[^/.]+$/, ".jpg");
        let convertedFile = this.blobToFile(blobResult as Blob,newName);
        this.compressAndSetImageFile(convertedFile);
      }
      else {
        this.compressAndSetImageFile(file);
      }
    }
    catch(ex){
      console.error(ex);
      this.toastr.error("Bild konnte nicht konvertiert werden.", "Fehler")
    }
  }

  compressAndSetImageFile(file: File) {
    var image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = () => {
          var maxDimension = Math.max(image.width, image.height)
          var scaleRatio = Math.min(100, 1080 / maxDimension * 100)
          this.imageCompressor.compressFile(image.src, DOC_ORIENTATION.Up, scaleRatio, 100, this.maxDimension, this.maxDimension).then((compressedFile) => {
            var compressedImage = this.dataURLtoFile(compressedFile, file.name);
            this.imageFile = compressedImage;
          }).catch((err) => {
            this.toastr.error("Bild konnte nicht komprimiert werden.", "Fehler")
            console.log(err)
            this.spinner.hide()
          });
      }
  }


  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.blob
    this.croppedImageDataUrl = event.base64;
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }

  blobToBase64(blob): Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }

  private blobToFile = (theBlob: Blob, fileName:string): File => {
    let b: any = theBlob;
    b.lastModified = new Date();
    b.name = fileName;
    return <File>theBlob;
  }

  compressAndClose(file: File) {
    var image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      var maxDimension = Math.max(image.width, image.height)
      var scaleRatio = Math.min(100, 1080 / maxDimension * 100)
      this.spinner.show()
      this.imageCompressor.compressFile(image.src, DOC_ORIENTATION.Up, scaleRatio, 60, this.maxDimension, this.maxDimension).then((compressedFile) => {
        var compressedImage = this.dataURLtoFile(compressedFile, file.name);
        this.dialogRef.close({croppedImage: compressedImage});
        this.spinner.hide()
      }).catch((err) => {
        console.log(err)
        this.spinner.hide()
      });
    }
  }

  onSkipCrop() {
    this.dialogRef.close({croppedImage: this.imageFile});
  }

  onFinishCrop() {
    var blob = this.croppedImage
    var fileType = this.imageFile.name.toLowerCase().endsWith('.png') ? "image/png" : "image/jpeg"
    let imageFile = new File([blob], this.imageFile.name, { type: fileType });
    this.compressAndClose(imageFile);
  }

  onCancelCrop() {
    this.dialogRef.close();
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    this.spinner.hide("image-editor-spinner");
  }

  loadImageFailed() {
    console.error('Load image failed');
    this.spinner.hide("image-editor-spinner");
    this.toastr.error("Bild konnte nicht geladen werden.", "Fehler")
  }

  rotateRight() {
    try{
      this.spinner.show("image-editor-spinner");
      setTimeout(() => {
        this.canvasRotation++;
        this.flipAfterRotate();
      });
    }
    catch(e){
      console.log(e)
      this.spinner.hide("image-editor-spinner");
    }
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
    this.translateH = 0;
    this.translateV = 0;
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {
      translateUnit: 'px'
    };
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
}
