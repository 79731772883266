import { MatDividerModule } from '@angular/material/divider';
import { Component, Directive, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { TrainingVariable } from 'src/app/model/training-variables.model';
import { User } from 'src/app/model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TrainingPlanEditorComponent } from '../training-plan-editor/training-plan-editor.component';
import { SetParameter, TrainingPlan } from 'src/app/model/training-plan.model';
import { LanguageService } from 'src/app/services/language.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { TrainingService } from 'src/app/services/training.service';
import { CardioZone, CardioZoneGroup } from 'src/app/model/cardio-zone-group.model';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-training-variable-editor-dialog',
  templateUrl: './training-variable-editor-dialog.component.html',
  styleUrls: ['./training-variable-editor-dialog.component.css']
})
export class TrainingVariableEditorDialogComponent {

  public user: User;
  public trainingVariables: TrainingVariable[];
  // public changedTrainingVariables: TrainingVariable[] = [];
  public hasChanges: boolean = false;

  public SetParameter = SetParameter;

  public rangePattern: string = "^[1-9][0-9]?[0-9]?(-[1-9][0-9]?[0-9]?)?$";
  public namePattern: string = "^[a-zA-Z0-9_]*$";

  public paceRangePattern: string = "^(((60|[0-5])?[0-9]):[0-5][0-9])(-((60|[0-5])?[0-9]):[0-5][0-9])?$";

  public allAvailableForeignVariables: TrainingVariable[] = [];

  public colorLightGrey = getComputedStyle(document.documentElement).getPropertyValue('--colorLightGrey') || '#F6F8FC';

  public trainingVariableEditorDialogType: TrainingVariableEditorDialogType;

  public TrainingVariableEditorDialogType = TrainingVariableEditorDialogType;

  public showTrainingVariablesTab: boolean = true;

  public cardioZoneGroups: CardioZoneGroup[] = [];
  public globalCardioZones: CardioZone[] = [];

  public TrainingVariableEditorDialogTab = TrainingVariableEditorDialogTab;
  public enabledTrainingVariableEditorDialogTab: TrainingVariableEditorDialogTab = TrainingVariableEditorDialogTab.all;

  constructor(public dialogRef: MatDialogRef<TrainingVariableEditorDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { user: User, trainingVariables: TrainingVariable[], trainingVariableEditorDialogType: TrainingVariableEditorDialogType, foreignVariables: TrainingVariable[], enabledTrainingVariableEditorDialogTab: TrainingVariableEditorDialogTab }, public userService: FirestoreService, private spinner: NgxSpinnerService, private toastr: ToastrService, private languageService: LanguageService, private dialog: MatDialog, private trainingService: TrainingService, public utilityService: UtilityService) {
    this.user = data.user;
    this.trainingVariables = data.trainingVariables;
    this.trainingVariableEditorDialogType = data.trainingVariableEditorDialogType;
    this.enabledTrainingVariableEditorDialogTab = data.enabledTrainingVariableEditorDialogTab ?? TrainingVariableEditorDialogTab.all;

    if (this.enabledTrainingVariableEditorDialogTab == TrainingVariableEditorDialogTab.variables) {
      this.showTrainingVariablesTab = true;
    }
    else if (this.enabledTrainingVariableEditorDialogTab == TrainingVariableEditorDialogTab.cardioZones) {
      this.showTrainingVariablesTab = false;
    }

    if (this.user) {
      this.cardioZoneGroups = this.user.cardioZoneGroups?.map(x => x.clone()) ?? [];
    }
    this.allAvailableForeignVariables = data.foreignVariables;
    
    this.globalCardioZones = this.userService.getLoggedInUser().trainingSettingsLicenceHolder.cardioZones.map(x => x.clone());
  }

  showVariables(showVariables: boolean) {
    this.showTrainingVariablesTab = showVariables;
  }

  getChangesHintText() {
    switch (this.trainingVariableEditorDialogType) {
      case TrainingVariableEditorDialogType.global:
        return " Hierbei handelt es sich um Variablen, die übergreifend über alle Coachees hinweg genutzt werden können. Du kannst sie innerhalb eines Coachees auch nochmal individualisieren."
      case TrainingVariableEditorDialogType.user:
        return " Hierbei handelt es sich um Variablen, die nur für diesen Coachee gelten und in neuen Trainingsplänen genutzt werden können. Um alte Pläne anzupassen, ändere die Variablen innerhalb eines Plans."
      case TrainingVariableEditorDialogType.plan:
      case TrainingVariableEditorDialogType.template:
        return " Hierbei handelt es sich um Variablen, die nur für diesen Plan gelten. Wenn du diese anpasst, wird auch der Plan neu berechnet."
      default:
        break;
    }
    return '';
  }

  getHeartRate(trainingVariable: TrainingVariable) {
    if (trainingVariable.heartRate) {
      return trainingVariable.heartRate;
    }
    else if (trainingVariable.minHeartRate && trainingVariable.maxHeartRate) {
      return trainingVariable.minHeartRate + '-' + trainingVariable.maxHeartRate
    }

    return "";
  }

  setHeartRate(trainingVariable: TrainingVariable, value: string) {
    if (value == null || value.length == 0) {
      trainingVariable.heartRate = undefined;
      trainingVariable.minHeartRate = undefined;
      trainingVariable.maxHeartRate = undefined;
    }
    if (value.match(this.rangePattern)) {
      if (value.includes('-')) {
        let splitted = value.split('-');
        if (splitted.length > 1) {
          let low = parseInt(splitted[0])
          let high = parseInt(splitted[1])

          if (low && high) {
            trainingVariable.minHeartRate = low;
            trainingVariable.maxHeartRate = high;
            trainingVariable.heartRate = undefined;
          }
        }
      }
      else {
        let rate = parseInt(value);
        if (rate) {
          trainingVariable.heartRate = rate;
          trainingVariable.minHeartRate = undefined;
          trainingVariable.maxHeartRate = undefined;
        }
      }
    }
    this.hasChanges = true;
  }

  setWeight(trainingVariable: TrainingVariable, value: string) {
    if (value == null || value.length == 0) {
      trainingVariable.weight = undefined;
    }
    let weight = parseFloat(value);
    if (weight) {
      trainingVariable.weight = weight;
    }
    this.hasChanges = true;
  }

  getPace(trainingVariable: TrainingVariable, isPace500: boolean) {
    let parameter = isPace500 ? "pace500" : "pace";
    let minParameter = isPace500 ? "minPace500" : "minPace";
    let maxParameter = isPace500 ? "maxPace500" : "maxPace";
    if (trainingVariable[parameter]) {
      return new Date(0, 0, 0, 0, 0, trainingVariable[parameter], 0)?.toTimeString()?.slice(3, 8);
    }
    else if (trainingVariable[minParameter] && trainingVariable[maxParameter]) {
      let minSecondsDate = trainingVariable[minParameter] ? new Date(0, 0, 0, 0, 0, trainingVariable[minParameter], 0)?.toTimeString()?.slice(3, 8) : "00:00";
      let maxSecondsDate = trainingVariable[maxParameter] ? new Date(0, 0, 0, 0, 0, trainingVariable[maxParameter], 0)?.toTimeString()?.slice(3, 8) : "00:00";

      return minSecondsDate + '-' + maxSecondsDate
    }

    return "";
  }

  getSecondsFromTimeString(timeString: string) {
    let splitted = timeString.split(':');
    if (splitted.length == 3) {
      let hours = parseInt(splitted[0]);
      let minutes = parseInt(splitted[1]);
      let seconds = parseInt(splitted[2]);

      if (hours >= 0 && minutes >= 0 && seconds >= 0) {
        return hours * 3600 + minutes * 60 + seconds;
      }
    }
    else if (splitted.length == 2) {
      let minutes = parseInt(splitted[0]);
      let seconds = parseInt(splitted[1]);

      if (minutes >= 0 && seconds >= 0) {
        return minutes * 60 + seconds;
      }
    }
  }

  setPace(trainingVariable: TrainingVariable, value: string, isPace500: boolean) {
    let parameter = isPace500 ? "pace500" : "pace";
    let minParameter = isPace500 ? "minPace500" : "minPace";
    let maxParameter = isPace500 ? "maxPace500" : "maxPace";
    if (value == null || value.length == 0) {
      trainingVariable[parameter] = undefined;
      trainingVariable[minParameter] = undefined;
      trainingVariable[maxParameter] = undefined;
    }
    if (value.match(this.paceRangePattern)) {
      if (value.includes('-')) {
        let splitted = value.split('-');
        if (splitted.length > 1) {
          let low = this.getSecondsFromTimeString(splitted[0]);
          let high = this.getSecondsFromTimeString(splitted[1]);

          if (low && high) {
            trainingVariable[minParameter] = low;
            trainingVariable[maxParameter] = high;
            trainingVariable[parameter] = undefined;
          }
        }
      }
      else {
        let pace = this.getSecondsFromTimeString(value);
        if (pace) {
          trainingVariable[minParameter] = undefined;
          trainingVariable[maxParameter] = undefined;
          trainingVariable[parameter] = pace;
        }
      }
    }
    this.hasChanges = true;
  }

  setName(trainingVariable: TrainingVariable, value: string) {
    trainingVariable.name = value;
    this.hasChanges = true;
  }

  setReps(trainingVariable: TrainingVariable, value: string) {
    if (value == null || value.length == 0) {
      trainingVariable.reps = undefined;
      trainingVariable.minReps = undefined;
      trainingVariable.maxReps = undefined;
    }
    if (value.match(this.rangePattern)) {
      if (value.includes('-')) {
        let splitted = value.split('-');
        if (splitted.length > 1) {
          let low = parseInt(splitted[0])
          let high = parseInt(splitted[1])

          if (low && high) {
            trainingVariable.minReps = low;
            trainingVariable.maxReps = high;
            trainingVariable.reps = undefined;
          }
        }
      }
      else {
        let reps = parseInt(value);
        if (reps) {
          trainingVariable.reps = reps;
          trainingVariable.minReps = undefined;
          trainingVariable.maxReps = undefined;
        }
      }
    }
    this.hasChanges = true;
  }

  getReps(trainingVariable: TrainingVariable) {
    if (trainingVariable.reps) {
      return trainingVariable.reps;
    }
    else if (trainingVariable.minReps && trainingVariable.maxReps) {
      return trainingVariable.minReps + '-' + trainingVariable.maxReps
    }

    return "";
  }


  removeVariable(variable: TrainingVariable) {
    this.trainingVariables = this.trainingVariables.filter(x => x != variable);
  }

  checkUsedTrainingPlanVariables(availableVariables: TrainingVariable[], availableTrainingPlans: TrainingPlan[]): { variable: TrainingVariable, trainingPlans: TrainingPlan[] }[] {
    let usedVariables: { variable: TrainingVariable, trainingPlans: TrainingPlan[] }[] = [];
    availableTrainingPlans.forEach(trainingPlan => {

      let variablesOfTrainingPlan = TrainingPlanEditorComponent.getAllVariablesOfTrainingPlan(trainingPlan, availableVariables);
      variablesOfTrainingPlan?.availableVariables?.forEach(variable => {
        let found = usedVariables.find(x => x.variable == variable);
        if (found) {
          if (!found.trainingPlans.find(x => x.id == trainingPlan.id)) {
            found.trainingPlans.push(trainingPlan);
          }
        }
        else {
          usedVariables.push({ variable: variable, trainingPlans: [trainingPlan] })
        }
      });
    });

    return usedVariables;
  }

  async onSaveTrainingVariables() {

    if (this.enabledTrainingVariableEditorDialogTab != TrainingVariableEditorDialogTab.cardioZones) {
      let findInvalid = this.trainingVariables.find(x => this.isVariableNameInvalid(x));
      if (findInvalid) {
        this.toastr.error("Ungültiger Variablenname: " + findInvalid.name, "Fehler", { timeOut: 3000, positionClass: 'toast-bottom-center' });
        return;
      }

      let findInvalidVariableValue = this.trainingVariables.find(x => this.isPaceValueInvalid(x) || this.isHeartRateValueInvalid(x) || this.isWeightValueInvalid(x));
      if (findInvalidVariableValue) {
        this.toastr.error("Ungültiger Variablenwert: " + findInvalidVariableValue.name, "Fehler", { timeOut: 3000, positionClass: 'toast-bottom-center' });
        return;
      }
    }

    if (this.enabledTrainingVariableEditorDialogTab != TrainingVariableEditorDialogTab.variables) {
      let invalidZoneGroup = this.cardioZoneGroups.find(x => x.isZoneGroupInvalid());
      if (invalidZoneGroup) {
        let zoneGroupName = invalidZoneGroup.isDefaultZoneGroup ? "Standard" : invalidZoneGroup.name;

        let errorMessage = "Ungültige Zonengruppe: " + zoneGroupName;
        if (invalidZoneGroup.isExerciseAssignementInvalid()) {
          errorMessage += " - Bitte wähle mindestens eine Übung.";
        }
        else if (invalidZoneGroup.isGroupNameInvalid()) {
          errorMessage += " - Bitte gib einen Gruppennamen an.";
        }
        else if (invalidZoneGroup.isAnyZoneValueInvalid()) {
          errorMessage += " - Bitte überprüfe die Zonenwerte.";
        }
        this.toastr.error(errorMessage, "Fehler", { timeOut: 3000, positionClass: 'toast-bottom-center' });
        return;
      }

      if ((this.trainingVariableEditorDialogType == TrainingVariableEditorDialogType.user || this.trainingVariableEditorDialogType == TrainingVariableEditorDialogType.plan) && this.user && this.cardioZoneGroups?.length > 0) {
        this.userService.updateCardioZoneGroups(this.user, this.cardioZoneGroups);
      }
      else if (this.trainingVariableEditorDialogType == TrainingVariableEditorDialogType.global && this.cardioZoneGroups?.length > 0) {
        let coach = this.userService.getLoggedInUser();
        coach.trainingSettingsLicenceHolder.cardioZones = this.globalCardioZones.map(x => x.clone());
        await this.userService.updateTrainingSettingsForLicencHolder(coach);
      }
    }

    this.dialogRef.close({ trainingVariables: this.trainingVariables?.map(x => x.clone()) ?? [] });
  }

  onSynchronizeVariables() {
    let availableVariables = this.trainingVariableEditorDialogType == TrainingVariableEditorDialogType.plan ? this.user.trainingVariables : this.user.trainingSettingsLicenceHolder?.trainingVariables ?? [];
    for (let variable of availableVariables) {

      if (this.trainingVariables.find(x => x.id == variable.id) == null) {
        let newVarable = variable.clone();
        this.trainingVariables.push(newVarable);
        this.hasChanges = true;
      }
      else {
        this.trainingVariables = this.trainingVariables.map(x => x.id == variable.id ? variable.clone() : x);
      }
    }
  }

  onSynchronizeVariable(variable: TrainingVariable) {
    let availableVariable = this.user.trainingVariables.find(x => x.id == variable.id) ?? this.user.trainingSettingsLicenceHolder?.trainingVariables.find(x => x.id == variable.id);
    if (availableVariable) {
      this.trainingVariables = this.trainingVariables.map(x => x.id == availableVariable.id ? availableVariable.clone() : x);
      this.hasChanges = true;
    }

  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  onCreateNewVariable() {
    let newVariable = new TrainingVariable();
    this.trainingVariables.push(newVariable);
    this.hasChanges = true;
  }

  onAddAvailableVariable(variable: TrainingVariable) {
    let newVariable = variable.clone();
    this.trainingVariables.push(newVariable);
    this.hasChanges = true;
  }

  isVariableSelectable(variable: TrainingVariable) {
    return this.trainingVariables.find(x => x.name == variable.name) == null;
  }

  nameLostFocus(variable: TrainingVariable) {
    let existingVariable = this.allAvailableForeignVariables.find(x => x.name == variable.name);
    if (existingVariable) {
      variable.setVariableProperties(existingVariable);
      this.toastr.info("Variable " + variable.name + " existiert bereits und wurde übernommen.", "Variable übernommen", { timeOut: 3000, positionClass: 'toast-bottom-center' });
      this.hasChanges = true;
    }
  }

  private reservedVariableNames: string[] = ["BODYWEIGHT", "AGE"];

  isVariableNameInvalid(variable: TrainingVariable) {
    let invalidNamePattern: string = "^(\\d+)RM*$"
    if (variable.name == null || variable.name.length == 0) {
      return true;
    }
    if (variable.name.match(/^\d/) || this.reservedVariableNames.includes(variable.name.toUpperCase())) {
      return true;
    }
    if (variable.name.match(invalidNamePattern)) {
      return true;
    }
    if (this.trainingVariables?.filter(x => x.name == variable.name && x != variable)?.length > 0) {
      return true;
    }
    if (this.allAvailableForeignVariables?.filter(x => x.name == variable.name && x.id != variable.id)?.length > 0) {
      return true;
    }
    if (variable.name.includes('#')) {
      return true;
    }
    return false;
  }

  isPaceValueInvalid(variable: TrainingVariable) {
    return !variable.isNew && (variable.paceAvailableOnInstantiation && variable.pace == undefined && (variable.minPace == undefined || variable.maxPace == undefined));
  }
  isPace500ValueInvalid(variable: TrainingVariable) {
    return !variable.isNew && (variable.pace500AvailableOnInstantiation && variable.pace500 == undefined && (variable.minPace500 == undefined || variable.maxPace500 == undefined));
  }
  isHeartRateValueInvalid(variable: TrainingVariable) {
    return !variable.isNew && (variable.heartRateAvailableOnInstantiation && variable.heartRate == undefined && (variable.minHeartRate == undefined || variable.maxHeartRate == undefined));
  }
  isWeightValueInvalid(variable: TrainingVariable) {
    return !variable.isNew && (variable.weightAvailableOnInstantiation && variable.weight == undefined);
  }
  isRepsValueInvalid(variable: TrainingVariable) {
    return !variable.isNew && (variable.repsAvailableOnInstantiation && variable.reps == undefined && (variable.minReps == undefined || variable.maxReps == undefined));
  }
}

export enum TrainingVariableEditorDialogType {
  user,
  global,
  plan,
  template
}

export enum TrainingVariableEditorDialogTab {
  variables,
  cardioZones,
  all
}