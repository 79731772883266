import { Component, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { PaymentSettings } from 'src/app/model/payment-settings.model';
import { ProductPurchase } from 'src/app/model/product-purchase.model';
import { Product } from 'src/app/model/product.model';
import { User } from 'src/app/model/user.model';
import { ChatService } from 'src/app/services/chat.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { PaymentService } from 'src/app/services/payment.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.css']
})
export class LoginDialogComponent {

  get user(): User {
    return this.userService.getLoggedInUser()
  }

  public environment = environment

  public userSelected = false

  presetUid: string
  redirectPath: string

  constructor(public dialogRef: MatDialogRef<LoginDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { presetUid: string, redirectPath: string }, public userService: FirestoreService, private paymentService: PaymentService, public utilityService: UtilityService, private authService: AuthService, private chatService: ChatService, private dialog: MatDialog, private toastr: ToastrService, private ngZone: NgZone) { 
    this.presetUid = data.presetUid
    this.redirectPath = data.redirectPath
  }
  canContinue() {
    if (this.user && this.presetUid && this.user.uid == this.presetUid) return true
    if (this.user && !this.presetUid) return true
    return false
  }

  onSelectUser() {
    this.userSelected = true
    this.onCloseDialog(true)
  }

  onCloseDialog(success: boolean){
    this.dialogRef.close({success: success})
  }

  onLogout() {
    this.userService.logout();
    this.authService.logoutToPage(this.redirectPath);
    this.chatService.logout();
  }
}
