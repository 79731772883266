<div *ngIf="!userService.getLoggedInUser()?.disabled && userService.getLoggedInUser()?.hasPortalAccess()" class="page-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-md-6 greeting order-2 order-md-1">
                <h2 [ngStyle]="utilityService.customMobileAppStyle('dashboardH2')">Hi {{userService.getLoggedInUser()?.name}}!</h2>
            </div>
            <div class="col-10 col-md-4 col-lg-6 profile order-1 order-md-2">
                <div class="profile-container">
                    <div class="profile-picture">
                        <button class="profile-button" title="Profilbild hochladen" (click)="openProfilePicDialog()">
                            <span class="hint">Profilbild hochladen</span>
                            <input id="input-profilepicture" type="file" style="display: none;" accept=".jpg, .png" (change)="uploadProfilePic($event)">
                            <img draggable="false" src="{{userService.getLoggedInUser()?.profilePictureUrl}}">
                        </button>
                    </div>
                    <h3 class="name">{{userService.getLoggedInUser()?.name}}</h3>
                    <div *ngIf="utilityService.onNativeMobileApp()" class="refresh-button" tooltip="Seite neu laden." (click)="refreshCapacitorApp()">
                        <!-- <i class="bi bi-arrow-clockwise"></i> -->
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                          </svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-xl-6 col-xxl-8" [ngStyle]="utilityService.customMobileAppStyle('dashboardCustomers')">
                <div class="row">
                    <ng-container *ngIf="userService.getLoggedInUser()?.isUser && !(userService.getLoggedInUser()?.isCoach)">
                        <div class="col-12 col-sm-6 col-md-6 col-xl-12 col-xxl-4">
                            <div class="layout-box client-box" routerLink="/client/{{userService.getLoggedInUser().uid}}">
                                <h3 class="name">Fortschritte & Tagebuch</h3>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-xl-12 col-xxl-4">
                            <div class="layout-box client-box" routerLink="/recipes_client">
                                <h3 class="name">Geteilte Rezepte</h3>
                            </div>
                        </div>
                    </ng-container>

                    <div *ngIf="userService.getLoggedInUser().isLicenceHolder && !userService.getLoggedInUser().portalSettingsLicenceHolder?.hideCreateSubscriptionHint && !userService.getLoggedInUser().licenceHolder?.onTrial && !environment.isWhitelabel" class="col-12">
                        <div class="layout-box turquoise">
                            <h3>Danke, dass du nutrilize getestet hast!</h3>
                            Wir hoffen, dir und deinen Kund:innen hat nutrilize gefallen.<br>
                            Wenn du nutrilize weiterhin nutzen möchtest, erstelle dir einfach ein Abonnement unter "Account".<br><br>
                            <span class="bold">Dein To Do:</span><br>
                            1. Einrichtung deines „Abonnements“ im Bereich „Account“ (falls noch nicht erledigt).<br>
                            2. Eingabe deiner Rechnungsinformationen im Rechnungsportal unter "Account".<br>
                            3. In der Lizenzverwaltung die Trainerlizenzen markieren (Trainerlizenzen sind Lizenzen, die du und dein Coaching-Team zur internen Nutzung benötigen und nicht abgerechnet werden).<br>
                            4. Fertig.<br>
                            Im Bereich "Account" kannst du deine Rechnungshistorie einsehen und deine Rechnungsdaten aktualisieren.<br>
                            Du hast noch Fragen? Dann melde dich einfach über unsere bekannten Kanäle.
                            <div class="textalign-center">
                                <button class="button-filled small light margin-10" (click)="openAccountSettings()">Zum Account</button><br>
                                <div class="icon-button" (click)="onHideCreateSubscriptionHint()">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                    </svg>
                                    Als erledigt markieren
                                </div>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="selectedClientGroup">
                        <div class="icon-button add-to-dashboard marginleft-25" (click)="onUnselectClientGroup()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                            </svg>
                            Zurück
                        </div>
                        <div class="chip selected-group">{{selectedClientGroup}}</div>
                        <div class="spacer-10"></div>
                        <ng-container *ngFor="let c of getClientsForSelectedGroup()">
                            <div class="col-12 col-sm-6 col-md-6 col-xl-12 col-xxl-4">
                                <app-customer-button [user]="c" [dashboard]="false"></app-customer-button>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!selectedClientGroup">
                        <div *ngFor="let group of userService.getLoggedInUser()?.portalSettingsCoach?.dashboardClientGroups" class="col-12 col-sm-6 col-lg-6 col-xl-6 col-xxl-4">
                            <div class="layout-box client-box" (click)="onClientGroupSelected(group)" [ngStyle]="utilityService.customMobileAppStyle('layout-box')">
                                <div class="remove-box-button" (click)="onRemoveGroupFromDashboard(group)">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>
                                </div>
                                <div class="row">
                                    <div class="col-10">
                                        <h3 class="name">{{group}}</h3>
                                    </div>
                                    <div class="col-2">
                                        <button mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                        </svg>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="onSendBroadcastMessageToClientGroup(group)">Broadcast-Nachricht versenden</button>
                                        </mat-menu>
                                
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngFor="let c of userService.getAccessibleClients()">
                            <div *ngIf="userService.getLoggedInUser()?.isCoach && userService.getLoggedInUser()?.portalSettingsCoach?.dashboardUids.includes(c.uid)" class="col-12 col-sm-6 col-lg-6 col-xl-12 col-xxl-4">
                                <app-customer-button [user]="c" [dashboard]="true"></app-customer-button>
                            </div>
                        </ng-container>
                        <div *ngIf="userService.getLoggedInUser()?.isCoach && !userService.clientsLoaded" class="col-12 col-sm-6 col-md-6 col-xl-6 col-xxl-4">
                            <div class="layout-box transparent add-button" [ngStyle]="utilityService.customMobileAppStyle('layout-box')" >
                                <div class="icon-button add-to-dashboard" >
                                    <div class="loading-animation">
                                        <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
                                    </div>
                                </div>
                            </div>
                            <mat-menu #addItemMenu="matMenu" xPosition="before">
                                <ng-container *ngFor="let group of userService.getClientGroups()">
                                    <button *ngIf="group != 'Alle' && !userService.getLoggedInUser()?.portalSettingsCoach?.dashboardClientGroups.includes(group)" mat-menu-item (click)="onAddGroupToDashboard(group)">{{group}}</button>
                                </ng-container>
                                <ng-container *ngFor="let client of getOptions()">
                                    <button mat-menu-item (click)="onAddClientToDashboard(client)">{{client.getName()}}</button>
                                </ng-container>
                            </mat-menu>
                        </div>
                        <div *ngIf="userService.getLoggedInUser()?.isCoach && userService.clientsLoaded && userService.getClients()?.length > 0" class="col-12 col-sm-6 col-md-6 col-xl-6 col-xxl-4">
                            <div class="layout-box transparent add-button" [ngStyle]="utilityService.customMobileAppStyle('layout-box')" [matMenuTriggerFor]="addItemMenu">
                                <div class="icon-button add-to-dashboard" [ngStyle]="utilityService.customMobileAppStyle('addToDashboard')">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                    </svg>
                                    Kund:in oder Gruppe anpinnen
                                </div>
                            </div>
                            <mat-menu #addItemMenu="matMenu" xPosition="before">
                                <ng-container *ngFor="let group of userService.getClientGroups()">
                                    <button *ngIf="group != 'Alle' && !userService.getLoggedInUser()?.portalSettingsCoach?.dashboardClientGroups.includes(group)" mat-menu-item (click)="onAddGroupToDashboard(group)">{{group}}</button>
                                </ng-container>
                                <ng-container *ngFor="let client of getOptions()">
                                    <button mat-menu-item (click)="onAddClientToDashboard(client)">{{client.getName()}}</button>
                                </ng-container>
                            </mat-menu>
                        </div>
                    </ng-container>
                    <div *ngIf="userService.getLoggedInUser()?.isCoach && userService.getClients()?.length == 0 && userService.getLoggedInUser()?.coach?.portalSettingsCoach && !userService.getLoggedInUser()?.coach?.portalSettingsCoach?.hideTutorialsHint && !environment.isWhitelabel" class="col-12 col-sm-6 col-md-6 col-xl-6 col-xxl-8">
                        <div class="layout-box turquoise">
                            <div class="remove-box-button" (click)="onHideTutorialsHint()">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </div>
                            <h3>Erste Schritte mit nutrilize</h3>
                            Neu hier? In unseren Tutorials machen wir dich fit für den Coaching-Alltag mit nutrilize! <br>Dort findest du Schritt-für-Schritt Anleitungen zu allen wichtigen Themen im Tool. Viel Spaß beim Ausprobieren!
                        </div>
                    </div>
                    <div *ngIf="userService.getLoggedInUser()?.isCoach && userService.clientsLoaded && userService.getLoggedInUser()?.coach && userService.getClients()?.length == 0 && getPendingLicences()?.length == 0 && environment.firebaseProjectId != 'traindoo-app'" class="col-12 col-sm-6 col-md-6 col-xl-6 col-xxl-4">
                        <div class="layout-box turquoise">
                            <h3>Noch ganz schön leer hier...</h3>
                            Lass uns deine:n erste:n Kundin:en verbinden!
                            <br>
                            <div class="textalign-center">
                                <button class="button-filled small light margin-10" (click)="onConnectNewClient()">Los geht's</button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="userService.getLoggedInUser()?.isCoach && userService.clientsLoaded && userService.getLoggedInUser()?.coach && userService.getClients()?.length == 0 && getPendingLicences()?.length == 0 && environment.firebaseProjectId == 'traindoo-app'" class="col-12 col-md-8 col-xl-8 col-xxl-6">
                        <div class="layout-box turquoise">
                            <h3>Noch ganz schön leer hier...</h3>
                            Füge dich als ersten Athleten hinzu!
                            <br><br>
                            Lade dir dazu auf deinem Smartphone die TRAINDOO 2.0 App herunter und gib nach der Anmeldung deinen Trainercode <span class="bold">{{userService.getLoggedInUser()?.coach.trainerCode}}</span> ein.<br><br>
                            Deinen Trainercode findest du auch unten links über dein Profilbild. Über ihn können sich alle deine Athleten mit dir verbinden.
                        </div>
                    </div>
                    <div *ngIf="userService.getLoggedInUser()?.isCoach && userService.getLoggedInUser()?.coach && userService.getClients()?.length > 0 && !userService.getLoggedInUser()?.coach?.portalSettingsCoach?.hideCoachAppHint && !environment.isWhitelabel" class="col-12 col-sm-6 col-md-6 col-xl-6 col-xxl-4">
                        <div class="layout-box turquoise">
                            <div class="remove-box-button" (click)="onHideCoachAppHint()">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </div>
                            <h3>Coaching Zone auch als App verfügbar!</h3>
                            Wusstest du, dass es die Coaching Zone auch als App für dich gibt? Du kannst sie <a href="https://play.google.com/store/apps/details?id=app.nutrilize.portal" target="_blank" class="underline">hier im Play Store</a> oder <a href="https://apps.apple.com/de/app/nutrilize-for-coaches/id1638587436" target="_blank" class="underline">hier im App Store</a> herunterladen.
                            <br>
                        </div>
                    </div>
                    <div *ngIf="userService.getLoggedInUser()?.isCoach && userService.getNewConnectedClients()?.length > 0" class="col-12 col-md-6 col-xl-12 col-xxl-6">
                        <div class="layout-box turquoise">
                            <div class="header">
                                <div class="row">
                                    <div class="col">
                                        <h3>{{userService.getNewConnectedClients()[0].getName()}} hat sich mit dir verbunden!</h3>
                                    </div>
                                    <div class="col-2">
                                        <div class="icon-button float-right" (click)="userService.removePendingRedemptionLid(userService.getLoggedInUser(), userService.getNewConnectedClients()[0].getLid())">
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            Willst du sie/ihn direkt begrüßen oder Nährstoffziele zuweisen?
                            <br>
                            <div class="textalign-center">
                                <button class="button-filled small light margin-10" (click)="chatService.onOpenChatForUid(userService.getNewConnectedClients()[0].uid)">Chatten</button>
                                <button class="button-filled small light margin-10" (click)="onAssignNutritionalGoal(userService.getNewConnectedClients()[0].uid)">Nährstoffziele zuweisen</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-6 col-xxl-4" [ngStyle]="utilityService.customMobileAppStyle('dashboardOverview')">
                <div *ngIf="!environment.isWhitelabel && !isAdmin() && userService.getLoggedInUser()?.isCoach && userService.getLoggedInUser()?.licenceHolder?.hasFailedPayments()" class="layout-box link" (click)="openAccountSettings()">
                    <div class="alert-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                            <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z"/>
                            <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
                        </svg>
                    </div>
                    <h3 class="display-inlineblock">Du hast fehlgeschlagene Zahlungen</h3>
                    <div>Klicke hier, um zur Zahlungsübersicht zu gelangen und deine offenen Rechnungen zu begleichen.</div>
                </div>
                <div *ngIf="userService.getLoggedInUser()?.isCoach" class="layout-box adjust-mobile">
                    <div *ngIf="userService.getLoggedInUser()?.isCoach" class="row justify-content-center">
                        <div class="div textalign-center tab-button-group adjust-mobile">
                            <button *ngIf="userService.getLoggedInUser()?.isCoach" class="{{eventLogTab}}" (click)="swapTab('eventLogView')">
                                Feed
                            </button>
                            <button *ngIf="userService.getLoggedInUser()?.isCoach && environment.firebaseProjectId != 'traindoo-app'" class="{{tasksViewTab}}" (click)="swapTab('tasksView')">
                                Aufgaben
                            </button>
                            <button *ngIf="!utilityService?.onNativeMobileApp()" class="{{unreadMessagesTab}}" (click)="swapTab('unreadMessagesView')">
                                Nachrichten ({{chatService.getUnreadChats()?.length}})
                            </button>
                            <button *ngIf="userService.getLoggedInUser()?.isCoach && environment.firebaseProjectId != 'traindoo-app'" class="{{expiringLicencesTab}}" (click)="swapTab('expiringLicensesView')">
                                Lizenzen ({{userService.expiringLicences?.length}})
                            </button>
                            <button *ngIf="userService.getLoggedInUser()?.isCoach && getNumberOfExpiringPlans() > 0" class="{{getExpiringNutritionPlansTab}}" (click)="swapTab('expiringNutritionPlansView')">
                                Ablaufende Pläne ({{getNumberOfExpiringPlans()}})
                            </button>
                        </div>
                    </div>
                    <div [ngClass]="utilityService?.onNativeMobileApp() ? 'spacer-10' : 'spacer-25'"></div>

                    <div *ngIf="showEventLogView">
                        <app-event-log [allCoachUsers]="!selectedClientGroup" [eventUsers]="getClientsForSelectedGroup()"></app-event-log>
                    </div>
                    <div *ngIf="showTasksView">
                        <app-task-list></app-task-list>
                    </div>
                    <div *ngIf="showUnreadMessagesView">
                        <h3>Ungelesene Nachrichten</h3>
                        <div *ngFor="let chat of chatService.getUnreadChats()">
                            <div class="layout-box chat-box" (click)="onOpenChat(chat)">
                                <div class="chat-name">{{chat.chatPartner?.getName()}}</div>
                                <div class="message-preview">{{chat.unreadMessages[0]?.message}}</div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showExpiringLicensesView">
                        <h3 class="display-inlineblock">Ablaufende Lizenzen</h3>
                        <div class="display-inlineblock wrapper-timerange-selection float-right">
                            <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownExpiring" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{'Nächste ' + getExpiringLicencesThreshold() + ' Tage'}}</button>
                            <div class="dropdown-menu" aria-labelledby="dropdownExpiring">
                                <button class="dropdown-item" type="button" (click)="updateExpiringLicencesThreshold(2)">2 Tage</button>
                                <button class="dropdown-item" type="button" (click)="updateExpiringLicencesThreshold(5)">5 Tage</button>
                                <button class="dropdown-item" type="button" (click)="updateExpiringLicencesThreshold(14)">14 Tage</button>
                                <button class="dropdown-item" type="button" (click)="updateExpiringLicencesThreshold(30)">30 Tage</button>
                                <button class="dropdown-item" type="button" (click)="updateExpiringLicencesThreshold(60)">60 Tage</button>
                            </div>
                        </div>
                        <div *ngFor="let l of userService.expiringLicences">
                            <div class="layout-box sub-box">
                                <div class="bold">{{l.user?.getName()}}</div>
                                <div *ngIf="!l.productPurchase">Ablaufdatum: {{l.expirationDate?.asFormatedString()}} (benötigt manuelle Deaktivierung)</div>
                                <div *ngIf="l.productPurchase">Enddatum: {{l.expirationDate?.asFormatedString()}} (automatische Deaktivierung)</div>
                                <div *ngIf="!l.productPurchase" class="icon-button" (click)="onDeactivateLicence(l)">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>
                                    Lizenz deaktivieren
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showExpiringNutritionPlansView">
                        <h3>Ablaufende Pläne</h3>
                        <ng-container *ngFor="let client of getExpiringPlanClients()">
                            <div *ngIf="client.hasExpiringNutritionPlan()" class="layout-box sub-box">
                                <div class="bold">{{client.getName()}}</div>
                                <div class="">Ablaufdatum Ernährungsplan: {{client.getExpiringNutritionPlanDate().asFormatedString()}}</div>
                                <div class="icon-button small" routerLink="/client/{{client.uid}}">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-up-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                    </svg>
                                    Coachee öffnen
                                </div>
                                <div class="icon-button marginleft-25" (click)="onHideExpiringNutritionPlanHint(client)">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>
                                    Ausblenden
                                </div>
                            </div>
                            <div *ngIf="client.hasExpiringTrainingPlan()" class="layout-box sub-box">
                                <div class="bold">{{client.getName()}}</div>
                                <div class="">Ablaufdatum Trainingsplan: {{client.getExpiringTrainingPlanDate().asFormatedString()}}</div>
                                <div class="icon-button small" routerLink="/client/{{client.uid}}">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-up-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                    </svg>
                                    Coachee öffnen
                                </div>
                                <div class="icon-button marginleft-25" (click)="onHideExpiringTrainingPlanHint(client)">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>
                                    Ausblenden
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <chat *ngIf="userService.getLoggedInUser().isCoach">
    </chat>
</div>

<div *ngIf="userService.getLoggedInUser()?.disabled" class="page-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-md-6 greeting order-2 order-md-1">
                <h2>Hi {{userService.getLoggedInUser()?.name}}!</h2>
            </div>
            <div class="col-10 col-md-6 profile order-1 order-md-2">
                <div class="profile-container">
                    <div class="profile-picture">
                        <button class="profile-button" title="Profilbild hochladen">
                            <img draggable="false" src="{{userService.getLoggedInUser()?.profilePictureUrl}}">
                        </button>
                    </div>
                    <h3 class="name">{{userService.getLoggedInUser()?.name}}</h3>
                    <div *ngIf="userService.getLoggedInUser()?.isCoach" class="news-button" routerLink="/partners">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-mailbox" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M4 4a3 3 0 0 0-3 3v6h6V7a3 3 0 0 0-3-3zm0-1h8a4 4 0 0 1 4 4v6a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V7a4 4 0 0 1 4-4zm2.646 1A3.99 3.99 0 0 1 8 7v6h7V7a3 3 0 0 0-3-3H6.646z"/>
                            <path fill-rule="evenodd" d="M11.793 8.5H9v-1h5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.354-.146l-.853-.854z"/>
                            <path d="M5 7c0 .552-.448 0-1 0s-1 .552-1 0a1 1 0 0 1 2 0z"/>
                        </svg>
                    </div>
                    <div *ngIf="userService.getLoggedInUser()?.isCoach && userService.getLoggedInUser()?.portalSettingsCoach?.maxVersionNumberNewsDisplayed >= 0 && userService.getLoggedInUser()?.portalSettingsCoach?.maxVersionNumberNewsDisplayed < userService.APP_VERSION_NUMBER" class="indicator news-indicator"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                Wir haben diesen Account vorübergehend deaktiviert.<br>
                Um ihn wieder zu nutzen, gib uns bitte kurz Bescheid.
            </div>
        </div>
    </div>
</div>

<div *ngIf="!userService.getLoggedInUser()?.hasPortalAccess()" class="page-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-md-6 greeting order-2 order-md-1">
                <h2>Hi {{userService.getLoggedInUser()?.name}}!</h2>
            </div>
            <div class="col-10 col-md-6 profile order-1 order-md-2">
                <div class="profile-container">
                    <div class="profile-picture">
                        <button class="profile-button" title="Profilbild hochladen">
                            <img draggable="false" src="{{userService.getLoggedInUser()?.profilePictureUrl}}">
                        </button>
                    </div>
                    <h3 class="name">{{userService.getLoggedInUser()?.name}}</h3>
                    <div *ngIf="userService.getLoggedInUser()?.isCoach" class="news-button" routerLink="/partners">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-mailbox" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M4 4a3 3 0 0 0-3 3v6h6V7a3 3 0 0 0-3-3zm0-1h8a4 4 0 0 1 4 4v6a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V7a4 4 0 0 1 4-4zm2.646 1A3.99 3.99 0 0 1 8 7v6h7V7a3 3 0 0 0-3-3H6.646z"/>
                            <path fill-rule="evenodd" d="M11.793 8.5H9v-1h5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.354-.146l-.853-.854z"/>
                            <path d="M5 7c0 .552-.448 0-1 0s-1 .552-1 0a1 1 0 0 1 2 0z"/>
                        </svg>
                    </div>
                    <div *ngIf="userService.getLoggedInUser()?.isCoach && userService.getLoggedInUser()?.portalSettingsCoach?.maxVersionNumberNewsDisplayed >= 0 && userService.getLoggedInUser()?.portalSettingsCoach?.maxVersionNumberNewsDisplayed < userService.APP_VERSION_NUMBER" class="indicator news-indicator"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div *ngIf="userService.getLoggedInUser()?.isLicenceHolder && userService.getLoggedInUser()?.licenceHolder?.preStarterPackageClient" class="col-8">
                    <div class="layout-box turquoise">
                        <h3>Richte dein Abonnement ein, um fortzufahren.</h3>
                        Ab sofort werden wir deinen monatlichen Rechnungsbetrag per Lastschriftverfahren einziehen, wofür wir Stripe als Zahlungsdienstleister nutzen. Das bedeutet du sparst dir den Stress, unsere Rechnungen manuell zu begleichen!<br>
                        Deine Konditionen bzw. unser Pricing ändert sich dadurch natürlich nicht!<br><br>
                        <span class="bold">Dein To Do:</span><br>
                        1. Einrichtung deines „Abonnements“ im Bereich „Account“.<br>
                        2. Eingabe deiner Rechnungsinformationen im Rechnungsportal unter "Account".<br>
                        3. In der Lizenzverwaltung die Trainerlizenzen markieren (Trainerlizenzen sind Lizenzen, die du und dein Coaching-Team zur internen Nutzung benötigen und nicht abgerechnet werden).<br>
                        4. Fertig.<br>
                        Im Bereich "Account" kannst du deine Rechnungshistorie einsehen und deine Rechnungsdaten aktualisieren.<br>
                        Du hast noch Fragen? Dann melde dich einfach über unsere bekannten Kanäle.
                        <div class="textalign-center">
                            <button class="button-filled small light margin-10" (click)="openAccountSettings()">Zum Account</button><br>
                        </div>
                    </div>
                </div>
                <div *ngIf="userService.getLoggedInUser()?.isLicenceHolder && !userService.getLoggedInUser()?.licenceHolder?.preStarterPackageClient" class="col-8">
                    <div class="layout-box turquoise">
                        <h3>Danke, dass du nutrilize getestet hast!</h3>
                        Wir hoffen, dir und deinen Kund:innen hat nutrilize gefallen.<br>
                        Wenn du nutrilize weiterhin nutzen möchtest, erstelle dir einfach ein Abonnement unter "Account".<br><br>
                        <span class="bold">Dein To Do:</span><br>
                        1. Einrichtung deines „Abonnements“ im Bereich „Account“ (falls noch nicht erledigt).<br>
                        2. Eingabe deiner Rechnungsinformationen im Rechnungsportal unter "Account".<br>
                        3. In der Lizenzverwaltung die Trainerlizenzen markieren (Trainerlizenzen sind Lizenzen, die du und dein Coaching-Team zur internen Nutzung benötigen und nicht abgerechnet werden).<br>
                        4. Fertig.<br>
                        Im Bereich "Account" kannst du deine Rechnungshistorie einsehen und deine Rechnungsdaten aktualisieren.<br>
                        Du hast noch Fragen? Dann melde dich einfach über unsere bekannten Kanäle.
                        <div class="textalign-center">
                            <button class="button-filled small light margin-10" (click)="openAccountSettings()">Zum Account</button><br>
                        </div>
                    </div>
                </div>
                <div *ngIf="!userService.getLoggedInUser()?.isLicenceHolder" class="col-8">
                    <div class="layout-box turquoise">
                        <h3>Danke, dass du nutrilize getestet hast!</h3>
                        Wir hoffen, dir und deinen Kund:innen hat nutrilize gefallen.<br>
                        Der Admin deines Teams kann nutrilize für die weitere Nutzung wieder freischalten.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
