import { Component, Input, OnInit } from '@angular/core';
import {MatDialog } from '@angular/material/dialog';
import { Moment } from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/auth/auth.service';
import { Activity } from 'src/app/model/activity.model';
import { MergedTrainingExercise } from 'src/app/model/training-exercise';
import { TrackedTrainingSession } from 'src/app/model/training-monitoring.model';
import { TrainingPlan, TrainingSession } from 'src/app/model/training-plan.model';
import { User } from 'src/app/model/user.model';
import { FirestoreNutritionPlanService } from 'src/app/services/firestore-nutritionplan.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { TrainingService } from 'src/app/services/training.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-training-graph',
  templateUrl: './training-graph.component.html',
  styleUrls: ['./training-graph.component.css']
})
export class TrainingGraphComponent implements OnInit {

  constructor(public userService: FirestoreService, public dialog: MatDialog, private spinner: NgxSpinnerService, public utilityService: UtilityService, private trainingService: TrainingService, private authService: AuthService) { }

  private sessions: TrackedTrainingSession[]
  private activities: Activity[]

  public user: User
  @Input() set User(value: User){
    this.user = value;
    this.init()
  }

  public trainingSessionsForExerciseHistory: TrackedTrainingSession[]

  private startDate: Date
  private endDate: Date = new Date()

  public selectedStartDate: Date;
  public selectedEndDate: Date;

  
  onStartDateChanged(startDate: Date) {
    this.selectedStartDate = startDate;
    if(this.selectedStartDate && this.selectedEndDate){
      this.loadData()
    }
  }
  onEndDateChanged(endDate: Date) {
    this.selectedEndDate = endDate;
    if(this.selectedStartDate && this.selectedEndDate){
      this.loadData()
    }
  }

  public today = new Date()
  startDateFilterData = (d: Moment | null): boolean => {
    if (d?.toDate() > this.today) return false
    return true
  }
  endDateFilterData = (d: Moment | null): boolean => {
    if (d?.toDate() > this.today) return false
    return true
  }

  graphData: any[] = []
  graphParams: any[] = []
  graphAxis: any[] = []
  graphType: string = 'line'

  customizeLeftYAxis = (arg: any) => { return arg.valueText }
  customizeRightYAxis = (arg: any) => { return arg.valueText }

  customizeLegendText = (arg: any) => {
    if (this.isBarChartParameterSelected() && this.legendOverwrites[arg.seriesName] != undefined) return this.legendOverwrites[arg.seriesName]
    return arg.seriesName
  }

  customizeTooltip = (arg: any) => {
    var name = arg.point?.series?.name;
    var text = arg.point?.data?.date.asFormatedString() + '\n'
    this.graphParams.forEach(item => {
      if (arg.point?.data[item.valueField] != undefined && arg.point?.data[item.valueField] != null) {
        if (item.name == name) {
          text = text + "<b>" + item.name + ': ' + arg.point?.data[item.valueField + 'Rendered'] + ' ' + (item.unit || '') + "</b>" + '\n'
        } else {
          text = text + item.name + ': ' + arg.point?.data[item.valueField + 'Rendered'] + ' ' + (item.unit || '') + '\n'
        }
      }
    })
    return { text: text}
  }

  customizePointLabelText = (arg: any) => {
    var name = arg.point?.series?.name;
    var text = ''
    this.graphParams?.forEach(item => {
      if (arg.point?.data[item.valueField] != undefined && arg.point?.data[item.valueField] != null) {
        if (item.name == name) {
          text = arg.point?.data[item.valueField + 'Rendered'] + ' ' + (item.unit || '')
        }
      }
    })
    return text
  }
  customizePointLabel (pointInfo: any) {
    return { backgroundColor: 'transparent', font: {color: '#767676', size: '12px'} }
  }

  public selectedParameters: DropdownItem[]
  public parameters: DropdownItem[] = [
    {id: 0, name: "Gesamtgewicht", tmp: '', unit: null, graphParams: (exerciseId: string, exerciseName: string) => [
      {valueField: (exerciseId ?? '') + "totalWeight", name: "Gesamtgewicht " + (exerciseName ?? ''), axis: "weightAxis", unit: "kg"}
    ]},
    {id: 1, name: "Sätze", tmp: '', unit: null, graphParams: (exerciseId: string, exerciseName: string) => [
      {valueField: (exerciseId ?? '') + "sets", name: "Sätze " + (exerciseName ?? ''), axis: "countAxis", unit: ""}
    ]},
    {id: 2, name: "Wiederholungen", tmp: '', unit: null, graphParams: (exerciseId: string, exerciseName: string) => [
      {valueField: (exerciseId ?? '') + "reps", name: "Wiederholungen " + (exerciseName ?? ''), axis: "countAxis", unit: "Wdh"}
    ]},
    {id: 3, name: "Maximalgewicht", tmp: '', unit: null, graphParams: (exerciseId: string, exerciseName: string) => [
      {valueField: (exerciseId ?? '') + "maxWeight", name: "Maximalgewicht " + (exerciseName ?? ''), axis: "weightAxis", unit: "kg"}
    ]},
    {id: 4, name: "RPE", tmp: '', unit: null, graphParams: (exerciseId: string, exerciseName: string) => [
      {valueField: (exerciseId ?? '') + "rpe", name: "RPE " + (exerciseName ?? ''), axis: "exertionAxis", unit: ""}
    ]},
    {id: 9, name: "RIR", tmp: '', unit: null, graphParams: (exerciseId: string, exerciseName: string) => [
      {valueField: (exerciseId ?? '') + "rir", name: "RIR " + (exerciseName ?? ''), axis: "exertionAxis", unit: ""}
    ]},
    {id: 5, name: "E1RM", tmp: '', unit: null, graphParams: (exerciseId: string, exerciseName: string) => [
      {valueField: (exerciseId ?? '') + "e1rm", name: "E1RM " + (exerciseName ?? ''), axis: "weightAxis", unit: ""}
    ]},
    {id: 6, name: "Trainingsdauer", tmp: '', unit: null, graphParams: (exerciseId: string, exerciseName: string) => [
      {valueField: "trainingDuration", name: "Trainingsdauer", axis: "durationAxis", unit: "h"}
    ]},
    {id: 7, name: "Aktivitätsdauer", tmp: '', unit: null, graphParams: (exerciseId: string, exerciseName: string) => []},
    {id: 8, name: "Aktivitätskalorien", tmp: '', unit: null, graphParams: (exerciseId: string, exerciseName: string) => []},
  ]

  public selectedTimeRange: DropdownItem
  public timeRanges: DropdownItem[] = [
    {id: 0, name: "Letzte 31 Tage", tmp: '', unit: null, graphParams: []},
    {id: 1, name: "Letzte 90 Tage", tmp: '', unit: null, graphParams: []},
    {id: 2, name: "Letzte 365 Tage", tmp: '', unit: null, graphParams: []},
    {id: 3, name: "Gesamter Zeitraum", tmp: '', unit: null, graphParams: []},
    {id: 4, name: "Benutzerdefinierter Zeitraum", tmp: '', unit: null, graphParams: []},
  ]

  selectedExercises: MergedTrainingExercise[] = []
  exercises: MergedTrainingExercise[] = this.trainingService.MergedTrainingExercises

  selectedSessions: TrainingSession[] = []
  trainingPlans: TrainingPlan[] = []


  public selectedCustomCategories: DropdownItem[] = []
  public availableCustomCategories: DropdownItem[] = []

  ngOnInit(): void {

  }

  loadData(forceDataLoad: boolean = false) {

    var oldStartDate = this.startDate?.clone()
    var oldEndDate = this.endDate?.clone()

    this.endDate = new Date()

    if (this.selectedTimeRange.id == 0) {
      this.startDate = this.endDate.clone().addDays(-31)
    } else if (this.selectedTimeRange.id == 1) {
      this.startDate = this.endDate.clone().addDays(-90)
    } else if (this.selectedTimeRange.id == 2) {
      this.startDate = this.endDate.clone().addDays(-365)
    } else if (this.selectedTimeRange.id == 3) {
      this.startDate = this.endDate.clone().addDays(-3650)
    } else if (this.selectedTimeRange.id == 4) {
      if(this.selectedStartDate && this.selectedEndDate && this.selectedEndDate > this.selectedStartDate) {
        this.selectedEndDate.setHours(0,0,0,0);
        this.selectedStartDate.setHours(0,0,0,0);
        this.startDate = this.selectedStartDate?.clone();
        this.endDate = this.selectedEndDate?.clone();
      }
      else {
        return;
      }
    }

    if (this.selectedTimeRange.id != 4) {
      this.selectedStartDate = this.startDate;
      this.selectedEndDate = this.endDate;
    }

    if (!forceDataLoad && oldStartDate != null && oldEndDate != null && this.startDate >= oldStartDate && this.endDate <= oldEndDate) {
      this.updateGraph()
      return
    }

    //this.spinner.show()

    this.userService.getTrackedTrainingSessionsWithExercises(this.user, this.startDate, this.endDate).then(sessions => {
      this.selectedStartDate = sessions.map(x => x.startDate).sort((a, b) => a.getTime() - b.getTime())?.[0] || this.selectedStartDate;
      this.sessions = sessions

      this.refreshAvailableExercises()

      //this.spinner.hide()

      this.updateGraph()
    })

  }
  
  updateTrainingSessionsForExerciseHistory() {
    if(this.selectedSessions.length === 0){
      this.trainingSessionsForExerciseHistory = this.sessions.filter(session => (session.startDate > this.startDate || session.startDate.isSameDate(this.startDate)))
      this.exercisesForHistoryTableBySession = this.exercises
    }
    else {
      this.exercisesForHistoryTableBySession = []
      this.trainingSessionsForExerciseHistory = this.sessions.filter(session => (session.startDate > this.startDate || session.startDate.isSameDate(this.startDate)) && this.selectedSessions.filter(s => s.id == session.plannedSessionId).length > 0)
      let exerciseIds: string[] = []
      this.trainingSessionsForExerciseHistory.map(x => x.trackedTrainingExercises).forEach(exerciseList => {
        exerciseList.forEach(exercise => {
          if (!exerciseIds.includes(exercise.exerciseId)) exerciseIds.push(exercise.exerciseId)
        })
      });
      this.exercisesForHistoryTableBySession = this.exercises.filter(exercise => exerciseIds.includes(exercise.sourceExerciseId))
    }
    
  }

  public exercisesForHistoryTableBySession: MergedTrainingExercise[] = []

  getExercisesForHistoryTable() {
    if (!this.selectedExercises || this.selectedExercises.length == 0){
      return this.exercisesForHistoryTableBySession
    }
    return this.selectedExercises
  }

  onOpenSessionSelection() {
    this.trainingPlans = this.user.trainingPlans.filter(plan => !plan.deleted)
  }

  refreshAvailableExercises() {
    var exerciseIds = []
    this.sessions.forEach(session => {
      session.trackedTrainingExercises.forEach(exercise => {
        if (!exerciseIds.includes(exercise.exerciseId)) exerciseIds.push(exercise.exerciseId)
      })
    })
    var availableExercises: MergedTrainingExercise[] = []
    this.trainingService.MergedTrainingExercises.forEach(exercise => {
      if (exerciseIds.includes(exercise.sourceExerciseId)) availableExercises.push(exercise)
    })
    this.exercises = availableExercises
  }

  init() {
    this.selectedTimeRange = this.timeRanges[0]
    this.selectedParameters = [this.parameters[0]]
    this.selectedExercises = []
    this.trainingPlans = this.user.trainingPlans.filter(plan => !plan.deleted)

    this.availableCustomCategories = []

    this.trainingService.MergedTrainingExercises.forEach(exercise => {
      if (exercise.customCategories?.length > 0) {
        exercise.customCategories.forEach(category => {
          if (this.availableCustomCategories.find(x => x.name == category.originObject) == undefined) {
            this.availableCustomCategories.push({id: this.availableCustomCategories.length, name: category.originObject, tmp: '', unit: null, graphParams: []})
          }
        })
      }
    })

    this.loadData(true)
  }


  getSelectableTrainingSessionsOfPlan(plan: TrainingPlan){
    let availableSessions: TrainingSession[] = []
    if(plan.deleted) return availableSessions;
    let availablePlanSessions = plan.sessions.filter(x => !x.deleted && !x.isRestDay);
    availablePlanSessions.forEach(session => {
      if(!session.deleted){
        if(session.baseSessionId && availablePlanSessions.find(x => x.id == session.baseSessionId)) {
          return;
        }
        availableSessions.push(session)
      }
    });
    return availableSessions;
  }

  getSelectedParameters() {
    if (this.selectedParameters.length == 0) {
      return 'Parameter auswählen'
    } else if (this.selectedParameters.length == 1) {
      return this.selectedParameters[0].name
    } else {
      var text = ""
      this.selectedParameters.forEach(type => {
        text += type.name + ", "
      })
      text = text.substr(0, text.length - 2)
      return text
    }
  }

  getSelectedExercises() {
    if (this.selectedExercises.length == 0) {
      return 'Alle Übungen'
    } else if (this.selectedExercises.length == 1) {
      return this.selectedExercises[0].name?.GetValue('de')
    } else {
      var text = ""
      this.selectedExercises.forEach(type => {
        text += type.name?.GetValue('de') + ", "
      })
      text = text.substr(0, text.length - 2)
      return text
    }
  }

  getSelectedSessions() {
    if (this.selectedSessions.length == 0) {
      return 'Alle Einheiten'
    } else if (this.selectedSessions.length == 1) {
      return this.selectedSessions[0].name
    } else {
      var text = ""
      this.selectedSessions.forEach(type => {
        text += type.name + ", "
      })
      text = text.substr(0, text.length - 2)
      return text
    }
  }

  getSelectedCustomCategories() {
    if (this.selectedCustomCategories.length == 0) {
      return 'Keine Kategorien'
    } else if (this.selectedCustomCategories.length == 1) {
      return this.selectedCustomCategories[0].name
    } else {
      var text = ""
      this.selectedCustomCategories.forEach(type => {
        text += type.name + ", "
      })
      text = text.substr(0, text.length - 2)
      return text
    }
  }

  onGraphTimeRangeChanged(selection: DropdownItem) {
    this.selectedTimeRange = selection
    this.loadData()
  }

  onGraphExerciseChanged(exercise: MergedTrainingExercise) {
    if (exercise == null) {
      this.selectedExercises = []
      this.updateGraph()
      return
    }
    if (this.selectedExercises.includes(exercise)) {
      this.selectedExercises.forEach( (item, index) => {
        if (item == exercise) this.selectedExercises.splice(index, 1);
      });
    } else {
      this.selectedExercises.push(exercise)
    }
    this.updateGraph()
  }

  onGraphSessionChanged(session: TrainingSession) {
    if (session == null) {
      this.selectedSessions = []
      this.updateGraph()
      return
    }
    if (this.selectedSessions.includes(session)) {
      this.selectedSessions.forEach( (item, index) => {
        if (item == session) this.selectedSessions.splice(index, 1);
      });
    } else {
      this.selectedSessions.push(session)
    }
    this.updateGraph()
  }

  onGraphParameterChanged(selection: DropdownItem) {
    if (selection.id == 7 && this.selectedParameters.filter(p => p.id == 7).length == 0) {
      this.graphType = 'stackedBar'
      this.selectedParameters = [selection]
      if (!this.activities) {
        this.userService.getAllActivities(this.user).toPromise().then(activities => {
          this.activities = activities
          this.updateGraph()
        })
      }
    } else if (selection.id == 8 && this.selectedParameters.filter(p => p.id == 8).length == 0) {
      this.graphType = 'stackedBar'
      this.selectedParameters = [selection]
      if (!this.activities) {
        this.userService.getAllActivities(this.user).toPromise().then(activities => {
          this.activities = activities
          this.updateGraph()
        })
      }
    } else {
      this.graphType = 'line'
      if (this.selectedParameters.includes(selection)) {
        this.selectedParameters.forEach( (item, index) => {
          if (item == selection || item.id == 7 || item.id == 8) this.selectedParameters.splice(index, 1);
        });
      } else {
        this.selectedParameters = this.selectedParameters.filter(p => p.id != 7 && p.id != 8)
        this.selectedParameters.push(selection)
      }
    }
    this.updateGraph()
  }

  onGraphCustomCategoryChanged(selection: DropdownItem) {
    if (selection == null) {
      this.selectedCustomCategories = []
      this.updateGraph()
      return
    }
    if (this.selectedCustomCategories.includes(selection)) {
      this.selectedCustomCategories.forEach( (item, index) => {
        if (item == selection) this.selectedCustomCategories.splice(index, 1);
      });
    } else {
      this.selectedCustomCategories.push(selection)
    }
    this.updateGraph()
  }

  canSelectExercisesAndSessions() {
    if (this.isBarChartParameterSelected()) {
      return false
    }
    return true
  }
  isBarChartParameterSelected() {
    return this.selectedParameters.length == 1 && (this.selectedParameters[0].id == 7 || this.selectedParameters[0].id == 8)
  }
  canSelectCustomCategories() {
    return environment.firebaseProjectId == 'traindoo-app'
  }

  public legendOverwrites = new Map<string, number>()

  updateGraph() {

    this.customizeLeftYAxis = (arg: any) => {
      return arg.valueText;
    }
    this.customizeRightYAxis = (arg: any) => {
      return arg.valueText;
    }

    var graphParams = []
    this.selectedParameters.forEach(param => {
      for (var i = 0; i < Math.max(1, this.selectedExercises.length); i++) {
        var exerciseId = null
        var exerciseName = null
        if (this.selectedExercises.length > 0) {
          exerciseId = this.selectedExercises[i].sourceExerciseId
          exerciseName = this.selectedExercises[i].name.GetValue('de')
        }

        param.graphParams(exerciseId, exerciseName).forEach(p => {
          if (graphParams.find(x => x.valueField == p.valueField) == undefined) graphParams.push(p)
        })
      }

      for (var i = 0; i < this.selectedCustomCategories.length; i++) {
        var category = this.selectedCustomCategories[i]
        param.graphParams(category.name, category.name).forEach(p => {
          if (graphParams.find(x => x.valueField == p.valueField) == undefined) graphParams.push(p)
        })
      }
    })
    this.graphParams = graphParams

    var graphAxis: any[] = []
    if (this.selectedParameters.includes(this.parameters[0]) || this.selectedParameters.includes(this.parameters[3]) || this.selectedParameters.includes(this.parameters[6])) {
      graphAxis.push({ name: "weightAxis", visualRange: [0, null] })
    }

    if (this.selectedParameters.includes(this.parameters[1]) || this.selectedParameters.includes(this.parameters[2])) {
      graphAxis.push({ name: "countAxis", visualRange: [0, null] })
    }

    if (this.selectedParameters.includes(this.parameters[4]) || this.selectedParameters.includes(this.parameters[5])) {
      graphAxis.push({ name: "exertionAxis", visualRange: [0, 10] })
    }

    if (this.selectedParameters.includes(this.parameters[7]) || this.selectedParameters.includes(this.parameters[8])) {
      var axisDescription = (arg: any) => {
        var duration = parseInt(arg.valueText)
        return Math.floor(duration / 60) + ':' + ((duration % 60) < 10 ? '0' : '') + (duration % 60) + ' h';
      }
      if (graphAxis.length == 0) {
        this.customizeLeftYAxis = axisDescription
      } else if (graphAxis.length == 1) {
        this.customizeRightYAxis = axisDescription
      }

      graphAxis.push({ name: "durationAxis", visualRange: [0, null] })
    }

    if (this.selectedParameters.includes(this.parameters[9])) {
      graphAxis.push({ name: "calorieAxis", visualRange: [0, null] })
    }

    this.graphAxis = graphAxis

    var statisticsItems: any[] = []

    if (this.isBarChartParameterSelected()) {

      var summaryMap = new Map<string, number>()

      this.activities.forEach(activity => {
        if ((activity.date < this.startDate && !activity.date.isSameDate(this.startDate)) || (activity.date > this.endDate && !activity.date.isSameDate(this.endDate))) return
        if (this.selectedParameters[0].id == 7 && activity.duration == 1440) return

        var param = graphParams.find(p => p.name == activity.name)
        if (!param) {
          if (this.selectedParameters[0].id == 7) param = {valueField: "activityDuration" + FirestoreNutritionPlanService.generateUniqueString(), name: activity.name, axis: "durationAxis", unit: "h"}
          if (this.selectedParameters[0].id == 8) param = {valueField: "activityCalories" + FirestoreNutritionPlanService.generateUniqueString(), name: activity.name, axis: "calorieAxis", unit: "kcal"}
          graphParams.push(param)
        }

        var statisticsItem: any;
        var date = activity.date
        date.setHours(0, 0, 0, 0)
        var duration = activity.duration
        var calories = activity.caloriesBurned

        statisticsItem = {
          date: date,
          dateRendered: date.asShortFormatedString(),
          weekNumber: date.getYearOfWeekOfYearNumber() + '/' + (date.getWeekOfYearNumber() < 10 ? '0' : '') + date.getWeekOfYearNumber(),
        }
        if (this.selectedParameters[0].id == 7) {
          statisticsItem[param.valueField] = duration
          statisticsItem[param.valueField + 'Rendered'] = Math.floor(duration / 60) + ':' + ((duration % 60) < 10 ? '0' : '') + (duration % 60)
          summaryMap[param.valueField] = (summaryMap[param.valueField] || 0) + duration
        } else if (this.selectedParameters[0].id == 8) {
          statisticsItem[param.valueField] = calories
          statisticsItem[param.valueField + 'Rendered'] = calories?.toFixed() || '0'
          summaryMap[param.valueField] = (summaryMap[param.valueField] || 0) + calories
        }

        statisticsItems.push(statisticsItem)
      })

      this.legendOverwrites = new Map<string, number>()
      graphParams.forEach(param => {
        if (summaryMap[param.valueField] != undefined) {
          var value = summaryMap[param.valueField]
          var printableValue = value?.toFixed() + ' ' + param.unit 
          if (this.selectedParameters[0].id == 7) printableValue = Math.floor(value / 60) + ':' + ((value % 60) < 10 ? '0' : '') + (value % 60) + ' h'
          this.legendOverwrites[param.name] = param.name + '\n(' + printableValue + ' gesamt)'
        }
      })

    } else {
      this.sessions.forEach(session => {
        if ((session.startDate < this.startDate && !session.startDate.isSameDate(this.startDate)) || (session.endDate > this.endDate && !session.endDate.isSameDate(this.endDate))) return
        if (this.selectedSessions.length > 0) {
          if (this.selectedSessions.filter(s => s.id == session.plannedSessionId).length == 0) {
            let trainingPlan = this.trainingPlans.find(x => x.id == session.trainingPlanId);
            if(!trainingPlan || !trainingPlan?.sessions?.find(x => x.id == session.plannedSessionId && x.baseSessionId && this.selectedSessions?.find(y => y.id == x.baseSessionId))){
              return;
            }
          }
        }
  
        var statisticsItem: any;
        var date = session.startDate
        statisticsItem = {
          date: date,
          dateRendered: date.asShortFormatedString(),
          weekNumber: date.getYearOfWeekOfYearNumber() + '/' + (date.getWeekOfYearNumber() < 10 ? '0' : '') + date.getWeekOfYearNumber(),
        }
  
        for (var i = 0; i < Math.max(1, this.selectedExercises.length); i++) {
  
          var exerciseId = null
          var exerciseName = null
          if (this.selectedExercises.length > 0) {
            exerciseId = this.selectedExercises[i].sourceExerciseId
            exerciseName = this.selectedExercises[i].name.GetValue('de')
          }
  
          var totalWeight = session.getTotalWeight(exerciseId)
          if (totalWeight != null) {
            statisticsItem[(exerciseId ?? '') + "totalWeight"] = totalWeight
            statisticsItem[(exerciseId ?? '') + "totalWeightRendered"] = totalWeight
          }
          var setCount = session.getSetCount(exerciseId)
          if (setCount != null) {
            statisticsItem[(exerciseId ?? '') + "sets"] = setCount
            statisticsItem[(exerciseId ?? '') + "setsRendered"] = setCount
          }
          var repCount = session.getRepCount(exerciseId)
          if (repCount != null) {
            statisticsItem[(exerciseId ?? '') + "reps"] = repCount
            statisticsItem[(exerciseId ?? '') + "repsRendered"] = repCount
          }
          var maxWeight = session.getMaxWeight(exerciseId)
          if (maxWeight != null) {
            statisticsItem[(exerciseId ?? '') + "maxWeight"] = maxWeight
            statisticsItem[(exerciseId ?? '') + "maxWeightRendered"] = maxWeight
          }
          var e1rm = session.getE1RM(exerciseId)
          if (e1rm != null) {
            statisticsItem[(exerciseId ?? '') + "e1rm"] = e1rm
            statisticsItem[(exerciseId ?? '') + "e1rmRendered"] = e1rm
          }
          var rpe = session.getAverageRPE(exerciseId)
          if (rpe != null) {
            statisticsItem[(exerciseId ?? '') + "rpe"] = rpe
            statisticsItem[(exerciseId ?? '') + "rpeRendered"] = rpe
          }
          var rir = session.getAverageRIR(exerciseId)
          if (rir != null) {
            statisticsItem[(exerciseId ?? '') + "rir"] = rir
            statisticsItem[(exerciseId ?? '') + "rirRendered"] = rir
          }
        }

        for (var i = 0; i < this.selectedCustomCategories?.length; i++) {
          var category = this.selectedCustomCategories[i].name

          let totalWeight = null
          let setCount = null
          let repCount = null
          let maxWeight = null
          let e1rm = null
          let rpe = null
          let rir = null

          session.trackedTrainingExercises.forEach(exercise => {
            var exerciseId = exercise.exerciseId
            var trainingExercise = this.trainingService.MergedTrainingExercises.find(x => x.sourceExerciseId == exerciseId)
            if (trainingExercise) {
              if (trainingExercise.customCategories.find(x => x.originObject == category)) {
                if (totalWeight == null) totalWeight = 0
                totalWeight += exercise.getTotalWeight()
                if (setCount == null) setCount = 0
                setCount += exercise.getSetCount()
                if (repCount == null) repCount = 0
                repCount += exercise.getRepCount()
                if (maxWeight == null) maxWeight = 0
                maxWeight += exercise.getMaxWeight()
                if (e1rm == null) e1rm = 0
                e1rm += exercise.getE1RM()
                if (rpe == null) rpe = 0
                rpe += exercise.getAverageRPE()
                if (rir == null) rir = 0
                rir += exercise.getAverageRIR()
              }
            }
          })
          
          if (totalWeight != null) {
            statisticsItem[(category ?? '') + "totalWeight"] = totalWeight
            statisticsItem[(category ?? '') + "totalWeightRendered"] = totalWeight
          }

          if (setCount != null) {
            statisticsItem[(category ?? '') + "sets"] = setCount
            statisticsItem[(category ?? '') + "setsRendered"] = setCount
          }

          if (repCount != null) {
            statisticsItem[(category ?? '') + "reps"] = repCount
            statisticsItem[(category ?? '') + "repsRendered"] = repCount
          }

          if (maxWeight != null) {
            statisticsItem[(category ?? '') + "maxWeight"] = maxWeight
            statisticsItem[(category ?? '') + "maxWeightRendered"] = maxWeight
          }

          if (e1rm != null) {
            statisticsItem[(category ?? '') + "e1rm"] = e1rm
            statisticsItem[(category ?? '') + "e1rmRendered"] = e1rm
          }

          if (rpe != null) {
            statisticsItem[(category ?? '') + "rpe"] = rpe
            statisticsItem[(category ?? '') + "rpeRendered"] = rpe
          }

          if (rir != null) {
            statisticsItem[(category ?? '') + "rir"] = rir
            statisticsItem[(category ?? '') + "rirRendered"] = rir
          }
        }
  
        var trainingDuration = session.getTrainingDuration()
        if (trainingDuration != null) {
          statisticsItem["trainingDuration"] = trainingDuration
          statisticsItem["trainingDurationRendered"] = session.getPrintableTrainingDuration()
        }
        
        statisticsItems.push(statisticsItem)
      })
    }

    this.graphData = statisticsItems

    this.refreshAvailableExercises()

    this.updateTrainingSessionsForExerciseHistory()
  }

  isAdmin() {
    return this.authService.isAdmin()
  }
}

class DropdownItem {
  id: number;
  tmp: string;
  name: string;
  unit: string;
  graphParams: any
}