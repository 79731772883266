import { Component, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { MetricsSelectionDialogComponent } from 'src/app/metrics-selection-dialog/metrics-selection-dialog.component';
import { Product } from 'src/app/model/product.model';
import { Questionaire } from 'src/app/model/questionaires.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { PaymentService } from 'src/app/services/payment.service';
import { QuestionairesService } from 'src/app/services/questionaires.service';
import { UtilityService } from 'src/app/services/utility.service';
import { SingleExerciseComponent } from 'src/app/training/single-exercise/single-exercise.component';
import { ImageEditorComponent } from 'src/app/utilities/image-editor/image-editor.component';
import { PaymentComponent } from '../payment/payment.component';

@Component({
  selector: 'app-product-editor',
  templateUrl: './product-editor.component.html',
  styleUrls: ['./product-editor.component.css']
})
export class ProductEditorComponent {
  
  public PaymentComponent = PaymentComponent

  product: Product
  hasChanges: boolean = false
  overwriteVatRate: boolean = false

  public availableQuestionaires: Questionaire[] = []

  constructor(public dialogRef: MatDialogRef<ProductEditorComponent>, @Inject(MAT_DIALOG_DATA) private data: {product: Product}, public userService: FirestoreService, private paymentService: PaymentService, public utilityService: UtilityService, private questionaireService: QuestionairesService, private dialog: MatDialog, private toastr: ToastrService, private ngZone: NgZone) { 
    this.product = data.product
    if (this.product.vatRate != null) this.overwriteVatRate = true
    if (this.product.thumbnailPath) {
      userService.getDownloadUrl(this.product.getFullThumbnailPath(userService.getLoggedInUser().licenceHolderUid)).then(url => this.product.thumbnailUrl = url)
    }
    this.questionaireService.getQuestionaires().then(questionaires => {
      this.availableQuestionaires = questionaires
    })
  }

  ngOnInit(): void {

  }

  get paymentSettings() {
    return this.paymentService.paymentSettings
  }

  getCurrency() {
    return this.product.currency ?? this.paymentSettings.currency
  }

  onCurrencySelected(currency: string) {
    this.product.currency = currency
  }

  onOverwriteVatRateChanged(value: boolean) {
    if (!value) this.product.vatRate = null
  }

  onSetRecurring(value: boolean) {
    this.product.recurring = value
    if (this.product.recurring) {
      if (this.product.duration == null) {
        this.product.duration = 1
      }
      if (!this.product.durationUnit) {
        this.product.durationUnit = 'month'
      }
      //this.product.licenceType = 'Coaching'
    }
  }
  onSetDurationFixed(value: boolean) {
    if (this.product.recurring) {
      this.product.durationMultiplier = value ? 1 : null
      if (!value) this.product.autoRenew = true
    } else {
      this.product.durationMultiplier = value ? 1 : null
      this.product.duration = value ? 1 : null
    }
    //this.product.licenceType = 'Coaching'
  }
  onSetNoDuration() {
  }

  onSetLicenceProduct() {
    this.product.licenceType = 'Coaching'
  }
  onSetNoLicenceProduct() {
    this.product.licenceType = null
  }

  getDurationInDays(): number {
    if (!this.product.duration) return 0
    if (this.product.durationUnit == 'day') return this.product.duration
    if (this.product.durationUnit == 'week') return this.product.duration * 7
    if (this.product.durationUnit == 'month') return this.product.duration * 30
    if (this.product.durationUnit == 'year') return this.product.duration * 365
    return this.product.duration
  }

  onSetDurationUnit(value: string) {
    this.product.durationUnit = value
  }
  
  priceInvalid: boolean = false
  initialSetupFeeInvalid: boolean = false

  onPriceChanged(value: string) {
    var value = value?.replace(',', '.')
    var parts = value?.split('.')
    if (parts.length > 2 || parts.length > 1 && parts[1].length > 2) {
      this.priceInvalid = true
      return
    }
    var price = parseFloat(value)
    if (price != null && !isNaN(price) && price >= 0) {
      this.product.price = Math.round(price * 100)
      this.priceInvalid = false
    } else {
      this.priceInvalid = true
    }
    this.hasChanges = true
  }
  onInitialSetupFeeChanged(value: string) {
    var value = value?.replace(',', '.')
    var parts = value?.split('.')
    if (parts?.length > 2 || parts?.length > 1 && parts[1].length > 2) {
      this.initialSetupFeeInvalid = true
      return
    }
    if (value == null || value == undefined || value == '') {
      this.product.initialSetupFee = null
      this.initialSetupFeeInvalid = false
      this.hasChanges = true
      return
    }
    var price = parseFloat(value)
    if (price != null && !isNaN(price) && price >= 0) {
      this.product.initialSetupFee = Math.round(price * 100)
      this.initialSetupFeeInvalid = false
    } else {
      this.initialSetupFeeInvalid = true
    }
    this.hasChanges = true
  }
  onSetAutoRenew(value: boolean) {
    if (!this.product.duration) return
    this.product.autoRenew = value
  }
  isCancelationPeriodValid() {
    if (!this.product.autoRenew) return true
    return this.product.cancelationPeriod >= 0 && this.product.cancelationPeriod < this.getDurationInDays()
  }

  onEnableStartDate() {
    this.product.startDate = new Date()
  }
  onSetStartDate(dateString: string) {
    var date = this.parseDateString(dateString)
    this.product.startDate = date
  }
  private parseDateString(date:string): Date {
    if(date == null) return null;
    date = date?.replace('T','-');
    var parts = date?.split('-');
    return new Date(+parts[0], +parts[1]-1, +parts[2]);
 }

  onSaveProduct() {
    if (this.product.price == null || this.product.price == undefined) this.product.price = 0
    if (this.priceInvalid || this.initialSetupFeeInvalid) {
      this.toastr.error('Der Preis ist ungültig', '', {positionClass: 'toast-bottom-center'})
      return
    }
    if (this.product.name == null || this.product.name.length == 0) {
      this.toastr.error('Bitte gib einen Namen für das Produkt an', '', {positionClass: 'toast-bottom-center'})
      return
    }
    if (this.product.recurring && (this.product.duration == null || this.product.duration == 0)) {
      this.toastr.error('Bitte gib eine Laufzeit an', '', {positionClass: 'toast-bottom-center'})
      return
    }
    if (this.product.price < 0) {
      this.toastr.error('Der Preis darf nicht negativ sein', '', {positionClass: 'toast-bottom-center'})
      return
    }
    if (this.product.recurring && !this.isCancelationPeriodValid()) {
      this.toastr.error('Die Kündigungsfrist muss mindestens 1 Tag betragen und kleiner als die Laufzeit sein.', '', {positionClass: 'toast-bottom-center'})
      return
    }
    if (this.overwriteVatRate && (this.product.vatRate == null || this.product.vatRate < 0 || this.product.vatRate > 100)) {
      this.toastr.error('Die Mehrwertsteuer muss zwischen 0 und 100 liegen', '', {positionClass: 'toast-bottom-center'})
      return
    }
    this.onCloseDialog(true, false);
  }

  onDeleteProduct() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du das Produkt wirklich löschen?', title: 'Produkt löschen', positiveButton: 'Ja', negativeButton: 'Nein' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.onCloseDialog(false, true)
      }
    })
  }

  onCancelDialog() {
    if (!this.hasChanges) {
      this.onCloseDialog(false, false)
      return
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du das Produkt vor dem Schließen speichern?', title: 'Produkt speichern', positiveButton: 'Ja', negativeButton: 'Nein' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.onSaveProduct()
      } else {
        this.onCloseDialog(false, false)
      }
    })
  }

  onCloseDialog(shouldSave: boolean, shouldDelete: boolean){
    this.dialogRef.close({shouldSave: shouldSave, shouldDelete: shouldDelete, newThumbnail: this.newThumbnail})
  }

  onUploadThumbnail() {
    document.getElementById('input-thumbnail').click()
  }
  uploadedThumbnail(e) {
    if (e.target.files && e.target.files[0]) {
      if (!SingleExerciseComponent.checkUploadFile(e.target.files[0], 50000000, ["jpg", "jpeg", "png"], this.toastr)) return;
      this.convertThumbnail(e.target.files[0])
      this.hasChanges = true
    }
  }


  onPreselectQuestionaire(questionaire: Questionaire) {
    if (this.product.onboardingQuestionaireIds.includes(questionaire.id)) {
      this.product.onboardingQuestionaireIds.forEach( (item, index) => {
        if (item == questionaire.id) this.product.onboardingQuestionaireIds.splice(index, 1)
      })
    } else {
      this.product.onboardingQuestionaireIds.push(questionaire.id)
    }
  }
  onHideNutritionValuesChanged() {
    this.product.hideNutritionValues = !this.product.hideNutritionValues
  }
  onHideChatChanged() {
    this.product.hideChat = !this.product.hideChat
  }
  getSelectedOnboardingQuestionaires(): string{
    var checkedElements = '';
    if(this.product.onboardingQuestionaireIds) {
      this.product.onboardingQuestionaireIds.forEach(id => {
        var name = this.getQuestionaireById(id)?.name
        if (name) {
          if (checkedElements.length > 0){
            checkedElements = checkedElements.concat(', ')
          }
          checkedElements = checkedElements.concat(name);
        }
      });
    }
    if (checkedElements.length > 0) return checkedElements
    return null
  }
  getQuestionaireById(id: string) {
    return this.availableQuestionaires.filter(q => q.id == id).shift()
  }
  getAssignedGroupNames(): string {
    var text = '';
    if (this.product.assignedClientGroups) {
      this.product.assignedClientGroups.forEach(name => {
        if (text.length > 0){
          text = text.concat(', ')
        }
        text = text.concat(name);
      });
    }
    if (text.length > 0) return text
    return null
  }

  onAssignToClientGroup(group: string) {
    if (this.product.assignedClientGroups.includes(group)) {
      this.product.assignedClientGroups.forEach( (item, index) => {
        if (item == group) this.product.assignedClientGroups.splice(index, 1)
      })
    } else {
      this.product.assignedClientGroups.push(group)
    }
  }
  getPrintableAssignedMetrics() {
    var checkedElements = '';
    if(this.product.assignedMetricIds) {
      this.product.assignedMetricIds.forEach(id => {
        var name = this.userService.getMetricByMetricId(id)?.nameTranslation['de']
        if (name) {
          if (checkedElements.length > 0){
            checkedElements = checkedElements.concat(', ')
          }
          checkedElements = checkedElements.concat(name);
        } else {
          this.userService.fetchMetricByMetricId(id).then(metric => {})
        }
      });
    }
    if (checkedElements.length > 0) return checkedElements
    return null
  }
  onChangeAssignedMetrics() {
    const dialogRef = this.dialog.open(MetricsSelectionDialogComponent, { data: { notAvailableMetricIds: this.product?.assignedMetricIds || [], canShowMetricSets: true}, width: '1200px'})
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.shouldTake) {
          result.metrics.forEach(metric => {
            if (!this.product?.assignedMetricIds.includes(metric.id)) {
              this.product.assignedMetricIds.push(metric.id)
            }
          });
        }
      }
    });
  }
  onClearAssignedMetrics() {
    this.product.assignedMetricIds = []
  }
  
  private newThumbnail: File = null;

  convertThumbnail(thumbnail: File) {
    const dialogRef = this.dialog.open(ImageEditorComponent, {
      data: { imageFile: thumbnail, aspectRatio: 1.5, maintainAspectRatio: true, containWithinAspectRatio: true, maxDimension: 750 },
      width: '1000px',
    });
    dialogRef.afterClosed().subscribe(async result => {
      if(result?.croppedImage) {
        var thumbnailImageSrc: any = await this.blobToBase64(result.croppedImage);
        this.newThumbnail = result.croppedImage;
        this.product.thumbnailUrl = thumbnailImageSrc
      }
    });
  }

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
}
