<div class="dialog-topbar">
    <h3>Email anpassen</h3>
</div>
<div class="dialog-content-wrapper">
    <p>
        <input class="input wide" [(ngModel)]="template.subject" placeholder="Betreff" type="text">
    </p>
    <br>
    <p>
        <input  class="input full-width" [(ngModel)]="template.heading" placeholder="Überschrift" type="text">
    </p>
    <br>
    <textarea class="input full-width" [(ngModel)]="template.body" placeholder="Inhalt" type="text" rows="10"></textarea><br>
    <ng-container *ngIf="type == 'invitation'">
        <div class="spacer-10"></div>
        <div class="label">
            Verfügbare Variablen: {{ "{{Name}\},{{LicenceCode}\}, {{LicenceLink}\}" }}
        </div>
    </ng-container>
    <div class="spacer-25"></div>
    <div class="actions row">
        <div class="col-12">
            <button class="button-filled small light" (click)="onCancelDialog()">Abbrechen</button>
            <button class="button-filled small light float-right" (click)="onConfirmDialog()">Bestätigen</button>
        </div>
    </div>
</div>