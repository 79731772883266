export interface ILanguageDictionary<Type>{
    originObject: Type;
    comparableValue: string;
    de: string;
    en: string;

    clone(): ILanguageDictionary<Type>;
    SetValue(languageCode:string, value:string);
    GetValue(languageCode:string):string;
    ParseFromMap(input:Record<string, string>)
    AsMap():Record<string,string>
}

export class LanguageDictionary<Type> implements ILanguageDictionary<Type> {
    originObject: Type;
    de: string = null;
    en: string = null;

    get comparableValue(): string {
        return this.de + this.en;
    }

    constructor(de?: string, en?: string, originObject?: Type) {
        this.de = de || null;
        this.en = en || null;
        this.originObject = originObject || null;
    }

    clone(): LanguageDictionary<Type> {
        return new LanguageDictionary<Type>(this.de, this.en);
    }

    hasAnyValue(): boolean {
        return this.comparableValue?.length > 0;
    }

    public SetValue(languageCode: string, value: string) {
        if (languageCode == "de") this.de = value;
        else if (languageCode == "en") this.en = value;
    }
    public GetValue(languageCode: string): string {
        return (this.AsMap()[languageCode])?.toString();
    }

    public ParseFromMap(input: Record<string, string>) {
        this.de = input["de"] || this.de;
        this.en = input["en"] || this.en;
    }

    public AsMap(): Record<string, string> {
        return {
            ["de"]: this.de,
            ["en"]: this.en
        };
    }
}
