<div class="page-content">
    <div class="container-fluid padding-0">
        <div class="row">
            <div class="col-12">
                <div class="recipe-tags marginleft-25">
                    <div class="chip link client-group {{selectedCategory == null ? ' active' : ''}}" (click)="onChangeCategory(null)">
                        Alle
                    </div>
                    <div class="chip link client-group {{selectedCategory == 'education' ? ' active' : ''}}" (click)="onChangeCategory('education')">
                        Weiterbildung
                    </div>
                    <div class="chip link client-group {{selectedCategory == 'business' ? ' active' : ''}}" (click)="onChangeCategory('business')">
                        Business-Coaching
                    </div>
                    <div class="chip link client-group {{selectedCategory == 'food' ? ' active' : ''}}" (click)="onChangeCategory('food')">
                        Lebensmittel/Supplemente
                    </div>
                </div>
            </div>
            <div class="spacer-10"></div>

            <div class="col-12 col-xxxl-10">
                <div class="masonry-container">
                    <ng-container *ngFor="let partner of partners">
                        <div class="masonry-item large" *ngIf="partner == 'nutrilize' && selectedCategory == null">
                            <div class="layout-box padding-0 referral-nutrilize">
                                <img src="assets/images/Referral Graphic full.png" class="referral-image" alt="">
                                <div *ngIf="referralCounter == 0" class="referral-counter">
                                    <img src="assets/images/Referral Counter 0.png" class="referral-counter-image" alt="">
                                </div>
                                <div *ngIf="referralCounter == 1" class="referral-counter">
                                    <img src="assets/images/Referral Counter 1.png" class="referral-counter-image" alt="">
                                </div>
                                <div *ngIf="referralCounter == 2" class="referral-counter">
                                    <img src="assets/images/Referral Counter 2.png" class="referral-counter-image" alt="">
                                </div>
                                <div *ngIf="referralCounter == 3" class="referral-counter">
                                    <img src="assets/images/Referral Counter 3.png" class="referral-counter-image" alt="">
                                </div>
                                <div *ngIf="referralCounter == null" class="referral-counter">
                                    <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
                                </div>
                                <div class="transparent-button cursor-pointer {{referralCounter == null ? 'invisible' : ''}}" (click)="onShareAffiliateLink()"></div>
                            </div>
                        </div>
                        <div class="masonry-item small" *ngIf="partner == 'david-shiva' && (selectedCategory == null || selectedCategory == 'business')">
                            <div class="layout-box padding-0 referral-david-shiva">
                                <img src="assets/images/Partner David Shiva.jpg" class="referral-image" alt="">
                                <a class="transparent-button" href="https://calendly.com/coachdavidshiva/30min" target="_blank"></a>
                            </div>
                        </div>
                        <div class="masonry-item large" *ngIf="partner == 'kaf-akademie' && (selectedCategory == null || selectedCategory == 'education')">
                            <div class="layout-box padding-0 referral-kaf-akademie">
                                <img src="assets/images/Partner KAF Akademie.png" class="referral-image" alt="">
                                <div class="video-wrapper">
                                    <iframe src="https://fast.wistia.net/embed/iframe/3rqvkxu6b4?videoFoam=true" title="Die Fitnesstrainer B-Lizenz ist tot! Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen="" width="800" height="450" style="width: 100%"></iframe>
                                </div>
                                <a class="transparent-button" href="https://calendly.com/kaf-akademie/erstgespraech" target="_blank"></a>
                            </div>
                        </div>
                        <div class="masonry-item small" *ngIf="partner == 'prepmymeal' && (selectedCategory == null || selectedCategory == 'food')">
                            <div class="layout-box padding-0 referral-prepmymeal">
                                <img src="assets/images/Partner prepmymeal.png" class="referral-image" alt="">
                                <a class="transparent-button" href="https://prepmymeal.com/?ref=n4HzuQ15hwSej0" target="_blank"></a>
                            </div>
                        </div>
                        <div class="masonry-item large" *ngIf="partner == 'musclemind' && (selectedCategory == null || selectedCategory == 'business')">
                            <div class="layout-box padding-0 referral-musclemind">
                                <img src="assets/images/Partner MuscleMind.png" class="referral-image" alt="">
                                <div class="video-wrapper">
                                    <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/901874607?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Musclemind - Förderungscheck"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                                </div>
                                <a class="transparent-button" href="https://calendly.com/musclemindstrategie/kennenlern-gesprach" target="_blank"></a>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            
        </div>
    </div>
</div>