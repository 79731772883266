import { NumberOfRounds, PlannedTrainingExercise, SetParameter2LabelMapping, SetParameter2LabelUnitMapping, SetParameter2SubHeadingMapping, SetParameter2UnitMapping } from './../../model/training-plan.model';
import { TrainingService } from './../../services/training.service';
import { ExtendedTrackedTrainingExercise, TrackedExerciseSet, TrackedSuperSet, TrackedSuperSetRounds, TrackedTrainingExercise, TrackedTrainingSession, TrackedTrainingSet, TrackedVideoRecording } from './../../model/training-monitoring.model';
import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TrainingSession, SetParameter } from 'src/app/model/training-plan.model';
import { MergedTrainingExercise } from 'src/app/model/training-exercise';
import { Questionaire, QuestionaireResult } from 'src/app/model/questionaires.model';
import { CompletedQuestionaireResultsDialogComponent } from 'src/app/questionaire/completed-questionaire-results-dialog/completed-questionaire-results-dialog.component';
import { User } from 'src/app/model/user.model';
import { UtilityService } from 'src/app/services/utility.service';
import { Activity } from 'src/app/model/activity.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { firstValueFrom } from 'rxjs';
import { SelectionDialogComponent } from 'src/app/selection-dialog/selection-dialog.component';

@Component({
  selector: 'app-training-history-dialog',
  templateUrl: './training-history-dialog.component.html',
  styleUrls: ['./training-history-dialog.component.css']
})
export class TrainingHistoryDialogComponent implements OnInit {

  public trainingSessionContainerList: TrainingSessionContainer[] = [];
  public allAvailableTraingingSessionContainers: TrainingSessionContainer[] = [];

  public selectedTrackedSuperSets: TrackedSuperSet[] = [];

  public userUid: string;
  public setParameter2LabelMapping = SetParameter2LabelMapping;
  public setParameter2UnitMapping = SetParameter2UnitMapping;
  public setParameter2LabelUnitMapping = SetParameter2LabelUnitMapping;
  public setParameter2SubHeadingMapping = SetParameter2SubHeadingMapping;
  public setParameter = SetParameter;

  public selectedLanguageCode = "de"

  public questionaireResults: QuestionaireResult[] = [];
  public selectedQuestionaireResults: QuestionaireResult[] = [];

  public user: User

  constructor(public dialogRef: MatDialogRef<TrainingHistoryDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {user: User, selectedTrackedTrainingSession: TrackedTrainingSession, selectedPlannedTrainingSession: TrainingSession, questionaireResults: QuestionaireResult[], trainingSessionContainerList: TrainingSessionContainer[], allAvailableTraingingSessionContainers: TrainingSessionContainer[]}, public dialog: MatDialog, public trainingService: TrainingService, public utilityService: UtilityService, private userService: FirestoreService) 
  {
    let selectedTrackedTrainingSession = data.selectedTrackedTrainingSession;
    let selectedPlannedTrainingSession = data.selectedPlannedTrainingSession;
    this.questionaireResults = data.questionaireResults;
    this.trainingSessionContainerList = data.trainingSessionContainerList || null;
    this.userUid = data.user?.uid
    this.user = data.user

    if (this.trainingSessionContainerList == null){
      this.trainingSessionContainerList = [];
      this.trainingSessionContainerList.push(new TrainingSessionContainer(selectedTrackedTrainingSession, selectedPlannedTrainingSession, this.questionaireResults));
    }
    this.allAvailableTraingingSessionContainers = data.allAvailableTraingingSessionContainers || [];
  }

  addComparisonTrackedSession(){
    // this.trainingSessionContainerList.push(new TrainingSessionContainer());
    this.allAvailableTraingingSessionContainers.forEach(container => {
      if(!container.trackedTrainingSession.sessionName){
        if(container.trackedTrainingSession.custom){
          container.trackedTrainingSession.sessionName = "Eigene Einheit";
        }
        else {
          container.trackedTrainingSession.sessionName = container.plannedTrainingSession?.nameTranslation?.GetValue(this.selectedLanguageCode) ?? "Unbekannte Einheit";
        }
      }
      
    });
    const dialogRef = this.dialog.open(SelectionDialogComponent, { data: { selectableItems: this.allAvailableTraingingSessionContainers.filter(x => this.trainingSessionContainerList.find(y => x.trackedTrainingSession == y.trackedTrainingSession) == null), relevantProperties: ['trackedTrainingSession.sessionName', 'trackedTrainingSession.startDate']}, width: '1000px'});
    dialogRef.afterClosed().subscribe(async result => {
      if (result){
        this.trainingSessionContainerList.push(result.selectedItem);
      }
    });
  }


  ngOnInit(): void {
  }

  onCloseDialog(){
    this.dialogRef.close()
  }
}

export class TrainingSessionContainer {
  public trackedTrainingSession: TrackedTrainingSession;
  public plannedTrainingSession: TrainingSession;
  public questionaireResults: QuestionaireResult[] = [];
  public trackedSuperSets: TrackedSuperSet[] = [];
  public activity: Activity

  constructor(trackedTrainingSession?: TrackedTrainingSession, plannedTrainingSession?: TrainingSession, questionaireResults?: QuestionaireResult[], activity?: Activity) {
    this.trackedTrainingSession = trackedTrainingSession
    this.plannedTrainingSession = plannedTrainingSession
    this.questionaireResults = questionaireResults
    this.activity = activity

    if(this.trackedTrainingSession){
      this.initSuperSets();
    }
  }

  initSuperSets(){
    this.trackedSuperSets = []
    let trackedExercises: ExtendedTrackedTrainingExercise[] = []
    this.trackedSuperSets.push(new TrackedSuperSet(trackedExercises))
    this.trackedTrainingSession.trackedTrainingExercises.forEach(trackedExercise => {
      let extendedTrackedExercise = ExtendedTrackedTrainingExercise.createFromTrackedTrainingExercise(trackedExercise)
      extendedTrackedExercise.isExerciseExchanged = trackedExercise?.plannedExerciseId != null && this.plannedTrainingSession?.exercises?.find(x => !x.deleted && x.id == trackedExercise?.plannedExerciseId && (x.exerciseId == trackedExercise?.exerciseId)) == null;
      trackedExercises.push(extendedTrackedExercise)
      if(!trackedExercise.nextSupersetExerciseId) {
        trackedExercises = []
        this.trackedSuperSets.push(new TrackedSuperSet(trackedExercises))
      }
      else {
        if(this.trackedSuperSets.filter(x => x.trackedSuperSetExercises == trackedExercises).length === 0) {
          this.trackedSuperSets.push(new TrackedSuperSet(trackedExercises))
        }
      }
    });
    this.trackedSuperSets.forEach(superSet => {
      let index: number = 0      
      while(superSet.trackedSuperSetExercises.filter(x => x.trackedSets.length > index).length > 0) {
        let superSetRound = new TrackedSuperSetRounds();
        superSet.trackedSuperSetRounds.push(superSetRound)
        superSet.trackedSuperSetExercises.forEach(exercise => {
          let trackedSet = exercise.trackedSets.find(x => (x.setNumber ?? 0) == index)
          if(trackedSet){
            let plannedExercise = this.plannedTrainingSession?.exercises?.filter(x => x.id == exercise?.plannedExerciseId)[0];
            superSetRound.trackedExerciseSets.push(new TrackedExerciseSet(trackedSet, exercise, superSet.trackedSuperSetExercises.indexOf(exercise), plannedExercise));
          }
        });
        index += 1
      }
    });
    this.combinePlannedTrainingExercises();
  }

  getPlannedSetParameterValue(trackedSuperSet: TrackedSuperSet, roundIndex: number, exerciseIndex: number, setParameter: SetParameter): string{
    try{
      let trackedExercise = trackedSuperSet.trackedSuperSetRounds[roundIndex]?.trackedExerciseSets[exerciseIndex].trackedTrainingExercise;
      if(trackedExercise.sets?.length > 0) {
        let plannedSet = trackedExercise.sets?.find(x => x.setNumber == trackedSuperSet.trackedSuperSetRounds[roundIndex]?.trackedExerciseSets[exerciseIndex]?.trackedTrainingSet?.setNumber);
        if(plannedSet?.[setParameter.toString()] != null){
          if(setParameter == SetParameter.time){
            return " / " + this.getSecondsAsTime(plannedSet[setParameter.toString()]);
          }
          else {
            return " / " + plannedSet[setParameter.toString()].toString();
          }
        }
      }


      let plannedExercise = trackedSuperSet.trackedSuperSetRounds[roundIndex]?.trackedExerciseSets[exerciseIndex]?.plannedTrainingExercise;
      
      let plannedSet = plannedExercise?.sets[roundIndex];
      if(trackedSuperSet.trackedSuperSetExercises.length > 1){
        if(trackedSuperSet.trackedSuperSetRounds[0].trackedExerciseSets[0].plannedTrainingExercise?.superSetConfig?.numberOfRounds == NumberOfRounds.AMRAP){
          let index = roundIndex % plannedExercise?.sets.length;
          plannedSet = plannedExercise?.sets[index];
        }
      }
      
      
      if(plannedSet && setParameter?.toString()){
        let value = plannedSet[setParameter.toString()] || "";
        
        if(value){
          if(setParameter == SetParameter.time){
            return " / " + this.getSecondsAsTime(value);
          }
          else {
            return " / " + value.toString();
          }
        }
      }
    }
    catch(e){
      console.error(e)
    }

    return "";
  }

  getSecondsAsTime(inputSeconds: number): string{
    if(inputSeconds == undefined) return "";
    if(inputSeconds < 0) inputSeconds = 0
    return inputSeconds.asDurationString();
  }

  combinePlannedTrainingExercises(){
    try {
      this.trackedSuperSets.forEach(trackedSuperSet => {
        trackedSuperSet.plannedSuperSetRounds = [];
        for (let roundIndex = 0; roundIndex < trackedSuperSet.trackedSuperSetRounds.length; roundIndex++) {
          const superSetRound = trackedSuperSet.trackedSuperSetRounds[roundIndex];
          let plannedSuperSetRound = [];
          trackedSuperSet.plannedSuperSetRounds.push(plannedSuperSetRound);
          for (let setIndex = 0; setIndex < superSetRound.trackedExerciseSets.length; setIndex++) {
            const exerciseSet = superSetRound.trackedExerciseSets[setIndex];
            let plannedValue = new Map<SetParameter, string>();
            plannedSuperSetRound.push(plannedValue);
            if(!exerciseSet.trackedTrainingExercise.isExerciseExchanged){
              let availableSetParameters = TrainingSessionContainer.getAvailableSetParametersByTrackedExerciseSets(superSetRound.trackedExerciseSets);
              availableSetParameters?.forEach(setParameter => {
                let value = this.getPlannedSetParameterValue(trackedSuperSet, roundIndex, setIndex, setParameter);
                plannedValue.set(setParameter, value);
              });
            }
            else {
              let availableSetParameters = TrainingSessionContainer.getAvailableSetParametersByTrackedExerciseSets(superSetRound.trackedExerciseSets);
              availableSetParameters?.forEach(setParameter => {
                plannedValue.set(setParameter, '');
              });
            }
          }
        }
      });
    }
    catch(ex){
      console.error(ex)
    }
  }


  static getAvailableSetParametersByTrackedExerciseSets(exerciseSets: TrackedExerciseSet[]): SetParameter[]{
    return Object.values(SetParameter).filter(parameter => this.trackedExercisesHaveValues(exerciseSets, parameter));
  }

  static trackedExercisesHaveValues(sets: TrackedExerciseSet[], setParameter: SetParameter): boolean {
    return sets.filter(x => x.trackedTrainingSet[setParameter.toString()] != null).length > 0;
  }
}