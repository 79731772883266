import { Component } from '@angular/core';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-settings-v2',
  templateUrl: './settings-v2.component.html',
  styleUrls: ['./settings-v2.component.css']
})
export class SettingsV2Component {

  constructor(public userService: FirestoreService) {
    this.isDarkModeEnabled = localStorage.getItem('darkMode') == 'true'
  }

  get isNutritionEnabled() {
    return this.userService.getLoggedInUser().isNutritionEnabled()
  }
  isDarkModeEnabled: boolean
  darkModeSettingChanged = false

  showTeamManagement = false

  get coach() {
    return this.userService.getLoggedInUser().coach
  }
  
  onNutritionEnabledChanged(value: boolean) {
    this.userService.getLoggedInUser().coach.nutritionEnabled = value
    this.userService.updateNutritionEnabled(this.userService.getLoggedInUser(), this.isNutritionEnabled)
  }
  onDarkModeEnabledChanged(value: boolean) {
    this.isDarkModeEnabled = value
    this.darkModeSettingChanged = true
    localStorage.setItem('darkMode', value ? 'true' : 'false')
  }
  onVoiceMessagesEnabledChanged() {
    var enabled = !this.coach.coacheeVoiceMessageEnabled
    this.userService.updateVoiceMessagesEnabled(this.coach, enabled)
  }

  onShowTeamManagement() {
    this.showTeamManagement = !this.showTeamManagement
  }
}
