import { SubTask, Task } from 'src/app/model/task.model';
import { Injectable } from '@angular/core';
import { IndividualFirebase } from '../app.module';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FirestoreService } from './firestore.service';
import { Observable, Subject, map, of } from 'rxjs';
import { DocumentReference } from 'firebase/firestore';
import { LanguageDictionary } from '../model/languagedictionary.model';
import { FilterObject } from '../filter-selection-dropdown/filter-selection-dropdown.component';

@Injectable({
  providedIn: 'root'
})
export class TaskManagementService {

  get firestore(): AngularFirestore {
    return this.mainFirebase.firestore
  }
  get fireStorage(): AngularFireStorage {
    return this.mainFirebase.storage
  }

  constructor(private mainFirebase: IndividualFirebase) { }

  private tasks: Task[] = []
  private observableTasks: Observable<Task[]> = null
  private subjectTasks: Subject<Task[]> = null
  public notDeletedTasks: Task[] = []
  public taskManagementVisible: boolean = false

  public selectedTask: Task = null;
  public taskIdToSelect: string = null;

  public selectableTags: LanguageDictionary<string>[] = [];


  showTaskManagementSideBar(taskIdToSelect: string = null){
    this.taskManagementVisible = true;
    this.setSelectedTask(taskIdToSelect);
  }

  hideTaskManagementSideBar(){
    this.taskManagementVisible = false;
    this.selectedTask = null;
  }

  setSelectedTask(taskIdToSelect: string = null){
    this.taskIdToSelect = taskIdToSelect;
    if(taskIdToSelect != null && this.tasks != null && this.tasks.length > 0){
      this.selectedTask = this.tasks.find(x => x.id == taskIdToSelect);
    }
  }

  public getTasks(): Task[] {
    return this.tasks;
  }

  public getObservableTasks(licenceHolderUid: string): Subject<Task[]> {
    if (this.subjectTasks == null) {
      this.setObservableTasks(licenceHolderUid)
    }
    return this.subjectTasks
  }

  private setObservableTasks(licenceHolderUid: string) {
    this.subjectTasks = new Subject<Task[]>();
    this.subjectTasks.next(this.tasks);
    this.observableTasks = this.firestore.collection('Tasks', ref => ref.where('licenceHolderUid', '==', licenceHolderUid)).valueChanges({idField: 'id'}).pipe(
      map(documents => {
        var tasks: Task[] = []
        for(let document of documents){
          var task = new Task(document as Task)
          if (!task.deleted) {
            task.id = document.id
            if((task as any).dueDate != null){
              task.dueDate = new Date((task as any).dueDate.seconds * 1000)
            }
            if((task as any).timestamp != null){
              task.timestamp = new Date((task as any).timestamp.seconds * 1000)
            }
            if((document as any).tags != null){
              task.tags = ((document as any).tags as string[])?.map(x => new LanguageDictionary<string>(x, x, x)) || []
            }
            // this.loadSubTasksForTask(task);
            tasks.push(task)
          }
        }
        tasks.sort((a, b) => a.dueDate?.getTime() - b.dueDate?.getTime())
        
        let availableTasks = tasks.filter(task => !task.deleted);
        for(let task of availableTasks){
          let availableTaskIndex = this.notDeletedTasks.indexOf(this.notDeletedTasks.find(t => t.id == task.id));
          if(availableTaskIndex >= 0){
            if(this.notDeletedTasks[availableTaskIndex].timestamp < task.timestamp){
              for(let property of Object.getOwnPropertyNames(task)){
                if(property != 'subTasks'){
                  this.notDeletedTasks[availableTaskIndex][property] = task[property];
                }
                else {
                  for(let subTask of task.subTasks){
                    let availableSubTaskIndex = this.notDeletedTasks[availableTaskIndex].subTasks.indexOf(this.notDeletedTasks[availableTaskIndex].subTasks.find(t => t.id == subTask.id));
                    if(availableSubTaskIndex >= 0){
                      if(this.notDeletedTasks[availableTaskIndex].subTasks[availableSubTaskIndex].timestamp < subTask.timestamp){
                        for(let subTaskProperty of Object.getOwnPropertyNames(subTask)){
                          this.notDeletedTasks[availableTaskIndex].subTasks[availableSubTaskIndex][subTaskProperty] = subTask[subTaskProperty];
                        }
                      }
                    }
                    else {
                      this.notDeletedTasks[availableTaskIndex].subTasks.push(subTask);
                    }
                  }
                }
              }
            }
          }
          else {
            this.notDeletedTasks.push(task);
          }
        }

        for(let task of this.notDeletedTasks){
          let availableTaskIndex = availableTasks.indexOf(availableTasks.find(t => t.id == task.id));
          if(availableTaskIndex < 0){
            this.notDeletedTasks.splice(this.notDeletedTasks.indexOf(task), 1);
          }
        }
        this.notDeletedTasks = tasks
        return tasks
      }));
      
    this.observableTasks.subscribe(tasks => {
      this.tasks = tasks;
      if(this.taskIdToSelect != null){
        this.selectedTask = this.tasks.find(x => x.id == this.taskIdToSelect);
        this.taskIdToSelect = null;
      }
      this.setSelectableTags(tasks.map(x => x.tags));
      this.subjectTasks.next(tasks);
    });
  }

  private setSelectableTags(array: LanguageDictionary<string>[][]){
    this.selectableTags = []
    array.forEach(element => {
      element.forEach(languageDict => {
        if(this.selectableTags.find(x => x.originObject.toString() === languageDict.originObject.toString()) == null) this.selectableTags.push(languageDict)
      })
    });
  }

  // async loadSubTasksForTask(task: Task): Promise<void> {
  //   try{
  //     let documents = await this.firestore.collection('Tasks').doc(task.id).collection("SubTasks").ref.get()
  //     var subTasks: SubTask[] = []
  //     for (let document of documents.docs) {
  //       var subTask = new SubTask(document.data() as SubTask)
  //       subTasks.push(subTask)
  //     }
  //     subTasks.sort((a, b) => a.position - b.position);
  //     task.subTasks = subTasks; 
  //   }
  //   catch(ex){
  //     console.error(ex);
  //   }
  // }

  // loadSubTasksForTask(task: Task): Observable<boolean> {
  //   return new Observable((observer) => {
  //     this.firestore.collection('Tasks/' + task.id + "/SubTasks").ref.get().then(async documents => {
  //       var subTasks: SubTask[] = []
  //       for (let document of documents.docs) {
  //         var subTask = new SubTask(document.data() as SubTask)
  //         subTasks.push(subTask)
  //       }
  //       subTasks.sort((a, b) => a.position - b.position);
  //       observer.next(true)
  //       observer.complete()
  //     })
  //   })
  // }

  // private async loadTasks(): Promise<void> {
  //   const documents = await this.firestore
  //       .collection('Tasks')
  //       .ref
  //       .where('deleted', '==', false)
  //       .where('assignedCoach', '==', this.userService.getLoggedInUser().uid)
  //       .get();
  //   const tasks: Task[] = [];
  //   for(let document of documents.docs){
  //     const task = new Task(document.data() as Task)
  //     task.id = document.id
  //     tasks.push(task)
  //   }
  //   this.tasks = tasks?.sort((a, b) => a.dueDate.getTime() - b.dueDate.getTime());
  // }

  // public async saveOrUpdateTask(task: Task, updateCorrespondingTasks: boolean): Promise<void> {
  //   if(task.id == null){
  //     await this.addTask(task);
  //   }
  //   else {
  //     await this.updateTask(task, updateCorrespondingTasks);
  //   }
  // }

  public async addTask(task: Task): Promise<void> {
    let ref = await this.firestore.collection('Tasks').add(task.asFirebaseMap());
    task.id = ref.id;
    await ref.update({refTaskId: ref.id, timestamp: new Date()});
    // this.addOrUpdateSubTasks(task);
    this.tasks.push(task);
  }


  public async updateTaskRepetition(task: Task): Promise<void> {
    let now = new Date();
    let lastDueDateTask = task;
    
    let updateTasks: Task[] = [];
    updateTasks.push(task);
    this.tasks = this.tasks.map(t => t.id == task.id ? task : t);
    
    if(task.refTaskId == task.id){
    }
    let correspondingTasks = this.tasks.filter(x => x.refTaskId == task.refTaskId && x.id != task.id);
    for(let correspondingTask of correspondingTasks){
      if(correspondingTask.dueDate > now){
        correspondingTask.deleted = true;
        correspondingTask.active = false;
      }
      else {
        if(correspondingTask?.dueDate > lastDueDateTask?.dueDate){
          lastDueDateTask = correspondingTask;
        }
        correspondingTask.repetition = task.repetition;
        correspondingTask.repetitionMultiplier = task.repetitionMultiplier;
        correspondingTask.dueDate = new Date(correspondingTask.dueDate.getFullYear(), correspondingTask.dueDate.getMonth(), correspondingTask.dueDate.getDate(), task.dueDate.getHours(), task.dueDate.getMinutes(), task.dueDate.getSeconds(), task.dueDate.getMilliseconds());

      }
      updateTasks.push(correspondingTask);
    }

    if(lastDueDateTask != null){
      lastDueDateTask.active = true;
    }

    for(let updateTask of updateTasks){
      await this.firestore.collection('Tasks').doc(updateTask.id).update(updateTask.asFirebaseMap());
    }
  }
  
  public async updateSingleTask(task: Task): Promise<void> {
    await this.firestore.collection('Tasks').doc(task.id).update(task.asFirebaseMap());
    this.tasks = this.tasks.map(t => t.id == task.id ? task : t);
  }

  public async updateAllRecurringTasks(task: Task, onlyFollowingTasks: boolean): Promise<void> {
    let tasks = this.tasks;
    let updateTasks: Task[] = [];
    updateTasks.push(task);

    let correspondingTasks = this.tasks?.filter(x => x.refTaskId == task.refTaskId && x.id != task.id)
    if(onlyFollowingTasks){
      correspondingTasks = correspondingTasks?.filter(x => x.dueDate >= task.dueDate);
    }
    if(correspondingTasks?.length > 0){
      for(let correspondingTask of correspondingTasks){
        let newTask = task.clone();
        newTask.dueDate = new Date(correspondingTask.dueDate.getFullYear(), correspondingTask.dueDate.getMonth(), correspondingTask.dueDate.getDate(), task.dueDate.getHours(), task.dueDate.getMinutes(), task.dueDate.getSeconds(), task.dueDate.getMilliseconds());
        newTask.id = correspondingTask.id;
        newTask.subTasks = [];
        newTask.active = correspondingTask.active;
        newTask.done = correspondingTask.done;
        for(let subTask of task.subTasks){
          let clonedSubTask = subTask.clone();
          let correspondingTaskSubTask = correspondingTask.subTasks.find(x => x.id == subTask.id);
          if(correspondingTaskSubTask != null){
            clonedSubTask.done = correspondingTaskSubTask.done;
          }
          newTask.subTasks.push(clonedSubTask);
        }
        updateTasks.push(newTask);
      }
    }
    for(let updateTask of updateTasks){
      await this.firestore.collection('Tasks').doc(updateTask.id).update(updateTask.asFirebaseMap());
    }
    
    // this.tasks = this.tasks.map(t => t.id == task.id ? task : t);
  }


  // private copyTaskWithoutDate(task: Task){
  //   let newTask: Task = task.clone();
  //   newTask.dueDate = task.dueDate;
  // }

  // public async addOrUpdateSubTasks(task: Task): Promise<void> {
  //   for(let i = 0; i < task.subTasks.length; i++){
  //     if(task.subTasks[i].id == null){
  //       await this.firestore.collection('Tasks/' + task.id + "/SubTasks").add(task.subTasks[i].asFirebaseMap());
  //     }
  //     else {
  //       await this.firestore.collection('Tasks/' + task.id + "/SubTasks").doc(task.subTasks[i].id).update(task.subTasks[i].asFirebaseMap());
  //     }
  //   }
  //   this.tasks = this.tasks.map(t => t.id == task.id ? task : t);
  // }

  public async completeTask(task: Task): Promise<void> {
    await this.firestore.collection('Tasks').doc(task.id).update({done: true, timestamp: new Date()});
    task.timestamp = new Date();
    task.done = true;
    this.tasks = this.tasks.map(t => t.id == task.id ? task : t);
  }

  public async unCompleteTask(task: Task): Promise<void> {
    await this.firestore.collection('Tasks').doc(task.id).update({done: false, timestamp: new Date()});
    task.timestamp = new Date();
    task.done = false;
    this.tasks = this.tasks.map(t => t.id == task.id ? task : t);
  }

  public async deleteSingleTask(task: Task): Promise<void> {
    // await this.firestore.collection('Tasks').doc(task.id).delete();
    await this.firestore.collection('Tasks').doc(task.id).update({deleted: true, timestamp: new Date(), active: false});
    task.deleted = true;
    task.timestamp = new Date();
    this.tasks = this.tasks.filter(t => t.id != task.id);
  }

  public async deleteAllRecurringTasks(task: Task, onlyFollowingTasks: boolean = false): Promise<void> {
    await this.firestore.collection('Tasks').doc(task.id).update({deleted: true, timestamp: new Date(), active: false});
    task.deleted = true;
    task.timestamp = new Date();
    this.tasks = this.tasks.filter(t => t.id != task.id);

    let correspondingTasks = this.tasks.filter(x => x.refTaskId == task.refTaskId && x.id != task.id && x.deleted == false);
    
    if(onlyFollowingTasks){
      correspondingTasks = correspondingTasks.filter(x => x.dueDate >= task.dueDate);
    }
    if(correspondingTasks?.length > 0){
      correspondingTasks.sort((a, b) => (a.dueDate?.getTime() - b.dueDate?.getTime()));
      let now = new Date();
      let lastSet: boolean = false;
      for(let correspondingTask of correspondingTasks){
        if(correspondingTask.dueDate > now){
          correspondingTask.deleted = true;
          correspondingTask.active = false;
        }
        else {
          if(lastSet == false){
            correspondingTask.active = true;
          }
        }
        await this.firestore.collection('Tasks').doc(correspondingTask.id).update({deleted: true, timestamp: new Date(), active: false});
      }
    }
  }

  public async deleteOnlyOneRecurringTask(task: Task): Promise<void> {
    await this.firestore.collection('Tasks').doc(task.id).update({deleted: true, timestamp: new Date()});
    task.deleted = true;
    task.timestamp = new Date();
    this.tasks = this.tasks.filter(t => t.id != task.id);
  }

}
