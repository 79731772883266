import { LanguageService } from './../../services/language.service';
import { UtilityService } from './../../services/utility.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { TrainingService } from './../../services/training.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TrainingPlan } from 'src/app/model/training-plan.model';
import { User } from 'src/app/model/user.model';
import { FirestoreNutritionPlanService } from 'src/app/services/firestore-nutritionplan.service';
import { CreateTemplateDialogComponent } from 'src/app/create-template-dialog/create-template-dialog.component';
import {MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { TrainingPlanEditorComponent } from '../training-plan-editor/training-plan-editor.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { TrainingPlanTemplateFolder } from 'src/app/model/training-plan-template-folder.model';
import { TrainingPlanTemplateFolderEditorComponent } from '../training-plan-template-folder-editor/training-plan-template-folder-editor.component';
import { ToastrService } from 'ngx-toastr';
import { TrainingPlanTemplatesSelectionDialogComponent } from '../training-plan-templates-selection-dialog/training-plan-templates-selection-dialog.component';
import { TrainingVariableEditorDialogComponent, TrainingVariableEditorDialogType } from '../training-variable-editor-dialog/training-variable-editor-dialog.component';
import { VisualSelectionDialogComponent } from 'src/app/dialogs/visual-selection-dialog/visual-selection-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-training-templates',
  templateUrl: './training-templates.component.html',
  styleUrls: ['./training-templates.component.css']
})
export class TrainingTemplatesComponent implements OnInit {

  public environment = environment

  public spinnerText = ""
  public selectedTemplateTab: string = "OWN"
  public filteredTrainingPlanTemplates: TrainingPlan[]
  public allTrainingPlanTemplates: TrainingPlan[]
  public searchInput: string
  public selectedTrainingTemplate: TrainingPlan;

  @Input() selectionMode: boolean;
  @Output() trainingPlanTemplateSelectionChanged = new EventEmitter<TrainingPlan>();
  @Output() onDragStartTrainingPlanTemplate = new EventEmitter<TrainingPlan>();

  public availableTemplateFilters: string[] = Object.keys(TemplateFilter).map(key => TemplateFilter[key]);
  public selectedTemplateFilter: string = this.availableTemplateFilters[0];

  public user: User;

  public availableTrainingPlanTemplateFolders: TrainingPlanTemplateFolder[] = [];
  public selectedTrainingPlanTemplateFolder: TrainingPlanTemplateFolder = null;

  public templateSharingEnabled: boolean = false;
  get isCoacheeTrainingPlanCreationEnabled() {
    return this.userService.getLoggedInUser().isCoacheeTrainingPlanCreationEnabled()
  }

  constructor(public trainingService: TrainingService, public userService: FirestoreService, public dialog: MatDialog, public utilityService: UtilityService, private spinner: NgxSpinnerService, public languageService: LanguageService, private toastr: ToastrService) { }

  async ngOnInit(): Promise<void> {
    await this.updateFilteredTemplates();
    await this.updateTemplateFolders();
    this.templateSharingEnabled = this.userService.getLoggedInUser()?.coach?.trainingPlanTemplateSharingEnabled || false;
  }

  onAdministratorModeChanged(value:boolean){
    if(this.trainingService.CanEnterTrainingTemplateAdministratorEditMode == true){
      this.trainingService.TrainingTemplateAdministratorEditModeActivated = value
      this.updateTemplateFolders()
      this.updateFilteredTemplates()
    }
  }

  onCoacheeTrainingPlanCreationEnabledChanged(value: boolean) {
    this.userService.getLoggedInUser().coach.coacheeTrainingPlanCreationEnabled = value
    this.userService.updateCoacheeTrainingPlanCreationEnabled(this.userService.getLoggedInUser(), this.isCoacheeTrainingPlanCreationEnabled)
  }

  onTemplateSharingEnabledChanged(value: boolean) {
    this.templateSharingEnabled = value
    this.userService.updateTrainingPlanTemplateSharingEnabled(this.userService.getLoggedInUser(), this.templateSharingEnabled)
    if (this.templateSharingEnabled) {
      this.toastr.warning("Durch diese Einstellung wird noch nichts automatisch geteilt. Du musst jede Vorlage individuell teilen und Nutzern zuweisen.", "",  {
        positionClass: 'toast-bottom-center',
        timeOut: 10000,
      });
    }
  }

  onTemplateFilterChanged(filter: string){
    this.selectedTemplateFilter = filter;
    this.updateFilteredTemplates();
  }

  onShowAllTrainingPlanTemplates(){
    this.selectedTrainingPlanTemplateFolder = null;
    this.updateFilteredTemplates();
  }

  onSelectTrainingPlanTemplateFolder(folder: TrainingPlanTemplateFolder){
    this.selectedTrainingPlanTemplateFolder = folder;
    this.selectedTrainingTemplate = null;
    this.updateFilteredTemplates();
  }

  async deleteTrainingPlanTemplateFolder(folder: TrainingPlanTemplateFolder){
    try{
      this.spinner.show("templates-spinner");
      folder.deleted = true;
      let removeTemplates = this.allTrainingPlanTemplates.filter(x => x.folderIds.includes(folder.id));
      await this.trainingService.saveTrainingPlanTemplateFolder(folder, false, null, null, removeTemplates);
      this.spinner.hide("templates-spinner");
      await this.updateTemplateFolders();
      this.onShowAllTrainingPlanTemplates();
    }
    catch(ex){
      console.error(ex);
      this.toastr.error("Es ist ein unbekannter Fehler aufgetreten.", "Löschen fehlgeschlagen", {
        positionClass: 'toast-bottom-center'
      });
    }
    finally{
      this.spinner.hide("templates-spinner");
    }
  }

  onCopySelectedTemplateFolder(folder: TrainingPlanTemplateFolder){
    let tempFolder = folder.clone();
    // tempFolder.id = null;
    tempFolder.name.SetValue(this.languageService.selectedLanguageCode, tempFolder.name.GetValue(this.languageService.selectedLanguageCode) + " (Kopie)");

    if(folder.licenceHolderUid != this.trainingService.TemplateUserUid) {
      tempFolder.assignedUids = [];
      tempFolder.assignedGroupNames = [];
    }
    if(this.trainingService.TrainingTemplateAdministratorEditModeActivated && this.trainingService.CanEnterTrainingTemplateAdministratorEditMode){
      tempFolder.licenceHolderUid = "nutrilize"
    }
    else{
      tempFolder.licenceHolderUid = this.userService.getLoggedInUser().licenceHolderUid
    }
    const dialogRef = this.dialog.open(TrainingPlanTemplateFolderEditorComponent, { data: { selectedTrainingPlanTemplateFolder: tempFolder}, width: '1000px', autoFocus: false})
    dialogRef.afterClosed().subscribe(async result => {
      if(result){
        if(result.save){
          try{
            this.spinner.show("templates-spinner");
            tempFolder.id = null;
            let removedTemplates: TrainingPlan[] = result.removedTemplates || [];
            let newTemplates: TrainingPlan[] = result.newTemplates || [];
            let trainingPlanTemplates = (await this.trainingService.getTrainingPlanTemplates()).filter(x => x.folderIds?.includes(folder.id));
            if(folder.licenceHolderUid != this.trainingService.TemplateUserUid) {
              for(let removedTemplate of removedTemplates){
                trainingPlanTemplates = trainingPlanTemplates.filter(x => x.id != removedTemplate.id);
                newTemplates = newTemplates.filter(x => x.id != removedTemplate.id);
              }
              removedTemplates = [];
              newTemplates = newTemplates.map(x => x.clone());
              for(let newTemplate of newTemplates){
                newTemplate.id = null;
              }
              for(let template of trainingPlanTemplates){
                await this.trainingService.getTrainingPlanTemplatePlannedExercises(template);
                let clonedTemplate = template.clone();
                clonedTemplate.id = null;
                clonedTemplate.folderIds = []
                if(this.trainingService.TrainingTemplateAdministratorEditModeActivated && this.trainingService.CanEnterTrainingTemplateAdministratorEditMode){
                  clonedTemplate.licenceHolderUid = "nutrilize"
                  clonedTemplate.coachUid = "nutrilize"
                }
                else{
                  clonedTemplate.licenceHolderUid = this.userService.getLoggedInUser().licenceHolderUid
                  clonedTemplate.coachUid = this.userService.getLoggedInUser().uid
                }
                newTemplates.push(clonedTemplate);
              }
            }
            else {
              for(let template of trainingPlanTemplates){
                if(removedTemplates.find(x => x.id == template.id) == null){
                  if(newTemplates.find(x => x.id == template.id) == null){
                    newTemplates.push(template.clone());
                  }
                }
              }
            }

            await this.trainingService.saveTrainingPlanTemplateFolder(tempFolder, result.removeThumbnail, result.newThumbnail, newTemplates, removedTemplates);
            await this.updateTemplateFolders();
            this.selectedTemplateTab = "OWN";
            let templateFolderToSelect = this.availableTrainingPlanTemplateFolders.find(x => x.id == tempFolder.id);
            this.onSelectTrainingPlanTemplateFolder(templateFolderToSelect);
          }
          catch(ex){
            console.error(ex);
            this.toastr.error("Es ist ein unbekannter Fehler aufgetreten.", "Fehler", {
              positionClass: 'toast-bottom-center'
            });
          }
          finally{
            this.spinner.hide("templates-spinner");
          }
        }
      }
    });
  }

  onEditSelectedTemplateFolder(folder: TrainingPlanTemplateFolder){
    let tempFolder = folder.clone();
    if(folder.licenceHolderUid != this.trainingService.TemplateUserUid) return;
    const dialogRef = this.dialog.open(TrainingPlanTemplateFolderEditorComponent, { data: { selectedTrainingPlanTemplateFolder: tempFolder}, width: '1000px', autoFocus: false})
    dialogRef.afterClosed().subscribe(async result => {
      if(result){
        if(result.delete){
          await this.deleteTrainingPlanTemplateFolder(tempFolder);
        }
        else if(result.save){
          try{
            this.spinner.show("templates-spinner");
            await this.trainingService.saveTrainingPlanTemplateFolder(tempFolder, result.removeThumbnail, result.newThumbnail, result.newTemplates, result.removedTemplates);
            await this.updateTemplateFolders();
            let templateFolderToSelect = this.availableTrainingPlanTemplateFolders.find(x => x.id == tempFolder.id);
            this.onSelectTrainingPlanTemplateFolder(templateFolderToSelect);
          }
          catch(ex){
            console.error(ex);
            this.toastr.error("Es ist ein unbekannter Fehler aufgetreten.", "Fehler", {
              positionClass: 'toast-bottom-center'
            });
          }
          finally{
            this.spinner.hide("templates-spinner");
          }
        }
      }
    });
  }

  calculateNumberOfTemplatesInFolder(folder){
    return this.allTrainingPlanTemplates?.filter(x => x.folderIds.includes(folder.id))?.length || 0;
  }

  getNumberOfTemplatesInFolder(folder: TrainingPlanTemplateFolder){
    return this.calculateNumberOfTemplatesInFolder(folder);
    // if(folder.getTempNumberOfTemplates() != null && folder.getTempNumberOfTemplates() != 0) return folder.getTempNumberOfTemplates();
    // else {
    //   folder.setTempNumberOfTemplates(this.calculateNumberOfTemplatesInFolder(folder));
    //   return folder.getTempNumberOfTemplates();
    // }
  }

  calculateSharedWith(folder: TrainingPlanTemplateFolder){
    let users = this.userService.getClients();
    let sharedWithUsers = users.filter(x => folder.assignedUids.includes(x.uid));
    let sharedWithGroups = folder.assignedGroupNames.filter(x => this.userService.getClientGroups()?.includes(x));

    let sharedWith = "";
    if(sharedWithUsers.length > 0){
      sharedWith += sharedWithUsers.map(x => x.name).join(", ");
    }
    if(sharedWithGroups.length > 0){
      if(sharedWith.length > 0) sharedWith += ", ";
      sharedWith += sharedWithGroups.join(", ");
    }

    return sharedWith;
  }

  getSharedWith(folder: TrainingPlanTemplateFolder){
    if(folder.getTempSharedWith() != null) return folder.getTempSharedWith();
    else {
      folder.setTempSharedWith(this.calculateSharedWith(folder));
      return folder.getTempSharedWith();
    }
  }

  onCreateTrainingPlanTemplateFolder(){
    let newTrainingPlanTemplateFolder = new TrainingPlanTemplateFolder();
    if(this.trainingService.TrainingTemplateAdministratorEditModeActivated && this.trainingService.CanEnterTrainingTemplateAdministratorEditMode){
      newTrainingPlanTemplateFolder.licenceHolderUid = "nutrilize"
    }
    else{
      newTrainingPlanTemplateFolder.licenceHolderUid = this.userService.getLoggedInUser().licenceHolderUid
    }
    const dialogRef = this.dialog.open(TrainingPlanTemplateFolderEditorComponent, { data: { selectedTrainingPlanTemplateFolder: newTrainingPlanTemplateFolder}, width: '1000px', autoFocus: false})
    dialogRef.afterClosed().subscribe(async result => {
      if(result){
        if(result.save){
          try{
            this.spinner.show("templates-spinner");
            await this.trainingService.saveTrainingPlanTemplateFolder(newTrainingPlanTemplateFolder, result.removeThumbnail, result.newThumbnail, result.newTemplates, result.removedTemplates);
            await this.updateTemplateFolders();
            let templateFolderToSelect = this.availableTrainingPlanTemplateFolders.find(x => x.id == newTrainingPlanTemplateFolder.id);
            this.onSelectTrainingPlanTemplateFolder(templateFolderToSelect)
          }
          catch(ex){
            console.error(ex);
            this.toastr.error("Es ist ein unbekannter Fehler aufgetreten.", "Fehler", {
              positionClass: 'toast-bottom-center'
            });
          }
          finally{
            this.spinner.hide("templates-spinner");
          }
        }
      }
    })
  }

  onCreateTemplate(){
    this.user = this.userService.getLoggedInUser()
    this.selectedTrainingTemplate = new TrainingPlan()
    this.selectedTrainingTemplate.isTemplate = true
    if(this.trainingService.TrainingTemplateAdministratorEditModeActivated && this.trainingService.CanEnterTrainingTemplateAdministratorEditMode){
      this.selectedTrainingTemplate.userEditable = true;
    }

    const visualSelectionDialogRef = this.dialog.open(VisualSelectionDialogComponent, {
      data: { title: 'Wähle den gewünschten Plantyp' },
    });
    visualSelectionDialogRef.afterClosed().subscribe(result => {
      if(result){
        if(result.isPeriodic == true) {
          this.selectedTrainingTemplate.isPeriodicPlan = true;
        }
        const dialogRef = this.dialog.open(TrainingPlanEditorComponent, { data: { trainingPlan: this.selectedTrainingTemplate, coach: this.userService.getLoggedInUser()}, width: '1000px', autoFocus: false})
        dialogRef.afterClosed().subscribe(async result => {
          if(result){
            if(result.save){
              if(this.selectedTrainingPlanTemplateFolder?.id != null){
                this.selectedTrainingTemplate.folderIds.push(this.selectedTrainingPlanTemplateFolder.id);
              }
              this.onSaveTemplate(this.selectedTrainingTemplate, result.removeThumbnail, result.newThumbnail);
            }
            else {
              this.cancelEditTrainingTemplate()
            }
          }
        });
      }
    });

  }

  onTemplateSearchInputChanged(text: string) {
    this.searchInput = text
    this.updateFilteredTemplates()
  }

  async updateTemplateFolders() {
    try{
      this.spinner.show("templates-spinner");
      this.availableTrainingPlanTemplateFolders = await this.trainingService.getTrainingPlanTemplateFolders();
    }
    catch(ex){
      console.error(ex);
    }
    finally{
      this.spinner.hide("templates-spinner");
    }
  }

  isTemplateFiltered(template: TrainingPlan){
    switch(this.selectedTemplateFilter){
      case TemplateFilter.ALL:
        return true;
      case TemplateFilter.IN_FOLDER:
        return this.getTrainingTemplateFoldersOfTemplate(template)?.length > 0;
      case TemplateFilter.NOT_IN_FOLDER:
        return this.getTrainingTemplateFoldersOfTemplate(template)?.length == 0;
      case TemplateFilter.SHARED:
        if(this.templateSharingEnabled == false) return false;
        if(template.assignedGroupNames?.length > 0 || template.assignedUids?.length > 0) return true;
        let trainingPlanTemplateFolders = this.getTrainingTemplateFoldersOfTemplate(template);
        return trainingPlanTemplateFolders?.find(x => x.assignedGroupNames?.length > 0 || x.assignedUids?.length > 0) != null;
      case TemplateFilter.NOT_SHARED:
        if(this.templateSharingEnabled == false) return true;
        if(template.assignedGroupNames?.length > 0 || template.assignedUids?.length > 0) return false;
        let trainingPlanTemplateFolders2 = this.getTrainingTemplateFoldersOfTemplate(template);
        return trainingPlanTemplateFolders2?.find(x => x.assignedGroupNames?.length > 0 || x.assignedUids?.length > 0) == null;
      default:
        return true;
    }
  }

  async updateFilteredTemplates() {
    try{
      this.filteredTrainingPlanTemplates = []
      this.spinner.show("templates-spinner");
      this.allTrainingPlanTemplates = await this.trainingService.getTrainingPlanTemplates();
      this.spinner.hide("templates-spinner");
      if ((!this.searchInput || this.searchInput.length == 0)) {
        this.filteredTrainingPlanTemplates = this.allTrainingPlanTemplates.filter(x => (this.selectedTrainingPlanTemplateFolder == null && this.isTemplateFiltered(x)) || x.folderIds.includes(this.selectedTrainingPlanTemplateFolder?.id));
      } else {
        this.allTrainingPlanTemplates.forEach(template => {
          if (template?.getName()?.toLowerCase()?.includes(this.searchInput?.toLowerCase()) && (this.selectedTrainingPlanTemplateFolder == null && this.isTemplateFiltered(template) || template.folderIds?.includes(this.selectedTrainingPlanTemplateFolder?.id))) {
            this.filteredTrainingPlanTemplates.push(template);
          }
        });
      }
    }
    catch(ex){
      console.error(ex);
    }
  }

  async onDeleteTemplateSearchInput() {
    this.searchInput = null;
    (<HTMLInputElement> document.getElementById('templatesearch-input')).value = ''
    this.updateFilteredTemplates();
  }

  onSelectTemplateTab(tabName: string){
    this.selectedTemplateTab = tabName;
    this.selectedTrainingPlanTemplateFolder = null;
    this.updateFilteredTemplates();
  }

  getPrintableTemplateFolderNames(template: TrainingPlan){
    return this.getTrainingTemplateFoldersOfTemplate(template).map(x => x.name.GetValue(this.languageService.selectedLanguageCode)).join(", ");
    // if(template.folderIds == null || template.folderIds.length == 0) return "";
    // if(template.getTempPrintableTemplateFolderNames() != null && template.getTempPrintableTemplateFolderNames().length != 0) return template.getTempPrintableTemplateFolderNames();
    // else {
    //   template.setTempPrintableTemplateFolderNames(this.getTrainingTemplateFoldersOfTemplate(template).map(x => x.name.GetValue(this.languageService.selectedLanguageCode)).join(", "));
    //   return template.getTempPrintableTemplateFolderNames();
    // }
  }

  getTrainingTemplateFoldersOfTemplate(template: TrainingPlan){
    return this.availableTrainingPlanTemplateFolders?.filter(x => template.folderIds.includes(x.id)) || [];
  }

  async onOpenTemplate(template: TrainingPlan){
    try {
      this.spinner.show();
      await this.trainingService.getTrainingPlanTemplatePlannedExercises(template);
    } catch(ex) {
      console.error(ex);
      this.toastr.error("Es ist ein unbekannter Fehler aufgetreten.", "Fehler", {
        positionClass: 'toast-bottom-center'
      });
    } finally {
      this.spinner.hide()
    }
    if(this.selectionMode){
      this.trainingPlanTemplateSelectionChanged.emit(template);
      return;
    }
    this.user = this.userService.getLoggedInUser()
    this.selectedTrainingTemplate = template.clone()
    console.log('OPen template ', this.selectedTrainingTemplate.id)
    let readOnlyMode = this.selectedTrainingTemplate.licenceHolderUid != this.trainingService.TemplateUserUid
    const dialogRef = this.dialog.open(TrainingPlanEditorComponent, { data: { trainingPlan: this.selectedTrainingTemplate, trainingTemplateFolders: this.getTrainingTemplateFoldersOfTemplate(template), readOnlyMode: readOnlyMode, coach: this.userService.getLoggedInUser()}, width: '1000px', autoFocus: false})
    dialogRef.afterClosed().subscribe(async result => {
      if(result){
        if(result.save){
          if(readOnlyMode){
            this.toastr.show("Da du diese Vorlage nicht erstellt hast, wird eine Kopie erstellt.", "Vorlage wird kopiert");
            if(this.trainingService.TrainingTemplateAdministratorEditModeActivated && this.trainingService.CanEnterTrainingTemplateAdministratorEditMode){
              this.selectedTrainingTemplate.coachUid = "nutrilize"
              this.selectedTrainingTemplate.licenceHolderUid = this.trainingService.TemplateUserUid
            }
            else{
              this.selectedTrainingTemplate.coachUid = this.userService.getLoggedInUser().coachUid
              this.selectedTrainingTemplate.licenceHolderUid = this.userService.getLoggedInUser().licenceHolderUid
            }
            await this.copyTrainingTemplate(this.selectedTrainingTemplate);
          }
          else {
            await this.onSaveTemplate(this.selectedTrainingTemplate, result.removeThumbnail, result.newThumbnail)
          }
        }
        else if(result.delete) {
          if(readOnlyMode){
            this.toastr.show("Da du diese Vorlage nicht erstellt hast, kann diese nicht gelöscht werden.", "Vorlage kann nicht gelöscht werden");
            return;
          }
          await this.deleteTrainingTemplate(this.selectedTrainingTemplate)
        }
        else {
          this.cancelEditTrainingTemplate()
        }
      }
    })
  }

  async onSaveTemplate(template: TrainingPlan, removeThumbnail: boolean, newThumbnail: File = null){
    this.spinner.show("templates-spinner")
    if (template.id) {
      await this.trainingService.updateTrainingPlanTemplate(template, removeThumbnail, newThumbnail)
      this.selectedTrainingTemplate = null
    } else {
      await this.saveTrainingPlanAsTemplate(template, newThumbnail)
    }
    await this.updateFilteredTemplates()  
    this.spinner.hide("templates-spinner")
  }

  async onCopyTempalte(template: TrainingPlan) {
    await this.trainingService.getTrainingPlanTemplatePlannedExercises(template);
    var newTemplate = template.clone();
    this.saveTrainingPlanAsTemplate(newTemplate)
  }
  getBackgroundImage(template: TrainingPlan){
    if(template.imageDownloadURL == null) return null;
    return {'background-image': "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('"+template.imageDownloadURL+"')"};
  }

  public async saveTrainingPlanAsTemplate(template: TrainingPlan, newThumbnail: File = null){

    this.selectedTrainingTemplate = null
    template.name = template.name || 'Vorlage'
    if(this.trainingService.TrainingTemplateAdministratorEditModeActivated && this.trainingService.CanEnterTrainingTemplateAdministratorEditMode){
      template.coachUid = "nutrilize"
      template.licenceHolderUid = this.trainingService.TemplateUserUid
    }
    else{
      template.coachUid = this.userService.getLoggedInUser().coachUid
      template.licenceHolderUid = this.userService.getLoggedInUser().licenceHolderUid
    }
    template.isTemplate = true

    template.weeks.forEach(week => {
      let newWeekId = FirestoreNutritionPlanService.generateUniqueString(8)
      template.sessions.forEach(session => {
        if(session.weekId == week.id){
          session.weekId = newWeekId
        }
      });
      week.id = newWeekId
    });

    template.sessions.forEach(session => {
      let newSessionId = FirestoreNutritionPlanService.generateUniqueString(8)
      template.sessions.forEach(baseSession => {
        if(baseSession.baseSessionId == session.id){
          baseSession.baseSessionId = newSessionId;
        }
      });
      session.exercises.forEach(exercise => {
        exercise.id = null
        exercise.sessionId = newSessionId
      });
      session.id = newSessionId;
    });

    template.sessions = template.sessions.filter(x => !x.deleted)
    template.sessions.map(x => x.exercises = x.exercises.filter(x => !x.deleted))

    this.spinner.show("templates-spinner");
    await this.trainingService.saveTrainingPlanTemplate(template, newThumbnail)
    this.spinner.hide("templates-spinner");
  }

  cancelEditTrainingTemplate(){
    this.selectedTrainingTemplate = null
  }

  async deleteTrainingTemplate(deleteTemplate: TrainingPlan){
    let dialogResult = false;
    if(this.selectedTrainingPlanTemplateFolder != null) {
      dialogResult = await this.dialog.open(ConfirmationDialogComponent, {
        data: { message: 'Möchtest du diese Vorlage aus dem Ordner entfernen oder vollständig löschen?', title: 'Trainingsplan löschen', positiveButton: 'Aus Ordner entfernen', negativeButton: 'Vollständig löschen' },
      }).afterClosed().toPromise();
    }
  
    let template = deleteTemplate.clone();
    if (dialogResult == true) {
      this.spinner.show("templates-spinner")
      template.folderIds = template.folderIds.filter(x => x != this.selectedTrainingPlanTemplateFolder.id);
      await this.trainingService.updateTrainingPlanTemplate(template, false, null);
      await this.updateFilteredTemplates()
      this.spinner.hide("templates-spinner")
    }
    else if (dialogResult == false) {
      const dialogRef2 = this.dialog.open(ConfirmationDialogComponent, {
        data: { message: 'Möchtest du diese Vorlage wirklich vollständig löschen?', title: 'Trainingsplan löschen' },
      });
      dialogRef2.afterClosed().subscribe(async result => {
        if (result) {
          this.spinner.show("templates-spinner")
          this.selectedTrainingTemplate = null
          await this.trainingService.deleteTrainingPlanTemplate(template)
          await this.updateFilteredTemplates()
          this.spinner.hide("templates-spinner")
        }
      });
    }
  }

  async copyTrainingTemplate(template: TrainingPlan){
    await this.trainingService.getTrainingPlanTemplatePlannedExercises(template);
    var newTemplate = template.clone()
    newTemplate.id = null
    if(template.licenceHolderUid != this.trainingService.TemplateUserUid){
      newTemplate.folderIds = [];
    }
    newTemplate.nameTranslation.SetValue(this.languageService.selectedLanguageCode, newTemplate.nameTranslation.GetValue(this.languageService.selectedLanguageCode) + " (Kopie)");
    await this.saveTrainingPlanAsTemplate(newTemplate)
    await this.updateFilteredTemplates()
  }

  async onAddTrainingPlanTemplates(){
    // this.newTrainingPlanTemplates.push(new TrainingPlan());
    this.dialog.open(TrainingPlanTemplatesSelectionDialogComponent, {data: {availableTemplates: (await this.trainingService.getTrainingPlanTemplates()).filter(x => !x.folderIds?.includes(this.selectedTrainingPlanTemplateFolder.id)), availableTrainingPlanTemplateFolders: this.availableTrainingPlanTemplateFolders}, width: '1000px', autoFocus: false}).afterClosed().subscribe(async result => {
      if (result?.shouldTake){
        console.log(result)
        for(let selectedTemplate of result.selectedTemplates){
          await this.trainingService.getTrainingPlanTemplatePlannedExercises(selectedTemplate);
          let template = selectedTemplate?.clone();
          if(template.folderIds == null) template.folderIds = [];
          template.folderIds.push(this.selectedTrainingPlanTemplateFolder.id);
          await this.trainingService.updateTrainingPlanTemplate(template, false, null);
        }
      }
      this.updateFilteredTemplates();
    });
  }

  onDragOverFolderDropzone(event: DragEvent){
    event.preventDefault();
  }

  
  draggedTemplate: TrainingPlan
  draggingDeprecatedTemplate = false
  draggingTemplate = false
  onDragStartTemplate(event, template: any) {
    this.draggedTemplate = template
    this.draggingTemplate = true
    this.draggingDeprecatedTemplate = false
    event.dataTransfer.setData('text/plain', template)
    event.dataTransfer.effectAllowed = 'move'
    if(this.selectionMode){
      this.onDragStartTrainingPlanTemplate.emit(template);
    }
  }
  onDragEndFolderTemplate(event, template: any) {
    this.draggedTemplate = null
    this.draggingTemplate = false
  }
  onDragOverTemplateDropzone(event) {
    event.preventDefault()
  }

  async onDropOnFolderDropzone(event, folder: TrainingPlanTemplateFolder) {
    console.log(folder)
    console.log(event)
    console.log('## Drop template')
    event.preventDefault()
    if (this.selectionMode || !this.draggingTemplate) return
    if(folder.licenceHolderUid != this.trainingService.TemplateUserUid) return;
    let draggingTemplate = this.draggedTemplate.clone();
    if(draggingTemplate.folderIds == null) draggingTemplate.folderIds = [];
    if(draggingTemplate.folderIds.includes(folder.id)) return;
    draggingTemplate.folderIds.push(folder.id);
    console.log(draggingTemplate)
    await this.trainingService.updateTrainingPlanTemplate(draggingTemplate, false, null);
    await this.updateFilteredTemplates();
  }
  onDragEndTemplate(event, template: any) {
    this.draggedTemplate = null
    this.draggingTemplate = false
  }

  
  async openTrainingVariablesDialog(){
    let trainingVariables = this.userService.getLoggedInUser()?.trainingSettingsLicenceHolder?.trainingVariables || [];
    const dialogRef = this.dialog.open(TrainingVariableEditorDialogComponent, {width: '800px', data: { user: this.userService.getLoggedInUser(), trainingVariables: trainingVariables?.map(x => x.clone()), trainingVariableEditorDialogType: TrainingVariableEditorDialogType.global}});
    dialogRef.afterClosed().subscribe(async result => {
      if(result){
        let trainingSettings = this.userService.getLoggedInUser().trainingSettingsLicenceHolder;
        trainingSettings.trainingVariables = result.trainingVariables;
        await this.userService.updateTrainingSettingsForLicencHolder(this.userService.getLoggedInUser());
      }
    });
  }
}

enum TemplateFilter{
  ALL = "Alle",
  IN_FOLDER = "In Ordner",
  NOT_IN_FOLDER = "Nicht in Ordner enthalten",
  SHARED = "Geteilt",
  NOT_SHARED = "Nicht geteilt"
}