<div class="row horizontal-scroll-wrapper flex-nowrap">
    <div class="d-inline-block trainingsession-col layout-box {{trainingSessionContainerList.length == 1 ? 'w-100' : ''}}" *ngFor="let trainingSessionContainer of trainingSessionContainerList">
        <i *ngIf="trainingSessionContainerList.length > 1" (click)="closeTrainingSessionContainer(trainingSessionContainer)" class="bi bi-x-lg link trainingSessionContainer-close-button"></i>
        <div class="row">
            <div class="col tracked-training-wrapper">
                <div class="row">
                    <div class="col-10">
                        <h3 class="tracked-session-heading" tabindex="0">{{trainingSessionContainer.trackedTrainingSession?.sessionName || trainingSessionContainer.plannedTrainingSession?.name}}</h3>
                        {{(trainingSessionContainer.trackedTrainingSession?.startDate | timezone:user)?.asDateTimeFormatedString()}} - {{(trainingSessionContainer.trackedTrainingSession | timezone:user)?.endDate?.asTimeFormatedString()}}
                        <div class="label">
                            Dauer: {{trainingSessionContainer.trackedTrainingSession?.getPrintableTrainingDuration()}}h
                        </div>
                        <div class="spacer-10"></div>
                        <div *ngIf="trainingSessionContainer.trackedTrainingSession?.custom" class="row label">
                            <div class="icon-button" tooltip="Diese Einheit hat dein Coachee außerhalb eines Plans durchgeführt.">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                </svg>
                                <div class="label display-inlineblock marginleft-5">Freies Training</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-2" *ngIf="isCoach()">
                        <button mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                            </svg>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="onMarkVideosAsViewed()">Videos als gesehen markieren</button>
                            <button mat-menu-item (click)="onSaveTrainingAsTemplate(trainingSessionContainer)">Absolviertes Training als Vorlage speichern</button>
                        </mat-menu>
                    </div>
                </div>
                
                <div class="spacer-10"></div>
                <ng-container *ngFor="let superSet of trainingSessionContainer.trackedSuperSets">
                    <div *ngIf="superSet.trackedSuperSetExercises[0]?.groupHeading" class="exercise-group-heading">{{superSet.trackedSuperSetExercises[0].groupHeading}}</div>
                    <ng-container *ngIf="superSet.trackedSuperSetExercises.length > 1">
                        <div class="row layout-box sub-box ng-star-inserted x-scrollable">
                            <div><b>Supersatz</b></div>
                            <div class="label w-100">
                                Dauer: {{getTimeOfSuperSet(superSet)?.toTimeString()?.slice(0,8)}}
                            </div>
                            <div class="spacer-10"></div>
                            <div style="overflow-x:auto; white-space: nowrap; display: flex;">
                                <div style="display: flex;">
                                    <div *ngFor="let exercise of superSet.trackedSuperSetExercises; let thumbnailIndex = index">
                                        <div style="text-align: center; margin-right: 10px;" *ngIf="exercise.exerciseId as exerciseId">
                                            <span><b>{{(thumbnailIndex + 10).toString(36).toUpperCase()}}</b></span>
                                            <br>
                                            <img draggable="false" *ngIf="getExerciseById(exerciseId)?.thumbnailDownloadURL" loading="lazy" (error)="utilityService.onImageErrorHide($event)" src="{{getExerciseById(exerciseId)?.thumbnailDownloadURL}}" class="superset-thumbnail">
                                            <div *ngIf="!getExerciseById(exerciseId)?.thumbnailDownloadURL">
                                                <img draggable="false" loading="lazy" src="{{ (trainingService.getExerciseThumbnailUrl(getExerciseById(exerciseId)) | async) }}" (error)="utilityService.onImageErrorHide($event)" class="superset-thumbnail">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="spacer-25"></div>
                            <div style="width: 100%" *ngFor="let superSetRound of superSet.trackedSuperSetRounds; let round = index">
                                <div><b>Runde {{round + 1}}</b> ({{superSetRound.getTime().toTimeString().slice(0,8)}})</div>
                                <table class="col-12 sets-table">
                                    <thead>
                                        <tr>
                                            <th class="setnumber">
                                                <div class="label">Übung</div>
                                                <div class="text-center">#</div>
                                            </th>
                                            <th *ngIf="superSet.hasVideoRecordings()" class="video-column">
                                                <div class="label">Video</div>
                                                <div class="text-center">&nbsp;</div>
                                            </th>
                                            <th class="setnumber">
                                                <div class="label"></div>
                                                <div></div>
                                            </th>
                                            <th class="value-column" *ngFor="let setParameter of getAvailableSetParametersByTrackedExerciseSets(superSetRound.trackedExerciseSets)">
                                                <div class="label">{{setParameter2SubHeadingMapping[setParameter]}}</div>
                                                <div>{{setParameter2LabelUnitMapping[setParameter] | unitConversion: languageService.selectedUnitSystem}}</div>
                                            </th>
                                            <th class="value-column" *ngIf="hasAnyPauseDuration(superSetRound.trackedExerciseSets)">
                                                <div class="label">Pausendauer</div>
                                                <div>{{setParameter2LabelUnitMapping[setParameter.time]}}</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let set of superSetRound.trackedExerciseSets; let i = index">
                                            <td class="setnumber">
                                                <b>{{(set.exerciseThumbnailNumber + 10).toString(36).toUpperCase()}}</b>
                                                <div class="video-recording-indicator" *ngIf="set.trackedTrainingExercise.note" [matTooltip]="set.trackedTrainingExercise.note">
                                                    <div class="icon-button">
                                                        <svg _ngcontent-aot-c1="" class="bi bi-sticky" fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path _ngcontent-aot-c1="" d="M2.5 1A1.5 1.5 0 0 0 1 2.5v11A1.5 1.5 0 0 0 2.5 15h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 15 8.586V2.5A1.5 1.5 0 0 0 13.5 1h-11zM2 2.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5V8H9.5A1.5 1.5 0 0 0 8 9.5V14H2.5a.5.5 0 0 1-.5-.5v-11zm7 11.293V9.5a.5.5 0 0 1 .5-.5h4.293L9 13.793z"></path></svg>
                                                    </div>
                                                </div>
                                            </td>
                                            <td *ngIf="superSet.hasVideoRecordings()" class="setnumber">
                                                <div *ngIf="set.trackedTrainingExercise.getTrackedVideoRecordingBySetNumber(set.trackedTrainingSet.setNumber)">
                                                    <div class="icon-button" (click)="onShowTrackedVideoRecording(set.trackedTrainingExercise.getTrackedVideoRecordingBySetNumber(set.trackedTrainingSet.setNumber), set.trackedTrainingExercise)">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-camera-video" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"/>
                                                        </svg>
                                                        <div *ngIf="set.trackedTrainingExercise.getTrackedVideoRecordingBySetNumber(set.trackedTrainingSet.setNumber)?.viewedByCoach == false" class="indicator small display-inlineblock marginleft-5" tooltip="Du hast diese Videoaufnahme noch nicht angesehen."></div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                {{getExerciseById(set.trackedTrainingExercise.exerciseId)?.name?.GetValue(selectedLanguageCode)}}
                                            </td>
                                            <td class="value-column" *ngFor="let setParameter of getAvailableSetParametersByTrackedExerciseSets(superSetRound.trackedExerciseSets)">
                                                <ng-container *ngIf="setParameter == 'time' || setParameter == 'pace'; else notduration;">
                                                    {{getSecondsAsTime(set.trackedTrainingSet[setParameter])}}<div class="label" style="display: inline;">{{getNewPlannedSetParamterValue(set.trackedTrainingExercise, round, setParameter)}}</div>
                                                </ng-container>
                                                <ng-template #notduration>
                                                    <ng-container *ngIf="setParameter == 'weight'">{{set.trackedTrainingSet[setParameter] | weightConversion: languageService.selectedUnitSystem: true }}</ng-container>
                                                    <ng-container *ngIf="setParameter != 'weight'">{{set.trackedTrainingSet[setParameter]}}</ng-container>
                                                    <div class="label" style="display: inline;">{{getNewPlannedSetParamterValue(set.trackedTrainingExercise, round, setParameter)}}</div>
                                                </ng-template>
                                            </td>
                                            <td class="value-column" *ngIf="hasAnyPauseDuration(superSetRound.trackedExerciseSets)">
                                                {{getSecondsAsTime(set.trackedTrainingSet.pauseDuration)}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="spacer-25"></div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="superSet.trackedSuperSetExercises.length == 1">
                        <div class="row">
                            <div class="col-12 exercise-box layout-box sub-box">
                                <div class="row mr-2">
                                    <div class="thumbnail-image" *ngIf="superSet.trackedSuperSetExercises[0]?.exerciseId as exerciseId">
                                        <img draggable="false" *ngIf="getExerciseById(exerciseId)?.thumbnailDownloadURL" loading="lazy" (error)="utilityService.onImageErrorHide($event)" src="{{getExerciseById(exerciseId)?.thumbnailDownloadURL}}">
                                        <div *ngIf="!getExerciseById(exerciseId)?.thumbnailDownloadURL">
                                            <img draggable="false" loading="lazy" (error)="utilityService.onImageErrorHide($event)" src="{{ (trainingService.getExerciseThumbnailUrl(getExerciseById(exerciseId)) | async) }}">
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="row">
                                            <b>{{getExerciseById(superSet.trackedSuperSetExercises[0].exerciseId)?.name?.GetValue(selectedLanguageCode)}}</b>
                                        </div>
                                        <div class="row">
                                            {{getExerciseById(superSet.trackedSuperSetExercises[0].exerciseId)?.subName?.GetValue(selectedLanguageCode)}}
                                        </div>
                                    </div>
                                    <div class="ml-3" *ngIf="!trainingSessionContainer.trackedTrainingSession?.custom && superSet.trackedSuperSetExercises[0].isExerciseExchanged">
                                        <ng-container *ngIf="superSet.trackedSuperSetExercises[0].replacedExerciseId; else customAdded">
                                            <svg matTooltip="Die durchgeführte Übung weicht von der geplanten Übung ab." xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-repeat float-right" viewBox="0 0 16 16">
                                                <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                                                <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
                                            </svg>
                                        </ng-container>
                                        <ng-template #customAdded>
                                            <svg matTooltip="Die durchgeführte Übung wurde manuell durch den Coachee ergänzt." xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle-dotted float-right" viewBox="0 0 16 16">
                                                <path d="M8 0q-.264 0-.523.017l.064.998a7 7 0 0 1 .918 0l.064-.998A8 8 0 0 0 8 0M6.44.152q-.52.104-1.012.27l.321.948q.43-.147.884-.237L6.44.153zm4.132.271a8 8 0 0 0-1.011-.27l-.194.98q.453.09.884.237zm1.873.925a8 8 0 0 0-.906-.524l-.443.896q.413.205.793.459zM4.46.824q-.471.233-.905.524l.556.83a7 7 0 0 1 .793-.458zM2.725 1.985q-.394.346-.74.74l.752.66q.303-.345.648-.648zm11.29.74a8 8 0 0 0-.74-.74l-.66.752q.346.303.648.648zm1.161 1.735a8 8 0 0 0-.524-.905l-.83.556q.254.38.458.793l.896-.443zM1.348 3.555q-.292.433-.524.906l.896.443q.205-.413.459-.793zM.423 5.428a8 8 0 0 0-.27 1.011l.98.194q.09-.453.237-.884zM15.848 6.44a8 8 0 0 0-.27-1.012l-.948.321q.147.43.237.884zM.017 7.477a8 8 0 0 0 0 1.046l.998-.064a7 7 0 0 1 0-.918zM16 8a8 8 0 0 0-.017-.523l-.998.064a7 7 0 0 1 0 .918l.998.064A8 8 0 0 0 16 8M.152 9.56q.104.52.27 1.012l.948-.321a7 7 0 0 1-.237-.884l-.98.194zm15.425 1.012q.168-.493.27-1.011l-.98-.194q-.09.453-.237.884zM.824 11.54a8 8 0 0 0 .524.905l.83-.556a7 7 0 0 1-.458-.793zm13.828.905q.292-.434.524-.906l-.896-.443q-.205.413-.459.793zm-12.667.83q.346.394.74.74l.66-.752a7 7 0 0 1-.648-.648zm11.29.74q.394-.346.74-.74l-.752-.66q-.302.346-.648.648zm-1.735 1.161q.471-.233.905-.524l-.556-.83a7 7 0 0 1-.793.458zm-7.985-.524q.434.292.906.524l.443-.896a7 7 0 0 1-.793-.459zm1.873.925q.493.168 1.011.27l.194-.98a7 7 0 0 1-.884-.237zm4.132.271a8 8 0 0 0 1.012-.27l-.321-.948a7 7 0 0 1-.884.237l.194.98zm-2.083.135a8 8 0 0 0 1.046 0l-.064-.998a7 7 0 0 1-.918 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>
                                            </svg>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="spacer-25"></div>
                                <div class="row" *ngIf="superSet.trackedSuperSetExercises[0].trackedSets?.length > 0">
                                    <table class="col-12 sets-table exercise-table">
                                        <thead>
                                            <tr>
                                                <th class="setnumber">
                                                    <div class="label">Satz</div>
                                                    <div class="text-center">#</div>
                                                </th>
                                                <th *ngIf="superSet.trackedSuperSetExercises[0]?.hasVideoRecordings()" class="video-column">
                                                    <div class="label">Video</div>
                                                    <div class="text-center">&nbsp;</div>
                                                </th>
                                                <th class="value-column" *ngFor="let setParameter of getAvailableSetParameters(superSet.trackedSuperSetExercises[0]?.trackedSets)">
                                                    <div class="label">{{setParameter2SubHeadingMapping[setParameter]}}</div>
                                                    <div>{{setParameter2LabelUnitMapping[setParameter]}}</div>
                                                </th>
                                                <th class="value-column" *ngIf="hasExertionValues(superSet.trackedSuperSetExercises[0].trackedSets)">
                                                    <div class="label">{{setParameter2SubHeadingMapping[setParameter.rir]}}</div>
                                                    {{getExertionUnitFromPlannedExercise(getPlannedExerciseById(superSet.trackedSuperSetExercises[0]?.plannedExerciseId, trainingSessionContainer))}}
                                                </th>
                                                <th class="value-column" *ngIf="trackedTrainingSetHasAnyPauseDuration(superSet.trackedSuperSetExercises[0]?.trackedSets)">
                                                    <div class="label">Pausendauer</div>
                                                    <div>{{setParameter2LabelUnitMapping[setParameter.time]}}</div>
                                                </th>
                                                <th *ngIf="superSet.trackedSuperSetExercises[0]?.hasCoacheeNotes()" class="video-column">
                                                    <div class="label">Notiz</div>
                                                    <div class="text-center">&nbsp;</div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let set of superSet.trackedSuperSetExercises[0].trackedSets; let i = index">
                                                <td class="setnumber" class="{{isPartOfDropset(set, superSet.trackedSuperSetExercises[0].trackedSets, i) ? 'dropset-line' : ''}}">
                                                    <ng-container *ngIf="set.isWarmupSet || set.isDropset; else defaultSet">
                                                        <div matTooltip="’W’ kennzeichnet einen Warmup-Satz. Dieser Satz fließt nicht in deine Statistik mit ein." class="setmode-indicator" *ngIf="set.isWarmupSet">
                                                            W
                                                        </div>
                                                        <div matTooltip="’D’ kennzeichnet einen Dropset." class="setmode-indicator" *ngIf="set.isDropset">
                                                            D
                                                        </div>
                                                    </ng-container>
                                                    <ng-template #defaultSet>
                                                        <span tooltip="Satz-Typ wählen">
                                                            {{superSet.trackedSuperSetExercises[0].getRealSetIndex(set) + 1}}
                                                        </span>
                                                    </ng-template>
                                                </td>
                                                <td *ngIf="superSet.trackedSuperSetExercises[0]?.hasVideoRecordings()">
                                                    <div *ngIf="superSet.trackedSuperSetExercises[0].getTrackedVideoRecordingBySetNumber(set.setNumber)">
                                                        <div class="icon-button" (click)="onShowTrackedVideoRecording(superSet.trackedSuperSetExercises[0].getTrackedVideoRecordingBySetNumber(set.setNumber), superSet.trackedSuperSetExercises[0])">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-camera-video" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"/>
                                                            </svg>
                                                            <div *ngIf="superSet.trackedSuperSetExercises[0].getTrackedVideoRecordingBySetNumber(set.setNumber)?.viewedByCoach == false" class="indicator small display-inlineblock marginleft-5" tooltip="Du hast diese Videoaufnahme noch nicht angesehen."></div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="value-column" *ngFor="let setParameter of getAvailableSetParameters(superSet.trackedSuperSetExercises[0].trackedSets)">
                                                    <ng-container *ngIf="setParameter == 'time' || setParameter == 'pace'; else notduration;">
                                                        {{getSecondsAsTime(set[setParameter])}}<div class="label" style="display: inline;">{{getNewPlannedSetParamterValue(superSet.trackedSuperSetExercises[0], i, setParameter)}}</div>
                                                    </ng-container>
                                                    <ng-template #notduration>
                                                        <ng-container *ngIf="setParameter == 'weight'">{{set[setParameter] | weightConversion: languageService.selectedUnitSystem: true }}</ng-container>
                                                        <ng-container *ngIf="setParameter != 'weight'">{{set[setParameter]}}</ng-container>
                                                        <div class="label" style="display: inline;">{{getNewPlannedSetParamterValue(superSet.trackedSuperSetExercises[0], i, setParameter)}}</div>
                                                    </ng-template>
                                                </td>
                                                <td class="value-column" *ngIf="hasExertionValues(superSet.trackedSuperSetExercises[0].trackedSets)">
                                                    {{set.exertionValue}}
                                                </td>
                                                <td class="value-column" *ngIf="trackedTrainingSetHasAnyPauseDuration(superSet.trackedSuperSetExercises[0].trackedSets)">
                                                    {{getSecondsAsTime(set.pauseDuration)}}
                                                </td>
                                                <td *ngIf="superSet.trackedSuperSetExercises[0]?.hasCoacheeNotes()">
                                                    <div *ngIf="set.coacheeNote?.length > 0">
                                                        <div class="icon-button" tooltip="{{set.coacheeNote}}">
                                                            <i class="bi bi-chat-left fontsize-20"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-12 exercisenote-wrapper" *ngIf="superSet.trackedSuperSetExercises[0].hasNote() || superSet.trackedSuperSetExercises[0].hasNoteImages()">
                                    <div class="note-icon">
                                        <svg _ngcontent-aot-c1="" class="bi bi-sticky" fill="currentColor" height="16" viewBox="0 0 16 16"width="16" xmlns="http://www.w3.org/2000/svg">
                                            <path _ngcontent-aot-c1="" d="M2.5 1A1.5 1.5 0 0 0 1 2.5v11A1.5 1.5 0 0 0 2.5 15h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 15 8.586V2.5A1.5 1.5 0 0 0 13.5 1h-11zM2 2.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5V8H9.5A1.5 1.5 0 0 0 8 9.5V14H2.5a.5.5 0 0 1-.5-.5v-11zm7 11.293V9.5a.5.5 0 0 1 .5-.5h4.293L9 13.793z"></path>
                                        </svg>
                                    </div>
                                    <div class="exercisenote-content">
                                        <div class="exercisenote-text" *ngIf="superSet.trackedSuperSetExercises[0].hasNote()">{{superSet.trackedSuperSetExercises[0].note}}</div>
                                        <div *ngIf="superSet.trackedSuperSetExercises[0].hasNoteImages()">
                                            <app-image-upload-view [filePaths]="superSet.trackedSuperSetExercises[0].noteImagePaths" [canEdit]="false" [pathPrefix]="'users/' + user.uid + '/'"></app-image-upload-view>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <div class="spacer-10"></div>
                <ng-container *ngIf="trainingSessionContainer.questionaireResults?.length > 0">
                    <h3>Check-Ins</h3>
                    <div class="icon-button marginleft-25 padding-4" (click)="onOpenQuestionaireResults()" tooltip="Markiere die Check-Ins, die du vergleichen möchtest, mit einem Haken.">
                        Ausgewählte Check-Ins vergleichen
                    </div>
                    <div class="container-fluid padding-0">
                        <div class="col-12">
                            <ng-container *ngFor="let questionaireResult of trainingSessionContainer.questionaireResults">
                                <ng-container *ngIf="questionaireResult?.assignedQuestionaire?.completed;">
                                    <div class="row layout-box sub-box link" (click)="onOpenQuestionaire(questionaireResult);$event.stopPropagation()">
                                        <div class="checkbox-wrapper d-inline-block" (click)="onQuestionaireResultSelected(questionaireResult);$event.stopPropagation()">
                                            <input class="input checkbox margin-vertical-auto h-100" type="checkbox" [checked]="selectedQuestionaireResults.includes(questionaireResult)" tooltip="Zum Vergleich hinzufügen">
                                        </div>
                                        <div class="col">
                                            <div class="row">
                                                <div class="col">
                                                    <b>{{questionaireResult.assignedQuestionaire.name}}</b>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    {{(questionaireResult.assignedQuestionaire.completionDate | timezone:user)?.asDateTimeFormatedString() || (questionaireResult.assignedQuestionaire.assignmentDate | timezone:user)?.asDateTimeFormatedString()}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="float-right margin-vertical-auto">
                                            <div class="icon-button marginright-10">
                                                Öffnen
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
                <div class="spacer-25"></div>
                <app-cardio-evaluation [User]="user" [TrainingSessionContainer]="trainingSessionContainer" [Activity]="trainingSessionContainer?.activity" [StartDate]="trainingSessionContainer?.trackedTrainingSession?.startDate" [EndDate]="trainingSessionContainer?.trackedTrainingSession?.endDate"></app-cardio-evaluation>
            </div>
        </div>
    </div>
</div>