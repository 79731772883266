import { ClientComponent } from '../../client/client-v1/client-v1.component';
import { SessionTemplatesDialogComponent } from './../session-templates-dialog/session-templates-dialog.component';
import { UtilityService } from './../../services/utility.service';
import { ExercisesTableDialogComponent } from './../exercises-table-dialog/exercises-table-dialog.component';
import { MergedTrainingExercise } from './../../model/training-exercise';
import { TrainingService } from './../../services/training.service';
import { TrainingPlan, TrainingSession, PlannedTrainingExercise, TrainingSet, Label2FrequencyMapping, Frequency2LabelMapping, SuperSet, NumberOfRounds2LabelMapping, Label2NumberOfRoundsMapping, NumberOfRounds, SetParameter2LabelMapping, Label2SetParameterMapping, SetParameter, SuperSetConfig, SetParameter2UnitMapping, SetParameter2LabelUnitMapping, SetParameter2SubHeadingMapping, TrainingPlanAccess, Week } from './../../model/training-plan.model';
import { Component, Input, OnInit, Output, EventEmitter, Inject, NgZone } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import '../../prototypes'
import { CdkDrag, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ToastrService } from 'ngx-toastr';
import { FirestoreNutritionPlanService } from 'src/app/services/firestore-nutritionplan.service';
import { CreateTemplateDialogComponent } from 'src/app/create-template-dialog/create-template-dialog.component';
import { User } from 'src/app/model/user.model';
import { AuthService } from 'src/app/auth/auth.service';
import { ExerciseHistoryDialogComponent } from 'src/app/training-monitoring/exercise-history-dialog/exercise-history-dialog.component';
import { environment } from 'src/environments/environment';
import { LanguageService } from 'src/app/services/language.service';
import { TrainingplanExportService } from 'src/app/services/trainingplan-export.service';
import { SingleExerciseComponent } from '../single-exercise/single-exercise.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomSettingsDialogComponent } from 'src/app/dialogs/custom-settings-dialog/custom-settings-dialog.component';
import { FirestoreService } from 'src/app/services/firestore.service';
import { DropdownItem } from 'src/app/model/automatic-push-notification.model';
import { ImageEditorComponent } from 'src/app/utilities/image-editor/image-editor.component';
import { TrainingPlanTemplateFolder } from 'src/app/model/training-plan-template-folder.model';
import { time } from 'console';
import { LanguageDictionary } from 'src/app/model/languagedictionary.model';
import { TrainingVariable } from 'src/app/model/training-variables.model';
import {MatDividerModule} from '@angular/material/divider';
import { TrainingVariableEditorDialogComponent, TrainingVariableEditorDialogType } from '../training-variable-editor-dialog/training-variable-editor-dialog.component';
import { VisualSelectionDialogComponent } from 'src/app/dialogs/visual-selection-dialog/visual-selection-dialog.component';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { TrainingHistoryDialogComponent, TrainingSessionContainer } from 'src/app/training-monitoring/training-history-dialog/training-history-dialog.component';
import { NumberToShortWeekDayStringsMapping } from 'src/app/model/task.model';
import { first, timeout } from 'rxjs';
import { ExerciseGroupTemplatesDialogComponent } from '../exercise-group-templates-dialog/exercise-group-templates-dialog.component';
import { Questionaire, QuestionaireResult } from 'src/app/model/questionaires.model';
import { CalendarItem, SingleCalendarDay } from 'src/app/monthly-calendar/monthly-calendar.component';
import { TrackedTrainingExercise, TrackedTrainingSession, TrackedTrainingSet } from 'src/app/model/training-monitoring.model';
import { CardioZone, CardioZoneGroup } from 'src/app/model/cardio-zone-group.model';
import { Moment } from 'moment';
import { TrainingPlanEditorHelper } from './utilities/training-plan-editor-helper';
import { ClientBaseComponent } from 'src/app/client/client-base/client-base.component';
import { TrainingPlanEditorComponent } from './training-plan-editor.component';
import { NotificationService } from 'src/app/services/notification.service';
import { Metadata } from 'src/app/model/metadata-user.model';


export class BaseTrainingEditor {

  constructor(public trainingService: TrainingService, public toastr: ToastrService, public dialog: MatDialog, public authService: AuthService, public utilityService: UtilityService, public languageService: LanguageService, public ngZone: NgZone, public trainingPlanExportService: TrainingplanExportService, public spinner: NgxSpinnerService, public userService: FirestoreService, public notificationService: NotificationService, public dialogRef: any) {
    this.trainingPlanEditorHelper = new TrainingPlanEditorHelper(trainingService, dialog, spinner, userService, toastr);
  }

  public trainingPlanEditorHelper: TrainingPlanEditorHelper;

  public user: User
  public selectedCoach: User
  public readOnlyMode: boolean = false;

  public trainingPlan: TrainingPlan;
  public expandedExercise: PlannedTrainingExercise
  public hasChanges: boolean = false
  public copyExercise: PlannedTrainingExercise
  public copyExercisesList: PlannedTrainingExercise[]
  public _selectedSession: TrainingSession

  public get selectedSession(): TrainingSession {
    return this._selectedSession;
  }
  public set selectedSession(value: TrainingSession) {
    this.onSessionSelectionChanged(value);
  }

  public trackedTrainingSessions: TrackedTrainingSession[] = []
  public calendarItems: CalendarItem<TrainingSession>[] = []
  public currentSelectedCalendarMonth: Date = null
  public sessionsColumnClosed: boolean = false;
  public trainingPlanSettingsExpanded: boolean = false;

  public _endDate: Date = null;
  get endDate(): Date{
    return this.trainingPlan.endDate ?? this._endDate;
  }

  public get availableTrainingVariables(): TrainingVariable[] {
    return this.trainingPlan?.trainingVariables;
  }

  /*@Output() deleteTrainingPlanEvent = new EventEmitter<TrainingPlan>();
  @Output() saveChangesEvent = new EventEmitter<TrainingPlan>();
  @Output() cancelChangesEvent = new EventEmitter();*/

  async init() {
    if(!this.trainingPlan.id) {
      // this.setAvailableTrainingVariables();
      if(this.trainingPlan.isPeriodicPlan){
        this.trainingPlan.initWeeks();
        let firstWeek = this.trainingPlan.weeks[0];
        let firstDay = this.getWeekDays(firstWeek.id)[0];

        this.addSessionToDay(firstDay, this.trainingPlan.weeks[0].id);
      }
      else {
        this.addSession(false, false);
      }
    }

    if(this.trainingPlan.isPeriodicPlan && this.trainingPlan.weeks.length == 0){
      this.trainingPlan.initWeeks();
    }
    else if(this.trainingPlan.isTemplate && !this.trainingPlan.startDate){
      this.trainingPlan.startDate = new Date(0);
    }

    if(this.trainingPlan.id && !this.trainingPlan.isTemplate && this.user){
      if(this.trainingPlan.isPeriodicPlan){
        await this.setTrackedSessions();
      }
      else {
        this.loadTrackedTrainingSessions();
      }
    }

    this.setWeekDays();
    this.scrollDayIntoView(this.selectedSession?.plannedDate);

    if(!this.trainingPlan.isTemplate) {
      this.user.filterCardioZoneGroupZones(this.getGlobalCardioZones());
    }
  }

  public loadingTrackedTrainingSessions: boolean = false;

  public async loadTrackedTrainingSessions(){
    try{
      this.loadingTrackedTrainingSessions = true;
      let trackedTrainingSessions = [];
      for(let session of this.trainingPlan.sessions){
        if(!session.deleted && !session.isRestDay){
          let trackedSessions = await this.userService.getTrackedTrainingSessionsByPlannedSessionId(this.user, session.id);
          if(trackedSessions.length > 0){
            trackedTrainingSessions.push(...trackedSessions);
          }
        }
      }
      // this.setLatestDateWithSessionHistory(this.selectedSession);
      this.trackedTrainingSessions = trackedTrainingSessions;
      this.setCalendarItems();
    }
    catch(ex){
      console.error(ex);
    }
    finally{
      this.loadingTrackedTrainingSessions = false;
    }
  }

  onSelectTrainingPlan(trainingPlan: TrainingPlan){
    this.trainingPlan = trainingPlan;
    this.selectedSession = null
    this.init()
  }

  async setTrackedSessions(){
    try{
      this.spinner.show();
      for(let session of this.trainingPlan?.sessions){
        if(!session.deleted){
          let trackedSessions = await this.userService.getTrackedTrainingSessionsByPlannedSessionId(this.user, session.id);
          if(trackedSessions.length > 0){
            session.isTracked = true;
            session.trackedTrainingSession = trackedSessions[0];
            session.trackedTrainingSession.plannedWeekIndex = this.trainingPlan.getWeekIndexById(session.weekId);
            session.trackedTrainingSession.plannedWeek = this.trainingPlan.weeks[session.trackedTrainingSession.plannedWeekIndex];
            if(this.selectedSession == session){
              this.loadTrackedTrainingSession(session);
            }
          }
        }
      }
      this.trackedTrainingSessions = this.trainingPlan.sessions.map(x => x.trackedTrainingSession).filter(x => x != null);
    }
    catch(ex){
      console.error(ex);
    }
    finally{
      this.spinner.hide();
    }
  }


  public loadingSessionHistory: boolean = false;

  async loadTrackedTrainingSession(plannedSession: TrainingSession){
    if(plannedSession.trackedTrainingSession){
      try{
        this.loadingSessionHistory = true;
        let trackedTrainingSession = plannedSession.trackedTrainingSession;
        if(trackedTrainingSession.trackedTrainingExercises == null || trackedTrainingSession.trackedTrainingExercises?.length == 0){
          let trackedExercises = await this.userService.getTrackedTrainingExercisesBySessionId(this.user, trackedTrainingSession.id);
          trackedTrainingSession.trackedTrainingExercises = trackedExercises;
        }
      }
      catch(ex){
        console.error(ex);
      }
      finally{
        this.loadingSessionHistory = false;
      }
    }
  }

  getWeekById(weekId: string){
    if (!this.selectedSession) return null
    var index = this.trainingPlan.getWeekIndexById(weekId)
    if (index != null && index < this.trainingPlan.weeks.length) {
      return this.trainingPlan.weeks[index]
    }
    return null
  }

  getWeekOfSelectedSession() {
    if (!this.selectedSession) return null
    var index = this.trainingPlan.getWeekIndexById(this.selectedSession.weekId)
    if (index != null && index < this.trainingPlan.weeks.length) {
      return this.trainingPlan.weeks[index]
    }
    return null
  }
  getWeekIndexOfSelectedSession() {
    if (!this.selectedSession) return null
    var index = this.trainingPlan.getWeekIndexById(this.selectedSession.weekId)
    if (index != null && index < this.trainingPlan.weeks.length) {
      return index + 1
    }
    return null
  }

  public addSession(isRest:boolean, addToNewWeek: boolean, newSession: TrainingSession = new TrainingSession()){
    if(!this.trainingPlan.sessions) this.trainingPlan.sessions = []
    let availableSessions = this.trainingPlan.sessions.filter(x => !x.deleted)
    newSession.isRestDay = isRest
    if(isRest) 
    {
      newSession.nameTranslation.de = "Rest"
      newSession.nameTranslation.en = "Rest"
      
    }
    else {
      let sessionNumber = availableSessions.filter(x => !x.isRestDay).length + 1
      if(this.languageService.selectedLanguageCode == "en"){
        if(newSession.nameTranslation.en == null) newSession.nameTranslation.en = "Session " + sessionNumber
      }
      else {
        if(newSession.nameTranslation.de == null) newSession.nameTranslation.de = "Einheit " + sessionNumber        
      }
    }
    if(this.trainingPlan.isPeriodicPlan){
      if(addToNewWeek){
        let weekNumbers = this.trainingPlan.getWeekNumbersList();
        let lastWeekNumber = weekNumbers[weekNumbers.length - 1] ?? 0
        let newWeek = new Week();
        let newWeekNumber = this.trainingPlan.weeks.push(newWeek);
        newSession.weekId = newWeek.id;

        let startDate = this.trainingPlan.startDate;
        let weekStartDate = new Date(startDate);
        weekStartDate.setDate(weekStartDate.getDate() + newWeekNumber * 7);
        if(this.trainingPlan.endDate != null){
          if(weekStartDate > this.trainingPlan.endDate){
            this.trainingPlan.endDate = weekStartDate;
          }
        }
        else {
          let weekEndDate = new Date(weekStartDate);
          weekEndDate.setDate(weekEndDate.getDate() + 6);
        }
        newSession.plannedDate = this.getAdjustedTimezoneDate(weekStartDate, this.user);
      }
      else {
        if(this.trainingPlan.weeks.length == 0){
          let newWeek = new Week();
          let newWeekNumber = this.trainingPlan.weeks.push(newWeek);
        }
        newSession.weekId = newSession.weekId ?? this.trainingPlan.weeks[0]?.id;

      }
    }
    this.trainingPlan.sessions.push(newSession)
    this.selectedSession = newSession
    this.hasChanges = true

    this.setWeekDays();
  }
  public weekDaysByWeekId: Map<string, Date[]> = new Map<string, Date[]>();

  setWeekDays(){
    if (!this.trainingPlan) return
    this.weekDaysByWeekId = new Map<string, Date[]>();
    let weeks = this.trainingPlan.weeks;
    for(let week of weeks){
      this.weekDaysByWeekId[week.id] = this.getWeekDays(week.id);
    }
    this.trainingPlan.setDurationInDays();
    this.setCalendarItems();

    if(this.trainingPlan.isPeriodicPlan){
      let lastWeek = this.trainingPlan.weeks[this.trainingPlan.weeks.length - 1];
      if(lastWeek){
        let lastWeekDays = this.weekDaysByWeekId[lastWeek.id];
        if(lastWeekDays){
          let lastDay = lastWeekDays[lastWeekDays.length - 2];
          if(lastDay) {
            this.trainingPlan.endDate = lastDay.clone();
          }
        }
      }
    }
  }

  static getWeekNumberByWeekId(trainingPlan: TrainingPlan, weekId: string): number {
    let weeks = trainingPlan.weeks;
    let week = weeks.find(x => x.id == weekId);
    if(week){
      let weekNumber = weeks.indexOf(week);
      return weekNumber;
    }
    return 0;
  }

  getWeekDays(weekId: string): Date[]{
    let weekNumber = BaseTrainingEditor.getWeekNumberByWeekId(this.trainingPlan, weekId);
    let startDate = this.trainingPlan.startDate ?? new Date(0);
    let weekStartDate = new Date(startDate);
    weekStartDate.setDate(weekStartDate.getDate() + weekNumber * 7);
    weekStartDate.setHours(0,0,0,0);
    let weekEndDate = new Date(weekStartDate);
    weekEndDate.setDate(weekEndDate.getDate() + 6);
    weekEndDate.setHours(0,0,0,0);
    let weekDays = [];
    for(let index = 0; index < 7; index++){
      let date = new Date(weekStartDate);
      date.setDate(date.getDate() + index);
      date.setHours(0,0,0,0);
      weekDays.push(date);
    }

    weekDays.push(null);
    return weekDays;
  }

  setCalendarItems(){
    this.calendarItems = [];
    if(this.trainingPlan.isPeriodicPlan){
      this.trainingPlan.sessions.forEach(session => {
        if(!session.deleted && session.plannedDate){
          if(session.isTracked){
            this.calendarItems.push(new CalendarItem<TrainingSession>(session.plannedDate, session.nameTranslation.GetValue(this.languageService.selectedLanguageCode) + ' (absolviert)', this.getBaseSessionReferenceColor(session), session));
          }
          else {
            this.calendarItems.push(new CalendarItem<TrainingSession>(session.plannedDate, session.nameTranslation.GetValue(this.languageService.selectedLanguageCode), this.getBaseSessionReferenceColor(session), session));
  
          }
        }
      });
    }
    else {
      let startDate = this.trainingPlan.startDate?.clone();

      let availableSessions = this.trainingPlan.sessions.filter(x => !x.deleted);
      let trackedSessions = this.trackedTrainingSessions;

      let lastTrackedPlannedSession: TrainingSession = null;
      let today = new Date();

      if(this.trainingPlan.isTemplate) {
        today = new Date(0);
        today.setHours(0, 0, 0, 0);
        if(this.trainingPlan.durationInDays){
          let endDate = startDate?.clone();
          endDate.addDays(this.trainingPlan.durationInDays - 1);
          this._endDate = endDate;
        }
      }
      else {
        today.setHours(0, 0, 0, 0);
        if(this.trainingPlan.startDate && today > this.trainingPlan.startDate){
          startDate = today.clone();
        }
  
        if(trackedSessions.length > 0){
          for(let trackedSession of trackedSessions?.sort((a, b) => a.startDate.getTime() - b.startDate.getTime())){
            let plannedSession = availableSessions.find(x => x.id == trackedSession.plannedSessionId);
            if(plannedSession){
              this.calendarItems.push(new CalendarItem<TrainingSession>(trackedSession?.startDate, plannedSession.nameTranslation.GetValue(this.languageService.selectedLanguageCode) + ' (absolviert)', this.getBaseSessionReferenceColor(plannedSession), plannedSession));
              lastTrackedPlannedSession = plannedSession;
              startDate = trackedSession.startDate.clone();
              startDate.addDays(1);
              plannedSession.trackedTrainingSession = trackedSession;
            }
          }
        }
      }

      startDate.setHours(0,0,0,0);
      let currentMonth = this.currentSelectedCalendarMonth ?? startDate?.clone();
      if(currentMonth && availableSessions.length > 1){
        let currentPlantSessionIndex = 0;
        if(lastTrackedPlannedSession){
          currentPlantSessionIndex = (availableSessions.findIndex(x => x.id == lastTrackedPlannedSession.id) + 1) % availableSessions.length;
        }
        let iteration = 0;
        let monthDiff = currentMonth.getMonthSinceYearZero() - startDate?.getMonthSinceYearZero();
        while(currentMonth.getMonthSinceYearZero() <= (startDate?.getMonthSinceYearZero() +  monthDiff)){
          for (let index = currentPlantSessionIndex; index < availableSessions.length; index++) {
            const session = availableSessions[index];
            if(!session.deleted){
              if(!session.isRestDay){
                let plannedDate = startDate.clone();
                if(this.trainingPlan.endDate && plannedDate > this.trainingPlan.endDate) return;
                if(plannedDate < today && !plannedDate.isSameDate(today)) {
                  startDate.addDays(1);
                  index--;
                  continue;
                }
                this.calendarItems.push(new CalendarItem<TrainingSession>(plannedDate, session.nameTranslation.GetValue(this.languageService.selectedLanguageCode), this.getBaseSessionReferenceColor(session), session));
              }
              startDate.addDays(1);
            }
          }
          currentPlantSessionIndex = 0;
          iteration++;

          // to prevent continuous loop in case of error
          if(iteration > 10000) break;
        }
      }
    }
  }

  getBaseSessionReferenceColor(session: TrainingSession){
    return TrainingPlanEditorComponent.getBaseSessionReferenceColor(session, this.trainingPlan.sessions);
  }

  static getSessionColor(sessionId: string, availableSessions: TrainingSession[]){
    let baseSession = availableSessions?.find(x => !x.deleted && x.id == sessionId);
    if(baseSession){
      let index = availableSessions?.filter(x => !x.deleted && !x.baseSessionId)?.indexOf(baseSession);
      return baseSession.indicatorColor ?? BaseTrainingEditor.getColorOfIndex(index);
    }
    return '';
  }

  getSessionColor(sessionId: string){
    return TrainingPlanEditorComponent.getSessionColor(sessionId, this.trainingPlan.sessions);
  }
  
  private static pastelColors = [
    "#FFB6C1", // Pastellrosa
    "#FFD700", // Pastellgelb
    "#87CEEB", // Pastellblau
    "#98FB98", // Pastellgrün
    "#FFA07A", // Pastellorange
    "#D2B6FF", // Pastelllila
    "#FFDAB9", // Pastellpfirsich
    "#B0E0E6", // Pastelltürkis
    "#FFE4C4", // Pastellbeige
    "#F0E68C"  // Pastellkhaki
  ];

  static getColorOfIndex(index: number){
    let modIndex = index % 10;
    return this.pastelColors[modIndex]
  }

  onSelectedCalendarMonthChanged(value: Date){
    if(this.currentSelectedCalendarMonth != value){
      this.currentSelectedCalendarMonth = value;
      this.setCalendarItems();
    }
  }

  async onOpenTrackedTrainingSession(plannedSession: TrainingSession){
    if(plannedSession.trackedTrainingSession){
      try{
        this.spinner.show();
        let trackedTrainingSession = plannedSession.trackedTrainingSession;
        if(trackedTrainingSession.trackedTrainingExercises == null || trackedTrainingSession.trackedTrainingExercises?.length == 0){
          let trackedExercises = await this.userService.getTrackedTrainingExercisesBySessionId(this.user, trackedTrainingSession.id);
          trackedTrainingSession.trackedTrainingExercises = trackedExercises;
        }
        let questionaireResults = this.user.questionaireResults?.filter(x => x.assignedQuestionaire?.trackedSessionId == trackedTrainingSession?.id);
        this.spinner.hide();
        this.dialog.open(TrainingHistoryDialogComponent, { data: { user: this.user, selectedTrackedTrainingSession: trackedTrainingSession, selectedPlannedTrainingSession: plannedSession, questionaireResults: questionaireResults}})
      }
      catch(ex){
        console.error(ex);
      }
    }
  }

  public async saveSessionAsTemplate(session: TrainingSession){
    let sessionTemplate =  new TrainingSession(session.name, null, session.isRestDay, session.exercises, false, session.weekId, session.plannedDate, null, session.hide, session.indicatorColor, session.estimatedDurationInMinutes, session.nameTranslation);

    let trainingPlanTemplate = new TrainingPlan();
    trainingPlanTemplate.sessions = [sessionTemplate];
    trainingPlanTemplate.nameTranslation = new LanguageDictionary<string>(sessionTemplate.nameTranslation.de, sessionTemplate.nameTranslation.en, sessionTemplate.nameTranslation.originObject);
    trainingPlanTemplate.trainingVariables = TrainingPlanEditorComponent.getAllVariablesOfTrainingPlan(trainingPlanTemplate, this.availableTrainingVariables)?.availableVariables ?? [];
    // await this.saveTrainingPlanAsTemplate(trainingPlanTemplate);
    await this.trainingPlanEditorHelper.saveTrainingPlanAsTemplate(trainingPlanTemplate, this.availableTrainingVariables, this.selectedCoach);
    if(this.trainingService.TrainingTemplateAdministratorEditModeActivated){
      trainingPlanTemplate.userEditable = true;
    }
  }

  public async saveWeekAsTemplate(week: Week){
    let trainingPlanTemplate = this.trainingPlan.clone();
    let weekName = week.name.clone();

    if(weekName.de != null || weekName.en != null){
      trainingPlanTemplate.nameTranslation.de = trainingPlanTemplate.nameTranslation.de + " - " + weekName.de;
      trainingPlanTemplate.nameTranslation.en = trainingPlanTemplate.nameTranslation.en + " - " + weekName.en;
    }
    else {
      if(trainingPlanTemplate.nameTranslation.de){
        trainingPlanTemplate.nameTranslation.de = trainingPlanTemplate.nameTranslation.de + " - Woche 1";
      }
      if(trainingPlanTemplate.nameTranslation.en) {
        trainingPlanTemplate.nameTranslation.en = trainingPlanTemplate.nameTranslation.en + " - Week 1";
      }
    }
    trainingPlanTemplate.trainingVariables = TrainingPlanEditorComponent.getAllVariablesOfTrainingPlan(trainingPlanTemplate, this.availableTrainingVariables)?.availableVariables ?? [];
    trainingPlanTemplate.sessions = trainingPlanTemplate.sessions.filter(x => x.weekId == week.id);
    for(let session of trainingPlanTemplate.sessions){
      if(session.baseSessionId){
        let baseSession = trainingPlanTemplate.sessions.find(x => x.id == session.baseSessionId);
        if(!baseSession){
          session.baseSessionId = null;
        }
      }
    }
    trainingPlanTemplate.weeks = [week.clone()];

    await this.trainingPlanEditorHelper.saveTrainingPlanAsTemplate(trainingPlanTemplate, this.availableTrainingVariables, this.selectedCoach);
  }

  savePlanAsTemplate(trainingPlan: TrainingPlan) {
    this.trainingPlanEditorHelper?.saveTrainingPlanAsTemplate(trainingPlan, this.availableTrainingVariables, this.selectedCoach)
  }

  connectToBaseSession(session: TrainingSession, baseSession: TrainingSession){
    let duplicatedSession = this.trainingPlan.sessions.find(x => !x.deleted && x.baseSessionId == session.id)
    if(duplicatedSession){
      baseSession.baseSessionId = session.id;
    }
    else {
      session.baseSessionId = baseSession.id;
    }
    this.setCalendarItems();
    this.hasChanges = true;
  }

  public draggingSession: TrainingSession = null;
  public focusedWeekDayForSessionDrop: Date = null;
  public focusedWeekNumberForSessionDrop: number = null;
  public focusedAfterDropzoneSession: TrainingSession = null;

  onDropAfterSession(session: TrainingSession){
    let draggingSession = this.draggingSession;
    if(draggingSession){
      this.trainingPlan.sessions.sort((a,b) => Number(!b.deleted) - Number(!a.deleted));
      let availableSession = this.trainingPlan.sessions.filter(x => !x.deleted);
      let newIndex = this.trainingPlan.sessions.indexOf(session);
      let previousIndex = this.trainingPlan.sessions.indexOf(draggingSession);
      if(newIndex < previousIndex && newIndex < availableSession.length - 1){
        newIndex++;
      }
      draggingSession.weekId = session.weekId;
      draggingSession.plannedDate = session.plannedDate?.clone();
      moveItemInArray(this.trainingPlan.sessions, previousIndex, newIndex);
      this.draggingSession = null;
      this.focusedWeekDayForSessionDrop = null;
      this.focusedWeekNumberForSessionDrop = null;
      this.focusedAfterDropzoneSession = null;

      this.hasChanges = true;
      this.setWeekDays();
    }
  }

  duplicateSession(session: TrainingSession) {
    if (!this.trainingPlan.sessions) this.trainingPlan.sessions = []
    let oldIndex = this.trainingPlan.sessions.indexOf(session);
    let newSession = session.clone()
    newSession.id = FirestoreNutritionPlanService.generateUniqueString(8)
    newSession.exercises.forEach(exercise => exercise.id = null)
    if(session.baseSessionId){
      newSession.baseSessionId = session.baseSessionId
    }
    else {
      newSession.baseSessionId = session.id
    }
    this.trainingPlan.sessions.push(newSession)
    if(oldIndex != null){
      let newIndex = this.trainingPlan.sessions.indexOf(newSession);
      if(newIndex != null){
        moveItemInArray(this.trainingPlan.sessions, newIndex, oldIndex + 1);
      }
    }
    this.selectedSession = newSession
    this.hasChanges = true;
  }

  removeSession(session: TrainingSession){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du diese Einheit wirklich vollständig löschen?', title: 'Einheit löschen' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let indexOfSession = this.trainingPlan.sessions.filter(x => !x.deleted).indexOf(session)
        session.deleted = true
        let availableSessions = this.trainingPlan.sessions.filter(x => !x.deleted)
        let referencedSessions = availableSessions.filter(x => x.baseSessionId == session.id);
        let newBaseSessionId = null;
        for(let index = 0; index < referencedSessions.length; index++){
          if(index == 0){
            referencedSessions[index].baseSessionId = null;
            newBaseSessionId = referencedSessions[index].id;
          }
          else {
            referencedSessions[index].baseSessionId = newBaseSessionId;
          }
        }

        if (this.selectedSession == session && availableSessions.length > 0) {
          if(indexOfSession < availableSessions.length){
            this.selectedSession = availableSessions[indexOfSession];
          }
          else if (availableSessions.length > 0) {
            this.selectedSession = availableSessions[availableSessions.length - 1];
          }
        }
        this.hasChanges = true
        this.setWeekDays();
      }
    })
  }

  public async addWeekFromTemplate(){
    let availableTemplateFolders = await this.trainingService.getTrainingPlanTemplateFolders();
    const dialogRef = this.dialog.open(SessionTemplatesDialogComponent, { data: { weekSelectionMode: true, availableTemplateFolders: availableTemplateFolders}, width: '1000px'})
    dialogRef.afterClosed().subscribe( async result => {
      if(result){
        if(result.shouldTake){
          try {
            this.spinner.show();
            let missingVariables = [];
            let selectedWeeks: {week: Week, trainingPlan: TrainingPlan}[] = result.selectedWeeks;
            if(selectedWeeks != null && selectedWeeks?.length > 0) {
              for(let selectedWeek of selectedWeeks) {
                let week = selectedWeek.week?.clone();
                let trainingPlanTemplate = selectedWeek.trainingPlan?.clone();
                let weekSessions = trainingPlanTemplate.sessions.filter(x => x.weekId == week.id);
                week.id = FirestoreNutritionPlanService.generateUniqueString(8);
                week.collapsed = false;
                week.hide = false;
                this.trainingPlan.weeks.push(week);
                let weekDays = this.getWeekDays(week.id);
  
                let sessions: TrainingSession[] = [];
                for(let session of weekSessions) {
                  let newId = FirestoreNutritionPlanService.generateUniqueString(8);
                  if(session.plannedDate){
                    let dayNumber = trainingPlanTemplate.getWeekDayByDate(session.plannedDate);
                    if(dayNumber == 0) {
                      dayNumber = 6;
                    }
                    else if(dayNumber > 0) {
                      dayNumber--;
                    }
                    session.plannedDate = weekDays[dayNumber];
                  }
                  session.weekId = week.id;

                  for(let exercise of session.exercises){
                    exercise.sessionId = newId;
                    exercise.id = null;
                  }
                  for(let session of weekSessions) {
                    if(session.baseSessionId == session.id){
                      session.baseSessionId = newId;
                    }
                  }
                  session.id = newId;
                  sessions.push(session);
                }
                sessions.forEach(session => {
                  if(session.baseSessionId){
                    let baseSession = sessions.find(x => x.id == session.baseSessionId);
                    if(!baseSession){
                      session.baseSessionId = null;
                    }
                  }
                });
                missingVariables.push(...this.addMissingVariablesForSessions(sessions))
                this.trainingPlan.sessions.push(...sessions);
              }
            }
            this.setWeekDays();
  
            if(missingVariables.length > 0){
              const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                data: { message: 'Fehlende Variablen wurden automatisch ergänzt. Möchtest du die Werte jetzt überprüfen?', title: 'Variablen bearbeiten', positiveButton: 'Ja', negativeButton: 'Abbrechen' },
              });
              dialogRef.afterClosed().subscribe(result => {
                if(result) {
                  this.openTrainingVariablesDialog();
                }
              })
            }
  
            this.hasChanges = true;
          }
          catch(ex){
            console.error(ex);
          }
          finally {
            this.spinner.hide();
          }
        }
      }
    });
  }

  public addMissingVariablesForSessions(sessions: TrainingSession[]): TrainingVariable[]{
    let missingVariables: TrainingVariable[] = [];
    let globalVariables = this.userService.getLoggedInUser()?.trainingSettingsLicenceHolder?.trainingVariables?.map(x => x.clone());
    let userVariables = this.trainingPlan.isTemplate ? [] : this.user.trainingVariables?.map(x => x.clone());
    for (let newSession of sessions) {
      let sessionVariables = TrainingPlanEditorComponent.getAllVariablesOfSession(newSession, this.availableTrainingVariables);
      if(sessionVariables.missingVariableIds?.length > 0) {
        for(let missingVariableId of sessionVariables.missingVariableIds){
          if(!this.trainingPlan.trainingVariables.find(x => x.id == missingVariableId)) {
            let variable = userVariables.find(x => x.id == missingVariableId);
            if(!variable) variable = globalVariables.find(x => x.id == missingVariableId);
            if(variable){
              this.trainingPlan.trainingVariables.push(variable);
              missingVariables.push(variable);
            }
          }
        }
      }
    }
    return missingVariables;
  }

  public async addSessionFromTemplate(plannedDay: Date = null, week: Week = null){
    if(!this.trainingPlan.sessions) this.trainingPlan.sessions = []
    let availableTemplateFolders = await this.trainingService.getTrainingPlanTemplateFolders();
    const dialogRef = this.dialog.open(SessionTemplatesDialogComponent, { data: { availableTemplateFolders: availableTemplateFolders }, width: '1000px'})
    dialogRef.afterClosed().subscribe( async result => {
      if(result){
        if(result.shouldTake){
          this.spinner.show()
          let missingVariables = this.addMissingVariablesForSessions(result.selectedSessions);
          
          if(this.trainingPlan.isPeriodicPlan){
            for (let newSession of result.selectedSessions){
              newSession.plannedDate = plannedDay;
              newSession.weekId = week?.id;
              if(plannedDay) {
                let newId = FirestoreNutritionPlanService.generateUniqueString(8);
                result.selectedSessions.forEach(session => {
                  if(session.baseSessionId == newSession.id){
                    session.baseSessionId = newId;
                  }
                });
                newSession.exercises.forEach(exercise => {
                  exercise.sessionId = newId;
                  exercise.id = null;
  
                });
                newSession.id = newId;
              }
            }
          }
          else {
            for (let newSession of result.selectedSessions){
              newSession.plannedDate = null;
              newSession.weekId = null;
              newSession.baseSessionId = null;
            }
          }

          result.selectedSessions.forEach(session => {
            if(this.trainingPlan.isPeriodicPlan && session.baseSessionId){
              let baseSession = result.selectedSessions.find(x => x.id == session.baseSessionId);
              if(!baseSession){
                session.baseSessionId = null;
              }
            }
            this.trainingPlan.sessions.push(session)
          });

          this.setWeekDays();

          if(missingVariables.length > 0){
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
              data: { message: 'Fehlende Variablen wurden automatisch ergänzt. Möchtest du die Werte jetzt überprüfen?', title: 'Variablen bearbeiten', positiveButton: 'Ja', negativeButton: 'Abbrechen' },
            });
            dialogRef.afterClosed().subscribe(result => {
              if(result) {
                this.openTrainingVariablesDialog();
              }
            })
          }

          this.hasChanges = true
          this.spinner.hide()
        }
      }
    })
  }

  async openTrainingVariablesDialog(){
    let foreignVariables = this.user?.trainingVariables?.filter(x => !this.availableTrainingVariables?.find(y => y?.id == x?.id)) ?? [];
    let globalForeignVariabels = this.userService.getLoggedInUser()?.trainingSettingsLicenceHolder?.trainingVariables?.filter(x => !foreignVariables?.find(y => y?.id == x?.id) && !this.availableTrainingVariables?.find(y => y?.id == x?.id));
    if(globalForeignVariabels?.length > 0){
      foreignVariables = foreignVariables.concat(globalForeignVariabels)
    }
    if(this.trainingPlan.isTemplate){
      let coach = this.userService.getLoggedInUser();
      let dialogRef = this.dialog.open(TrainingVariableEditorDialogComponent, {width: '800px', data: { user: coach, trainingVariables: this.trainingPlan.trainingVariables?.map(x => x.clone()), foreignVariables: foreignVariables, trainingVariableEditorDialogType: TrainingVariableEditorDialogType.template}});
      let result = await dialogRef.afterClosed().toPromise();
      if(result){
        this.trainingPlan.trainingVariables = result.trainingVariables;
      }
    }
    else {
      let dialogRef = this.dialog.open(TrainingVariableEditorDialogComponent, {width: '800px', data: { user: this.user, trainingVariables: this.trainingPlan.trainingVariables?.map(x => x.clone()), foreignVariables: foreignVariables, trainingVariableEditorDialogType: TrainingVariableEditorDialogType.plan}});
      let result = await dialogRef.afterClosed().toPromise();
      if(result){
        this.trainingPlan.trainingVariables = result.trainingVariables;
      }
    }

    this.hasChanges = true;

    let formulaCalculationErrors = TrainingPlanEditorComponent.calculateAllVariableValues(this.trainingPlan, this.availableTrainingVariables, this.getUserCardioZoneGroups(), this.languageService.selectedLanguageCode);
    if(formulaCalculationErrors?.length > 0) {
      let firstError = formulaCalculationErrors[0];
      let session = firstError.session;
      this.onSessionSelectionChanged(session);
      let sourceExerciseId = firstError.exercise.exerciseId;
      let exerciseName = this.getExerciseById(sourceExerciseId)?.name?.GetValue(this.languageService.selectedLanguageCode);
      // let errorText = formulaCalculationErrors.join(', ')#
      this.expandedExercise = firstError.exercise;
      let setNumber = firstError.exercise.sets.indexOf(firstError.set) + 1;
      let errorText = '</br>Einheit: ' + session.nameTranslation.GetValue(this.languageService.selectedLanguageCode) + '</br> Übung: ' + exerciseName + '</br> Satz: ' + setNumber + '</br> Parameter: ' + SetParameter2LabelMapping[firstError.setProperty];
      this.toastr.error("Es konnten nicht alle Werte berechnet werden. Bitte überprüfe deine Formeleingaben. " + errorText, "Ungültige Formeleingabe",  {
        positionClass: 'toast-bottom-center',
        enableHtml: true
      })
      return false;
    }
  }

  getUserCardioZoneGroups(){
    if(this.trainingPlan.isTemplate){
      let dummyCardioZoneGroup = CardioZoneGroup.getEmptyDefaultZoneGroup();
      dummyCardioZoneGroup.zones = this.getGlobalCardioZones();
      dummyCardioZoneGroup.paceAvailable = true;
      dummyCardioZoneGroup.pace500Available = true;
      dummyCardioZoneGroup.heartRateAvailable = true;
      return [dummyCardioZoneGroup];
    }
    return this.user.cardioZoneGroups;
  }
  getGlobalCardioZones() {
    return this.selectedCoach.trainingSettingsLicenceHolder.cardioZones;
  }

  getAdjustedTimezoneDate(date: Date, user: User): Date {
    // var currentOffset = (date.getTimezoneOffset() * 60000 * -1)
    // var userOffset = user?.getLatestTimezoneOffsetForDate(date)
    // if (userOffset != null && userOffset != currentOffset) {
    //   var timezoneOffset = currentOffset - userOffset
    //   var newDate = new Date(date.getTime() + timezoneOffset)
    //   return newDate
    // }
    return date
  }

  onDropOnWeekDayItem(weekDay: Date, weekId: string) {
    if(this.draggingSession){
      this.trainingPlan.sessions.sort((a,b) => Number(!b.deleted) - Number(!a.deleted));
      this.draggingSession.plannedDate = this.getAdjustedTimezoneDate(weekDay, this.user)
      this.draggingSession.weekId = weekId;

      let availableSessions = this.trainingPlan.sessions.filter(x => !x.deleted);
      if(availableSessions?.length > 1){
        let firstWeekDaySession = availableSessions.find(x => x.plannedDate == weekDay);
        if(firstWeekDaySession != this.draggingSession){
          let newIndex = availableSessions.indexOf(firstWeekDaySession);
          let previousIndex = availableSessions.indexOf(this.draggingSession);
          if(newIndex < previousIndex){
            moveItemInArray(this.trainingPlan.sessions, previousIndex, newIndex);
          }
        }
      }

      this.draggingSession = null;

      this.focusedWeekDayForSessionDrop = null;
      this.focusedWeekNumberForSessionDrop = null;
      this.focusedAfterDropzoneSession = null;
      this.setWeekDays();
    }
  }
  public addSessionToDay(date: Date, weekId: string){
    let trainingSession = new TrainingSession()
    trainingSession.plannedDate = this.getAdjustedTimezoneDate(date, this.user)
    trainingSession.weekId = weekId
    this.addSession(false, false, trainingSession);
    this.setWeekDays();
  }

  public addWeek(){
    this.trainingPlan.weeks.push(new Week());
    this.setWeekDays();
  }

  public focusedBeforeDropzoneWeek: Week = null;
  public focusedAfterDropzoneWeek: Week = null;

  public movingWeekNumber: number = null;
  public isMovingWeek: boolean = false;
  public draggingWeek: Week = null;

  startMoveWeek(weekNumber){
    this.movingWeekNumber = weekNumber;
    this.isMovingWeek = true;
  }

  removeWeek(week: Week){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du die Einheiten dieser Woche wirklich vollständig löschen?', title: 'Woche löschen' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.movingWeekNumber = null;
        this.isMovingWeek = false;
        let weekNumber = TrainingPlanEditorComponent.getWeekNumberByWeekId(this.trainingPlan, week.id);
        let availableSessions = this.trainingPlan.sessions.filter(x => !x.deleted)
        availableSessions.forEach(session => {
          let sessionWeekNumber = TrainingPlanEditorComponent.getWeekNumberByWeekId(this.trainingPlan, session.weekId);
          if(session.weekId == week.id){
            session.deleted = true;
            let referencedSessions = availableSessions.filter(x => x.baseSessionId == session.id);
            let newBaseSessionId = null;
            for(let index = 0; index < referencedSessions.length; index++){
              if(index == 0){
                referencedSessions[index].baseSessionId = null;
                newBaseSessionId = referencedSessions[index].id;
              }
              else {
                referencedSessions[index].baseSessionId = newBaseSessionId;
              }
            }
          }
          else if(sessionWeekNumber > weekNumber) {
            if(session.plannedDate){
              session.plannedDate.setDate(session.plannedDate.getDate() - 7);
            }
          }
        });
        this.trainingPlan.weeks = this.trainingPlan.weeks.filter(x => x.id != week.id);
        this.setWeekDays();
      }
    });
  }

  onDropWeek(week: Week, after: boolean){
    if(this.draggingWeek == null) return;
    let weekNumbers = this.trainingPlan.weeks;
    let newWeekNumber = weekNumbers.indexOf(week);
    let oldWeekNumber = weekNumbers.indexOf(this.draggingWeek);
    if(after && newWeekNumber < oldWeekNumber && newWeekNumber < weekNumbers.length - 1){
      newWeekNumber++;
    }
    if(oldWeekNumber != newWeekNumber){
      let allSessions = this.trainingPlan.sessions.filter(x => !x.deleted);
      for(let session of allSessions){
        let sessionWeekNumber = TrainingPlanEditorComponent.getWeekNumberByWeekId(this.trainingPlan, session.weekId);
        if(oldWeekNumber > newWeekNumber){
          if(session.weekId == this.draggingWeek.id){
            // session.weekNumber = newWeekNumber;
            if(session.plannedDate){
              session.plannedDate.setDate(session.plannedDate.getDate() - (oldWeekNumber - newWeekNumber) * 7);
            }
          }
          else if(sessionWeekNumber >= newWeekNumber && sessionWeekNumber < oldWeekNumber){
            // session.weekNumber++;
            if(session.plannedDate){
              session.plannedDate.setDate(session.plannedDate.getDate() + 7);
            }
          }
        }
        else {
          if(sessionWeekNumber == oldWeekNumber){
            // session.weekNumber = newWeekNumber;
            if(session.plannedDate){
              session.plannedDate.setDate(session.plannedDate.getDate() + (newWeekNumber - oldWeekNumber) * 7);
            }
          }
          else if(sessionWeekNumber > oldWeekNumber && sessionWeekNumber <= newWeekNumber){
            if(session.plannedDate){
              session.plannedDate.setDate(session.plannedDate.getDate() - 7);
            }
          }
        }
      }
      
      moveItemInArray(this.trainingPlan.weeks, oldWeekNumber, newWeekNumber);

      this.setWeekDays();
      this.hasChanges = true
    }
    this.draggingWeek = null;
    this.focusedAfterDropzoneWeek = null;
    this.focusedBeforeDropzoneWeek = null;
  }


  duplicateWeek(week: Week){
    let availableSessions = this.trainingPlan.sessions.filter(x => !x.deleted)
    let weekSessions = availableSessions.filter(x => x.weekId == week.id)

    let newWeekSessions = weekSessions.map(x => x.clone())

    let newWeek = week.clone();
    newWeek.id = FirestoreNutritionPlanService.generateUniqueString(8);

    let weekNumber = TrainingPlanEditorComponent.getWeekNumberByWeekId(this.trainingPlan, week.id);
    
    let nextWeek = this.getWeekDays(week.id);
    let nextWeekLastDay = nextWeek[nextWeek.length - 2]?.clone();
    nextWeekLastDay.setDate(nextWeekLastDay.getDate() + 7);
    nextWeekLastDay.setHours(0,0,0,0);

    let today = new Date();
    today.setHours(0,0,0,0);
    let nextWeekIsInPast = nextWeekLastDay?.getTime() < today.getTime();

    let diffInDays = 7;

    if(!this.trainingPlan.isTemplate && nextWeekIsInPast) {
      let lastWeek = this.trainingPlan.weeks[this.trainingPlan.weeks.length - 1];
      let tempWeekNumber = TrainingPlanEditorComponent.getWeekNumberByWeekId(this.trainingPlan, lastWeek.id);

      if(nextWeekLastDay){
        while(nextWeekLastDay?.getTime() < today.getTime()){
          tempWeekNumber++;
          let newWeek = new Week();
          newWeek.id = FirestoreNutritionPlanService.generateUniqueString(8);
          nextWeekLastDay.setDate(nextWeekLastDay.getDate() + 7);
          this.trainingPlan.weeks.splice(tempWeekNumber, 0, newWeek);
        }

        let diffInWeeks = tempWeekNumber - weekNumber;
        diffInDays = (diffInWeeks + 1) * 7;

        weekNumber = tempWeekNumber;
        this.toastr.info("Die duplizierte Woche wurde automatisch an das Ende des Plans angehängt.", "Automatisch verschoben", {
          positionClass: 'toast-bottom-center'
        });
      }
      
    }

    newWeekSessions.forEach(session => {
      if(!session.baseSessionId) {
        session.baseSessionId = session.id
      }
      session.id = FirestoreNutritionPlanService.generateUniqueString(8)
      session.exercises.forEach(exercise => exercise.id = null)
      session.weekId = newWeek.id;
      if(session.plannedDate) {
        let date = new Date(session.plannedDate)
        date.setDate(date.getDate() + diffInDays)
        date.setHours(0,0,0,0);
        session.plannedDate = this.getAdjustedTimezoneDate(date, this.user)
      }
    })
    this.trainingPlan.sessions = this.trainingPlan.sessions.concat(newWeekSessions)
    let newWeekNumber = weekNumber + 1;
    this.trainingPlan.weeks.splice(newWeekNumber, 0, newWeek);
    this.trainingPlan.sessions.forEach(
      session => {
        let sessionWeekNumber = TrainingPlanEditorComponent.getWeekNumberByWeekId(this.trainingPlan, session.weekId);
        if(sessionWeekNumber > newWeekNumber) {
          if(session.plannedDate) {
            let date = new Date(session.plannedDate)
            date.setDate(date.getDate() + 7)
            session.plannedDate = date
          }
        }
      }
    )
    this.setWeekDays();
  }

  unfocus() {
    try {
      document.activeElement['blur']();
    }
    catch (ex) {
      console.error(ex);
    }
  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public newThumbnail: File;
  public thumbnailImageSrc: any;
  public removeThumbnail: boolean = false;

  public async saveTrainingPlan() {
    this.unfocus();
    if (!this.setAndCheckAllValues()) {
      return false
    }
    if(this.trainingPlan.nameTranslation?.GetValue(this.languageService.selectedLanguageCode) == null || this.trainingPlan.nameTranslation?.GetValue(this.languageService.selectedLanguageCode)?.length == 0) {
      this.trainingPlan.nameTranslation.SetValue(this.languageService.selectedLanguageCode, "Trainingsplan");
    }

    this.trainingPlan.sessions?.filter(x => !x.deleted).forEach((session, i) => {
      if(session.nameTranslation.GetValue(this.languageService.selectedLanguageCode)?.length == 0 || session.nameTranslation.GetValue(this.languageService.selectedLanguageCode) == null){
        if(this.languageService.isLanguageGerman) {
          session.nameTranslation.SetValue(this.languageService.selectedLanguageCode, "Einheit " + (i + 1));
        }
        else {
          session.nameTranslation.SetValue(this.languageService.selectedLanguageCode, "Session " + (i + 1));
        }
      }
    });

    if (this.trainingPlan.nameTranslation[this.languageService.selectedLanguageCode]?.length > 0 && this.trainingPlan.sessions?.filter(x => !x.deleted).filter(x => x.name?.length <= 0 || x.name?.length == null).length === 0){
      if(this.shouldRemoveEmptySessions(this.trainingPlan)){
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          data: { message: 'Dein Plan enthält leere Einheiten. Sollen diese automatisch entfernt werden?', title: 'Leere Einheiten entfernen', positiveButton: 'Ja', negativeButton: 'Nein' },
        });
        let result = await dialogRef.afterClosed().toPromise();
        if(result == true){
          this.removeEmptySessions(this.trainingPlan);
        }
      }
      this.trainingPlan.sessions.forEach(session => {
        session.indicatorColor = TrainingPlanEditorComponent.getBaseSessionReferenceColor(session, this.trainingPlan.sessions);
        session.exercises.forEach(exercise => {
          if (exercise.pauseDuration == null || exercise.pauseDuration == undefined) exercise.pauseDuration = this.getExerciseById(exercise.exerciseId)?.pauseDuration || this.getExerciseById(exercise.exerciseId)?.getDefaultPauseDuration() 
        })
      })
      //this.saveChangesEvent.emit(this.trainingPlan)
      if (this.dialogRef) {
        this.dialogRef?.close({delete: false, save: true, newThumbnail: this.newThumbnail, removeThumbnail: this.removeThumbnail})
      } else {
        this.spinner.show()
        if (this.trainingPlan.id?.length > 0){
          await this.userService.updateTrainingPlan(this.user, this.selectedCoach, this.trainingPlan, this.removeThumbnail, this.thumbnailImageSrc, null)
          this.notificationService.composeTrainingPlanUpdateNotification()
        } else{
          await this.userService.saveTrainingPlan(this.user, this.selectedCoach, this.trainingPlan, this.removeThumbnail, this.thumbnailImageSrc, null)
          this.notificationService.composeTrainingPlanCreationNotification()
        }
        await this.userService.updateTrainingPlanEndDateInMetadata(this.user)
        this.spinner.hide()
      }

      return true
    } else{
      this.toastr.error("Bitte überprüfe deine Eingaben.", "", {
        positionClass: 'toast-bottom-center'
      });
      return false
    }
  }
  getExerciseById(exerciseId: string):MergedTrainingExercise{
    return this.trainingService.MergedTrainingExercises.filter(x => x.sourceExerciseId == exerciseId)[0] || null
  }

  shouldRemoveEmptySessions(trainingPlan: TrainingPlan): boolean{
    return trainingPlan.sessions?.filter(x => !x.deleted && !x.isRestDay && x.exercises.length == 0)?.length > 0
  }

  removeEmptySessions(trainingPlan: TrainingPlan): boolean{
    let removedSomething = false;
    trainingPlan.sessions.forEach(session => {
      if(!session.deleted && !session.isRestDay){
        if(session.exercises.filter(x => !x.deleted).length === 0) {
          session.deleted = true;
          removedSomething = true;
        }
      }
    });

    return removedSomething;
  }

  static calculateAllVariableValues(trainingPlan: TrainingPlan, trainingVariables: TrainingVariable[], availableCardioZoneGroups: CardioZoneGroup[], selectedLanguageCode: string): {session: TrainingSession, exercise: PlannedTrainingExercise, set: TrainingSet, setProperty: string, variable: TrainingVariable}[] {
    // let formulaCalculationErrors: string[] = []
    let formulaCalculationErrorInformation: {session: TrainingSession, exercise: PlannedTrainingExercise, set: TrainingSet, setProperty: string, variable: TrainingVariable}[] = []
    trainingPlan.sessions.forEach(session => {
      if (!session.deleted && !session.isTracked) {
        session.exercises.forEach(exercise => {
          if (!exercise.deleted) {
            exercise.setParameters.forEach(setParameter => {
              exercise.sets.forEach(set => {
                if (BaseTrainingEditor.hasSetParameterFormulaInput(setParameter)) {
                  if (!TrainingPlanEditorComponent.setCalculatedFormulaValue(set, setParameter, trainingVariables, TrainingPlanEditorComponent.getUserCardioZoneGroupByExerciseId(exercise.exerciseId, setParameter, availableCardioZoneGroups)?.zones)) {
                    let variable = TrainingPlanEditorComponent.getVariableByFormula(set[setParameter + 'Formula'], trainingVariables);
                    if (variable) {
                      // formulaCalculationErrors.push(session.nameTranslation.GetValue(selectedLanguageCode) + '-' + SetParameter2LabelMapping[setParameter] + ': ' + variable.name);
                      formulaCalculationErrorInformation.push({session: session, exercise: exercise, set: set, setProperty: setParameter, variable: variable});
                    }
                    else {
                      // formulaCalculationErrors.push(session.nameTranslation.GetValue(selectedLanguageCode) + '-' + SetParameter2LabelMapping[setParameter] + ': ' + set[setParameter + 'Formula']);
                      formulaCalculationErrorInformation.push({session: session, exercise: exercise, set: set, setProperty: setParameter, variable: null});
                    }
                  }
                }
              });
            });
          }
        });
      }
    });
    return formulaCalculationErrorInformation;
  }

  static hasSetParameterFormulaInput(setParameter: SetParameter): boolean {
    return setParameter == SetParameter.weight || setParameter == SetParameter.pace || setParameter == SetParameter.heartRate || setParameter == SetParameter.pace500 || setParameter == SetParameter.reps;
  }  

  setAndCheckAllValues(): boolean{
    var inputValid = true;
    var firstInvalidSession: TrainingSession = null;
    for (var session of this.trainingPlan.sessions) {
      if(session.indicatorColor?.includes('rgb')) {
        try{
          let converted = ClientBaseComponent.rgbaStringToHex(session.indicatorColor);
          session.indicatorColor = converted;
        }
        catch(ex){
          console.error(ex);
        }
      }
      for (var superSet of session.superSets) {
        if(superSet?.exercises[0]?.superSetConfig) {
          if(superSet.exercises[0].superSetConfig.numberOfRounds == NumberOfRounds.AMRAP) {
            inputValid = inputValid && this.checkTotalAvailableTime(superSet.exercises[0].superSetConfig)
          }
          else {
            superSet.exercises[0].superSetConfig.roundAvailableTime = null
            superSet.exercises[0].superSetConfig.totalAvailableTime = null
          }
        }
        for (var exercise of superSet.exercises) {
          if(exercise.setParameters.includes(SetParameter.timeUnderTension)){
            for (var set of exercise.sets) {
              var valid = this.trainingPlanEditorHelper.checkTUTINput(set)
              inputValid = inputValid && valid
            }
          }
        }
      }
      if(!inputValid && firstInvalidSession == null){
        firstInvalidSession = session;
      }
    }
    if(!inputValid) {
      if(firstInvalidSession){
        this.onSessionSelectionChanged(firstInvalidSession);
      }
      this.toastr.error("Dein Plan enthält Fehler. Bitte überprüfe die rot markierten Felder und korrigiere deine Eingaben.", "Ungültige Eingaben",  {
        positionClass: 'toast-bottom-center'
      })
      return false;
    }

    
    let formulaCalculationErrors = TrainingPlanEditorComponent.calculateAllVariableValues(this.trainingPlan, this.availableTrainingVariables, this.getUserCardioZoneGroups(), this.languageService.selectedLanguageCode);

    if(formulaCalculationErrors?.length > 0) {
      let firstError = formulaCalculationErrors[0];
      let session = firstError.session;
      this.onSessionSelectionChanged(session);
      let sourceExerciseId = firstError.exercise.exerciseId;
      let exerciseName = this.getExerciseById(sourceExerciseId)?.name?.GetValue(this.languageService.selectedLanguageCode);
      // let errorText = formulaCalculationErrors.join(', ')#
      this.expandedExercise = firstError.exercise;
      let setNumber = firstError.exercise.sets.indexOf(firstError.set) + 1;
      let errorText = '</br>Einheit: ' + session.nameTranslation.GetValue(this.languageService.selectedLanguageCode) + '</br> Übung: ' + exerciseName + '</br> Satz: ' + setNumber + '</br> Parameter: ' + SetParameter2LabelMapping[firstError.setProperty];
      this.toastr.error("Es konnten nicht alle Werte berechnet werden. Bitte überprüfe deine Formeleingaben. " + errorText, "Ungültige Formeleingabe",  {
        positionClass: 'toast-bottom-center',
        enableHtml: true
      })
      return false;
    }
    return true;
  }

  public async setCurrentSession(){
    var availableSessions = this.trainingPlan?.sessions?.filter(x => !x.deleted).sort((a, b) => a.plannedDate?.getTime() - b.plannedDate?.getTime());
    if(availableSessions?.length > 0) {
      let sessionToSelect = availableSessions[0];
      if(this.trainingPlan.isPeriodicPlan){
        let today = new Date();
        let nextDateSession = availableSessions?.find(x => x.plannedDate?.isSameDate(today) || x.plannedDate?.getTime() > today?.getTime());
        if(nextDateSession) sessionToSelect = nextDateSession;
      }
      await this.onSessionSelectionChanged(sessionToSelect);
    }
    else {
      this.selectedSession = null;
    }
  }

  public async onSessionSelectionChanged(session: TrainingSession){
    this.closeSessionsColumn();
    if(session != null) {
      await this.loadTrackedTrainingSession(session);
      if(session.plannedDate){
        this.scrollDayIntoView(session.plannedDate);
      }
    }
    this._selectedSession = session
  }

  toggleSessionsColumn() {
    this.sessionsColumnClosed = !this.sessionsColumnClosed;
  }

  closeSessionsColumn() {
    this.sessionsColumnClosed = true;
  }


  private scrollDayIntoView(date: Date){
    setTimeout(() => {
      let weekOfDate = this.trainingPlan.getWeekForDate(date);
      if(weekOfDate){
        if(weekOfDate.collapsed) weekOfDate.collapsed = false;
        let weekElement = document.getElementById('week-' + weekOfDate.id);
        if(weekElement) weekElement.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
      }
      let element = document.getElementById('weekDay-' + date?.getTime());
      if(element) element.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
    }, 100);
  }

  private scrollSessionItemIntoView(session: TrainingSession){
    let sessionItem = document.getElementById('sessionItem-' + session.id);
    if(sessionItem){
      sessionItem.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
    }
  }

  public async onDaySelectionChanged(calendarDay: SingleCalendarDay<TrainingSession>) {
    if(this.trainingPlan.isPeriodicPlan){
      let session = this.trainingPlan.sessions.find(x => !x.deleted && x.plannedDate?.isSameDate(calendarDay.date));
      if(session){
        this.onSessionSelectionChanged(session);
      }
      this.scrollDayIntoView(calendarDay.date);
    }
    else {
      if(calendarDay.calendarItems.length > 0){
        let session = calendarDay.calendarItems[0]?.item;
        if(session){
          this.onSessionSelectionChanged(session);
          this.scrollSessionItemIntoView(session);
        }
      }
    }
  }


  toggleExerciseRecordingRequestedForSet(exercise: PlannedTrainingExercise, set: TrainingSet) {
    set.videoRecordingRequested = !set.videoRecordingRequested;
    this.hasChanges = true
  }
  
  checkTotalAvailableTime(superSetConfig:SuperSetConfig):boolean {
    if(superSetConfig.totalAvailableTime < 1){
      superSetConfig.totalAvailableTime = 300;
      this.toastr.error("Bitte gib eine gültige verfügbare Zeit ein.", "",  {
        positionClass: 'toast-bottom-center'
      });
      return false
    }
    return true
  }


}