import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-inputfield-dialog',
  templateUrl: './inputfield-dialog.component.html',
  styleUrls: ['./inputfield-dialog.component.css']
})
export class InputFieldDialogComponent implements OnInit {

  public message: string
  public title: string
  public input: string

  constructor(public dialogRef: MatDialogRef<InputFieldDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {message: string, title: string, input: string}) {
    this.message = data.message
    this.title = data.title
    this.input = data.input
  }

  ngOnInit(): void {
  }

  onCancelDialog() {
    this.dialogRef.close(null);
  }
  onConfirmDialog() {
    this.dialogRef.close({input: this.input});
  }

}
