import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth/auth.service';
import { User } from '../model/user.model';
import { FirestoreService } from '../services/firestore.service';
import { UtilityService } from '../services/utility.service';
import { MarketingKitComponent } from '../dialogs/marketing-kit/marketing-kit.component';
import {  MatDialog } from '@angular/material/dialog';
import { CoachInvoice } from '../model/invoice-coach.model';
import { firstValueFrom } from 'rxjs';
import { FilterObject } from '../filter-selection-dropdown/filter-selection-dropdown.component';
import { ILanguageDictionary, LanguageDictionary } from '../model/languagedictionary.model';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { BanktransferDialogComponent } from '../dialogs/banktransfer-dialog/banktransfer-dialog.component';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  constructor(private router: Router, public userService: FirestoreService, private authService: AuthService, public utilityService: UtilityService, private toastr: ToastrService, private ngZone: NgZone, public dialog: MatDialog) {
    this.user = userService.getLoggedInUser()
  }

  get coachInvoices() {
    return this.user?.licenceHolder?.coachInvoices
  }

  ngOnInit(): void {
    if (this.utilityService.onNativeMobileApp()) return

    if (this.user.getStripeCustomerId()) {
      this.loadStripeCustomerPortalLink()
    } else if (this.user.isLicenceHolder) {
      if (!this.user.licenceHolder) {
        var licenceHolderSubscription = this.userService.getLicenceHolderByUid(this.user.licenceHolderUid).subscribe(licenceHolder => {
          if (this.user.licenceHolderUid == this.user.uid) this.user.isLicenceHolder = true
          this.user.licenceHolder = licenceHolder
          this.userService.loadSubscriptions(this.user)
          licenceHolderSubscription.unsubscribe()
          if (this.user.getStripeCustomerId()) {
            this.loadStripeCustomerPortalLink()
          } else {
            this.createStripeCustomer()
          }
        })
      } else {
        this.createStripeCustomer()
      }
    }
    if (this.user.getStripeCustomerId()) this.loadStripeCheckoutSession();

    if (!this.isAdmin()) {
      this.hideInvoices = false
    } else {
      this.userService.loadCoachInvoices()
    }
  }

  isAdmin() {
    return this.authService.isAdmin()
  }

  onShowInvoices() {
    this.hideInvoices = !this.hideInvoices
  }

  user: User
  subscription: any
  stripeCustomerPortalLink: string = null
  stripeCheckoutSessionLink: string = null
  hideInvoices: boolean = true


  public getCoachInvoices() {
    return this.coachInvoices.filter(x => this.fallsInFilter(this.availableStatuses, x.status, false))
  }

  onDeleteInvoice(invoice: CoachInvoice) {
    this.userService.deleteCoachInvoiceFromSevDesk(invoice).then(async (res) => {
      console.log('Result: ', res)
      if (res.success) {
        await this.userService.deleteInvoiceOfCoach(invoice)
        this.toastr.success('Rechnung erfolgreich gelöscht')
      } else if (res.error) {
        if (res.error == 'Invoice not found') {
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: { message: 'Rechnung nicht gefunden in SevDesk, Zahlung trotzdem löschen?', title: 'Rechnung löschen' },
          });
          dialogRef.afterClosed().subscribe(async result => {
            if (result) {
              await this.userService.deleteInvoiceOfCoach(invoice)
            }
          });
        }
      }
    })
  }
  onMarkInvoiceAsPaid(invoice: CoachInvoice) {
    this.userService.markSevDeskCoachInvoiceAsPaid(invoice).then(async (res) => {
      console.log('Result: ', res)
      if (res.success) {
        await this.userService.markInvoiceOfCoachAsPaid(invoice)
        this.toastr.success('Rechnung erfolgreich bezahlt')
      } else if (res.error) {
        console.log(res.error)
      }
    })
  }

  hasFailedPayments() {
    return this.coachInvoices.filter(x => x.status == 'failed').length > 0
  }

  fallsInFilter(filter:FilterObject[], item: string, isTranslatable: boolean = true):boolean{
    return (!this.hasFilter(filter) || (filter.filter(i => i.isFiltered && this.getCompareText(i.originObject, isTranslatable) === item)).length > 0)
  }
  hasFilter(filterObjects:FilterObject[]):boolean{
    return filterObjects?.filter(x => x.isFiltered)?.length > 0
  }
  getCompareText(filterObject: ILanguageDictionary<any>, isTranslatable: boolean = true):string{
    if(isTranslatable) return filterObject.comparableValue
    else return filterObject.originObject.toString()
  }

  public availableStatuses: FilterObject[] = [
    new FilterObject(new LanguageDictionary('Bezahlt', 'Paid', 'paid'), false),
    new FilterObject(new LanguageDictionary('Ausstehend', 'Pending', 'pending'), false),
    new FilterObject(new LanguageDictionary('Manuelle Überweisung initiiert', 'Bank transfer initiated', 'manual_transfer_initiated'), false),
    new FilterObject(new LanguageDictionary('Fehlgeschlagen', 'Failed', 'failed'), false),
    new FilterObject(new LanguageDictionary('Wird erneut versucht', 'Retrying', 'failed_retrying'), false),
    new FilterObject(new LanguageDictionary('Erstattet', 'Refunded', 'refunded'), false),
    new FilterObject(new LanguageDictionary('Storniert', 'Voided', 'voided'), false),
  ];

  onShowBankTransferDialog(invoice: CoachInvoice) {
    const dialogRef = this.dialog.open(BanktransferDialogComponent, {
      data: { amount: invoice.amount, currency: invoice.currency, purposeOfTransfer: invoice.invoiceNumber },
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result?.bankTransferInitiated) {
        await this.userService.updateCoachInvoicePaymentStatus(invoice, 'manual_transfer_initiated')
      }
    })
  }

  loadStripeCustomerPortalLink() {
    this.userService.createStripeCustomerPortalSession(this.user.getStripeCustomerId()).subscribe(result => {
      this.ngZone.run( () => {
        this.stripeCustomerPortalLink = result.redirectUrl
      });
    }, err => {})
  }
  loadStripeCheckoutSession() {
    this.user.observableSubscription.subscribe(subscription => {
      this.ngZone.run( () => {
        this.subscription = subscription
      });
      if (!subscription) {
        this.userService.createStripeCheckoutSession(this.user.getStripeCustomerId(), this.user.licenceHolder?.stripePriceId ?? 'price_1K5pTxJHstnwiu1fq2OSRAvr', !this.user.licenceHolder?.preStarterPackageClient, this.user.licenceHolder?.allowPaymentMethodCreditCard ?? false).subscribe(result => {
          this.ngZone.run( () => {
            this.stripeCheckoutSessionLink = result.redirectUrl
          });
        }, err => {})
      } else {
      }
    })
  }
  createStripeCustomer() {
    this.userService.createStripeCustomer(this.user.licenceHolderUid, this.authService.user.email, this.user.licenceHolder?.licenceHolderName ?? this.user.name).subscribe(result => {
      var customerId = result.customerId
      this.user.licenceHolder.stripeCustomerId = customerId
      this.loadStripeCustomerPortalLink()
      this.loadStripeCheckoutSession()
    }, err => {
      console.log(err)
    })
  }

  onTrainingEnabledChanged(value: boolean) {
    this.user.coach.trainingEnabled = value
    this.userService.updateTrainingEnabled(this.user, value)
  }

  canAccessTraining(): boolean {
    return this.authService.canAccessTraining()
  }

  openMarketingKit() {
    const dialogRef = this.dialog.open(MarketingKitComponent, { data: { }, width: '1000px'})
  }
}
