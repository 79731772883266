<div class="row">
    <div class="col-12">
        <div *ngIf="utilityService.onNativeMobileApp()" class="layout-box">
            <h3 class="display-inlineblock">Rechnungen</h3><br>
            Rufe die Coaching Zone im Browser auf und melde dich an, um auf die Zahlungen zuzugreifen.
        </div>

        <div *ngIf="!utilityService.onNativeMobileApp()" class="layout-box table-wrapper-box">

            <div class="row">
                <div class="col-3"></div>
                <div class="col-6 textalign-center tab-button-group adjust-mobile">
                    <button class="tab-button {{currentTab == tabPayments ? 'active' : ''}}" (click)="onChangeTab(tabPayments)">
                        Rechnungen
                        <div class="underline"></div>
                    </button>
                    <button class="tab-button {{currentTab == tabSubscriptions ? 'active' : ''}}" (click)="onChangeTab(tabSubscriptions)">
                        Käufe
                        <div class="underline"></div>
                    </button>
                </div>
                <div class="col-3">
                    <div *ngIf="currentTab == tabPayments">
                        <div class="display-inlineblock marginleft-10 float-right">
                            <div class="icon-button" mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-gear padding-4" viewBox="0 0 16 16">
                                    <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0"/>
                                    <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z"/>
                                </svg>
                                <span class="only-desktop">Weitere Aktionen</span>
                            </div>
                            <mat-menu #menu="matMenu" class="padding-15">
                                <div class="icon-button margin-4 mobile-marginbottom-10" (click)="onExportInvoices()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                    </svg>
                                    Rechnungen herunterladen
                                </div>
                                <div class="spacer-10"></div>
                                <div class="icon-button margin-4 mobile-marginbottom-10" (click)="onExportPaymentTable()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                    </svg>
                                    Tabelle herunterladen
                                </div>
                                <div class="spacer-10"></div>
                                <div class="icon-button margin-4 mobile-marginbottom-10" (click)="onCreatePayoutReport()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bank" viewBox="0 0 16 16">
                                        <path d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.5.5 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89zM3.777 3h8.447L8 1zM2 6v7h1V6zm2 0v7h2.5V6zm3.5 0v7h1V6zm2 0v7H12V6zM13 6v7h1V6zm2-1V4H1v1zm-.39 9H1.39l-.25 1h13.72z"/>
                                    </svg>
                                    Auszahlungsabgleich
                                </div>
                            </mat-menu>
                        </div>
                    </div>
                </div>
            </div>
            <div class="spacer-25"></div>

            <div *ngIf="currentTab == tabPayments" class="table payments-table">

                <button *ngIf="paymentService.canUsePayment" class="button-filled small" (click)="onCreatePayment()">
                    Erstellen
                </button>
                <div class="spacer-25"></div>

                <div class="row table-item table-header">
                    <div class="col">
                        Betrag
                    </div>
                    <div class="col">
                        Status
                        <app-filter-selection-dropdown class="marginleft-10" [availableFilterObjects]="availablePaymentStatuses" [selectedLanguage]="'de'"></app-filter-selection-dropdown>
                    </div>
                    <div class="col">
                        Kunde
                        <app-filter-selection-dropdown class="marginleft-10" [hasSearchField]="true" [availableFilterObjects]="availableClients" [selectedLanguage]="'de'" (onFilterOpened)="onOpenClientsFilter()"></app-filter-selection-dropdown>
                    </div>
                    <div class="col">
                        Datum
                    </div>
                    <div class="col">
                        Rechnung
                    </div>
                    <div class="col" matTooltip="Das Auszahlungsdatum wird erst gesetzt, wenn du einen Auszahlungsabgleich durchgeführt hast. Diesen findest du oben rechts unter Weitere Aktionen.">
                        Auszahlungsdatum
                        <app-filter-selection-dropdown class="marginleft-10" [hasSearchField]="true" [availableFilterObjects]="availablePayoutDates" [selectedLanguage]="'de'" (onFilterOpened)="onOpenPayoutDatesFilter()"></app-filter-selection-dropdown>
                    </div>
                    <div class="col">
                        Einzugsdatum
                        <app-filter-selection-dropdown class="marginleft-10" [hasSearchField]="true" [availableFilterObjects]="availableReversePayoutDates" [selectedLanguage]="'de'" (onFilterOpened)="onOpenReversePayoutDatesFilter()"></app-filter-selection-dropdown>
                    </div>
                </div>

                <div class="row table-item link" *ngFor="let payment of payments" (click)="onSelectPayment(payment)">
                    <div class="col">
                        {{payment.getPrintablePriceWithCurrency()}}
                        <div *ngIf="payment.status == 'voided'">-{{payment.getPrintablePriceWithCurrency()}} (Storno)</div>
                    </div>
                    <div class="col">
                        <div *ngIf="payment.getPrintableStatus()" class="status-indicator-wrapper">
                            <div class="status-indicator {{payment.status}}"></div>
                            {{payment.getPrintableStatus()}}
                        </div>
                    </div>
                    <div class="col">
                        {{payment.customer?.getName() ?? 'Unbekannter Kunde'}}
                    </div>
                    <div class="col">
                        {{payment.date.asFormatedString()}}
                    </div>
                    <div class="col">
                        <div class="link" (click)="onOpenInvoice(payment);$event.stopPropagation()">{{payment.invoiceNumber}}</div>
                        <div *ngIf="payment.cancellationInvoiceNumber" class="link" (click)="onOpenCancellationInvoice(payment);$event.stopPropagation()">{{payment.cancellationInvoiceNumber}} (Storno)</div>
                    </div>
                    <div class="col">
                        {{payment.payoutDate?.asFormatedString()}}
                    </div>
                    <div class="col">
                        {{payment.reversePayoutDate?.asFormatedString()}}
                    </div>
                </div>

                <div class="row table-item">
                    <div class="col">
                        {{getTotalAmount()}} €
                    </div>
                    <div class="col">
                        {{payments.length}} {{payments.length == 1 ? 'Rechnung' : 'Rechnungen'}}
                    </div>
                    <div class="col">
                    </div>
                    <div class="col">
                    </div>
                    <div class="col">
                    </div>
                    <div class="col">
                    </div>
                </div>
            </div>

            <div *ngIf="currentTab == tabSubscriptions" class="table purchase-table">

                <button *ngIf="paymentService.canUsePayment" class="button-filled small" (click)="onCreatePurchase()">
                    Erstellen
                </button>
                <div class="spacer-25"></div>

                <div class="row table-item table-header">
                    <div class="col">
                        ID
                    </div>
                    <div class="col sort-attribute {{purchasesSortAttribute == 'customer' ? 'active' : ''}} {{purchasesSortDirection}}" (click)="onPurchaseSortChange('customer')">
                        Kunde
                        <app-filter-selection-dropdown class="marginleft-10" [hasSearchField]="true" [availableFilterObjects]="availableClients" [selectedLanguage]="'de'" (onFilterOpened)="onOpenClientsFilter()"></app-filter-selection-dropdown>
                    </div>
                    <div class="col sort-attribute {{purchasesSortAttribute == 'product' ? 'active' : ''}} {{purchasesSortDirection}}" (click)="onPurchaseSortChange('product')">
                        Produkt
                        <app-filter-selection-dropdown class="marginleft-10" [hasSearchField]="true" [availableFilterObjects]="availableProducts" [selectedLanguage]="'de'" (onFilterOpened)="onOpenProductsFilter()"></app-filter-selection-dropdown>
                    </div>
                    <div class="col">
                        Status
                        <app-filter-selection-dropdown class="marginleft-10" [availableFilterObjects]="availablePurchaseStatuses" [selectedLanguage]="'de'"></app-filter-selection-dropdown>
                    </div>
                    <div class="col sort-attribute {{purchasesSortAttribute == 'nextPaymentDate' ? 'active' : ''}} {{purchasesSortDirection}}" (click)="onPurchaseSortChange('nextPaymentDate')">
                        Nächste Zahlung
                    </div>
                    <div class="col sort-attribute {{purchasesSortAttribute == 'startDate' ? 'active' : ''}} {{purchasesSortDirection}}" (click)="onPurchaseSortChange('startDate')">
                        Start
                    </div>
                    <div class="col sort-attribute {{purchasesSortAttribute == 'endDate' ? 'active' : ''}} {{purchasesSortDirection}}" (click)="onPurchaseSortChange('endDate')">
                        Ende
                    </div>
                    <div class="col">
                        Aktion
                    </div>
                </div>

                <div class="row table-item link" *ngFor="let subscription of productPurchases" (click)="onSelectProductPurchase(subscription)">
                    <div class="col label">
                        {{subscription.id}}
                    </div>
                    <div class="col">
                        {{subscription.customer?.getName() ?? subscription.licence?.presetName ?? subscription.licence?.email ?? 'Unbekannter Kunde'}}
                    </div>
                    <div class="col">
                        {{subscription.getProductName() ?? 'Unbekanntes Produkt'}}
                    </div>
                    <div class="col">
                        <div *ngIf="subscription.getPrintableStatus()" class="status-indicator-wrapper">
                            <div class="status-indicator {{subscription.status}}"></div>
                            {{subscription.getPrintableStatus()}}
                        </div>
                    </div>
                    <div class="col">
                        {{subscription.nextPaymentDate?.asFormatedString()}}
                    </div>
                    <div class="col">
                        {{subscription.getPrintableStartDate()}}
                    </div>
                    <div class="col">
                        {{subscription.getPrintableEndDate()}}
                    </div>
                    <div class="col">
                        <div *ngIf="subscription.status == 'uncompleted'" class="icon-button small margin-inbetween" (click)="onEditSubscription(subscription);$event.stopPropagation()">
                            <svg class="padding-4" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-up-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                            </svg>
                            Erneut einladen
                        </div>
                        <div *ngIf="(subscription.status == 'purchased' || subscription.status == 'active') && !subscription.endDate" class="icon-button small margin-inbetween" (click)="onCancelSubscription(subscription);$event.stopPropagation()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                            Verlängerung kündigen
                        </div>
                        <div *ngIf="subscription.status == 'purchased' && subscription.licenceId && !subscription.licence?.active" class="icon-button small margin-inbetween" (click)="onActivateLicence(subscription);$event.stopPropagation()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-send padding-4" viewBox="0 0 16 16">
                                <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z"/>
                              </svg>
                            Sofort aktivieren
                        </div>
                        <div *ngIf="(subscription.status == 'purchased' || subscription.status == 'active')" class="icon-button small margin-inbetween" (click)="onDeleteSubscription(subscription);$event.stopPropagation()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                            Sofort löschen
                        </div>
                        <div *ngIf="subscription.status == 'uncompleted' || subscription.status == 'draft'" class="icon-button small margin-inbetween" (click)="onDeleteSubscription(subscription);$event.stopPropagation()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                            Löschen
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true"><div *ngIf="spinnerText" class="spinner-overlaytext" [innerHTML]="spinnerText"></div></ngx-spinner>