import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { TimerangeSelectionDialogComponent } from 'src/app/dialogs/timerange-selection-dialog/timerange-selection-dialog.component';
import { ProductPurchase } from 'src/app/model/product-purchase.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { PaymentService } from 'src/app/services/payment.service';
import { RenewalDialogComponent } from '../renewal-dialog/renewal-dialog.component';
import { EmailTemplate } from 'src/app/model/payment-settings.model';
import { EmailTemplateDialogComponent } from 'src/app/dialogs/email-template-dialog/email-template-dialog.component';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CustomerPaymentSettings, PaymentMethod } from 'src/app/model/customer-payment-settings.model';
import { Moment } from 'moment';

@Component({
  selector: 'app-purchase-dialog',
  templateUrl: './purchase-dialog.component.html',
  styleUrls: ['./purchase-dialog.component.css']
})
export class PurchaseDialogComponent {

  productPurchase: ProductPurchase
  email: string
  defaultPaymentMethod: PaymentMethod

  constructor(public dialogRef: MatDialogRef<PurchaseDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {productPurchase: ProductPurchase}, public userService: FirestoreService, private spinner: NgxSpinnerService, private dialog: MatDialog, private paymentService: PaymentService, private toastr: ToastrService) {
    this.productPurchase = data.productPurchase
    if (this.productPurchase.customerUid) userService.getEmailForUser(this.productPurchase.customerUid).then((email) => {
      this.email = email
    })

    this.userService.getCustomerPaymentSettings(this.productPurchase.customerUid).then(customerPaymentSettings => {
      if (customerPaymentSettings && customerPaymentSettings.defaultPaymentMethods) {
        this.defaultPaymentMethod = customerPaymentSettings.defaultPaymentMethods.find(x => x.licenceHolderUid == this.productPurchase.licenceHolderUid)
      }
    })
  }

  getDefaultPaymentMethod() {
    if (this.defaultPaymentMethod) {
      return this.defaultPaymentMethod.getPrintablePaymentMethod()
    }
    return null
  }

  onEditStartDate() {
    const dialogRef = this.dialog.open(TimerangeSelectionDialogComponent, {
      data: { startDate: this.productPurchase.startDate, showEndDate: false },
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result && result.startDate) {
        this.spinner.show()
        var startDate = result.startDate
        startDate.setHours(0)
        startDate.setMinutes(0)
        startDate.setSeconds(0)
        startDate.setMilliseconds(0)
        this.userService.updateProductPurchaseStartDate(this.productPurchase.id, startDate).then((res) => {
          if (res?.success == true) {
            this.productPurchase.startDate = startDate
          }
          this.spinner.hide()
        })
      }
    })
  }

  onEditEndDate() {
    const dialogRef = this.dialog.open(RenewalDialogComponent, {
      data: { productPurchase: this.productPurchase }, width: '500px'
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        this.spinner.show()
        this.spinner.hide()
      }
    });
  }

  onEditPaymentDate() {
    var currentShift = this.productPurchase.currentPaymentDateShift
    var minDate = this.productPurchase.nextPaymentDate.clone()
    minDate.setDate(minDate.getDate() - currentShift)
    var maxDate = this.productPurchase.nextPaymentDate.clone()
    var maxShift = 0
    if (this.productPurchase.durationUnit == 'month') {
      maxShift = 28 * this.productPurchase.duration
    } else if (this.productPurchase.durationUnit == 'year') {
      maxShift = 364 * this.productPurchase.duration
    } else if (this.productPurchase.durationUnit == 'day') {
      maxShift = this.productPurchase.duration - 1
    } else if (this.productPurchase.durationUnit == 'week') {
      maxShift = 6 * this.productPurchase.duration
    }
    maxDate.setDate(maxDate.getDate() + maxShift - currentShift)

    var dateFilter = (d: Moment | null): boolean => {
      if (!d?.toDate()) return false
      return d?.toDate().isSameOrAfterDate(minDate) && d?.toDate().isSameOrBeforeDate(maxDate)
    }
    const dialogRef = this.dialog.open(TimerangeSelectionDialogComponent, {
      data: { startDate: this.productPurchase.nextPaymentDate, showEndDate: false, startDateFilter: dateFilter },
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result && result.startDate) {
        this.spinner.show()
        var newPaymentDate = result.startDate
        const diffTime = newPaymentDate.getTime() - this.productPurchase.nextPaymentDate.getTime()
        const newShift = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        this.userService.updateProductPurchasePaymentDate(this.productPurchase.id, newShift).then((res) => {
          if (res?.success == true) {
            this.productPurchase.nextPaymentDate = newPaymentDate
            this.productPurchase.currentPaymentDateShift = currentShift + newShift
          }
          this.spinner.hide()
        })
      }
    })
  }

  onSkipNextPayment() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du die nächste Zahlung wirklich aussetzen?<br>Die nächste Zahlung wird dann erst zum übernächsten Zahlungstermin fällig.', title: 'Nächste Zahlung aussetzen' },
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        this.spinner.show()
        var res = await this.userService.skipNextPaymentDate(this.productPurchase)
        this.spinner.hide()
        this.closeDialog()
      }
    });
  }

  isPaymentMethodAvailable(method: string) {
    if (!this.productPurchase.availablePaymentMethods) return this.paymentService.paymentSettings?.availablePaymentMethods?.includes(method) ?? false
    return this.productPurchase.availablePaymentMethods.includes(method)
  }

  getPrintablePaymentMethods() {
    var result = ''
    var availablePaymentMethods = this.productPurchase.availablePaymentMethods
    if (!availablePaymentMethods) availablePaymentMethods = this.paymentService.paymentSettings?.availablePaymentMethods
    if (!availablePaymentMethods || availablePaymentMethods.length == 0) return null
    if (availablePaymentMethods.includes('sepa_debit')) result += 'Sepa-Lastschrift, '
    if (availablePaymentMethods.includes('bank_transfer')) result += 'Banküberweisung, '
    if (availablePaymentMethods.includes('card')) result += 'Kreditkarte, '
    return result.substring(0, result.length - 2)
  }

  async onPaymentMethodSelected(method: string) {
    if (!this.productPurchase.availablePaymentMethods) this.productPurchase.availablePaymentMethods = this.paymentService.paymentSettings?.availablePaymentMethods
    if (this.productPurchase.availablePaymentMethods.includes(method)) {
      this.productPurchase.availablePaymentMethods = this.productPurchase.availablePaymentMethods.filter(x => x != method)
    } else {
      this.productPurchase.availablePaymentMethods.push(method)
    }
    await this.userService.updateProductPurchaseAvailablePaymentMethods(this.productPurchase)
  }

  onRequestPaymentMethodChange() {
    var template = new EmailTemplate()
    template.subject = 'Zahlungsmethode anpassen'
    template.heading = 'Zahlungsmethode anpassen'
    template.body = 'Hallo {{Name}},<br><br>bitte passe über den Button unten deine hinterlegte Zahlungsmethode an.<br><br>Viel Spaß und viele Grüße!'  
    
    const dialogRef = this.dialog.open(EmailTemplateDialogComponent, { data: { template: template }, width: '1000px'})
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.template) {
          this.spinner.show()
          let firebaseUserEmail = await this.userService.getEmailForUser(this.productPurchase.customerUid)
          var paymentSettings = this.paymentService.paymentSettings
          let dynamicLink = environment.baseUrl + '/checkout/' + this.productPurchase.id
          let subject = template?.subject?.replace('{{Name}}', this.productPurchase.customer.getName() ?? '')
          let heading = template?.heading?.replace('{{Name}}', this.productPurchase.customer.getName() ?? '')
          let body = template?.body?.replace('{{Name}}', this.productPurchase.customer.getName() ?? '')
          firstValueFrom(this.userService.sendInvitationEmail(firebaseUserEmail, this.productPurchase.customer.getName(), heading, body, dynamicLink, 'Zahlungsmethode anpassen', subject, null, paymentSettings?.email, this.userService.getLoggedInUser().licenceHolder?.uid)).then(res => {
            this.spinner.hide()
            if (res?.result == 'success') {
              this.toastr.success("Email wurde versendet.", "",  {
                positionClass: 'toast-bottom-center'
              });
            } else {
              this.toastr.error("Beim Versenden der Email ist ein Fehler aufgetreten.", "",  {
                positionClass: 'toast-bottom-center'
              });
            }
          }, error => {
            console.log(error)
          })
        }
      }
    });
  }

  onCancelSubscription() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du das Abonnement wirklich kündigen?<br>Nach Ablauf der Laufzeit werden keine weiteren Rechnungen gestellt und die Lizenz wird deaktiviert.', title: 'Abonnement kündigen' },
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        this.spinner.show()
        await this.userService.cancelProductPurchase(this.productPurchase)
        this.spinner.hide()
      }
    });
  }

  onCloseDialog() {
    this.closeDialog()
  }

  closeDialog() {
    this.dialogRef.close(null);
  }
}
