import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { urlToHttpOptions } from 'url';

@Component({
  selector: 'app-time-input-control',
  templateUrl: './time-input-control.component.html',
  styleUrls: ['./time-input-control.component.css']
})
export class TimeInputControlComponent implements OnInit {

  public minuteMode: boolean = false;
  public inputMask: string = "Hh:m0:s0";
  public placeholder: string = "HH:mm:ss";

  @Input() set fullSeconds(value: number) {
    if(value == null) {
      value = 0
    }

    if(this.timeFullSeconds != value){
      this.timeFullSeconds = value
      this.date = new Date(0,0,0,0,0,value,0)?.toTimeString()?.slice(0,8);
    }
  }

  @Input() set fullMinutes(value: number) {
    this.minuteMode = true;
    this.inputMask = "Hh:m0";
    this.placeholder = "HH:mm";
    if(value == null) {
      value = 0
    }

    this.fullSeconds = value * 60
    // if(this.timeFullSeconds != value){
    //   this.timeFullSeconds = value
    //   this.date = new Date(0,0,0,0,0,value,0)?.toTimeString()?.slice(0,8);
    // }
  }

  @Output() fullSecondsChange = new EventEmitter<number>()
  @Output() fullMinutesChange = new EventEmitter<number>()
  
  @Input() backgroundColor: string = "white";
  @Input() readOnlyMode: boolean = false;
  
  constructor() { }

  ngOnInit(): void {
  }

  public date: string;

  public inputPattern:string = '^[0-9:]+$';
  public inputChanged(value: string) {
    this.calcFullSeconds(value)
  }

  private timeFullSeconds: number;


  calcFullSeconds(value: string){
    console.log(value)
    const splitted = value.split(':')
    this.timeFullSeconds = 0
    if(this.minuteMode && splitted.length === 2) {
      this.timeFullSeconds += parseInt(splitted[0]) * 60 * 60
      this.timeFullSeconds += parseInt(splitted[1]) * 60
      this.fullSecondsChange.emit(this.timeFullSeconds)
      this.fullMinutesChange.emit(Math.round(this.timeFullSeconds / 60))
    }
    else if(splitted.length === 3) {
      this.timeFullSeconds += parseInt(splitted[0]) * 60 * 60
      this.timeFullSeconds += parseInt(splitted[1]) * 60
      this.timeFullSeconds += parseInt(splitted[2])
      this.fullSecondsChange.emit(this.timeFullSeconds)
      this.fullMinutesChange.emit(Math.round(this.timeFullSeconds / 60))
    }
    // this.date = new Date(0,0,0,0,0,this.timeFullSeconds,0)?.toTimeString()?.slice(0,8);  
  }

}
