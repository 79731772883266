import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription, combineLatest, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { Chat } from '../model/chat.model';
import { Chatmessage } from '../model/chatmessage.model';
import { FirestoreService } from './firestore.service';
import { UtilityService } from './utility.service';
import { HostListener } from '@angular/core';   
import { Router } from '@angular/router';
import { Coach } from '../model/coach.model';

@Injectable({
    providedIn: 'root'
})
export class ChatService {
  
  private chats: Chat[] = null;
  private uid: string;
  private unreadMessages = [];

  private openChats: Chat[] = []
  private subscriptionMessages: Map<string, Subscription> = new Map<string, Subscription>();
  private subscriptionChats: Subscription

  private loaded: boolean = false;
  private newMessage = [];

  public playingMessageId: string = ""

  constructor(private userService: FirestoreService, private authService: AuthService, private toastr: ToastrService, private utilityService: UtilityService, private router: Router) {
      this.loaded = false;
      utilityService.currentEvent.subscribe(event => {
          if (event == 'WINDOW_RESIZE') {
              while (utilityService.onMobile() && this.openChats?.length > this.getMaxNumberOfOpenChats()) this.openChats.splice(0, 1)
          }
      })
      if(utilityService.onNativeMobileApp()){
          router.events.subscribe((val) => {
              this.closeAllChats();
            });
      }
      this.userService.observableUser.subscribe(async x => {
        this.loadChats()
        if (x && x.coach?.licenceHolderUid && !this.coachesOfLicenceHolder) {
            var coaches = await firstValueFrom(this.userService.getAllCoachesByLicenceHolderUid(x.coach?.licenceHolderUid))
            this.coachesOfLicenceHolder = coaches
        }
      })

  }

  fetchChats(){
      if (!this.loaded) this.loadChats()
  }

  delay(ms: number) {
      return new Promise( resolve => setTimeout(resolve, ms) );
  }

  getMaxNumberOfOpenChats() {
      if (this.utilityService.onMobile()) {
          return 1
      } else {
          return 3
      }
  }

  private loadingChats = false;
  private chatSubscription: Subscription = null;

  async loadChats() {
      this.unreadMessages = [];
      this.chats = [];
      this.uid = this.authService.user.uid;
      if (!this.userService.getLoggedInUser().coach?.licenceHolderUid) return
      if(this.chatSubscription != null) return;

    // this.userService.getObservableClients().subscribe(clients => {
    //     console.log("loadChats clients: " + clients.length);
    // });
    // this.userService.getChatsByLicenceHolder(this.userService.getLoggedInUser().coach?.licenceHolderUid, this.userService.getLoggedInUser()?.coach?.uid).subscribe(chats => {
    //     console.log("loadChats chats: " + chats.length);
    // });


    this.chatSubscription = combineLatest(
        this.userService.getObservableClients(),
        this.userService.getChatsByLicenceHolder(this.userService.getLoggedInUser().coach?.licenceHolderUid, this.userService.getLoggedInUser()?.coach?.uid)
      ).pipe(
        map(async ([clients, chats]) => {
            if (this.loadingChats) return;
            this.loadingChats = true;
            for (var client of clients.changed) {
                var clientChats: Chat[] = []
                var chat: Chat = null
                var lastMessageDate: Date = null

                if(!client.licence?.active){
                    if(this.chats.find(c => c.chatPartner?.uid == client.uid)){
                        this.chats = this.chats.filter(c => c.chatPartner?.uid != client.uid);
                    }
                    continue;
                }

                for (var c of chats) {
                    if (c.participantsUids.includes(this.userService.getLoggedInUser().coach?.licenceHolderUid) || c.participantsUids.includes(this.userService.getLoggedInUser()?.coach?.uid)) {
                        var coachUid = (c.participantsUids[0] == this.userService.getLoggedInUser().coach?.licenceHolderUid || c.participantsUids[0] == this.userService.getLoggedInUser()?.coach?.uid) ? c.participantsUids[0] : c.participantsUids[1]
                        if (client.uid != coachUid && client.uid != this.userService.getLoggedInUser().uid) {
                            if (c.participantsUids.includes(client.uid) && c.participantsUids.includes(coachUid)) {
                                clientChats.push(c)
                            }
                        } else {
                            if (c.participantsUids.length == 2 && c.participantsUids[0] == client.uid && c.participantsUids[1] == client.uid) {
                                clientChats.push(c)
                            }
                        }
                    }
                }

                if (clientChats.length == 0) {
                    var tmpChats = await this.userService.getChatForUser(client.uid)
                    if (tmpChats.length > 0) {
                        for (var c of tmpChats) {
                            var coachUid = (c.participantsUids[0] == client.uid) ? c.participantsUids[1] : c.participantsUids[0]
                            if (coachUid == this.userService.getLoggedInUser().uid) {
                                chat = c
                            } else {
                                var licenceHolderUid = await this.userService.getLicenceHolderIdOfCoach(coachUid)
                                if (licenceHolderUid && licenceHolderUid == this.userService.getLoggedInUser().coach?.licenceHolderUid) {
                                    chat = c
                                }
                            }
                        }
                    }
                } else if (clientChats.length == 1) {
                    chat = clientChats[0]
                } else {
                    for (var c of clientChats) {
                        if(this.subscriptionMessages.has(c.chatId)){
                            continue;
                        }
                        var message = await this.userService.getLatestChatMessage(c.chatId)
                        if (!chat) {
                            chat = c
                            if (message) lastMessageDate = message.time
                        } else {
                            if (!lastMessageDate) {
                                chat = c
                                if (message) lastMessageDate = message.time
                            } else {
                                if (message && message.time > lastMessageDate) {
                                    chat = c
                                    if (message) lastMessageDate = message.time
                                }
                            }
                        }
                    }
                }

                if (chat) {
                    chat.lastMessageTimeAsNumber = 0

                    if (chat.participantsUids.length == 2 && chat.chatId) {
                        var alreadyLoaded = false
                        alreadyLoaded = this.chats.find(c => c.chatId == chat.chatId) != null;
                        if (!alreadyLoaded && this.userService.getLoggedInUser().coach?.canAccessUser(client)) {
                            chat.chatPartner = client
                            let tempChat = new Chat(chat)
                            this.chats.push(tempChat);
                            this.loadMessages(tempChat);
                        }
                    }
                }
            }
            this.loadingChats = false;
            return chats
        })
      ).subscribe(result => {})
      this.loaded = true;
  }

  loadMessages(chat: Chat) {
      if (this.subscriptionMessages.get(chat.chatId)) {
          this.subscriptionMessages.get(chat.chatId).unsubscribe()
          this.subscriptionMessages.delete(chat.chatId)
      }
      var subscriptionMessages = this.userService.observeChatMessages(chat.chatId, chat.chatPartner, 5).subscribe(messages => {
        // chat.messages = messages.reverse()
        messages.forEach(message => {
            message.chatId = chat.chatId
            message.time = new Date((message as any).time.seconds * 1000)
            message.isFromPartner = chat.chatPartner.uid == message.uid
        })
        this.userService.loadChatMessageContents(messages, chat.chatPartner)

        if (!chat.messages || chat.messages.length == 0) {
            chat.messages = messages
          } else {
            for (let i = messages.length - 1; i >= 0; i--) {
              let message = messages[i]
              let found = chat.messages.find(x => x.messageId == message.messageId)
              if (!found) {
                chat.messages.unshift(message)
              } else {
                var index = chat.messages.indexOf(found)
                chat.messages.splice(index, 1, message)
              }
            }
          }
        chat.unreadMessages = []
        chat.messages.forEach(message => {
            if (!message.read && message.uid == chat.chatPartner.uid && chat.chatPartner.uid != this.uid) chat.unreadMessages.push(message)
            if (message.time.getTime() > chat.lastMessageTimeAsNumber) chat.lastMessageTimeAsNumber = message.time.getTime()
        })
        this.sortChats()
      });
      this.subscriptionMessages.set(chat.chatId, subscriptionMessages)
  }

  sortChats() {
    this.chats.sort((a,b) => {
        if (a.unreadMessages?.length > 0 && b.unreadMessages?.length > 0) {
            return b.lastMessageTimeAsNumber - a.lastMessageTimeAsNumber
        }
        if (a.unreadMessages?.length > 0) return -1
        if (b.unreadMessages?.length > 0) return 1
        return b.lastMessageTimeAsNumber - a.lastMessageTimeAsNumber
    });
  }

  onOpenChatForUid(uid: string) {
    var chat = this.getChatForUid(uid)
    if (chat) this.openChat(chat)
  }

  getChatForUid(uid: string) {
    return this.chats.find(c => c.chatPartner?.uid == uid)
  }

  hasUnreadMessages(filteredCoachUids: string[], filteredGroupNames: string[]) {
    var unread = false
    this.getFilteredChats(null, filteredCoachUids, filteredGroupNames)?.forEach(chat =>{
        if (chat.unreadMessages?.length > 0) unread = true
    });
    
    return unread
  }
  
  openChat(chat: Chat) {
    var alreadyOpen = false
    this.openChats.forEach(c =>{
        if (chat == c) alreadyOpen = true
    });
    if (!alreadyOpen) {
        if (this.openChats?.length == this.getMaxNumberOfOpenChats()) this.openChats?.splice(0, 1)
        this.userService.loadChatMessageContents(chat.messages, chat.chatPartner)
        this.openChats.push(chat)
        chat.isOpen = true
        this.userService.markMessagesAsRead(chat)
    }
  }

  onOpenChatById(chatId: string) {
    this.chats.forEach(c =>{
        if (c.chatId == chatId) {
            this.openChat(c)
            return;
        }
    });
  }

  onCloseChat(chat: Chat) {
    this.openChats.forEach( (item, index) => {
        if (item == chat) this.openChats.splice(index, 1);
    });
    chat?.messages?.forEach(message => {
        message?.audioPlayerRef?.pause()
    });
    chat.tempIsFolded = false
    chat.isOpen = false
  }

  closeAllChats(){
    this.openChats.forEach( (item) => {
        item.isOpen = false;
    });
    this.openChats = []
  }

  getUnreadChats() {
    var chats = []
    this.chats?.forEach(chat =>{
        if (chat.unreadMessages?.length > 0) {
            chats.push(chat)
        }
    });
    return chats
  }

  getChats() {
      return this.chats;
  }
  getOpenChats() {
      return this.openChats;
  }

  
  getFilteredChats(clientSearchInput: string, filteredCoachUids: string[], filteredGroupNames: string[]):Chat[]{
    let chats = this.getChats().filter(c => filteredGroupNames.includes("Alle") || this.isChatInCoachFilter(c, filteredCoachUids) || this.isChatInGroupFilter(c, filteredGroupNames));
    if (!clientSearchInput || clientSearchInput.length == 0) {
      return chats;
    } else {
      var filteredChats = []
      chats.forEach(chat => {
        if (chat.chatPartner.getName().toLowerCase().includes(clientSearchInput.toLowerCase())) {
          filteredChats.push(chat)
        }
      })
      return filteredChats
    }
  }

  isChatInCoachFilter(chat: Chat, filteredCoachUids: string[]) {
    // if(filteredCoachUids.length == 0) return true;
    if(filteredCoachUids.includes(chat.chatPartner.coachUid)) return true;
  }
  
  isChatInGroupFilter(chat: Chat, filteredGroupNames: string[]) {
    // if(filteredGroupNames.length == 0) return true;
    return chat.chatPartner.metadataUser?.assignedClientGroups?.find(x => filteredGroupNames.includes(x));
  }



  logout() {
      this.chats = [];
      this.loaded = false;
      if (this.subscriptionChats) this.subscriptionChats.unsubscribe()
      this.subscriptionMessages.forEach(element => {
          element.unsubscribe()
      });
      this.subscriptionMessages = new Map<string, Subscription>();
  }

  getNewMessageBoolean() {
      var ret = false;
      this.newMessage.forEach(b => {
          if (b) {
              ret = true;
          }
      });
      return ret;
  }

  getNewMessageForSpecificChat(chat: Chat) {
      var ret = false;
      this.unreadMessages.forEach(m => {
          if (m.chatId == chat.chatId && m.message != "--nothing--" && m.message != undefined) {
              ret = true;
          }
      });
      return ret;
  }

  showToastMessage(message: string) {
    this.toastr.error(message, "",  {
        positionClass: 'toast-bottom-center'
      });
  }

  markLastMessageAsUnread(chat:Chat){
      if(chat?.messages[0]){
          this.userService.markLastMessageAsUnread(chat)
      }
  }

  markMessageAsUnread(message:Chatmessage){
    this.userService.markMessageAsUnread(message.chatId, message.messageId);
  }

  public coachesOfLicenceHolder: Coach[]
  private senderDetailsPromisesMap = new Map<string, Promise<any>>()

  getSenderDetailsForMessage(message: Chatmessage) {
    if (!message) return null
    if (message.senderDetails) return message.senderDetails
    if (this.senderDetailsPromisesMap.has(message.uid)) {
        return this.senderDetailsPromisesMap.get(message.uid).then((details) => {
            message.senderDetails = details
            return details
        })
    }
    var promise: Promise<any> = new Promise(async (resolve, reject) => {
        var imageUrl = await firstValueFrom(this.userService.fireStorage.ref("users/" + message.uid + "/profile_picture.jpg").getDownloadURL()).catch(e => {})
        var name = this.coachesOfLicenceHolder?.find(c => c.uid == message.uid)?.name
        message.senderDetails = {profileImageUrl: imageUrl, name: name }
        resolve(message.senderDetails)
    });
    this.senderDetailsPromisesMap.set(message.uid, promise)
    return promise;
  }
}