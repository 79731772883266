<div class="dialog-topbar">
    <h3>Affiliate-Link</h3>
</div>

<div class="dialog-content-wrapper">
    <div class="bold">Dein Affiliate-Link: </div>
    <div class="spacer-10"></div>
    {{link}}<br>
    <div class="spacer-10"></div>
    <button class="icon-button marginright-25" ngxClipboard [cbContent]="link" (click)="onLinkCopied()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-files padding-4" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z"/>
            <path d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z"/>
        </svg>
        Kopieren
    </button>
    <a class="icon-button marginright-25" href="{{link}}" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-arrow-up-right padding-4" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
        </svg>
        Öffnen
    </a>
</div>

<mat-dialog-actions>
    <div class="actions">
        <button class="button-filled small light float-right" (click)="onClose()">Schließen</button>
    </div>
</mat-dialog-actions>