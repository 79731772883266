// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  isWhitelabel: true,
  brandName: 'RECOMAT',
  appName: 'Coaching Zone',
  redeemLinkPrefix: 'https://recomat.page.link/?link=https://recomat.page.link/redeem?lid%3D',
  androidBundleId: 'de.recomat.app',
  iOSBundleId: 'de.recomat.app',
  iOSAppStoreNumber: '6503047602',
  firebaseProjectId: 'recomat-app',
  assetFolderPath: 'assets/recomat',
  loginImagePath: 'assets/recomat/app-icon.png',
  baseUrl: 'https://recomat-app.web.app',
  inAppLink: 'https://recomat.page.link/',
  termsAndConditionsUrl: 'https://www.recomat.de/',
  MEAL_DUMMY_IMAGE_UNDEFINED_URL: "https://firebasestorage.googleapis.com/v0/b/aesthetics-advisor.appspot.com/o/common%2Fmeal_dummy_images%2Fmeal_image_snack.jpeg?alt=media&token=6fb15b8b-6875-4fb0-8efc-389e36ad9b4e",
  MEAL_DUMMY_IMAGE_BREAKFAST_URL: "https://firebasestorage.googleapis.com/v0/b/aesthetics-advisor.appspot.com/o/common%2Fmeal_dummy_images%2Fmeal_image_breakfast.jpeg?alt=media&token=dacf04fe-a2a8-4496-b542-994e886f3656",
  MEAL_DUMMY_IMAGE_LUNCH_URL: "https://firebasestorage.googleapis.com/v0/b/aesthetics-advisor.appspot.com/o/common%2Fmeal_dummy_images%2Fmeal_image_lunch.jpeg?alt=media&token=d06ff3ce-b934-425a-8969-5a2def8d286f",
  MEAL_DUMMY_IMAGE_DINNER_URL: "https://firebasestorage.googleapis.com/v0/b/aesthetics-advisor.appspot.com/o/common%2Fmeal_dummy_images%2Fmeal_image_dinner.jpeg?alt=media&token=693a7f3c-da6a-4b2b-9875-2cb9a5a3192d",
  MEAL_DUMMY_IMAGE_SNACK_URL: "https://firebasestorage.googleapis.com/v0/b/aesthetics-advisor.appspot.com/o/common%2Fmeal_dummy_images%2Fmeal_image_snack.jpeg?alt=media&token=6fb15b8b-6875-4fb0-8efc-389e36ad9b4e",
  colorCalorieGraph: '#FF5169',
  colorCarbohydratesGraph: '#9E31FF',
  colorProteinGraph: '#5CEFFF',
  colorFatGraph: '#B7FD78',
  colorOtherNutrientsGraph: '#B7FD78',
  
  firebaseProjectCommon: {
    apiKey: "AIzaSyCQvv6TtQ84CLuzgvgwiYtKXAu0-lleGwI",
    authDomain: "nutrilize-b4788.firebaseapp.com",
    projectId: "nutrilize-b4788",
    storageBucket: "nutrilize-b4788.appspot.com",
    messagingSenderId: "829290663374",
    appId: "1:829290663374:web:bb3ebd492d53a8d675ef9f",
    measurementId: "G-M6XC70DSPL"
  },
  firebaseProjectIndividual: {
    apiKey: "AIzaSyD7thhRFr-6mqaZ9PXp73TMKp9BIvsz9cc",
    authDomain: "recomat-app.firebaseapp.com",
    projectId: "recomat-app",
    storageBucket: "recomat-app.appspot.com",
    messagingSenderId: "908437481923",
    appId: "1:908437481923:web:6006ad9f24cdfeaebb3576",
  },
  overwrite(environment: any) {
    this.production = environment.production
    this.isWhitelabel = environment.isWhitelabel
    this.brandName = environment.brandName
    this.appName = environment.appName
    this.redeemLinkPrefix = environment.redeemLinkPrefix
    this.androidBundleId = environment.androidBundleId
    this.iOSBundleId = environment.iOSBundleId
    this.iOSAppStoreNumber = environment.iOSAppStoreNumber
    this.firebaseProjectId = environment.firebaseProjectId
    this.assetFolderPath = environment.assetFolderPath
    this.loginImagePath = environment.loginImagePath
    this.baseUrl = environment.baseUrl
    this.inAppLink = environment.inAppLink
    this.termsAndConditionsUrl = environment.termsAndConditionsUrl
    this.MEAL_DUMMY_IMAGE_UNDEFINED_URL = environment.MEAL_DUMMY_IMAGE_UNDEFINED_URL
    this.MEAL_DUMMY_IMAGE_BREAKFAST_URL = environment.MEAL_DUMMY_IMAGE_BREAKFAST_URL
    this.MEAL_DUMMY_IMAGE_LUNCH_URL = environment.MEAL_DUMMY_IMAGE_LUNCH_URL
    this.MEAL_DUMMY_IMAGE_DINNER_URL = environment.MEAL_DUMMY_IMAGE_DINNER_URL
    this.MEAL_DUMMY_IMAGE_SNACK_URL = environment.MEAL_DUMMY_IMAGE_SNACK_URL
    this.firebaseProjectCommon = environment.firebaseProjectCommon
    this.firebaseProjectIndividual = environment.firebaseProjectIndividual
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
