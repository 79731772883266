import { PlannedFood } from './plannedfood.model';
import { NutritionalValueHolder } from './basenutritionfact.model';

export class PlannedMeal implements NutritionalValueHolder {
    
    id: string;
    number: number;
    dayNumber: number;
    name: string;
    foods: PlannedFood[];
    comment: string;
    instructions: string;
    note: string;
    mealTemplateId: string;
    imageURL: string;
    handled: boolean;
    trackedMealId: number;
    replacedMealId: number;
    thumbnailPath: string

    constructor();
    constructor(init: PlannedMeal);
    constructor(init?: PlannedMeal) {
        this.id = init && init.id || null
        this.number = init && init.number || null
        this.dayNumber = init && init.dayNumber || null
        this.foods = init && init.foods || []
        this.name = init && init.name || null
        this.comment = init && init.comment || null
        this.instructions = init && init.instructions || null
        this.note = init && init.note || null
        this.mealTemplateId = init && init.mealTemplateId || null
        this.imageURL = init && init.imageURL || null
        this.handled = init && init.handled || false
        this.trackedMealId = init && init.trackedMealId || null
        this.replacedMealId = init && init.replacedMealId || null
    }
    
    getNutritionalValue(nutritionalValue: string): number {
        var value = 0
        this.foods.forEach(f => {
            value += f.getNutritionalValue(nutritionalValue) || 0
        })
        return value
    }
    getCarbohydrates() {
        var value = 0
        this.foods.forEach(f => {
            value += f.getCarbohydrates()
        })
        return value
    }
    getProtein() {
        var value = 0
        this.foods.forEach(f => {
            value += f.getProtein()
        })
        return value
    }
    getFat() {
        var value = 0
        this.foods.forEach(f => {
            value += f.getFat()
        })
        return value
    }
    getCalories() {
        var value = 0
        this.foods.forEach(f => {
            value += f.getCalories()
        })
        return value
    }
    
    clone() {
        var meal = new PlannedMeal()
        meal.id = this.id
        meal.number = this.number
        meal.dayNumber = this.dayNumber
        meal.comment = this.comment
        meal.instructions = this.instructions
        meal.note = this.note
        meal.name = this.name
        meal.foods = []
        meal.mealTemplateId = this.mealTemplateId
        meal.imageURL = this.imageURL
        this.foods.forEach(f => {
            meal.foods.push(f.clone())
        })
        return meal
    }
}
