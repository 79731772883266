import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LanguageDictionary } from 'src/app/model/languagedictionary.model';
import { SubTask, Task } from 'src/app/model/task.model';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-task-element',
  templateUrl: './task-element.component.html',
  styleUrls: ['./task-element.component.css'],
  animations: [
    trigger('fadeSlideGrowKeyframe', [
      transition(':enter', [
          style({ opacity: 0, transform: 'scale(0.5) translateY(50px)' }),
          animate(
              '500ms',
              keyframes([
                  style({ opacity: 1, offset: 0.3 }),
                  style({ transform: 'translateY(0)', offset: 0.6 }),
                  style({ transform: 'scale(1)', offset: 1 }),
              ])
          ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1) translateY(0px)' }),
        animate(
            '500ms',
            keyframes([
                style({ opacity: 0, offset: 0.3 }),
                style({ transform: 'translateY(50px)', offset: 0.6 }),
                style({ transform: 'scale(0)', offset: 1 }),
            ])
        ),
    ])
    ]),
  ]
})
export class TaskElementComponent {

  constructor(private userService: FirestoreService) { }
 
  @Input() public selectedTask: Task;
  @Input() public selectedSubTask: SubTask;
  @Input() public task: Task;
  @Input() public disableEdit: boolean = false;

  @Output() public onCompleteTask: EventEmitter<Task> = new EventEmitter<Task>();
  @Output() public onUnCompleteTask: EventEmitter<Task> = new EventEmitter<Task>();
  @Output() public onDeleteTask: EventEmitter<Task> = new EventEmitter<Task>();
  @Output() public onDuplicateTask: EventEmitter<Task> = new EventEmitter<Task>();
  @Output() public onEditTask: EventEmitter<Task> = new EventEmitter<Task>();
  @Output() public onAddSubTask: EventEmitter<Task> = new EventEmitter<Task>();
  @Output() public onSaveSubTasks: EventEmitter<Task> = new EventEmitter<Task>();
  @Output() public onSaveAndAddSubTasks: EventEmitter<SubTask> = new EventEmitter<SubTask>();
  @Output() public onRemoveSubTask: EventEmitter<SubTask> = new EventEmitter<SubTask>();
  @Output() public onCompleteSubTask: EventEmitter<SubTask> = new EventEmitter<SubTask>();
  @Output() public onUnCompleteSubTask: EventEmitter<SubTask> = new EventEmitter<SubTask>();

  private hasChanges: boolean = false;
  enteredChanges(){
    this.hasChanges = true;
  }

  saveIfHasChanges(){
    if(this.hasChanges){
      this.onSaveSubTasks.emit(this.task);
      this.hasChanges = false;
    }
  }
  
  getPrintableConnectedClientName(task: Task): string {
    if(!task.connectedClientUid) return '-';
    return this.userService.getAccessibleClients()?.find(client => client.uid == task.connectedClientUid)?.getName();
  }

  getPrintableTags(items: LanguageDictionary<any>[], maxItems: number):string{
    var returnString: string = "";
    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      if(index > maxItems-1) return returnString + ", ..."
      if(index > 0) returnString = returnString.concat(", ");
      returnString = returnString.concat(element?.originObject?.toString());
    }
    return returnString;
  }
}
