import { Component, HostListener, Input, NgZone } from '@angular/core';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { CardioZoneGroup } from 'src/app/model/cardio-zone-group.model';
import { User } from 'src/app/model/user.model';
import { TrainingVariableEditorDialogComponent, TrainingVariableEditorDialogType } from '../training-variable-editor-dialog/training-variable-editor-dialog.component';
import { FirestoreService } from 'src/app/services/firestore.service';
import { TrainingVariable } from 'src/app/model/training-variables.model';
import { TrainingService } from 'src/app/services/training.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { TrainingPlanEditorComponent } from '../training-plan-editor/training-plan-editor.component';
import { LanguageService } from 'src/app/services/language.service';
import { PlannedTrainingExercise, TrainingPlan, TrainingSession } from 'src/app/model/training-plan.model';
import { TrackedTrainingSession } from 'src/app/model/training-monitoring.model';
import { AuthService } from 'src/app/auth/auth.service';
import { UtilityService } from 'src/app/services/utility.service';
import { TrainingplanExportService } from 'src/app/services/trainingplan-export.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseTrainingEditor } from '../training-plan-editor/base-training-plan-editor';
import { NotificationService } from 'src/app/services/notification.service';
import { LanguageDictionary } from 'src/app/model/languagedictionary.model';
import { CreatePeriodicPlanDialogComponent } from 'src/app/dialogs/create-periodic-plan-dialog/create-periodic-plan-dialog.component';

@Component({
  selector: 'app-training-plan-editor-inline',
  templateUrl: './training-plan-editor-inline.component.html',
  styleUrls: ['./training-plan-editor-inline.component.css']
})
export class TrainingPlanEditorInlineComponent extends BaseTrainingEditor {

  constructor(public trainingService: TrainingService, public toastr: ToastrService, public dialog: MatDialog, public authService: AuthService, public utilityService: UtilityService, public languageService: LanguageService, public ngZone: NgZone, public trainingPlanExportService: TrainingplanExportService, public spinner: NgxSpinnerService, public userService: FirestoreService, public notificationService: NotificationService) {
    super(trainingService, toastr, dialog, authService, utilityService, languageService, ngZone, trainingPlanExportService, spinner, userService, notificationService, null)
  }

  async ngOnInit(): Promise<void> {

    //this.init()
    
  }

  showWeekNote: boolean = false

  async init() {
    if (!this.trainingPlan) this.trainingPlan = this.getMainTrainingPlan()?.clone()
    if (!this.selectedSession) this.selectedSession = this.trainingPlan?.sessions[0]
    if (!this.trainingPlan.id) {
      // this.setAvailableTrainingVariables();
      if(this.trainingPlan.isPeriodicPlan){
        this.trainingPlan.initWeeks();
        let firstWeek = this.trainingPlan.weeks[0];
        let firstDay = this.getWeekDays(firstWeek.id)[0];

        this.addSessionToDay(firstDay, this.trainingPlan.weeks[0].id);
      }
      else {
        this.addSession(false, false);
      }
    }

    if(this.trainingPlan.isPeriodicPlan && this.trainingPlan.weeks.length == 0){
      this.trainingPlan.initWeeks();
    }
    else if(this.trainingPlan.isTemplate && !this.trainingPlan.startDate){
      this.trainingPlan.startDate = new Date(0);
    }

    if(this.trainingPlan.id && !this.trainingPlan.isTemplate && this.user){
      if(this.trainingPlan.isPeriodicPlan){
        await this.setTrackedSessions();
      }
      else {
        this.loadTrackedTrainingSessions();
      }
    }

    this.setWeekDays();
  }


  @Input() set User(value: User) {
    this.user = value;
    if (!this.trainingPlan) {
      if (this.getMainTrainingPlan()) {
        this.init()
      } else {
        this.user.observableTrainingPlansLoader.subscribe((value) => {
          if (value && !this.trainingPlan) {
            this.init()
          }
        })
      }
      
    }
  }

  @Input() set SelectedCoach(value: User) {
    this.selectedCoach = value;
  }

  getMainTrainingPlan() {
    if(this.trainingPlan) return this.trainingPlan;
    return this.user?.getMainTrainingPlan()
  }

  @HostListener('window:keydown', ['$event'])
  async onKeyDown(event: KeyboardEvent) {
      if ((event.metaKey || event.ctrlKey) && event.key === 's') {
          event.preventDefault();
          var result = await this.saveTrainingPlan()
          if (result) {
            this.toastr.success('Gespeichert', null, {
                positionClass: 'toast-bottom-center'
            })
          }
      }
  }

  showVariableNotAvailableDialog(){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Die Variable existiert nicht. Möchtest du eine neue Variable anlegen?', title: 'Variable anlegen', positiveButton: 'Ja', negativeButton: 'Abbrechen' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.openTrainingVariablesDialog();
      }
    })
  }

  async onCreateTrainingPlan() {
    var numberOfPlans = this.user.trainingPlans.length
    var minDate = null
    this.user.trainingPlans?.forEach((plan) => {
      if (plan.endDate && (!minDate || plan.endDate > minDate)) {
        minDate = plan.endDate
      }
    })
    if (!minDate) {
      minDate = new Date().getStartOfWeek()
    } else {
      minDate = minDate.getStartOfWeek().addDays(1)
    }

    const dialogRef = this.dialog.open(CreatePeriodicPlanDialogComponent, {
      data: { name: 'Zyklus ' + (numberOfPlans + 1), startDate: new Date(), minDate: minDate },
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result?.startDate) {
        var template = result.template
        this.trainingPlan = new TrainingPlan();
        if (template) {
          await this.trainingService.getTrainingPlanTemplatePlannedExercises(template);
          this.trainingPlan = await this.trainingPlanEditorHelper.clonePlan(template, this.user, this.selectedCoach, result.startDate)
          if (this.trainingPlan == null) return
          this.trainingPlan.isTemplate = false
        } else {
          this.trainingPlan.startDate = result.startDate
          this.trainingPlan.startDate.setHours(0)
          this.trainingPlan.startDate.setMinutes(0)
          this.trainingPlan.startDate.setSeconds(0)
          this.trainingPlan.startDate.setMilliseconds(0)
          this.trainingPlan.endDate = null
          this.trainingPlan.isPeriodicPlan = true
        }
        this.trainingPlan.name = result.name
        this.trainingPlan.nameTranslation = new LanguageDictionary<string>()
        this.trainingPlan.nameTranslation.de = result.name
        await this.saveTrainingPlan()
        if (this.user.trainingPlans.length > 0) {
          var endDate = this.trainingPlan.startDate.clone().addDays(-1)
          for (var plan of this.user.trainingPlans) {
            if (plan.id != this.trainingPlan.id && (!endDate || plan.endDate.isSameOrAfterDate(endDate))) {
              plan.endDate = endDate
              await this.userService.updateTrainingPlanEndDate(this.user, plan)
            }
          }
        }
        this.selectedSession = null
        this.init()
      }
    })
  }

  canDeleteTrainingPlan(trainingPlan: TrainingPlan) {
    if (trainingPlan.id == this.user.getMainTrainingPlan().id) return true
    return false
  }

  onDeleteTrainingPlan() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchstest du den gesamten Plan und alle darin befindlichen Wochen und Einheiten wirklich löschen?<br>Wir empfehlen dir, nur versehentlich erstellte und ungenutzte Pläne zu löschen.', title: 'Trainingsplan/Zyklus löschen', positiveButton: 'Ja', negativeButton: 'Abbrechen' },
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        this.deleteTrainingPlan()
      }
    })
  }

  async deleteTrainingPlan(){
    this.spinner.show()
    if (this.trainingPlan.id?.length > 0) {
      this.trainingPlan.deleted = true
      await this.userService.updateTrainingPlan(this.user, this.selectedCoach, this.trainingPlan, true)
      this.user.trainingPlans = this.user.trainingPlans.filter((plan) => plan.id != this.trainingPlan.id)
      await this.userService.updateTrainingPlanEndDateInMetadata(this.user)
      this.trainingPlan = null
      this.selectedSession = null
    }
    this.spinner.hide();
    this.init()
  }
}
