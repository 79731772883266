import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from 'src/app/model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-payout-report-dialog',
  templateUrl: './payout-report-dialog.component.html',
  styleUrls: ['./payout-report-dialog.component.css']
})
export class PayoutReportDialogComponent {

  user: User
  loading: boolean = false

  constructor(public dialogRef: MatDialogRef<PayoutReportDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { }, private paymentService: PaymentService, private userService: FirestoreService) { 
    this.user = userService.getLoggedInUser()
  }


  onDragOverUploadField(event) {
    event.preventDefault();
  }
  
  onDropOnUploadField(event) {
    event.preventDefault();
    if (event.dataTransfer.files?.length > 0) {
      var file = event.dataTransfer.files[0]
      this.processFile(file)
    }
  }

  onPayoutFileUploaded(e) {
    if (!e.target.files[0].name.toLowerCase().endsWith('.csv')) {
      return
    }
    var file = e.target.files[0]
    this.processFile(file)
  }

  processFile(file: File) {

    this.loading = true
    let reader: FileReader = new FileReader();
    reader.readAsText(file);
    reader.onload = async (e) => {
      let csv: string = reader.result as string;
      let csvToRowArray = csv.split("\n");
      var header = csvToRowArray[0].split(",")
      var newHeader = header.map(x => {
        if (x == '"payment_intent_id"') return 'paymentIntentId'
        if (x == '"payment_metadata[paymentId]"') return 'paymentId'
        if (x == '"payment_metadata[productPurchaseId]"') return 'productPurchaseId'
        if (x == '"automatic_payout_effective_at"') return 'payoutDate'
        if (x == '"reporting_category"') return 'reportingCategory'
        return null
      })
      var valueArray = [];
      for (let index = 1; index < csvToRowArray.length; index++) {
        let row = csvToRowArray[index].split(",");
        var rowObject = {}
        for (let i = 0; i < header.length; i++) {
          if (newHeader[i] != null) {
            if (newHeader[i] == 'payoutDate') {
              var arr = row[i]?.replaceAll('"', '')?.split(/-|\s|:/)
              if (arr?.length > 2) {
                var date = new Date(parseInt(arr[0]), parseInt(arr[1]) - 1, parseInt(arr[2]), 0, 0, 0)
                rowObject[newHeader[i]] = date
              }
            } else {
              rowObject[newHeader[i]] = row[i]?.replaceAll('"', '')
            }
          }
        }
        valueArray.push(rowObject)
      }
      valueArray = valueArray.sort((a, b) => {
        if (a.payoutDate < b.payoutDate) return -1
        if (a.payoutDate > b.payoutDate) return 1
        return 0
      })
      this.paymentService.interruptPaymentObserver = true

      for (var element of valueArray) {
        if (element.payoutDate) {
          var payment = this.paymentService.payments.find(x => {
            if (element.paymentIntentId && x.stripePaymentIntentId) return x.stripePaymentIntentId == element.paymentIntentId
            if (element.paymentId && x.id) return x.id == element.paymentId
            return false
          })
          if (payment) {
            var hasChanges = false
            if (element.reportingCategory == 'charge') {
              if (!payment.payoutDate) hasChanges = true
              payment.payoutDate = element.payoutDate
            } else if (element.reportingCategory == 'charge_failure' || element.reportingCategory == 'dispute') {
              if (payment.payoutDate.isSameDate(element.payoutDate)) {
                payment.payoutDate = null
                hasChanges = true
              } else {
                payment.reversePayoutDate = element.payoutDate
                hasChanges = true
              }
            }
            if (hasChanges) await this.userService.updatePaymentPayoutData(payment)
            //await this.userService.updatePaymentPayoutData(payment)
          } else {
            console.log('Payment not found', element)
          }
        }
      }
      this.loading = false
      this.paymentService.interruptPaymentObserver = false

      this.onCloseDialog()
    }
  }

  onUploadPayoutReport() {
    document.getElementById('input-file').click()
  }

  onCloseDialog(){
    this.dialogRef.close()
  }
}
