import { UtilityService } from 'src/app/services/utility.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-monthly-calendar',
  templateUrl: './monthly-calendar.component.html',
  styleUrls: ['./monthly-calendar.component.css']
})
export class MonthlyCalendarComponent<T> implements OnInit {


  constructor(private utilityService: UtilityService) { }
  ngOnInit(): void {
    if(this.isCollapsible && this.utilityService.onMobile()){
      this.calendarCollapsed = true;
    }
  }

  public today: Date = new Date();


  public weeks: number[] = [];
  public singleCalendarDaysByWeekNumber: Map<number, SingleCalendarDay<T>[]> = new Map<number, SingleCalendarDay<T>[]>();
  public singleCalendarDays: SingleCalendarDay<T>[] = [];


  private _currentMonth : Date = null;
  public get currentMonth() : Date {
    return this._currentMonth;
  }
  public set currentMonth(v : Date) {
    this._currentMonth = v;
    this.currentMonthChanged.emit(v);
  }

  @Output() currentMonthChanged = new EventEmitter<Date>();

  public selectedCalendarDay: SingleCalendarDay<T> = null;

  public calendarCollapsed: boolean = false;

  private _endDate: Date;
  private _startDate: Date;

  @Input() draggingEnabled: boolean = false;

  @Input() set startDate(value: Date){
    this._startDate = value;
    this.updateCalendar();
  }
  get startDate(){
    return this._startDate;
  }

  @Input() set endDate(value: Date){
    this._endDate = value;
    this.updateCalendar();
  }
  get endDate(){
    return this._endDate;
  }

  @Output() dragOverCalendarDay = new EventEmitter<any>();
  @Output() selectedDayItemChanged = new EventEmitter<CalendarItem<T>>();
  
  @Output() selectedDayChanged = new EventEmitter<SingleCalendarDay<T>>();


  @Input() set selectedDay(value: Date) {
    if(value){
      let month = value.clone();
      month.setDate(1);
      month.setHours(0, 0, 0, 0);
      this.currentMonth = month;
    }
    this.updateCalendar();
    this.selectedCalendarDay = this.singleCalendarDays.find(day => day.date.isSameDate(value));
  }

  private _calendarItems: CalendarItem<T>[];
  @Input() set calendarItems(value: CalendarItem<T>[]) {
    this._calendarItems = value;
    this.updateCalendar();
  }

  @Output() droppedOnCalendarDay = new EventEmitter<Date>();

  public _isCollapsible: boolean = false;
  @Input() set isCollapsible(value: boolean){
    this._isCollapsible = value;
    if(!value){
      this.calendarCollapsed = false;
    }
  }
  get isCollapsible(){
    return this._isCollapsible;
  }
  


  @Input() showRelativeMonths: boolean = false;

  @Input() isBusy: boolean = false;

  getCalendarDayTooltip(day: SingleCalendarDay<T>){
    let tooltipParts = [];
    let tooltip: string = "";
    day?.calendarItems?.forEach(item => {
      let title = item.title ?? "";
      if(!tooltipParts.includes(title)) {
        tooltipParts.push(title);
      }
    });
    tooltip = tooltipParts.join("\n");
    return tooltip;
  }


  getDisplayedMonth() {
    let today = new Date();
    if(this.showRelativeMonths && this.currentMonth && this.startDate){
      let diff = this.currentMonth.getMonth() - this.startDate.getMonth() + (12 * (this.currentMonth.getFullYear() - this.startDate.getFullYear()));
      return (diff + 1) + ". Monat";
    }
    return this.currentMonth.getPrintableMonth() + (this.currentMonth.getFullYear() != today.getFullYear() ? (", " + this.currentMonth.getFullYear()) : '');
  }

  jumpMonthBackward(){
    if(!this.canJumpMonthBackward()) return;
    this.currentMonth.setMonth(this.currentMonth.getMonth() - 1);
    this.currentMonthChanged.emit(this.currentMonth);
    this.updateCalendar();
  }

  jumpMonthForward(){
    if(!this.canJumpMonthForward()) return;
    this.currentMonth.setMonth(this.currentMonth.getMonth() + 1);
    this.currentMonthChanged.emit(this.currentMonth);
    this.updateCalendar();
  }

  canJumpMonthForward(){
    let nextMonth = new Date(this.currentMonth);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    return !this.endDate || nextMonth <= this.endDate;
  }
  canJumpMonthBackward(){
    return !this.startDate || this.currentMonth > this.startDate;
  }

  jumpToCurrentMonth(){
    if(this.showRelativeMonths){
      let currentMonth = this.startDate?.clone() ?? new Date(0);
      currentMonth.setDate(1);
      currentMonth.setHours(0, 0, 0, 0);
      this.currentMonth = currentMonth;
    }
    else {
      let currentMonth = new Date();
      currentMonth.setDate(1);
      currentMonth.setHours(0, 0, 0, 0);
  
      if(this.endDate && currentMonth > this.endDate){
        currentMonth = this.endDate.clone();
        currentMonth.setDate(1);
        currentMonth.setHours(0, 0, 0, 0);
      }
      this.currentMonth = currentMonth;
    }
    
    this.updateCalendar();
  }

  canJumpToCurrentMonth(){
    if(!this.endDate) return true;
    let currentMonth = new Date();
    currentMonth.setDate(1);
    currentMonth.setHours(0, 0, 0, 0);
    return currentMonth < this.endDate;
  }


  onSelectedCalendarItemChanged(item: CalendarItem<T>){
    this.selectedCalendarDay = this.singleCalendarDays.find(day => day.date.isSameDate(item.date));
    this.selectedDayItemChanged.emit(item);
  }

  onSelectedCalendarDayChanged(day: SingleCalendarDay<T>, dayItemMenuTrigger: MatMenuTrigger){
    if(day.disabled) return;
    if(this.selectedDayItemChanged?.observed && day.calendarItems.length > 1){
      dayItemMenuTrigger.openMenu();
    }
    else {
      this.selectedCalendarDay = day;
      this.selectedDayChanged.emit(day);
    }    
  }

  timedOutCloser: any;

  onMouseEnterCalendarDay(dayItemMenuTrigger: MatMenuTrigger, calendarDay: SingleCalendarDay<T>){
    if(this.timedOutCloser){
      clearTimeout(this.timedOutCloser);
    }
    if(calendarDay.calendarItems.length > 0){
      dayItemMenuTrigger.openMenu();
    }
  }
  onMouseLeaveCalendarDay(dayItemMenuTrigger: MatMenuTrigger){
    this.timedOutCloser = setTimeout(() => {
      if(dayItemMenuTrigger.menuOpen) {
        dayItemMenuTrigger.closeMenu();
      }
    }, 500);
  }

  setDayItemsByWeekNumber(){
    this.singleCalendarDaysByWeekNumber = new Map<number, SingleCalendarDay<T>[]>();
    this.singleCalendarDays.forEach(day => {
      let weekNumber = day.date.getWeekOfYearNumber();
      if(!this.singleCalendarDaysByWeekNumber.has(weekNumber)){
        this.singleCalendarDaysByWeekNumber.set(weekNumber, []);
      }
      this.singleCalendarDaysByWeekNumber.get(weekNumber).push(day);
    });
    this.weeks = this.getWeeks();

    for(let week of this.weeks){
      if(this.singleCalendarDaysByWeekNumber?.has(week) && this.singleCalendarDaysByWeekNumber?.get(week)?.length < 7){
        let firstDay = this.singleCalendarDaysByWeekNumber.get(week)[0].date.clone();
        let diffToMonday = firstDay.getDayNumber();
        for(let i = 0; i < diffToMonday; i++){
          this.singleCalendarDaysByWeekNumber.get(week).unshift(null);
        }

        let lastDay = this.singleCalendarDaysByWeekNumber.get(week)[this.singleCalendarDaysByWeekNumber.get(week).length - 1].date.clone();
        let diffToSunday = 6 - lastDay.getDayNumber();
        for(let i = 0; i < diffToSunday; i++){
          this.singleCalendarDaysByWeekNumber.get(week).push(null);
        }
      }
    }
  }

  getWeeks(){
    let weeks = Array.from(this.singleCalendarDaysByWeekNumber.keys());
    return weeks;
  }


  updateCalendar(){
    if (!this._calendarItems) return;
    if(!this.currentMonth){
      let currentMonth = new Date();
      if(this.showRelativeMonths){
        currentMonth = this.startDate?.clone() ?? new Date(0);
      }
      currentMonth.setDate(1);
      currentMonth.setHours(0, 0, 0, 0);
      this.currentMonth = currentMonth;
    }

    if(this.endDate && this.currentMonth > this.endDate){
      let currentMonth = this.endDate.clone();
      currentMonth.setDate(1);
      currentMonth.setHours(0, 0, 0, 0);
      this.currentMonth = currentMonth;
    }

    this.singleCalendarDays = [];
    let firstDay = new Date(this.currentMonth);
    let lastDay = new Date(this.currentMonth.getFullYear(), this.currentMonth.getMonth() + 1, 0);

    let currentDay = firstDay.clone();
    let currentCalendarDay: SingleCalendarDay<T> = null;
    while (currentDay <= lastDay) {
      currentCalendarDay = new SingleCalendarDay();
      currentCalendarDay.date = currentDay.clone();
      currentCalendarDay.calendarItems = this._calendarItems.filter(item => item.date.isSameDate(currentDay));
      currentCalendarDay.disabled = (this.startDate && currentDay < this.startDate && !this.startDate.isSameDate(currentDay)) || (this.endDate && currentDay > this.endDate && !this.endDate.isSameDate(currentDay));
      currentCalendarDay.disabledDrop = this.startDate && currentDay < this.startDate
      this.singleCalendarDays.push(currentCalendarDay);
      currentDay.addDays(1);
    }

    this.setDayItemsByWeekNumber();

    if(this.selectedCalendarDay){
      this.selectedCalendarDay = this.singleCalendarDays.find(day => day.date.isSameDate(this.selectedCalendarDay.date));
    }
    else {
      this.selectedCalendarDay = this.singleCalendarDays.find(day => day.date.isSameDate(new Date()));
    }
  }

  toggleCollapsedCalendar(){
    if(this.isCollapsible){
      this.calendarCollapsed = !this.calendarCollapsed;
    }
    else {
      this.calendarCollapsed = false;
    }
  }

  onDragOverDay(event: DragEvent, calendarDay: SingleCalendarDay<T>){
    if(this.draggingEnabled && calendarDay != null && !calendarDay?.disabledDrop){
      this.dragOverCalendarDay.emit([event, calendarDay.date]);
    }
  }

  onDropOnDay(event, calendarDay: SingleCalendarDay<T>){
    if(this.draggingEnabled){
      event.preventDefault();
      this.droppedOnCalendarDay.emit(calendarDay.date);
    }
  }
  

  getRelativeWeekNumberByYearWeekNumber(weekNumber: number){
    let date = this.singleCalendarDaysByWeekNumber.get(weekNumber)?.[0]?.date;
    let startDate = this.startDate;
    if(date && startDate){
      let weekNumber = 1;
      let currentDay = startDate.clone();
      while(currentDay < date){
        currentDay.addDays(7);
        weekNumber++;
      }
      return weekNumber?.toString();
    }
    return '1'
  }

}

export class SingleCalendarDay<T> {
  public date: Date;
  public calendarItems: CalendarItem<T>[];
  public disabled: boolean = false;
  public disabledDrop: boolean = true;

  getCalendarItems(limit: number){
    return this.calendarItems.slice(0, limit);
  }
}

export class CalendarItem<T> {
  public date: Date;
  public title: string;
  public color: string;
  public item: T;

  constructor(date?: Date, title?: string, color?: string, item?: T) {
    this.date = date;
    this.title = title;
    this.color = color;
    this.item = item;
  }
}