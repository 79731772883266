<div class="dialog-topbar">
    <div class="icon-button" (click)="saveGroup()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
        <ng-container *ngIf="!utilityService.onSmallDisplay()">Speichern</ng-container>
    </div>
    <div class="icon-button float-right" (click)="cancelEditGroup()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        <ng-container *ngIf="!utilityService.onSmallDisplay()">Schließen</ng-container>
    </div>
    <div class="icon-button float-right delete-recipe marginleft-25 marginright-25" (click)="deleteGroup()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="padding-4 bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
        <ng-container *ngIf="!utilityService.onSmallDisplay()">Löschen</ng-container>
    </div>
</div>
<div *ngIf="selectedMetricGroup" class="dialog-content-wrapper" id="metricGroup-editor">
    <h3 class="">Metrik-Set bearbeiten</h3>
    <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-12">
            <label for="input-plan-name" class="label">Name</label>
            <input id="input-plan-name" maxlength="35" required class="input plannedmeal-name form-control wide-input" [(ngModel)]="selectedMetricGroup.name.de" placeholder="Name" type="text">
        </div>
    </div>
    <div class="spacer-50"></div>
    <div class="row">
        <div class="col-12">
            <div class="layout-box sub-box questions-box">
                <div class="bold display-inlineblock">Metriken</div>
                <div class="icon-button display-inlineblock marginleft-25" (click)="addMetric()">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                    </svg>
                    Metrik hinzufügen
                </div>
                <div class="row" cdkDropList (cdkDropListDropped)="dropAssignedMetric($event)">
                    <ng-container *ngFor="let metric of selectedMetricGroup?.metrics">
                        <div class="col-12" cdkDrag>
                            <div class="metric-wrapper">
                                <div class="drag-handle link" cdkDragHandle>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-grip-vertical" viewBox="0 0 16 16">
                                        <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                    </svg>
                                </div>
                                <div class="row">
                                    <div class="col-10 col-sm-8 col-lg-10 col-xl-6">
                                        <div class="name">{{metric.nameDe}}</div>
                                        <div class="description">
                                            {{metric.descriptionDe}} [Typ: {{metric.getPrintableType()}}]</div>
                                    </div>
                                    <div class="col-2 col-sm-1 order-sm-3 order-lg-2 order-xl-3">
                                        <div class="icon-button" (click)="onRemoveAssignedMetric(metric)">
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <!-- <div class="col-6 col-sm-3 order-sm-2 order-lg-3 col-lg-6 order-xl-2 col-xl-3">
                                        <div *ngIf="question.metric.isMetricTypeNumber()" class="targetvalue-wrapper">
                                            <div class="label">Zielwert</div>
                                            <input [ngModel]="metric.targetValue" (input)="onMetricTargetValueChanged($event.target.value, question.metric)" class="input small white wide {{metric.hasTargetValueError ? 'invalid' : ''}}" type="text" placeholder="optional"/>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="spacer-10"></div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner name="metricSetSpinner" bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>