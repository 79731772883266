import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA,MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-marketing-kit',
  templateUrl: './marketing-kit.component.html',
  styleUrls: ['./marketing-kit.component.css']
})
export class MarketingKitComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<MarketingKitComponent>, @Inject(MAT_DIALOG_DATA) private data: {  }, public dialog: MatDialog) {
    
  }

  ngOnInit(): void {
  }

  onCloseDialog(){
    this.dialogRef.close()
  }
}
