import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { TrainingPlan, TrainingSession, Week } from 'src/app/model/training-plan.model';
import { LanguageService } from 'src/app/services/language.service';
import { TrainingService } from 'src/app/services/training.service';

@Component({
  selector: 'app-session-templates-dialog',
  templateUrl: './session-templates-dialog.component.html',
  styleUrls: ['./session-templates-dialog.component.css']
})
export class SessionTemplatesDialogComponent {

  public showSessionTemplates: boolean = false;
  public filteredTrainingPlanTemplates: TrainingPlan[];
  public selectedSessionTemplateTab: string = "OWN"

  public expandedWeek: Week = null;

  public selectedSessions: TrainingSession[] = []

  constructor(public dialogRef: MatDialogRef<SessionTemplatesDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { }, public trainingService: TrainingService, public languageService: LanguageService, private spinner: NgxSpinnerService){
    this.updateFilteredSessionTemplates();
  }

  public sessionTemplateSearchInput: string

  onSelectTemplateTab(tabName: string){
    this.selectedSessionTemplateTab = tabName
  }

  toggleMenu(week: Week){
    if(this.expandedWeek == week){
      this.expandedWeek = null;
    }
    else {
      this.expandedWeek = week;
    }
  }

  async selectSessionTemplate(session: TrainingSession, template: TrainingPlan){
    this.spinner.show("sessionTemplateSpinner")
    if(!this.selectedSessions.includes(session)){
      await this.trainingService.getTrainingPlanTemplatePlannedExercises(template);
      this.selectedSessions.push(session);
    }
    else{
      this.selectedSessions = this.selectedSessions.filter(x => x != session)
    }
    this.spinner.hide("sessionTemplateSpinner")
  }

  onSessionTemplateSearchInputChanged(text: string) {
    this.sessionTemplateSearchInput = text
    this.updateFilteredSessionTemplates()
  }

  async updateFilteredSessionTemplates() {
    this.spinner.show("sessionTemplateSpinner");
    if (!this.sessionTemplateSearchInput || this.sessionTemplateSearchInput.length === 0) {
      this.filteredTrainingPlanTemplates = (await this.trainingService.getTrainingPlanTemplates())?.filter(template => template.sessions?.filter(x => !x.isRestDay)?.length > 0);
    } else {
    this.filteredTrainingPlanTemplates = 
      (await this.trainingService.getTrainingPlanTemplates())
        .filter(template => template.sessions?.filter(x => !x.isRestDay)?.length > 0 && (template.getName()?.toLowerCase().includes(this.sessionTemplateSearchInput.toLowerCase()) || template.sessions?.find(x => x.name?.toLowerCase()?.includes(this.sessionTemplateSearchInput.toLowerCase())) != null));
    }
    this.spinner.hide("sessionTemplateSpinner");
  }

  async onDeleteSessionTemplateSearchInput() {
    this.sessionTemplateSearchInput = null;
    (<HTMLInputElement> document.getElementById('templatesearch-input')).value = ''
    this.filteredTrainingPlanTemplates = await this.trainingService.getTrainingPlanTemplates()
  }

  onTakeSelection(selectedSessions: TrainingSession[]){
    let newSessions = [];
    for (let session of selectedSessions) {
      let newSession = new TrainingSession(session.name, null, session.isRestDay, session.exercises, false, session.weekId, session.plannedDate, session.baseSessionId, session.hide, session.indicatorColor, session.estimatedDurationInMinutes, session.nameTranslation);
      newSession.exercises.forEach(exercise => {
        exercise.id = null;
        exercise.sessionId = newSession.id;
      });
      newSessions.push(newSession);
    }
    

    if(newSessions.length > 0){
      this.dialogRef.close({shouldTake: true, selectedSessions: newSessions})
    }
  }

  onCloseDialog(){
    this.dialogRef.close({shouldTake: false})
  }
}
