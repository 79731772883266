import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { TrainingPlanTemplateFolder } from 'src/app/model/training-plan-template-folder.model';
import { TrainingPlan } from 'src/app/model/training-plan.model';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-training-plan-templates-selection-dialog',
  templateUrl: './training-plan-templates-selection-dialog.component.html',
  styleUrls: ['./training-plan-templates-selection-dialog.component.css']
})
export class TrainingPlanTemplatesSelectionDialogComponent {
  public filteredTrainingPlanTemplates: TrainingPlan[];
  public allAvailableTemplates: TrainingPlan[] = []
  public availableTrainingPlanTemplateFolders: TrainingPlanTemplateFolder[] = []
  public selectedTemplateTab: string = "OWN"
  public allowOnlySingleSelection: boolean = false

  public selectedTemplates: TrainingPlan[] = []

  constructor(public dialogRef: MatDialogRef<TrainingPlanTemplatesSelectionDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { availableTemplates: TrainingPlan[], availableTrainingPlanTemplateFolders: TrainingPlanTemplateFolder[], allowOnlySingleSelection: boolean }, public languageService: LanguageService, private spinner: NgxSpinnerService){
    this.allAvailableTemplates = data.availableTemplates;
    this.availableTrainingPlanTemplateFolders = data.availableTrainingPlanTemplateFolders;
    if (data.allowOnlySingleSelection != null) this.allowOnlySingleSelection = data.allowOnlySingleSelection;
    this.updateFilteredTemplates();
  }

  public templateSearchInput: string

  onSelectTemplateTab(tabName: string){
    this.selectedTemplateTab = tabName
  }

  async selectTemplate(template: TrainingPlan){
    if (this.allowOnlySingleSelection) {
      this.selectedTemplates = [template]
      this.onTakeSelection(this.selectedTemplates)
      return
    }
    if(!this.selectedTemplates.includes(template)){
      this.selectedTemplates.push(template);
    }
    else{
      this.selectedTemplates = this.selectedTemplates.filter(x => x != template)
    }
  }

  onTemplateSearchInputChanged(text: string) {
    this.templateSearchInput = text
    this.updateFilteredTemplates()
  }

  getPrintableTemplateFolderNames(template: TrainingPlan){
    return this.getTrainingTemplateFoldersOfTemplate(template).map(x => x.name.GetValue(this.languageService.selectedLanguageCode)).join(", ");
  }

  getTrainingTemplateFoldersOfTemplate(template: TrainingPlan){
    return this.availableTrainingPlanTemplateFolders?.filter(x => template.folderIds.includes(x.id)) || [];
  }

  async updateFilteredTemplates() {
    if (!this.templateSearchInput || this.templateSearchInput.length === 0) {
      this.filteredTrainingPlanTemplates = this.allAvailableTemplates;
    } else {
      this.filteredTrainingPlanTemplates = this.allAvailableTemplates.filter(template => (template.getName()?.toLowerCase().includes(this.templateSearchInput.toLowerCase())));
    }
  }

  async onDeleteTemplateSearchInput() {
    this.templateSearchInput = null;
    (<HTMLInputElement> document.getElementById('templatesearch-input')).value = ''
    this.updateFilteredTemplates();
  }

  onTakeSelection(selectedTemplates: TrainingPlan[]){
    if(selectedTemplates.length > 0){
        this.dialogRef.close({shouldTake: true, selectedTemplates: selectedTemplates})
    }
  }

  onCloseDialog(){
    this.dialogRef.close({shouldTake: false})
  }
}
