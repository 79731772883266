<div class="layout-box adjust-mobile">
    <h3 class="display-inlineblock">Trainingspläne</h3>
    <div class="training-plan-action-buttons display-inlineblock marginleft-25 mobile-marginleft-0">
        <div class="icon-button display-inlineblock" (click)="onCreateTrainingPlan()">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
            </svg>
            Erstellen
        </div>
        <div class="icon-button display-inlineblock marginleft-25" (click)="onShowTrainingPlanTemplates()">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
            </svg>
            Aus Vorlage erstellen
        </div>
        <div class="icon-button small display-inlineblock marginleft-25" (click)="openTrainingVariablesDialog()">
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-bullseye" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                <path d="M8 13A5 5 0 1 1 8 3a5 5 0 0 1 0 10m0 1A6 6 0 1 0 8 2a6 6 0 0 0 0 12"/>
                <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8"/>
                <path d="M9.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
            </svg>
            <span class="only-desktop">Trainingsvariablen bearbeiten</span>
        </div>
    </div>
    <div class="recipesharing-toggle toggle-wrapper display-inlineblock float-right margin-4 mobile-marginbottom-10" tooltip="Aktiviere, dass Coachees sich selbstständig Trainingspläne in der App erstellen können.">
        <div class="toggle {{isCoacheeTrainingPlanCreationEnabled() ? 'on' : ''}}" (click)="onEnableCoacheeTrainingPlanCreationEnabledChanged(!isCoacheeTrainingPlanCreationEnabled())">
            <div class="handle"></div>
        </div>
        <div class="text">
            Planerstellung durch Coachee zulassen <span *ngIf="user.coacheeTrainingPlanCreationEnabled == null">(Standardeinstellung)</span>
        </div>
    </div>
    <div class="spacer-10"></div>
    <!--div class="icon-button display-inlineblock marginleft-25 training-plan-action-button" (click)="onShowTrainingPlanCopies()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
        </svg>
        Von anderem Kunden kopieren
    </div-->
    <div class="row margin-0 width-100 trainingplan-container">
        <div *ngIf="showTrainingPlanTemplates || showTrainingPlanCopies" class="col-12 layout-box sub-box dropzone" (dragover)="onDragOverTemplateDropzone($event)" (dragend)="onDragEndTemplate($event, null)" (drop)="onDropOnTempateDropzone($event)">
            <div class="fontsize-small">Plan hier ablegen</div>
        </div>
        <!-- <div *ngIf="showTrainingPlanCopies" class="col-12 layout-box sub-box dropzone" (dragover)="onDragOverTemplateDropzone($event)" (dragend)="onDragEndTemplate($event, null)" (drop)="onDropOnTempateDropzone($event)">
            <div class="fontsize-small">Plan hier ablegen</div>
        </div> -->
        <ng-container *ngIf="!user.loadedTrainingPlans">
            <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
        </ng-container>
        <ng-container *ngFor="let plan of user.trainingPlans">
            <div *ngIf="!plan?.deleted && (showAllTrainingPlanConfigs || !plan.isDeprecated)" class="col-12 layout-box sub-box link" (click)="onEditTrainingPlan(plan)">
                <!-- <div *ngIf="planConfig.isCurrentlyActive()" class="active-marker"></div> -->
                <div class="row">
                    <div class="col-8 col-sm-6 col-xl-2">
                        {{plan?.name}}<br>
                        <div class="label">{{plan.startDate.asFormatedString()}} {{plan.endDate ? ' bis ' + plan.endDate.asFormatedString() : ' bis unbegrenzt'}}</div>
                    </div>
                    <div class="col-4 col-sm-6 col-xl-8">
                        <div *ngIf="!utilityService.onMobile()" class="icon-button marginleft-50">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-arrow-up-right padding-4" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                            </svg>
                            Öffnen
                        </div>
                        <div class="icon-button marginleft-25 marginbottom-10" (click)="onDuplicateTrainingPlanConfig(plan);$event.stopPropagation()">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="padding-4 bi bi-files" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z"/>
                                <path d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z"/>
                            </svg>
                            <span class="only-desktop">Duplizieren</span>
                        </div>
                        <div class="icon-button marginleft-25 marginbottom-10" (click)="onDeleteTrainingPlan(plan);$event.stopPropagation()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-trash padding-4" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>
                            <span class="only-desktop">Löschen</span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <div *ngIf="hasDeprecatedPlans(user.trainingPlans) && !showAllTrainingPlanConfigs" class="icon-button display-inlineblock marginleft-25" (click)="onShowAllTrainingPlanConfigs(true)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            </svg>
            Alle anzeigen
        </div>
        <div *ngIf="hasDeprecatedPlans(user.trainingPlans) && showAllTrainingPlanConfigs" class="icon-button display-inlineblock marginleft-25" (click)="onShowAllTrainingPlanConfigs(false)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
            </svg>
            Alte verbergen
        </div>
    </div>
</div>
<div *ngIf="showTrainingPlanCopies" class="templates-view layout-box">
    <div class="dialog-topbar">
        <div class="icon-button marginright-25" (click)="onHideTrainingPlanCopies()">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
        </div>
    </div>
    <div class="dialog-content-wrapper">
        <div class="row">
            <div class="col-12" *ngFor="let client of userService.getClients()">
                <ng-container *ngIf="client !== user">
                    <div class="row" *ngIf="client.trainingPlans?.length > 0">
                        <div class="">{{client.name}}</div>
                    </div>
                    <div class="row" *ngFor="let plan of client.trainingPlans">
                        <div class="layout-box sub-box template-item"  (click)="selectTrainingPlanTemplate(plan)" draggable="true" (dragstart)="onDragStartPlanningTemplate($event, plan)" (dragend)="onDragEndTemplate($event, plan)">
                            <div class="">{{plan.name}}</div>
                            <div class="small-text" *ngIf="plan.isDeprecated">(abgelaufen)</div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="spacer-50"></div>
    </div>
</div>

<ng-container *ngIf="showTrainingPlanTemplates">
    <div class="templates-view layout-box">
        <div class="dialog-topbar">
            <div class="icon-button marginright-25" (click)="onHideTrainingPlanTemplates()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </div>
        </div>
        <div class="dialog-content-wrapper">
            <app-training-templates (onDragStartTrainingPlanTemplate)="onDragStartTrainingPlanTemplate($event)" [selectionMode]="true" (trainingPlanTemplateSelectionChanged)="trainingPlanTemplateSelected($event)"></app-training-templates>
            <!--div class="tab-selection textalign-center">
                <button class="button tab-button" (click)="onSelectTemplateTab('OWN')">
                    Eigene Vorlagen
                    <div *ngIf="selectedTemplateTab == 'OWN'" class="underline"></div>
                </button>
                <button class="button tab-button" (click)="onSelectTemplateTab('NUTRILIZE')">
                    nutrilize Vorlagen
                    <div *ngIf="selectedTemplateTab == 'NUTRILIZE'" class="underline"></div>
                </button>
            </div>
            <div class="spacer-20"></div-->
            <!-- <div class="row">
                <div class="col-12">
                    <div class="spacer-10"></div>
                    <input id="templatesearch-input" class="input wide" (input)="onTemplateSearchInputChanged($event.target.value)" placeholder="Vorlagen durchsuchen" type="text">
                    <div class="icon-button deletesearch-icon delete-recipesearch" (click)="onDeleteTemplateSearchInput()">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                    <div class="spacer-25"></div>
                </div>
            </div>
            <div *ngIf="trainingService.trainingPlanTemplates?.length > 0" class="row">
                <div *ngFor="let template of filteredTrainingPlanTemplates" class="col-12">
                    <div (click)="selectTrainingPlanTemplate(template)" *ngIf="(selectedTemplateTab === 'OWN' && template.licenceHolderUid !== 'nutrilize') || (selectedTemplateTab === 'NUTRILIZE' && template.licenceHolderUid === 'nutrilize')" class="layout-box sub-box template-item" draggable="true" (dragstart)="onDragStartPlanningTemplate($event, template)" (dragend)="onDragEndTemplate($event, template)">
                        <div class="">{{template.name}}</div>
                        <div class="label">{{template.getDescription()}}</div>
                    </div>
                </div>
            </div>
            <div class="spacer-50"></div> -->
        </div>
    </div>
</ng-container>
<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true"><div *ngIf="spinnerText" class="spinner-overlaytext" [innerHTML]="spinnerText"></div></ngx-spinner>