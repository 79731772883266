<div class="dialog-topbar">
    <h3>Einstellungen</h3>
</div>
<div class="dialog-content-wrapper mealsuggestion-settings">
    Genauigkeit:
    <div class="spacer-10"></div>
    <div class="durationunit-selection-wrapper">
        <div class="d-inline-block wrapper-selection">
            <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownTrigger" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{mealSuggestionAccuracy == 'high' ? 'Hoch' : 'Standard'}}</button>
            <div class="dropdown-menu" aria-labelledby="dropdownProduct">
                <button class="dropdown-item" type="button" (click)="onSetMealSuggestionAccuracy('medium')">Standard</button>
                <button class="dropdown-item" type="button" (click)="onSetMealSuggestionAccuracy('high')">Hoch</button>
            </div>
        </div>
    </div>
    <div class="spacer-10"></div>
    <div *ngIf="mealSuggestionAccuracy == 'high'" class="label">
        Achtung: Bei höherer Genauigkeit werden weniger passende Rezepte gefunden.
    </div>
    <div class="spacer-10"></div>
    <input class="input checkbox" type="checkbox" [(ngModel)]="allowWeightAdjustment"><div class="display-inline"> Gewichtsanpassung der einzelnen Lebensmittel zulassen</div>
    <div class="icon-button marginleft-25" tooltip="Um die Nährstoffziele noch besser zu erreichen, können einzelne Lebensmittel des Rezepte unabhängig voneinander angepasst werden.">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
        </svg>
    </div>
</div>
<mat-dialog-actions>
    <div class="actions">
        <button class="button-filled small light float-right marginright-25" (click)="onSubmit()">Übernehmen</button>
    </div>
</mat-dialog-actions>