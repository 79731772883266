<div class="dialog-topbar">
    <h3>Vorlage Erstellen</h3>
</div>
<div class="dialog-content-wrapper">
    <p>
        Vorlagenname:<br>
        <input class="input wide" [(ngModel)]="name" placeholder="Name" type="text">
    </p>
    <br>
    <p>
        Beschreibung:<br>
        <textarea class="input wide" [(ngModel)]="description" placeholder="Beschreibung" type="text" rows="5"></textarea>
    </p>
    <br>
    <div class="actions">
        <button class="button-filled small light float-right" (click)="onConfirmDialog()">Erstellen</button>
        <button class="button-filled small light float-right marginright-25" (click)="onCancelDialog()">Abbrechen</button>
    </div>
</div>