<div class="container pt-3">
  <div class="row justify-content-sm-center">
      <div class="layout-box reset-password">
        <h2>Passwort zurücksetzen</h2>
        <div class="">
          <div class="row">
            <div class="col-md-12">
              <form>
                <input [(ngModel)]="mail" [ngModelOptions]="{standalone: true}" type="text" class="form-control mb-2" placeholder="Email" required autofocus/>
                <button class="btn btn-lg btn-primary btn-block mb-1 button-filled float-right" type="submit" (click)="resetPassword()">Zurücksetzen</button>
                <br><br>
                <button class="btn button-filled light small" routerLink="/login">Zurück zum Login</button>
              </form>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
