import { ClientComponent } from '../client/client-v1/client-v1.component';
import { NutritionalGoal } from './nutritionalgoal.model';
import { CalendarDay, DayItem } from '../nutrition-plan/nutrition-plan.component';
import { PlannedMealV2 } from './plannedmealv2.model';
import { NutritionalGoalV2 } from './nutritionalgoalv2.model';
import { FirestoreNutritionPlanService } from '../services/firestore-nutritionplan.service';
import { NutritionalValue } from './basenutritionfact.model';

export class CycleConfig {
    public weekDays = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];

    id: string;
    name: string;
    startDate: Date
    endDate: Date

    isTemplate: boolean
    startDayNumber: number
    endDayNumber: number
    

    commonTargetValues:Record<string, number> = {}


    // public getCommonTargetValuesAsRecord(){
    //     // let recordObject: Record<string,number> = {}
    //     // for (let [key, value] of this.commonTargetValues) {
    //     //     if(recordObject[key] != null) recordObject[key] = value;
    //     // }
    //     // console.log(recordObject)
    //     // return recordObject;     
    //     console.log(this.commonTargetValues)
    //     return this.commonTargetValues

    // }


    situations: Situation[]

    // Tmp:
    selectedSituation: Situation
    colorScheme: string

    constructor();
    constructor(init: CycleConfig);
    constructor(init?: CycleConfig) {
        this.id = init && init.id || null
        this.name = init && init.name || null
        this.startDate = init && init.startDate || null
        this.endDate = init && init.endDate || null
        this.situations = init && init.situations || []
        this.isTemplate = init && init.isTemplate || false
        this.startDayNumber = init && init.startDayNumber || null
        this.endDayNumber = init && init.endDayNumber || null
    }

    setSelectedSituationById(id: String) {
        this.selectedSituation = this.situations?.find(situation => situation.id == id)
    }

    getNutritionalGoalForDate(date: Date): NutritionalGoalV2 {
        return this.getNutritionalGoalForDateAndSituation(date, this.selectedSituation?.id)
    }

    getNutritionalGoalForDateAndSituation(date: Date, situationId: string): NutritionalGoalV2 {
        var applicableGoal = null
        var weekday = NutritionalGoal.WEEKDAYS[date.getDayNumber()]
        if (situationId) {
            this.situations.forEach(situation => {
                if (!applicableGoal && situation.id == situationId) {
                    situation.nutritionalGoals.forEach(goal => {
                        if (goal.applicableWeekdays.includes(weekday) || goal.applicableWeekdays.includes('all')) applicableGoal = goal
                    })
                }
            })
        }
        if (applicableGoal == null) {
            this.situations.forEach(situation => {
                if (!applicableGoal && situation.id == SituationType.default) {
                    situation.nutritionalGoals.forEach(goal => {
                        if (goal.applicableWeekdays.includes(weekday) || goal.applicableWeekdays.includes('all')) applicableGoal = goal
                    })
                }
            })
        }
        return applicableGoal
    }

    clone(): CycleConfig {
        var config = new CycleConfig(this)
        if (this.startDate) config.startDate = this.startDate.clone()
        if (this.endDate) config.endDate = this.endDate.clone()
        config.commonTargetValues = this.commonTargetValues;
        config.situations = []
        this.situations.forEach(e => {
            var situation = e.clone()
            config.situations.push(situation)
        })
        config.selectedSituation = config.situations.find(situation => situation.id == this.selectedSituation.id)
        return config
    }

    hasStructuralDifference(config: CycleConfig) {
        var hasDiff = false
        if (config.situations.length != this.situations.length) hasDiff = true
        if (!hasDiff) {
            for (var i = 0; i < this.situations.length; i++) {
                if (!hasDiff && this.situations[i].hasStructuralDifference(config.situations[i])) hasDiff = true
            }
        }
        return hasDiff
    }

    getName() {
        return this.name || 'Nährstoffziel'
    }

    isValid() {
        var valid = true
        this.situations.forEach(situation => {
            if (situation.type == SituationType.custom && situation.customName.length == 0) valid = false
            situation.nutritionalGoals.forEach(goal => {
              if (!goal.isValid()) valid = false
            })
        })
        return valid
    }

    isCurrentlyActive() {
        return this.isActiveAtDate(new Date())
    }
    isActiveAtDate(date: Date) {
        return (this.startDate < date || this.startDate.isSameDate(date)) && (!this.endDate || this.endDate.isSameDate(date) || this.endDate > date)
    }

    isOlderThanDate(date: Date) {
        return this.endDate && (this.endDate < date && !this.endDate.isSameDate(date))
    }
    isOlderThanToday() {
        return this.isOlderThanDate(new Date())
    }
    isInFuture(): boolean {
      let today = new Date()
      return this.startDate > today || this.startDate.isSameDate(today)
    }
    intersectsWith(c: CycleConfig): boolean {
        var first = this.startDate.isSameOrBeforeDate(c.startDate) ? this : c
        var second = this.startDate.isSameOrBeforeDate(c.startDate) ? c : this
        return second.startsInside(first) || first.endsInside(second) || second.endsInside(first) || second.isInside(first)
    }
    startsInside(c: CycleConfig): boolean {
        if (this.startDate.isSameOrAfterDate(c.startDate) && ((c.endDate && this.startDate.isSameOrBeforeDate(c.endDate)) || !c.endDate)) return true
        return false
    }
    endsInside(c: CycleConfig): boolean {
        if (this.endDate && ((!c.endDate && this.startsInside(c)) || (c.endDate && (this.endDate.isSameOrBeforeDate(c.endDate)) && this.endDate.isSameOrAfterDate(c.startDate)))) return true
        return false
    }
    isInside(c: CycleConfig) {
        return this.startDate.isSameOrAfterDate(c.startDate) && this.endDate && ((c.endDate && this.endDate.isSameOrBeforeDate(c.endDate)) || (!c.endDate))
    }

    static defaultConfig(startDate: Date, endDate: Date) {
        var config = new CycleConfig()
        config.startDate = startDate
        config.endDate = endDate
        config.situations.push(Situation.defaultSituation())
        return config
    }

}

export enum SituationType {
    none = '',
    default = 'DEFAULT',
    training = 'TRAINING',
    restday = 'RESTDAY',
    custom = 'CUSTOM',
  }
export const SituationTranslation: Record<SituationType, string> = {
    [SituationType.none]: "Situation auswählen",
    [SituationType.default]: "Standard",
    [SituationType.training]: "Training",
    [SituationType.restday]: "Ruhetag",
    [SituationType.custom]: "Eigene",
};

export class Situation {
    id: string
    type: SituationType
    customName: string
    nutritionalGoals: NutritionalGoalV2[]

    constructor();
    constructor(init: Situation);
    constructor(init?: Situation) {
        this.id = init && init.id || FirestoreNutritionPlanService.generateUniqueString()
        this.type = init && init.type || null
        this.customName = init && init.customName || null
        this.nutritionalGoals = init && init.nutritionalGoals || []
    }

    getName() {
        if (this.type == SituationType.custom) return this.customName
        return SituationTranslation[this.type]
    }

    hasStructuralDifference(situation: Situation) {
        var hasDiff = false
        if (this.getName() != situation.getName()) hasDiff = true
        if (this.nutritionalGoals.length != situation.nutritionalGoals.length) hasDiff = true
        if (!hasDiff) {
            for (var i = 0; i < this.nutritionalGoals.length; i++) {
                if (!hasDiff && this.nutritionalGoals[i].hasStructuralDifference(situation.nutritionalGoals[i])) hasDiff = true
            }
        }
        return hasDiff
    }

    static defaultSituation() {
        return new Situation({id: SituationType.default, type: SituationType.default, customName: null, nutritionalGoals: []} as any)
    }

    clone(): Situation {
        var situation = new Situation(this)
        situation.nutritionalGoals = []
        this.nutritionalGoals.forEach(goal => {
            situation.nutritionalGoals.push(goal.clone())
        })
        return situation
    }
}