<ng-container *ngTemplateOutlet="text">
</ng-container>

<!-- Text Button -->
<ng-template #text>
  <div class="layout-box client-box" [ngStyle]="utilityService.customMobileAppStyle('layout-box')" routerLink="/client/{{user?.uid}}" (click)="navService.setNavItemsNormal()">
    <div *ngIf="dashboard" class="remove-box-button" (click)="deleteUserFromDashboard()">
      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
      </svg>
    </div>
    <div class="row">
      <div class="col-10">
        <div class="profile">
          <div class="profile-picture">
            <img draggable="false" *ngIf="user?.profilePictureUrl" loading="lazy" (error)="utilityService.onImageErrorHide($event)" src="{{user?.profilePictureUrl}}">
            <div *ngIf="!user?.profilePictureUrl">
                <img draggable="false" loading="lazy" (error)="utilityService.onImageErrorHide($event)" src="{{ (userService.getProfilePictureForUser(user) | async) }}">
            </div>
          </div>
        </div>
        <h3 class="name">{{user?.getName()}}</h3>
        <!--div class="indicator marginleft-10 display-inlineblock" *ngIf="user?.versionCode > 135 && !getCoach()?.portalSettingsCoach?.migratedAppUserUids.includes(user.uid)" tooltip="Dieser Coachee hat die neue App-Version installiert. Stelle sicher, dass du die neuen Einstellungen verwendest."></div-->
      </div>
      <div class="col-2">
        <button mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
          </svg>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="onRenameClient()">Kund:in umbenennen</button>
          <button mat-menu-item [matMenuTriggerFor]="addToGroup">Zu Gruppe hinzufügen</button>
          <button *ngIf="user.metadataUser?.assignedClientGroups?.length > 0" mat-menu-item [matMenuTriggerFor]="removeFromGroup">Aus Gruppe entfernen</button>
          <button mat-menu-item (click)="chatService.onOpenChatForUid(user.uid)">Chat öffnen</button>
          <button mat-menu-item (click)="onDeactivateLicence()">Lizenz deaktivieren</button>
        </mat-menu>
        <mat-menu #addToGroup="matMenu">
          <ng-container *ngFor="let group of userService.getClientGroups()">
            <button *ngIf="group != 'Alle' && !user.metadataUser?.assignedClientGroups.includes(group)" mat-menu-item (click)="userService.addClientToGroup(user, group)">{{group}}</button>
          </ng-container>
          <button mat-menu-item (click)="createNewGroupAndAddUser()">Neue Gruppe erstellen</button>
        </mat-menu>
        <mat-menu #removeFromGroup="matMenu">
          <ng-container *ngFor="let group of user.metadataUser?.assignedClientGroups">
            <button mat-menu-item (click)="userService.removeClientFromGroup(user, group)">{{group}}</button>
          </ng-container>
        </mat-menu>

      </div>
      <div class="col-12" *ngIf="!utilityService.onNativeMobileApp() && environment.firebaseProjectId != 'traindoo-app'">
        <div class="spacer-10"></div>
        <div class="kpi-display">
          <div class="kpi-weight">
            <div *ngIf="user?.firstBodyWeight" class="start-value">
              {{user?.firstBodyWeight?.toFixed(1)}}
              <span class="label">Startgewicht</span>
            </div>
            <div *ngIf="user?.firstBodyWeight && user?.latestBodyWeight" class="trend-indicator">
              <svg *ngIf="user?.firstBodyWeight > user?.latestBodyWeight" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M14 13.5a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1 0-1h4.793L2.146 2.854a.5.5 0 1 1 .708-.708L13 12.293V7.5a.5.5 0 0 1 1 0v6z"/>
              </svg>
              <svg *ngIf="user?.firstBodyWeight < user?.latestBodyWeight" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
              </svg>
              <svg *ngIf="user?.firstBodyWeight == user?.latestBodyWeight" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
              </svg>
            </div>
            <div *ngIf="user?.latestBodyWeight" class="current-value">
              {{user?.latestBodyWeight?.toFixed(1)}}
              <span class="label">Gewicht</span>
            </div>
          </div>
        </div>
      </div>

      <div class="plan-state" *ngIf="environment.firebaseProjectId == 'traindoo-app'">
        <div class="chip link not-planned" *ngIf="!user.hasActiveTrainingPlan()">Kein Training geplant</div>
        <div class="chip link planned" *ngIf="user.hasActiveTrainingPlan() && !user.hasExpiringTrainingPlan()">Training ist geplant</div>
        <div class="chip link expiring" *ngIf="user.hasActiveTrainingPlan() && user.hasExpiringTrainingPlan()">Plan endet bald</div>
      </div>

      <div class="groups-list" *ngIf="environment.firebaseProjectId == 'traindoo-app'">
        <div class="recipe-tags">
          <ng-container *ngFor="let group of user.metadataUser?.assignedClientGroups">
              <div class="chip link client-group" mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                {{group}}
              </div>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="userService.removeClientFromGroup(user, group)">Aus Gruppe entfernen</button>
              </mat-menu>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>