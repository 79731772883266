<div class="nutritionalvalue-popover">
    <div class="nutritionalvalue-group">
        <span class="bold">Nährstoffe:</span><br>
        <ng-container *ngFor="let nutritionalValue of BaseNutritionFact.getNutritionalValuesForGroup(0)">
            <div class="row">
                <div *ngIf="nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != null && nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != undefined" class="col-12 nutritionalvalue-entry">
                    {{BaseNutritionFact.getPrintableNutritionalValue(nutritionalValue, nutritionalValueHolder?.getNutritionalValue(nutritionalValue), cycleConfig?.commonTargetValues[nutritionalValue])}}
                </div>
            </div>
        </ng-container>
    </div>
    <div class="nutritionalvalue-group">
        <span class="bold">Nährstoffdetails:</span><br>
        <ng-container *ngFor="let nutritionalValue of BaseNutritionFact.getNutritionalValuesForGroup(1)">
            <div class="row">
                <div *ngIf="nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != null && nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != undefined" class="col-12 nutritionalvalue-entry">
                    {{BaseNutritionFact.getPrintableNutritionalValue(nutritionalValue, nutritionalValueHolder?.getNutritionalValue(nutritionalValue), cycleConfig?.commonTargetValues[nutritionalValue])}}
                </div>
            </div>
        </ng-container>
    </div>
    <div class="nutritionalvalue-group">
        <span class="bold">Vitamine:</span><br>
        <ng-container *ngFor="let nutritionalValue of BaseNutritionFact.getNutritionalValuesForGroup(3)">
            <div class="row">
                <div *ngIf="nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != null && nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != undefined" class="col-12 nutritionalvalue-entry">
                    {{BaseNutritionFact.getPrintableNutritionalValue(nutritionalValue, nutritionalValueHolder?.getNutritionalValue(nutritionalValue), cycleConfig?.commonTargetValues[nutritionalValue])}}
                </div>
            </div>
        </ng-container>
    </div>
    <div class="nutritionalvalue-group">
        <span class="bold">Mineralstoffe und Spurenelemente:</span><br>
        <ng-container *ngFor="let nutritionalValue of BaseNutritionFact.getNutritionalValuesForGroup(4)">
            <div class="row">
                <div *ngIf="nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != null && nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != undefined" class="col-12 nutritionalvalue-entry">
                    {{BaseNutritionFact.getPrintableNutritionalValue(nutritionalValue, nutritionalValueHolder?.getNutritionalValue(nutritionalValue), cycleConfig?.commonTargetValues[nutritionalValue])}}
                </div>
            </div>
        </ng-container>
    </div>
    <div class="nutritionalvalue-group">
        <span class="bold">Kohlenhydratzusammensetzung:</span><br>
        <ng-container *ngFor="let nutritionalValue of BaseNutritionFact.getNutritionalValuesForGroup(2)">
            <div class="row">
                <div *ngIf="nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != null && nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != undefined" class="col-12 nutritionalvalue-entry">
                    {{BaseNutritionFact.getPrintableNutritionalValue(nutritionalValue, nutritionalValueHolder?.getNutritionalValue(nutritionalValue), cycleConfig?.commonTargetValues[nutritionalValue])}}
                </div>
            </div>
        </ng-container>
    </div>
    <div class="nutritionalvalue-group">
        <span class="bold">Aminosäuren:</span><br>
        <ng-container *ngFor="let nutritionalValue of BaseNutritionFact.getNutritionalValuesForGroup(5)">
            <div class="row">
                <div *ngIf="nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != null && nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != undefined" class="col-12 nutritionalvalue-entry">
                    {{BaseNutritionFact.getPrintableNutritionalValue(nutritionalValue, nutritionalValueHolder?.getNutritionalValue(nutritionalValue), cycleConfig?.commonTargetValues[nutritionalValue])}}
                </div>
            </div>
        </ng-container>
    </div>
    <div class="nutritionalvalue-group">
        <span class="bold">Fettzusammensatzung:</span><br>
        <ng-container *ngFor="let nutritionalValue of BaseNutritionFact.getNutritionalValuesForGroup(6)">
            <div class="row">
                <div *ngIf="nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != null && nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != undefined" class="col-12 nutritionalvalue-entry">
                    {{BaseNutritionFact.getPrintableNutritionalValue(nutritionalValue, nutritionalValueHolder?.getNutritionalValue(nutritionalValue), cycleConfig?.commonTargetValues[nutritionalValue])}}
                </div>
            </div>
        </ng-container>
    </div>
    <div class="nutritionalvalue-group">
        <div class="row col-12 footnote">
            *EU-RDA (2008) <br>
            **von Coach geplant
        </div>
    </div>
</div>