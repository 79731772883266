import { Component, Inject, Input, OnInit } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { InputFieldDialogComponent } from '../inputfield-dialog/inputfield-dialog.component';
import { User } from '../model/user.model';
import { ChatService } from '../services/chat.service';
import { FirestoreService } from '../services/firestore.service';
import { NavbarService } from '../services/navbar.service';
import { UtilityService } from '../services/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-customer-button',
  templateUrl: './customer-button.component.html',
  styleUrls: ['./customer-button.component.css']
})
export class CustomerButtonComponent implements OnInit {

  public environment = environment

  @Input() user: User;
  @Input() dashboard: boolean;
  coach: User

  constructor(public navService: NavbarService, public userService: FirestoreService, public chatService: ChatService, public dialog: MatDialog, public utilityService: UtilityService) { }

  ngOnInit(): void {
    this.coach = this.userService.getLoggedInUser()
  }

  deleteUserFromDashboard() {
    this.userService.deleteUserFromDashboard(this.user?.uid);
  }

  createNewGroupAndAddUser() {
    const dialogRef = this.dialog.open(InputFieldDialogComponent, {
      data: { message: '', title: 'Gruppe erstellen' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.input) {
        var clientGroup = result.input
        if (!this.coach.portalSettingsCoach.clientGroups.includes(clientGroup)) this.coach.portalSettingsCoach.clientGroups.push(clientGroup)
        this.userService.updatePortalSettingsForCoach(this.coach)
        if (!this.userService.clientGroups.includes(clientGroup)) this.userService.clientGroups.push(clientGroup)
        this.userService.sortClientGroups()
        this.userService.addClientToGroup(this.user, result.input)
      }
    });
  }

  onDeactivateLicence() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du die Lizenz wirklich deaktivieren? ' + this.user.getName() + ' kann danach nicht mehr in der Coaching Zone von dir betreut werden.', title: 'Lizenz deaktivieren' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userService.deactivateLicence(this.user.licence.lid);
      }
    });
  }

  onRenameClient() {
    const dialogRef = this.dialog.open(InputFieldDialogComponent, {
      data: { message: '', title: 'Kund:in umbenennen' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.input) {
        this.user.metadataUser.userName = result.input
        this.userService.updateCoachingMetaData(this.user, false)
      }
    });
  }

  getCoach() {
    return this.userService.getLoggedInUser()
  }

}
