export class CoachSubscription {
    id: string;
    creationDate: Date;
    provider: string;
    stripeCustomerId: string;
    stripeSubscriptionItemId: string;
    product: string
    active: boolean
    overwriteSubscriptionStartDate: Date

    constructor();
    constructor(init: CoachSubscription);
    constructor(init?: CoachSubscription) {
        this.id = init && init.id || null
        this.creationDate = init && init.creationDate || null
        this.provider = init && init.provider || null
        this.stripeCustomerId = init && init.stripeCustomerId || null
        this.stripeSubscriptionItemId = init && init.stripeSubscriptionItemId || null
        this.product = init && init.product || null
        this.active = init && init.active || false
        this.overwriteSubscriptionStartDate = init && init.overwriteSubscriptionStartDate || null
    }

}
