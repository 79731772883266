import { Component, Input } from '@angular/core';
import { Note } from 'src/app/model/note.model';
import { User } from 'src/app/model/user.model';
import * as marked from 'marked';
import { FirestoreService } from 'src/app/services/firestore.service';
import { firstValueFrom } from 'rxjs';
import { DashboardComponent } from 'src/app/dashboard/dashboard-v1/dashboard.component';
import { NotesDialogComponent } from '../notes-dialog/notes-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-quick-note-view',
  templateUrl: './quick-note-view.component.html',
  styleUrls: ['./quick-note-view.component.css']
})
export class QuickNoteViewComponent {

  public user: User
  public loading: boolean = true
  public editableNote: Note = new Note()

  @Input() set setUser(value: User) {
    this.user = value
    if (this.user?.notes) {
      this.loading = false
      this.setEditableNote()
    } else {
      firstValueFrom(this.userService.getAllNotesForUser(this.user)).then(notes => {
        this.user.notes = notes
        this.loading = false
        this.setEditableNote()
      })
    }
  }

  setEditableNote() {
    var mainNote = this.user.notes.find(note => note.isMainNote)
    if (mainNote != null) {
      this.editableNote = mainNote
    } else if (this.user.notes.length > 0) {
      this.editableNote = this.user.notes[0]
    } else {
      this.editableNote.isMainNote = true
    }
    DashboardComponent.delay(100).then(() => {
      (<HTMLInputElement> document.getElementById('editor-textarea')).value = this.editableNote.content
    })
  }

  onShowAllNotes() {
    this.dialog.open(NotesDialogComponent, {width: '1000px', data: { user: this.user }});
  }

  compiledMarkdown: string
  editorValue: string
  editorPlaceholder = 'Neue Notiz...'

  constructor(private userService: FirestoreService, private dialog: MatDialog) { }

  onEditorValueChanged(value: string) {
    if (value == this.editorPlaceholder || value?.length == 0) {
      this.editableNote.content = null
    } else {
      this.editableNote.content = value
    }
    this.compiledMarkdown = this.compileMarkdown(value);
  }

  compileMarkdown(value: string): string {
    if (value == null) return ""
    return marked.parser(marked.lexer(value.replace(/\n/g, '<br>')));
  }

  onFocusChanged(focused: boolean) {
    if (!focused) {
      this.saveNote()
    }
  }

  saveNote() {
    if (this.editableNote.id == null) {
      this.editableNote.date = new Date()
      this.userService.insertNoteForUser(this.editableNote, this.user)
    } else {
      if ((this.editableNote.content == null || this.editableNote.content.length == 0) && this.editableNote.attachedFileName == null) {
        this.userService.deleteNoteForUser(this.editableNote, this.user)
      } else {
        this.userService.updateNoteForUser(this.editableNote, this.user)
      }
    }
  }
}
