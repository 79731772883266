import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductPurchase } from 'src/app/model/product-purchase.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { PaymentService } from 'src/app/services/payment.service';
import { Payment } from 'src/app/model/payment.model';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-paymentdetails-dialog',
  templateUrl: './paymentdetails-dialog.component.html',
  styleUrls: ['./paymentdetails-dialog.component.css']
})
export class PaymentDetailsDialogComponent {

  payment: Payment

  constructor(public dialogRef: MatDialogRef<PaymentDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {payment: Payment}, public userService: FirestoreService, private spinner: NgxSpinnerService, private dialog: MatDialog, private paymentService: PaymentService) {
    this.payment = data.payment
  }

  onRefundPayment() {
    console.log("Refunding payment")
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du den Betrag wirklich rückerstatten?', title: 'Betrag erstatten' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show()
        this.userService.refundPayment(this.payment, this.paymentService.stripeAccountId).then(() => {
          this.spinner.hide()
        })
      }
    });
  }
  onVoidPayment() {
    console.log("Voiding payment")
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du die Rechnung wirklich stornieren?', title: 'Rechnung stornieren' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show()
        this.userService.voidPayment(this.payment, this.paymentService.stripeAccountId).then(() => {
          this.spinner.hide()
        })
      }
    });
  }
  onReChargePayment() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du die Zahlung wirklich erneut einziehen?', title: 'Zahlung erneut einziehen' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show()
        this.userService.reChargePayment(this.payment, this.paymentService.stripeAccountId).then(() => {
          this.spinner.hide()
        })
      }
    });
  }
  onRecreateInvoice() {
    this.spinner.show()
    this.userService.recreateInvoicePdf(this.payment).then(() => {
      this.spinner.hide()
    })
  }
  onMarkPaymentAsPaid() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du die Zahlung wirklich als bezahlt markieren?', title: 'Zahlung als bezahlt markieren' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show()
        this.userService.markPaymentAsPaid(this.payment, this.paymentService.stripeAccountId).then(() => {
          this.spinner.hide()
        })
      }
    });
  }
  onRecreateCancellationInvoice() {
    console.log("Recreate cancellation invoice PDF")
    this.spinner.show()
    this.userService.recreateCancellationInvoicePdf(this.payment).then(() => {
      this.spinner.hide()
    })
  }

  onOpenInvoice() {
    this.spinner.show()
    this.userService.getDownloadUrl('payments/' + this.payment.id + '/' + (this.payment.invoiceFileName ?? ('Rechnung_' + this.payment.id + '.pdf'))).then(url => {
      this.spinner.hide()
      window.open(url, "_blank");
    })
  }

  onOpenCancellationInvoice() {
    this.spinner.show()
    this.userService.getDownloadUrl('payments/' + this.payment.id + '/' + (this.payment.cancellationInvoiceFileName ?? ('Rechnung_' + this.payment.id + '.pdf'))).then(url => {
      this.spinner.hide()
      window.open(url, "_blank");
    })
  }

  onCloseDialog() {
    this.closeDialog()
  }

  closeDialog() {
    this.dialogRef.close(null);
  }
}
