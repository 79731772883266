import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SelectableItem } from 'src/app/metrics-selection-dialog/metrics-selection-dialog.component';
import { Repetition, Repetition2LabelMapping } from 'src/app/model/automatic-push-notification.model';
import { EventTriggerType, EventTriggerType2LabelMapping, Questionaire, TriggerType } from 'src/app/model/questionaires.model';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-questionaire-selection-dialog',
  templateUrl: './questionaire-selection-dialog.component.html',
  styleUrls: ['./questionaire-selection-dialog.component.css']
})
export class QuestionaireSelectionDialogComponent implements OnInit {

  @Input() selectableQuestionaires: SelectableItem<Questionaire>[];
  
  public filteredSelectableQuestionaires: SelectableItem<Questionaire>[] = [];

  constructor(public dialogRef: MatDialogRef<QuestionaireSelectionDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {selectableQuestionaires: SelectableItem<Questionaire>[]}, public utilityService: UtilityService) {
    this.selectableQuestionaires = data.selectableQuestionaires;
    this.filteredSelectableQuestionaires = this.selectableQuestionaires;
  }

  ngOnInit(): void {
  }

  onSearchInputChanged(value: string){
    this.filteredSelectableQuestionaires = this.selectableQuestionaires.filter(x => x.Item.name.toLowerCase().includes(value.toLowerCase()) || x.Item.internalName.toLowerCase().includes(value.toLowerCase()));
  }

  onDeleteSearchInput(){
    this.filteredSelectableQuestionaires = this.selectableQuestionaires;
    (<HTMLInputElement> document.getElementById('search-input')).value = ''
  }

  onTakeSelection(){
    this.dialogRef.close({shouldTake: true});
  }

  onCancelSelection()
  {
    this.dialogRef.close();
  }

  getTriggerString(questionaire: Questionaire): string{
    if(TriggerType[questionaire.trigger] == TriggerType.event) {
      return EventTriggerType2LabelMapping[EventTriggerType[questionaire.eventTrigger]] || '';
    }
    else if(questionaire.trigger == TriggerType.recurring) {
      if(questionaire.repetition != Repetition.everyXDays) return Repetition2LabelMapping[Repetition[questionaire.repetition]]
      else if(questionaire.repetitionMultiplier) return "Alle " + questionaire.repetitionMultiplier + " Tage";
    }
    return ''
  }

  getRecipientsString(questionaire: Questionaire): string{
    let returnString = "";
    let maxLength = 30;
    if(questionaire.assignedGroupNames.length > 0){
      if(questionaire.assignedGroupNames.includes('Alle')){
        return "Alle";
      }
      questionaire.assignedGroupNames.forEach(group => {
        if(returnString.length > maxLength) return;
        returnString += group;
      });
    }
    if(questionaire.assignedUids.length > 0){
      questionaire.assignedUids.forEach(userUid => {
        if(returnString.length > maxLength) return;
        returnString += userUid;
      });
    }
  }

  selectQuestionaire(selectable: SelectableItem<Questionaire>){
    selectable.IsSelected = !selectable.IsSelected;
  }

}