import { User } from './../model/user.model';
import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../services/firestore.service';
// import { User as FirebaseUser } from 'firebase/auth';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { Chat } from '../model/chat.model';
import { ChatService } from '../services/chat.service';
import {User as FirebaseUser} from '@angular/fire/auth';

@Component({
  selector: 'app-profile-screen',
  templateUrl: './profile-screen.component.html',
  styleUrls: ['./profile-screen.component.css']
})
export class ProfileScreenComponent implements OnInit {

  public lidBool: boolean;
  public user: User;
  public authServiceUser: FirebaseUser;
  public uid: string;
  public chats = []

  constructor(public chatService: ChatService, private userService: FirestoreService, private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.user = this.userService.getLoggedInUser();
    this.authServiceUser = this.authService.user;
    this.uid = this.authService.user.uid;
    if (this.userService.getLoggedInUser().getLid() == "") {
      this.lidBool = false;
    } else {
      this.lidBool = true;
    }
    if (!this.authService.isLoggedIn) {
      this.router.navigate['login'];
    }
  }

  logout() {
    this.authService.logout();
    this.userService.logout();
  }

}
