import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NutritionPlanConfig } from '../model/nutritionplanconfig.model';
import { Moment } from 'moment';

@Component({
  selector: 'app-paste-plantemplate-dialog',
  templateUrl: './paste-plantemplate-dialog.component.html',
  styleUrls: ['./paste-plantemplate-dialog.component.css']
})
export class PastePlanTemplateDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PastePlanTemplateDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {startDate: Date}) {
    this.startDate = data.startDate
  }

  public startDate: Date

  ngOnInit(): void {
  }


  public today = new Date()
  startDateFilterNutritionPlanConfig = (d: Moment | null): boolean => {
    let lastMonday = new Date()
    lastMonday.setDate(this.today.getDate() - (this.today.getDay() + 6) % 7);
    if (d?.toDate() < lastMonday && !d?.toDate().isSameDate(lastMonday)) return false
    return true
  }

  onStartDateChanged(date: Date) {
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    this.startDate = date
  }

  onCancelDialog() {
    this.dialogRef.close(null);
  }
  onConfirmDialog() {
    this.dialogRef.close({startDate: this.startDate});
  }

}
