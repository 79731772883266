import { Component, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { Payment } from 'src/app/model/payment.model';
import { User } from 'src/app/model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { PaymentService } from 'src/app/services/payment.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-invoice-editor',
  templateUrl: './invoice-editor.component.html',
  styleUrls: ['./invoice-editor.component.css']
})
export class InvoiceEditorComponent {

  constructor(public dialogRef: MatDialogRef<InvoiceEditorComponent>, @Inject(MAT_DIALOG_DATA) private data: { }, public userService: FirestoreService, private paymentService: PaymentService, public utilityService: UtilityService, private dialog: MatDialog, private toastr: ToastrService, private ngZone: NgZone, private spinner: NgxSpinnerService) { 
    this.payment = new Payment()
  }

  public payment: Payment
  public assignedCustomer: User
  public productName: string = ''

  ngOnInit(): void {

  }

  get paymentSettings() {
    return this.paymentService.paymentSettings
  }

  onSelectCustomer(user: User) {
    this.assignedCustomer = user
  }
  getAvailableCustomers() {
    return this.userService.getAccessibleClients().filter(user => user.getStripeCustomerIdOfClient(this.paymentService.stripeAccountId) != null)
  }

  priceInvalid: boolean = false
  initialSetupFeeInvalid: boolean = false

  onAmountChanged(value: string) {
    var value = value?.replace(',', '.')
    var parts = value?.split('.')
    if (parts.length > 2 || parts.length > 1 && parts[1].length > 2) {
      this.priceInvalid = true
      return
    }
    var price = parseFloat(value)
    if (price != null && !isNaN(price) && price >= 0) {
      this.payment.amount = Math.round(price * 100)
      this.priceInvalid = false
    } else {
      this.priceInvalid = true
    }
  }

  async onSavePayment() {
    if (!this.assignedCustomer) {
      this.toastr.error('Bitte wähle einen Rechnungsempfänger aus.', '', {positionClass: 'toast-bottom-center'})
      return
    }
    if (!this.payment.amount || this.payment.amount <= 0 || !this.productName) {
      this.toastr.error('Bitte korrigiere den Betrag oder die Produktbezeichnung.', '', {positionClass: 'toast-bottom-center'})
      return
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Bist du sicher, dass du diese Rechnung erstellen und die Zahlung einziehen möchtest?', title: 'Rechnung erstellen', positiveButton: 'Ja', negativeButton: 'Nein' },
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result == true) {
        this.spinner.show()
        var res = await this.userService.createPayment(this.userService.getLoggedInUser().licenceHolderUid, this.paymentService.stripeAccountId, this.assignedCustomer.uid, this.payment.amount, this.payment.currency, [{name: this.productName, amount: this.payment.amount}])
        this.spinner.hide()
        this.onCloseDialog()
      }
    })
    
  }

  onCancelDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du die erstellte Rechnung wirklich verwerfen?', title: 'Rechnung verwerfen', positiveButton: 'Ja', negativeButton: 'Nein' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.onCloseDialog()
      }
    })
  }

  onCloseDialog(){
    this.dialogRef.close()
  }
}
