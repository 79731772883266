
<div class="dialog-topbar">
    <h3 class="display-inlineblock margin-0">Eigene Metrik <ng-container *ngIf="!customMetric.id">erstellen</ng-container><ng-container *ngIf="customMetric.id">bearbeiten</ng-container></h3>
    <div class="display-inlineblock marginleft-25">
        <app-language-selector></app-language-selector>
    </div>
    <div class="icon-button float-right" (click)="onCloseCustomMetricDialog()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
    </div>
</div>
<div class="dialog-content-wrapper">
    <div class="spacer-10"></div>
    Name der Metrik:<br>
    <div class="spacer-10"></div>
    <input [(ngModel)]="customMetric.nameTranslation[languageService.selectedLanguageCode]" class="input small wide {{customMetric.nameTranslation[languageService.selectedLanguageCode]?.length > 0 ? '' : 'invalid'}}" type="text" placeholder="Bspw. Laune"/>
    <div class="icon-button marginleft-25" tooltip="Name der Metrik, der auch beim Tracken in der App angezeigt wird.">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
        </svg>
    </div>
    <div class="spacer-25"></div>
    Beschreibung der Metrik (nur für dich):<br>
    <div class="spacer-10"></div>
    <input [(ngModel)]="customMetric.descriptionDe" class="input small wide" type="text" placeholder="Bspw. Laune von 1 bis 10"/>
    <div class="icon-button marginleft-25" tooltip="Bezeichung, die dir beim Suchen und Zuweisen einer Metrik angezeigt wird.">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
        </svg>
    </div>
    <div class="spacer-25"></div>
    Fragestellung (optional, für Nutzung in Check-Ins):<br>
    <div class="spacer-10"></div>
    <input [(ngModel)]="customMetric.questionTextTranslation[languageService.selectedLanguageCode]" class="input small wide" type="text" placeholder="Bspw.: Wie geht es dir?"/>
    <div class="icon-button marginleft-25" tooltip="Die Fragestellung wird Coachees angezeigt, wenn sie die Metrik in einem Checkin ausfüllen.">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
        </svg>
    </div>
    <div class="spacer-25"></div>
    <ng-container *ngIf="!customMetric.id">
        Typ:<br>
        <div class="spacer-10"></div>
        <div class="d-inline-block wrapper-metrictype-selection">
            <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownMetricType" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getDescriptionForMetricType(customMetric.metricType)}}</button>
            <div class="dropdown-menu" aria-labelledby="dropdownMetricType">
                <div *ngFor="let metricType of customMetricTypes">
                    <button class="dropdown-item" type="button" (click)="onCustomMetricTypeChanged(metricType.type)">{{metricType.description}}</button>
                </div>
            </div>
        </div>
        <div class="icon-button marginleft-25" tooltip="Typ der Metrik: Was soll zum Tracken eines Wertes eingegeben werden?">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
        </div>
        <div class="spacer-10"></div>
        <div *ngIf="customMetric.isMetricTypeNumber()" class="d-inline-block wrapper-datatype-selection">
            <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownDataType" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{customMetric.isDataTypeInteger() ? 'Ganzzahl' : 'Kommazahl'}}</button>
            <div class="dropdown-menu" aria-labelledby="dropdownDataType">
                <button class="dropdown-item" type="button" (click)="onCustomMetricDataTypeChanged('INTEGER')">Ganzzahl</button>
                <button class="dropdown-item" type="button" (click)="onCustomMetricDataTypeChanged('DOUBLE')">Kommazahl</button>
            </div>
        </div>
        <div class="spacer-25"></div>
        
    </ng-container>

    <div *ngIf="customMetric.isMetricTypeNumber()">
        Einheit des Wertes:<br>
        <div class="spacer-10"></div>
        <input [ngModel]="customMetric.unitTranslation[languageService.selectedLanguageCode]" (input)="onCustomMetricUnitChanged($event.target.value, languageService.selectedLanguageCode)" class="input small wide" type="text" placeholder="optional"/>
        <div class="icon-button marginleft-25" tooltip="Einheit des getrackten Wertes (optional). Wird an Wert (Zahl) angehängt.">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
        </div>
        <div class="spacer-25"></div>
        Erklärung der Werte/Hinweis:<br>
        <div class="spacer-10"></div>
        <input [ngModel]="customMetric.valueExplanationTranslation[languageService.selectedLanguageCode]" (input)="onCustomMetricValueExplanationChanged($event.target.value)" class="input small wide" type="text" placeholder="Bspw. 1 (schlecht) - 5 (gut) (optional)"/>
        <div class="icon-button marginleft-25" tooltip="Erklärungen zur Metrik und den zu trackenden Werten (optional).">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
        </div>
        <div class="spacer-25"></div>
    </div>
    <div *ngIf="customMetric.isMetricTypeNumber()" class="metric-valuerange-wrapper">
        Wertebereich:<br>
        <div class="spacer-10"></div>
        <input [ngModel]="customMetric.minValue" (input)="onCustomMetricMinValueChanged($event.target.value)" class="input small marginright-25 marginbottom-15 {{showCustomMetricMinValueError ? 'invalid' : ''}}" type="text" placeholder="Min. (optional)"/>
        <input [ngModel]="customMetric.maxValue" (input)="onCustomMetricMaxValueChanged($event.target.value)" class="input small marginbottom-15 {{showCustomMetricMaxValueError ? 'invalid' : ''}}" type="text" placeholder="Max. (optional)"/>
        <div class="icon-button marginleft-25 marginbottom-15" tooltip="Einschränkung der möglichen Werte, die getrackt werden können (optional).">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
        </div>
        <div class="spacer-10"></div>
        Zielwert:<br>
        <div class="spacer-10"></div>
        <input [ngModel]="customMetric.targetValue" (input)="onMetricTargetValueChanged($event.target.value, customMetric)" class="input small {{customMetric.hasTargetValueError ? 'invalid' : ''}}" type="text" placeholder="Ziel (optional)"/>
        <div class="icon-button marginleft-25" tooltip="Wert, der beim Tracken als Ziel angezeigt werden soll (optional).">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
        </div>
        <div class="spacer-25"></div>
    </div>

    <div *ngIf="customMetric.isMetricTypeText() || customMetric.isMetricTypeYesNo() || customMetric.isMetricTypeSelection() || customMetric.isMetricTypeDuration() || customMetric.isMetricTypeToDo() || customMetric.isMetricTypeMultiselect()">
        Erklärung/Hinweis:<br>
        <div class="spacer-10"></div>
        <input [ngModel]="customMetric.valueExplanationTranslation[languageService.selectedLanguageCode]" (input)="onCustomMetricValueExplanationChanged($event.target.value)" class="input small wide" type="text" placeholder="Bspw. Erläuterung zur Metrik (optional)"/>
        <div class="icon-button marginleft-25" tooltip="Erklärungen zur Metrik und Hinweise/Anweisungen (optional).">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
        </div>
        <div class="spacer-25"></div>
    </div>

    <ng-container *ngIf="!customMetric.id">
        <div *ngIf="customMetric.isMetricTypeSelection() || customMetric.isMetricTypeMultiselect()" class="metric-selectablevalues-wrapper">
            Auswählbare Werte (aufsteigend sortiert):
            <div class="icon-button marginleft-25" tooltip="Alle möglichen Werte, die getrackt werden können. Je höher die Nummer, desto größer erscheint der Wert auf der y-Achse im Graph (-> Wert mit höchster Nummer hat den größten Ausschlag).">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                </svg>
            </div><br>
            <div class="spacer-10"></div>
            <ng-container *ngFor="let index of createRange(getSelectableValues(languageService.selectedLanguageCode)?.length)">
                <div class="metric-selectablevalue-wrapper margin-10">
                    {{index + 1}}. <input class="input metric-selectablevalue small wide {{getSelectableValues(languageService.selectedLanguageCode)[index]?.length > 0 ? '' : 'invalid'}}" [ngModel]="getSelectableValues(languageService.selectedLanguageCode)[index]" (input)="onMetricSelectableValueChanged($event.target.value, index)" type="text" placeholder="Name des Werts"/>
                    <div class="icon-button marginleft-25" (click)="onDeleteSelectableValue(index)">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                </div>
            </ng-container>
            <div class="icon-button margin-10" (click)="onAddNewSelectableValue()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>
                Weiteren Wert hinzufügen
            </div>
            <div class="spacer-25"></div>
        </div>
        
        <div *ngIf="customMetric.isMetricTypeDuration()">
            Zielwert:<br>
            <div class="spacer-10"></div>
            <input [ngModel]="customMetric.getPrintableTargetValue()" (input)="onMetricTargetValueChanged($event.target.value, customMetric)" class="input small wide {{customMetric.hasTargetValueError ? 'invalid' : ''}}" type="text" placeholder="Ziel (bspw. 7:30) (optional)"/>
            h:min
            <div class="icon-button marginleft-25" tooltip="Wert, der beim Tracken als Ziel angezeigt werden soll (optional).">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                </svg>
            </div>
            <div class="spacer-25"></div>
        </div>
    </ng-container>

    <div *ngIf="customMetric.isMetricTypeImage()">
        Erklärung/Hinweis:<br>
        <div class="spacer-10"></div>
        <input [ngModel]="customMetric.valueExplanationTranslation[languageService.selectedLanguageCode]" (input)="onCustomMetricValueExplanationChanged($event.target.value)" class="input small wide" type="text" placeholder="Bspw. Bild von vorne aufnehmen (optional)"/>
        <div class="icon-button marginleft-25" tooltip="Erklärungen zur Metrik und Hinweise/Anweisungen (optional).">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
        </div>
        <div class="spacer-25"></div>
    </div>
        
    <div *ngIf="isCustomMetricValid()" class="icon-button" (click)="onSaveCustomMetric()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
        <ng-container *ngIf="!customMetric.id">Erstellen</ng-container>
        <ng-container *ngIf="customMetric.id">Speichern</ng-container>
    </div>
</div>