
<i *ngIf="isClosable" (click)="onClose()" class="bi bi-x-lg link questionaire-close-button"></i>
<div class="row">
    <h3 class="margin-0">{{editabledQuestionaireResult?.assignedQuestionaire.name}}</h3>
    <div class="toggle-wrapper icon-toggle d-flex float-right h-100">
        <div class="toggle margin-vertical-auto {{editMode ? 'on' : ''}}" (click)="switchEditMode()">
            <div class="handle"></div>
            <div class="toggle-icon left">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
                </svg>
            </div>
            <div class="toggle-icon right">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16">
                    <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                </svg>
            </div>
        </div>
    </div>
</div>
<div class="row">
    {{editabledQuestionaireResult?.assignedQuestionaire?.completionDate?.toLocaleString()}}
</div>
<div class="spacer-10"></div>
<div class="spacer-10"></div>
<div class="row">
    <ng-container *ngFor="let metricData of editabledQuestionaireResult?.metricData">
        <div class="col-12 metricdata-wrapper">
            <div class="margin-top-15px">
                <app-metricdata-view [user]="_user" [isQuestionaireMetricData]="true" [setMetricData]="metricData" [setEditMode]="editMode" (removeMetricData)="onRemoveMetricData($event, editabledQuestionaireResult)"></app-metricdata-view>
            </div>
        </div>
    </ng-container>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="spacer-25"></div>
            <div class="feedback-wrapper">
                Feedback geben:
                <div class="spacer-10"></div>
                <app-md-editor id="editor-note" class="multi-line"
                    (valueChanged)="onFeedbackChanged(editabledQuestionaireResult, $event)"
                    [inputValue]="editabledQuestionaireResult?.assignedQuestionaire?.feedback || ''"
                    [placeHolder]="''" [readOnlyMode]="!userService.getLoggedInUser()?.isCoach">
                </app-md-editor>
                <button *ngIf="hasFeedbackChanges && userService.getLoggedInUser()?.isCoach" class="btn-link button-sendfeedback" (click)="sendFeedback(editabledQuestionaireResult.assignedQuestionaire); $event.stopPropagation()">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </button>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="editMode">
        <button class="button-filled small save-changes-button" (click)="onSaveChanges()">Speichern</button>
    </div>