<div class="layout-box adjust-mobile">
    <h3>Trainingsstatistik</h3>
    <div class="d-inline-block wrapper-timerange-selection">
        <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownTimeRanges" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{selectedTimeRange.name}}</button>
        <div class="dropdown-menu" aria-labelledby="dropdownTimeRanges">
            <div *ngFor="let range of timeRanges">
                <button class="dropdown-item" type="button" (click)="onGraphTimeRangeChanged(range)">{{range.name}}</button>
            </div>
        </div>
    </div>
    <ng-container *ngIf="selectedTimeRange.id == 4">
        <div class="display-inlineblock">
            Von: 
            <span class="marginleft-5">{{selectedStartDate?.asFormatedString()}}</span>
            <input [matDatepicker]="startDatePicker" class="invisible-datetime-input" [value]="selectedStartDate" [matDatepickerFilter]="startDateFilterData" class="visibility-hidden width-0 padding-0" (dateInput)="onStartDateChanged($event.value?.toDate())">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker ></mat-datepicker><br>
        </div>
        <div class="display-inlineblock">
            Bis: 
            <span class="marginleft-5">{{selectedEndDate?.asFormatedString()}}</span>
            <input [matDatepicker]="endDatePicker" class="invisible-datetime-input" [value]="selectedEndDate" [matDatepickerFilter]="endDateFilterData" class="visibility-hidden width-0 padding-0" (dateInput)="onEndDateChanged($event.value?.toDate())">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker ></mat-datepicker>
        </div>
        <br>
    </ng-container>
    <div class="d-inline-block wrapper-bodydatatype-selection">
        <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownParameters" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getSelectedParameters()}}</button>
        <div class="dropdown-menu" aria-labelledby="dropdownParameters">
            <div *ngFor="let type of parameters">
                <button class="dropdown-item {{selectedParameters.includes(type) ? 'selected' : ''}}" type="button" (click)="onGraphParameterChanged(type)">
                    {{type.name}}
                    <svg *ngIf="selectedParameters.includes(type)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                    </svg>
                </button>
            </div>
        </div>
    </div>
    <div class="d-inline-block wrapper-timerange-selection" *ngIf="canSelectExercisesAndSessions()">
        <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownExercise" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getSelectedExercises()}}</button>
        <div class="dropdown-menu" aria-labelledby="dropdownExercise">
            <button class="dropdown-item {{selectedExercises?.length == 0 ? 'selected' : ''}}" type="button" (click)="onGraphExerciseChanged(null)">
                Alle Übungen
                <svg *ngIf="selectedExercises?.length == 0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
            </button>
            <div *ngFor="let exercise of exercises">
                <button class="dropdown-item {{selectedExercises.includes(exercise) ? 'selected' : ''}}" type="button" (click)="onGraphExerciseChanged(exercise)">
                    {{exercise.name?.GetValue('de')}} <span class="label">{{exercise.subName?.GetValue('de')}}</span>
                    <svg *ngIf="selectedExercises.includes(exercise)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                    </svg>
                </button>
            </div>
        </div>
    </div>
    <div class="d-inline-block wrapper-session-selection" *ngIf="canSelectExercisesAndSessions()">
        <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownSession" (click)="onOpenSessionSelection()" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getSelectedSessions()}}</button>
        <div class="dropdown-menu" aria-labelledby="dropdownSession">
            <button class="dropdown-item {{selectedSessions?.length == 0 ? 'selected' : ''}}" type="button" (click)="onGraphSessionChanged(null)">
                Alle Einheiten
                <svg *ngIf="selectedSessions?.length == 0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
            </button>
            <div *ngFor="let plan of trainingPlans">
                <div class="label">{{plan.name}}</div>
                <ng-container *ngFor="let session of getSelectableTrainingSessionsOfPlan(plan)">
                    <button class="dropdown-item {{selectedSessions.includes(session) ? 'selected' : ''}}" type="button" (click)="onGraphSessionChanged(session)">
                        {{session.name || 'Einheit'}}
                        <svg *ngIf="selectedSessions.includes(session)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                        </svg>
                    </button>
                </ng-container>
            </div>
            
        </div>
    </div>
    <div class="d-inline-block wrapper-session-selection" *ngIf="canSelectCustomCategories()">
        <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownSession" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getSelectedCustomCategories()}}</button>
        <div class="dropdown-menu" aria-labelledby="dropdownExercise">
            <button class="dropdown-item {{selectedCustomCategories?.length == 0 ? 'selected' : ''}}" type="button" (click)="onGraphCustomCategoryChanged(null)">
                Keine Kategorie anzeigen
                <svg *ngIf="selectedCustomCategories?.length == 0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
            </button>
            <div *ngFor="let customCategory of availableCustomCategories">
                <button class="dropdown-item {{selectedCustomCategories?.includes(customCategory) ? 'selected' : ''}}" type="button" (click)="onGraphCustomCategoryChanged(customCategory)">
                    {{customCategory.name}}
                    <svg *ngIf="selectedCustomCategories?.includes(customCategory)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                    </svg>
                </button>
            </div>
        </div>
    </div>
    <dx-chart *ngIf="graphData" palette="nutrilizePalette" [dataSource]="graphData" title="">

        <ng-container *ngFor="let graphItem of graphParams">
            <dxi-series *ngIf="graphItem.style != 'average'" valueField="{{graphItem.valueField}}" name="{{graphItem.name}}" type="{{graphType}}" axis="{{graphItem.axis || ''}}">
            </dxi-series>
            <dxi-series *ngIf="graphItem.style == 'average'" valueField="{{graphItem.valueField}}" name="{{graphItem.name}}" type="{{graphType}}" dashStyle="dash" color="#CCC">
                <dxo-point [visible]="true"></dxo-point>
            </dxi-series>
        </ng-container>

        <dxo-common-series-settings #seriesSettings argumentField="date" type="{{graphType}}">
            <dxo-point [visible]="true" [size]="9"></dxo-point>
        </dxo-common-series-settings>
        <dxo-margin [bottom]="20"></dxo-margin>
        <dxo-common-axis-settings>
            <dxo-grid [visible]="true" color="var(--darkestBackgroundColor)"></dxo-grid>
        </dxo-common-axis-settings>
        <dxo-legend
            verticalAlignment="{{utilityService.onMobile() ? 'bottom' : 'center'}}"
            horizontalAlignment="{{utilityService.onMobile() ? 'center' : 'right'}}"
            itemTextPosition="top"
            [customizeText]="customizeLegendText">
        </dxo-legend>
        <ng-container *ngFor="let axis of graphAxis; let i = index">
            <dxi-value-axis name="{{axis.name}}" [visualRange]="axis.visualRange" [tickInterval]="axis.tickInterval || undefined" [visible]="i <= 1" [position]="i == 0 ? 'left' : 'right'">
                <dxo-tick [visible]="i <= 1"></dxo-tick>
                <dxo-label [visible]="i <= 1" [customizeText]="i == 0 ? customizeLeftYAxis : customizeRightYAxis"></dxo-label>
            </dxi-value-axis>
        </ng-container>
        <dxo-argument-axis [allowDecimals]="false" [axisDivisionFactor]="60" >
            <dxo-tick-interval [days]="1"></dxo-tick-interval>
            <dxo-label>
                <dxo-format type="dd. MMM"></dxo-format>
            </dxo-label>
        </dxo-argument-axis>
        <dxo-export [enabled]="false"></dxo-export>
        <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip"></dxo-tooltip>
    </dx-chart>

    <app-training-graph-extension *ngIf="trainingSessionsForExerciseHistory" [User]="user" [TrackedTrainingSessions]="trainingSessionsForExerciseHistory" [TrainingExercise]="getExercisesForHistoryTable()"></app-training-graph-extension>
</div>