import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-macro-slider',
  templateUrl: './macro-slider.component.html',
  styleUrls: ['./macro-slider.component.css']
})
export class MacroSliderComponent {

  @Input() set initialDistribution(distribution: number[]) {
    var newDistribution = [distribution[0], distribution[0] + distribution[1]]
    if (newDistribution[0] != this._macroCalculatorSliderValues[0] || newDistribution[1] != this._macroCalculatorSliderValues[1]) {
      this._macroCalculatorSliderValues = newDistribution
    }
  }
  @Output() onDistributionChanged = new EventEmitter<number[]>();
  @Input() classes: string = ""

  public macroCalculatorCarbohydratesPercentage: number = 50
  public macroCalculatorProteinPercentage: number = 20
  public macroCalculatorFatPercentage: number = 30
  
  _macroCalculatorSliderValues = [45, 75]
  get macroCalculatorSliderValues() {
    return this._macroCalculatorSliderValues
  }
  set macroCalculatorSliderValues(value: number[]) {
    if (value[0] != this._macroCalculatorSliderValues[0] || value[1] != this._macroCalculatorSliderValues[1]) {
      this.onDistributionChanged.emit([value[0], value[1] - value[0], 100 - value[1]])
    }
    this._macroCalculatorSliderValues = value
  }
  macroCalculatorSliderConfig: any = {
    behaviour: 'drag',
    connect: [false, false, false],
    step: 1,
    range: {
      min: 0,
      max: 100
    },
  };


  getMacroCalculatorCarbohydratesPercentage() {
    return this.macroCalculatorSliderValues[0]
  }
  getMacroCalculatorProteinPercentage() {
    return this.macroCalculatorSliderValues[1] - this.macroCalculatorSliderValues[0]
  }
  getMacroCalculatorFatPercentage() {
    return 100- this.macroCalculatorSliderValues[1]
  }
  
  onCarbohydratePercentageChanged(text: string) {
    var regex = new RegExp("^[0-9]+$");
    if (regex.test(text) && parseInt(text) >= 0)  {
      var number = parseInt(text);
      this.macroCalculatorSliderValues = [number, this.macroCalculatorSliderValues[1]]
    }
  }
  onProteinPercentageChanged(text: string) {
    var regex = new RegExp("^[0-9]+$");
    if (regex.test(text) && parseInt(text) >= 0)  {
      var number = parseInt(text);
      this.macroCalculatorSliderValues = [this.macroCalculatorSliderValues[0], this.macroCalculatorSliderValues[0] + number]
    }
  }


  /*onMacroCalculatorPercentageChanged(type: string, text: string) {
    var regex = new RegExp("^[0-9]+$");
    if (regex.test(text) && parseInt(text) > 0)  {
      var number = parseInt(text);
      if (type == "C") {
        this.macroCalculatorCarbohydratesPercentage = number
      } else if (type == "P") {
        this.macroCalculatorProteinPercentage = number
      } else if (type == "F") {
        this.macroCalculatorFatPercentage = number
      }
    }
  }*/
}
