import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { User } from 'src/app/model/user.model';

@Component({
  selector: 'app-notes-dialog',
  templateUrl: './notes-dialog.component.html',
  styleUrls: ['./notes-dialog.component.css']
})
export class NotesDialogComponent {

  public user: User

  constructor(public dialogRef: MatDialogRef<NotesDialogComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) private data: {user: User }) {
    this.user = data.user
  }

  ngOnInit(): void {
  }

  onConfirmDialog() {
    this.dialogRef.close()
  }

}
