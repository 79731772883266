import { UtilityService } from 'src/app/services/utility.service';
import { NutritionalValuePopoverComponent } from './../nutritional-value-popover/nutritional-value-popover.component';
import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { BaseNutritionFact, NutritionalValueHolder } from '../model/basenutritionfact.model';
import { Ingredient } from '../model/ingredient.model';
import { NutritionalSummary } from '../model/nutritionalsummary.model';
import { PlannedFood } from '../model/plannedfood.model';
import { PlannedMeal } from '../model/plannedmeal.model';
import { Recipe } from '../model/recipe.model';
import { Serving } from '../model/serving.model';
import { NutritionService } from '../services/nutrition.service';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
const instantsearch = require('instantsearch.js').default;
import { searchBox, hits, pagination } from 'instantsearch.js/es/widgets';
import { HitsConnector } from 'instantsearch.js/es/connectors/hits/connectHits';
import {connectHits} from "instantsearch.js/es/connectors/index";
import { PlannedMealV2 } from '../model/plannedmealv2.model';
import { FirestoreService } from '../services/firestore.service';
import { ToastrService } from 'ngx-toastr';


const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: "Io7U5tHSaJfUWuCG0P1SrhOAPnLPgjQ3", // Be sure to use an API key that only allows search operations
    nodes: [
      {
        host: "oh8j5ed04lrti217p.a1.typesense.net",
        port: 443,
        protocol: "https",
      },
    ],
    cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  query_by is required.
  additionalSearchParameters: {
    query_by: "nameDe,brands,barcode",
    sort_by: "detailed:desc",
    per_page: 50,
  },
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

@Component({
  selector: 'app-ingredient-selector',
  templateUrl: './ingredient-selector.component.html',
  styleUrls: ['./ingredient-selector.component.css']
})
export class IngredientSelectorComponent implements AfterViewInit {
  @Input() mode: string;
  public INGREDIENT_MODE = 'INGREDIENT'
  public PLANNEDFOOD_MODE = 'PLANNED_FOOD'
  @Input() selectedFoodSearchSource: string;
  @Input() canUseAutoServing: boolean = false

  @Output() plannedFoodAdded = new EventEmitter<PlannedFood>();
  @Output() ingredientAdded = new EventEmitter<Ingredient>();
  @Output() plannedMealAdded = new EventEmitter<PlannedMealV2>();
  @Output() recipeForAutoServingSelected = new EventEmitter<Recipe>();

  public BaseNutritionFact = BaseNutritionFact

  public recipeSearchInput = '';
  public loadingNutritionalValues = false
  public focusedNutritionFact: BaseNutritionFact
  public filteredRecipeSearchResults: Recipe[];
  public selectedRecipe: Recipe;
  public selectedNutritionFact: BaseNutritionFact
  public selectedWeight: number
  public selectedNutritionFactServing: Serving
  public customNutritionFactServing: Serving
  public customNutritionFactServingName: string
  public selectedServingMultiplier: number
  public selectedRecipeMultiplier: number
  public recipeSearchFocused: boolean;
  public nutritionFactSearchFocused: boolean;
  public macroDummyNutritionalSummary: NutritionalSummary;
  public foodDummyNutritionalSummary: NutritionalSummary;
  public calorieDummyCalories: number;
  public calorieDummyName: string;
  public marcoDummyName: string;
  public foodDummyName: string;
  public foodDummyWeight: number;

  // public hits: any[] = [];


  searchConfig = instantsearch({
    indexName: 'BaseNutritionFacts',
    searchClient,
    queryType: 'prefixAll',
    removeWordsIfNoResults: 'lastWords',
    minWordSizefor2Typos: '7'
  });

  constructor(public nutritionService: NutritionService, public utilityService: UtilityService, private userService: FirestoreService, private toastr: ToastrService) {
    if(this.userService.getLoggedInUser().isCoach){
      this.filteredRecipeSearchResults = this.nutritionService.recipes
    }
    else {
      this.filteredRecipeSearchResults = this.nutritionService.clientRecipes
    }
    this.selectedWeight = 0
    this.selectedRecipeMultiplier = 1
    this.selectedServingMultiplier = 1
    this.macroDummyNutritionalSummary = new NutritionalSummary()
    this.foodDummyNutritionalSummary = new NutritionalSummary()
    this.foodDummyNutritionalSummary.calories = null
    this.calorieDummyCalories = 0
    this.marcoDummyName = ""
    this.foodDummyName = ""
    this.calorieDummyName = ""
    this.foodDummyWeight = 0
  }

  ngAfterViewInit() {
    if (this.selectedFoodSearchSource == 'NUTRITIONFACTS') {
      this.setSearchConfig();
    }
  }

  state: { hits: {}[] } = { hits: [] };
  setSearchConfig(){
    let searchBoxContainer = document.getElementById('searchbox')
    let paginationContainer = document.getElementById('pagination')

    if(!searchBoxContainer || !paginationContainer) return;
    let customHits = connectHits(this.updateState);
    this.searchConfig.addWidgets([
      searchBox({
          container: searchBoxContainer,
          placeholder: 'Lebensmittel suchen',
      }),
      customHits({}),
      pagination({
          container: paginationContainer,
      }),
    ]);

    this.searchConfig.start();
  }

  updateState = (state, isFirstRendering) => {
    if (isFirstRendering) {
      return Promise.resolve().then(() => {
        this.state = state;
      });
    }

    this.state = state;
  }

  renderFn(HitsRenderingOptions) {
    HitsRenderingOptions.widgetParams.subject.next(HitsRenderingOptions.hits);
  }

  onRecipeSearchFocused() {
    this.recipeSearchFocused = true;
    if (this.recipeSearchInput?.length == 0){
      if(this.userService.getLoggedInUser().isCoach){
        this.filteredRecipeSearchResults = this.nutritionService.recipes
      }
      else {
        this.filteredRecipeSearchResults = this.nutritionService.clientRecipes
      }
    }
  }

  onNutritionFactFocused(hit: any) {
    if (hit["id"] == this.focusedNutritionFact?.id) return;
    this.focusedNutritionFact = this.algoliaHitToBaseNutritionFact(hit)
    this.nutritionalValueHolder = this.focusedNutritionFact
  }
  public nutritionalValueHolder: NutritionalValueHolder
  onNutritionalValuesFocused(nutritionalValueHolder: NutritionalValueHolder) {
    this.nutritionalValueHolder = nutritionalValueHolder
  }
  algoliaHitToBaseNutritionFact(hit: any): BaseNutritionFact {
    var baseNutritionFact = new BaseNutritionFact((hit as any) as BaseNutritionFact);
    BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
      if (hit[nutritionalValue] != null && hit[nutritionalValue] != undefined) {
        baseNutritionFact.setNutritionalValue(nutritionalValue, parseFloat(hit[nutritionalValue]))
      }
    });
    return baseNutritionFact
  }

  onRecipeSearchInputChanged(text: string) {
    if (!text || text.length == 0) {
      if(this.userService.getLoggedInUser().isCoach){
        this.filteredRecipeSearchResults = this.nutritionService.recipes
      }
      else {
        this.filteredRecipeSearchResults = this.nutritionService.clientRecipes
      }
    } else {
      this.filteredRecipeSearchResults = []
      var tagSearch = text.includes('#') ? text.replace('#', '').toLowerCase() : null
      let recipes = this.userService.getLoggedInUser().isCoach ? this.nutritionService.recipes : this.nutritionService.clientRecipes;
      recipes.forEach(recipe => {
        if ((tagSearch == null && recipe.getName().toLowerCase().includes(text.toLowerCase()))) {
          this.filteredRecipeSearchResults.push(recipe)
        } else if (tagSearch != null) {
          var match = false
          recipe.getComputedTags().forEach(t => {
              if (t.printableIdentifier.toLowerCase().includes(tagSearch)) match = true
          })
          if (match) this.filteredRecipeSearchResults.push(recipe)
        }
      })
    }
    if (!this.selectedRecipe || text != this.selectedRecipe.getName()) {
      this.selectedRecipe = null
    }
    this.recipeSearchInput = text || ''
  }

  async onRecipeSelected(recipe: Recipe) {
    this.recipeSearchFocused = false;
    if (!recipe.loaded || !recipe.nutritionFactsLoaded || !recipe.baseRecipe?.nutritionFactsLoaded) {
      this.loadingNutritionalValues = true
      await this.nutritionService.loadFullRecipe(recipe)
      if (!recipe.calories) recipe.recalculateNutritionalValues()
      recipe.loaded = true
      this.loadingNutritionalValues = false
    }
    this.selectedRecipe = recipe.clone();
    (<HTMLInputElement> document.getElementById('recipesearch-input')).value = recipe.name
  }
  onDeleteRecipeSearch() {
    this.selectedRecipe = null;
    (<HTMLInputElement> document.getElementById('recipesearch-input')).value = ''
    this.recipeSearchFocused = false
    this.recipeSearchInput = ''
  }
  onDeleteNutritionFactSearch() {
    try{
      this.selectedNutritionFact = null;
      (<HTMLInputElement> document.getElementById('nutritionfact-search-box').getElementsByClassName('ais-SearchBox-input')[0]).value = ''
      this.nutritionFactSearchFocused = false
      this.selectedNutritionFactServing = null
      this.customNutritionFactServing = null
      this.customNutritionFactServingName = ''
  
      this.searchConfig.helper.setQuery('').search();
    }
    catch(ex){
      console.error(ex);
    }
  }
  onSelectSearchSource(source: string) {
    this.selectedFoodSearchSource = source
    if (source == 'NUTRITIONFACTS') {
      this.setSearchConfig();
    } else if (source == 'DUMMIES') {
      this.nutritionService.loadDummyNutritionFacts()
    }
  }

  public dummySearchResults: BaseNutritionFact[]
  public dummySearchFocused: boolean
  public dummySearchInput = '';

  onDummySearchInputChanged(text: string) {
    if (text?.length == 0) {
      this.dummySearchResults = this.nutritionService.dummyNutritionFacts
    } else {
      this.dummySearchResults = []
      this.nutritionService.dummyNutritionFacts.forEach(dummy => {
        if (dummy.getName().toLowerCase().includes(text.toLowerCase())) {
          this.dummySearchResults.push(dummy)
        }
      })
    }
  }

  onDummySearchFocused() {
    this.dummySearchFocused = true;
    if (this.dummySearchInput?.length == 0){
      this.dummySearchResults = this.nutritionService.dummyNutritionFacts
    }
  }
  onDummySelected(dummy: BaseNutritionFact) {
    this.foodDummyName = dummy.getName()
    this.marcoDummyName = dummy.getName()
    this.foodDummyNutritionalSummary = new NutritionalSummary()
    this.foodDummyNutritionalSummary.carbohydrates = dummy.carbohydrates
    this.foodDummyNutritionalSummary.protein = dummy.protein
    this.foodDummyNutritionalSummary.fat = dummy.fat
    this.foodDummyNutritionalSummary.calories = dummy.calories
    this.macroDummyNutritionalSummary = new NutritionalSummary()
    this.macroDummyNutritionalSummary.carbohydrates = dummy.carbohydrates
    this.macroDummyNutritionalSummary.protein = dummy.protein
    this.macroDummyNutritionalSummary.fat = dummy.fat
    this.macroDummyNutritionalSummary.calories = dummy.calories
    this.dummySearchFocused = false
  }
  onDeleteDummySearch() {
    (<HTMLInputElement> document.getElementById('dummysearch-input')).value = ''
    this.dummySearchFocused = false
    this.dummySearchInput = ''
  }

  onNutritionFactSearchClicked() {
    this.nutritionFactSearchFocused = true;
  }
  onNutritionFactSelected(hit: any) {
    this.nutritionFactSearchFocused = false;
    this.selectedNutritionFact = this.algoliaHitToBaseNutritionFact(hit);
    (<HTMLInputElement> document.getElementById('nutritionfact-search-box').getElementsByClassName('ais-SearchBox-input')[0]).value = this.selectedNutritionFact.nameDe
  }
  onSelectedWeightChanged(text: string) {
    var regex = new RegExp("^[0-9]+$");
    if (regex.test(text) && parseInt(text) > 0)  {
      var number = parseInt(text);
      this.selectedWeight = number
    } else {
      this.selectedWeight = 0
    }
  }
  onServingSelected(serving: Serving) {
    this.selectedNutritionFactServing = serving
  }
  unselectNutritionFactServing() {
    this.selectedNutritionFactServing = null
    this.customNutritionFactServing = null
    this.customNutritionFactServingName = ''
    this.selectedServingMultiplier = 1
  }
  onCreateCustomServing() {
    this.customNutritionFactServing = new Serving()
    this.customNutritionFactServingName = ''
    this.customNutritionFactServing.amount = 0
    this.customNutritionFactServing.identifier = this.customNutritionFactServing.IDENTIFIER_CUSTOM
  }
  onCustomServingNameChanged(text: string) {
    this.customNutritionFactServingName = text
  }
  onRecipeMultiplierChanged(text: string) {
    if (text) text = text.replace(',', '.')
    var regex = new RegExp("^[0-9,.]+$");
    if (regex.test(text) && parseFloat(text) > 0)  {
      var number = parseFloat(text);
      this.selectedRecipeMultiplier = number
    } else {
      this.selectedRecipeMultiplier = 0
    }
    if (this.macroDummyNutritionalSummary.isValid()) {
      this.macroDummyNutritionalSummary.calories = Math.round((this.macroDummyNutritionalSummary.carbohydrates + this.macroDummyNutritionalSummary.protein) * 4.1 + this.macroDummyNutritionalSummary.fat * 9.3)
    } else {
      this.macroDummyNutritionalSummary.calories = 0
    }
  }
  onServingMultiplierChanged(text: string) {
    if (text) text = text.replace(',', '.')
    var regex = new RegExp("^[0-9,.]+$");
    if (regex.test(text) && parseFloat(text) > 0)  {
      var number = parseFloat(text);
      this.selectedServingMultiplier = number
    } else {
      this.selectedServingMultiplier = 1
    }
    if (this.macroDummyNutritionalSummary.isValid()) {
      this.macroDummyNutritionalSummary.calories = Math.round((this.macroDummyNutritionalSummary.carbohydrates + this.macroDummyNutritionalSummary.protein) * 4.1 + this.macroDummyNutritionalSummary.fat * 9.3)
    } else {
      this.macroDummyNutritionalSummary.calories = 0
    }
  }
  onMacroDummyCarbohydratesChanged(text: string) {
    if (text) text = text.replace(',', '.')
    var regex = new RegExp("^[0-9,.]+$");
    if (text?.length > 0 && regex.test(text) && parseFloat(text)) {
      var number = parseFloat(text)
      this.macroDummyNutritionalSummary.carbohydrates = number
    } else {
      this.macroDummyNutritionalSummary.carbohydrates = 0
    }
    if (this.macroDummyNutritionalSummary.isValid()) {
      this.macroDummyNutritionalSummary.calories = Math.round((this.macroDummyNutritionalSummary.carbohydrates + this.macroDummyNutritionalSummary.protein) * 4.1 + this.macroDummyNutritionalSummary.fat * 9.3)
    } else {
      this.macroDummyNutritionalSummary.calories = 0
    }
  }
  onMacroDummyProteinChanged(text: string) {
    if (text) text = text.replace(',', '.')
    var regex = new RegExp("^[0-9,.]+$");
    if (text?.length > 0 && regex.test(text) && parseFloat(text)) {
      var number = parseFloat(text)
      this.macroDummyNutritionalSummary.protein = number
    } else {
      this.macroDummyNutritionalSummary.protein = 0
    }
    if (this.macroDummyNutritionalSummary.isValid()) {
      this.macroDummyNutritionalSummary.calories = Math.round((this.macroDummyNutritionalSummary.carbohydrates + this.macroDummyNutritionalSummary.protein) * 4.1 + this.macroDummyNutritionalSummary.fat * 9.3)
    } else {
      this.macroDummyNutritionalSummary.calories = 0
    }
  }
  onMacroDummyFatChanged(text: string) {
    if (text) text = text.replace(',', '.')
    var regex = new RegExp("^[0-9,.]+$");
    if (text?.length > 0 && regex.test(text) && parseFloat(text)) {
      var number = parseFloat(text)
      this.macroDummyNutritionalSummary.fat = number
    } else {
      this.macroDummyNutritionalSummary.fat = 0
    }
    if (this.macroDummyNutritionalSummary.isValid()) {
      this.macroDummyNutritionalSummary.calories = Math.round((this.macroDummyNutritionalSummary.carbohydrates + this.macroDummyNutritionalSummary.protein) * 4.1 + this.macroDummyNutritionalSummary.fat * 9.3)
    } else {
      this.macroDummyNutritionalSummary.calories = 0
    }
  }
  onCalorieDummyCaloriesChanged(text: string) {
    var regex = new RegExp("^[0-9]+$");
    if (regex.test(text) && parseInt(text) > 0)  {
      var number = parseInt(text);
      this.calorieDummyCalories = number
    } else {
      this.calorieDummyCalories = 0
    }
  }
  onFoodDummyCarbohydratesChanged(text: string) {
    if (text) text = text.replace(',', '.')
    var regex = new RegExp("^[0-9,.]+$");
    if (text?.length > 0 && regex.test(text) && parseFloat(text)) {
      var number = parseFloat(text)
      this.foodDummyNutritionalSummary.carbohydrates = number
    } else {
      this.foodDummyNutritionalSummary.carbohydrates = 0
    }
  }
  onFoodDummyProteinChanged(text: string) {
    if (text) text = text.replace(',', '.')
    var regex = new RegExp("^[0-9,.]+$");
    if (text?.length > 0 && regex.test(text) && parseFloat(text)) {
      var number = parseFloat(text)
      this.foodDummyNutritionalSummary.protein = number
    } else {
      this.foodDummyNutritionalSummary.protein = 0
    }
  }
  onFoodDummyFatChanged(text: string) {
    if (text) text = text.replace(',', '.')
    var regex = new RegExp("^[0-9,.]+$");
    if (text?.length > 0 && regex.test(text) && parseFloat(text)) {
      var number = parseFloat(text)
      this.foodDummyNutritionalSummary.fat = number
    } else {
      this.foodDummyNutritionalSummary.fat = 0
    }
  }
  onFoodDummyCaloriesChanged(text: string) {
    if (text) text = text.replace(',', '.')
    var regex = new RegExp("^[0-9,.]+$");
    if (text?.length > 0 && regex.test(text) && parseFloat(text)) {
      var number = parseFloat(text)
      this.foodDummyNutritionalSummary.calories = number
    } else {
      this.foodDummyNutritionalSummary.calories = 0
    }
  }
  onFoodDummyWeightChanged(text: string) {
    var regex = new RegExp("^[0-9]+$");
    if (regex.test(text) && parseInt(text) > 0)  {
      var number = parseInt(text);
      this.foodDummyWeight = number
    } else {
      this.foodDummyWeight = 0
    }
  }
  onCalorieDummyNameChanged(text: string) {
    this.calorieDummyName = text
  }
  onMacroDummyNameChanged(text: string) {
    this.marcoDummyName = text
  }
  onFoodDummyNameChanged(text: string) {
    this.foodDummyName = text
  }
  getFoodDummyCalories() {
    if (this.foodDummyNutritionalSummary && this.foodDummyNutritionalSummary.isValid(false)) {
      return Math.round((this.foodDummyNutritionalSummary.carbohydrates + this.foodDummyNutritionalSummary.protein) * 4.1 + this.foodDummyNutritionalSummary.fat * 9.3)
    }
    return 0
  }

  async onSaveFoodDummy() {
    if (this.foodDummyNutritionalSummary.calories == null) this.foodDummyNutritionalSummary.calories = this.getFoodDummyCalories()
    var nutritionFact = new BaseNutritionFact()
    nutritionFact.nameDe = this.foodDummyName
    nutritionFact.carbohydrates = this.foodDummyNutritionalSummary.carbohydrates
    nutritionFact.protein = this.foodDummyNutritionalSummary.protein
    nutritionFact.fat = this.foodDummyNutritionalSummary.fat
    nutritionFact.calories = this.foodDummyNutritionalSummary.calories
    await this.nutritionService.saveDummyNutritionFact(nutritionFact)
    this.toastr.success('Speichern erfolgreich.', "",  {positionClass: 'toast-bottom-center'});
  }
  async onSaveMacroDummy() {
    var nutritionFact = new BaseNutritionFact()
    nutritionFact.nameDe = this.marcoDummyName
    nutritionFact.carbohydrates = this.macroDummyNutritionalSummary.carbohydrates
    nutritionFact.protein = this.macroDummyNutritionalSummary.protein
    nutritionFact.fat = this.macroDummyNutritionalSummary.fat
    nutritionFact.calories = this.macroDummyNutritionalSummary.calories
    await this.nutritionService.saveDummyNutritionFact(nutritionFact)
    this.toastr.success('Speichern erfolgreich.', "",  {positionClass: 'toast-bottom-center'});
  }

  async onDeleteDummyNutritionFact(nutritionFact: BaseNutritionFact) {
    await this.nutritionService.deleteDummyNutritionFact(nutritionFact)
    this.dummySearchResults = this.dummySearchResults.filter(dummy => dummy.id != nutritionFact.id)
    this.toastr.success('Löschen erfolgreich.', "",  {positionClass: 'toast-bottom-center'});
  }

  onAddNutritionFactToMeal() {
    if (this.mode == this.INGREDIENT_MODE) {
      var ingredient = new Ingredient()
      ingredient.nutritionFact = this.selectedNutritionFact
      ingredient.nutritionFactId = this.selectedNutritionFact.id
      if (this.customNutritionFactServing) {
        ingredient.weight = this.selectedWeight
        ingredient.servingSize = 'CUSTOM\'' + this.customNutritionFactServingName.replace('\'', '').replace(':', '') + '\':' + this.selectedWeight + 'g'
      } else if (this.selectedNutritionFactServing) {
        ingredient.weight = this.selectedNutritionFactServing.amount * this.selectedServingMultiplier
        ingredient.servingSize = this.selectedNutritionFactServing.rawServing
      } else {
        ingredient.weight = this.selectedWeight
      }
      this.ingredientAdded.emit(ingredient)
    } else if (this.mode == this.PLANNEDFOOD_MODE) {
      var food = new PlannedFood()
      food.nutritionFactId = this.selectedNutritionFact.id
      food.nutritionFact = this.selectedNutritionFact
      if (this.selectedNutritionFact.unit) food.unit = this.selectedNutritionFact.unit
      if (this.customNutritionFactServing) {
        food.weight = this.selectedWeight
        food.servingSize = 'CUSTOM\'' + this.customNutritionFactServingName.replace('\'', '').replace(':', '') + '\':' + this.selectedWeight + 'g'
      } else if (this.selectedNutritionFactServing) {
        food.weight = this.selectedNutritionFactServing.amount * this.selectedServingMultiplier
        food.servingSize = this.selectedNutritionFactServing.rawServing
      } else {
        food.weight = this.selectedWeight
      }
      this.plannedFoodAdded.emit(food)
    }
    this.selectedNutritionFact = null;
    this.selectedWeight = 0;
    this.selectedNutritionFactServing = null
    this.customNutritionFactServing = null
    this.customNutritionFactServingName = ''
    this.selectedRecipeMultiplier = 1
    this.nutritionFactSearchFocused = false
    this.selectedServingMultiplier = 1;
    (<HTMLInputElement> document.getElementById('nutritionfact-search-box').getElementsByClassName('ais-SearchBox-input')[0]).value = ''
    this.onDeleteNutritionFactSearch();
  }

  onAddRecipeToMeal() {
    if (this.mode == this.PLANNEDFOOD_MODE) {
      var plannedMeal = new PlannedMealV2()
      plannedMeal.baseRecipe = this.selectedRecipe
      plannedMeal.baseMealTemplateId = this.selectedRecipe.id
      plannedMeal.baseMealTemplateServingMultiplier = this.selectedRecipeMultiplier
      /*
      plannedMeal.name = this.selectedRecipe.name
      if (!plannedMeal.mealTemplateId) plannedMeal.mealTemplateId = this.selectedRecipe.id
      if (!plannedMeal.imageURL) plannedMeal.imageURL = this.selectedRecipe.thumbnailURL
      plannedMeal.thumbnailPath = this.selectedRecipe.getThumbnailPath() ?? null
      plannedMeal.instructions = plannedMeal.instructions ? plannedMeal.instructions + '  ' + (this.selectedRecipe.instructions || '') : this.selectedRecipe.instructions
      */
      var foods = []
      //var firstFood: PlannedFood
      this.selectedRecipe.getIngredients().forEach(ingredient => {
        var food = new PlannedFood()
        food.nutritionFactId = ingredient.nutritionFactId
        food.nutritionFact = ingredient.nutritionFact
        food.servingSize = ingredient.servingSize
        food.name = ingredient.name
        food.isDummy = ingredient.isDummy
        food.groupHeading = ingredient.groupHeading
        if (ingredient.isDummy) {
          food.weight = ingredient.weight
          if (food.weight) food.weight = (ingredient.weight / this.selectedRecipe.getServings() * this.selectedRecipeMultiplier).roundToPlaces(0)
          food.calories = (ingredient.nutritionalSummary.calories / this.selectedRecipe.getServings() * this.selectedRecipeMultiplier).roundToPlaces(0)
          food.carbohydrates = (ingredient.nutritionalSummary.carbohydrates / this.selectedRecipe.getServings() * this.selectedRecipeMultiplier).roundToPlaces(0)
          food.protein = (ingredient.nutritionalSummary.protein / this.selectedRecipe.getServings() * this.selectedRecipeMultiplier).roundToPlaces(0)
          food.fat = (ingredient.nutritionalSummary.fat / this.selectedRecipe.getServings() * this.selectedRecipeMultiplier).roundToPlaces(0)
        } else {
          food.weight = (ingredient.weight / this.selectedRecipe.getServings() * this.selectedRecipeMultiplier).roundToPlaces(0)
        }
        foods.push(food)
        //if (!firstFood) firstFood = food
      })
      //if (firstFood) firstFood.groupHeading = this.selectedRecipe.getName()
      if (plannedMeal.baseMealTemplateId) {
        plannedMeal.baseRecipeFoods = foods
      } else {
        plannedMeal.foods = foods
      }
      this.plannedMealAdded.emit(plannedMeal)
    }
    this.selectedRecipe = null
    this.selectedWeight = 0
    this.selectedRecipeMultiplier = 1
    this.recipeSearchFocused = false
    this.recipeSearchInput = ''
    this.selectedServingMultiplier = 1;
    (<HTMLInputElement> document.getElementById('recipesearch-input')).value = ''
  }

  onAdjustAndAddRecipeToMeal() {
    if (this.mode == this.PLANNEDFOOD_MODE) {
      this.recipeForAutoServingSelected.emit(this.selectedRecipe)
    }
    this.selectedRecipe = null
    this.selectedWeight = 0
    this.selectedRecipeMultiplier = 1
    this.recipeSearchFocused = false
    this.recipeSearchInput = ''
    this.selectedServingMultiplier = 1;
    (<HTMLInputElement> document.getElementById('recipesearch-input')).value = ''
  }

  addCalorieDummyToMeal() {
    if (this.mode == this.INGREDIENT_MODE) {
      var ingredient = new Ingredient()
      ingredient.weight = null
      ingredient.nutritionFactId = null
      ingredient.nutritionalSummary = new NutritionalSummary()
      ingredient.nutritionalSummary.carbohydrates = null
      ingredient.nutritionalSummary.protein = null
      ingredient.nutritionalSummary.fat = null
      ingredient.nutritionalSummary.calories = this.calorieDummyCalories
      ingredient.isDummy = true
      if (this.calorieDummyName?.length > 0) ingredient.name = this.calorieDummyName
      this.ingredientAdded.emit(ingredient)
    } else if (this.mode == this.PLANNEDFOOD_MODE) {
      var food = new PlannedFood()
      food.nutritionFactId = null
      food.nutritionFact = null
      food.weight = null
      food.calories = this.calorieDummyCalories
      food.isDummy = true
      if (this.calorieDummyName?.length > 0) food.name = this.calorieDummyName
      this.plannedFoodAdded.emit(food)
      this.calorieDummyCalories = 0
      this.calorieDummyName = ""
    }
  }
  addMacroDummyToMeal() {
    if (this.mode == this.INGREDIENT_MODE) {
      var ingredient = new Ingredient()
      ingredient.weight = null
      ingredient.nutritionFactId = null
      ingredient.nutritionalSummary = new NutritionalSummary()
      ingredient.nutritionalSummary.carbohydrates = this.macroDummyNutritionalSummary.carbohydrates
      ingredient.nutritionalSummary.protein = this.macroDummyNutritionalSummary.protein
      ingredient.nutritionalSummary.fat = this.macroDummyNutritionalSummary.fat
      ingredient.nutritionalSummary.calories = this.macroDummyNutritionalSummary.calories
      ingredient.isDummy = true
      if (this.marcoDummyName?.length > 0) ingredient.name = this.marcoDummyName
      this.ingredientAdded.emit(ingredient)
    } else if (this.mode == this.PLANNEDFOOD_MODE) {
      var food = new PlannedFood()
      food.nutritionFactId = null
      food.nutritionFact = null
      food.weight = null
      food.carbohydrates = this.macroDummyNutritionalSummary.carbohydrates
      food.protein = this.macroDummyNutritionalSummary.protein
      food.fat = this.macroDummyNutritionalSummary.fat
      food.calories = this.macroDummyNutritionalSummary.calories
      food.isDummy = true
      if (this.marcoDummyName?.length > 0) food.name = this.marcoDummyName
      this.plannedFoodAdded.emit(food)
      this.macroDummyNutritionalSummary = new NutritionalSummary()
      this.marcoDummyName = ""
    }
  }
  addFoodDummyToMeal() {
    if (this.foodDummyNutritionalSummary.calories == null) this.foodDummyNutritionalSummary.calories = this.getFoodDummyCalories()
    if (this.mode == this.INGREDIENT_MODE) {
      var ingredient = new Ingredient()
      ingredient.weight = this.foodDummyWeight
      ingredient.nutritionFactId = null
      ingredient.nutritionalSummary = new NutritionalSummary()
      ingredient.nutritionalSummary.carbohydrates = this.foodDummyNutritionalSummary.carbohydrates * this.foodDummyWeight / 100
      ingredient.nutritionalSummary.protein = this.foodDummyNutritionalSummary.protein * this.foodDummyWeight / 100
      ingredient.nutritionalSummary.fat = this.foodDummyNutritionalSummary.fat * this.foodDummyWeight / 100
      ingredient.nutritionalSummary.calories = this.foodDummyNutritionalSummary.calories * this.foodDummyWeight / 100
      ingredient.isDummy = true
      if (this.foodDummyName?.length > 0) ingredient.name = this.foodDummyName
      this.ingredientAdded.emit(ingredient)
    } else if (this.mode == this.PLANNEDFOOD_MODE) {
      var food = new PlannedFood()
      food.nutritionFactId = null
      food.nutritionFact = null
      food.weight = this.foodDummyWeight
      food.carbohydrates = this.foodDummyNutritionalSummary.carbohydrates * this.foodDummyWeight / 100
      food.protein = this.foodDummyNutritionalSummary.protein * this.foodDummyWeight / 100
      food.fat = this.foodDummyNutritionalSummary.fat * this.foodDummyWeight / 100
      food.calories = this.foodDummyNutritionalSummary.calories * this.foodDummyWeight / 100
      food.isDummy = true
      if (this.foodDummyName?.length > 0) food.name = this.foodDummyName
      this.plannedFoodAdded.emit(food)
      this.foodDummyNutritionalSummary = new NutritionalSummary()
      this.foodDummyName = ""
      this.foodDummyWeight = 0
      this.foodDummyNutritionalSummary.calories = null
    }
  }
}
function sendEvent(hit: any) {
  console.log(hit)
}