import { Component, Input, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { PaymentSettings } from 'src/app/model/payment-settings.model';
import { ProductPurchase } from 'src/app/model/product-purchase.model';
import { Product } from 'src/app/model/product.model';
import { User } from 'src/app/model/user.model';
import { ChatService } from 'src/app/services/chat.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { environment } from 'src/environments/environment';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Title } from '@angular/platform-browser';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.css']
})
export class ProductViewComponent {

  @Input() set setProduct(value: Product) {
    this.product = value
  }
  @Input() set setPaymentSettings(value: PaymentSettings) {
    this.paymentSettings = value
  }

  public environment = environment

  public productId: string
  public product: Product
  public paymentSettings: PaymentSettings

  constructor(private router: Router, public paymentService: PaymentService, public userService: FirestoreService, private authService: AuthService, private chatService: ChatService, private route: ActivatedRoute, private ngZone: NgZone, public dialog: MatDialog, private spinner: NgxSpinnerService, private titleService: Title) {
  
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(async (param) => {
      var id = param.get('id')
      if (id?.includes('?')) {
        id = id.split('?')[0]
      }
      if (id?.includes('%')) {
        id = id.split('%')[0]
      }
      this.productId = id
      this.loadProduct()
    })
  }

  isProductPageEnabled() {
    return this.product && (this.product.productPageEnabled || this.product.showOnLandingPage)
  }

  isProductAvailable() {
    return this.product && !this.product.deleted
  }

  async loadProduct() {
    if (this.productId) {
      this.product = await this.userService.getProduct(this.productId)
      if (this.product.thumbnailPath) {
        this.userService.getDownloadUrl(this.product.getFullThumbnailPath(this.product.licenceHolderUid)).then(url => this.product.thumbnailUrl = url)
      }
      await this.loadPaymentSettings()
    }
  }

  async loadPaymentSettings() {
    this.paymentSettings = await this.userService.getPaymentSettings(this.product.licenceHolderUid)
    this.titleService.setTitle(this.paymentSettings?.companyName ?? "Meine Landing-Page")
    if (this.paymentSettings?.termsAndConditionsPath) {
      this.paymentSettings.termsAndConditionsUrl = await this.userService.getDownloadUrl("licence_holders/" + this.product.licenceHolderUid + "/settings/payment/" + this.paymentSettings.termsAndConditionsPath)
    }
    if (this.paymentSettings?.customAccentColor) {
      document.documentElement.style.setProperty('--accentColor', this.paymentSettings.customAccentColor);
      document.documentElement.style.setProperty('--accentColorHover', this.paymentSettings.customAccentColor + 'aa');
      document.documentElement.style.setProperty('--textOnAccentColor', this.paymentSettings.customOnAccentTextColor);
    }
  }
}
