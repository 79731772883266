<!-- <audio preload="auto" controls controlslist="nodownload noremoteplayback noplaybackrate">
    <source src="{{src}}">
    Your browser does not support the audio element.
</audio> -->
<div class="row" [attr.disabled]="isDisabled ? true : null">
    <div class="container">
        <div class="row">
            <div class="col-3">
                <button (click)="togglePlay()" class="play-button" [disabled]="isDisabled">
                    <svg *ngIf="!playing" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill="var(--accentColor)" class="bi bi-play-fill" viewBox="0 0 16 16">
                        <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                    </svg>
                    <!-- <svg *ngIf="!playing" xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" fill="#4AE6D730" class="bi bi-play-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z"/>
                      </svg> -->
                    <svg *ngIf="playing" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill="var(--accentColor)" class="bi bi-pause-fill" viewBox="0 0 16 16">
                        <path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z"/>
                    </svg>
                </button>
            </div>
            <div class="col-6 range-container">
                <input type="range" step="0.001" [disabled]="isDisabled" class="slider" id="seek-slider" max="{{seekMax}}" value="{{currentTime}}" #seekRef (input)="changeSeek(seekRef.value)">
            </div>
            <div class="col-3 playback-rate-container">
                <button *ngIf="playing" class="playback-rate-button" (click)="changePlaybackRate()">
                    {{playbackRate}}x
                </button>
            </div>
        </div>
    </div>

    </div>
