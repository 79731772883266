import { CardioZone, CardioZoneGroup } from "./cardio-zone-group.model";
import { TrainingVariable } from "./training-variables.model";

export class TrainingSettingsLicenceHolder {
    public trainingVariables: TrainingVariable[] = [];
    public cardioZones: CardioZone[] = [];

    constructor();
    constructor(init: TrainingSettingsLicenceHolder);
    constructor(init?: TrainingSettingsLicenceHolder) {
        this.trainingVariables = init && init.trainingVariables?.map(x => new TrainingVariable(x)) || [];
        this.cardioZones = init && init.cardioZones?.map(x => new CardioZone(x)) || CardioZoneGroup.getDefaultCardioZones();
    }
}