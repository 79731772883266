<div class="page-content">
    <div class="container-fluid">
        <h2 class="">Dein Profil</h2>
        <div class="row align-items-left"style="margin-top: 2%;">
            <div class="col-sm">
                Name: {{user.name}}
            </div>
        </div>
        <div class="row align-items-left" style="margin-bottom: 2%;">
            <div class="col-sm">
                Account-Email: {{authServiceUser.email}}
            </div>
        </div>
        <div class="row align-items-left">
            <div class="col-sm">
                Körpergröße: {{user.bodyHeight}}
            </div>
        </div>
        <div class="row align-items-left">
            <div class="col-sm">
                Körpergewicht: {{user.bodyWeight}}
            </div>
        </div>
        <div class="row align-items-left">
            <div class="col-sm">
                Alter: {{user.age}}
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col">
                <chat>
                </chat>
            </div>
        </div>
    </div>
</div>
