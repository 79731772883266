import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { MergedTrainingExercise, TrainingExercise } from 'src/app/model/training-exercise';
import { Frequency2LabelMapping, Label2FrequencyMapping, Label2NumberOfRoundsMapping, Label2SetParameterMapping, NumberOfRounds, NumberOfRounds2LabelMapping, PlannedTrainingExercise, SetParameter, SetParameter2LabelMapping, SetParameter2LabelUnitMapping, SetParameter2SubHeadingMapping, SetParameter2UnitMapping, SuperSet, SuperSetConfig, TrainingPlan, TrainingSession, TrainingSet } from 'src/app/model/training-plan.model';
import { TrainingVariable } from 'src/app/model/training-variables.model';
import { User } from 'src/app/model/user.model';
import { LanguageService } from 'src/app/services/language.service';
import { TrainingService } from 'src/app/services/training.service';
import { SessionTemplatesDialogComponent } from 'src/app/training/session-templates-dialog/session-templates-dialog.component';
import { SingleExerciseComponent } from 'src/app/training/single-exercise/single-exercise.component';
import { TrainingPlanEditorComponent } from '../../training-plan-editor.component';
import { FirestoreService } from 'src/app/services/firestore.service';
import { TrackedTrainingExercise, TrackedTrainingSession, TrackedTrainingSet } from 'src/app/model/training-monitoring.model';
import { ToastrService } from 'ngx-toastr';
import { CardioZone, CardioZoneGroup } from 'src/app/model/cardio-zone-group.model';
import { MatMenuTrigger } from '@angular/material/menu';
import { NumberToShortWeekDayStringsMapping } from 'src/app/model/task.model';
import { UtilityService } from 'src/app/services/utility.service';
import { LanguageDictionary } from 'src/app/model/languagedictionary.model';
import { TrainingPlanEditorHelper } from '../../utilities/training-plan-editor-helper';
import { ExerciseHistoryDialogComponent } from 'src/app/training-monitoring/exercise-history-dialog/exercise-history-dialog.component';
import { TrainingSessionContainer } from 'src/app/training-monitoring/training-history-dialog/training-history-dialog.component';
import { QuestionaireResult } from 'src/app/model/questionaires.model';
import { environment } from 'src/environments/environment';
import { format } from 'path';
import { RpeTableDialogComponent } from 'src/app/training/rpe-table-dialog/rpe-table-dialog.component';
import { WeightConversionPipe } from 'src/app/weight.pipe';
import { UnitConversionPipe } from 'src/app/unit.pipe';

@Component({
  selector: 'app-training-session-editor',
  templateUrl: './training-session-editor.component.html',
  styleUrls: ['./training-session-editor.component.css']
})
export class TrainingSessionEditorComponent {

  public environment = environment

  public NumberToShortWeekDayStringsMapping = NumberToShortWeekDayStringsMapping;
  public trainingPlanEditorHelper: TrainingPlanEditorHelper;

  public setParameter = SetParameter;
  public setParameter2LabelMapping = SetParameter2LabelMapping;
  public label2SetParameterMapping = Label2SetParameterMapping;
  public setParameter2UnitMapping = SetParameter2UnitMapping;
  public setParameter2LabelUnitMapping = SetParameter2LabelUnitMapping;
  public setParameter2SubHeadingMapping = SetParameter2SubHeadingMapping;
  public hasSetParameterFormulaInput = TrainingPlanEditorComponent.hasSetParameterFormulaInput;
  public frequencyLabels = Object.values(Frequency2LabelMapping).filter(value => typeof value === 'string');
  public frequency2LabelMapping = Frequency2LabelMapping;
  public label2FrequencyMapping = Label2FrequencyMapping;
  public numberOfRounds2LabelMapping = NumberOfRounds2LabelMapping;
  public label2NumberOfRoundsMapping = Label2NumberOfRoundsMapping;
  public NumberOfRounds = NumberOfRounds
  public numberOfRoundsLabels = Object.values(NumberOfRounds2LabelMapping).filter(value => typeof value === 'string');

  public oneRmTrainingVariables: TrainingVariable[] = [];
  public oneRmGoalTrainingVariables: TrainingVariable[] = [];
  public getOneRmTrainingVariables(): TrainingVariable[] {
    return this.getAllAvailableTrainingVariables().filter(x => x.id.startsWith('ONERM_') && x.weight != null)
  }
  public getOneRmGoalTrainingVariables(): TrainingVariable[] {
    return this.getAllAvailableTrainingVariables().filter(x => x.id.startsWith('GOAL1RM_') && x.weight != null)
  }

  updateOneRmTrainingVariables() {
    this.oneRmTrainingVariables = this.getOneRmTrainingVariables()
    this.oneRmGoalTrainingVariables = this.getOneRmGoalTrainingVariables()
  }

  constructor(public trainingService: TrainingService, public languageService: LanguageService, private spinner: NgxSpinnerService, public dialog: MatDialog, public userService: FirestoreService, private toastr: ToastrService, public utilityService: UtilityService, private weightPipe: WeightConversionPipe, private unitPipe: UnitConversionPipe) {
    this.trainingPlanEditorHelper = new TrainingPlanEditorHelper(trainingService, dialog, spinner, userService, toastr);
  }

  async ngOnInit(): Promise<void> {
    this.initDummySessionForXRMCalculationForSession(this.selectedSession);
    this.setLatestDateWithSessionHistory(this.selectedSession);
    this.setTrainingSessionContainerList();
  }

  public questionaireResults: QuestionaireResult[] = [];
  public trainingSessionContainerList: TrainingSessionContainer[] = [];
  setTrainingSessionContainerList(){
    this.questionaireResults = [];
    this.trainingSessionContainerList = [];
    let trackedTrainingSession = this.selectedSession?.trackedTrainingSession;
    if(this.trainingSessionContainerList?.length == 0){
      this.trainingSessionContainerList = [];
      this.questionaireResults = this.user?.questionaireResults?.filter(x => x.assignedQuestionaire?.trackedSessionId == trackedTrainingSession?.id);
      this.trainingSessionContainerList.push(new TrainingSessionContainer(trackedTrainingSession, this.selectedSession, this.questionaireResults));
    }
  }
  private _selectedSession: TrainingSession;
  @Input({ required: true }) set selectedSession(TrainingSession) {
    this._selectedSession = TrainingSession;
    this.initDummySessionForXRMCalculationForSession(this.selectedSession);
    this.setLatestDateWithSessionHistory(this.selectedSession);
    this.setTrainingSessionContainerList();
  }
  public get selectedSession(): TrainingSession {
    return this._selectedSession;
  }
  @Input({ required: true }) hasChanges: boolean;
  @Output() hasChangesChange = new EventEmitter<boolean>();

  public get hasChangesInternal(): boolean {
    return this.hasChanges;
  }
  public set hasChangesInternal(value: boolean) {
    this.hasChanges = value;
    this.hasChangesChange.emit(value);
  }

  @Input({ required: true }) copyExercise: PlannedTrainingExercise;
  @Output() copyExerciseChange = new EventEmitter<PlannedTrainingExercise>();

  public get copyExerciseInternal(): PlannedTrainingExercise {
    return this.copyExercise;
  }
  public set copyExerciseInternal(value: PlannedTrainingExercise) {
    this.copyExercise = value;
    this.copyExerciseChange.emit(value);
  }

  @Input({ required: true }) copyExercisesList: PlannedTrainingExercise[];
  @Output() copyExercisesListChange = new EventEmitter<PlannedTrainingExercise[]>();

  public get copyExercisesListInternal(): PlannedTrainingExercise[] {
    return this.copyExercisesList;
  }
  public set copyExercisesListInternal(value: PlannedTrainingExercise[]) {
    this.copyExercisesList = value;
    this.copyExercisesListChange.emit(value);
  }



  @Input({ required: true }) expandedExercise: PlannedTrainingExercise;
  @Output() expandedExerciseChange = new EventEmitter<PlannedTrainingExercise>();

  public get expandedExerciseInternal(): PlannedTrainingExercise {
    return this.expandedExercise;
  }
  public set expandedExerciseInternal(value: PlannedTrainingExercise) {
    this.expandedExercise = value;
    this.expandedExerciseChange.emit(value);
  }

  @Output() closeSessionsColumn = new EventEmitter<void>();

  @Input({ required: true }) readOnlyMode: boolean;

  @Input({ required: true }) trainingPlan: TrainingPlan;
  @Input({ required: true }) user: User;

  @Output() openTrainingVariablesDialog = new EventEmitter<void>();

  @Input({ required: true }) availableTrainingVariables: TrainingVariable[] = [];

  @Input({ required: true }) userCardioZoneGroups: CardioZoneGroup[] = [];
  @Input({ required: true }) globalCardioZones: CardioZone[] = [];

  @Output() showVariableNotAvailableDialog = new EventEmitter<void>();

  @Input({ required: true }) selectedCoach: User;

  private _trackedTrainingSessions: TrackedTrainingSession[];
  @Input({ required: true }) set trackedTrainingSessions(value: TrackedTrainingSession[]) {
    this._trackedTrainingSessions = value;
    this.setLatestDateWithSessionHistory(this.selectedSession);
    this.setTrainingSessionContainerList();
  }
  public get trackedTrainingSessions(): TrackedTrainingSession[] {
    return this._trackedTrainingSessions;
  }

  @Input() headerAreaEnabled: boolean = true;


  public trainingPlanHistoryCollapsed: boolean = true;
  public historyAlwaysOpen: boolean = this.environment.firebaseProjectId == 'traindoo-app'

  public setParameter2ValueStepMapping: Record<SetParameter, number> = {
    [SetParameter.weight]: 0.01,
    [SetParameter.reps]: 1,
    [SetParameter.time]: 1,
    [SetParameter.rir]: 0.1,
    [SetParameter.rpe]: 0.1,
    [SetParameter.pace]: 1,
    [SetParameter.distance]: 0.01,
    [SetParameter.timeUnderTension]: 1,
    [SetParameter.calories]: 0.01,
    [SetParameter.caloriesPerHour]: 0.01,
    [SetParameter.rpm]: 0.01,
    [SetParameter.watts]: 0.01,
    [SetParameter.speed]: 0.01,
    [SetParameter.heartRate]: 1,
    [SetParameter.note]: null,
    [SetParameter.pace500]: 1,
    [SetParameter.pauseDuration]: 1,
  };


  public hoveredExercise: PlannedTrainingExercise = null
  public hoveredInputParameter: SetParameter
  mouseEnterExercise(exercise: PlannedTrainingExercise) {
    this.hoveredExercise = exercise;
  }

  public draggingGroup: LanguageDictionary<string> = null;
  public draggingGroupSession: TrainingSession = null;

  isExerciseExpanded(exercise: PlannedTrainingExercise) {
    if (environment.firebaseProjectId == 'traindoo-app') return true
    return this.expandedExercise === exercise
  }

  toggleExerciseRecordingRequestedForSet(exercise: PlannedTrainingExercise, set: TrainingSet) {
    set.videoRecordingRequested = !set.videoRecordingRequested;
    this.hasChangesInternal =  true
  }

  onOpenRpeDialog(exercise: PlannedTrainingExercise, set: TrainingSet) {
    var rpe = set.rpe
    if (rpe == null && set.rir != null) {
      rpe = 10 - set.rir
    }
    var dialog = this.dialog.open(RpeTableDialogComponent, { data: { user: this.user, weight: set.weight, reps: set.reps, rpe: rpe } });
    dialog.afterClosed().subscribe(result => {
      if (result && result.weight) {
        this.trainingService.rpeTableCopyResult = result
      }
    });
  }
  onPasteRpeTableCopyResult(exercise: PlannedTrainingExercise, set: TrainingSet) {
    if (this.trainingService.rpeTableCopyResult) {
      set.weight = this.trainingService.rpeTableCopyResult.weight
      set.weightFormula = null
      set.reps = this.trainingService.rpeTableCopyResult.reps
      if (exercise.setParameters?.includes(SetParameter.rpe)) {
        set.rpe = this.trainingService.rpeTableCopyResult.rpe
      } else if (exercise.setParameters?.includes(SetParameter.rir)) {
        set.rir = 10 - this.trainingService.rpeTableCopyResult.rpe
      }
      this.trainingService.rpeTableCopyResult = null
      this.hasChangesInternal =  true
    }
  }

  onDragStartGroup(event, session: TrainingSession, groupHeading: LanguageDictionary<string>) {
    if (this.readOnlyMode || session.isTracked) return;
    event.dataTransfer.effectAllowed = 'move'
    event.dataTransfer.setData('text', 'group')
    setTimeout(() => {
      this.draggingGroup = groupHeading;
      this.draggingGroupSession = session;
    }, 100);
    return true;
  }

  onDropGroupAfterGroup(session: TrainingSession, groupHeading: LanguageDictionary<string>) {
    if (this.draggingGroupSession && this.draggingGroup && this.draggingGroup != groupHeading) {
      session.exercises.sort((a, b) => Number(!b.deleted) - Number(!a.deleted)).sort((a, b) => a.position - b.position);
      let draggingGroup = this.draggingGroup;
      let draggingGroupSession = this.draggingGroupSession;

      let draggingIndex = draggingGroupSession.exercises.indexOf(draggingGroupSession.exercises.find(x => x.groupHeadingTranslation == draggingGroup));
      let draggingGroupExercises = TrainingPlanEditorComponent.getExercisesByGroupName(draggingGroupSession.exercises, draggingGroup);

      session.exercises.splice(draggingIndex, draggingGroupExercises.length);

      let newGroupExercises = TrainingPlanEditorComponent.getExercisesByGroupName(session.exercises, groupHeading);
      let newIndex = session.exercises.indexOf(session.exercises.find(x => x.groupHeadingTranslation == groupHeading));

      if (newIndex >= draggingIndex) {
        newIndex += newGroupExercises.length;
      }
      session.exercises.splice(newIndex, 0, ...draggingGroupExercises);

      for (let index = 0; index < session.exercises.length; index++) {
        const exercise = session.exercises[index];
        exercise.position = index;
      }


      session.initSuperSets();

      this.hasChangesInternal =  true;

    }
    this.draggingGroup = null;
    this.draggingGroupSession = null;
  }

  onDragEndGroup() {
    this.draggingGroup = null;
    this.draggingGroupSession = null;
  }

  onAddGroupHeading(exercise: PlannedTrainingExercise) {
    exercise.groupHeadingTranslation = new LanguageDictionary<string>();
    exercise.groupHeadingTranslation.de = ''
    exercise.groupHeadingTranslation.en = ''
    this.hasChangesInternal =  true
  }

  onDeleteGroupHeading(exercise: PlannedTrainingExercise) {
    exercise.groupHeadingTranslation = null
    this.hasChangesInternal =  true
  }


  public async onSaveGroupAsTemplate(sessions: TrainingSession, groupNameTranslation: LanguageDictionary<string>) {
    let groupExercises = TrainingPlanEditorComponent.getExercisesByGroupName(sessions.exercises, groupNameTranslation);
    if (groupExercises.length === 0) return;
    if (groupExercises[0].connectedSuperSetExercise && !groupExercises[0].superSetConfig) {

    }
    let session = new TrainingSession(groupNameTranslation.de, null, false, groupExercises, false, null, null, null, false, null, null, null);
    let trainingPlanTemplate = new TrainingPlan();
    trainingPlanTemplate.sessions = [session];
    trainingPlanTemplate.nameTranslation = new LanguageDictionary<string>(groupNameTranslation.de, groupNameTranslation.en, groupNameTranslation.originObject);
    trainingPlanTemplate.trainingVariables = TrainingPlanEditorComponent.getAllVariablesOfTrainingPlan(trainingPlanTemplate, this.availableTrainingVariables)?.availableVariables ?? [];
    await this.trainingPlanEditorHelper.saveTrainingPlanAsTemplate(trainingPlanTemplate, this.availableTrainingVariables, this.selectedCoach);
    if (this.trainingService.TrainingTemplateAdministratorEditModeActivated) {
      trainingPlanTemplate.userEditable = true;
    }
  }

  onCopyGroupHeading(session: TrainingSession, groupNameTranslation: LanguageDictionary<string>) {
    this.copyExercisesListInternal = TrainingPlanEditorComponent.getExercisesByGroupName(session.exercises, groupNameTranslation);
  }

  onCancelCopyGroupHeading() {
    this.copyExercisesListInternal = null;
  }

  onPasteGroupHeading(session: TrainingSession, exercise: PlannedTrainingExercise = null) {
    let index = exercise == null ? -1 : session.exercises.indexOf(exercise);
    this.selectedSession.exercises.sort((a, b) => Number(!b.deleted) - Number(!a.deleted));
    this.copyExercisesListInternal.forEach((exercise, i) => {
      let pasteExercise = exercise.clone();
      pasteExercise.id = null;
      session.exercises.push(pasteExercise);
      this.expandedExerciseInternal = pasteExercise;
  
      if (this.selectedSession.exercises.length > 1) {
        let newIndex = index + i;
        let previousIndex = this.selectedSession.exercises.indexOf(pasteExercise);
        this.updateSuperSetsOnExerciseMove(previousIndex, newIndex + 1)
      }
      else {
        pasteExercise.connectedSuperSetExercise = false;
        pasteExercise.superSetConfig = null;
      }
    });

    this.copyExercisesListInternal = null;
    this.selectedSession.initSuperSets()
    this.hasChangesInternal =  true;
  }

  onMouseUpDragHandle(event: any) {
    event.target.parentNode.setAttribute('draggable', 'false')
  }
  onMouseDownDragHandle(event: any) {
    event.target.parentNode.setAttribute('draggable', 'true')
  }

  onCopyExercise(session: TrainingSession, exercise: PlannedTrainingExercise, superSet: SuperSet) {
    this.copyExerciseInternal = exercise
  }


  trainingExercisesLoaded(): boolean {
    return this.trainingService?.MergedTrainingExercises?.length > 0
  }

  public dummySet: TrainingSet = new TrainingSet()
  toggleExpandedExercise(exercise: PlannedTrainingExercise) {
    if (this.expandedExercise === exercise) this.expandedExercise = null
    else this.expandedExercise = exercise

    this.dummySet = new TrainingSet()
  }


  onShowExerciseHistory(exercise: PlannedTrainingExercise) {
    const dialogRef = this.dialog.open(ExerciseHistoryDialogComponent, { data: { user: this.user, exercise: exercise }, width: '750px' })
  }
  onAutoRunChanged(exercise: PlannedTrainingExercise) {
    exercise.autoRun = !exercise.autoRun
    this.hasChangesInternal = true
  }

  getSelectableSetParameters(exercise: PlannedTrainingExercise): SetParameter[] {
    return Object.values(SetParameter).filter(parameter => !exercise.setParameters.includes(parameter))
  }

  onParameterSetSelected(parameter: SetParameter, exercise: PlannedTrainingExercise) {
    exercise.setParameters.push(parameter)
    this.hasChangesInternal =  true
  }

  onRemoveParameterFromExerciseTableClick(parameter: SetParameter, exercise: PlannedTrainingExercise) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du diesen Parameter wirklich aus der Tabelle entfernen?', title: 'Parameter entfernen' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeParameterFromExerciseTable(parameter, exercise)
        exercise.sets?.forEach(set => {
          set[parameter.toString()] = undefined
        });
      }
    })
  }

  isPartOfDropset(set: TrainingSet, sets: TrainingSet[], setIndex: number) {
    if (set?.isDropset) {
      return true;
    }
    return this.isFirstPartOfDropset(set, sets, setIndex);
  }

  isFirstPartOfDropset(set: TrainingSet, sets: TrainingSet[], setIndex: number) {
    if (sets?.length > setIndex + 1) {
      return sets[setIndex + 1].isDropset;
    }
    return false;
  }

  canSelectWarmupSet(exercise: PlannedTrainingExercise, set: TrainingSet) {
    if (exercise.getSetIndexWithoutWarmupSets(set) === 0) return true;
    return false;
  }

  removeParameterFromExerciseTable(parameter: SetParameter, exercise: PlannedTrainingExercise) {
    let index = exercise.setParameters.indexOf(parameter)
    if (index >= 0) {
      exercise.setParameters.splice(index, 1)
    }
    this.hasChangesInternal =  true
  }

  onSetOptionChanged(value: any, parameter: SetParameter, exercise: PlannedTrainingExercise) {
    if (!value) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { message: 'Möchtest du diesen Parameter aus der Tabelle entfernen?', title: 'Parameter entfernen' },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.removeParameterFromExerciseTable(parameter, exercise)
        }
      })
    }
  }

  public getPlaceholderForSetParameter(setParameter: SetParameter): string {
    if (setParameter == SetParameter.weight) {
      return ""
    }
    else if (setParameter == SetParameter.pace || setParameter == SetParameter.pace500) {
      return "mm:ss"
    }
    else if (setParameter == SetParameter.heartRate) {
      return ""
    }
    else {
      return "";
    }
  }


  notePlaceholder = 'Individueller Hinweis für deinen Coachee';
  videoRequestNotePlaceholder = 'Individueller Hinweis zur Videoaufnahme';


  onEditorValueNoteChanged(value: string, exercise: PlannedTrainingExercise) {
    if (value == this.notePlaceholder) {
      exercise.note = ''
    } else {
      exercise.note = value
    }
    this.hasChangesInternal =  true
  }

  onEditorValueVideoRequestNoteChanged(value: string, exercise: PlannedTrainingExercise) {
    if (value == this.videoRequestNotePlaceholder) {
      exercise.videoRecordingRequest.hint = ''
    } else {
      exercise.videoRecordingRequest.hint = value
    }
    this.hasChangesInternal =  true
  }

  onFrequencySelectionChanged(frequency: string, exercise: PlannedTrainingExercise) {
    exercise.videoRecordingRequest.frequency = this.label2FrequencyMapping[frequency];
    this.hasChangesInternal =  true
  }

  toggleVideoRecordingRequest(exercise: PlannedTrainingExercise) {
    exercise.videoRecordingRequest.active = !exercise.videoRecordingRequest.active;
    if (!exercise.videoRecordingRequest.active) exercise.videoRecordingRequest.hint = ''
    this.hasChangesInternal =  true
  }


  //SuperSets

  onSetSuperSetConnection(exercise: PlannedTrainingExercise, superSet: SuperSet){
    if(superSet.exercises.length === 1) 
    {
      superSet.exercises[0].superSetConfig = new SuperSetConfig();
      exercise.pauseDuration = 0
    }
    let exerciseIndex = superSet.exercises.indexOf(exercise)

    if(exerciseIndex < superSet.exercises.length - 1) {
      let nextExercise = superSet.exercises[exerciseIndex + 1]
      if(exerciseIndex === 0) exercise.connectedSuperSetExercise = false
      if(nextExercise) {
        if (superSet.exercises.length <= 2) nextExercise.connectedSuperSetExercise = false
        else nextExercise.superSetConfig = superSet.exercises[0].superSetConfig
      }
    }
    else {
      let superSetIndex = this.selectedSession.superSets.indexOf(superSet);
      let nextSuperSet = this.selectedSession.superSets[superSetIndex + 1]
      let nextExercise = nextSuperSet.exercises[0]
      if(nextSuperSet != null)
      {
        exercise.connectedSuperSetExercise = true
        exercise.pauseDuration = 0
        
        if(exerciseIndex === 0) {
          if(nextExercise.superSetConfig != null) {
            exercise.superSetConfig = nextExercise.superSetConfig
            nextExercise.superSetConfig = null
          }
          else {
            exercise.superSetConfig = new SuperSetConfig();
          }
        }
        
        if(nextExercise) {
          nextExercise.connectedSuperSetExercise = true
          nextExercise.superSetConfig = null
        }
      }
    }
    this.selectedSession.initSuperSets()
    this.hasChangesInternal =  true
  }

  onSetNumberOfRounds(superSet: SuperSet, numberOfRounds: string){
    superSet.exercises[0].superSetConfig.numberOfRounds = this.label2NumberOfRoundsMapping[numberOfRounds];
    this.hasChangesInternal =  true
  }

  public isFormulaValueAvailable(set: TrainingSet, setParameter: SetParameter): boolean {
    let formulaParameter = setParameter.toString() + "Formula"
    return set[formulaParameter];
  }

  takeDummySet(exercise: PlannedTrainingExercise) {
    exercise.sets.push(this.dummySet)
    this.dummySet = new TrainingSet()
  }

  tutChange(set: TrainingSet) {
    if (set.timeUnderTension.length > 0 && set.timeUnderTension.length < 6 && set.timeUnderTension[set.timeUnderTension.length - 1] != '-') {
      set.timeUnderTension += '-'
    }
    set.timeUnderTension = set.timeUnderTension.replace('x', 'X')
  }

  noteChanged(set: TrainingSet, value: string) {
    set.note = value
  }

  onPasteExercise(session: TrainingSession, exercise?: PlannedTrainingExercise) {
    let pasteExercise = this.copyExercise.clone();
    pasteExercise.id = null;
    pasteExercise.groupHeadingTranslation = null;
    session.exercises.push(pasteExercise);
    this.expandedExerciseInternal = pasteExercise;
    this.selectedSession.exercises.sort((a, b) => Number(!b.deleted) - Number(!a.deleted));

    if (this.selectedSession.exercises.length > 1) {
      let newIndex = exercise == null ? -1 : this.selectedSession.exercises.indexOf(exercise);
      let previousIndex = this.selectedSession.exercises.indexOf(pasteExercise);
      this.updateSuperSetsOnExerciseMove(previousIndex, newIndex + 1)
    }
    else {
      pasteExercise.connectedSuperSetExercise = false;
      pasteExercise.superSetConfig = null;
    }

    this.copyExerciseInternal = null;
    this.selectedSession.initSuperSets()
    this.hasChangesInternal =  true;
  }

  updateSuperSetsOnExerciseMove(previousIndex: number, currentIndex: number) {
    this.selectedSession.exercises.sort((a, b) => Number(!b.deleted) - Number(!a.deleted))
    moveItemInArray(this.selectedSession.exercises, previousIndex, currentIndex);
    let movedExercise = this.selectedSession.exercises[currentIndex];
    let prevIndexExercise = currentIndex > 0 ? this.selectedSession.exercises[currentIndex - 1] : null;
    let nextIndexExercise = currentIndex < this.selectedSession.exercises.length - 1 ? this.selectedSession.exercises[currentIndex + 1] : null;

    let oldSuperSet = this.selectedSession.superSets.find(x => x.exercises.includes(movedExercise));

    if (oldSuperSet && oldSuperSet.exercises.length > 1) {
      let oldIndexInSuperSet = oldSuperSet.exercises.indexOf(movedExercise);

      if (oldIndexInSuperSet === 0) {
        // War das erste Element in SuperSet
        let nextInOldSuperSet = oldSuperSet.exercises[oldIndexInSuperSet + 1];

        if (oldSuperSet.exercises.length > 2) {
          nextInOldSuperSet.superSetConfig = movedExercise.superSetConfig;
        } else {
          nextInOldSuperSet.connectedSuperSetExercise = false;
        }
      } else if (oldIndexInSuperSet === oldSuperSet.exercises.length - 1) {
        // War das letzte Element in SuperSet
        let prevInOldSuperSet = oldSuperSet.exercises[oldIndexInSuperSet - 1];
        if (oldSuperSet.exercises.length === 2) {
          prevInOldSuperSet.superSetConfig = null;
          prevInOldSuperSet.connectedSuperSetExercise = false;
        }
      }
    }

    if (prevIndexExercise?.connectedSuperSetExercise && nextIndexExercise?.connectedSuperSetExercise && nextIndexExercise.superSetConfig == null) {
      movedExercise.connectedSuperSetExercise = true;
    } else {
      movedExercise.connectedSuperSetExercise = false;
    }
  }

  dropTrainingExercise(event: CdkDragDrop<string[]>) {
    this.updateSuperSetsOnExerciseMove(event.previousIndex, event.currentIndex);

    this.selectedSession.initSuperSets()
    this.hasChangesInternal = true
  }




  getEstimatedDurationInMinutes(session: TrainingSession): number {
    if (session.estimatedDurationInMinutes == null) {
      session.initSuperSets();
      let estimatedDurationInSeconds = 0
      session.superSets.forEach(superSet => {
        if (superSet.exercises?.length > 1 && superSet.exercises[0]?.superSetConfig?.numberOfRounds == NumberOfRounds.AMRAP) {
          estimatedDurationInSeconds += superSet.exercises[0]?.superSetConfig?.totalAvailableTime || 0;
        }
        else {
          superSet.exercises.forEach(exercise => {
            if (!exercise.deleted) {
              exercise.sets.forEach(set => {
                if(set.pauseDuration > 0) estimatedDurationInSeconds += set.pauseDuration
                if (set.time > 0) estimatedDurationInSeconds += set.time
                else if (set.reps > 0 && set.timeUnderTension) {
                  let timeUnderTensionValues = set.timeUnderTension.split('-').map(x => parseInt(x))
                  let timeUnderTensionSeconds = 0;
                  timeUnderTensionValues.forEach(value => {
                    timeUnderTensionSeconds += value || 0
                  });
                  if (timeUnderTensionSeconds <= 0) timeUnderTensionSeconds = 3
                  estimatedDurationInSeconds += (set.reps * timeUnderTensionSeconds) || 0;
                }
                else if (set.reps > 0) estimatedDurationInSeconds += set.reps * 3
                else if (set.maxReps > 0) estimatedDurationInSeconds += set.maxReps * 3
                else if (set.minReps > 0) estimatedDurationInSeconds += set.minReps * 3
                estimatedDurationInSeconds += 20;

                if (exercise.pauseDuration != undefined) {
                  estimatedDurationInSeconds += exercise.pauseDuration || 0
                }
                else {
                  estimatedDurationInSeconds += this.getExerciseById(exercise.exerciseId)?.getDefaultPauseDuration() || 0
                }
              });
            }
          });
        }
      });
      session.estimatedDurationInMinutes = Math.round(estimatedDurationInSeconds / 60);
    }
    return session.estimatedDurationInMinutes;
  }

  refreshEstimatedDurationInMinutes(session: TrainingSession) {
    session.estimatedDurationInMinutes = null;
    this.getEstimatedDurationInMinutes(session);
  }

  setEstimateedDurationInMinutes(session: TrainingSession, value: number) {
    session.estimatedDurationInMinutes = Math.round(value);
  }


  public selectedTrainingExercises: MergedTrainingExercise[] = [];
  selectedTrainingExercisesDrop(event: CdkDragDrop<MergedTrainingExercise[]>) {
    moveItemInArray(this.selectedTrainingExercises, event.previousIndex, event.currentIndex);
  }

  removeSelectedExercise(exercise: MergedTrainingExercise) {
    // this.selectedTrainingExercises = this.selectedTrainingExercises.filter(x => x.id != exercise.id)
    let index = this.selectedTrainingExercises.indexOf(exercise);
    if (index >= 0) {
      this.selectedTrainingExercises.splice(index, 1);
    }
  }

  onTrainingExerciseMultiSelectionChanged(selectedExercises: MergedTrainingExercise[]) {
    this.selectedTrainingExercises = selectedExercises;
  }

  positionExercise: PlannedTrainingExercise = null
  showExerciseDialog = false
  replacingExercise: PlannedTrainingExercise = null

  alternativeExercise: PlannedTrainingExercise = null


  onAddAlternativeExercise(exercise: PlannedTrainingExercise) {
    this.replacingExercise = null
    this.alternativeExercise = exercise
    this.showExerciseDialog = true
  }

  onRemoveAlternativeExercise(exercise: PlannedTrainingExercise) {
    this.alternativeExercise = null;
    exercise.alternativeExerciseId = null;
    this.hasChangesInternal =  true;
  }

  onCancelSelection() {
    this.positionExercise = null;
    this.showExerciseDialog = false;
    this.selectedTrainingExercises = [];
    this.alternativeExercise = null;
    this.replacingExercise = null;
  }

  addPlannedTrainingExercise(positionExercise: PlannedTrainingExercise = null) {
    this.replacingExercise = null
    this.showExerciseDialog = true
    this.positionExercise = positionExercise
  }

  onTakeSelection(exercises: MergedTrainingExercise[]) {
    if (exercises?.length > 0) {
      exercises.forEach(exercise => {
        let newExercise = new PlannedTrainingExercise()
        newExercise.exerciseId = exercise.sourceExerciseId
        newExercise.pauseDuration = exercise.pauseDuration
        newExercise.setParameters = exercise.defaultSetParameters.map(x => x.originObject)
        if (this.user?.defaultExertionParameter) {
          if (this.user.defaultExertionParameter == 'rpe') {
            if (!newExercise.setParameters.includes(SetParameter.rpe)) {
              newExercise.setParameters.push(SetParameter.rpe)
            }
          } else if (this.user.defaultExertionParameter == 'rir') {
            if (!newExercise.setParameters.includes(SetParameter.rir)) {
              newExercise.setParameters.push(SetParameter.rir)
            }
          }
        }

        if (this.positionExercise) {
          let index = this.selectedSession.exercises.indexOf(this.positionExercise);


          let notDeletedExercises = this.selectedSession.exercises.filter(x => !x.deleted);
          let notDeletedIndex = notDeletedExercises.indexOf(this.positionExercise);
          if (notDeletedIndex > 0) {
            let previousExercise = notDeletedExercises[notDeletedIndex - 1];
            if (previousExercise.superSetConfig || previousExercise.connectedSuperSetExercise) {
              newExercise.connectedSuperSetExercise = true
            }
          }
          this.selectedSession.exercises.splice(index, 0, newExercise);
        }
        else {
          this.selectedSession.exercises.push(newExercise)
        }
        this.expandedExercise = newExercise
        this.addSet(this.expandedExercise)
        this.selectedSession.initSuperSets()
      });
      this.hasChangesInternal =  true
    }
    this.selectedTrainingExercises = [];
    this.showExerciseDialog = false
  }


  getTrainingExercises() {
    return this.trainingService.MergedTrainingExercises.filter(x => x.sourceExerciseId != this.alternativeExercise?.exerciseId && x.id != this.replacingExercise?.exerciseId)
  }

  onReplaceExercise(session: TrainingSession, exercise: PlannedTrainingExercise, superSet: SuperSet) {
    this.alternativeExercise = null;
    this.replacingExercise = exercise
    this.showExerciseDialog = true
  }

  onTrainingExerciseSelectionChanged(exercise: MergedTrainingExercise) {
    if (this.replacingExercise) {
      this.replacingExercise.exerciseId = exercise.sourceExerciseId
      this.replacingExercise.pauseDuration = exercise.pauseDuration

      // this.replacingExercise.setParameters = exercise.defaultSetParameters.map(x => x.originObject)
      // this.replacingExercise.sets = []
      // this.addSet(this.replacingExercise)
      this.selectedSession.initSuperSets()
      this.hasChangesInternal =  true
      this.expandedExercise = this.replacingExercise
    }
    else if (this.alternativeExercise) {
      this.alternativeExercise.alternativeExerciseId = exercise.sourceExerciseId
      this.hasChangesInternal =  true
    }

    this.alternativeExercise = null;
    this.replacingExercise = null;

    this.showExerciseDialog = false
  }


  addSet(exercise: PlannedTrainingExercise) {
    if (exercise.sets.length > 0) {
      let newTrainingSet = new TrainingSet(exercise.sets[exercise.sets.length - 1]);
      newTrainingSet.isDropset = false;
      newTrainingSet.isWarmupSet = false;
      exercise.sets.push(newTrainingSet)
    }
    else exercise.sets.push(new TrainingSet())
    this.hasChangesInternal =  true
  }

  removeSet(exercise: PlannedTrainingExercise, set: TrainingSet) {
    const index = exercise.sets.indexOf(set, 0);
    if (index > -1) {
      exercise.sets.splice(index, 1);
    }
    this.hasChangesInternal =  true
  }

  onRemoveExercise(session: TrainingSession, exercise: PlannedTrainingExercise, superSet: SuperSet) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du diese Übung wirklich aus dem Plan entfernen?', title: 'Übung löschen' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeExercise(session, exercise, superSet)
      }
    })
  }

  removeExercise(session: TrainingSession, exercise: PlannedTrainingExercise, superSet: SuperSet) {
    if (exercise.connectedSuperSetExercise) {
      exercise.connectedSuperSetExercise = false
      if (exercise.superSetConfig) {
        let indexInSuperSet = superSet.exercises.indexOf(exercise)
        if (indexInSuperSet < superSet.exercises.length - 1) {
          superSet.exercises[indexInSuperSet + 1].superSetConfig = exercise.superSetConfig
        }
        exercise.superSetConfig = null
      }
    }
    exercise.deleted = true
    session.initSuperSets()
    this.hasChangesInternal =  true
  }


  onShowExerciseInfo(exercise: PlannedTrainingExercise) {
    let mergedExercise = this.getExerciseById(exercise.exerciseId);
    if (mergedExercise) {
      let dialogRef = this.dialog.open(SingleExerciseComponent, { data: { selectedExercise: mergedExercise }, width: '1000px' });
      dialogRef.afterClosed().subscribe(async result => {
        if (result) {
          if (result.shouldSave) {
            this.spinner.show()
            var exercise = result.exercise as MergedTrainingExercise
            if (exercise) {
              if (result.shouldDelete) {
                if (exercise.creatorUid == this.trainingService.UserUid) {
                  exercise.trainingExercise.deleted = true;
                } else {
                  exercise.trainingExerciseOverwrite.deleted = true;
                  exercise.trainingExerciseOverwrite.hiddenExercise = true;
                }
              }
              this.trainingService.saveOrUpdateMergedTrainingExercise(exercise, result.newThumbnail, result.newVideo, result.newExercisePreview, result.deletedVideo, result.customImages)
            }
            this.spinner.hide()
          }
        }
      })
    }
  }


  async addExerciseGroupFromTemplate(session: TrainingSession) {
    let availableTemplateFolders = await this.trainingService.getTrainingPlanTemplateFolders();
    const dialogRef = this.dialog.open(SessionTemplatesDialogComponent, { data: { availableTemplateFolders: availableTemplateFolders }, width: '1000px' })
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.shouldTake) {
          this.spinner.show()
          let missingVariables: TrainingVariable[] = [];
          let globalVariables = this.userService.getLoggedInUser()?.trainingSettingsLicenceHolder?.trainingVariables?.map(x => x.clone());
          let userVariables = this.trainingPlan.isTemplate ? [] : this.user.trainingVariables?.map(x => x.clone());
          for (let newSession of result.selectedSessions) {
            let sessionVariables = TrainingPlanEditorComponent.getAllVariablesOfSession(newSession, this.availableTrainingVariables);
            if (sessionVariables.missingVariableIds?.length > 0) {
              for (let missingVariableId of sessionVariables.missingVariableIds) {
                if (!this.trainingPlan.trainingVariables.find(x => x.id == missingVariableId)) {
                  let variable = userVariables.find(x => x.id == missingVariableId);
                  if (!variable) globalVariables.find(x => x.id == missingVariableId);
                  if (variable) {
                    this.trainingPlan.trainingVariables.push(variable);
                    missingVariables.push(variable);
                  }
                }
              }
            }
          }

          for (let newSession of result.selectedSessions) {
            for (let newExercise of newSession.exercises) {
              newExercise.sessionId = session.id
              session.exercises.push(newExercise as PlannedTrainingExercise)
            }
          }

          session.initSuperSets()
          this.initDummySessionForXRMCalculationForSession(session);
          if (missingVariables.length > 0) {
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
              data: { message: 'Fehlende Variablen wurden automatisch ergänzt. Möchtest du die Werte jetzt überprüfen?', title: 'Variablen bearbeiten', positiveButton: 'Ja', negativeButton: 'Abbrechen' },
            });
            dialogRef.afterClosed().subscribe(result => {
              if (result) {
                this.openTrainingVariablesDialog?.emit();
              }
            })
          }

          this.hasChangesInternal =  true
          this.spinner.hide()
        }
      }
    })
  }

  onCreateExercise() {
    var selectedTrainingExercise = new MergedTrainingExercise();
    selectedTrainingExercise.creatorUid = this.trainingService.UserUid
    const dialogRef = this.dialog.open(SingleExerciseComponent, { data: { selectedExercise: selectedTrainingExercise, editExerciseDisabled: this.trainingService.UserUid != selectedTrainingExercise.creatorUid, overwriteExerciseDisabled: this.trainingService.AdministratorEditModeActivated }, width: '1000px' })
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.shouldSave) {
          var exercise = result.exercise as MergedTrainingExercise
          if (exercise) {
            if (result.shouldDelete) {
              exercise.deleted = true
              selectedTrainingExercise = null
            }
            this.saveMergedTrainingExercise(exercise, result.newThumbnail, result.newVideo, result.newExercisePreview)
          }
        }
      }
    })
  }

  saveMergedTrainingExercise(exercise: MergedTrainingExercise, newThumbnail: any, newVideo: any, newExercisePreview: any) {
    this.trainingService.insertTrainingExercise(exercise, newThumbnail, newVideo, newExercisePreview)
  }

  private initDummySessionForXRMCalculationForSession(session: TrainingSession) {
    if (!session) return
    for (let exercise of session.exercises) {
      if (exercise.hasAnySetWithXRepMax()) {
        this.setDummySessionForXRMCalculation(exercise);
      }
    }
  }

  private dummySessionsForXMLCalculationByExerciseId: Map<string, TrackedTrainingSession> = new Map<string, TrackedTrainingSession>();

  async setDummySessionForXRMCalculation(exercise: PlannedTrainingExercise) {
    if (this.trainingPlan.isTemplate || !this.user || exercise.deleted || this.dummySessionsForXMLCalculationByExerciseId.has(exercise.exerciseId)) return;
    try {
      let dummySession = new TrackedTrainingSession();
      this.dummySessionsForXMLCalculationByExerciseId.set(exercise.exerciseId, dummySession);
      let past30Days = new Date();
      past30Days.setDate(past30Days.getDate() - 30);
      let trackedExercises = await this.userService.getTrackedTrainingExercisesByExerciseId(this.user?.uid, exercise.exerciseId, past30Days, new Date());
      dummySession.trackedTrainingExercises = trackedExercises;
    }
    catch (ex) {
      console.error(ex);
    }
  }


  //set set Values


  public repsPatter: string = "^(((([1-9][0-9]?[0-9]?)%(([1-9]|10)RM|[a-zA-Z0-9_ ]+))|([+]?\\d+(\-\\d+)?))|(([+]|\-)?([1-9]?[0-9]?[0-9])(%#)([1-9]?[0-9]?[0-9])))$";
  public weightPattern: string = "^(((([1-9][0-9]?[0-9]?)%(([1-9]|10)RM|[a-zA-Z0-9_ ]+))|([+]?\\d+(([.]|[,])\\d+)?))|(([+]|\-)?([1-9]?[0-9]?[0-9])(%#)([1-9]?[0-9]?[0-9])))$";
  public getRegexForSetParameter(setParameter: SetParameter): string {
    if (setParameter == SetParameter.weight) {
      // Regex allow everything for traindoo-app
      if (environment.firebaseProjectId == 'traindoo-app') return ".*";
      return this.weightPattern;
    }
    else if (setParameter == SetParameter.pace || setParameter == SetParameter.pace500) {
      return "^(([1-9][0-9]?[0-9]?)%([a-zA-Z0-9_]+))|((60|[0-5][0-9]):[0-5][0-9])(-(60|[0-5][0-9]):[0-5][0-9])?$";
    }
    else if (setParameter == SetParameter.heartRate) {
      return "^((([1-9][0-9]?[0-9]?)%([a-zA-Z0-9_]+))|(\\d+(-\\d+)?))$";
    }
    else if (setParameter == SetParameter.time) {
      return "^(([0-9][0-9]:)?([0-5][0-9]):[0-5][0-9])(-([0-9][0-9]:)?([0-5][0-9]):[0-5][0-9])?$";
    }
    else if (setParameter == SetParameter.reps) {
      // Regex allow everything for traindoo-app
      if (environment.firebaseProjectId == 'traindoo-app') return ".*";
      return this.repsPatter;
    }
    else {
      return "";
    }
  }


  public rpePattern: string = "^([0-9]*([.]|[,]))?[0-9]+(-([0-9]*[.])?[0-9]+)?$";
  getSetRpe(set: TrainingSet): string {
    if (set.rpe != null) return set.rpe.toString()
    if (set.maxRpe != null && set.minRpe != null) return set.minRpe + "-" + set.maxRpe
    return ""
  }

  setSetRpe(set: TrainingSet, value: string) {
    var minRpe: number = null
    var maxRpe: number = null
    var rpe: number = null
    if (value == null || value?.length == 0) {
      set.rpe = null
      this.hasChangesInternal =  true
      return
    }

    if (value.includes('-')) {
      var valueArray = value.split('-')
      minRpe = parseFloat(valueArray[0]?.replace(',', '.'))
      maxRpe = parseFloat(valueArray[1]?.replace(',', '.'))
    }
    else {
      rpe = parseFloat(value?.replace(',', '.'))
    }
    if (Number.isNaN(rpe)) {
      rpe = null
    }
    if (Number.isNaN(minRpe)) {
      minRpe = null
    }
    if (Number.isNaN(maxRpe)) {
      maxRpe = null
    }

    if (minRpe != null && maxRpe != null) {
      set.minRpe = minRpe
      set.maxRpe = maxRpe
      set.rpe = null
    }
    else if (rpe != null) {
      set.minRpe = null
      set.maxRpe = null
      set.rpe = rpe
    }

    this.hasChangesInternal =  true
  }

  public rirPattern: string = "^([0-9]*([.]|[,]))?[0-9]+(-([0-9]*[.])?[0-9]+)?$";
  getSetRir(set: TrainingSet): string {
    if (set.rir != null) return set.rir.toString()
    if (set.maxRir != null && set.minRir != null) return set.minRir + "-" + set.maxRir
    return ""
  }

  setSetRir(set: TrainingSet, value: string) {
    var minRir: number = null
    var maxRir: number = null
    var rir: number = null
    if (value == null || value?.length == 0) {
      set.rir = null
      this.hasChangesInternal =  true
      return
    }

    if (value.includes('-')) {
      var valueArray = value.split('-')
      minRir = parseFloat(valueArray[0]?.replace(',', '.'))
      maxRir = parseFloat(valueArray[1]?.replace(',', '.'))
    }
    else {
      rir = parseFloat(value?.replace(',', '.'))
    }
    if (Number.isNaN(rir)) {
      rir = null
    }
    if (Number.isNaN(minRir)) {
      minRir = null
    }
    if (Number.isNaN(maxRir)) {
      maxRir = null
    }

    if (minRir != null && maxRir != null) {
      set.minRir = minRir
      set.maxRir = maxRir
      set.rir = null
    }
    else if (rir != null) {
      set.minRir = null
      set.maxRir = null
      set.rir = rir
    }

    this.hasChangesInternal =  true
  }

  getSetReps(set: TrainingSet): string {
    if (set.reps != null) return set.reps.toString()
    if (set.maxReps != null && set.minReps != null) return set.minReps + "-" + set.maxReps
    return ""
  }


  setSetReps(set: TrainingSet, value: string) {
    var minReps: number = null
    var maxReps: number = null
    var reps: number = null
    if (value == null || value?.length == 0) {
      set.reps = null
      set.maxReps = null
      set.minReps = null
      this.hasChangesInternal =  true
      return
    }

    if (value.includes('-')) {
      var valueArray = value.split('-')
      minReps = parseInt(valueArray[0])
      maxReps = parseInt(valueArray[1])
    }
    else {
      reps = parseInt(value)
    }
    if (Number.isNaN(reps)) {
      reps = null
    }
    if (Number.isNaN(minReps)) {
      minReps = null
    }
    if (Number.isNaN(maxReps)) {
      maxReps = null
    }

    if (minReps != null && maxReps != null) {
      set.minReps = minReps
      set.maxReps = maxReps
      set.reps = null
    }
    else if (reps != null) {
      set.minReps = null
      set.maxReps = null
      set.reps = reps
    }

    this.hasChangesInternal =  true
  }

  setPace(set: TrainingSet, value: string) {
    if (value.length == 1 && value != ':') {
      value += ':00'
    }
    if (!value.includes(':')) return;
    var valueArray = value.split(':');
    let minutes = parseInt(valueArray[0])
    let seconds = parseInt(valueArray[1])

    if (!Number.isNaN(minutes)) {
      set.pace = minutes * 60
    }
    if (!Number.isNaN(seconds)) {
      set.pace += seconds
    }
    this.hasChangesInternal =  true
  }

  getPace(set: TrainingSet): string {
    const minutes = Math.floor(set.pace / 60) | 0;
    const seconds = set.pace - minutes * 60 | 0;

    if (seconds < 10)
      return minutes.toString() + ":" + (seconds < 10) ? '0' : '' + seconds.toString()
  }

  getTime(set: TrainingSet): string {
    if (set.minTime && set.maxTime) {
      let minTime = this.getSecondsAsTimeWithHours(set.minTime)
      let maxTime = this.getSecondsAsTimeWithHours(set.maxTime)
      return minTime + "-" + maxTime
    }
    else {
      return this.getSecondsAsTimeWithHours(set.time)
    }
  }

  getSecondsAsTimeWithHours(inputSeconds: number): string {
    if (inputSeconds == undefined) return "";
    if (inputSeconds < 0) inputSeconds = 0
    return inputSeconds.asDurationString();
  }

  setTime(set: TrainingSet, value: string) {
    set.time = undefined;
    set.minTime = undefined;
    set.maxTime = undefined;
    if (value.includes('-')) {
      var valueArray = value.split('-')
      if(valueArray[0]?.length > 0 && valueArray[1]?.length > 1) {
        let minTime = this.timeStringWithHoursToSeconds(valueArray[0])
        let maxTime = this.timeStringWithHoursToSeconds(valueArray[1])
        if(minTime && maxTime) {
          set.minTime = minTime
          set.maxTime = maxTime
          set.time = undefined;
        }
      }
    }
    else {
      if(value?.length > 0) {
        let time = this.timeStringWithHoursToSeconds(value)
        if(time) {
          set.time = time
          set.minTime = undefined;
          set.maxTime = undefined;
        }
      }
    }
    this.hasChangesInternal =  true
  }

  getSeconds(value: number): string {
    const minutes = Math.floor(value / 60) | 0;
    const seconds = value - minutes * 60 | 0;

    return ((seconds < 10) ? '0' : '') + seconds.toString();
  }
  getMinutes(value: number): string {
    const minutes = Math.floor(value / 60) | 0;
    return ((minutes < 10) ? '0' : '') + minutes;
  }

  setPaceMinutes(set: TrainingSet, value: number) {
    const minutes = Math.floor(set.pace / 60) | 0;
    set.pace -= minutes * 60;
    set.pace += value
    this.hasChangesInternal =  true

  }
  setPaceSeconds(set: TrainingSet, value: number) {
    const minutes = Math.floor(set.pace / 60) | 0;
    set.pace = minutes * 60 + value;
    this.hasChangesInternal =  true
  }


  getUserCardioZoneGroupByExerciseId(exerciseId: string, setParameter: SetParameter) {
    return TrainingPlanEditorComponent.getUserCardioZoneGroupByExerciseId(exerciseId, setParameter, this.userCardioZoneGroups);
  }

  getCalculatedFormulaValue(set: TrainingSet, exercise: PlannedTrainingExercise, setParameter: SetParameter, unit: string): string {
    let setProperty = setParameter.toString();
    let formulaProperty = setProperty + 'Formula';
    let upperCaseProperty = setProperty.charAt(0).toUpperCase() + setProperty.slice(1);
    if (set[formulaProperty]?.toLowerCase()?.includes("%")) {
      if (set[formulaProperty].match(TrainingPlanEditorComponent.xRepMaxPattern)) {
        let splittedRepMax = set[formulaProperty].split('%');
        let percentageString = splittedRepMax[0];
        let repMaxValueString = splittedRepMax[1]?.replace("RM", "");
        if (percentageString && repMaxValueString) {
          let percentage = parseInt(percentageString);
          let repMaxValue = parseInt(repMaxValueString);

          if (repMaxValue > 0 && percentage > 0) {
            if (this.dummySessionsForXMLCalculationByExerciseId?.has(exercise.exerciseId)) {
              let exrm = this.dummySessionsForXMLCalculationByExerciseId?.get(exercise.exerciseId)?.getEXRM(repMaxValue)
              if (exrm) {
                let calculatedValue = exrm * percentage / 100;
                var u = this.unitPipe.transform(unit, this.languageService.selectedUnitSystem)
                return "Referenzwert: " + (this.weightPipe.transform(exrm, this.languageService.selectedUnitSystem, true)) + ' ' + u + ' -> ' + (this.weightPipe.transform(calculatedValue, this.languageService.selectedUnitSystem, true)) + ' ' + u;
              }
            }
          }
        }
      }
      else if (set[formulaProperty].match(TrainingPlanEditorComponent.refSetPattern)) {
        let splittedRefNumber = set[formulaProperty].split('%#');
        let indexOfRefSet = parseInt(splittedRefNumber[1]) - 1;
        if (indexOfRefSet >= 0 && indexOfRefSet <= exercise.sets.length) {
          let refSet = exercise.sets[indexOfRefSet];
          let percentage = parseInt(set[formulaProperty]?.split('%')[0]);
          if (refSet[setProperty] != undefined) {
            if (percentage > 0) {
              if (setParameter == SetParameter.pace || setParameter == SetParameter.pace500) {
                let calculatedValue = refSet[setProperty] / (percentage / 100);
                let minSecondsDate = new Date(0, 0, 0, 0, 0, refSet[setProperty], 0)?.toTimeString()?.slice(3, 8) ?? "00:00";
                let propertySecondsDate = new Date(0, 0, 0, 0, 0, calculatedValue, 0)?.toTimeString()?.slice(3, 8) ?? "00:00";
                return "Referenzwert: " + minSecondsDate + ' ' + unit + ' -> ' + propertySecondsDate + ' ' + unit;
              }
              else if (setParameter == SetParameter.heartRate) {
                let calculatedValue = refSet[setProperty] * percentage / 100;
                return "Referenzwert: " + refSet[setProperty]?.toString() + ' ' + unit + ' -> ' + calculatedValue?.toString() + ' ' + unit;
              }
              let step = this.setParameter2ValueStepMapping[setParameter];
              let calculatedValue = 0;
              if (step == 1) {
                calculatedValue = Math.round(refSet[setProperty] * percentage / 100);
              } else {
                calculatedValue = refSet[setProperty] * percentage / 100;
              }
              if (setParameter == SetParameter.weight) {
                var u = this.unitPipe.transform(unit, this.languageService.selectedUnitSystem)
                return "Referenzwert: " + (this.weightPipe.transform(refSet[setProperty], this.languageService.selectedUnitSystem, true)) + ' ' + u + ' -> ' + (this.weightPipe.transform(calculatedValue, this.languageService.selectedUnitSystem, true)) + ' ' + u;
              }
              return "Referenzwert: " + refSet[setProperty]?.toString() + ' ' + unit + ' -> ' + calculatedValue?.toString() + ' ' + unit;
            }
          }
          else {
            if (setParameter == SetParameter.reps) {
              let min = refSet["min" + upperCaseProperty];
              let max = refSet["max" + upperCaseProperty];
              if (min != undefined && max != undefined) {
                let calculatedMinValue = Math.round(min * percentage / 100);
                let calculatedMaxValue = Math.round(max * percentage / 100);
                return "Referenzwert: " + min + '-' + max + ' ' + unit + ' -> ' + calculatedMinValue + '-' + calculatedMaxValue + ' ' + unit;
              }
            }
            else if (setParameter == SetParameter.pace || setParameter == SetParameter.pace500) {
              let minPace = setParameter == SetParameter.pace ? refSet.minPace : refSet.minPace500;
              let maxPace = setParameter == SetParameter.pace ? refSet.maxPace : refSet.minPace500;
              if (percentage > 0) {
                let minSecondsDate = new Date(0, 0, 0, 0, 0, minPace, 0)?.toTimeString()?.slice(3, 8) ?? "00:00";
                let maxSecondsDate = new Date(0, 0, 0, 0, 0, maxPace, 0)?.toTimeString()?.slice(3, 8) ?? "00:00";

                let calculatedMinValue = minPace / (percentage / 100);
                let calculatedMaxValue = maxPace / (percentage / 100);

                let minPropertySecondsDate = new Date(0, 0, 0, 0, 0, calculatedMinValue, 0)?.toTimeString()?.slice(3, 8) ?? "00:00";
                let maxPropertySecondsDate = new Date(0, 0, 0, 0, 0, calculatedMaxValue, 0)?.toTimeString()?.slice(3, 8) ?? "00:00";
                return "Referenzwert: " + minSecondsDate + '-' + maxSecondsDate + ' ' + unit + ' -> ' + minPropertySecondsDate + '-' + maxPropertySecondsDate + ' ' + unit;
              }
            }
          }
        }
      }
      else if (set[formulaProperty].includes(`%${TrainingPlanEditorComponent.cardioZonePrefix}{`)) {
        let splitted = set[formulaProperty]?.split(`%${TrainingPlanEditorComponent.cardioZonePrefix}{`);
        let percentage = parseInt(splitted[0]);
        let zoneIdPart = splitted[1];
        if (percentage > 0 && zoneIdPart) {
          let zoneId = zoneIdPart.split('}')[0];
          let zoneGroup = this.getUserCardioZoneGroupByExerciseId(exercise.exerciseId, setParameter);
          let zone = TrainingPlanEditorComponent.getCardioZoneById(zoneId, zoneGroup?.zones);
          if (zone) {
            let uppperCaseSetProperty = setProperty.charAt(0).toUpperCase() + setProperty.slice(1);
            let minZoneValue = zone["min" + uppperCaseSetProperty];
            let maxZoneValue = zone["max" + uppperCaseSetProperty];
            if (minZoneValue != undefined && maxZoneValue != undefined) {
              if (setProperty == SetParameter.heartRate) {
                let minPropertyValue = Math.round(minZoneValue * percentage / 100);
                let maxPropertyValue = Math.round(maxZoneValue * percentage / 100);
                if (minPropertyValue > 0 && maxPropertyValue > 0) {
                  return "Referenzwert: " + minZoneValue + "-" + maxZoneValue + ' ' + unit + " -> " + minPropertyValue?.toString() + "-" + maxPropertyValue?.toString() + ' ' + unit
                }
              }
              else if (setProperty == SetParameter.pace || setProperty == SetParameter.pace500) {
                let minPropertyValue = Math.round(minZoneValue / (percentage / 100));
                let maxPropertyValue = Math.round(maxZoneValue / (percentage / 100));
                if (minPropertyValue > 0 && maxPropertyValue > 0) {
                  let minSecondsDate = new Date(0, 0, 0, 0, 0, minZoneValue, 0)?.toTimeString()?.slice(3, 8) ?? "00:00";
                  let maxSecondsDate = new Date(0, 0, 0, 0, 0, maxZoneValue, 0)?.toTimeString()?.slice(3, 8) ?? "00:00";
                  let minPropertySecondsDate = new Date(0, 0, 0, 0, 0, minPropertyValue, 0)?.toTimeString()?.slice(3, 8) ?? "00:00";
                  let maxPropertySecondsDate = new Date(0, 0, 0, 0, 0, maxPropertyValue, 0)?.toTimeString()?.slice(3, 8) ?? "00:00";
                  return "Referenzwert: " + minSecondsDate + "-" + maxSecondsDate + ' ' + unit + " -> " + minPropertySecondsDate + "-" + maxPropertySecondsDate + ' ' + unit
                }
              }
            }
            else if (minZoneValue != undefined && zone.isMaxCardioZone) {
              if (setProperty == SetParameter.heartRate) {
                let propertyValue = Math.round(minZoneValue * percentage / 100);
                if (propertyValue > 0) {
                  return "Referenzwert: " + minZoneValue + ' ' + unit + " -> " + propertyValue?.toString() + ' ' + unit
                }
              }
              else if (setProperty == SetParameter.pace || setProperty == SetParameter.pace500) {
                let propertyValue = Math.round(minZoneValue / (percentage / 100));
                if (propertyValue > 0) {
                  let zoneSecondsDate = new Date(0, 0, 0, 0, 0, minZoneValue, 0)?.toTimeString()?.slice(3, 8) ?? "00:00";
                  let propertySecondsDate = new Date(0, 0, 0, 0, 0, propertyValue, 0)?.toTimeString()?.slice(3, 8) ?? "00:00";
                  return "Referenzwert: " + zoneSecondsDate + ' ' + unit + " -> " + propertySecondsDate + ' ' + unit
                }
              }
            }
          }
        }
      }
      else {
        let variable = TrainingPlanEditorComponent.getVariableByFormula(set[formulaProperty], this.availableTrainingVariables);
        if (variable) {
          let percentage = parseInt(set[formulaProperty]?.split('%')[0]);
          if (percentage > 0) {
            let uppperCaseSetProperty = setProperty.charAt(0).toUpperCase() + setProperty.slice(1);
            if (variable[setProperty] === undefined) {
              let minVariableValue = variable["min" + uppperCaseSetProperty];
              let maxVariableValue = variable["max" + uppperCaseSetProperty];

              if (minVariableValue != undefined && maxVariableValue != undefined) {
                if (setParameter == SetParameter.pace || setParameter == SetParameter.pace500) {
                  let minPropertyValue = Math.round(minVariableValue / (percentage / 100));
                  let maxPropertyValue = Math.round(maxVariableValue / (percentage / 100));
                  if (minPropertyValue > 0 && maxPropertyValue > 0) {
                    let minSecondsDate = new Date(0, 0, 0, 0, 0, minPropertyValue, 0)?.toTimeString()?.slice(3, 8) ?? "00:00";
                    let maxSecondsDate = new Date(0, 0, 0, 0, 0, maxPropertyValue, 0)?.toTimeString()?.slice(3, 8) ?? "00:00";
                    let minVariableDate = new Date(0, 0, 0, 0, 0, minVariableValue, 0)?.toTimeString()?.slice(3, 8) ?? "00:00";
                    let maxVariableDate = new Date(0, 0, 0, 0, 0, maxVariableValue, 0)?.toTimeString()?.slice(3, 8) ?? "00:00";
                    return "Referenzwert: " + minVariableDate + "-" + maxVariableDate + ' ' + unit + " -> " + minSecondsDate + "-" + maxSecondsDate + ' ' + unit
                  }
                }
                else if (setParameter == SetParameter.heartRate || setParameter == SetParameter.reps) {
                  let minPropertyValue = Math.round(minVariableValue * percentage / 100);
                  let maxPropertyValue = Math.round(maxVariableValue * percentage / 100);
                  if (minPropertyValue > 0 && maxPropertyValue > 0) {
                    return "Referenzwert: " + minVariableValue + "-" + maxVariableValue + ' ' + unit + " -> " + minPropertyValue?.toString() + "-" + maxPropertyValue?.toString() + ' ' + unit
                  }
                }
                else {
                  let minPropertyValue = minVariableValue * percentage / 100;
                  let maxPropertyValue = maxVariableValue * percentage / 100;
                  if (minPropertyValue > 0 && maxPropertyValue > 0) {
                    return "Referenzwert: " + minVariableValue + "-" + maxVariableValue + ' ' + unit + " -> " + minPropertyValue?.toString() + "-" + maxPropertyValue?.toString() + ' ' + unit
                  }
                }
              }
            }
            else {

              if (setParameter == SetParameter.pace || setParameter == SetParameter.pace500) {
                let propertyValue = Math.round(variable[setProperty] / (percentage / 100));
                if (propertyValue > 0) {
                  let variableDate = new Date(0, 0, 0, 0, 0, variable[setProperty], 0)?.toTimeString()?.slice(3, 8) ?? "00:00";
                  let propertySecondsDate = new Date(0, 0, 0, 0, 0, propertyValue, 0)?.toTimeString()?.slice(3, 8) ?? "00:00";
                  return "Referenzwert: " + variableDate + ' ' + unit + " -> " + propertySecondsDate + ' ' + unit
                }
              }
              else if (setParameter == SetParameter.heartRate || setParameter == SetParameter.reps) {
                let propertyValue = Math.round(variable[setProperty] * percentage / 100);
                if (propertyValue > 0) {
                  return "Referenzwert: " + variable[setProperty] + ' ' + unit + " -> " + propertyValue?.toString() + ' ' + unit
                }
              } else if (setParameter == SetParameter.weight) {
                let propertyValue = variable[setProperty] * percentage / 100;
                var u = this.unitPipe.transform(unit, this.languageService.selectedUnitSystem)
                if (propertyValue > 0) {
                  return "Referenzwert: " + (this.weightPipe.transform(variable[setProperty], this.languageService.selectedUnitSystem, true)) + ' ' + u + " -> " + (this.weightPipe.transform(propertyValue, this.languageService.selectedUnitSystem, true)) + ' ' + u
                }
              }
              else {
                let propertyValue = variable[setProperty] * percentage / 100;
                if (propertyValue > 0) {
                  return "Referenzwert: " + variable[setProperty] + ' ' + unit + " -> " + propertyValue?.toString() + ' ' + unit
                }
              }
            }
          }
        }
      }
      return "Berechnung in App";
    }
  }


  setSetValueWithVariable(set: TrainingSet, value: string, exercise: PlannedTrainingExercise, setParameter: SetParameter) {
    let parameterString = setParameter.toString();
    let pattern = setParameter == SetParameter.reps ? this.repsPatter : this.weightPattern;
    if (value == null || value?.length == 0) {
      set[parameterString] = undefined
      set[parameterString + "Formula"] = undefined
      set["min" + parameterString.charAt(0).toUpperCase() + parameterString.slice(1)] = undefined
      set["max" + parameterString.charAt(0).toUpperCase() + parameterString.slice(1)] = undefined
      this.hasChangesInternal =  true
      return
    }
    let upperCaseProperty = parameterString.charAt(0).toUpperCase() + parameterString.slice(1);
    if (value.includes('%')) {
      if (value?.match(pattern)) {
        if (value.match(TrainingPlanEditorComponent.xRepMaxPattern)) {
          this.setDummySessionForXRMCalculation(exercise);
          set[parameterString + "Formula"] = value
          set[parameterString] = undefined
          set["min" + upperCaseProperty] = undefined
          set["max" + upperCaseProperty] = undefined
        }
        else if (value.match(TrainingPlanEditorComponent.refSetPattern)) {
          let sets = exercise?.sets;
          let indexOfCurrentSet = sets.indexOf(set);
          let splittedRefNumber = value.split('%#');
          let indexOfRefSet = parseInt(splittedRefNumber[1]) - 1;
          if (indexOfRefSet >= 0 && indexOfRefSet <= sets.length && indexOfCurrentSet > indexOfRefSet) {
            set[parameterString] = undefined
            set["min" + upperCaseProperty] = undefined
            set["max" + upperCaseProperty] = undefined
            set[parameterString + "Formula"] = value
          }
          else {
            this.toastr.error("Referenzsatz existiert nicht.", "", {
              positionClass: 'toast-bottom-center'
            });
          }
        }
        else {
          let parsedInput = this.parseVariableValueInput(value);
          let multiplier = parsedInput?.multiplier;

          if (parsedInput.variableName?.length > 0) {
            let variable = parsedInput?.variable;

            if (variable) {
              if (multiplier > 0) {
                if (!this.availableTrainingVariables.find(x => x.id == variable.id)) {
                  this.trainingPlan.trainingVariables.push(variable);
                }
                set[parameterString + "Formula"] = value.replace(variable.name, '{' + variable.id + '}');
                TrainingPlanEditorComponent.setCalculatedFormulaValue(set, setParameter, this.availableTrainingVariables, this.getUserCardioZoneGroupByExerciseId(exercise.exerciseId, setParameter)?.zones);
              }
            }
            else {
              this.showVariableNotAvailableDialog.emit();
            }
          }
          else if (multiplier > 0) {
            set[parameterString + "Formula"] = multiplier + '%';
          }
        }
      }
    } else {
      value = value.replace(',', '.');
      if (value.includes('-')) {
        let splittedValue = value.split('-');
        let min = parseFloat(splittedValue[0]);
        let max = parseFloat(splittedValue[1]);
        if (!isNaN(min) && !isNaN(max)) {
          set["min" + upperCaseProperty] = min;
          set["max" + upperCaseProperty] = max;
          set[parameterString] = null
          set[parameterString + "Formula"] = undefined
        } else if (environment.firebaseProjectId == 'traindoo-app' && setParameter == SetParameter.reps) {
          set[parameterString + 'String'] = value;
          set[parameterString] = null
          set[parameterString + "Formula"] = null
        }
      } else {
        set["min" + upperCaseProperty] = undefined;
        set["max" + upperCaseProperty] = undefined;
        let numberValue = parseFloat(value);
        if (!isNaN(numberValue)) {
          set[parameterString] = numberValue
          set[parameterString + "Formula"] = undefined
        } else {
          set[parameterString] = undefined
          set[parameterString + "Formula"] = undefined
          if (environment.firebaseProjectId == 'traindoo-app' && setParameter == SetParameter.reps) {
            set[setParameter.toString() + 'String'] = value;
            set[parameterString] = null
            set[parameterString + "Formula"] = null
          }
        }
      }
    }
    this.hasChangesInternal =  true

  }
  setSetWeight(set: TrainingSet, value: string, exercise: PlannedTrainingExercise, variable: TrainingVariable = null) {
    if (value == null || value?.length == 0) {
      set.weight = undefined
      set.weightFormula = undefined
      this.hasChangesInternal =  true
      return
    }
    
    if (value.includes('%')) {
      if (value?.match(this.weightPattern)) {
        if (value.match(TrainingPlanEditorComponent.xRepMaxPattern)) {
          this.setDummySessionForXRMCalculation(exercise);
          set.weightFormula = value
          set.weight = undefined
        } else if (value.match(TrainingPlanEditorComponent.refSetPattern)) {
          let sets = exercise?.sets;
          let indexOfCurrentSet = sets.indexOf(set);
          let splittedRefNumber = value.split('%#');
          let indexOfRefSet = parseInt(splittedRefNumber[1]) - 1;
          if (indexOfRefSet >= 0 && indexOfRefSet <= sets.length && indexOfCurrentSet > indexOfRefSet) {
            set.weight = undefined
            set.weightFormula = value
          }
          else {
            this.toastr.error("Referenzsatz existiert nicht.", "", {
              positionClass: 'toast-bottom-center'
            });
          }
        } else if (value.endsWith('%1RM_TOPSET')) {
          set.weightFormula = value
          set.weight = undefined
        } else {
          let parsedInput = this.parseVariableValueInput(value, variable);
          let multiplier = parsedInput?.multiplier;

          if (parsedInput.variableName?.length > 0) {
            let variable = parsedInput?.variable;

            if (variable) {
              if (multiplier > 0) {
                if (!this.availableTrainingVariables.find(x => x.id == variable.id)) {
                  this.trainingPlan.trainingVariables.push(variable);
                }
                set.weightFormula = value.replace(variable.name, '{' + variable.id + '}');
                TrainingPlanEditorComponent.setCalculatedFormulaValue(set, SetParameter.weight, this.availableTrainingVariables, this.getUserCardioZoneGroupByExerciseId(exercise.exerciseId, SetParameter.weight)?.zones);
                // set.weight = variable.weight * multiplier / 100;
              }
            } else {
              this.showVariableNotAvailableDialog.emit();
            }
          }
          else if (multiplier > 0) {
            set.weightFormula = multiplier + '%';
          }
        }
      }
    } else {
      value = value.replace(',', '.');
      if (value?.match(this.weightPattern)) {
        let weightValue = parseFloat(value);
        if (!isNaN(weightValue)) {
          set.weight = this.weightPipe.transform(parseFloat(value), this.languageService.selectedUnitSystem, false, true)
          set.weightFormula = null
          set.weightString = null
        } else {
          set.weight = undefined
          set.weightFormula = undefined
        }
      } else if (environment.firebaseProjectId == 'traindoo-app') {
        set.weight = null
        set.weightFormula = null
        set.weightString = value
      }
    }
    this.hasChangesInternal =  true
  }

  getSetValue(set: TrainingSet, setParameter: SetParameter, exercise: PlannedTrainingExercise) {
    if (setParameter == SetParameter.weight) return TrainingPlanEditorComponent.getSetWeight(set, this.availableTrainingVariables, this.languageService.selectedUnitSystem, this.weightPipe);
    if (setParameter == SetParameter.pace || setParameter == SetParameter.pace500) return TrainingPlanEditorComponent.getSetPace(set, setParameter, this.availableTrainingVariables, this.getUserCardioZoneGroupByExerciseId(exercise.exerciseId, setParameter)?.zones);
    if (setParameter == SetParameter.heartRate) return TrainingPlanEditorComponent.getSetHeartRate(set, this.availableTrainingVariables, this.getUserCardioZoneGroupByExerciseId(exercise.exerciseId, setParameter)?.zones);
    if (setParameter == SetParameter.reps) return TrainingPlanEditorComponent.getSetValueWithVariable(set, setParameter, this.availableTrainingVariables);
    return set[setParameter];
  }

  setSetValue(set: TrainingSet, setParameter: SetParameter, value: string, setIndex: number, exercise: PlannedTrainingExercise) {
    if (setParameter == SetParameter.weight) this.setSetWeight(set, value, exercise);
    if (setParameter == SetParameter.pace || setParameter == SetParameter.pace500) this.setSetPace(exercise.sets, setParameter, set, value, setIndex, exercise);
    if (setParameter == SetParameter.heartRate) this.setSetHeartRate(exercise.sets, set, value, setIndex, exercise);
    if (setParameter == SetParameter.reps) this.setSetValueWithVariable(set, value, exercise, setParameter);
  }


  getValueByFormula(set: TrainingSet, setParameter: SetParameter): string {
    let formula = set[setParameter + 'Formula'];
    let splitted = formula?.split('%');
    if (splitted?.length > 1) {
      let value = parseFloat(splitted[0]);
      return value.toString();
    }
    return "";
  }

  getVariableNameByFormula(exercise: PlannedTrainingExercise, set: TrainingSet, setParameter: SetParameter): string {
    let formula = set[setParameter + 'Formula'];
    if (formula?.includes('%{')) {
      let variable = TrainingPlanEditorComponent.getVariableByFormula(formula, this.availableTrainingVariables);
      if (variable) {
        if (variable.id.startsWith('ONERM_')) return '1RM ' + variable.name;
        if (variable.id.startsWith('GOAL1RM_')) return 'Zyklusziel ' + variable.name;
        return variable.name;
      }
    } else if (formula?.includes('%' + TrainingPlanEditorComponent.cardioZonePrefix)) {
      let splitted = formula.split(`%${TrainingPlanEditorComponent.cardioZonePrefix}{`);
      if (splitted?.length > 1) {
        let zoneId = splitted[1].replace('}', '');
        let zone = TrainingPlanEditorComponent.getCardioZoneById(zoneId, this.globalCardioZones);
        if (zone) return zone.name;
      }
    } else {
      let splitted = formula?.split('%');
      if (splitted?.length > 1) {
        if (splitted[1].includes('#')) {
          let splittedRefNumber = splitted[1].split('#');
          let indexOfRefSet = parseInt(splittedRefNumber[1]) - 1;
          if (indexOfRefSet >= 0 && indexOfRefSet < exercise.sets.length) {
            let refSet = exercise.sets[indexOfRefSet];
            if (refSet.isWarmupSet) {
              return "W" + (indexOfRefSet > 0 ? indexOfRefSet : "");
            }
            else if (refSet.isDropset) {
              let dropSetIndex = 0;
              if (indexOfRefSet > 0) {
                for (let i = 0; i < indexOfRefSet; i++) {
                  if (exercise.sets[i]?.isDropset) dropSetIndex++;
                }
              }
              return "D" + (dropSetIndex > 0 ? dropSetIndex : "");
            }
          }
        }
        if (splitted[1] == '1RM_TOPSET') return '1RM Topset'
        return splitted[1]
      }
    }
    return null;
  }

  hasValueRange(value: string) {
    if (value?.includes('-')) return true;
    return false;
  }

  switchFormulaInput(set: TrainingSet, setParameter: SetParameter, formulInputTrigger: MatMenuTrigger) {
    let property = setParameter.toString();
    if (set[property + 'Formula'] == null || set[property + 'Formula']?.includes('%') == false) {
      let value = 100;
      set[property] = undefined;
      set[property + 'Formula'] = value + '%';
      if (!formulInputTrigger.menuOpen) {
        formulInputTrigger.openMenu();
      }
    }
    else {
      set[property + 'Formula'] = undefined;
      if (formulInputTrigger.menuOpen) {
        formulInputTrigger.closeMenu();
      }
    }
  }

  submitFormulaInput(set: TrainingSet, percentageInput: HTMLInputElement, variableInput: HTMLInputElement, setParameter: SetParameter, formulInputTrigger: MatMenuTrigger = null, setIndex: number, exercise: PlannedTrainingExercise, trainingVariable: TrainingVariable = null) {

    let percentage = percentageInput.value;
    let variable = variableInput.value;
    let sets = exercise.sets;

    if (variable == null || variable.length == 0) {
      variable = this.getVariableNameBySet(set, setParameter, true);
    }
    if (percentage == null || percentage.length == 0) {
      percentage = this.getPercentageBySet(set, setParameter);
    }

    if (this.globalCardioZones?.find(x => x.name?.toUpperCase() == variable?.toUpperCase())) {
      variable = TrainingPlanEditorComponent.cardioZonePrefix + variable;
    }

    let value = percentage + '%' + variable;
    if (setParameter == SetParameter.weight) {
      this.setSetWeight(set, value, exercise, trainingVariable);
    }
    else if (setParameter == SetParameter.pace || setParameter == SetParameter.pace500) {
      this.setSetPace(sets, setParameter, set, value, setIndex, exercise);
    }
    else if (setParameter == SetParameter.heartRate) {
      this.setSetHeartRate(sets, set, value, setIndex, exercise);
    }
    else if (setParameter == SetParameter.reps) {
      this.setSetValueWithVariable(set, value, exercise, setParameter);
    }

    percentageInput.value = '';
    variableInput.value = '';

    if (formulInputTrigger?.menuOpen) {
      formulInputTrigger.closeMenu();
    }
  }


  setSetHeartRate(sets: TrainingSet[], set: TrainingSet, value: string, setIndex: number, exercise: PlannedTrainingExercise) {
    if (value == null || value?.length == 0) {
      set.heartRate = undefined
      set.heartRateFormula = undefined
      set.minHeartRate = undefined
      set.maxHeartRate = undefined
      this.hasChangesInternal =  true
      return
    }
    if (value?.includes('%')) {
      if (value.match(TrainingPlanEditorComponent.refSetPattern)) {
        let indexOfCurrentSet = sets.indexOf(set);
        let splittedRefNumber = value.split('%#');
        let indexOfRefSet = parseInt(splittedRefNumber[1]) - 1;
        if (indexOfRefSet <= sets.length && indexOfCurrentSet > indexOfRefSet) {
          set.heartRate = undefined
          set.minHeartRate = undefined
          set.maxHeartRate = undefined
          set.heartRateFormula = value
        }
        else {
          this.toastr.error("Referenzsatz existiert nicht.", "", {
            positionClass: 'toast-bottom-center'
          });
        }
      }
      else if (value.match(TrainingPlanEditorComponent.cardioZonePattern)) {
        let splitted = value.split(`%${TrainingPlanEditorComponent.cardioZonePrefix}`);
        let minHeartRate = parseInt(splitted[0]);
        let zoneName = splitted[1];
        let zone = this.getCardioZoneByName(zoneName);
        if (zone && !isNaN(minHeartRate)) {
          set.heartRateFormula = value.replace(zoneName, '{' + zone.id + '}');
          TrainingPlanEditorComponent.setCalculatedFormulaValue(set, SetParameter.heartRate, this.availableTrainingVariables, this.getUserCardioZoneGroupByExerciseId(exercise.exerciseId, SetParameter.heartRate)?.zones);
        }
      }
      else {
        this.setVariableSuggestions(sets, value, SetParameter.heartRate, setIndex);
        let parsedInput = this.parseVariableValueInput(value);
        let multiplier = parsedInput?.multiplier;
        let variable = parsedInput?.variable;

        if (parsedInput.variableName?.length > 0) {
          if (variable) {
            if (multiplier > 0) {
              if (!this.availableTrainingVariables.find(x => x.id == variable.id)) {
                this.trainingPlan.trainingVariables.push(variable);
              }
              set.heartRateFormula = value.replace(variable.name, '{' + variable.id + '}');
              // set.heartRate = variable.heartRate * multiplier / 100;

              TrainingPlanEditorComponent.setCalculatedFormulaValue(set, SetParameter.heartRate, this.availableTrainingVariables, this.getUserCardioZoneGroupByExerciseId(exercise.exerciseId, SetParameter.heartRate)?.zones);
            }
          }
          else {
            this.showVariableNotAvailableDialog.emit();
          }
        }
        else if (multiplier > 0) {
          set.heartRateFormula = multiplier + '%';
        }
      }
    }
    else {
      if (value?.includes('-')) {
        let splitted = value.split('-');
        let minHeartRate = parseInt(splitted[0]);
        let maxHeartRate = parseInt(splitted[1]);

        if (!isNaN(minHeartRate) && !isNaN(maxHeartRate)) {
          set.minHeartRate = minHeartRate;
          set.maxHeartRate = maxHeartRate;
          set.heartRate = undefined;
          set.heartRateFormula = undefined;
        }
      }
      else {
        let heartRate = parseInt(value)
        if (!isNaN(heartRate)) {
          set.heartRate = heartRate;
          set.heartRateFormula = undefined
          set.minHeartRate = undefined
          set.maxHeartRate = undefined
        }
      }
    }
    this.hasChangesInternal =  true
  }

  private getCardioZoneByName(zoneName: string) {
    let zone = TrainingPlanEditorComponent.getCardioZoneByName(zoneName, this.globalCardioZones);
    return zone;
  }

  setSetPace(sets: TrainingSet[], setParameter: SetParameter, set: TrainingSet, value: string, setIndex: number, exercise: PlannedTrainingExercise) {
    let parameter = setParameter.toString();
    let upperCaseParameter = parameter.charAt(0).toUpperCase() + parameter.slice(1);
    let formulaParameter = parameter + "Formula";
    let minParameter = "min" + upperCaseParameter;
    let maxParameter = "max" + upperCaseParameter;
    if (value == null || value?.length == 0) {
      set[parameter] = undefined
      set[formulaParameter] = undefined
      set[minParameter] = undefined
      set[maxParameter] = undefined
      this.hasChangesInternal =  true
      return
    }
    if (value.includes('%')) {
      if (value.match(TrainingPlanEditorComponent.refSetPattern)) {
        let indexOfCurrentSet = sets.indexOf(set);
        let splittedRefNumber = value.split('%#');
        let indexOfRefSet = parseInt(splittedRefNumber[1]) - 1;
        if (indexOfRefSet <= sets.length && indexOfCurrentSet > indexOfRefSet) {
          set[parameter] = undefined
          set[minParameter] = undefined
          set[maxParameter] = undefined
          set[formulaParameter] = value
        }
        else {
          this.toastr.error("Referenzsatz existiert nicht.", "", {
            positionClass: 'toast-bottom-center'
          });
        }
      }
      else if (value.match(TrainingPlanEditorComponent.cardioZonePattern)) {
        let splitted = value.split(`%${TrainingPlanEditorComponent.cardioZonePrefix}`);
        let percentage = parseInt(splitted[0]);
        let zoneName = splitted[1];
        let zone = this.getCardioZoneByName(zoneName);
        if (zone && !isNaN(percentage)) {
          set[formulaParameter] = value.replace(zoneName, '{' + zone.id + '}');
          TrainingPlanEditorComponent.setCalculatedFormulaValue(set, setParameter, this.availableTrainingVariables, this.getUserCardioZoneGroupByExerciseId(exercise.exerciseId, setParameter)?.zones);
        }
      }
      else {
        this.setVariableSuggestions(sets, value, setParameter, setIndex);
        let parsedInput = this.parseVariableValueInput(value);
        let multiplier = parsedInput?.multiplier;
        let variable = parsedInput?.variable;

        if (parsedInput.variableName?.length > 0) {
          if (variable) {
            if (multiplier > 0) {
              if (!this.availableTrainingVariables.find(x => x.id == variable.id)) {
                this.trainingPlan.trainingVariables.push(variable);
              }
              set[formulaParameter] = value.replace(variable.name, '{' + variable.id + '}');
              TrainingPlanEditorComponent.setCalculatedFormulaValue(set, setParameter, this.availableTrainingVariables, this.getUserCardioZoneGroupByExerciseId(exercise.exerciseId, setParameter)?.zones);
            }
          }
          else {
            this.showVariableNotAvailableDialog.emit();
          }
        }
        else if (multiplier > 0) {
          set[formulaParameter] = multiplier + '%';
        }
      }
    }
    else {
      if (value.includes('-')) {
        let splitted = value.split('-');
        let minSeconds = this.timeStringToSeconds(splitted[0]);
        let maxSeconds = this.timeStringToSeconds(splitted[1]);

        if (minSeconds != null && maxSeconds != null) {
          set[minParameter] = minSeconds;
          set[maxParameter] = maxSeconds;
          set[parameter] = undefined;
          set[formulaParameter] = undefined
        }
      }
      else {
        let seconds = this.timeStringToSeconds(value)
        if (seconds != null) {
          set[parameter] = seconds;
          set[formulaParameter] = undefined
          set[minParameter] = undefined
          set[maxParameter] = undefined
        }
      }
    }
    this.hasChangesInternal =  true
  }


  isVariableInputValid(sets: TrainingSet[], set: TrainingSet, setParameter: SetParameter) {
    let value = set[setParameter + 'Formula'];
    if (value == null || value?.length == 0) return true;
    if (value?.match(TrainingPlanEditorComponent.xRepMaxPattern)) {
      return true;
    } else if (value?.match(TrainingPlanEditorComponent.oneRmTopsetPattern)) {
      return true 
    } else if (value?.match(TrainingPlanEditorComponent.refSetPattern)) {
      let indexOfCurrentSet = sets.indexOf(set);
      let splittedRefNumber = value.split('%#');
      let indexOfRefSet = parseInt(splittedRefNumber[1]) - 1;
      if (indexOfRefSet <= sets.length && indexOfCurrentSet > indexOfRefSet) {
        return true;
      }
      else return false;
    }
    else if (value?.includes(`%${TrainingPlanEditorComponent.cardioZonePrefix}{`)) {
      let splitted = value.split(`%${TrainingPlanEditorComponent.cardioZonePrefix}{`);
      let percentage = parseInt(splitted[0]);
      let zoneId = splitted[1].replace('}', '');
      if (zoneId) {
        let zone = TrainingPlanEditorComponent.getCardioZoneById(zoneId, this.globalCardioZones);
        if (zone && !isNaN(percentage)) {
          return true;
        }
      }
      else return false;
    }


    if (value?.includes('%')) {

      let percentage = parseInt(value?.split('%')[0]);
      if (percentage > 0) {
        let variable = TrainingPlanEditorComponent.getVariableByFormula(value, this.availableTrainingVariables);
        if (variable) {
          let uppperCaseSetParameter = setParameter.charAt(0).toUpperCase() + setParameter.slice(1);
          if (variable[setParameter] != undefined || variable['min' + uppperCaseSetParameter] != undefined) {
            return true;
          }
        }
      }
    }
    return false;
  }

  timeStringWithHoursToSeconds(timeString: string) {
    let splitted = timeString.split(':');
    if (splitted.length === 3) {
      let hours = parseInt(splitted[0]) * 3600;
      let minutes = parseInt(splitted[1]) * 60;
      let seconds = parseInt(splitted[2]);
      if (!isNaN(hours) && !isNaN(minutes) && !isNaN(seconds)) {
        return hours + minutes + seconds;
      }
    }
    else if (splitted.length === 2) {
      let minutes = parseInt(splitted[0]) * 60;
      let seconds = parseInt(splitted[1]) ?? 0;
      if (!isNaN(minutes) && !isNaN(seconds)) {
        return minutes + seconds;
      }
    }
    else if (splitted.length === 1) {
      let splittedString = splitted[0];
      if(splittedString.length <= 2) {
        let seconds = parseInt(splitted[0]);
        if (!isNaN(seconds)) {
          return seconds;
        }
      }
      else if(splittedString.length <= 4) {
        let minutesLenght = splittedString.length - 2;
        let minutes = parseInt(splittedString.slice(0, minutesLenght)) * 60;
        let seconds = parseInt(splittedString.slice(minutesLenght, 4));
        if (!isNaN(minutes) && !isNaN(seconds)) {
          return minutes + seconds;
        }
      }
      else {
        let hoursLenght = splittedString.length - 4;
        let hours = parseInt(splittedString.slice(0, hoursLenght)) * 3600;
        let minutes = parseInt(splittedString.slice(hoursLenght, hoursLenght + 2)) * 60;
        let seconds = parseInt(splittedString.slice(hoursLenght + 2, hoursLenght + 4));
        if (!isNaN(hours) && !isNaN(minutes) && !isNaN(seconds)) {
          return hours + minutes + seconds;
        }
      }
    }
    return null;
  }

  timeStringToSeconds(timeString: string) {
    let splitted = timeString.split(':');
    if (splitted.length === 2) {
      let minutes = parseInt(splitted[0]) * 60;
      let seconds = parseInt(splitted[1]);
      if (!isNaN(minutes) && !isNaN(seconds)) {
        return minutes + seconds;
      }
    }
    else {
      let splittedString = splitted[0];
      if(splittedString.length <= 2) {
        let seconds = parseInt(splitted[0]);
        if (!isNaN(seconds)) {
          return seconds;
        }
      }
      else {
        let minutesLenght = splittedString.length - 2;
        let minutes = parseInt(splittedString.slice(0, minutesLenght)) * 60;
        let seconds = parseInt(splittedString.slice(minutesLenght, 4));
        if (!isNaN(minutes) && !isNaN(seconds)) {
          return minutes + seconds;
        }
      }
    }
    return null;
  }


  public filteredVariableSuggestions: TrainingVariable[];
  public rmSuggestions: string[] = [];
  // public setRefSuggestions: string[] = [];
  public setRefSuggestions: { name: string, setIndex: string }[] = [];
  public cardioZoneSuggestions: CardioZone[] = [];

  private getAllAvailableTrainingVariables(): TrainingVariable[] {
    let variables = this.availableTrainingVariables.map(x => x);
    if (this.trainingPlan.isTemplate) {

      let globalVariables = this.userService.getLoggedInUser()?.trainingSettingsLicenceHolder?.trainingVariables?.map(x => x.clone()) ?? []
      globalVariables.forEach(variable => {
        if (variables.find(x => x.id == variable.id) == null) {
          variables.push(variable);
        }
      });
    }
    else {
      let userVariables = this.user?.trainingVariables?.map(x => x.clone()) ?? [];
      userVariables.forEach(variable => {
        if (variables.find(x => x.id == variable.id) == null) {
          variables.push(variable);
        }
      });
    }
    return variables;
  }

  setVariableSuggestions(sets: TrainingSet[], variableInput: string, setParameter: SetParameter, setIndex: number, variableSuggestionsMenuTrigger: MatMenuTrigger = null, rmSuggestionsMenuTrigger: MatMenuTrigger = null, setRefSuggestionsMenuTrigger: MatMenuTrigger = null, cardioZoneSuggestionsMenuTrigger: MatMenuTrigger = null) {
    this.rmSuggestions = [];
    this.setRefSuggestions = [];
    this.cardioZoneSuggestions = [];
    let uppperCaseSetParameter = setParameter.charAt(0).toUpperCase() + setParameter.slice(1);
    let availableTrainingVariables = this.getAllAvailableTrainingVariables()?.filter(x => x[setParameter] != undefined || x['min' + uppperCaseSetParameter] != undefined) ?? [];
    if (variableInput?.length > 0) {
      let filteredVariables = availableTrainingVariables.filter(x => x.name.toLowerCase().includes(variableInput.toLowerCase()));
      this.filteredVariableSuggestions = filteredVariables;

      if (setParameter == SetParameter.weight && variableInput.match("^([1-9]|10)R?M?$")) {
        let rmValue = variableInput.replace('R', '').replace('M', '');
        this.rmSuggestions = [rmValue + 'RM'];
      }
      else if (variableInput.startsWith('#')) {
        let splittedRefNumber = variableInput.split('#');
        let refSetNumber = parseInt(splittedRefNumber[1]);
        if (!isNaN(refSetNumber) && refSetNumber > 0) {
          this.setRefSuggestions = [{ name: '#' + refSetNumber, setIndex: "#" + (refSetNumber - 1) }];
        }
        else this.setRefSuggestions = [{ name: variableInput + '1', setIndex: "#1" }];
      }

      if (setParameter == SetParameter.heartRate || setParameter == SetParameter.pace || setParameter == SetParameter.pace500) {
        this.cardioZoneSuggestions = this.globalCardioZones?.filter(x => x.name?.toUpperCase().includes(variableInput?.toUpperCase())) ?? [];
      }
    }
    else {
      if (setParameter == SetParameter.weight) {
        for (let i = 1; i <= 10; i++) {
          this.rmSuggestions.push(i + 'RM');
        }
      }

      let warmupCount = 0;
      let dropSetCount = 0;
      for (let i = 1; i <= setIndex; i++) {
        if (sets[i - 1].isWarmupSet) {
          this.setRefSuggestions.push({ name: '#W' + (warmupCount > 0 ? warmupCount.toString() : ''), setIndex: '#' + i });
          warmupCount++;
        }
        else if (sets[i - 1].isDropset) {
          this.setRefSuggestions.push({ name: '#D' + (dropSetCount > 0 ? dropSetCount.toString() : ''), setIndex: '#' + i });
          dropSetCount++;
        }
        else {
          this.setRefSuggestions.push({ name: '#' + (i - warmupCount - dropSetCount), setIndex: '#' + i });
        }
      }
      this.filteredVariableSuggestions = availableTrainingVariables;

      if (setParameter == SetParameter.heartRate || setParameter == SetParameter.pace || setParameter == SetParameter.pace500) {
        this.cardioZoneSuggestions = this.selectedCoach.trainingSettingsLicenceHolder.cardioZones;
      }
      this.updateOneRmTrainingVariables()
    }


    //if only filteredVariableSuggestions has elements, show variableSuggestionsMenu
    if (this.filteredVariableSuggestions.length > 0 && this.rmSuggestions.length == 0 && this.setRefSuggestions.length == 0 && this.cardioZoneSuggestions.length == 0) {
      variableSuggestionsMenuTrigger?.openMenu();
      rmSuggestionsMenuTrigger?.closeMenu();
      setRefSuggestionsMenuTrigger?.closeMenu();
      cardioZoneSuggestionsMenuTrigger?.closeMenu();
    }
    else if (this.rmSuggestions.length > 0 && this.filteredVariableSuggestions.length == 0 && this.setRefSuggestions.length == 0 && this.cardioZoneSuggestions.length == 0) {
      rmSuggestionsMenuTrigger?.openMenu();
      variableSuggestionsMenuTrigger?.closeMenu();
      setRefSuggestionsMenuTrigger?.closeMenu();
      cardioZoneSuggestionsMenuTrigger?.closeMenu();
    }
    else if (this.setRefSuggestions.length > 0 && this.filteredVariableSuggestions.length == 0 && this.rmSuggestions.length == 0 && this.cardioZoneSuggestions.length == 0) {
      setRefSuggestionsMenuTrigger?.openMenu();
      rmSuggestionsMenuTrigger?.closeMenu();
      variableSuggestionsMenuTrigger?.closeMenu();
      cardioZoneSuggestionsMenuTrigger?.closeMenu();
    }
    else if (this.cardioZoneSuggestions.length > 0 && this.filteredVariableSuggestions.length == 0 && this.rmSuggestions.length == 0 && this.setRefSuggestions.length == 0) {
      cardioZoneSuggestionsMenuTrigger?.openMenu();
      variableSuggestionsMenuTrigger?.closeMenu();
      rmSuggestionsMenuTrigger?.closeMenu();
      setRefSuggestionsMenuTrigger?.closeMenu();
    }
  }

  onPercentageInputKeyDown(event, focusElement: HTMLInputElement) {
    if (event.key === '%') {
      event.stopPropagation();
      event.preventDefault();
      focusElement.focus();
    }
  }

  setSelectedRM(rmValue: string, set: TrainingSet, percentageInput: HTMLInputElement, previousPercentage: string, variableInput: HTMLInputElement, setParameter: SetParameter, setIndex: number, exercise: PlannedTrainingExercise) {
    let percentage = percentageInput?.value;
    if (percentage == null || percentage?.length <= 0) {
      percentage = previousPercentage;
    }
    if (percentage?.length > 0) {
      let multiplier = parseInt(percentage);
      if (!isNaN(multiplier)) {
        let propertyName = setParameter.toString();
        if (propertyName) {
          // set[propertyName] = parseInt(rmValueWithoutRM) * multiplier / 100;
          set[propertyName + 'Formula'] = multiplier + '%' + rmValue;
          variableInput.value = rmValue;
          this.submitFormulaInput(set, percentageInput, variableInput, setParameter, null, setIndex, exercise);
        }
      }
    }
    this.filteredVariableSuggestions = [];
    this.rmSuggestions = [];
  }

  setSelectedCardioZone(cardioZone: CardioZone, set: TrainingSet, percentageInput: HTMLInputElement, variableInput: HTMLInputElement, setParameter: SetParameter, setIndex: number, exercise: PlannedTrainingExercise) {
    variableInput.value = TrainingPlanEditorComponent.cardioZonePrefix + cardioZone.name;
    this.submitFormulaInput(set, percentageInput, variableInput, setParameter, null, setIndex, exercise);
    this.filteredVariableSuggestions = [];
  }

  setSelectedVariable(variable: TrainingVariable, set: TrainingSet, percentageInput: HTMLInputElement, variableInput: HTMLInputElement, setParameter: SetParameter, setIndex: number, exercise: PlannedTrainingExercise) {
    console.log('set selected variable', variable);
    variableInput.value = variable.name;
    this.submitFormulaInput(set, percentageInput, variableInput, setParameter, null, setIndex, exercise, variable);
    this.filteredVariableSuggestions = [];
  }

  getTrainingVariableFormula(variable: TrainingVariable, multiplier: number) {
    return multiplier + '%' + '{' + variable.id + '}';
  }

  setSetMode(exercise: PlannedTrainingExercise, set: TrainingSet, mode: string, setIndex: number) {
    if (mode === 'warmup') {
      set.isWarmupSet = true;
      set.isDropset = false;
      if (exercise.sets.filter(x => x.isWarmupSet).length === 1) {
        this.toastr.info("'W' kennzeichnet einen Warmup-Satz. Dieser Satz fließt nicht in deine Statistik mit ein.", "Info", {
          positionClass: 'toast-bottom-center'
        });
      }
    }
    else if (mode === 'dropset') {
      set.isWarmupSet = false;
      set.isDropset = true;
      if (exercise.sets.filter(x => x.isDropset).length === 1) {
        this.toastr.info("'D' kennzeichnet einen Dropset.", "Info", {
          positionClass: 'toast-bottom-center'
        });
      }
      if(setIndex > 0) {
        let previousSet = exercise.sets[setIndex - 1];
        if(previousSet.pauseDuration){
          previousSet.pauseDuration = undefined;
        }
      }
    }
    else {
      set.isWarmupSet = false;
      set.isDropset = false;
    }
  }

  parseVariableValueInput(variableNameInput: string, variable: TrainingVariable = null) {
    let trainingVariables = this.getAllAvailableTrainingVariables();
    let splittedValue = variableNameInput.split('%');
    let multiplier = parseInt(splittedValue[0]);
    let variableName = splittedValue[1]?.split('}')[0];
    if (!variable) variable = trainingVariables.find(x => x.name == variableName);
    return { multiplier: multiplier, variable: variable, variableName: variableName };
  }



  getVariableNameBySet(set: TrainingSet, setParameter: SetParameter, whithPrefix: boolean) {
    let formula = set[setParameter + 'Formula'];
    if (formula?.includes(`%${TrainingPlanEditorComponent.cardioZonePrefix}{`)) {
      let zoneId = formula.split(`%${TrainingPlanEditorComponent.cardioZonePrefix}{`)[1]?.split('}')[0];
      let zone = TrainingPlanEditorComponent.getCardioZoneById(zoneId, this.globalCardioZones);
      if (zone) {
        if (whithPrefix) {
          return TrainingPlanEditorComponent.cardioZonePrefix + zone.name;
        }
        else {
          return zone.name;
        }
      }
    }
    if (formula?.includes('%')) {
      let variable = TrainingPlanEditorComponent.getVariableByFormula(formula, this.availableTrainingVariables);
      if (variable) {
        return variable.name;
      }
      else {
        let splitted = formula?.split('%');
        if (splitted?.length > 1) {
          return splitted[1];
        }
      }
    }
    return null;
  }

  getPercentageBySet(set: TrainingSet, setParameter: SetParameter) {
    let formula = set[setParameter + 'Formula'];
    return this.getPercentageBySetFormula(formula);
  }

  getPercentageBySetFormula(formula: string) {
    let splitted = formula?.split('%');
    if (splitted?.length > 1) {
      return splitted[0];
    }
    return null;
  }
  //#region History

  public selectedHistoryDate: Date = new Date();
  public selectedHistoryTrackedSession: TrackedTrainingSession = null;

  public loadingSessionHistory: boolean = false;
  async setSelectedHistoryTrackedSession(trackedTrainingSession: TrackedTrainingSession, fallbackDate: Date = new Date()) {
    try {
      if (trackedTrainingSession == null) {
        this.selectedHistoryDate = fallbackDate;
        this.selectedHistoryTrackedSession = null;
        return;
      }
      this.loadingSessionHistory = true;
      if (trackedTrainingSession.trackedTrainingExercises == null || trackedTrainingSession.trackedTrainingExercises?.length == 0) {
        let trackedExercises = await this.userService.getTrackedTrainingExercisesBySessionId(this.user, trackedTrainingSession.id);
        trackedTrainingSession.trackedTrainingExercises = trackedExercises;
      }
      if (trackedTrainingSession.sessionName == null) {
        let plannedSession = this.trainingPlan.sessions.find(x => x.id == trackedTrainingSession.plannedSessionId);
        if (plannedSession) {
          trackedTrainingSession.sessionName = plannedSession.name;
        }
      }
      this.selectedHistoryDate = trackedTrainingSession.endDate;
      this.selectedHistoryTrackedSession = trackedTrainingSession;
    }
    catch (ex) {
      console.error(ex);
    }
    finally {
      this.loadingSessionHistory = false;
    }
  }

  getAvailableTrackedSessions() {
    return this.trainingPlan.isPeriodicPlan ? this.trainingPlan.sessions?.filter(x => x.isTracked && x.trackedTrainingSession)?.map(x => x.trackedTrainingSession) : this.trackedTrainingSessions;
  }

  getTrackedSessionsBySession(selectedSession: TrainingSession) {
    let availableTrackedSessions = this.getAvailableTrackedSessions();
    if (selectedSession?.baseSessionId) {
      // return this.getSessionColor(session.baseSessionId);
      let connectedSessions = this.trainingPlan.sessions.filter(x => !x.deleted && x.id == selectedSession.baseSessionId || x.baseSessionId == selectedSession.baseSessionId);
      let connectedSessionIds = connectedSessions?.map(x => x.id) ?? [];
      connectedSessionIds.push(selectedSession.id);
      return availableTrackedSessions?.filter(x => !x.deleted && connectedSessionIds?.includes(x.plannedSessionId)) || [];
    }

    let duplicatedSessions = this.trainingPlan.sessions?.filter(x => !x.deleted && x.baseSessionId == selectedSession.id);
    let duplicatedSessionIds = duplicatedSessions.map(x => x.id) ?? [];
    duplicatedSessionIds.push(selectedSession.id);
    return availableTrackedSessions?.filter(x => !x.deleted && duplicatedSessionIds?.includes(x.plannedSessionId)) || [];
  }

  setLatestDateWithSessionHistory(selectedSession: TrainingSession) {
    if (!selectedSession) return
    if (this.trainingPlan.isPeriodicPlan && selectedSession.isTracked && selectedSession.trackedTrainingSession) {
      this.setSelectedHistoryTrackedSession(selectedSession.trackedTrainingSession);
      return;
    }
    let trackedSessions = this.getTrackedSessionsBySession(selectedSession);
    if (trackedSessions.length > 0) {
      let latestDateSession = trackedSessions.filter(x => !x.deleted).sort((a, b) => b?.endDate?.getTime() - a?.endDate?.getTime())[0];
      this.setSelectedHistoryTrackedSession(latestDateSession)
    }
    else {
      this.setSelectedHistoryTrackedSession(null)
    }
  }

  public jumpToPreviousHistorySession() {
    let trackedSessions = this.getTrackedSessionsBySession(this.selectedSession);
    if (trackedSessions.length > 0) {
      let currentDate = this.selectedHistoryDate;
      let previousSession = trackedSessions.filter(x => x.endDate < currentDate && !x.deleted).sort((a, b) => b.endDate.getTime() - a.endDate.getTime())[0];
      this.setSelectedHistoryTrackedSession(previousSession)
    }
  }

  public jumpToNextHistorySession() {
    let trackedSessions = this.getTrackedSessionsBySession(this.selectedSession);
    if (trackedSessions.length > 0) {
      let currentDate = this.selectedHistoryDate;
      let nextSession = trackedSessions.filter(x => x.endDate > currentDate && !x.deleted).sort((a, b) => a.endDate.getTime() - b.endDate.getTime())[0];
      this.setSelectedHistoryTrackedSession(nextSession)
    }
  }

  getTrackedTrainingExerciseHistoryByPlannedExercise(plannedExercise: PlannedTrainingExercise): TrackedTrainingExercise {
    let trackedTrainingExercise = this.selectedHistoryTrackedSession?.trackedTrainingExercises?.find(x => x.plannedExerciseId == plannedExercise.id);
    return trackedTrainingExercise;
  }

  getAvailableSetParameters(trainingSets: TrackedTrainingSet[]): SetParameter[] {
    return Object.values(SetParameter).filter(parameter => this.hasValues(trainingSets, parameter))
  }
  hasValues(sets: TrackedTrainingSet[], setParameter: SetParameter): boolean {
    return sets.filter(x => x[setParameter.toString()] != null).length > 0
  }

  onHistoryDateSelected(date: Date) {
    let trackedSessions = this.getTrackedSessionsBySession(this.selectedSession);
    if (trackedSessions?.length > 0) {
      let selectedSession = trackedSessions.find(x => x.endDate?.isSameDate(date));
      this.setSelectedHistoryTrackedSession(selectedSession, date)
    }
    else {
      this.setSelectedHistoryTrackedSession(null, date);
    }
  }


  getSecondsAsTime(inputSeconds: number): string {
    if (inputSeconds == undefined) return "";
    if (inputSeconds < 0) inputSeconds = 0
    return inputSeconds.asDurationString();
  }

  //#endregion

  //duplicated
  getExerciseById(exerciseId: string): MergedTrainingExercise {
    return this.trainingService.MergedTrainingExercises.filter(x => x.sourceExerciseId == exerciseId)[0] || null
  }


  goToLink(url: string) {
    window.open(url, "_blank");
  }

}
