import { FcmService } from './../services/fcm.service';
import { Component, OnInit, NgZone, AfterViewInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';

import { FirestoreService } from '../services/firestore.service';
import { UtilityService } from '../services/utility.service';
import { NavbarService } from '../services/navbar.service';
import { ChatService } from '../services/chat.service';
import { User } from '../model/user.model';
import { Chatmessage } from '../model/chatmessage.model';
import { BroadcastChat, Chat } from '../model/chat.model';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import {MatDialog } from '@angular/material/dialog';
import { Licence } from '../model/lid.model';
import { environment } from 'src/environments/environment';
import { LicenceConnectionRequest } from '../model/licence-connection-request';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public loggedIn: boolean;
  public unreadChatMessages: Chatmessage[];

  public selectedUser: User;

  public showHelpDialog = false
  public visibleVideoNumber = null
  private profilePic: File;
  private profilePicImage = new Image();
  public selectedClientGroup: string = null

  public showEventLogView: boolean = true
  public showUnreadMessagesView: boolean = false
  public showExpiringLicensesView: boolean = false
  public showExpiringNutritionPlansView: boolean = false
  public showTasksView: boolean = false

  public eventLogTab = "tab-button active";
  public unreadMessagesTab = "tab-button";
  public expiringLicencesTab = "tab-button";
  public getExpiringNutritionPlansTab = "tab-button";
  public tasksViewTab = "tab-button";

  public environment = environment
  

  constructor(public chatService: ChatService, private authService: AuthService, private router: Router, public userService: FirestoreService, public utilityService: UtilityService, public navService: NavbarService, public dialog: MatDialog, private fcmService: FcmService) { }

  ngOnInit(): void {
    this.unreadChatMessages = [];
    this.loggedIn = this.authService.isLoggedIn();
  }

  refreshCapacitorApp(){
    window.location.reload();
  }

  onConnectNewClient() {
    this.router.navigateByUrl('licence')
  }

  logout() {
    this.userService.logout();
    this.authService.logout();
  }

  getPendingLicences() {
    return this.userService.getAccessiblePendingLicences()
  }
  
  static delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }  

  onOpenChat(chat: Chat) {
    this.chatService.openChat(chat);
  }

  openProfilePicDialog() {
    document.getElementById('input-profilepicture').click()
  }

  hasUnpinnedClients() {
    var hasUnpinned = false;
    this.userService.getAccessibleClients().forEach(c => {
      if (!this.userService.getLoggedInUser().portalSettingsCoach?.dashboardUids.includes(c.uid)) hasUnpinned = true;
    })
    return hasUnpinned;
  }

  getOptions() {
    var options = [];
    this.userService.getAccessibleClients().forEach(c => {
      if (!this.userService.getLoggedInUser().portalSettingsCoach?.dashboardUids.includes(c.uid)){
        options.push(c);
      }
    });
    return options;
  }

  resetTabs() {
    this.showEventLogView = false
    this.showUnreadMessagesView = false
    this.showExpiringLicensesView = false
    this.showExpiringNutritionPlansView = false
    this.showTasksView = false;
    this.eventLogTab = "tab-button";
    this.unreadMessagesTab = "tab-button";
    this.expiringLicencesTab = "tab-button";
    this.getExpiringNutritionPlansTab = "tab-button";
    this.tasksViewTab = "tab-button";
  }

  swapTab(tab: string) {
    this.resetTabs();
    switch(tab) {
      case "eventLogView":
        this.showEventLogView = true
        this.eventLogTab = "tab-button active";
        break;
      case "unreadMessagesView":
        this.showUnreadMessagesView = true;
        this.unreadMessagesTab = "tab-button active";
        break;
      case "expiringLicensesView":
        this.showExpiringLicensesView = true;
        this.expiringLicencesTab = "tab-button active";
        break;
      case "expiringNutritionPlansView":
        this.showExpiringNutritionPlansView = true;
        this.getExpiringNutritionPlansTab = "tab-button active";
        break;
      case "tasksView":
        this.showTasksView = true;
        this.tasksViewTab = "tab-button active";
        break;
      default:
        break;
    }
  }

  isAdmin() {
    return this.authService.isAdmin()
  }
  
  getExpiringPlanClients(): User[] {
    var maxDate = new Date()
    maxDate.addDays(2)
    return this.userService.getAccessibleClients().filter(x => x.hasExpiringNutritionPlan() || x.hasExpiringTrainingPlan())
  }
  getNumberOfExpiringPlans() {
    return this.userService.getAccessibleClients().reduce((value, current) => value + (current.hasExpiringNutritionPlan() && current.hasExpiringTrainingPlan() ? 2 : ( current.hasExpiringNutritionPlan() || current.hasExpiringTrainingPlan() ? 1 : 0 ) ), 0)
  }

  deleteUserFromDashboard(uid: string) {
    this.userService.deleteUserFromDashboard(uid);
  }

  onAssignNutritionalGoal(uid: string) {
    this.router.navigateByUrl('client/' + uid + '?tab=settings')
  }
  openAccountSettings() {
    this.router.navigateByUrl('settings/?tab=account')
  }

  onHideExpiringNutritionPlanHint(user: User) {
    this.userService.dismissExpiringNutritionPlanHint(user)
  }
  onHideExpiringTrainingPlanHint(user: User) {
    this.userService.dismissExpiringTrainingPlanHint(user)
  }
  onHideCreateSubscriptionHint() {
    this.userService.getLoggedInUser().portalSettingsLicenceHolder.hideCreateSubscriptionHint = true
    this.userService.updatePortalSettingsForLicenceHolder(this.userService.getLoggedInUser())
  }
  onHideTutorialsHint() {
    this.userService.getLoggedInUser().portalSettingsCoach.hideTutorialsHint = true
    this.userService.updatePortalSettingsForCoach(this.userService.getLoggedInUser())
  }
  onHideCoachAppHint() {
    this.userService.getLoggedInUser().portalSettingsCoach.hideCoachAppHint = true
    this.userService.updatePortalSettingsForCoach(this.userService.getLoggedInUser())
  }

  onAddGroupToDashboard(group: string) {
    this.userService.getLoggedInUser().portalSettingsCoach.dashboardClientGroups.push(group)
    this.userService.updateDashboardItems(this.userService.getLoggedInUser())
  }
  onAddClientToDashboard(client: User) {
      this.userService.getLoggedInUser().portalSettingsCoach.dashboardUids.push(client.uid)
      this.userService.updateDashboardItems(this.userService.getLoggedInUser())
  }
  onRemoveGroupFromDashboard(group: string) {
    if (this.userService.getLoggedInUser().portalSettingsCoach.dashboardClientGroups.includes(group)) {
      this.userService.getLoggedInUser().portalSettingsCoach.dashboardClientGroups.splice(this.userService.getLoggedInUser().portalSettingsCoach.dashboardClientGroups.indexOf(group), 1)
      this.userService.updateDashboardItems(this.userService.getLoggedInUser())
    }
  }
  onClientGroupSelected(group: string) {
    this.selectedClientGroup = group
  }
  getClientsForSelectedGroup(): User[] {
     if (!this.selectedClientGroup) return this.userService.getAccessibleClients()
     return this.userService.getAccessibleClients().filter(client => client.metadataUser?.assignedClientGroups.includes(this.selectedClientGroup))
  }
  onUnselectClientGroup() {
    this.selectedClientGroup = null
  }

  onDeactivateLicence(licence: Licence) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du die Lizenz wirklich deaktivieren? ' + licence.user?.name + ' kann danach nicht mehr in der Coaching Zone von dir betreut werden.', title: 'Lizenz deaktivieren' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userService.deactivateLicence(licence.lid);
      }
    });
  }
  uploadProfilePic(e) {
    this.profilePic = e.target.files[0];
    this.profilePicImage.src = URL.createObjectURL(this.profilePic);
    this.profilePicImage.onload = () => {
      var width = this.profilePicImage.width;
      var height = this.profilePicImage.height;

      var p = new Image();
      p.width = 250;
      var i = width / 250;
      p.height = height / i;
      p.src = this.profilePicImage.src;
      
      var x = 0;
      var y = 0;
      var min = height;
      if (width > height) {
        x = (width - height) / 2;
      } else {
        y = (height - width) / 2;
        min = width;
      }
      const elem = document.createElement('canvas');
      elem.width = 250;
      elem.height = 250;
      const ctx = elem.getContext('2d');
      ctx.drawImage(p, x, y, min, min, 0, 0, 250, 250);
      ctx.canvas.toBlob((blob) => {
          const file = new File([blob], "profile_picture.jpg", {
              type: 'image/jpeg',
              lastModified: Date.now()
          });
          this.userService.uploadProfileImage(file);
      }, 'image/jpeg', 1);
    }
    //this.userService.uploadProfileImage(this.profilePic);
  }

  getExpiringLicencesThreshold() {
    return this.userService.expiringLicencesThreshold
  }
  updateExpiringLicencesThreshold(threshold: number) {
    this.userService.expiringLicencesThreshold = threshold
    this.userService.refreshExpiringLicences()
  }
  
  onSendBroadcastMessageToClientGroup(clientGroup: string) {
    let broadcastChat: BroadcastChat = new BroadcastChat();
    broadcastChat.selectedClientGroups.push(clientGroup)
    this.chatService.openChat(broadcastChat)
  }

  async onDeclineConnectionRequest(request: LicenceConnectionRequest) {
    await this.userService.deleteLicenceConnectionRequest(request)
  }
  
  async onAcceptConnectionRequest(request: LicenceConnectionRequest) {
    var licence = await this.userService.allocateActiveLicence(request.userUid)
    if (licence) {
      await this.userService.setCurrentLicenceForUser(licence)
      await this.userService.deleteLicenceConnectionRequest(request)
    }
  }
}
