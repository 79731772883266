<div class="dialog-topbar">
    <div class="icon-button float-right" (click)="onCancel()">
        Abbrechen
    </div>
</div>
<div class="dialog-content-wrapper">
    <input [(ngModel)]="email" class="input wide {{email.length > 0 ? '' : 'invalid'}}" type="text" placeholder="Email-Adresse"/>
    <div class="spacer-10"></div>
    <input [(ngModel)]="password" class="input wide {{password.length >= 8 ? '' : 'invalid'}}" type="text" placeholder="Passwort"/>
    <div class="spacer-10"></div>
    <input [(ngModel)]="name" class="input wide {{name.length > 0 ? '' : 'invalid'}}" type="text" placeholder="Name"/>
    <div class="spacer-10"></div>
    <input class="input checkbox" type="checkbox" [checked]="onTrial" (change)="onIsOnTrialToggled($event.target.checked)"> In Probephase
    <div class="spacer-10"></div>

    <div *ngIf="email.length > 0 && password.length >= 8 && name.length > 0" class="icon-button" (click)="createUserAccount()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
        Erstellen
    </div>
</div>
<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>