import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/auth.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { ILanguageDictionary } from 'src/app/model/languagedictionary.model';
import { SharedFile, SharedFileType } from 'src/app/model/sharedfile.model';
import { User } from 'src/app/model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { LanguageService } from 'src/app/services/language.service';
import { SingleExerciseComponent } from 'src/app/training/single-exercise/single-exercise.component';
import { ImageEditorComponent } from 'src/app/utilities/image-editor/image-editor.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-file-editor-dialog',
  templateUrl: './file-editor-dialog.component.html',
  styleUrls: ['./file-editor-dialog.component.css']
})
export class FileEditorDialogComponent {

  public environment = environment

  public file: SharedFile = new SharedFile()
  public sharedFileType: SharedFileType = SharedFileType.File

  private newThumbnail: Blob;
  public thumbnailImageSrc: any;
  public removeThumbnail: boolean = false;

  private existingPersonalFiles: SharedFile[] = []

  public SharedFileType = SharedFileType

  public SharedFile = SharedFile;

  public urlLinkPattern = "http(s)?://.*";

  public descriptionPlaceholder = 'Beschreibung';

  constructor(public dialogRef: MatDialogRef<FileEditorDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {selectedFile: SharedFile, sharedFileType: SharedFileType, existingPersonalFiles: SharedFile[]}, public authService: AuthService, public languageService: LanguageService, private dialog: MatDialog, private toastr: ToastrService, private userService: FirestoreService){
    this.file = data.selectedFile
    this.sharedFileType = data.sharedFileType
    this.existingPersonalFiles = data.existingPersonalFiles

    if(this.sharedFileType == SharedFileType.VideoLink || this.file.isVideoLink()){
      this.descriptionPlaceholder = 'Formatierung:\n\n' +

      '**fett**\n oder *kursiv*\n\n' +

      'Ungeordnete Liste:\n' +
      '- Element 1\n\n' +

      'Geordnete Liste:\n' +
      '1. Element a\n' +
      '2. Element b';
      }
    
    this.thumbnailImageSrc = this.file.thumbnailLink || "";
  }

  onSaveFile(){
    if(this.sharedFileType == SharedFileType.Folder){
      if (this.file.name.GetValue(this.languageService.selectedLanguageCode)?.length == 0 || this.file.name.GetValue(this.languageService.selectedLanguageCode)?.includes('/') || this.file.name.GetValue(this.languageService.selectedLanguageCode)?.includes('.')) {
        this.toastr.error('Fehler: Der Ordnername darf kein "." oder "/" enthalten.', '',  {
          positionClass: 'toast-bottom-center'
        })
        return
      }
      var format = /[!@#$%^&*()+=\[\]{};':"\\|,.<>\/?]+/
      if (format.test(this.file.name.GetValue(this.languageService.selectedLanguageCode))) {
        this.toastr.error('Fehler: Der Ordnername darf keine Sonderzeichen enthalten.', '',  {
          positionClass: 'toast-bottom-center'
        })
        return
      }
      if(this.file.isFolder() && this.file.id == null){
        for (var file of this.existingPersonalFiles) {
          if (file.isFolder() && file.name.GetValue(this.languageService.selectedLanguageCode) == this.file.name.GetValue(this.languageService.selectedLanguageCode)) {
            this.toastr.error("Fehler: Es existiert bereits ein Ordner mit diesem Namen.", "",  {
              positionClass: 'toast-bottom-center'
            })
            return
          }
        }
      }
      this.file.fileNameTranslation.de = this.file.name.de
      this.file.fileNameTranslation.en = this.file.name.en
    }
    if(this.sharedFileType == SharedFileType.WebLink){
      if(this.file.webLink?.length == 0){
        this.toastr.error("Fehler: Der Link darf nicht leer sein.", "",  {
          positionClass: 'toast-bottom-center'
        })
        return
      }

      if(this.file.webLink?.match(this.urlLinkPattern) == null){
        this.toastr.error("Fehler: Der Link ist nicht gültig.", "",  {
          positionClass: 'toast-bottom-center'
        })
        return
      }
    }
    if(this.sharedFileType == SharedFileType.VideoLink){
      if(this.file.webLink?.length == 0){
        this.toastr.error("Fehler: Der Link darf nicht leer sein.", "",  {
          positionClass: 'toast-bottom-center'
        })
        return
      }

      if(!this.file.isVideoLink()){
        this.toastr.error("Fehler: Der Link ist nicht gültig.", "",  {
          positionClass: 'toast-bottom-center'
        })
        return
      }
    }

    if(this.file.description.GetValue(this.languageService.selectedLanguageCode) == this.descriptionPlaceholder){
      this.file.description.SetValue(this.languageService.selectedLanguageCode, '');
    }

    this.dialogRef.close({save: true, file: this.file, newThumbnail: this.newThumbnail, removeThumbnail: this.removeThumbnail});
  }

  onCancelEdit(){
    let typeName = this.sharedFileType == SharedFileType.Folder ? 'Ordner' : this.sharedFileType == SharedFileType.WebLink ? 'Link' : this.sharedFileType == SharedFileType.VideoLink ? 'Video-Link' : 'Datei';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du die Änderungen vor dem Schließen speichern?', title:  typeName + ' speichern', positiveButton: 'Ja', negativeButton: 'Nein' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.onSaveFile();
      } else {
        this.dialogRef.close()
      }
    });
  }

  onDeleteFile(){
    this.dialogRef.close({save: true, file: this.file, delete: true});
  }

  setLanguageDictionaryValue(file: SharedFile, propertyName: string, value: string){
    let languageDictionary = file[propertyName] as ILanguageDictionary<any>;
    languageDictionary.SetValue(this.languageService.selectedLanguageCode, value);
    file[propertyName] = languageDictionary;
  }

  
  onDragOver(event) {
    event.preventDefault();
  }

  onRemoveThumbnail(){
    this.removeThumbnail = true;
    this.thumbnailImageSrc = null;
    this.newThumbnail = null;
  }
  
  openThumbnailDialog() {
    (document.querySelector('#thumbnail-input') as HTMLElement).click()
  }
  
  onDropThumbnail(event) {
    event.preventDefault();
    if (event.dataTransfer.files?.length > 0) {
      var file = event.dataTransfer.files[0]
      if (!SingleExerciseComponent.checkUploadFile(file, 50000000, ["jpg", "jpeg", "png", "heic", "heif"], this.toastr)) return
      this.convertThumbnail(file);
    }
  }

  uploadThumbnail(e) {
    if (e.target.files && e.target.files[0]) {
      if (!SingleExerciseComponent.checkUploadFile(e.target.files[0], 50000000, ["jpg", "jpeg", "png", "heic", "heif"], this.toastr)) return;
      this.convertThumbnail(e.target.files[0])
    }
  }

  convertThumbnail(thumbnail: File) {
    let aspectRatio = (this.file?.isVideo() || this.sharedFileType == SharedFileType.Folder || this.file?.isFolder()) ? 16/9 : 1;
    const dialogRef = this.dialog.open(ImageEditorComponent, {
      data: { imageFile: thumbnail, aspectRatio: aspectRatio, maintainAspectRatio: true, containWithinAspectRatio: true, maxDimension: 720 },
      width: '1000px',
    });
    dialogRef.afterClosed().subscribe(async result => {
      if(result?.croppedImage) {
        this.thumbnailImageSrc = await this.blobToBase64(result.croppedImage);
        this.newThumbnail = result.croppedImage;
        this.removeThumbnail = false;
        this.file.thumbnailPath = thumbnail.name;
      }
    });
  }

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  getPrintableAssignments(sharedFile: SharedFile):string{
    var checkedElements = '';
    if(sharedFile.assignedGroupNames != null){
      if (sharedFile.assignedGroupNames?.includes('Alle')) return 'Alle'
      sharedFile.assignedGroupNames.forEach(name => {
        if(this.getSelectableClientGroups()?.includes(name)){
          if(checkedElements.length > 0){
            checkedElements = checkedElements.concat(', ')
          }
          checkedElements = checkedElements.concat(name);
        }
      });
    }
    this.getSelectableClients()?.forEach(element => {
      if(sharedFile.assignedUids?.includes(element.uid)){
        if(checkedElements.length > 0){
          checkedElements = checkedElements.concat(', ')
        }
        checkedElements = checkedElements.concat(element.getName());
      }
    });
    return checkedElements;
  }

  onTargetSelectionChangedGroup(group: string){
    if (!this.canAccessGroup(group)) return
    if (this.file.assignedGroupNames.includes(group)) {
      this.file.assignedGroupNames.forEach( (item, index) => {
        if (item == group) this.file.assignedGroupNames.splice(index, 1)
      })
    } else {
      this.file.assignedGroupNames.push(group)
    }
  }

  onTargetSelectionChangedUser(user: User){
    if (!this.canAccessUser(user)) return
    if (this.file.assignedUids.includes(user.uid)) {
      this.file.assignedUids.forEach( (item, index) => {
        if (item == user.uid) this.file.assignedUids.splice(index, 1)
      })
    } else {
      this.file.assignedUids.push(user.uid)
    }
  }
  
  canAccessUser(user: User) {
    return this.userService.getLoggedInUser().coach.canAccessUser(user)
  }

  canAccessGroup(group: string) {
    return this.userService.getLoggedInUser().coach.canAccessClientGroup(group)
  }

  areAllUsersTargeted() {
    return this.file.assignedGroupNames?.includes('Alle')
  }

  getSelectableClients() {
    return this.userService.getAccessibleClients()
  }

  getSelectableClientGroups() {
    return this.userService.getClientGroups()
  }
}
