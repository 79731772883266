<div class="dialog-topbar">
    <h3>{{title}}</h3>
</div>
<mat-radio-group
  aria-labelledby="radio-group-label"
  class="radio-group"
  [(ngModel)]="selectedRadioButton">
  <mat-radio-button class="radio-button" *ngFor="let button of radioButtons" [value]="button" color="primary">
    {{button}}
  </mat-radio-button>
</mat-radio-group>

<mat-dialog-actions>
    <div class="actions">
        <button class="button-filled small light marginright-25" (click)="onCancelDialog()">{{cancelButtonText}}</button>
        <button class="button-filled small light float-right" (click)="onConfirmDialog()">{{submitButtonText}}</button>
    </div>
</mat-dialog-actions>