export class BodyData {
    id: number;
    date: Date;
    bodyWeight: number;
    bodyHeight: number;
    chestCircumference: number;
    hipCircumference: number;
    waistCircumference: number;
    leftArmCircumference: number;
    rightArmCircumference: number;
    leftThighCircumference: number;
    rightThighCircumference: number;
    leftCalfCircumference: number;
    rightCalfCircumference: number;
    bellyCircumference: number;
    seatCircumference: number;

    bodyFat: number;
    bodyFatPercentage: number;
    bmi: number;
    bodyWeightWeeklyAverage: number;

    migrated: boolean;
    
    constructor();
    constructor(init: BodyData);
    constructor(init?: BodyData) {
        this.id = init && init.id != null ? init.id : null
        this.date = init && init.date || null
        this.bodyWeight = init && init.bodyWeight && init.bodyWeight > 0 ? init.bodyWeight : undefined
        this.bodyHeight = init && init.bodyHeight && init.bodyHeight > 0 ? init.bodyHeight : undefined
        this.chestCircumference = init && init.chestCircumference && init.chestCircumference > 0 ? init.chestCircumference : undefined
        this.hipCircumference = init && init.hipCircumference && init.hipCircumference > 0 ? init.hipCircumference : undefined
        this.waistCircumference = init && init.waistCircumference && init.waistCircumference > 0 ? init.waistCircumference : undefined
        this.leftArmCircumference = init && init.leftArmCircumference && init.leftArmCircumference > 0 ? init.leftArmCircumference : undefined
        this.rightArmCircumference = init && init.rightArmCircumference && init.rightArmCircumference > 0 ? init.rightArmCircumference : undefined
        this.rightThighCircumference = init && init.rightThighCircumference && init.rightThighCircumference > 0 ? init.rightThighCircumference : undefined
        this.leftThighCircumference = init && init.leftThighCircumference && init.leftThighCircumference > 0 ? init.leftThighCircumference : undefined
        this.leftCalfCircumference = init && init.leftCalfCircumference && init.leftCalfCircumference > 0 ? init.leftCalfCircumference : undefined
        this.rightCalfCircumference = init && init.rightCalfCircumference && init.rightCalfCircumference > 0 ? init.rightCalfCircumference : undefined
        this.bellyCircumference = init && init.bellyCircumference && init.bellyCircumference > 0 ? init.bellyCircumference : undefined
        this.seatCircumference = init && init.seatCircumference && init.seatCircumference > 0 ? init.seatCircumference : undefined

        this.bodyFat = init && init.bodyFat && init.bodyFat > 0 ? init.bodyFat : undefined
        this.bodyFatPercentage = this.bodyFat * 100 || undefined
        this.bmi = init && init.bmi || undefined
        this.bodyWeightWeeklyAverage = init && init.bodyWeightWeeklyAverage || undefined

        this.migrated = init && init.migrated || false
    }

}