<div class="page-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-9 col-lg-9 col-xl-8 col-xxl-4 ">
                <h2 class="">Einstellungen</h2>
            </div>
            <div class="col-2">
                <div class="icon-button" (click)="onShowTeamManagement()">
                    Trainerverwaltung
                </div>
            </div>
        </div>

        <div class="row" *ngIf="!showTeamManagement">
            <div class="col-6">
                <div *ngIf="coach" class="layout-box">
                    <h3>Coach</h3>
                    <div class="spacer-10"></div>
                    <div class="label">Name</div>
                    <div class="spacer-10"></div>
                    <input [(ngModel)]="coach.name" class="input small wide {{coach.name.length > 0 ? '' : 'invalid'}}" type="text" placeholder="Name"/>
                    
                    <div class="spacer-25"></div>
                </div>
            </div>
            <div class="col-6">
                <div class="layout-box">
                    <h3>App</h3>
                    <div class="spacer-10"></div>

                    <div class=" toggle-wrapper mobile-marginbottom-10 display-block">
                        <div class="toggle {{isNutritionEnabled ? 'on' : ''}}" (click)="onNutritionEnabledChanged(!isNutritionEnabled)">
                            <div class="handle"></div>
                        </div>
                        <div class="text">
                            Ernährung aktivieren
                        </div>
                    </div>
                    <div class="spacer-25"></div>

                    <div class=" toggle-wrapper mobile-marginbottom-10 display-block">
                        <div class="toggle {{isDarkModeEnabled ? 'on' : ''}}" (click)="onDarkModeEnabledChanged(!isDarkModeEnabled)">
                            <div class="handle"></div>
                        </div>
                        <div class="text">
                            Darkmode aktivieren
                        </div>
                    </div>
                    <div class="spacer-10" *ngIf="darkModeSettingChanged"></div>
                    <div class="label display-block" *ngIf="darkModeSettingChanged">
                        Lade die Seite neu, um die Änderungen zu übernehmen.
                    </div>
                    <div class="spacer-25"></div>

                    <div class="recipesharing-toggle toggle-wrapper">
                        <div class="toggle {{coach?.coacheeVoiceMessageEnabled ? 'on' : ''}}" (click)="onVoiceMessagesEnabledChanged()">
                            <div class="handle"></div>
                        </div>
                        <div class="text">
                            Versand von Sprachnachrichten durch Coachees erlauben
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="showTeamManagement">
            <div class="col-12">
                <app-team-management></app-team-management>
            </div>
        </div>
    </div>
</div>