<div *ngIf="mode == LIST_VIEW">
    <div class="row">
        <div class="col-12 col-xxxl-6">
            <div class="layout-box adjust-mobile">
                <h3 class="display-inlineblock">Nährstoffziele</h3>
                <div class="icon-button display-inlineblock marginleft-25 mr-auto pr-2" (click)="onCreateConfig()">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                    </svg>
                    Erstellen
                </div>
                <div class="display-inlineblock marginleft-10 float-right">
                    <div class="icon-button" mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-gear padding-4" viewBox="0 0 16 16">
                            <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0"/>
                            <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z"/>
                        </svg>
                        <span class="only-desktop">Weitere Einstellungen</span>
                    </div>
                    <mat-menu #menu="matMenu" class="padding-15">
                        Weitere Ernährungseinstellungen:
                        <div class="spacer-10"></div>
                        <div class="recipesharing-toggle toggle-wrapper margin-4" matTooltip="Blende alle Kalorien- und Ernährungs-relevanten Zahlen in der App aus.">
                            <div class="toggle {{user.hideNutritionValues ? 'on' : ''}}" (click)="onHideNutritionValuesChanged(!user.hideNutritionValues)">
                                <div class="handle"></div>
                            </div>
                            <div class="text">
                                Kalorien/Nährstoffe in App ausblenden
                            </div>
                        </div>
                        <div class="spacer-10"></div>
                        <div class="recipesharing-toggle toggle-wrapper margin-4 mobile-marginbottom-10" matTooltip="Aktiviere, dass Coachees sich automatisch Rezeptvorschläge erstellen lassen können.">
                            <div class="toggle {{isRecipeSuggestionsEnabled() ? 'on' : ''}}" (click)="onEnableRecipeSuggestionsChanged(!isRecipeSuggestionsEnabled())">
                                <div class="handle"></div>
                            </div>
                            <div class="text">
                                Rezeptvorschläge in App zulassen <span *ngIf="user.recipeSuggestionsEnabled == null">(Standardeinstellung)</span>
                            </div>
                        </div>
                        <div class="spacer-10"></div>
                        <div class="recipesharing-toggle toggle-wrapper margin-4 mobile-marginbottom-10" matTooltip="Aktiviere, dass Coachees ihre Nährstoffziele in der App selbst anpassen können.">
                            <div class="toggle {{user.coacheeNutritionalGoalEditingEnabled ? 'on' : ''}}" (click)="onEnableCoacheeNutritionalGoalEditingChanged(!user.coacheeNutritionalGoalEditingEnabled)">
                                <div class="handle"></div>
                            </div>
                            <div class="text">
                                Anpassung durch Coachee erlauben
                            </div>
                        </div>
                    </mat-menu>
                </div>
                <div class="spacer-10"></div>
                
                <div class="row margin-0 width-100">
                    <ng-container *ngFor="let config of configs">
                        <div *ngIf="!config.isOlderThanToday() || showAllNutritionConfigs" class="col-12 nutritiongoal-container padding-0">
                            <div class="timeline-container">
                                <div class="timeline"></div>
                                <div class="point {{config.isCurrentlyActive() ? ' active' : ''}}"></div>
                                <div class="label top-date">{{config.endDate ? config.endDate.asShortFormatedString() : ''}}</div>
                                <div class="label bottom-date">{{config.startDate.asShortFormatedString()}}</div>
                            </div>
                            <div class="goal-description link" (click)="onEditConfig(config)">
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-lg-6 col-xl-5 col-xxl-6">
                                        {{config.getName()}}<br>
                                        <div class="label">{{config.startDate.asFormatedString()}} {{config.endDate ? ' bis ' + config.endDate.asFormatedString() : ' bis unbegrenzt'}}</div>
                                        <div class="spacer-10"></div>
                                        <div class="goal-summary row">
                                            <ng-container *ngFor="let situation of config.situations">
                                                <div class="col-12 col-xxl-6">
                                                    <div *ngIf="config.situations.length > 1" class="label bold">{{situation.getName()}}</div>
                                                    <div class="row">
                                                        <ng-container *ngFor="let goal of situation.nutritionalGoals">
                                                            <div class="col-12">
                                                                <div class="label">{{goal.getPrintableGoal()}}</div>
                                                                <div *ngIf="situation.nutritionalGoals.length > 1" class="weekday-view">
                                                                    <div class="weekday-point {{goal.applicableWeekdays.includes('mon') ? ' active' : ''}}">M</div>
                                                                    <div class="weekday-point {{goal.applicableWeekdays.includes('tue') ? ' active' : ''}}">D</div>
                                                                    <div class="weekday-point {{goal.applicableWeekdays.includes('wed') ? ' active' : ''}}">M</div>
                                                                    <div class="weekday-point {{goal.applicableWeekdays.includes('thu') ? ' active' : ''}}">D</div>
                                                                    <div class="weekday-point {{goal.applicableWeekdays.includes('fri') ? ' active' : ''}}">F</div>
                                                                    <div class="weekday-point {{goal.applicableWeekdays.includes('sat') ? ' active' : ''}}">S</div>
                                                                    <div class="weekday-point {{goal.applicableWeekdays.includes('sun') ? ' active' : ''}}">S</div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div class="spacer-10"></div>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-6 col-xl-7 col-xxl-6">
                                        <div class="spacer-10"></div>
                                        <div *ngIf="!config.isOlderThanToday()" class="icon-button marginleft-50 mobile-marginleft-10" (click)="onEditConfig(config);$event.stopPropagation()">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16">
                                                <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                                            </svg>
                                            Anpassen
                                        </div>
                                        <div class="icon-button marginleft-50 mobile-marginleft-10" (click)="onDuplicateConfig(config);$event.stopPropagation()">
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="padding-4 bi bi-files" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z"/>
                                                <path d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z"/>
                                            </svg>
                                            Duplizieren
                                        </div>
                                        <div *ngIf="!config.isCurrentlyActive() && !config.isOlderThanToday()" class="icon-button marginleft-50 mobile-marginleft-10" (click)="onDeleteConfig(config);$event.stopPropagation()">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                            </svg>
                                            Löschen
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="spacer-25"></div>
                    <div *ngIf="hasOlderNutritionConfigs && !showAllNutritionConfigs" class="icon-button display-inlineblock" (click)="onShowAllNutritionConfigs(true)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        </svg>
                        Alle anzeigen
                    </div>
                    <div *ngIf="hasOlderNutritionConfigs && showAllNutritionConfigs" class="icon-button display-inlineblock" (click)="onShowAllNutritionConfigs(false)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                        </svg>
                        Alte verbergen
                    </div>
                </div>
            </div>
        </div>
        
        <div class="col-12 col-xxxl-6">
            <div class="layout-box adjust-mobile">
                <h3 class="display-inlineblock">Ernährungspläne</h3>
                <div class="label mb-3" *ngIf="onPhone()">
                    <i class="bi bi-info-circle"></i>
                    Ernährungspläne können auf mobilen Geräten nicht bearbeitet werden. Verwende dazu ein Gerät mit einem größeren Bildschirm.
                </div>
                <div *ngIf="!onMobile()" class="icon-button display-inlineblock marginleft-25" (click)="onCreatePlanConfig()">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                    </svg>
                    Erstellen
                </div>
                <div *ngIf="!onMobile()" class="icon-button display-inlineblock marginleft-25" (click)="onShowNutritionPlanTemplates()">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                    </svg>
                    Aus Vorlage erstellen
                </div>
                <div class="row margin-0">
                    <div *ngIf="showNutritionPlanTemplates" class="col-12 layout-box sub-box dropzone" (dragover)="onDragOverTemplateDropzone($event)" (dragend)="onDragEndTemplate($event, null)" (drop)="onDropOnTempateDropzone($event)">
                        <div class="fontsize-small">Vorlage hier ablegen</div>
                    </div>
                    <ng-container *ngFor="let planConfig of planConfigs">
                        <div *ngIf="!planConfig.isOlderThanToday() || showAllNutritionPlanConfigs" class="col-12 layout-box sub-box link" (click)="onOpenNutritionPlanConfig(planConfig)">
                            <div *ngIf="planConfig.isCurrentlyActive()" class="active-marker"></div>
                            <div class="row">
                                <div class="col-3 col-sm-3 col-md-2 col-lg-1">
                                    <div class="plan-indicator {{planConfig.isActive && planConfig.isCurrentlyActive() ? 'full' : ''}} {{planConfig.isActive && !planConfig.isCurrentlyActive() ? 'bordered' : ''}}" (click)="onActivatePlanConfig(planConfig);$event.stopPropagation()"></div>
                                    <div *ngIf="!planConfig.isActive" class="icon-button plan-indicator-action" (click)="onActivatePlanConfig(planConfig);$event.stopPropagation()">
                                        Verteilen
                                    </div>
                                    <div class="spacer-10"></div>
                                    <div *ngIf="!planConfig.isActive" class="label">Entwurf</div>
                                    <div *ngIf="planConfig.isActive && planConfig.isInFuture() && !planConfig.isCurrentlyActive()" class="label">Geplant</div>
                                    <div *ngIf="planConfig.isActive && planConfig.isCurrentlyActive()" class="label">Aktiv</div>
                                    <div *ngIf="planConfig.isActive && !planConfig.isInFuture() && !planConfig.isCurrentlyActive()" class="label">Abgelaufen</div>
                                </div>
                                <div class="col-8 col-sm-4 col-xl-2">
                                    {{planConfig.getName()}}<br>
                                    <div class="label">{{planConfig.getPrintableDateRange()}}</div>
                                </div>
                                <div *ngIf="!onMobile() && planConfig.canAccess(coach)" class="col-6 col-xl-8">
                                    <div class="spacer-10"></div>
                                    <div *ngIf="!planConfig.isOlderThanToday()" class="icon-button marginleft-50" (click)="onEditNutritionPlanConfig(planConfig);$event.stopPropagation()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16">
                                            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                                        </svg>
                                        Anpassen
                                    </div>
                                    <div class="icon-button marginleft-50">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                        </svg>
                                        Öffnen
                                    </div>
                                    <div class="icon-button marginleft-50" (click)="onDuplicateNutritionPlanConfig(planConfig);$event.stopPropagation()">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="padding-4 bi bi-files" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z"/>
                                            <path d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z"/>
                                        </svg>
                                        Duplizieren
                                    </div>
                                    <div *ngIf="planConfig.isInFuture() && !planConfig.isCurrentlyActive()" class="icon-button marginleft-50" (click)="onDeleteNutritionPlanConfig(planConfig);$event.stopPropagation()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                        </svg>
                                        Löschen
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div *ngIf="hasOlderNutritionPlanConfigs && !showAllNutritionPlanConfigs" class="icon-button display-inlineblock marginleft-25" (click)="onShowAllNutritionPlanConfigs(true)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        </svg>
                        Alle anzeigen
                    </div>
                    <div *ngIf="hasOlderNutritionPlanConfigs && showAllNutritionPlanConfigs" class="icon-button display-inlineblock marginleft-25" (click)="onShowAllNutritionPlanConfigs(false)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                        </svg>
                        Alte verbergen
                    </div>
                </div>
            </div>
        </div>
        
        <!--div *ngIf="showMetricsAndQuestionaires" class="col-12">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <app-metrics-assignment [selectedUser]="user" [selectedCoach]="coach"></app-metrics-assignment>
                </div>
                <div class="col-12 col-lg-6">
                    <app-user-questionaires-overview [user]="user"></app-user-questionaires-overview>
                </div>
            </div>
        </div>
        
        <div *ngIf="showTrainingPlans" class="col-12">
            <app-user-training-plan-overview *ngIf="coach.isTrainingEnabled() && user?.versionCode > 142" [selectedUser]="user"></app-user-training-plan-overview>
        </div-->
    </div>
</div>

<div *ngIf="mode == WEEK_VIEW" class="weeklyview">

    <div class="row">
        <div class="col-12">
            <div class="month-selection">
                <div class="icon-button margin-10 float-left" (click)="onCloseNutritionPlanConfig()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                    </svg>
                    Zurück zur Übersicht
                </div>
            </div>
        </div>
        <div class="spacer-25"></div>
        <div class="col-4 col-xl-3">
            <div class="display-inlineblock">
                <h3 class="display-inlineblock margin-0 float-left">{{openedPlanConfig?.getName()}}</h3>
                <div *ngIf="user?.hasOtherLanguageSettings()" class="display-inlineblock marginleft-10">
                    <app-language-selector></app-language-selector>
                </div>
                <div class="plan-toolbar show float-right">
                    <div class="icon-button marginhorizontal-5" (click)="onAutoFillPlan(openedPlanConfig)" tooltip="AutoFill">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-magic" viewBox="0 0 16 16">
                            <path d="M9.5 2.672a.5.5 0 1 0 1 0V.843a.5.5 0 0 0-1 0v1.829Zm4.5.035A.5.5 0 0 0 13.293 2L12 3.293a.5.5 0 1 0 .707.707L14 2.707ZM7.293 4A.5.5 0 1 0 8 3.293L6.707 2A.5.5 0 0 0 6 2.707L7.293 4Zm-.621 2.5a.5.5 0 1 0 0-1H4.843a.5.5 0 1 0 0 1h1.829Zm8.485 0a.5.5 0 1 0 0-1h-1.829a.5.5 0 0 0 0 1h1.829ZM13.293 10A.5.5 0 1 0 14 9.293L12.707 8a.5.5 0 1 0-.707.707L13.293 10ZM9.5 11.157a.5.5 0 0 0 1 0V9.328a.5.5 0 0 0-1 0v1.829Zm1.854-5.097a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L8.646 5.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0l1.293-1.293Zm-3 3a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L.646 13.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0L8.354 9.06Z"/>
                        </svg>
                    </div>
                    <div class="icon-button marginhorizontal-5" (click)="onSavePlanConfigAsTemplate(openedPlanConfig)" tooltip="Plan als Vorlage speichern">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16">
                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                        </svg>
                    </div>
                    <div class="icon-button marginhorizontal-5" (click)="onExportPlanConfig(openedPlanConfig)" tooltip="Als PDF speichern">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                            <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"/>
                        </svg>
                    </div>
                </div>
                <div class="spacer-10"></div>
                <div class="fontsize-small">{{openedPlanConfig.getPrintableDateRange()}}</div>
                <div *ngIf="openedPlanConfig.getNutritionPlans().length > 1" class="fontsize-small">{{openedPlanConfig.getNutritionPlans().length}} Pläne</div>
            </div>
            <div class="spacer-10"></div>
            <div class="plan-selection">
                <div *ngIf="openedPlanConfig?.getNutritionPlans().length > 1" class="button-wrapper">
                    <div class="label">Angezeigter Plan:</div>
                    <button class="button-filled light small dropdown-toggle" type="button" id="dropdownMealType" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grid-3x2" viewBox="0 0 16 16">
                            <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h13A1.5 1.5 0 0 1 16 3.5v8a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 11.5v-8zM1.5 3a.5.5 0 0 0-.5.5V7h4V3H1.5zM5 8H1v3.5a.5.5 0 0 0 .5.5H5V8zm1 0v4h4V8H6zm4-1V3H6v4h4zm1 1v4h3.5a.5.5 0 0 0 .5-.5V8h-4zm0-1h4V3.5a.5.5 0 0 0-.5-.5H11v4z"/>
                        </svg>
                        &nbsp; {{openedPlanConfig.selectedNutritionPlan.getName()}}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMealType">
                        <div *ngFor="let plan of openedPlanConfig.nutritionPlans">
                            <button class="dropdown-item" type="button" (click)="onChangeSelectedNutritionPlan(openedPlanConfig, plan)">{{plan.getName()}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">

            <div class="month-selection">
                
                <div class="date-selection">
                    <button class="button change-date" (click)="onShowPreviousMonth()">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                        </svg>
                    </button>
                    
                    <input [matDatepicker]="diaryDatePicker" class="visibility-hidden width-0 padding-0" (dateInput)="showMonthForDate($event.value?.toDate())">
                    <mat-datepicker-toggle matSuffix [for]="diaryDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #diaryDatePicker></mat-datepicker>
                    <div class="date-display">{{'KW ' + selectedDate.getWeekOfYearNumber() + ' / ' + selectedDate.getFullYear()}}</div>
                    <button class="button change-date" (click)="onShowNextMonth()">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </button>

                    <div class="plan-toolbar show marginleft-25">
                        <div class="icon-button" (click)="onClearWeek()" tooltip="Woche leeren">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>
                        </div>
                        <div class="icon-button marginleft-10" (click)="onCopyWeek()" tooltip="Woche kopieren">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-files" viewBox="0 0 16 16">
                                <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"/>
                            </svg>
                        </div>
                        <div class="icon-button marginleft-10" (click)="onMoveWeek()" tooltip="Woche verschieben">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-move" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10zM.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2zM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8z"/>
                            </svg>
                        </div>
                    </div>

                    <button class="button-filled white small marginleft-50" (click)="onShowToday()">Heute</button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="weeks?.length > 0" class="row">
        <ng-container *ngFor="let day of weeks[0].days; let dayIndex = index">
            <div class="col day-wrapper  {{selectedDays?.includes(day) ? ' selected' : ''}} {{selectedDays?.length == 1 && selectedDays?.includes(day) && !isMovingDays && !isCopyingDays ? ' show-dayinfo' : ''}} {{day == focusedDay && !draggingTemplate && !day.isRepetition ? ' hovered' : ''}} {{day == focusedDay && draggingTemplate && !day.planConfig ? ' template-dropzone' : ''}} {{day.isToday ? ' today' : ''}}" (mouseenter)="onFocusDay(day, true)" (mouseleave)="onFocusDay(day, false)" (dragover)="onDragOverDay($event, day)" (drop)="onDropOnDay($event, day)">
                
                <div class="day-toolbar {{ selectedDays?.indexOf(day) == 0 || day == focusedDay && !draggingTemplate && !day.isRepetition ? ' show' : ''}}">
                    <div *ngIf="!day.isRepetition && !day.isInPast()" class="icon-button" (click)="onClearDays(day)" tooltip="{{areMultipleDaysSelected() && selectedDays?.indexOf(day) > -1 ? 'Markierte Tage leeren' : 'Tag leeren'}}">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                        </svg>
                    </div>
                    <div *ngIf="!day.isRepetition" class="icon-button marginleft-10" (click)="onCopyDays(day)" tooltip="{{areMultipleDaysSelected() && selectedDays?.indexOf(day) > -1 ? 'Markierte Tage kopieren' : 'Tag kopieren'}}">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-files" viewBox="0 0 16 16">
                            <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"/>
                        </svg>
                    </div>
                    <div *ngIf="!day.isRepetition && !day.isInPast()" class="icon-button marginleft-10" (click)="onMoveDays(day)" tooltip="{{areMultipleDaysSelected() && selectedDays?.indexOf(day) > -1 ? 'Markierte Tage verschieben' : 'Tag verschieben'}}">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-move" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10zM.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2zM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8z"/>
                        </svg>
                    </div>
                </div>
                <div class="date-information" (mousedown)="onMouseDownDay($event, day)" (mouseover)="onMouseOverDay($event, day)" (mouseup)="onMouseUpDay($event, day)">
                    <div class="weekday">{{weekDays[day.date.getDayNumber()].substr(0,2)}}</div>
                    <div class="date">{{day.date.getDate() + '.' + (day.date.getMonth() + 1) + '.'}}</div>
                </div>
                
                <div class="day-header" *ngIf="day.config" (mousedown)="onMouseDownDay($event, day)" (mouseover)="onMouseOverDay($event, day)" (mouseup)="onMouseUpDay($event, day)">
                    <div class="config-indicator has-config color-black {{'colorscheme-' + day.config.colorScheme}} {{day.config != null && day.config == focusedConfig ? 'hovered-config' : ''}}" (mouseenter)="onHoverConfig(day.config, true)" (mouseleave)="onHoverConfig(day.config, false)">
                        <div *ngIf="day.isFirstVisibleDayOfConfig" class="config-name">{{day.config.name}}</div>
                        <!--div *ngIf="day.config" class="config-action-area" (click)="onEndNutritionConfig(day);$event.stopPropagation()" tooltip="Ziele hier beenden">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grip-vertical" viewBox="0 0 16 16">
                                <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                            </svg>
                        </div-->
                    </div>

                    <div class="header-extension">
                        <div *ngIf="day.isFirstVisibleDayOfConfig && day.config?.situations?.length > 1" class="button-wrapper display-inlineblock">
                            <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownMealType" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bullseye" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="M8 13A5 5 0 1 1 8 3a5 5 0 0 1 0 10zm0 1A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"/>
                                    <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"/>
                                    <path d="M9.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                </svg>
                                {{day.config.selectedSituation.getName()}}
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMealType">
                                <div *ngFor="let situation of day.config.situations">
                                    <button class="dropdown-item" type="button" (click)="onSelectSituation(day.config, situation)">{{situation.getName()}}</button>
                                </div>
                            </div>
                        </div>
                        <div class="nutritionalvalues-button display-inlineblock link float-right" [popover]="nutritionalValuePopover" (click)="onNutritionalValuesFocused(day)" placement="top" [outsideClick]="true" container="body">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                            </svg>
                        </div>
                    </div>
                    <div class="nutritional-value-graphs" *ngIf="day.config.getNutritionalGoalForDate(day.date)">
                        <div class="nutritional-value-graph carbohydrates">
                            <div class="background {{day.hasOverfilledGraph('carbohydrates') ? 'colored' : ''}}"></div>
                            <div class="foreground" [ngStyle]="{width: day.getGraphForegroundWidth('carbohydrates').toFixed(2) + '%'}"></div>
                            <div class="text">{{day.getNutritionalValue('carbohydrates')?.toFixed(0)}} / {{day.config.getNutritionalGoalForDate(day.date).getCarbohydrates()}} K</div>
                        </div>
                        <div class="nutritional-value-graph protein">
                            <div class="background {{day.hasOverfilledGraph('protein') ? 'colored' : ''}}"></div>
                            <div class="foreground" [ngStyle]="{width: day.getGraphForegroundWidth('protein').toFixed(2) + '%'}"></div>
                            <div class="text">{{day.getNutritionalValue('protein')?.toFixed(0)}} / {{day.config.getNutritionalGoalForDate(day.date).getProtein()}} E</div>
                        </div>
                        <div class="nutritional-value-graph fat">
                            <div class="background {{day.hasOverfilledGraph('fat') ? 'colored' : ''}}"></div>
                            <div class="foreground" [ngStyle]="{width: day.getGraphForegroundWidth('fat').toFixed(2) + '%'}"></div>
                            <div class="text">{{day.getNutritionalValue('fat')?.toFixed(0)}} / {{day.config.getNutritionalGoalForDate(day.date).getFat()}} F</div>
                        </div>
                        <div class="nutritional-value-graph calories">
                            <div class="background {{day.hasOverfilledGraph('calories') ? 'colored' : ''}}"></div>
                            <div class="foreground" [ngStyle]="{width: day.getGraphForegroundWidth('calories').toFixed(2) + '%'}"></div>
                            <div class="text">{{day.getNutritionalValue('calories')?.toFixed(0)}} / {{day.config.getNutritionalGoalForDate(day.date).getCalories()}} kcal</div>
                        </div>
                    </div>
                </div>
                <div class="day-header" *ngIf="!day.config"></div>
                <div class="day" (mousedown)="onMouseDownDay($event, day)" (mouseover)="onMouseOverDay($event, day)" (mouseup)="onMouseUpDay($event, day)">
                    <div class="row">
                        <ng-container *ngFor="let item of day.items">
                            <div class="col-12 dayitem-wrapper">

                                <div *ngIf="draggingMeal" class="dayitem-dropzone {{dragOverItem == item && !dragOverMeal && dragOutsideItem ? ' can-drop' : ''}} item-{{dragOverItem}} meal-{{dragOverMeal}} {{dragOutsideItem}}" (dragover)="onDragOverMeal($event, day, item, null, true);$event.stopPropagation()" (drop)="onDropBeforeDayItem($event, day, item);$event.stopPropagation()"><div class="active"></div></div>
                                
                                <div *ngIf="item.meal && item.mealConfig" class="dayitem "> <!--  {{draggingMeal ? ' drag-destination' : ''}} {{dragOverItem == item.mealConfig ? ' can-drop' : ''}}  -->
                                    <div class="meal-heading" tooltip="Ziel: {{getMealConfigTargetMacros(day, item)}}">{{item.mealConfig.getType()}}</div>
                                    <div class="editing-topbar">
                                        <div class="icon-button" (click)="onCopyDayItem(day, item);$event.stopPropagation()" tooltip="Mahlzeiten kopieren">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-files" viewBox="0 0 16 16">
                                                <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"/>
                                            </svg>
                                        </div>
                                        <div *ngIf="!day.isInPast() || day.isFirstDayOfPlanConfig()" class="icon-button" (click)="onDeleteDayItem(day, item);$event.stopPropagation()" tooltip="Mahlzeiten löschen">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div *ngIf="item.meal && !(item.alternativeMeals?.length > 0)">
                                        <div *ngIf="draggingMeal" class="dayitem-dropzone {{dragOverItem == item && !dragOverMeal && !dragOutsideItem ? ' can-drop' : ''}}" (dragover)="onDragOverMeal($event, day, item, null);$event.stopPropagation()" (drop)="onDropAsMainMeal($event, day, item, item.meal);$event.stopPropagation()"><div class="active"></div></div>

                                        <div class="meal single-meal" (click)="onEditMeal(day, item, item.meal)" draggable="true" (dragstart)="onDragStartMeal($event, day, item, item.meal)" (dragover)="onDragOverMeal($event, day, item, item.meal);$event.stopPropagation()" (dragend)="onDragEnd($event, day, item)" (drop)="onDropAfterMeal($event, day, item, item.meal);$event.stopPropagation()">
                                            <div class="editing-sidebar">
                                                <div class="icon-button" (click)="onCopyMeal(day, item, item.meal);$event.stopPropagation()">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-files" viewBox="0 0 16 16" tooltip="Mahlzeit kopieren">
                                                        <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"/>
                                                    </svg>
                                                </div>
                                                <div *ngIf="!day.isInPast() || day.isFirstDayOfPlanConfig()" class="icon-button" (click)="onDeleteMeal(day, item, item.meal);$event.stopPropagation()" tooltip="Mahlzeit löschen">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div class="thumbnail-image">
                                                <img draggble="false" *ngIf="item.meal.imageURL" loading="lazy" (error)="utilityService.onImageErrorHide($event)" src="{{item.meal.imageURL}}">
                                                <div *ngIf="!item.meal.imageURL">
                                                    <img draggble="false" loading="lazy" (error)="utilityService.onImageErrorHide($event)" src="{{ (nutritionService.getPlannedMealThumbnailUrl(item.meal) | async) }}">
                                                </div>
                                            </div>
                                            <div class="display-block">
                                                <div>{{item.meal.getName(languageService?.selectedLanguageCode)}}</div>
                                            </div>
                                            <div class="nutritional-values-small">
                                                {{item.meal.getPrintableNutritionalValues()}}
                                            </div>
                                        </div>
                                        <div *ngIf="draggingMeal" class="dayitem-dropzone {{dragOverItem == item && dragOverMeal && !dragOutsideItem ? ' can-drop' : ''}}" (dragover)="onDragOverMealItemSpace($event, day, item);$event.stopPropagation()" (drop)="onDropAfterMeal($event, day, item, item.meal);$event.stopPropagation()"><div class="active"></div></div>
                                    </div>
                                    <div *ngIf="item.alternativeMeals?.length > 0" class="mealalternatives-list">
                                        <div *ngIf="draggingMeal" class="dayitem-dropzone {{dragOverItem == item && !dragOverMeal && !dragOutsideItem ? ' can-drop' : ''}}" (dragover)="onDragOverMeal($event, day, item, null);$event.stopPropagation()" (drop)="onDropAsMainMeal($event, day, item, item.meal);$event.stopPropagation()"><div class="active"></div></div>
                                        
                                        <div *ngIf="item.meal" class="meal single-meal" (click)="onEditMeal(day, item, item.meal)" draggable="true" (dragstart)="onDragStartMeal($event, day, item, item.meal)" (dragover)="onDragOverMeal($event, day, item, item.meal);$event.stopPropagation()" (dragend)="onDragEnd($event, day, item)" (drop)="onDropAfterMeal($event, day, item, item.meal);$event.stopPropagation()">
                                            <div class="editing-sidebar">
                                                <div class="icon-button" (click)="onCopyMeal(day, item, item.meal);$event.stopPropagation()" tooltip="Mahlzeit kopieren">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-files" viewBox="0 0 16 16">
                                                        <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"/>
                                                    </svg>
                                                </div>
                                                <div *ngIf="!day.isInPast() || day.isFirstDayOfPlanConfig()" class="icon-button" (click)="onDeleteMeal(day, item, item.meal);$event.stopPropagation()" tooltip="Mahlzeit löschen">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div class="thumbnail-image">
                                                <img draggble="false" *ngIf="item.meal.imageURL" loading="lazy" (error)="utilityService.onImageErrorHide($event)" src="{{item.meal.imageURL}}">
                                                <div *ngIf="!item.meal.imageURL">
                                                    <img draggble="false" loading="lazy" (error)="utilityService.onImageErrorHide($event)" src="{{ (nutritionService.getPlannedMealThumbnailUrl(item.meal) | async) }}">
                                                </div>
                                            </div>
                                            <div class="display-block">
                                                <div>{{item.meal.getName(languageService?.selectedLanguageCode)}}</div>
                                            </div>
                                            <div class="nutritional-values-small">
                                                {{item.meal.getNutritionalValue('carbohydrates')?.toFixed(0)}}g K / {{item.meal.getNutritionalValue('protein')?.toFixed(0)}}g E / {{item.meal.getNutritionalValue('fat')?.toFixed(0)}}g F / {{item.meal.getNutritionalValue('calories')?.toFixed(0)}} kcal
                                            </div>
                                        </div>
                                        <div *ngIf="draggingMeal" class="dayitem-dropzone {{dragOverMeal == item.meal && !dragOutsideItem ? ' can-drop' : ''}}" (dragover)="onDragOverMealItemSpace($event, day, item);$event.stopPropagation()" (drop)="onDropAfterMeal($event, day, item, item.meal);$event.stopPropagation()"><div class="active"></div></div>
                                        <ng-container *ngFor="let alternativeMeal of item.alternativeMeals">
                                            <div class="label textalign-center">oder</div>
                                            <div class="meal" (click)="onEditMeal(day, item, alternativeMeal)" draggable="true" (dragstart)="onDragStartMeal($event, day, item, alternativeMeal)" (dragover)="onDragOverMeal($event, day, item, alternativeMeal);$event.stopPropagation()" (dragend)="onDragEnd($event, day, item)"  (drop)="onDropAfterMeal($event, day, item, alternativeMeal);$event.stopPropagation()">
                                                <div class="editing-sidebar">
                                                    <div class="icon-button" (click)="onCopyMeal(day, item, alternativeMeal);$event.stopPropagation()" tooltip="Mahlzeit kopieren">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-files" viewBox="0 0 16 16">
                                                            <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"/>
                                                        </svg>
                                                    </div>
                                                    <div *ngIf="!day.isInPast() || day.isFirstDayOfPlanConfig()" class="icon-button" (click)="onDeleteMeal(day, item, alternativeMeal);$event.stopPropagation()" tooltip="Mahlzeit löschen">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div class="thumbnail-image">
                                                    <img draggble="false" *ngIf="alternativeMeal.imageURL" loading="lazy" (error)="utilityService.onImageErrorHide($event)" src="{{alternativeMeal.imageURL}}">
                                                    <div *ngIf="!alternativeMeal.imageURL">
                                                        <img draggble="false" loading="lazy" (error)="utilityService.onImageErrorHide($event)" src="{{ (nutritionService.getPlannedMealThumbnailUrl(alternativeMeal) | async) }}">
                                                    </div>
                                                </div>
                                                <div class="display-block">
                                                    <div>{{alternativeMeal.getName(languageService?.selectedLanguageCode)}}</div>
                                                </div>
                                                <div class="nutritional-values-small">
                                                    {{alternativeMeal.getNutritionalValue('carbohydrates')?.toFixed(0)}}g K / {{alternativeMeal.getNutritionalValue('protein')?.toFixed(0)}}g E / {{alternativeMeal.getNutritionalValue('fat')?.toFixed(0)}}g F / {{alternativeMeal.getNutritionalValue('calories')?.toFixed(0)}} kcal
                                                </div>
                                            </div>
                                            <div *ngIf="draggingMeal" class="dayitem-dropzone {{dragOverMeal == alternativeMeal ? ' can-drop' : ''}}" (dragover)="onDragOverMealItemSpace($event, day, item);$event.stopPropagation()" (drop)="onDropAfterMeal($event, day, item, alternativeMeal);$event.stopPropagation()"><div class="active"></div></div>
                                        </ng-container>
                                    </div>

                                    <div *ngIf="!day.isInPast() || day.isFirstDayOfPlanConfig()" class="textalign-center">
                                        <div class="add-alternative" tooltip="Mahlzeit hinzufügen">
                                            <div class="icon-button" (click)="onAddAlternativeMealAtItem(day, item);$event.stopPropagation()">
                                                <svg width="22" height="22" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div class="suggest-alternative">
                                            <div class="icon-button marginleft-10" (click)="onSuggestMealAlternatives(day, item);$event.stopPropagation()" tooltip="Mahlzeit vorschlagen">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-magic" viewBox="0 0 16 16">
                                                    <path d="M9.5 2.672a.5.5 0 1 0 1 0V.843a.5.5 0 0 0-1 0v1.829Zm4.5.035A.5.5 0 0 0 13.293 2L12 3.293a.5.5 0 1 0 .707.707L14 2.707ZM7.293 4A.5.5 0 1 0 8 3.293L6.707 2A.5.5 0 0 0 6 2.707L7.293 4Zm-.621 2.5a.5.5 0 1 0 0-1H4.843a.5.5 0 1 0 0 1h1.829Zm8.485 0a.5.5 0 1 0 0-1h-1.829a.5.5 0 0 0 0 1h1.829ZM13.293 10A.5.5 0 1 0 14 9.293L12.707 8a.5.5 0 1 0-.707.707L13.293 10ZM9.5 11.157a.5.5 0 0 0 1 0V9.328a.5.5 0 0 0-1 0v1.829Zm1.854-5.097a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L8.646 5.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0l1.293-1.293Zm-3 3a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L.646 13.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0L8.354 9.06Z"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="isCopyingMeal" class="paste-meal">
                                        <div *ngIf="!day.isRepetition && !day.isInPast() || day.isFirstDayOfPlanConfig()" class="icon-button centered" (click)="onPasteOnItem(day, item);$event.stopPropagation()">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                            </svg>
                                            Einfügen
                                        </div>
                                        <div class="icon-button centered cancel-action" (click)="onCancelAction();$event.stopPropagation()">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                            </svg>
                                            Abbrechen
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="!item.meal && item.mealConfig" class="dayitem {{draggingMeal ? ' drag-destination' : ''}}" (click)="onAddNewMealAtItem(day, item)">
                                    <div class="meal-heading" tooltip="Ziel: {{getMealConfigTargetMacros(day, item)}}">{{item.mealConfig.getType()}}</div>

                                    <div *ngIf="draggingMeal" class="dayitem-dropzone {{dragOverItem == item.mealConfig ? ' can-drop' : ''}}" (dragover)="onDragOverMealItemConfig($event, day, item);$event.stopPropagation()" (drop)="onDropOnMealItemConfig($event, day, item)"><div class="active"></div></div>
                                    
                                    <div *ngIf="!day.isInPast() || day.isFirstDayOfPlanConfig()" class="textalign-center">
                                        <div class="add-alternative" tooltip="Mahlzeit hinzufügen">
                                            <div class="icon-button" (click)="onAddAlternativeMealAtItem(day, item);$event.stopPropagation()">
                                                <svg width="22" height="22" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div class="suggest-alternative">
                                            <div class="icon-button marginleft-10" (click)="onSuggestMealAlternatives(day, item);$event.stopPropagation()" tooltip="Mahlzeit vorschlagen">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-magic" viewBox="0 0 16 16">
                                                    <path d="M9.5 2.672a.5.5 0 1 0 1 0V.843a.5.5 0 0 0-1 0v1.829Zm4.5.035A.5.5 0 0 0 13.293 2L12 3.293a.5.5 0 1 0 .707.707L14 2.707ZM7.293 4A.5.5 0 1 0 8 3.293L6.707 2A.5.5 0 0 0 6 2.707L7.293 4Zm-.621 2.5a.5.5 0 1 0 0-1H4.843a.5.5 0 1 0 0 1h1.829Zm8.485 0a.5.5 0 1 0 0-1h-1.829a.5.5 0 0 0 0 1h1.829ZM13.293 10A.5.5 0 1 0 14 9.293L12.707 8a.5.5 0 1 0-.707.707L13.293 10ZM9.5 11.157a.5.5 0 0 0 1 0V9.328a.5.5 0 0 0-1 0v1.829Zm1.854-5.097a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L8.646 5.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0l1.293-1.293Zm-3 3a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L.646 13.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0L8.354 9.06Z"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="isCopyingMeal" class="paste-meal">
                                        <div *ngIf="!day.isRepetition && !day.isInPast() || day.isFirstDayOfPlanConfig()" class="icon-button centered" (click)="onPasteOnItem(day, item);$event.stopPropagation()">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                            </svg>
                                            Einfügen
                                        </div>
                                        <div class="icon-button centered cancel-action" (click)="onCancelAction();$event.stopPropagation()">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                            </svg>
                                            Abbrechen
                                        </div>
                                    </div>
                                    <div *ngIf="isCopyingDayItem" class="paste-meal">
                                        <div *ngIf="!day.isRepetition && !day.isInPast() && day.planConfig || day.isFirstDayOfPlanConfig()" class="icon-button centered" (click)="onPasteOnItem(day, item);$event.stopPropagation()">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                            </svg>
                                            Einfügen
                                        </div>
                                        <div class="icon-button centered cancel-action" (click)="onCancelAction();$event.stopPropagation()">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                            </svg>
                                            Abbrechen
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="item.meal && !item.mealConfig" class="dayitem {{draggingMeal ? ' drag-destination' : ''}}">
                                    <div *ngIf="item.meal && !(item.alternativeMeals?.length > 0)">
                                        <div class="meal single-meal" (click)="onEditMeal(day, item, item.meal)" draggable="true" (dragstart)="onDragStartMeal($event, day, item, item.meal)" (dragover)="onDragOverMeal($event, day, item, item.meal);$event.stopPropagation()" (dragend)="onDragEnd($event, day, item)" (drop)="onDropAfterMeal($event, day, item, item.meal);$event.stopPropagation()">
                                            <div class="editing-sidebar">
                                                <div *ngIf="!day.isInPast() || day.isFirstDayOfPlanConfig()" class="icon-button" (click)="onCopyMeal(day, item, item.meal);$event.stopPropagation()" tooltip="Mahlzeit kopieren">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-files" viewBox="0 0 16 16">
                                                        <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"/>
                                                    </svg>
                                                </div>
                                                <div *ngIf="!day.isInPast() || day.isFirstDayOfPlanConfig()" class="icon-button" (click)="onDeleteMeal(day, item, item.meal);$event.stopPropagation()" tooltip="Mahlzeit löschen">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div class="thumbnail-image">
                                                <img draggble="false" *ngIf="item.meal.imageURL" loading="lazy" (error)="utilityService.onImageErrorHide($event)" src="{{item.meal.imageURL}}">
                                                <div *ngIf="!item.meal.imageURL">
                                                    <img draggble="false" loading="lazy" (error)="utilityService.onImageErrorHide($event)" src="{{ (nutritionService.getPlannedMealThumbnailUrl(item.meal) | async) }}">
                                                </div>
                                            </div>
                                            <div class="display-block">
                                                <div>{{item.meal.getName(languageService?.selectedLanguageCode)}}</div>
                                            </div>
                                            <div class="nutritional-values-small">
                                                {{item.meal.getNutritionalValue('carbohydrates')?.toFixed(0)}}g K / {{item.meal.getNutritionalValue('protein')?.toFixed(0)}}g E / {{item.meal.getNutritionalValue('fat')?.toFixed(0)}}g F / {{item.meal.getNutritionalValue('calories')?.toFixed(0)}} kcal
                                            </div>
                                        </div>
                                        <div *ngIf="draggingMeal" class="dayitem-dropzone {{dragOverItem == item && !dragOutsideItem ? ' can-drop' : ''}}" (dragover)="onDragOverMealItemSpace($event, day, item);$event.stopPropagation()" (drop)="onDropAfterMeal($event, day, item, item.meal);$event.stopPropagation()"><div class="active"></div></div>
                                        
                                        <div *ngIf="!day.isInPast() || day.isFirstDayOfPlanConfig()" class="textalign-center">
                                            <div class="add-alternative" tooltip="Mahlzeit hinzufügen">
                                                <div class="icon-button" (click)="onAddAlternativeMealAtItem(day, item);$event.stopPropagation()">
                                                    <svg width="22" height="22" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div class="suggest-alternative">
                                                <div class="icon-button marginleft-10" (click)="onSuggestMealAlternatives(day, item);$event.stopPropagation()" tooltip="Mahlzeit vorschlagen">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-magic" viewBox="0 0 16 16">
                                                        <path d="M9.5 2.672a.5.5 0 1 0 1 0V.843a.5.5 0 0 0-1 0v1.829Zm4.5.035A.5.5 0 0 0 13.293 2L12 3.293a.5.5 0 1 0 .707.707L14 2.707ZM7.293 4A.5.5 0 1 0 8 3.293L6.707 2A.5.5 0 0 0 6 2.707L7.293 4Zm-.621 2.5a.5.5 0 1 0 0-1H4.843a.5.5 0 1 0 0 1h1.829Zm8.485 0a.5.5 0 1 0 0-1h-1.829a.5.5 0 0 0 0 1h1.829ZM13.293 10A.5.5 0 1 0 14 9.293L12.707 8a.5.5 0 1 0-.707.707L13.293 10ZM9.5 11.157a.5.5 0 0 0 1 0V9.328a.5.5 0 0 0-1 0v1.829Zm1.854-5.097a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L8.646 5.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0l1.293-1.293Zm-3 3a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L.646 13.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0L8.354 9.06Z"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="item.alternativeMeals?.length > 0" class="mealalternatives-list">
                                        <div *ngIf="draggingMeal" class="dayitem-dropzone {{dragOverItem == item && !dragOutsideItem ? ' can-drop' : ''}}" (dragover)="onDragOverMeal($event, day, item, null);$event.stopPropagation()" (drop)="onDropAsMainMeal($event, day, item, item.meal);$event.stopPropagation()"><div class="active"></div></div>
                                        <div *ngIf="item.meal" class="meal single-meal" (click)="onEditMeal(day, item, item.meal)" draggable="true" (dragstart)="onDragStartMeal($event, day, item, item.meal)" (dragover)="onDragOverMeal($event, day, item, item.meal);$event.stopPropagation()" (dragend)="onDragEnd($event, day, item)" (drop)="onDropAfterMeal($event, day, item, item.meal);$event.stopPropagation()">
                                            <div class="editing-sidebar">
                                                <div *ngIf="!day.isInPast() || day.isFirstDayOfPlanConfig()" class="icon-button" (click)="onCopyMeal(day, item, item.meal);$event.stopPropagation()" tooltip="Mahlzeit kopieren">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-files" viewBox="0 0 16 16">
                                                        <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"/>
                                                    </svg>
                                                </div>
                                                <div *ngIf="!day.isInPast() || day.isFirstDayOfPlanConfig()" class="icon-button" (click)="onDeleteMeal(day, item, item.meal);$event.stopPropagation()" tooltip="Mahlzeit löschen">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div class="thumbnail-image">
                                                <img draggble="false" *ngIf="item.meal.imageURL" loading="lazy" (error)="utilityService.onImageErrorHide($event)" src="{{item.meal.imageURL}}">
                                                <div *ngIf="!item.meal.imageURL">
                                                    <img draggble="false" loading="lazy" (error)="utilityService.onImageErrorHide($event)" src="{{ (nutritionService.getPlannedMealThumbnailUrl(item.meal) | async) }}">
                                                </div>
                                            </div>
                                            <div class="display-block">
                                                <div>{{item.meal.getName(languageService?.selectedLanguageCode)}}</div>
                                            </div>
                                            <div class="nutritional-values-small">
                                                {{item.meal.getNutritionalValue('carbohydrates')?.toFixed(0)}}g K / {{item.meal.getNutritionalValue('protein')?.toFixed(0)}}g E / {{item.meal.getNutritionalValue('fat')?.toFixed(0)}}g F / {{item.meal.getNutritionalValue('calories')?.toFixed(0)}} kcal
                                            </div>
                                        </div>
                                        <div *ngIf="draggingMeal" class="dayitem-dropzone {{dragOverMeal == item.meal && !dragOutsideItem ? ' can-drop' : ''}}" (dragover)="onDragOverMealItemSpace($event, day, item);$event.stopPropagation()" (drop)="onDropAfterMeal($event, day, item, item.meal);$event.stopPropagation()"><div class="active"></div></div>
                                        <ng-container *ngFor="let alternativeMeal of item.alternativeMeals">
                                            <div class="label textalign-center">oder</div>
                                            <div class="meal" (click)="onEditMeal(day, item, alternativeMeal)" draggable="true" (dragstart)="onDragStartMeal($event, day, item, alternativeMeal)" (dragover)="onDragOverMeal($event, day, item, alternativeMeal);$event.stopPropagation()" (dragend)="onDragEnd($event, day, item)"  (drop)="onDropAfterMeal($event, day, item, alternativeMeal);$event.stopPropagation()">
                                                <div class="editing-sidebar">
                                                    <div class="icon-button" (click)="onCopyMeal(day, item, alternativeMeal);$event.stopPropagation()" tooltip="Mahlzeit kopieren">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-files" viewBox="0 0 16 16">
                                                            <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"/>
                                                        </svg>
                                                    </div>
                                                    <div class="icon-button" (click)="onDeleteMeal(day, item, alternativeMeal);$event.stopPropagation()" tooltip="Mahlzeit löschen">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div class="thumbnail-image">
                                                    <img draggble="false" *ngIf="alternativeMeal.imageURL" loading="lazy" (error)="utilityService.onImageErrorHide($event)" src="{{alternativeMeal.imageURL}}">
                                                    <div *ngIf="!alternativeMeal.imageURL">
                                                        <img draggble="false" loading="lazy" (error)="utilityService.onImageErrorHide($event)" src="{{ (nutritionService.getPlannedMealThumbnailUrl(alternativeMeal) | async) }}">
                                                    </div>
                                                </div>
                                                <div class="display-block">
                                                    <div>{{alternativeMeal.getName(languageService?.selectedLanguageCode)}}</div>
                                                </div>
                                                <div class="nutritional-values-small">
                                                    {{alternativeMeal.getNutritionalValue('carbohydrates')?.toFixed(0)}}g K / {{alternativeMeal.getNutritionalValue('protein')?.toFixed(0)}}g E / {{alternativeMeal.getNutritionalValue('fat')?.toFixed(0)}}g F / {{alternativeMeal.getNutritionalValue('calories')?.toFixed(0)}} kcal
                                                </div>
                                            </div>
                                            <div *ngIf="draggingMeal" class="dayitem-dropzone {{dragOverMeal == alternativeMeal && !dragOutsideItem ? ' can-drop' : ''}}" (dragover)="onDragOverMealItemSpace($event, day, item);$event.stopPropagation()" (drop)="onDropAfterMeal($event, day, item, alternativeMeal);$event.stopPropagation()"><div class="active"></div></div>
                                        </ng-container>

                                        <div *ngIf="!day.isInPast() || day.isFirstDayOfPlanConfig()" class="textalign-center">
                                            <div class="add-alternative" tooltip="Mahlzeit hinzufügen">
                                                <div class="icon-button" (click)="onAddAlternativeMealAtItem(day, item);$event.stopPropagation()">
                                                    <svg width="22" height="22" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div class="suggest-alternative">
                                                <div class="icon-button marginleft-10" (click)="onSuggestMealAlternatives(day, item);$event.stopPropagation()" tooltip="Mahlzeit vorschlagen">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-magic" viewBox="0 0 16 16">
                                                        <path d="M9.5 2.672a.5.5 0 1 0 1 0V.843a.5.5 0 0 0-1 0v1.829Zm4.5.035A.5.5 0 0 0 13.293 2L12 3.293a.5.5 0 1 0 .707.707L14 2.707ZM7.293 4A.5.5 0 1 0 8 3.293L6.707 2A.5.5 0 0 0 6 2.707L7.293 4Zm-.621 2.5a.5.5 0 1 0 0-1H4.843a.5.5 0 1 0 0 1h1.829Zm8.485 0a.5.5 0 1 0 0-1h-1.829a.5.5 0 0 0 0 1h1.829ZM13.293 10A.5.5 0 1 0 14 9.293L12.707 8a.5.5 0 1 0-.707.707L13.293 10ZM9.5 11.157a.5.5 0 0 0 1 0V9.328a.5.5 0 0 0-1 0v1.829Zm1.854-5.097a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L8.646 5.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0l1.293-1.293Zm-3 3a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L.646 13.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0L8.354 9.06Z"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="isCopyingMeal" class="paste-meal">
                                        <div *ngIf="!day.isRepetition && !day.isInPast() || day.isFirstDayOfPlanConfig()" class="icon-button centered" (click)="onPasteOnItem(day, item);$event.stopPropagation()">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                            </svg>
                                            Einfügen
                                        </div>
                                        <div class="icon-button centered cancel-action" (click)="onCancelAction();$event.stopPropagation()">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                            </svg>
                                            Abbrechen
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="alternativeMealSuggestions && selectedDayItem == item" class="mealsuggestions-wrapper {{dayIndex == 5 || dayIndex == 6 ? ' left' : ''}}">
                                    <div class="dayinfo layout-box position-relative">
                                        <div class="icon-button float-right marginright-25" (click)="onHideMealAlternatives();$event.stopPropagation()">
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                            </svg>
                                        </div>
                                        <div class="icon-button float-right marginright-10" (click)="toggleMealSuggestionSettings();$event.stopPropagation()">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-sliders padding-6" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z"/>
                                            </svg>
                                        </div>
                                        <div class="date">Passende Vorschläge ({{alternativeMealSuggestions?.length}}):</div>
                                        <div class="row meal-list">
                                            <ng-container *ngFor="let meal of alternativeMealSuggestions">
                                                <div class="col-12 dayitem-wrapper">
                                                    <div class="">
                                                        <div class="meal single-meal">
                                                            <div class="thumbnail-image">
                                                                <img draggble="false" *ngIf="meal.imageURL" loading="lazy" (error)="utilityService.onImageErrorHide($event)" src="{{meal.imageURL}}">
                                                                <div *ngIf="!meal.imageURL">
                                                                    <img draggble="false" loading="lazy" (error)="utilityService.onImageErrorHide($event)" src="{{ (nutritionService.getPlannedMealThumbnailUrl(meal) | async) }}">
                                                                </div>
                                                            </div>
                                                            <div class="display-block">
                                                                <div>{{meal.getName(languageService?.selectedLanguageCode)}}</div>
                                                                <div class="nutritional-values-small">
                                                                    {{meal.getNutritionalValue('carbohydrates')?.toFixed(0)}}g K / {{meal.getNutritionalValue('protein')?.toFixed(0)}}g E / {{meal.getNutritionalValue('fat')?.toFixed(0)}}g F / {{meal.getNutritionalValue('calories')?.toFixed(0)}} kcal
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="select-alternative" (click)="onAddSuggestionToAlternatives(day, item, meal);$event.stopPropagation()" tooltip="Übernehmen">
                                                            <div class="icon-button">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div *ngIf="loadingMealSuggestions">
                                            <div class="loading-animation">
                                                <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>


                    <div class="day-footer textalign-center">
                        <div *ngIf="draggingMeal" class="dayend-dropzone {{dragOverItem == dayEndItem && !dragOverMeal && dragOutsideItem ? ' can-drop' : ''}}" (dragover)="onDragOverMeal($event, day, dayEndItem, null, true);$event.stopPropagation()" (drop)="onDropOnDayEndSpace($event, day, dayEndItem);$event.stopPropagation()"><div class="active"></div></div>
                        
                        <div *ngIf="isCopyingMeal" class="paste-meal">
                            <div *ngIf="!day.isRepetition && !day.isInPast() || day.isFirstDayOfPlanConfig()" class="icon-button centered" (click)="onPasteAtEnd(day);$event.stopPropagation()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                </svg>
                                Einfügen
                            </div>
                            <div class="icon-button centered cancel-action" (click)="onCancelAction();$event.stopPropagation()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                                Abbrechen
                            </div>
                        </div>
                        
                        <div *ngIf="day.planConfig && !day.isInPast()" class="icon-button" (click)="onAddNewMeal(day)" tooltip="Mahlzeit hinzufügen">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                            </svg>
                        </div>
                        <div *ngIf="day.planConfig && day.isInPast() && !day.isFirstDayOfPlanConfig()" class="label margintop-10">
                            Vergangene Tage können nicht bearbeitet werden.
                        </div>
                        
                        <div *ngIf="!day.planConfig" class="icon-button" (click)="onEditNutritionPlanConfig(day.planConfig)" tooltip="Ernährungsplan erstellen">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                            </svg>
                        </div>
                    </div>

                    <div *ngIf="day.isRepetition" class="day-overlay">
                        <div class="icon" tooltip="Wiederholung">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-arrow-repeat" viewBox="0 0 16 16">
                                <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                                <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
                            </svg>
                        </div>
                        <div *ngIf="day.planConfig && !day.isInPast()" class="icon-button" (click)="onCopyAndEditNutritionPlanConfig(day.planConfig, day)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                            </svg>
                            Kopie erstellen und bearbeiten
                        </div>
                    </div>
                    <div *ngIf="openedPlanConfig && day.planConfig && openedPlanConfig.id != day.planConfig.id" class="day-overlay opaque">
                        <div class="small centered padding-10">
                            <div class="fontsize-small">Plan:</div>
                            {{day.planConfig.getName()}}<br>
                            <div class="spacer-10"></div>
                            <div class="icon-button" (click)="onOpenNutritionPlanConfig(day.planConfig)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                </svg>
                                Plan öffnen
                            </div>
                        </div>
                    </div>

                    <div *ngIf="isCopyingDays && !selectedDays?.includes(day) && !day.isRepetition && (!day.isInPast() || day.isFirstDayOfPlanConfig()) && day.planConfig" class="day-overlay" (click)="onPasteOnDay(day);$event.stopPropagation()">
                        <div class="icon-button centered" tooltip="Einfügen">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                            </svg>
                            Einfügen
                        </div>
                        <div class="icon-button centered cancel-action" (click)="onCancelAction();$event.stopPropagation()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                            Abbrechen
                        </div>
                    </div>
                    <div *ngIf="isMovingDays && !day.isRepetition &&  (!day.isInPast() || day.isFirstDayOfPlanConfig()) && day.planConfig" class="day-overlay" (click)="onMoveToDay(day);$event.stopPropagation()">
                        <div class="icon-button" tooltip="Einfügen">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                            </svg>
                            Einfügen
                        </div>
                        <div class="icon-button centered cancel-action" (click)="onCancelAction();$event.stopPropagation()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                            Abbrechen
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<div id="templates-view" class="layout-box {{(showNutritionPlanTemplates ? 'show' : '')}}">
    <div class="dialog-topbar">
        <div class="icon-button marginright-25" (click)="onHideNutritionPlanTemplates()">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
        </div>
    </div>
    <div class="dialog-content-wrapper">
        <div class="row">
            <div class="col-12">
                <div class="spacer-10"></div>
                <input id="templatesearch-input" class="input wide" (input)="onTemplateSearchInputChanged($event.target.value)" placeholder="Vorlagen durchsuchen" type="text">
                <div class="icon-button deletesearch-icon delete-recipesearch" (click)="onDeleteTemplateSearchInput()">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </div>
                <div class="spacer-25"></div>
            </div>
        </div>
        <div *ngIf="planningTemplates?.length > 0" class="row">
            <div *ngFor="let template of filteredPlanningTemplates" class="col-12">
                <div class="layout-box sub-box template-item" draggable="true" (dragstart)="onDragStartPlanningTemplate($event, template)" (dragend)="onDragEndTemplate($event, template)">
                    <div class="">{{template.name}}</div>
                    <div class="label">{{template.getDescription()}}</div>
                    <div class="icon-button float-right" (click)="onDeletePlanningTemplate(template);$event.stopPropagation()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="deprecatedTempates?.length > 0" class="row">
            <div class="spacer-25"></div>
            <div class="col-12">
                Alte Vorlagen:
            </div>
            <div class="spacer-10"></div>
            <div *ngFor="let template of filteredDeprecatedTempates" class="col-12">
                <div class="layout-box sub-box template-item" draggable="true" (dragstart)="onDragStartDeprecatedTemplate($event, template)" (dragend)="onDragEndTemplate($event, template)">
                    <div class="">{{template.name}}</div>
                    <div class="small-text">{{template.duration}} Tage, je {{template.mealsPerDay}} Mahlzeiten</div>
                    <div *ngIf="template.referenceNutritionalGoal" class="nutritionplan-nutritional-values small-text">
                        Richtwerte: {{template.getCarbohydrates()?.toFixed(0)}}g K / {{template.getProtein()?.toFixed(0)}}g E / {{template.getFat()?.toFixed(0)}}g F / {{template.getCalories()?.toFixed(0)}} kcal
                    </div>
                </div>
            </div>
        </div>
        <div class="spacer-50"></div>
    </div>
</div>

<ng-template #nutritionalValuePopover>
    <app-nutritional-value-popover [nutritionalValueHolder]="nutritionalValueHolder"></app-nutritional-value-popover>
</ng-template>
<app-notification-composer [user]="user"></app-notification-composer>

<ngx-spinner class="ngx-spinner-nutritionplan" bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true"><div *ngIf="spinnerText" class="spinner-overlaytext" [innerHTML]="spinnerText"></div></ngx-spinner>