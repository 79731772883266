<div class="container">
    <div class="spacer-10"></div>
    <div class="hint label">
        <i class="bi bi-info-circle"></i>
        <span>{{getCardioZonesHintText()}}</span>
    </div>
    <div class="spacer-10"></div>
    <h2>
        Zonen
    </h2>
    <div class="spacer-10"></div>
    <table *ngIf="zones?.length > 0" class="col-12 sets-table">
        <thead class="variable-header">
            <tr>
                <th>
                    Zone
                </th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let zone of zones; let i = index;">
                <tr>
                    <td>
                        <input id="input-variable-name" [pattern]="namePattern" placeholder="Zone 1" maxlength="15" required class="input small full-width {{zone.isZoneNameInvalid(zones) ? 'invalid' : ''}}" [value]="zone.name" (input)="setName($event.target.value, i)"  oninput="this.value = this.value.toUpperCase()" placeholder="Name" type="text">
                    </td>
                    <td>
                        <i *ngIf="!zone.isMaxCardioZone" class="bi bi-x-lg link" (click)="removeCardioZone(zone)"></i>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
    <div class="spacer-10"></div>
    <div class="icon-button" (click)="addCardioZone()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
        </svg>
        Hinzufügen
    </div>
</div>