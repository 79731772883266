import { UtilityService } from 'src/app/services/utility.service';
import { Metric } from './../model/metric.model';
import { MetadataUser } from './../model/metadata-user.model';
import { DropdownItem } from './../model/automatic-push-notification.model';
import { FirestoreService } from './../services/firestore.service';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { User } from '../model/user.model';
// import { Timestamp } from 'rxjs/internal/operators/timestamp';
// import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { Observable, Subscription } from 'rxjs';
import { EventLog, EventType, EventType2LabelMapping, EventTypeString2LabelMapping, SanitizedHtmlPipe } from '../model/event-log.model';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { EventLogService } from '../services/event-log.service';

@Component({
  selector: 'app-event-log',
  templateUrl: './event-log.component.html',
  styleUrls: ['./event-log.component.css']
})
export class EventLogComponent implements OnInit, OnDestroy {

  public observableEventLogs: Observable<EventLog[]>;
  public eventLogs:EventLog[] = [];
  public filteredEventLogs:EventLog[] = [];

  public selectableEventTypes: DropdownItem[] =[];
  public allEventTypesChecked: boolean = true;

  public eventTypes = Object.values(EventType).filter(value => typeof value === 'string');
  public eventTypeLabels = Object.values(EventType2LabelMapping).filter(value => typeof value === 'string');
  public eventType2LabelMapping = EventType2LabelMapping;
  public eventTypeString2LabelMapping = EventTypeString2LabelMapping;

  public maxEventsPerPage = 2;
  public selectedDate:Date = new Date()

  public nextPageButtonVisible: boolean = false;

  public selectedEventTypes: EventType[] = [];
  
  @Input() set eventUsers(value: User[]){
    this.eventLogService?.setEventUsers(value);
    // if(this.equals(this.users, value)) return;
    // this.users = value;
    // this.refreshSelectableClientsGroups()
    // this.filterEventLogs()
  }

  @Input() set allCoachUsers(value: boolean){
    this.eventLogService?.setAllCoachUsers(value);
    // if(this._allCoachUsers === value) return;
    // this._allCoachUsers = value;
    // this.refreshSelectableClientsGroups()
    // this.filterEventLogs()
  }

  constructor(public userService: FirestoreService, private router: Router, public eventLogService: EventLogService, public utilityService: UtilityService) 
  {
    // console.log("constr event-log")
    // this.refreshObservableEventLogs();
    // this.observableClientsSubscription = this.userService.observableClients.subscribe(x => this.refreshSelectableClientsGroups())
    // if(this.selectableGroupNames?.length > 0) this.selectableGroupNames[0].checked = true;   
    // this.refreshSelectableClientsGroups();
    // eventLogService.spinner = spinner;
  }
  ngOnDestroy(): void {
  }
  ngOnInit(): void {
    this.eventLogService?.refreshSelectableClientsGroups()
  }

  isBusy() {
    return this.eventLogService?.isBusy
  }

  getEventOpenText(event: EventLog): string {
    if(event.type == 'progress' && event.eventItems['assignedQuestionaireId'] != null){
      return "Check-In aufrufen";
    }
    else if(event.type == 'training' && event.eventItems['trackedSessionId'] != null){
      return "Training aufrufen";
    }
    else {
      return "Kund:in aufrufen";
    }
  }
  
}