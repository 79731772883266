<div class="dialog-topbar">
    <h2 class="padding-0 margin-0">Athleten-Einstellungen</h2>
</div>
<div class="dialog-content-wrapper">
    <h3>Kundendaten</h3>
    <div class="row">
        <div class="col-8">Email:</div>
        <div class="col-4">{{userEmail}}</div>
        <div class="col-8">Aktuelles Gewicht:</div>
        <div class="col-4">{{user.latestBodyWeight?.toFixed(1) || user.bodyWeight?.toFixed(1)}} kg</div>
        <div class="col-8">Startgewicht:</div>
        <div class="col-4">{{user.firstBodyWeight?.toFixed(1) || user.bodyWeight?.toFixed(1)}} kg</div>
        <div class="col-8">Körpergröße:</div>
        <div class="col-4">{{user.bodyHeight.toFixed(0)}} cm</div>
        <ng-container *ngIf="user.birthDate">
            <div class="col-8">Geburtsdatum:</div>
            <div class="col-4">{{user.birthDate?.asFormatedString()}}</div>
        </ng-container>
        <div class="col-8">Alter:</div>
        <div class="col-4">{{user.age}} Jahre</div>
        <div class="col-8">Geschlecht:</div>
        <div class="col-4">{{user.getPrintableGender()}}</div>
        <div class="col-8">Aktivitätslevel (PAL-Wert):</div>
        <div class="col-4">{{user.getPrintablePhysicalActivityLevel()}}</div>
        <div class="col-4" *ngIf="user?.spikeProvider && user?.spikeUserId">Verbundenes Wearable: {{user?.spikeProvider}}</div>
    </div>

    <div class="spacer-20"></div>
    <div class="row">
        <div class="col-12">
            <h3>Check-Ins</h3>
        </div>
        <div class="col-5">
            Daily Check
        </div>
        <div class="col-7">
            <div class="d-inline-block wrapper-selection">
                <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getSelectedDailyQuestionaire()?.internalName ?? 'Auswählen'}}</button>
                <div class="dropdown-menu" aria-labelledby="dropdownBodyDataGraphTimeRange">
                    <div *ngFor="let questionaire of getDailyQuestionaires()">
                        <button class="dropdown-item" type="button" (click)="onSelectDailyCheck(questionaire)">{{questionaire.internalName}}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-5">
            Weekly Check
        </div>
        <div class="col-7">
            <div class="d-inline-block wrapper-selection">
                <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getSelectedWeeklyQuestionaire()?.internalName ?? 'Auswählen'}}</button>
                <div class="dropdown-menu" aria-labelledby="dropdownBodyDataGraphTimeRange">
                    <div *ngFor="let questionaire of getWeeklyQuestionaires()">
                        <button class="dropdown-item" type="button" (click)="onSelectWeeklyCheck(questionaire)">{{questionaire.internalName}}</button>
                    </div>
                </div>
            </div>
            <div class="d-inline-block wrapper-selection" *ngIf="getSelectedWeeklyQuestionaire()">
                <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getSelectedWeeklyCheckInDay()}}</button>
                <div class="dropdown-menu" aria-labelledby="dropdownWeeklyDate">
                    <button class="dropdown-item" type="button" (click)="onWeeklyCheckDayChanged('monday')">Montag</button>
                    <button class="dropdown-item" type="button" (click)="onWeeklyCheckDayChanged('tuesday')">Dienstag</button>
                    <button class="dropdown-item" type="button" (click)="onWeeklyCheckDayChanged('wednesday')">Mittwoch</button>
                    <button class="dropdown-item" type="button" (click)="onWeeklyCheckDayChanged('thursday')">Donnerstag</button>
                    <button class="dropdown-item" type="button" (click)="onWeeklyCheckDayChanged('friday')">Freitag</button>
                    <button class="dropdown-item" type="button" (click)="onWeeklyCheckDayChanged('saturday')">Samstag</button>
                    <button class="dropdown-item" type="button" (click)="onWeeklyCheckDayChanged('sunday')">Sonntag</button>
                </div>
            </div>
        </div>
        <div class="col-5">
            Trainingsfeedback
        </div>
        <div class="col-7">
            <div class="d-inline-block wrapper-selection">
                <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getSelectedTrainingFeedbackQuestionaire()?.internalName ?? 'Auswählen'}}</button>
                <div class="dropdown-menu" aria-labelledby="dropdownBodyDataGraphTimeRange">
                    <button class="dropdown-item" type="button" (click)="onSelectTrainingFeedbackQuestionaire(null)">-</button>
                    <div *ngFor="let questionaire of getTrainingFeedbackQuestionaires()">
                        <button class="dropdown-item" type="button" (click)="onSelectTrainingFeedbackQuestionaire(questionaire)">{{questionaire.internalName}}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="spacer-10"></div>
        <div class="col-12">
            <h3>Training</h3>
        </div>
        <div class="col-5">Nächster Wettkampf:</div>
        <div class="col-7">
            <div class="competitiondate-wrapper">
                <input [matDatepicker]="competitionDatePicker" class="visibility-hidden width-0 padding-0" (dateInput)="onCompetitionDateChanged($event.value?.toDate())">
                <mat-datepicker-toggle matSuffix [for]="competitionDatePicker" class="competition-datepicker-toggle"></mat-datepicker-toggle>
                <mat-datepicker #competitionDatePicker></mat-datepicker>
                {{user.nextCompetitionDate?.asFormatedString()}}
                <div *ngIf="user.nextCompetitionDate" class="display-inlineblock link marginleft-10">
                    <i class="bi bi-x-lg" (click)="onCompetitionDateChanged(null)"></i>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="spacer-20"></div>
        </div>
        <div class="col-5">
            Standard-Parameter RPE/RIR
        </div>
        <div class="col-7">
            <div class="d-inline-block wrapper-selection">
                <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getDefaultExertionParameter() ?? 'Nach Übung'}}</button>
                <div class="dropdown-menu" aria-labelledby="dropdownExertionParameter">
                    <button class="dropdown-item" type="button" (click)="onSelectDefaultExertionParameter(null)">Nach Übung</button>
                    <button class="dropdown-item" type="button" (click)="onSelectDefaultExertionParameter('rpe')">RPE</button>
                    <button class="dropdown-item" type="button" (click)="onSelectDefaultExertionParameter('rir')">RIR</button>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="spacer-10"></div>
            <h3>Sonstige</h3>
        </div>
        <div class="col-5">Ernährungsbereich:</div>
        <div class="col-7">
            <div class="recipesharing-toggle toggle-wrapper margin-4 mobile-marginbottom-10" matTooltip="Aktiviere den Ernährungsbereich in Traindoo für den Athleten.">
                <div class="toggle {{isNutritionEnabled() ? 'on' : ''}}" (click)="onEnableNutritionChanged(!isNutritionEnabled())">
                    <div class="handle"></div>
                </div>
                <div class="text">
                    Ernährung aktivieren <span *ngIf="user.nutritionEnabled == null">(Standardeinstellung)</span>
                </div>
            </div>
        </div>
        <div class="spacer-20"></div>
    </div>

    <div class="spacer-20"></div>

    <div class="actions">
        <button class="button-filled small light" (click)="onCancelDialog()">Schließen</button>
    </div>
</div>