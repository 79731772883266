import { Component, Inject, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import { MAT_DIALOG_DATA,MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UtilityService } from '../services/utility.service';
import { FirestoreService } from '../services/firestore.service';
import { ToastrService } from 'ngx-toastr';
import { Recipe } from '../model/recipe.model';
import { NutritionService } from '../services/nutrition.service';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { Tag } from '../model/tag.model';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Ingredient } from '../model/ingredient.model';
import { NutritionalValueHolder } from '../model/basenutritionfact.model';
import { User } from '../model/user.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';
import { LanguageService } from '../services/language.service';
import { AuthService } from '../auth/auth.service';
import { ImageEditorComponent } from '../utilities/image-editor/image-editor.component';
import { LanguageDictionary } from '../model/languagedictionary.model';
import { environment } from 'src/environments/environment';
import { OpenAIService } from '../services/openai.service';
import { ImageGenerationDialogComponent } from '../dialogs/image-generation-dialog/image-generation-dialog.component';
import { FileSharingComponent } from '../file-sharing/file-sharing.component';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ɵAngularFireSchedulers } from '@angular/fire';
import { initializeApp } from 'firebase/app';
import { firstValueFrom } from 'rxjs';
import { NutritionalSummary } from '../model/nutritionalsummary.model';

@Component({
  selector: 'app-recipe-editor',
  templateUrl: './recipe-editor.component.html',
  styleUrls: ['./recipe-editor.component.css']
})
export class RecipeEditorComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RecipeEditorComponent>, @Inject(MAT_DIALOG_DATA) private data: { recipe: Recipe, readOnly: boolean }, public utilityService: UtilityService, public userService: FirestoreService, public nutritionService: NutritionService, private spinner: NgxSpinnerService, private imageCompressor: NgxImageCompressService, private dialog: MatDialog, private toastr: ToastrService, public languageService: LanguageService, public authService: AuthService, private openAiService: OpenAIService, private ngZone: NgZone) {
    this.selectedRecipe = data.recipe
    this.readOnlyMode = data.readOnly ?? false
    dialogRef.disableClose = true
  }

  public readOnlyMode: boolean = false
  public environment = environment

  public selectedRecipe: Recipe
  public defaultTags: Tag[]
  public customTagName: string = ''

  public showRecipeNameErrorMessage = false;
  public showIngredientNutritionFactErrorMessage = false;
  public showIngredientWeightErrorMessage = false;

  public spinnerText = null
  private hasChanges: boolean = false
  private hasIngredientChanges: boolean = false

  public get recipeSharingEnabled() {
    return this.userService.getLoggedInUser().isRecipeSharingEnabled()
  }
  public get hasRecipeDatabaseAccess() {
    return this.userService.getLoggedInUser().hasRecipeDatabaseAccess
  }

  isAdmin(): boolean {
    return this.authService.isAdmin()
  }

  get editorValue(): string{
    return this.selectedRecipe?.getInstructions(this.languageService.selectedLanguageCode) ?? ''
  }
  set editorValue(value: string){
    if (this.selectedRecipe != null) {
      if (!this.selectedRecipe.instructionsTranslation) this.selectedRecipe.instructionsTranslation = new LanguageDictionary<string>()
      this.selectedRecipe.instructionsTranslation[this.languageService.selectedLanguageCode] = value
    }
  }

  placeholder =
      'Formatierung:\n\n' +

      '**fett**\n oder *kursiv*\n\n' +

      'Ungeordnete Liste:\n' +
      '- Element 1\n\n' +

      'Geordnete Liste:\n' +
      '1. Element a\n' +
      '2. Element b';

  ngOnInit(): void {
    this.defaultTags = Tag.getAllTags()

    // this.editorValue = this.selectedRecipe.instructionsTranslation[this.languageService.selectedLanguageCode]
    // if (this.editorValue?.length > 0) {
    //   (<HTMLInputElement> document.getElementById('editor-textarea')).value = this.editorValue
    // } else {
    //   (<HTMLInputElement> document.getElementById('editor-textarea')).value = ""
    // }

    this.dialogRef.backdropClick().subscribe(() => {
      this.onCloseRecipe()
    })
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
        this.onCloseRecipe()
      }
    });
  }


  doBeforeUnload() {
    // Show confirmation dialog before closing tab/browser.
    if (this.hasChanges) return false;
    return true
  }

  public nutritionalValueHolder: NutritionalValueHolder
  onNutritionalValuesFocused(nutritionalValueHolder: NutritionalValueHolder) {
    this.nutritionalValueHolder = nutritionalValueHolder
  }

  onRecipeNameChanged(text: string) {
    this.selectedRecipe.nameTranslation[this.languageService.selectedLanguageCode] = text
    if (text && text.length > 0) this.showRecipeNameErrorMessage = false
    this.hasChanges = true
  }
  onSaveRecipe() {
    if (!this.selectedRecipe.nameTranslation['de'] && this.selectedRecipe.name && !this.selectedRecipe.baseMealTemplateId) {
      this.selectedRecipe.nameTranslation['de'] = this.selectedRecipe.name
    }
    if (!this.selectedRecipe.instructionsTranslation['de'] && this.selectedRecipe.instructions) {
      this.selectedRecipe.instructionsTranslation['de'] = this.selectedRecipe.instructions
    }
    if ((!this.selectedRecipe.getName(this.languageService.selectedLanguageCode) || this.selectedRecipe.getName(this.languageService.selectedLanguageCode).length == 0) && !this.selectedRecipe.baseMealTemplateId) {
      this.showRecipeNameErrorMessage = true;
      return;
    } else {
      this.showRecipeNameErrorMessage = false;
      //this.selectedRecipe.instructionsTranslation[this.languageService.selectedLanguageCode] = this.editorValue || null
      if (this.selectedRecipe.instructionsTranslation.de?.length == 0) this.selectedRecipe.instructionsTranslation.de = null
      if (this.selectedRecipe.instructionsTranslation.en?.length == 0) this.selectedRecipe.instructionsTranslation.en = null
    }
    this.selectedRecipe.recalculateNutritionalValues();
    
    if (this.selectedRecipe.id && this.hasIngredientChanges) {
      this.dialogRef.close({ shouldSaveAsNew: true, shouldDelete: false });
    } else {
      this.dialogRef.close({ shouldSave: true, shouldDelete: false });
    }
    
    // this.editorValue = null;
    // (<HTMLInputElement> document.getElementById('editor-textarea')).value = ""
    //this.selectedRecipe = null

  }

  onCloseRecipe() {
    if (!this.hasChanges) {
      this.onCancelDialog()
      return
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du das Rezept vor dem Schließen speichern?', title: 'Rezept speichern', positiveButton: 'Ja', negativeButton: 'Nein' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.onSaveRecipe()
      } else {
        this.onCancelDialog()
      }
    })
  }

  async onDuplicateRecipe() {
    this.spinner.show()
    var newRecipe = this.selectedRecipe.clone()
    newRecipe.id = null
    /*if (this.selectedRecipe.baseMealTemplateId) {
      newRecipe.baseMealTemplateId = this.selectedRecipe.baseMealTemplateId
      newRecipe.baseRecipe = this.selectedRecipe.baseRecipe
    }*/
    newRecipe.name = this.selectedRecipe.name != null ? this.selectedRecipe.name + ' Kopie' : null
    if (newRecipe.nameTranslation['de']) {
      newRecipe.nameTranslation['de'] = newRecipe.nameTranslation['de'] + ' Kopie'
    }
    if (newRecipe.nameTranslation['en']) {
      newRecipe.nameTranslation['en'] = newRecipe.nameTranslation['en'] + ' Copy'
    }
    newRecipe.shared = false
    newRecipe.assignedUsers = []

    if (this.selectedRecipe.modelVersion < 2) {
      if (this.selectedRecipe.thumbnailURL) {
        newRecipe.thumbnailPath = 'meal_templates/' + this.selectedRecipe.id + '/thumbnail.jpg'
      }
    } else {
      newRecipe.thumbnailPath = this.selectedRecipe.getThumbnailPath()
    }
    await this.nutritionService.insertRecipe(newRecipe, this.userService.getLoggedInUser()).then()
    this.spinner.hide()
    this.onSaveRecipe()
  }

  onLoadRecipeBackup() {
    RecipeEditorComponent.loadRecipeBackup(this.selectedRecipe, this.ngZone, this.userService)
  }

  static async loadRecipeBackup(recipe: Recipe, ngZone: NgZone, userService: FirestoreService) {
    var options = {
      apiKey: "AIzaSyBQPj_tyt-3CzqR7sWx-l2ABo6oAeNqdag",
      authDomain: "nutrilize-whitelabel-test.firebaseapp.com",
      projectId: "nutrilize-whitelabel-test",
      storageBucket: "nutrilize-whitelabel-test.appspot.com",
      messagingSenderId: "619325009348",
      appId: "1:619325009348:web:fe542e82da69cc8f678891",
      measurementId: "G-G43V305738"
    };
    
    var backupApp = initializeApp(options, 'Backup');
    var firestore = new AngularFirestore(backupApp.options, 'Backup', false, {experimentalForceLongPolling: true}, PLATFORM_ID, ngZone, new ɵAngularFireSchedulers(ngZone), null, null, null, null, null, null, null, null, null, null);
    
    await firstValueFrom(firestore.collection('MealTemplates').doc(recipe.id).collection('Foods').get()).then(async (docs) => {
      for (var document of docs.docs) {
        var ingredient = new Ingredient(document.data() as Ingredient)
        ingredient.id = document.id
        ingredient.nutritionFactId = document.data().baseNutritionFactId
        if (ingredient.nutritionFactId) {
          ingredient.nutritionFact = await userService.getBaseNutritionFactById(ingredient.nutritionFactId).toPromise()
        }
        if (document.data().isDummy != null && document.data().isDummy == true) {
          ingredient.isDummy = true;
          ingredient.nutritionalSummary = new NutritionalSummary()
          ingredient.nutritionalSummary.carbohydrates = document.data().carbohydrates || 0
          ingredient.nutritionalSummary.protein = document.data().protein || 0
          ingredient.nutritionalSummary.fat = document.data().fat || 0
          ingredient.nutritionalSummary.calories = document.data().calories || 0
        }
        recipe.ingredients.push(ingredient)
      }
    })
  }
  
  onDeleteRecipe() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du dieses Rezept wirklich löschen?', title: 'Rezept löschen' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dialogRef.close({shouldDelete: true})
      } else {

      }
    })
  }

  onCancelDialog() {
    this.dialogRef.close(null);
  }
  onSetAllowWeightAdjustment(ingredient: Ingredient, checked: boolean) {
    ingredient.allowWeightAdjustment = checked
    this.hasChanges = true
  }
  addTagToSelectedRecipe(tag: Tag) {
    if (!this.selectedRecipe.tags) {
      if (this.selectedRecipe.baseRecipe) {
        this.selectedRecipe.tags = this.selectedRecipe.baseRecipe.tags
      }
    }
    if (!this.selectedRecipe.matchesTag(tag)) {
      if (this.selectedRecipe.tags == null || this.selectedRecipe.tags.length == 0) {
        this.selectedRecipe.tags = tag.rawTag
      } else {
        this.selectedRecipe.tags = this.selectedRecipe.tags + ';' + tag.rawTag
      }
    }
    this.selectedRecipe.computedTags = Tag.tagsFromString(this.selectedRecipe.getTags())
    var hasTag = false
    this.nutritionService.customTags.forEach(t => {
        if (t.printableIdentifier?.toLowerCase() == tag.printableIdentifier?.toLowerCase()) hasTag = true
    })
    this.defaultTags.forEach(t => {
      if (t.printableIdentifier?.toLowerCase() == tag.printableIdentifier?.toLowerCase()) hasTag = true
    })
    if (!hasTag) this.nutritionService.customTags.push(tag)
    this.hasChanges = true
  }

  removeTagFromSelectedRecipe(tag: Tag) {
    if (!this.selectedRecipe.matchesTag(tag)) return
    if (!this.selectedRecipe.tags) {
      if (this.selectedRecipe.baseRecipe) {
        this.selectedRecipe.tags = this.selectedRecipe.baseRecipe.tags
      }
    }
    var tags = this.selectedRecipe.tags
    tags = tags.replace(tag.rawTag, '')
    tags = tags.replace(';;', ';')
    if (tags.startsWith(';')) tags = tags.substr(1, tags.length)
    if (tags.endsWith(';')) tags = tags.substr(0, tags.length)
    if (tags.length == 0) tags = null
    this.selectedRecipe.tags = tags
    this.selectedRecipe.computedTags = Tag.tagsFromString(this.selectedRecipe.getTags())
    this.hasChanges = true
  }
  onCustomTagNameChanged(text: string) {
    this.customTagName = text
  }
  onAddCustomTag() {
    if (this.customTagName != null && this.customTagName.startsWith('#')) this.customTagName = this.customTagName.substring(1, this.customTagName.length)
    if (this.customTagName != null) this.customTagName = this.customTagName.replace('\'', '')
    if (this.customTagName?.length > 0) {
      var tag = new Tag(null, null, null)
      tag.identifier = Tag.IDENTIFIER_CUSTOM
      tag.rawTag = 'CUSTOM\'' + this.customTagName + '\''
      tag.printableIdentifier = this.customTagName
      this.addTagToSelectedRecipe(tag)
    }
    this.customTagName = ''
  }
  
  onRemoveSelectableTag(tag: Tag){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Der Tag wird aus allen Rezepten entfernt.\n Möchtest du diesen Tag wirklich vollständig löschen?', title: 'Tag löschen' },
      });
      dialogRef.afterClosed().subscribe(async result => {
        if (result) {
          try{
            this.spinner.show()
            await this.nutritionService.removeTagFromAllRecipes(tag);
          }
          catch(ex){
            console.log(ex);
            this.toastr.error("Fehler beim Löschen des Tags", "Fehler")
          }
          finally{
            this.spinner.hide()
          }
        }
      })
  }

  onEditorValueChanged(value: string) {
    if (value == this.placeholder) {
      this.editorValue = ''
    } else {
      this.editorValue = value
    }
    this.hasChanges = true
  }

  onPreparationTimeChanged(text: string) {
    if (text == null || text.length == 0) {
      this.selectedRecipe.preparationTimeMinutes = null
      return
    }
    var regex = new RegExp("^[0-9]+$");
    if (regex.test(text) && parseInt(text) > 0)  {
      var number = parseInt(text);
      if (number < 1) number = null
      this.selectedRecipe.preparationTimeMinutes = number
      console.log(this.selectedRecipe.preparationTimeMinutes)
    } else {}
    this.hasChanges = true
  }
  onTotalTimeChanged(text: string) {
    if (text == null || text.length == 0) {
      this.selectedRecipe.totalTimeMinutes = null
      return
    }
    var regex = new RegExp("^[0-9]+$");
    if (regex.test(text) && parseInt(text) > 0)  {
      var number = parseInt(text);
      if (number < 1) number = null
      this.selectedRecipe.totalTimeMinutes = number
    } else {}
    this.hasChanges = true
  }

  onNumberServingsChanged(text: string) {
    var regex = new RegExp("^[0-9]+$");
    if (regex.test(text) && parseInt(text) > 0)  {
      var number = parseInt(text);
      if (number < 1) number = 1
      this.selectedRecipe.servings = number
    } else {}
    this.selectedRecipe.recalculateNutritionalValues()
    this.hasChanges = true
    this.hasIngredientChanges = true
  }
  onAddIngredient(ingredient: Ingredient) {
    if (!this.selectedRecipe.ingredients) this.selectedRecipe.ingredients = this.selectedRecipe.getIngredients().map(f => f.clone())
    this.selectedRecipe.ingredients.push(ingredient)
    this.selectedRecipe.recalculateNutritionalValues()
    this.hasChanges = true
    this.hasIngredientChanges = true
  }
  onIngredientWeightChanged(text: string, ingredient: Ingredient) {
    if (!this.selectedRecipe.ingredients) {
      this.selectedRecipe.ingredients = this.selectedRecipe.getIngredients()?.map(f => f.clone()) ?? []
      ingredient = this.selectedRecipe.ingredients.find(f => f.nutritionFactId == ingredient.nutritionFactId && f.name == ingredient.name && f.weight == ingredient.weight)
    }
    var regex = new RegExp("^[0-9]+$");
    if (regex.test(text) && parseInt(text) > 0)  {
      var number = parseInt(text);
      ingredient.weight = number
    } else {
      ingredient.weight = 0
    }
    this.selectedRecipe.recalculateNutritionalValues()
    this.hasChanges = true
    this.hasIngredientChanges = true
  }
  onDeleteIngredient(ingredient: Ingredient) {
    if (!this.selectedRecipe.ingredients) {
      this.selectedRecipe.ingredients = this.selectedRecipe.getIngredients().filter(f => f != ingredient).map(f => f.clone())
    } else {
      this.selectedRecipe.ingredients.forEach( (item, index) => {
        if (item == ingredient) this.selectedRecipe.ingredients.splice(index, 1);
      });
    }
    
    this.selectedRecipe.recalculateNutritionalValues()
    this.hasChanges = true
    this.hasIngredientChanges = true
  }

  dropFood(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedRecipe.ingredients, event.previousIndex, event.currentIndex);
    this.hasChanges = true
  }
  onAddGroupHeading(ingredient: Ingredient) {
    ingredient.groupHeading = 'Gruppe'
    this.hasChanges = true
  }
  onDeleteGroupHeading(ingredient: Ingredient) {
    ingredient.groupHeading = null
    this.hasChanges = true
  }
  
  onUploadRecipeImage() {
    document.getElementById('input-recipeimage').click()
  }
  private newThumbnail: File = null;

  onNewRecipeImageUploaded(e) {
    var file: File;
    file = e.target.files[0];

    const dialogRef = this.dialog.open(ImageEditorComponent, {
      data: { imageFile: file, aspectRatio: 1, maintainAspectRatio: true, containWithinAspectRatio: true, maxDimension: 800 },
      width: '1000px',
    });
    dialogRef.afterClosed().subscribe(async result => {
      if(result?.croppedImage) {
        var thumbnailImageSrc: any = await this.blobToBase64(result.croppedImage);
        this.newThumbnail = result.croppedImage;
        this.selectedRecipe.thumbnailURL = thumbnailImageSrc
        this.selectedRecipe.updatedImage = this.newThumbnail
        this.selectedRecipe.thumbnailUrl = null
        this.hasChanges = true
      }
    });
  }

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }

  autoGenerateRecipeImage() {
    var dialog = this.dialog.open(ImageGenerationDialogComponent, { data: { initialPrompt: this.selectedRecipe.nameTranslation[this.languageService.selectedLanguageCode] }, width: '1000px' });
    dialog.afterClosed().subscribe(async result => {
      if (result?.success && result?.imageUrl) {
        var url = result.imageUrl
        var base64 = result.base64
        const imageBlob = FileSharingComponent.dataURItoBlob(url);
        const imageFile = new File([imageBlob], 'thumbnail.jpg', { type: 'image/jpeg' })
        this.selectedRecipe.thumbnailURL = url
        this.selectedRecipe.updatedImage = imageFile
      }
    })
  }

  onAllAssignedUserDropdownValueChanged(checked: boolean) {
    if (checked) {
      this.selectedRecipe.assignedUsers = ["all"]
    } else {
      this.selectedRecipe.assignedUsers = []
    }
    this.hasChanges = true
  }
  onAssignedGroupDropdownValueChanged(group: string){
    //if (!this.canAccessGroup(group)) return
    if (this.selectedRecipe.assignedGroupNames.includes(group)) {
      this.selectedRecipe.assignedGroupNames.forEach( (item, index) => {
        if (item == group) this.selectedRecipe.assignedGroupNames.splice(index, 1)
      })
    } else {
      this.selectedRecipe.assignedGroupNames.push(group)
    }
    this.hasChanges = true
  }
  onAssignedUserDropdownValueChanged(user: User){
    //if (!this.canAccessUser(user)) return
    if (this.selectedRecipe.assignedUsers.includes(user.uid)) {
      this.selectedRecipe.assignedUsers.forEach( (item, index) => {
        if (item == user.uid) this.selectedRecipe.assignedUsers.splice(index, 1)
      })
    } else {
      this.selectedRecipe.assignedUsers.push(user.uid)
    }
    this.hasChanges = true
  }
  getSelectableClientGroups() {
    return this.userService.getClientGroups()
  }
  getSelectableClients() {
    return this.userService.getAccessibleClients()
  }
  areAllUsersTargeted() {
    return this.selectedRecipe?.assignedUsers?.includes('all')
  }

  onShareSelectedRecipeChanged(shared: boolean) {
    this.selectedRecipe.shared = shared
  }

  clearRecipeErrorMessages() {
    document.getElementById('errormessage-recipename').innerHTML = ""
  }
  clearIngredientErrorMessages() {
    document.getElementById('errormessage-ingredient-nutritionfact').innerHTML = ""
  }
}
