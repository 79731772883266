<div id="notes-wrapper" class="notes-wrapper">
    <ng-container *ngFor="let note of user?.notes">
        <div class="layout-box note-box">
            <div class="date">{{note.getPrintableDate()}}</div>
            <div class="icon-button edit-note" (click)="onEditNote(note)">
                Bearbeiten
            </div>
            <div [innerHtml]="compileMarkdown(note.content)"></div>
            <div *ngIf="note.hasAttachment() && note.hasImageAttachment()" class="image-wrapper">
                <img draggable="false" src="{{note.attachmentUrl}}" alt="">
            </div>
            <div *ngIf="note.hasAttachment() && !note.hasImageAttachment()" class="file-wrapper">
                <a class="attached-file" href="{{note.attachmentUrl}}" target="_blank">{{note.attachedFileName}}</a>
            </div>
        </div>
    </ng-container>
</div>
<div class="newnote-wrapper">
    <app-md-editor class=""
        [placeHolder]="editorPlaceholder"
        (valueChanged)="onEditorValueChanged($event)">
    </app-md-editor>
    <div *ngIf="editableNote?.attachedFileName" class="file-wrapper selected-attachment">
        <div class="attached-file" target="_blank">
            {{editableNote?.attachedFileName}}
            <div class="icon-button" (click)="onRemoveSelectedFile()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </div>
        </div>
    </div>
    <button class="add-attachment" (click)="onSelectAttachment()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-paperclip" viewBox="0 0 16 16">
            <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"/>
        </svg>
        <input id="input-note-attachment" type="file" style="display: none;" accept=".jpg, .png, .pdf" (change)="onFileSelected($event)">
    </button>
    <button *ngIf="editableNote?.content?.length > 0 || editableNote?.attachedFileName || editableNote?.id" class="button-savenote" (click)="onSaveNote()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
    </button>
</div>