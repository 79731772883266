<div class="page-content">
    <div class="container-fluid">
        <div class="row">
            
            <div class="spacer-20"></div>

            <div class="col-12 col-xxl-6">
                
                <div class="row">
                    <div class="col-12">
                        <h2 class="">News-Board</h2>
                        <div class="layout-box news-feed">
                            <h3 class="display-inlineblock" id="push-notification-headline">Neuigkeiten für Dich.</h3>
                                <div class="">
                                    <div class="layout-box news-box">
                                        <h3>Neu in nutrilize: Bearbeiten von Kundendaten als Coach <span class="label">14.02.2024 ❤️</span></h3>
                                        <div class="bold">In der Coaching Zone:</div>
                                        <ul>
                                            <li>Mahlzeiten- und Fortschrittstracking über die Coaching Zone<br>
                                            - Für dich als Coach: Übernehme Einträge für deine Coachees, korrigiere falsche Eintragungen und trage Daten nach<br>
                                            - Für deine Coachees: Coaching Zone im Web für Eintragungen nutzbar<br>
                                            - Trainings-Feature momentan noch ausgeschlossen, ist aber in Planung
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Neu in nutrilize: Dateiablage 2.0 und Neues Info- & Supportkonzept <span class="label">12.02.2024</span></h3>
                                        <div class="bold">In der Coaching Zone:</div>
                                        <ul>
                                            <li>Dateiablage 2.0<br>
                                            - Neues Design<br>
                                            - Ordner an unterschiedliche Kundengruppen freigeben<br>
                                            - Hinzufügen von Thumbnails, Beschreibungen etc.
                                            </li>
                                            <li>Info & Support-Konzept (Runder Button unten rechts)<br>
                                            - Support-Chat direkt über die Coaching Zone<br>
                                            - 30+ neue Support-Artikel mit Schritt-für Schritt Anleitungen
                                            </li>
                                        </ul>
                                        <div class="bold">In der App:</div>
                                        <ul>
                                            <li>Dateiablage 2.0<br>
                                            - Weiterentwicklung als Wissensplattform, Neues Design<br>
                                            - Videos direkt in der nutrilize App abspielbar<br>
                                            - Rückfragen zu Videos direkt über den Chat stellen<br>
                                            - Videos als gesehen markieren
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Neu in nutrilize: Aufgaben erstellen/erledigen und neue Kundenübersicht <span class="label">17.01.2024</span></h3>
                                        <div class="bold">In der Coaching Zone:</div>
                                        <ul>
                                            <li>Erstelle und erledige Aufgaben mit Zuständigkeiten, Erinnerungen, Prioritäten etc. für dich und dein Team. Du findest die Aufgaben auf dem Dashboard beim Ereignisfeed sowie über den türkisen Button unten rechts.</li>
                                            <li>Behalte die Übersicht über deine Kund:innen inkl. offener Check-Ins, Körpergewichtsveränderung und ablaufenden Plänen in einer Listenansicht. Du kannst im Menüpunkt Kundenübersicht oben rechts zwischen Kacheln und Liste wechseln.</li>
                                            <li>Check-Ins, die du noch nicht angesehen hast, werden mit einem türkisen Punkt hervorgehoben</li>
                                            <li>Markiere mehrere Rezepte in deiner Datenbank, um sie gesammelt zu löschen oder zuzuweisen</li>
                                            <li>Passe die Zahlungsmethoden, das Enddatum und das Abrechnungsdatum bestehender Käufe an</li>
                                        </ul>
                                        <div class="bold">In der App:</div>
                                        <ul>
                                            <li>Vorschauansicht von Sätzen, Wiederholungen, Gewichten und Satzdauern für geplante und aktive Trainings</li>
                                            <li>Fix für doppelte Rezepte in der Rezeptdatenbank</li>
                                            <li>Fixes im Bereich Training</li>
                                        </ul>
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Neu in nutrilize: Verbessertes Trainingscoaching auf allen Ebenen <span class="label">20.11.2023</span></h3>
                                        <div class="bold">In der App:</div>
                                        <ul>
                                            <li>Verbessertes UI der Trainingsplanübersicht</li>
                                            <li>Zugewiesene Trainingspläne eigenständig anpassen und eigene Pläne erstellen (nur bei Freigabe durch Coach)</li>
                                            <li>Trainingsprogramme: Aus geteilten Planvorlagen des Coaches gewünschten Plan/Einheit auswählen und nutzen</li>
                                            <li>Übungsalternativen des Coaches priorisiert anzeigen</li>
                                        </ul>
                                        <div class="bold">In der Coaching Zone - Training:</div>
                                        <ul>
                                            <li>Übungen innerhalb und zwischen Einheiten kopieren</li>
                                            <li>Verbesserte Trainingsplanerstellung mit Untertiteln, Thumbnails, allgemeinen Hinweisen zum Plan und einer geschätzten Trainings-Dauer</li>
                                            <li>Einzelne Einheiten aus einer Vorlage in Plan einfügen</li>
                                            <li>Trainingsplanvorlagen als Programme mit Coachees in der App teilen, ohne diese direkt als Plan zuweisen zu müssen<br>
                                             - Organisation von Planvorlagen in Ordnern<br>
                                             - Teilen von Ordner und Vorlagen mit Coachees und Gruppen
                                            </li>
                                            <li>Einfügen von vorgeschlagenen Alternativübung für Coachees</li>
                                            <li>Info-Button innerhalb der Trainingsplanerstellung in jeder Übung, um sich die Spezifikationen der Übung anzuschauen</li>
                                            <li>Verbesserte Trainingshistorie:<br>
                                             - Workouts miteinander vergleichen (ähnlich zu Check-In Vergleich)<br>
                                             - Ziel/-Ist-Vergleich der einzelnen Parameter für absolvierte Einheiten<br>
                                             - Ausführungsvideos auch für einen Supersatz anzeigen
                                            </li>
                                        </ul>
                                        <div class="bold">In der Coaching Zone - Ernährungsplanung:</div>
                                        <ul>
                                            <li>Bilder bereits in Rezeptauswahl und Mahlzeitenübersicht einsehen</li>
                                            <li>Algorithmus zur Planerstellung: Möglichkeit, Genauigkeitslevel zu erhöhen</li>
                                        </ul>
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Neu in nutrilize: Mahlzeitenvorschläge und UX-Verbesserungen in der App, Trainingsplanexport als PDF, Zahlungsabwicklung <span class="label">12.10.2023</span></h3>
                                        <div class="bold">In der App:</div>
                                        <ul>
                                            <li>Graphische Übersicht der Makroverteilung innerhalb einzelner Mahlzeiten</li>
                                            <li>Anweisungen aus Ernährungsplänen, Rezepten und Lieblingsmahlzeiten bleiben auch in der getrackten Mahlzeit erhalten</li>
                                            <li>Vorschläge für passende Alternativen beim Austausch von Übungen während des Trainings</li>
                                            <li>Mehrfachauswahl von Übungen beim Erstellen einer eigenen Einheit/Ergänzen von Einheiten</li>
                                            <li>Anzeige von Gewichtsvorgaben in der Vorschau zu Supersätzen</li>
                                        </ul>
                                        <div class="bold">In der Coaching Zone:</div>
                                        <ul>
                                            <li>Export des Trainingsplans als PDF</li>
                                            <li>Neu gestalteter Einladungsprozess für Coachees<br>
                                             - Reaktiviere Coachees aus der Vergangenheit<br>
                                             - Erweiterte Voreinstellungen: Metriken zuweisen, Kalorien ausblenden etc.<br>
                                             - Textvorlage anpassen
                                            </li>
                                            <li>Möglichkeit zur Deaktivierung des Chats für einzelne Coachees (in der Chat-Kopfzeile).</li>
                                            <li>Möglichkeit zur Aktivierung von automatisierten Mahlzeitenvorschlägen in der App (im Menü unter Rezepte).</li>
                                            <li>Rollout Zahlungsabwicklung:<br>
                                             - Vollautomatisierte Zahlungsabwicklung und Rechnungserstellung für dein Coaching<br>
                                             - Produkte, Laufzeiten und Preise anlegen<br>
                                             - Landing-Page mit ausgewählten Produkten teilen<br>
                                             Um mehr Infos und Zugriff auf die Zahlungsabwicklung zu erhalten, schreibe uns einfach eine kurze Nachricht an alexander&#64;nutrilize.app
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Neu in nutrilize: Verbesserter Ernährungsplan PDF-Export und Feedback zu Check-Ins! <span class="label">18.09.2023</span></h3>
                                        Unser nächstes Update ist ready und liefert dir folgende neue Funktionen:<br><br>
                                        <div class="bold">In der Coaching Zone:</div>
                                        <ul>
                                            <li>Verbesserter Ernährungsplan-Export als PDF (Layout, Integration deines Logos & Option zum Anzeigen der Zutaten direkt im Plan)</li>
                                            <li>Verbesserte Check-Ins: Direktes Feedback geben und Inhaltsseiten einfügen (z.B. mit Hinweisen, Links oder Bild)</li>
                                            <li>Direkter Zugriff auf absolvierte Trainings & Check-Ins über den Ereignisfeed</li>
                                        </ul>
                                        <div class="bold">In der App:</div>
                                        <ul>
                                            <li>Fixes für Health-Synchronisation: Schlafimport über Apple Health, Überschreiben der Alltagsaktivitäten</li>
                                            <li>Erhalten von Feedback zu Check-Ins</li>
                                        </ul>
                                        <div class="bold">nutrilize Premium:</div>
                                        <ul>
                                            <li>Einführung eines In-App-Produkts für Nutzer:innen ohne Coaching</li>
                                            <li>Verbindung mit Health-Apps, Zugriff auf nutrilize Rezeptdatenbank, Trainings- und Ernährungsstatistiken etc.</li>
                                            <li>Nach Deaktivierung der Lizenz durch dich als Coach erhalten die Nutzer:innen einen zweiwöchigen, kostenlosen Premiumzugang zur Weiternutzung der App, welchen sie danach kostenpflichtig verlängern können.</li>
                                            <li>Vorteil für dich als Coach: Nutzer:innen bleiben in der App und können besser reaktiviert werden zur Wiederaufnahme des Coachings.</li>
                                        </ul>
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Neu in nutrilize: Dateiablage für deine Coachees und dich! <span class="label">28.07.2023</span></h3>
                                        Unser nächstes Update ist ready und liefert dir folgende neue Funktionen:<br><br>
                                        <div class="bold">In der Coaching Zone:</div>
                                        <ul>
                                            <li>Teilen von Bildern, Videos, Dokumenten oder Links in die Dateiablage mit deinen Coachees</li>
                                            <li>Training: Vollständige Bearbeiten von Übungen aus der nutrilize Datenbank, Duplizieren von Übungen</li>
                                            <li>Metriken: Gegenüberstellung von Bildmetriken im 1-zu-1-Vergleich</li>
                                            <li>Lizenzverwaltung: Direkter Versand von Einladungen an Coachees via Email</li>
                                            <li>Chat: Verbesserte Übersicht und Sortierun im Browser</li>
                                        </ul>
                                        <div class="bold">In der App:</div>
                                        <ul>
                                            <li>Abrufen und Download von Elementen aus der Dateiablage</li>
                                        </ul>
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Neu in nutrilize: Check-Ins und verbesserte Metriken <span class="label">03.07.2023</span></h3>
                                        Unser nächstes Update ist ready und liefert euch folgende neue Funktionen:<br><br>
                                        <div class="bold">In der Coaching Zone:</div>
                                        <ul>
                                            <li>Integration der Körperdaten in die Metriken (individuelle Zuweisung von Umfängen etc.)</li>
                                            <li>Erstellen von Metrik-Sets, um das Zuweisen zu erleichtern</li>
                                            <li>Neue Metriktypen der Art Videoupload, Mehrfachauswahl und Todo</li>
                                            <li>Erstellen von Check-In-Formularen, welche entweder regelmäßig an Coachees versandt werden oder durch ein Event getriggert werden (bspw. Anamnesebogen, Weekly Check-In, Feedback nach Training)</li>
                                            <li>Auswertung und Gegenüberstellung verschiedener Check-Ins</li>
                                            <li>Design-Update der Menüleiste<br>
                                                - Neues Icon "Coaching-Tools" beinhaltet Check-Ins und Erinnerungen<br>
                                                - Personalisierung rutscht in Bereich "Einstellungen und Account"
                                            </li>
                                        </ul>
                                        <div class="bold">In der App:</div>
                                        <ul>
                                            <li>Ausfüllen und Nachverfolgen von oben beschriebenen Check-Ins</li>
                                            <li>Neuen Metriken-Widget auf dem Dashboard zum schnellen Tracken bestimmter Metriken</li>
                                        </ul>
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Trainingsupdate 1.5 + neue Ernährungsfunktionen <span class="label">09.06.2023</span></h3>
                                        Unser nächstes Update im Bereich Training ist ready und liefert euch folgende neue Funktionen:<br><br>
                                        <div class="bold">TRAINING:</div>
                                        <div class="bold">In der App:</div>
                                        <ul>
                                            <li>Verbesserungen und Fixes im Ablauf</li>
                                            <li>Ansage der Pausen und Übungen in Supersätzen</li>
                                            <li>Verlängerung/Verkürzung von Pausenzeiten direkt in der App</li>
                                            <li>Parallele Musikwiedergabe und Pausenansage</li>
                                        </ul>
                                        <div class="bold">In der Coaching Zone:</div>
                                        <ul>
                                            <li>Auto-Durchlauf bei Sätzen mit vorgegebener Dauer (Automatischer Durchlauf zum nächsten Satz/Pause)</li>
                                        </ul>
                                        <div class="bold">ERNÄHRUNG:</div>
                                        <div class="bold">In der App:</div>
                                        <ul>
                                            <li>Erweitertes Nährstoffmonitoring: Einblick in Mikronährstoffaufnahme/Ziele</li>
                                            <li>Ausblenden von Kalorien und Nährwertdaten/-zielen (Kunde kann tracken, ohne Nährstoffe einzusehen)</li>
                                        </ul>
                                        <div class="bold">In der Coaching Zone:</div>
                                        <ul>
                                            <li>Erweiterte Nährstoffzielvorgabe (Ziele für erweiterte Makronährstoffe und Mikronährstoffe)</li>
                                            <li>Nährstoffe und Kalorien in der App ausblenden</li>
                                            <li>Detaillierte Analyse von Mikronährstoffen über einen vorgegebenen Zeitraum</li>
                                            <li>Anpassung der Auswertungszeiträume auf beliebigen Zeitrahmen</li>
                                        </ul>
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Trainingsupdate 1.4 <span class="label">01.05.2023</span></h3>
                                        Unser nächstes Update im Bereich Training ist ready und liefert euch folgende neue Funktionen:<br><br>
                                        <div class="bold">In der Coaching Zone:</div>
                                        <ul>
                                            <li>Erstellen von Supersätzen, Zirkeltrainings und anderen Trainingsmodi (AMRAP)</li>
                                            <li>Erweiterungen der Satzparameter um Satzdauer, Pace, Distanz, Time under Tension etc.</li>
                                            <li>Einsicht in Übungshistorie des Coachees während Erstellung eines Trainingsplans</li>
                                        </ul>
                                        <div class="bold">In der App:</div>
                                        <ul>
                                            <li>Absolvierung der oben aufgeführten Trainingsmodi inklusive Satz- und Rundentimer</li>
                                            <li>Absolvierung von Sätzen auf Zeit, Tracking der neuen Satzparameter</li>
                                            <li>Nachträgliche Bearbeitung von absolvierten Einheiten (Korrektur von Gewichten, Übungen, Wiederholungen etc.)</li>
                                        </ul>
                                        <div class="bold">Sonstiges (Coaching Zone):</div>
                                        <ul>
                                            <li>Broadcast-Nachrichten: Sende Nachrichten, Bilder etc. an mehrere/alle Coachees gleichzeitig.</li>
                                        </ul>
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Trainingsupdate 1.3 <span class="label">09.03.2023</span></h3>
                                        Unser nächstes Update im Bereich Training ist ready und liefert euch folgende neue Funktionen:<br><br>
                                        <div class="bold">In der Coaching Zone:</div>
                                        <ul>
                                            <li>Clustern der Übungen durch Hinzufügen von Zwischenüberschriften</li>
                                            <li>Verbesserte Analyse-/ Vergleichsmöglichkeiten: Statistiken auf Ebene der Einheiten</li>
                                        </ul>
                                        <div class="bold">In der App:</div>
                                        <ul>
                                            <li>Einsicht in Trainingsstatistiken analog Coaching Zone</li>
                                            <li>Ersetzen und Löschen von Übungen während des Trainings</li>
                                            <li>Erstellen von eigenen Workouts ohne vorgegebenen Trainingsplan</li>
                                        </ul>
                                        <div class="bold">Sonstiges:</div>
                                        <ul>
                                            <li>Apple Health / Google Fit: Import von "Alltagskalorien" zusätzlich zu getackten Aktivitäten</li>
                                        </ul>
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Trainingsupdate 1.2 + Sprachnachrichten <span class="label">12.02.2023</span></h3>
                                        Unser nächstes Update im Bereich Training ist ready und liefert euch folgende neue Funktionen:<br><br>
                                        <div class="bold">In der Coaching Zone:</div>
                                        <ul>
                                            <li>Übersicht der Trainingsfortschritte deiner Coachees im Tabellenformat</li>
                                            <li>Trainingsvideos/Technikchecks: Anfragen von Videoaufnahmen von Übungen im Trainingsplan und Einsicht in Trainingsaufnahmen, integrierte Feedbackfunktion und Vergleich im historischen Verlauf im Bereich "Fortschritt"
                                        </li>
                                        </ul>
                                        <div class="bold">In der App:</div>
                                        <ul>
                                            <li>Erstellung von Videoaufnahmen während des Workouts</li>
                                        </ul>
                                        <div class="bold">Sonstiges (Chat):</div>
                                        <ul>
                                            <li>Verfassen von Sprachnachrichten</li>
                                            <li>Löschen von Nachrichten</li>
                                        </ul>
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Trainingsupdate 1.1 <span class="label">16.01.2023</span></h3>
                                        Unser nächstes Update im Bereich Training ist ready und liefert euch folgende neue Funktionen:<br><br>
                                        <div class="bold">In der Coaching Zone:</div>
                                        <ul>
                                            <li>Einsicht in absolvierten Einheiten (bei euren Coachees im Tab "Fortschritt" und im Tagebuch bei den Aktivitäten)</li>
                                            <li>Progressionsauswertung auf Basis von Gewichten, Wiederholungen, Gesamtbelastung etc. (bei euren Coachees im Tab "Fortschritt")</li>
                                        </ul>
                                        <div class="bold">In der App:</div>
                                        <ul>
                                            <li>Hinzufügen von Übungen während des Trainings</li>
                                            <li>Erstellen von Notizen zu einzelnen Übungen</li>
                                        </ul>
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>We're live! - Trainingsfeature 1.0 <span class="label">15.12.2022</span></h3>
                                        Endlich ist es soweit. Wir launchen die erste Version unseres Trainings-Features in der Coaching Zone und Kunden-App.<br>
                                        <br>
                                        <div class="bold">Darin enthalten sind:</div><br>
                                        <ul>
                                            <li>Eine Übungsdatenbank mit über 200 vorgefertigten Übungen aus dem Bereich Geräte- und Freihanteltraining, Körpergewichtsübungen, Cross-Fit, Dehnübungen etc. Natürlich wird diese Datenbank ständig erweitert und neue Übungen hinzugefügt.
                                                <br> - Inklusive Animation der Übungsausführung, detaillierten Instruktionen und der Möglichkeit, ein persönliches Ausführungsvideo zu hinterlegen
                                                <br> - Erstellen eigener Übungen zur Nutzung in deinen Trainingsplänen
                                            </li>
                                            <li>
                                                Ein Feature, mit welchem du individuelle Trainingspläne für deine Coachees erstellen und an sie verteilen kannst.
                                                <br> - Planung von verschiedenen Einheiten eines Workout-Zyklus
                                                <br> - Zuweisung von Übungen zu den jeweiligen Einheiten inklusive Satzanzahl, Gewichtseinstellungen, Pausenzeiten & spezifischen Hinweisen

                                            </li>
                                            <li>
                                                Ein App-Update, welches nutrilize zum All-in-One-Tool macht
                                                <br> - Integration der erstellten Trainingspläne, welche vom Coachee jederzeit aufgerufen und absolviert werden können
                                                <br> - Trainings-Dokumentation (Satzanzahl, Gewichte, Intensität)
                                                <br> - Integrierter Pausen- & Workout-Timer
                                                <br> - Integration der hinterlegten Instruktionen, Animationen und Videos zur Übungsausführung
                                            </li>
                                        </ul>
                                        
                                        <br>
                                        <div class="bold">Was du tun musst, um das Trainingsfeature mit deinen Coachees zu nutzen:</div><br>
                                        <ul>
                                            <li>Aktiviere das Feature im Bereich „Account“ in deiner Coaching Zone. In der Menüleiste links erscheint der Tab „Übungen“, hinter welchem sich deine Übungsdatenbank verbirgt.</li>
                                            <li>Bitte deine Coachees, die App über den jeweiligen Store auf die neuste Version zu Updaten (Beim Öffnen der App läuft ein Import der alten Daten (Blaue Anzeige „Synchronisieren“).</li>
                                            <li>Jetzt hast du die Möglichkeit, für jede:n Coachee, welche:r die neuste Version der App auf dem Smartphone hat, im Bereich „Planung“ individuelle Trainingspläne zu erstellen und an deine Coachees zu verteilen.</li>
                                        </ul>

                                        <br>
                                        Als Dank für deine Geduld werden wir dir das Trainings-Feature in den ersten beiden Monaten kostenlos zur Verfügung stellen. Danach hast du die Wahl – nutzt du Training und Ernährung im Bundle oder bleibst du bei Ernährung only.

                                        <br><br>
                                        <div class="bold">Nach dem Launch ist vor dem Update – was dich im neuen Jahr erwartet:</div><br>

                                        <ul>
                                            <li>Ausführliche Analysemöglichkeiten der absolvierten Workouts und der Progression in der Coaching Zone</li>
                                            <li>Erstellen von Supersätzen, Speicherung oft genutzter Wiederholungsmuster (Pyramide etc.) und Progressionsplanung</li>
                                            <li>Aufnahme von Technikvideos im Training zur Analyse durch den Coach</li>
                                        </ul>
                                        <br>
                                        <div class="bold">Stay Tuned!</div>
                                        
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>WICHTIG! Steige jetzt auf die neuen Einstellungen um! <span class="label">09.12.2022</span></h3>
                                        In der neuen App-Version (Rollout ab heute) werden die alten Nährstoffziele und Ernährungspläne nicht mehr unterstützt.<br>
                                        Wenn du bei einigen deiner Coachees noch die alten Nährstoffziele und Ernährungspläne nutzt, steige bitte auf die neuen Einstellungen um.<br>
                                        Wir haben dir den Umstieg einfach gemacht und eine automatische Übertragung eingebaut. Infos dazu findest du bei den entsprechenden Coachees unter Planung.<br>
                                        Außerdem wird dir ein Indikator in der Kundenkachel angezeigt, wenn der Coachee die neue App-Version bereits installiert hat.<br>
                                        Wenn du Fragen hast oder Hilfe brauchst, melde dich gerne bei uns!
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Entdecke die Coaching Zone jetzt auch als App! <span class="label">26.09.2022</span></h3>
                                        Unterwegs nach den Fortschritten deiner Coachees schauen, das nächste Coaching vorbereiten, Feedback geben oder Fragen im Chat beantworten? Kein Problem! Mit der Coaching Zone als Smartphone-App hast du deine Coachees immer in der Tasche und bleibst Dank Push-Notifications überall und jederzeit auf dem Laufenden.
                                        <br><br>
                                        Suche im App Store oder Play Store einfach nach “nutrilize for Coaches”!
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Ernährungsplanung 2.0 verfügbar! <span class="label">01.09.2022</span></h3>
                                        Die neuen Ernährungseinstellungen sind jetzt für alle App-Nutzer:innen verfügbar, sobald das neuste Update installiert wurde.<br>
                                        Da einige Nutzer:innen ihren Account in der App bestätigen müssen, achte bitte darauf, dass deine Coachees in der App weiterhin angemeldet und mit dir verbunden sind. Falls du keine neuen Daten von deinen Kund:innen über die App erhalten solltest, lasse bitte deren Anmeldung in der App überprüfen.
                                        <br><br>
                                        Um dich mit dem Update vertraut zu machen, schau dir doch unsere neuen Tutorials an (unten links im Menü)!
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Ernährungsplanung 2.0: Rollout gestartet <span class="label">08.08.2022</span></h3>
                                        Die Ernährungspläne und Nährstoffziele haben ein großes Update bekommen!<br>
                                        Mit dem Update kannst du...<br>
                                        • Nährstoffziele an Zeiträume knüpfen, vorausplanen und in der Historie einsehen.<br>
                                        • Ernährungspläne direkt mit den Nährstoffzielen synchronisieren.<br>
                                        • mehrere Ernährungspläne nacheinander schalten und für deine Coachees sichtbar machen.<br>
                                        • mehrere Rezeptoptionen in einer Mahlzeit zur Auswahl stellen.<br>
                                        • Rezepte vorgeschlagen bekommen, die perfekt in eine Mahlzeit des Plans passen.<br>
                                        • Pläne sich automatisch wiederholen lassen.<br>
                                        • Rezeptkategorien nicht nur einschließen sondern auch ausschließen.<br>
                                        <br>
                                        In den kommenden Tagen erhalten Android-Nutzer:innen das Update, woraufhin du unter Planung die Möglichkeit hast, zu den neuen Einstellungen zu wechseln. iOS-Nutzer:innen sollen das Update in 1-2 Wochen erhalten.<br><br>
                                        Um dich mit dem Update vertraut zu machen, schau dir doch unsere neuen Tutorials an (unten links im Menü)!
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Du bist gefragt! <span class="label">20.06.2022</span></h3>
                                        Wir haben News für dich: Wir werden dir bald auch die Möglichkeit bieten, deine Trainingsplanung in nutrilize durchzuführen und damit deine Coachees noch ganzheitlicher zu betreuen.<br>
                                        Wir würden uns freuen, wenn du an dieser Umfrage teilnimmst und wir so auch deine Meinung bei der Planung berücksichtigen können!<br>
                                        <a href="https://forms.gle/xQGonyW9BYLbtNs77" target="_blank">Zur Umfrage</a>
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Ereignisfeed für dein Dashboard <span class="label">22.05.2022</span></h3>
                                        Wir haben dein Dashboard im Portal um einen Ereignisfeed erweitert!<br>
                                        Damit bleibst du immer up to date, was sich bei deinen Coachees tut und hast innerhalb weniger Sekunden den vollen Überblick.
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>App-Update für Fortschrittsbildschirm und neue Umfänge <span class="label">01.05.2022</span></h3>
                                        Der Fortschrittsbildschirm in der App hat ein großes Update bekommen: Der Graph wurde neu designed, Metriken lassen sich jetzt auch in der App im Graphen auswerten und wir haben die Umfänge erweitert.<br>Passend dazu kam auch eine Grafik als Hilfestellung dazu, wo die Umfänge gemessen werden sollen.
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Ernährungspläne automatisch befüllen - Jetzt testen! (Beta) <span class="label">03.03.2022</span></h3>
                                        Es geht weiter mit unseren Automatisierungsfunktionen: Neben der manuellen Erstellung von Ernährungsplänen kannst du die <span class="bold">Pläne jetzt auch automatisch mit Rezepten befüllen</span> lassen - natürlich abgestimmt auf die Ziele und Vorlieben deiner Coachees!<br>
                                        Du findest die AutoFill-Funktion in der Bearbeitungsoberfläche eines Plans.<br>
                                        In der Betaphase kannst du die Funktion ab sofort nutzen und uns <span class="bold">Feedback geben</span>, wie sie dir gefällt und wie wir sie noch verbessern können.
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Automatische Erinnerungen versenden <span class="label">11.02.2022</span></h3>
                                        In den Einstellungen hast du ab sofort die Möglichkeit, Erinnerungen in Form von <span class="bold">Push-Benachrichtigungen zu planen und automatisch an deine Coachees versenden</span> zu lassen.<br>
                                        Damit kannst du z.B. ausgewählte Coachees oder Kunden-Gruppen an einen Check-In, Termin oder an Habits erinnern.
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Verbesserungen an der Coaching Zone <span class="label">10.01.2022</span></h3>
                                        Wir haben die Coaching Zone an einigen Stellen für dich verbessert:<br>
                                        • Du kannst <span class="bold">Kund:innen umbenennen</span>, z.B. um Dopplungen zu vermeiden (über das 3-Punkte-Menü einer Kunden-Box).<br>
                                        • Du kannst <span class="bold">Gruppen erstellen</span>, diese auf dem Dashboard anpinnen und Kund:innen zuordnen für eine bessere Organisation.<br>
                                        • Du kannst Lizenzen direkt vom Dashboard aus deaktivieren.<br>
                                        • Wir haben in den Mikronährstoff-Zusammenfassungen den RDA der Europäischen Kommission als Referenzwert hinzugefügt.
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Merry Christmas <span class="label">24.12.2021</span></h3>
                                        Wir wünschen dir frohe Weihnachten, erholsame Feiertage und ein gutes und erfolgreiches Jahr 2022.<br>
                                        Wir danken dir vor allem für dein Vertrauen, deine Ideen und Anregungen in 2021 und freuen uns auf die Zusammenarbeit im kommenden Jahr. Lass es dir gut gehen!<br>
                                        Apropos Support: Wir sind seit neustem auch auf Trustpilot zu finden. Wir würden uns freuen, wenn du uns <a href="https://de.trustpilot.com/review/nutrilize.app" target="_blank">hier</a> ein Feedback da lässt!
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Verbesserungen an der Coaching Zone <span class="label">29.11.2021</span></h3>
                                        Wir haben die Coaching Zone an einigen Stellen für dich verbessert:<br>
                                        • Auf dem Dashboard erhältst du jetzt Hinweise für bald auslaufende Ernährungspläne.<br>
                                        • Beim Hinzufügen eines neuen Coachees kannst du jetzt auch eine komplette Anleitung zum Einlösen inkl. dem Lizenzcode kopieren und versenden.<br>
                                        • Exportiere das Ernährungstagebuch eines Coachees als PDF.<br>
                                        • In der Fortschrittstabelle und Ernährungsstatistik kannst du zusätzlich zu den Makronährstoffen auch Zucker, Gesättigte Fettsäuren, Ballaststoffe und Salz einsehen.<br>
                                        • Blende in der Tabellenansicht der Fortschritte einzelne Zeilen aus, sodass diese nicht in Durchschnitts- und Summenberechnungen einberechnet werden, indem du in der ersten Spalte einen Haken setzt.
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Mikronährstoffe und weitere Details durch den BLS <span class="label">07.10.2021</span></h3>
                                        Unsere Lebensmitteldatenbank haben wir vor einigen Wochen um den Bundeslebensmittelschlüssel (BLS) erweitert. Die hinzugefügten Lebensmittel sind in der Suche mit einem Icon "Detail" markiert und enthalten sämtliche Angaben zu Vitaminen, Mineralstoffen, Kohlenhydrataufteilungen und mehr.<br>
                                        Außerdem haben wir im Portal die Auswertungen und Nährstoffzusammenfassungen an verschiedenen Stellen um diese neuen Werte ergänzt, was du immer über die kleinen "i"-Icons einsehen kannst.
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Bilder-Serien anzeigen und Tabellenzeilen ausblenden <span class="label">17.09.2021</span></h3>
                                        Wir haben das Fortschritts-Monitoring weiter verbessert: Du kannst jetzt alle Bilder, die von deinen Kund:innen als Bildmetrik getrackt wurden (bspw. Formchecks), in einer Serie anzeigen. Außerdem kannst du Zeilen in der Tabellenansicht der Fortschritte ausblenden.
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>App-Personalisierung für dein Coaching <span class="label">01.09.2021</span></h3>
                                        Passe das Erscheinungsbild der Client-App an dein Coaching und Branding an! Die Konfiguration kannst du im Menü unter Einstellungen vornehmen.
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Bilder und PDFs per Chat versenden <span class="label">09.08.2021</span></h3>
                                        Der Chat wurde nach Android jetzt auch auf iOS um den Versand und Empfang von Bildern und Dateien erweitert!
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Video-Tutorials für nutrilize for Coaches <span class="label">16.07.2021</span></h3>
                                        Wir haben Video-Tutorials für dich erstellt. Von den ersten Schritten in der Coaching Zone bis zur Erstellung von Ernährungsplänen und Metriken ist alles dabei. Ihr findet sie in der Menüleiste unten links.
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Synchronisation mit Health-Apps und Fitnesstrackern <span class="label">13.07.2021</span></h3>
                                        Ein wichtiges Feature für deine Kund:innen: Absofort können verschiedene Daten (Aktivitäten, Schritte, Schlafdauer) von anderen Apps und Fitnesstrackern über Apple Health und Google Fit mit nutrilize synchronisiert werden.
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Fortschrittsgraphen kombiniert anzeigen, Dateiablage in den Notizen und PDF-Export für Ernährungspläne (Beta) <span class="label">21.06.2021</span></h3>
                                        Wir haben die Coaching Zone an einigen Stellen für dich verbessert:<br>
                                        • Zeige beliebige Fortschritte im Graph gleichzeitig an.<br>
                                        • Lege Dateien und Bilder in den Notizen ab.<br>
                                        • Exportiere Ernährungspläne als PDF (Beta).
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>EP-Vorlagen, Lizenzablaufdatum und Tabellenansicht für Fortschritte <span class="label">02.05.2021</span></h3>
                                        Wir haben die Coaching Zone an einigen Stellen für dich verbessert:<br>
                                        • Erstelle Ernährungsplan-Vorlagen, die du für verschiedene Kunden schnell wiederverwenden und anpassen kannst.<br>
                                        • Setze ein Ablaufdatum für Lizenzen und werde automatisch an den Ablauf einer Lizenz erinnert.<br>
                                        • Sieh dir sämtliche Körper- und Metrikdaten tabellarisch an.
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Notifications (aktualisiert) <span class="label">26.04.2021</span></h3>
                                        Push-Notifications haben es jetzt auch in unsere iOS-App geschafft!<br>
                                        Deine Kund:innen erhalten automatisch eine Benachrichtigung, wenn du ihnen Feedback oder eine Chat-Nachricht sendest.
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Eigene Metriken (aktualisiert) <span class="label">01.04.2021</span></h3>
                                        Weise deinen Kund:innen individuelle Metriken zu, anhand derer sie ihre Fortschritte tracken können. Damit bringst du sie in allen Bereich voran.<br>
                                        Das Feature wird absofort auf allen Plattformen unterstützt!
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Rezept-Pool (aktualisiert)</h3>
                                        Deine Rezeptdatenbank kannst du jetzt auch mit deinen Kund:innen außerhalb eines Ernährungsplans teilen. Weise dazu beliebige Rezepte allen oder ausgewählten Kund:innen zu.<br>
                                        Das Teilen deiner Rezepte kannst du im Menü unter Rezepte aktivieren. Das Feature wird absofort auf allen Plattformen unterstützt!
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Eigene Metriken in Beta</h3>
                                        Weise deinen Kund:innen individuelle Metriken zu, anhand derer sie ihre Fortschritte tracken können. Damit bringst du sie in allen Bereich voran.<br>
                                        Android-Nutzer:innen mit ausreichend neuer Appversion können bereits personalisiert Metriken von dir zum Tracken erhalten. Die iOS-Unterstützung folgt noch im März.
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Erweiterte Nährwertziele (aktualisiert)</h3>
                                        Mit den erweiterten Nährwertzielen kannst du spezifische Nährwertziele für unterschiedliche Wochentage setzen. Außerdem kannst du Alternativ-Ziele zum manuellen Überschreiben in der App bereitstellen.<br>
                                        Das Feature wird absofort auf allen Plattformen unterstützt!
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Erweiterte Nährwertziele in Beta <span class="label">22.02.2021</span></h3>
                                        Mit den erweiterten Nährwertzielen kannst du spezifische Nährwertziele für unterschiedliche Wochentage setzen. Außerdem kannst du Alternativ-Ziele zum manuellen Überschreiben in der App bereitstellen.<br>
                                        Für Nutzer mit ausreichender Appversion kannst du die erweiterten Nährwertziele im Portal direkt nutzen!<br>
                                        Android-Nutzer sind schon bedient, das passende iOS-Update kommt in den nächsten Tagen.
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Rezept-Pool in Beta <span class="label">16.02.2021</span></h3>
                                        Deine Rezeptdatenbank kannst du jetzt auch mit deinen Kund:innen außerhalb eines Ernährungsplans teilen. Weise dazu beliebige Rezepte allen oder ausgewählten Kund:innen zu.<br>
                                        Das Teilen deiner Rezepte kannst du im Menü unter Rezepte aktivieren. Der Pool ist vorerst nur über unsere Android-App einsehbar.
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Notizen zu deinen Kunden <span class="label">10.02.2021</span></h3>
                                        Du möchtest wichtige Infos, Zusammenfassungen deiner Kunden-Calls, Essvorlieben etc. zu deinen Kunden zentral verwahren und immer zur Hand haben?<br>
                                        Kein Problem! Zu jedem Kunden kannst du in der Coaching Zone Notizen anlegen, die selbstverständlich nur du siehst.<br>
                                        Wie? Wähle einfach einen Kunden aus und klicke oben rechts auf Notizen.
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Erstelle Rezepte und Ernährungspläne!</h3>
                                        Du kannst dir deine eigene Rezeptdatenbank aufbauen und damit ganz einfach Ernährungspläne für deine Kund:innen erstellen. Diese empfangen die Pläne natürlich direkt in ihrer App, um sie zu verwenden!<br>
                                        Bald wird es auch die Möglichkeit geben, die Rezepte deinen Kund:innen in nutrilize auch ohne Ernährungsplan zur Verfügung zu stellen.
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Pinne deine Kunden auf dein Dashboard!</h3>
                                        Auf dem neuen Dashboard des Portals hast du alles, was dir wichtig ist.<br>
                                        Dir werden neue Nachrichten angezeigt und kannst mit einem Klick direkt antworten.<br>
                                        Außerdem kannst du Kunden zum Dashboard hinzufügen, um dir schnell einen Überblick über ihren Fortschritt zu machen und sie gleich zu erreichen. Bald werden dir auch die Fortschritte deiner Kunden auf dem Dashboard angezeigt.
                                    </div>
                                    <div class="layout-box news-box">
                                        <h3>Chatte mit deinen Kunden!</h3>
                                        Mit der neuen Chat-Funktion kannst du direkt mit deinen Kunden zielorientiert und ohne Umwege kommunizieren.<br>
                                        Um einen Chat initial zu starten, navigiere zu deinem Kunden im Portal und klicke oben rechts auf "Chat". Deine aktiven Chats kannst du auf jedem Bildschirm unten rechts erreichen, indem du die Leiste ausklappst.
                                    </div>
                                </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="layout-box news-feed">
                            <h3 class="display-inlineblock" id="push-notification-headline">nutrilize weiterempfehlen</h3>
                            <p>Du bist in einem Coaching-Verband, einem Netzwerk oder kennst andere Personal Trainer:innen oder Ernährungscoaches, die sich für nutrilize for Coaches interessieren könnten?</p><br>
                            <p>Dann würden wir uns sehr über eine Weiterempfehlung freuen!<br>Gerne kann sich dein:e Kolleg:in direkt über Instagram oder das Kontaktformular auf unserer Website bei uns melden. Alternativ darfst du mit deinem Tipp auch gerne zu uns kommen und wir nehmen den Kontakt auf. ;)<br><br>
                                Und das Beste: Als kleines Dankeschön schenken wir dir bei Vertragsabschluss einen 50€ Coupon, den du mit deinen nächsten Monatsabrechnungen einlösen kannst.</p><br>
                            <p>In diesem Sinne dir weiterhin viel Spaß mit nutrilize for Coaches. #SharingIsCaring</p><br>
                            <p><a href="https://nutrilize.app/ernaehrungssoftware/" target="_blank">Unsere Website</a> | <a href="https://www.instagram.com/nutrilize/" target="_blank">nutrilize auf Instagram</a></p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-xl-8 col-xxl-6">

                <h2 class="">Unsere Partner</h2>
                <div class="layout-box">
                    <img draggable="false" class="kaf-logo" src="assets/images/KAF-Akademie-Logo.png">
                    <div class="kaf-heading">Unser Partner in Sachen Weiterbildung.</div>
                    <img draggable="false" class="kaf-programs" src="assets/images/KAF-Akademie-Programme.png">
                    <div class="spacer-50"></div>
                    <div class="row kaf-button-row">
                        <div class="col-12 col-xxxl-6">
                            <div class="kaf-button">
                                <a href="https://drive.google.com/file/d/19KaeK0xNxtGw0AggkAG-SlFfREqoPjaW/view?usp=share_link" target="_blank">Unterlagen herunterladen</a>
                            </div>
                        </div>
                        <div class="col-12 col-xxxl-6">
                            <div class="kaf-button">
                                <a href="https://kaf-akademie.de/" target="_blank">Website besuchen</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>